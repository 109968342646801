import { Injectable } from '@angular/core';

import { UserPermissionsGuard } from 'src/app/auth/auth.guard';
import { AppPermissions } from '../permissions';
import { AuthService } from '../auth/auth.service';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class AddNewChildGuard extends UserPermissionsGuard {
  //this.authService.isAllowed(AppPermissions.AddNewChild)
  authCheck = () => true;
}

@Injectable({
  providedIn: 'root',
})
export class ViewPendingReferralsGuard extends UserPermissionsGuard {
  authCheck = () => this.authService.isAllowed(AppPermissions.ViewPendingReferrals);
}

@Injectable({
  providedIn: 'root',
})
export class ChildSearchGuard extends UserPermissionsGuard {
  authCheck = () => this.authService.isAllowed(AppPermissions.ChildSearch);
}

@Injectable({
  providedIn: 'root',
})
export class AssignScGuard extends UserPermissionsGuard {
  authCheck = () => this.authService.isAllowed(AppPermissions.AssignServiceCoordinator);
}

@Injectable({
  providedIn: 'root',
})
export class EarlyAccessReferralAddGuard implements CanActivate {
  constructor(protected authService: AuthService) {}

  canActivate(): boolean {
    return !this.authService.user || this.authService.isAllowed(AppPermissions.AddReferral);
  }
}
