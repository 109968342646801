<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mb-0">Reassign Audiologist</h3>

<div mat-dialog-content class="py-3 mt-3">
  <mat-card class="my-0">
    <ng-container [formGroup]="formGroup">
      <p class="py-2">
        Please confirm you wish to transfer the learner to the selected audiologist before clicking Submit. This action will remove the
        learner from the current audiologist's case list.
      </p>
      <app-autocomplete label="Audiologist" formControlName="audiologistUserId" [options]="audiologistOptions"></app-autocomplete>
    </ng-container>
  </mat-card>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button aria-label="Cancel" (click)="onCancel()" class="background-color--muted mr-1" [isBusy]="isSaving" tabindex="0">
    Cancel
  </button>

  <button
    mat-raised-button
    aria-label="Submit"
    color="primary"
    (click)="onSubmit()"
    [disableAfterBusy]="formGroup.invalid"
    [disabled]="formGroup.invalid"
    [isBusy]="isSaving"
    tabindex="0"
  >
    Submit
  </button>
</div>
