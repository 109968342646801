import { Component, OnInit } from '@angular/core';
import { createAlertConfig } from '../../pageAlertHelper';
import { AlertConfig } from '../page-alert/page-alert.component';

@Component({
  selector: 'app-dhh-alert',
  templateUrl: './dhh-alert.component.html',
  styleUrls: ['./dhh-alert.component.scss'],
})
export class DhhAlertComponent implements OnInit {
  alertConfig: AlertConfig = {
    status: null,
    message: null,
  };
  constructor() {}

  ngOnInit(): void {
    this.alertConfig = createAlertConfig('', 'warning', [
      'This learner has been identified as DHH. Please reach out to the audiologist and teacher of the deaf and hard of hearing.',
    ]);
  }
}
