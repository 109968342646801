import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { LoginModel } from '../../auth-models';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-family-login',
  templateUrl: './family-login.component.html',
  styleUrls: ['./family-login.component.scss'],
})
export class FamilyLoginComponent implements OnInit {
  loginFailed = false;
  isBusy = false;

  constructor(private breakpointObserver: BreakpointObserver, private authService: AuthService) {}

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  ngOnInit() {}

  onSubmit(creds: LoginModel) {
    this.loginFailed = false;
    this.isBusy = true;
    this.authService.login(creds).subscribe(
      () => {
        if (this.authService.isAuthenticated) {
          if (this.authService.isPortalUser) {
            this.authService.navigateToPortal();
          } else {
            this.authService.navigatePostAuth();
          }
        }
        this.isBusy = false;
      },
      (err: any) => {
        this.loginFailed = true;
        this.isBusy = false;
        console.log('Login error...');
        console.log(err);
      }
    );
  }
}
