import { Component, Inject, OnInit } from '@angular/core';
import { PrintDocumentModel } from './print-document-model';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DocumentService } from '../../services/document/document.service';
import { NotificationService } from '../../services/notification.service';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { shortDateFormat } from '../../dateTimeHelpers';
import { openPopup } from '../../windowHelpers';

@Component({
  selector: 'app-print-documents-modal',
  templateUrl: './print-documents-modal.component.html',
  styleUrls: ['./print-documents-modal.component.scss'],
})
export class PrintDocumentsModalComponent implements OnInit {
  dataSource = new MatTableDataSource<PrintDocumentModel>();
  selection = new SelectionModel<PrintDocumentModel>(true, []);
  displayedColumns: string[] = ['title', 'date'];
  canMerge: boolean;
  showAll: boolean;
  buttonLabel = 'Print';

  shortDateFormat = shortDateFormat;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    private data: { canMerge: boolean; documents: any[]; mergeLabel: string; showAll: boolean; map: { [col: string]: string } },
    private dialogRef: MatDialogRef<PrintDocumentsModalComponent>,
    private documentService: DocumentService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.canMerge = this.data.canMerge;
    this.showAll = this.data.showAll;
    if (this.canMerge) {
      this.displayedColumns = ['checkbox', ...this.displayedColumns];
    }
    if (this.data.mergeLabel) {
      this.buttonLabel = this.data.mergeLabel;
    }
    this.dataSource.data = this.data.documents
      .map((x) => {
        const d = Object.assign({}, x) as PrintDocumentModel;
        if (this.data.map) {
          for (const col in this.data.map) {
            d[col] = x[this.data.map[col]];
          }
        }
        return d;
      })
      .filter((x) => x.systemGenerated || this.showAll);
  }

  onClose() {
    this.dialogRef.close();
  }

  onPrint() {
    if (this.selection.selected.length > 0) {
      const documentIds = this.selection.selected.map((x) => x.documentId);
      this.documentService.getMergedDocument(documentIds).subscribe(
        (res: any) => {
          if (res) {
            if (res.missingDocuments.length > 0) {
              this.notificationService.errorWithAction(
                'Some of the selected documents do not exist.',
                'Why?',
                () => this.notificationService.alert(res.missingDocuments?.join('<br>'), 'Unable to locate the below documents'),
                5000
              );
            }

            if (res.file) {
              const blob = this.b64toBlob(res.file, 'application/pdf');
              const blobUrl = URL.createObjectURL(blob);
              const oWindow = window.open(blobUrl);
              setTimeout(() => {
                oWindow.print();
              }, 500);
              this.onClose();
            }
          }
        },
        (error) => {
          this.notificationService.errorWithAction(
            'None of the selected documents exist.',
            'Why?',
            () => this.notificationService.alert(error?.error?.join('<br>'), 'Unable to locate the below documents'),
            5000
          );
        }
      );
    }
  }

  onDocumentView(doc: PrintDocumentModel) {
    openPopup(`/api/documents/generic/docid/${doc.documentId}`);
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: PrintDocumentModel): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.title}`;
  }

  private b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
