import { Injectable } from '@angular/core';

export class Correlation {
  private _id: string;
  public get id() {
    if (!this._id) {
      this._id = this.generateGuid();
    }
    return this._id;
  }
  private generateGuid() {
    // Public Domain/MIT
    let d = new Date().getTime(); //Timestamp
    let d2 = (typeof performance !== 'undefined' && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      const typedArray = new Uint8Array(1);
      const randomValue = crypto.getRandomValues(typedArray)[0];
      let r = (randomValue / Math.pow(2, 8)) * 16; //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        //Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
    });
  }
}
@Injectable({
  providedIn: 'root',
})
export class CorrelationService {
  private _correlation: Correlation;
  private _serviceId: string;
  private _count = [];
  constructor() {
    this._serviceId = new Correlation().id;
  }
  get current(): Correlation {
    return this._correlation;
  }
  begin(): Correlation {
    this._count.push(0);
    if (!this._correlation) {
      this._correlation = new Correlation();
    }
    return this._correlation;
  }
  end(): void {
    this._count.pop();
    if (this._count.length == 0) {
      this._correlation = null;
    }
  }
  cancel(): void {
    this._correlation = null;
  }
  correlateAction<T>(action: () => T) {
    this.begin();
    try {
      return action();
    } finally {
      this.end();
    }
  }
}
