<ul class="stepper" *ngIf="progressStages">
  <li
    *ngFor="let stage of progressStages; let i = index"
    class="stepper__item"
    [id]="stage.key + '_stage'"
    [ngClass]="{ complete: (activeStage && i <= activeStageIndex) || stage.selected }"
  >
    <div class="stepper__item-content">
      <mat-icon *ngIf="(activeStage && i <= activeStageIndex) || stage.selected">done</mat-icon>
      {{ stage.value }}
    </div>
  </li>
</ul>
