import { Help, HelpSection } from '../help';
import { I3IepHelp } from '../models/i3.help';

export function getI3IepDictionary(): Help[] {
  return [
    {
      title: HelpSection.I3,
      dictionary: [
        {
          label: I3IepHelp.IepTeams,
          iframeSource: 'i3IepThePlanForTheEligibleIndividual',
        },
        {
          label: I3IepHelp.IepIdr,
          iframeSource: 'i3ComponentsOfAnIep',
        },
        {
          label: I3IepHelp.IepReports,
          iframeSource: 'i3ComponentsOfAnIep',
        },
      ],
    },
  ];
}
