import { ParticipantType } from 'src/app/shared/modals/participant-search/participant-type';
import { LookupBase } from 'src/app/shared/models/lookup-base';

export interface MeetingParticipantRead {
  id: string;
  agency: string;
  agencyOther: string;
  role: string;
  roleOther: string;
  fullName: string;
  email: string;
  userId: string;
  familyMemberId: string;
  surrogateId?: string;
  agencyId: string;
  medicalSpecialistId: string;
  learnerId: string;
  type: ParticipantType;
  meetingAttendanceId: string;
  meetingAttendanceOther: string;
  excusalDate?: Date;
  excusalPrintDate?: Date;
  excusalAgreementReceived?: Date;
  teamMemberInputReceived?: Date;
  isByResident: boolean;
  excusalSignedFormId: string;
  excusalSignatoryToken: string;
  excusalSigned: boolean;
  excusalApproved: boolean;
}

export interface MeetingParticipantUpdate {
  id: string;
  agency: string;
  role: string;
  roleOther: string;
  fullName: string;
  email: string;
  userId: string;
  familyMemberId: string;
  agencyId: string;
  medicalSpecialistId: string;
  learnerId: string;
  type: ParticipantType;
  meetingAttendanceId: string;
  meetingAttendanceOther: string;
  excusalDate?: Date;
  excusalPrintDate?: Date;
  excusalAgreementReceived?: Date;
  teamMemberInputReceived?: Date;
}

export interface MeetingAttendance extends LookupBase {
  order: number;
  type: MeetingAttendanceType;
}

export enum MeetingAttendanceType {
  InPerson = 'InPerson',
  Written = 'Written',
  Video = 'Video',
  DidNotAttend = 'DidNotAttend',
  ExcusalNeeded = 'ExcusalNeeded',
  Excused = 'Excused',
  Other = 'Other',
}
