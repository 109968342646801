import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable } from 'rxjs';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { AchieveConfigService } from 'src/app/shared/services/achieve-config-service/achieve-config.service';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { IepServiceHelp } from 'src/app/shared/services/help/models/iep.help';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { BaseComponent } from '../../../../../shared/components/base-component/base-component';
import { LearnerSummary } from '../../../../../shared/models/learner';
import { DeactivationService } from '../../../../../shared/services/deactivation.service';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { IepAmendment } from '../../../../models/iep-amendment';
import { IepAssessmentDto } from '../../../../models/iep-assessment-dto';
import { IepAmendmentService } from '../../../../services/iep-amendment.service';
import { IepService } from '../../../../services/iep.service';

@Component({
  selector: 'app-iep-ssaa-assessment',
  templateUrl: './iep-ssaa-assessment.component.html',
  styleUrls: ['./iep-ssaa-assessment.component.scss'],
})
export class IepSsaaAssessmentComponent extends BaseComponent implements OnInit {
  @Input() learner: LearnerSummary;
  @Input() isDetailsView = false;
  caseId: string;
  iepId: string;
  shortDateFormat = shortDateFormat;
  howParticipateLabel: string;
  accommodationLabels: string[];
  priorVersionAccommodationLabels: string;
  assessmentModel: IepAssessmentDto;
  @Input() isAmendment: boolean;
  @Input() amendmentId: string;
  @Input() amendments: IepAmendment[];
  @Input() amendingAssessment: boolean;
  @Input() isDeleting = false;

  helpSection = HelpSection;
  iepServiceHelp = IepServiceHelp;

  get alternateAssessmentParticipationGuidelines() {
    return this.achieveConfigService?.settings?.databaseLinks?.alternateAssessmentParticipationGuidelines;
  }

  get hasAmendment() {
    return this.amendmentId;
  }

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentIsFinalized() {
    return this.amendment?.finalizeDate !== null;
  }

  get hasOpenAmendment() {
    return !!this.amendment && !this.amendmentIsFinalized;
  }

  get lastFinalizedDate() {
    if (this.amendments && this.amendments.length > 0) {
      const latest = this.amendments.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  get hasPriorVersion() {
    return !!this.assessmentModel?.priorVersion;
  }

  get formData() {
    return this.formGroup.value;
  }

  formGroup = new FormGroup({
    howParticipate: new FormControl('', Validators.required),
    whatBarriers: new FormControl(''),
    assessmentNecessary: new FormControl(''),
    accommodations: new FormControl([]),
    otherText: new FormControl(''),
  });

  get hasOtherOption() {
    const accommodations = this.formGroup.get('accommodations').value;
    return accommodations && accommodations.find((x) => x === this.accommodationOptions.find((accVal) => accVal.value === 'Other').key);
  }

  get howParticipate() {
    return this.formGroup.get('howParticipate').value;
  }

  get showAccommodationQuestion() {
    return (
      this.formGroup.get('howParticipate').value === 'standardAssessmentWithAccommodation' ||
      this.formGroup.get('howParticipate').value === 'alternateAssessmentWithAccommodation'
    );
  }

  howParticipateOptions: KeyValuePair[] = [
    new KeyValuePair('standardAssessmentNoAccommodations', 'Standard with no accommodations'),
    new KeyValuePair('standardAssessmentWithAccommodation', 'Standard with accommodations'),
    new KeyValuePair('alternateAssessmentNoAccommodations', 'Alternate Assessment with no accommodations'),
    new KeyValuePair('alternateAssessmentWithAccommodation', 'Alternate Assessment with accommodations'),
    new KeyValuePair('willNotParticipateInAssessment', 'Will not participate in statewide and districtwide Assessment'),
  ];

  accommodationOptions: KeyValuePair[] = [];

  save = new Observable<boolean>((observer) => {
    this.cd.detectChanges();

    const done = () => {
      this.isSaving = false;
      observer.next(true);
    };

    if (!this.formGroup.dirty || this.isSaving) {
      done();
      return;
    }

    this.isSaving = true;
    this.onSave().then(() => {
      done();
    });
  });

  constructor(
    private iepService: IepService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private helpService: HelpService,
    private cd: ChangeDetectorRef,
    private iepAmendmentService: IepAmendmentService,
    deactivationService: DeactivationService,
    private readonly achieveConfigService: AchieveConfigService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.caseId = this.route.parent?.snapshot.paramMap.get('caseId');
    this.iepId = this.route.parent?.snapshot.paramMap.get('iepId');
    if (!this.caseId) {
      this.caseId = this.route.snapshot.paramMap.get('caseId');
    }
    if (!this.iepId) {
      this.iepId = this.route.snapshot.paramMap.get('iepId');
    }

    forkJoin([
      this.iepService.getAssessmentFromIep(this.iepId, this.amendmentId ? this.amendmentId : ''),
      this.iepService.getAccommodationsForAssessment(this.iepId),
    ]).subscribe(([assessment, accommodations]) => {
      this.assessmentModel = assessment;

      this.accommodationOptions = accommodations
        .sort((a, b) => {
          if (a.order < b.order) {
            return -1;
          }
          if (a.order > b.order) {
            return 1;
          }
          return 0;
        })
        .map((x) => new KeyValuePair(x.id, x.label));

      if (assessment) {
        this.howParticipateLabel = this.howParticipateOptions.find((xx) => xx.key === assessment.howParticipate)?.value;

        this.accommodationLabels = [];
        assessment.accommodations.forEach((_assessment) => {
          const accom = this.accommodationOptions.find((x) => x.key === _assessment);
          if (accom) {
            this.accommodationLabels.push(accom.value === 'Other' ? assessment.otherText : accom.value);
          }
        });

        if (assessment.priorVersion) {
          const priorLabels = [];
          assessment.priorVersion.accommodations.forEach((_assessment, i) => {
            const accom = this.accommodationOptions.find((x) => x.key === _assessment);
            if (accom) {
              priorLabels.push(accom.value === 'Other' ? assessment.priorVersion.otherText : accom.value);
            }
          });
          this.priorVersionAccommodationLabels = priorLabels.length > 0 ? priorLabels.join(', ') : '';
        }

        this.formGroup.patchValue({
          howParticipate: assessment.howParticipate || '',
          whatBarriers: assessment.whatBarriers || '',
          assessmentNecessary: assessment.assessmentNecessary || '',
          accommodations: assessment.accommodations || [],
          otherText: assessment.otherText,
        });
      }
      this.saveSubscription = this.formGroup.valueChanges;
      this.startAutosaving();
    });

    this.subscriptions.add(
      this.formGroup.get('accommodations').valueChanges.subscribe(() => {
        if (this.hasOtherOption) {
          this.formGroup.get('otherText').setValidators([Validators.required]);
        } else {
          this.formGroup.get('otherText').setValue(null);
          this.formGroup.get('otherText').clearValidators();
        }
      })
    );

    this.subscriptions.add(
      this.formGroup.get('howParticipate').valueChanges.subscribe((howParticipateValue) => {
        this.formGroup.get('whatBarriers').clearValidators();
        this.formGroup.get('assessmentNecessary').clearValidators();
        this.formGroup.get('accommodations').clearValidators();

        this.formGroup.get('whatBarriers').setValue('');
        this.formGroup.get('assessmentNecessary').setValue('');
        this.formGroup.get('accommodations').setValue('');

        if (howParticipateValue === 'alternate') {
          this.formGroup.get('whatBarriers').setValidators([Validators.required]);
          this.formGroup.get('assessmentNecessary').setValidators([Validators.required]);
        }

        if (this.showAccommodationQuestion) {
          this.formGroup.get('accommodations').setValidators([Validators.required]);
        }
      })
    );
  }

  getFormAccommodationLabels(accommodations) {
    const accommodationLabels = [];

    if (accommodations) {
      accommodations.forEach((_accommodation) => {
        const accommodationFind = this.accommodationOptions.find((x) => x.key === _accommodation);

        if (accommodationFind) {
          accommodationLabels.push(accommodationFind.value);
        }
      });
    }

    return accommodationLabels.join(', ');
  }

  getHowParticipateText(value) {
    return this.howParticipateOptions.find((x) => x.key === value)?.value;
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm) {
    e.preventDefault();

    const dictionary = this.helpService.getIepServiceDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }

  async onSave(showNotification = true) {
    const formValues = this.formGroup.value;
    formValues.amendmentId = this.amendmentId;

    if (!this.isDeleting) {
      try {
        await this.iepService.updateAssessment(this.iepId, formValues).toPromise();
        if (showNotification) {
          this.notificationService.success('Saved!');
        }
      } catch {
        if (showNotification) {
          this.notificationService.error('Failed to save.');
        }
      }
    }
  }

  onAmend() {
    if (!this.amendment.amendingAssessment) {
      this.amendment.amendingAssessment = true;
      this.iepAmendmentService.updateAmendment(this.iepId, this.amendment).subscribe(() => {});
    }
  }

  openSummary() {
    const config: NewWindowConfig = {
      path: `cases/${this.caseId}/iep/${this.iepId}/services/summary`,
      popup: true,
      width: '1480px',
    };
    openNewWindow(config);
  }

  onClearControl(control: AbstractControl) {
    control.setValue(null);
  }
}
