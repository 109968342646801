import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { combineLatest } from 'rxjs';
import { SurveyInviteCreatorModalComponent } from 'src/app/evaluation/modals/survey-invite-creator-modal/survey-invite-creator-modal.component';
import { getFirstLocationName } from 'src/app/shared/locationHelpers';
import { NonAchieveParticipantType } from 'src/app/shared/non-achieve-participant';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { AuthService } from '../../../../auth/auth.service';
import { AppPermissions } from '../../../../permissions';
import { AreYouSureComponent } from '../../../../shared/components/are-you-sure-modal/are-you-sure.component';
import {
  TeamMemberSearchModalComponent,
  TeamSearchModalData,
} from '../../../../shared/modals/team-member-search-modal/team-member-search-modal.component';
import { KeyValuePair } from '../../../../shared/models/key-value-pair';
import { Team } from '../../../../shared/models/team';
import { CaseService } from '../../../../shared/services/case/case.service';
import { MemoryStorageService } from '../../../../shared/services/memory-storage/memory-storage.service';
import { NotificationService } from '../../../../shared/services/notification.service';
import { TeamService } from '../../../../shared/services/team/team.service';
import { Evaluation } from '../../../models/evaluation';
import { EvaluationUser } from '../../../models/evaluation-user';
import { EvaluationProxyService } from '../../../services/evaluation-proxy.service';
import { EvaluationUserService } from '../../../services/evaluation-user.service';

@Component({
  selector: 'app-evaluation-team-table',
  templateUrl: './evaluation-team-table.component.html',
  styleUrls: ['./evaluation-team-table.component.scss'],
})
export class EvaluationTeamTableComponent implements OnInit {
  evaluation: Evaluation;
  teams: Team[];
  teamOptions: KeyValuePair[] = [];
  teamMembers: EvaluationUser[] = [];
  permissions = AppPermissions;
  @Input() ownerId: string;
  @Output() surveyChanged = new EventEmitter<string>();
  nonAchieveParticipantType = NonAchieveParticipantType;
  getFirstLocationName = getFirstLocationName;

  isSuperAdminUser = false;

  displayedColumns: string[] = ['actions', 'name', 'jobTitle', 'email', 'phone', 'aea', 'district'];
  dataSource = new MatTableDataSource<any>();
  me: string;
  formGroup = new FormGroup({
    selectTeam: new FormControl(''),
  });

  constructor(
    private dialog: MatDialog,
    private readonly storage: MemoryStorageService,
    readonly authService: AuthService,
    private readonly evaluationProxyService: EvaluationProxyService,
    private readonly teamService: TeamService,
    private readonly evaluationUserService: EvaluationUserService,
    private readonly notificationService: NotificationService,
    private readonly routingService: RoutingService,
    private readonly userService: UserService,
    private readonly caseService: CaseService
  ) {}

  ngOnInit(): void {
    this.me = this.authService.user.id;
    this.isSuperAdminUser = this.authService.isSuperAdmin;

    this.evaluation = this.storage.getKey('currentEvaluation', true);
    combineLatest([this.teamService.getTeams(), this.evaluationUserService.get(this.evaluation.id)]).subscribe(([teams, members]) => {
      this.teams = teams;
      this.teamOptions = teams.map((team) => new KeyValuePair(team.id, team.name));
      this.teamMembers = members;
      this.dataSource.data = this.teamMembers;
      this.evaluationProxyService.teamChange.next(this.teamMembers);

      this.evaluationProxyService.openTeamSearch.subscribe(() => {
        this.onOpenTeamSearch();
      });

      this.evaluationProxyService.assignmentChange.subscribe(([field, assignments]) => {
        if (field === 'teamMember' || field === 'status') {
          this.evaluationUserService.get(this.evaluation.id).subscribe((x) => {
            this.teamMembers = x;
            this.evaluationProxyService.teamChange.next(this.teamMembers);
            this.dataSource.data = this.teamMembers;
          });
        }
      });
    });
  }

  onOpenSurveyInvite() {
    const dialogRef = this.dialog.open(SurveyInviteCreatorModalComponent, {
      width: '728px',
      data: {
        teamMembers: this.teamMembers,
        evaluation: this.evaluation,
      },
    });
    dialogRef.afterClosed().subscribe((familySurveyId) => {
      if (familySurveyId) {
        this.evaluation.familySurveyId = familySurveyId;
        this.surveyChanged.emit(familySurveyId);
      }
    });
  }

  canDelete(teamMember: EvaluationUser) {
    if (this.authService.isSuperAdmin) {
      return true;
    }
    if (this.evaluation.owner.id === teamMember.userId) {
      return false;
    }
    if (this.evaluation.owner.id !== this.me) {
      return false;
    }
    return !teamMember.assignments.filter((x) => x.status.label === 'Complete').length;
  }

  onScheduleMeeting(caseId: string) {
    this.routingService.openScheduleMeeting(this.evaluation.learner.id);
  }

  teamSelected(event) {
    const teamUsers = this.teams.find((x) => x.id === event).users.map((x) => x.userId);
    if (teamUsers && teamUsers.length > 0) {
      this.evaluationUserService.put(this.evaluation.id, teamUsers).subscribe((x) => {
        this.evaluationUserService.get(this.evaluation.id).subscribe((members) => {
          this.teamMembers = members;
          this.dataSource.data = this.teamMembers;
          this.evaluationProxyService.teamChange.next(this.teamMembers);
          this.notificationService.success('Saved');
        });
      });
    }
  }

  onRemoveMember(teamMember: EvaluationUser) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        subQuestion: 'Clicking Yes will remove this team member.',
      },
    });

    dialogRef.afterClosed().subscribe((yesImSure) => {
      if (yesImSure) {
        this.evaluationUserService.remove(this.evaluation.id, teamMember.userId).subscribe(
          () => {
            this.teamMembers = this.teamMembers.filter((x) => x.userId !== teamMember.userId);
            this.dataSource.data = this.teamMembers;
            this.notificationService.success('Saved');
            this.evaluationProxyService.teamChange.next(this.teamMembers);
          },
          () => {
            this.notificationService.error('Failed to remove team member.');
          }
        );
      }
    });
  }

  onOpenTeamSearch() {
    if (!this.evaluation.learner.attendingAeaId) {
      this.notificationService.error('Cannot search for team members while attending aea is not set.');
      return;
    }

    const config = {
      userId: this.authService.user.id,
      intakeType: this.evaluation?.intakeType,
      learnerId: this.evaluation?.learner?.id,
      isMeeting: false,
      userIds: this.teamMembers.map((x) => x.userId),
    };
    const dialogRef = this.dialog.open(TeamMemberSearchModalComponent, {
      width: '728px',
      data: {
        config,
        showAeaFilter: true,
      } as TeamSearchModalData,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (!res || !res.newUserIds || res.newUserIds.length === 0) {
        return;
      }
      this.evaluationUserService.put(this.evaluation.id, res.newUserIds).subscribe(() => {
        this.evaluationUserService.get(this.evaluation.id).subscribe((members) => {
          this.teamMembers = members;
          this.evaluationProxyService.teamChange.next(this.teamMembers);
          this.dataSource.data = this.teamMembers;
        });
      });
    });
  }
}
