import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NewWindowConfig, openNewWindow } from '../../shared/windowHelpers';

@Injectable({
  providedIn: 'root',
})
export class DhhRoutingService {
  constructor(private router: Router) {}

  learnerHearingDashboardPath(learnerId: string) {
    return ['/', 'dhh', 'learner-hearing-dashboard', learnerId, 'general-info'];
  }

  goToLearnerHearingDashboard(learnerId: string) {
    this.router.navigate(this.learnerHearingDashboardPath(learnerId));
  }

  lhdTestPath(learnerId: string) {
    return ['/', 'dhh', 'learner-hearing-dashboard', learnerId, 'tests'];
  }

  goToLhdTest(learnerId: string) {
    this.router.navigate(this.lhdTestPath(learnerId));
  }

  openLhdTest(learnerId: string, testId: string = null, testSource = 'LHD', beforeunload?: (event) => void) {
    const params = testId ? { testId: testId } : { testSource: testSource };
    const newWindowConfig: NewWindowConfig = {
      path: `/dhh/learner-hearing-dashboard/${learnerId}/add-test`,
      popup: true,
      width: '1450px',
      params: params,
    };
    const newWindow = openNewWindow(newWindowConfig);

    if (beforeunload) {
      newWindow.addEventListener('beforeunload', beforeunload);
    }

    return newWindow;
  }

  openLhdAssessment(learnerId: string, assessmentId: string = null, isViewOnly = false, beforeunload?: (event) => void) {
    const params = assessmentId ? { assessmentId: assessmentId, isViewOnly: isViewOnly } : { isViewOnly: isViewOnly };
    const newWindowConfig: NewWindowConfig = {
      path: `/dhh/learner-hearing-dashboard/${learnerId}/assessments/form`,
      popup: true,
      width: '1450px',
      params: params,
    };
    const newWindow = openNewWindow(newWindowConfig);

    if (beforeunload) {
      newWindow.addEventListener('beforeunload', beforeunload);
    }

    return newWindow;
  }

  childSearchPath() {
    return ['dhh', 'child-search'];
  }

  goToChildSearch() {
    this.router.navigate(this.childSearchPath());
  }

  learnerEntryPath() {
    return ['/', 'dhh', 'learner-entry'];
  }

  goToLearnerEntry() {
    this.router.navigate(this.learnerEntryPath());
  }

  openEventScheduler(appointmentId: string = null, beforeunload?: (event) => void) {
    const newWindowConfig: NewWindowConfig = {
      path: appointmentId === null ? 'dhh/event-scheduler' : `dhh/event-scheduler/${appointmentId}`,
      popup: true,
      width: '1450px',
    };
    const newWindow = openNewWindow(newWindowConfig);

    if (beforeunload) {
      newWindow.addEventListener('beforeunload', beforeunload);
    }

    return newWindow;
  }

  openLearnerAppointmentScheduler(learnerId: string, learnerAppointmentId: string = null, beforeunload?: (event) => void) {
    const newWindowConfig: NewWindowConfig = {
      path: learnerAppointmentId !== null ? `dhh/appointment/${learnerId}/${learnerAppointmentId}` : `dhh/appointment/${learnerId}`,
      popup: true,
      width: '1450px',
    };
    const newWindow = openNewWindow(newWindowConfig);

    if (beforeunload) {
      newWindow.addEventListener('beforeunload', beforeunload);
    }

    return newWindow;
  }

  lhdDocumentsPath(learnerId: string) {
    return ['/', 'dhh', 'learner-hearing-dashboard', learnerId, 'documents'];
  }

  openLhdConsents(learnerId: string, queryParams: any) {
    this.router.navigate(this.lhdDocumentsPath(learnerId), { queryParams: queryParams });
  }
}
