import { Component, Input, OnInit } from '@angular/core';
import dayjs from 'dayjs';

@Component({
  selector: 'app-dhh-learner-grade-view',
  templateUrl: './dhh-learner-grade-view.component.html',
  styleUrls: ['./dhh-learner-grade-view.component.scss'],
})
export class DhhLearnerGradeViewComponent implements OnInit {
  @Input() dateOfBirth: string;
  @Input() gradeDescription: string;
  @Input() showLabel = false;

  formattedGrade = '';
  constructor() {}

  ngOnInit(): void {
    if (this.dateOfBirth) {
      const threeYearsAgo = dayjs().subtract(3, 'year');
      if (threeYearsAgo.diff(this.dateOfBirth, 'year', true) < 0) {
        this.formattedGrade = 'U3';
      } else {
        this.formattedGrade = this.gradeDescription;
      }
    } else {
      this.formattedGrade = this.gradeDescription;
    }
  }
}
