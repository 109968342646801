import { Injectable } from '@angular/core';
import { MatBottomSheet, MatBottomSheetConfig } from '@angular/material/bottom-sheet';
import { HelpTextSheetComponent } from '../../components/help-text-sheet/help-text-sheet.component';
import { MatDialog } from '@angular/material/dialog';
import { HelpModalComponent } from '../../modals/help-modal/help-modal.component';
import { Help, HelpArea, HelpSection, HelpTerm, HelpText } from './help';
import { getBipDictionary, getBipImplementationDictionary, getBipStrategiesDictionary } from './dictionary/bip.dictionary';
import { getDsDictionary, getDsFormDictionary } from './dictionary/ds.dictionary';
import { getI3IepDictionary } from './dictionary/i3.dictionary';
import { getIepDictionary, getIepGoalDictionary, getIepPlaafpDictionary, getIepServiceDictionary } from './dictionary/iep.dictionary';
import {
  getEvalAssessmentDetailsDictionary,
  getEvaluationAfDictionary,
  getEvaluationDictionary,
  getEvaluationScDictionary,
} from './dictionary/evaluation.dictionary';
import { getFbaAssessmentDetailsDictionary, getFbaDictionary, getFbaSummaryDictionary } from './dictionary/fba.dictionary';
import { getReferralDictionary } from './dictionary/referral.dictionary';
import { getIntakeDictionary } from './dictionary/intake.dictionary';
import { getInsuranceDictionary } from './dictionary/insurace.dictionary';
import { DatabaseLinksService } from '../database-links/database-links.service';

export interface HelpModalConfig {
  canBrowse: boolean;
  help?: Help[];
  item?: HelpTerm;
  area: HelpArea;
  section?: HelpSection;
  nestedTerm?: string;
  width?: string;
}

@Injectable({
  providedIn: 'root',
})
export class HelpService {
  constructor(
    private bottomSheet: MatBottomSheet,
    private dialog: MatDialog,
    private readonly databaseLinksService: DatabaseLinksService
  ) {}
  config: MatBottomSheetConfig;

  openHelpSheet(help: HelpText[], isRaw = false) {
    this.bottomSheet.open(HelpTextSheetComponent, {
      data: { help, isRaw },
      panelClass: 'help-sheet',
      hasBackdrop: false,
    });
  }

  openHelpModal(config: HelpModalConfig) {
    const { help, item, section, area, nestedTerm, canBrowse = false, width = '1280px' } = config;

    const dialogRef = this.dialog.open(HelpModalComponent, {
      hasBackdrop: false,
      width,
      data: {
        help,
        item,
        section,
        area,
        nestedTerm,
        canBrowse,
      },
    });
  }

  getAllDictionaries(): Help[] {
    return []
      .concat(
        getDsDictionary(),
        this.getUpdatedI3IepDictionary(),
        getIepDictionary(),
        getEvaluationDictionary(),
        getFbaDictionary(),
        getBipDictionary(),
        getReferralDictionary(),
        getIntakeDictionary(),
        getInsuranceDictionary()
      )
      .sort((a, b) => (a.title > b.title ? 1 : -1));
  }

  // TODO: Refactor this. Shouldn't need to do this, but to brain-dead to think of a quick solution. 🧠🔫
  getDsFormDictionary(): Help[] {
    return getDsFormDictionary();
  }

  getUpdatedI3IepDictionary(): Help[] {
    const dict = getI3IepDictionary();
    console.info('Dictionary:', dict);
    dict[0].dictionary.forEach((d) => {
      if (d.iframeSource === 'i3IepThePlanForTheEligibleIndividual') {
        d.iframeSource = this.databaseLinksService.getDatabaseLink('i3IepThePlanForTheEligibleIndividual');
      }
      if (d.iframeSource === 'i3ComponentsOfAnIep') {
        d.iframeSource = this.databaseLinksService.getDatabaseLink('i3ComponentsOfAnIep');
      }
    });

    console.info('Updated Dictionary:', dict);
    return dict;
  }

  getIepGoalDictionary(): Help[] {
    return getIepGoalDictionary();
  }

  getIepServiceDictionary(): Help[] {
    return getIepServiceDictionary();
  }

  getIepPlaafpDictionary(): Help[] {
    return getIepPlaafpDictionary();
  }

  getEvalAssessmentDetailsDictionary(): Help[] {
    return getEvalAssessmentDetailsDictionary();
  }

  getEvaluationAfDictionary(): Help[] {
    return getEvaluationAfDictionary();
  }

  getEvaluationScDictionary(): Help[] {
    return getEvaluationScDictionary();
  }

  getFbaAssessmentDetailsDictionary(): Help[] {
    return getFbaAssessmentDetailsDictionary();
  }

  getFbaSummaryDictionary(): Help[] {
    return getFbaSummaryDictionary();
  }

  getBipImplementationDictionary(): Help[] {
    return getBipImplementationDictionary();
  }

  getBipStrategiesDictionary(): Help[] {
    return getBipStrategiesDictionary();
  }

  getReferralDictionary(): Help[] {
    return getReferralDictionary();
  }

  getIntakeDictionary(): Help[] {
    return getIntakeDictionary();
  }
  getInsuranceDictionary(): Help[] {
    return getInsuranceDictionary();
  }
}
