import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, Subscription } from 'rxjs';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { StringSizes } from 'src/app/shared/components/form/constants/constants';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { yesNoOptions } from 'src/app/shared/formHelpers';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { Surrogate } from 'src/app/shared/models/surrogate';
import { BooleanYesNoPipe } from 'src/app/shared/pipes/boolean-yesno.pipe';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { ConfirmationDialogComponent, NotificationService } from 'src/app/shared/services/notification.service';
import { ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { openViewMore } from 'src/app/shared/tableHelpers';
import { openPdfWindow } from 'src/app/shared/windowHelpers';
import { SurrogateService } from '../services/surrogate.service';
import { AuthService } from '../../../auth/auth.service';
import { AchieveConfigService } from '../../../shared/services/achieve-config-service/achieve-config.service';
import { LearnerSummary } from '../../../shared/models/learner';
import { ForgotPasswordViewModel } from '../../../auth/auth-models';
import { FamilyMemberService } from '../../../shared/services/family-member/family-member.service';

@Component({
  selector: 'app-surrogate-contact-info',
  templateUrl: './surrogate-contact-info.component.html',
  styleUrls: ['./surrogate-contact-info.component.scss'],
  providers: [BooleanYesNoPipe, DatePipe],
})
export class SurrogateContactInfoComponent implements OnInit, OnDestroy {
  @Input() learnerId: string;
  @Input() learner: LearnerSummary;
  @Input() isFirstSurrogate: boolean;
  @Input() hasWorkableCase = true;
  @Output() cancelSurrogateEntry = new EventEmitter();
  subscriptions = new Subscription();
  showSurrogateEndForm = false;
  shortDateFormat = shortDateFormat;
  isEditing = false;
  dataSource = new MatTableDataSource<Surrogate>();
  displayColumns = ['actions', 'name', 'email', 'appointedOn', 'discontinuedOn', 'status', 'validated', 'familyAccess'];
  formGroup = this.fb.group({
    id: '',
    determinedNeededOn: ['', Validators.required],
    reasonForSurrogateId: ['', Validators.required],
    name: ['', Validators.required],
    appointedOn: ['', Validators.required],
    address: ['', Validators.required],
    email: ['', [Validators.required, Validators.email]],
    limitedAccess: ['', Validators.required],
    limitedAccessExplanation: '',
    aeaDirectorName: ['', Validators.required],
    discontinuedOn: '',
    familyUserId: null,
    accessRevoked: null,
    isFamilyMemberReadOnly: null,
    isUserAccountActive: null,
    noPortalAccess: null,
    validated: null,
  });

  yesNoOptions = yesNoOptions;
  reasonForSurrogateOptions: KeyValuePair[] = [];
  activeCall = false;
  stringSizes = StringSizes;

  get showForm(): boolean {
    return this.showSurrogateEndForm || this.isEditing || this.isFirstSurrogate;
  }

  get canInviteFamilyMember() {
    return this.learner.isEligibleForFamilyPortal && this.isFamilyPortalEnabled && this.authService.canEditSurrogates;
  }

  get isFamilyPortalEnabled() {
    return this.achieveConfigService.settings?.featureFlags?.familyPortalEnabled;
  }

  get canManageFamilyMemberAccess() {
    return this.learner.isEligibleForFamilyPortal && this.authService.canEditSurrogates;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly surrogateService: SurrogateService,
    private readonly learnerService: LearnerService,
    private readonly dialog: MatDialog,
    private readonly datePipe: DatePipe,
    private readonly boolToYesNoConverter: BooleanYesNoPipe,
    private readonly reportingService: ReportingService,
    private readonly authService: AuthService,
    private readonly achieveConfigService: AchieveConfigService,
    private familyMemberService: FamilyMemberService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    if (!this.isFamilyPortalEnabled) {
      const indexOfPortalStatus = this.displayColumns.indexOf('status', 3);
      if (indexOfPortalStatus > -1) {
        this.displayColumns.splice(indexOfPortalStatus, 1);
      }
    }
    this.load();
    this.watchLimitedAccess();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  onEdit(surrogate: Surrogate) {
    this.isEditing = true;
    this.isFirstSurrogate = false;
    this.formGroup.get('discontinuedOn').clearValidators();
    this.formGroup.get('discontinuedOn').updateValueAndValidity();
    this.formGroup.patchValue(surrogate);
  }

  onEnd(surrogate: Surrogate) {
    this.formGroup.patchValue(surrogate);
    this.showSurrogateEndForm = true;
    this.formGroup.get('discontinuedOn').setValidators(Validators.required);
    this.formGroup.get('discontinuedOn').updateValueAndValidity();
  }

  onSave(isEnding?: boolean) {
    this.activeCall = true;
    const surrogate = this.formGroup.value;
    surrogate.learnerId = this.learnerId;
    if (!isEnding) {
      if (!!surrogate.id) {
        this.updateSurrogate(surrogate);
      } else {
        this.createSurrogate(surrogate);
      }
    } else {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '728px',
        data: {
          title: 'End Surrogacy',
          message: `Are you sure you wish to end surrogacy for ${surrogate.name}?`,
        },
      });

      dialogRef.afterClosed().subscribe((value) => {
        if (value) {
          this.updateSurrogate(surrogate);
        }
        this.activeCall = false;
      });
    }
  }

  onCancel() {
    if (!this.formGroup.dirty) {
      this.onReset();
      return;
    }

    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '728px',
      data: {
        subQuestion: 'Continuing will result in the loss of the current information.',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.onReset();
      }
    });
  }

  onReset() {
    this.isEditing = false;
    this.showSurrogateEndForm = false;
    this.formGroup.reset();
    if (this.dataSource.data.length === 0) {
      this.cancelSurrogateEntry.emit(true);
    }
  }

  onViewMore(surrogate: Surrogate) {
    const modalData: ViewMoreModalData[] = [
      {
        name: 'Name',
        value: surrogate.name,
      },
      {
        name: 'Address',
        value: surrogate.address,
      },
      {
        name: 'Email',
        value: surrogate.email,
      },
      {
        name: 'AEA Director/Designee Name',
        value: surrogate.aeaDirectorName,
      },
      {
        name: 'Date it was determined that a surrogate was needed',
        value: this.convertToShortDate(surrogate.determinedNeededOn),
      },
      {
        name: 'Reason for surrogate',
        value: this.reasonForSurrogateOptions.find((x) => x.key === surrogate.reasonForSurrogateId).value,
      },
      {
        name: 'Appointed On',
        value: this.convertToShortDate(surrogate.appointedOn),
      },
      {
        name: 'Limited Access',
        value: this.boolToYesNoConverter.transform(surrogate.limitedAccess),
      },
    ];

    if (surrogate.limitedAccess) {
      modalData.push({
        name: 'Explanation',
        value: surrogate.limitedAccessExplanation,
      });
    }

    if (surrogate.discontinuedOn) {
      modalData.push({
        name: 'Discontinued On',
        value: this.convertToShortDate(surrogate.discontinuedOn),
      });
    }
    openViewMore(this.dialog, modalData);
  }

  onPrintSurrogateLetter() {
    this.reportingService.createSurrogateLetter(this.learnerId).subscribe({
      next: (outputDocumentId: string) => openPdfWindow(this.learnerId, outputDocumentId),
      error: (err) => this.reportingService.handleOutputError(err),
    });
  }

  onAdd() {
    this.isEditing = true;
    this.formGroup.get('discontinuedOn').clearValidators();
    this.formGroup.get('discontinuedOn').updateValueAndValidity();
  }

  onInviteFamilyMember(id: string) {
    this.activeCall = true;
    this.surrogateService.inviteSurrogate(this.learnerId, id).subscribe(
      (response) => {
        this.activeCall = false;
        if (response.succeeded) {
          this.notificationService.success('Invitation sent!');
        } else {
          this.notificationService.error('Invitation failed');
        }
      },
      () => {
        this.activeCall = false;
        this.notificationService.error('Invitation failed');
      }
    );
  }

  resetPassword(surrogate: Surrogate) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Are you sure you want to send reset password request/email?',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        const model = { email: surrogate.email } as ForgotPasswordViewModel;
        this.authService.forgotPassword(model).subscribe(
          (response) => {
            this.notificationService.success('Reset password email has been sent.');
          },
          (error) => {
            this.notificationService.error(error?.error);
          }
        );
      }
    });
  }

  revokeAccess(surrogate: Surrogate) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Are you sure you want to revoke access from this account?',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.familyMemberService.revokeAccess(surrogate.id).subscribe(
          (accountStatus) => {
            surrogate.accessRevoked = true;
            surrogate.isUserAccountActive = accountStatus.value;
            this.notificationService.success('Successfully revoked user access');
          },
          () => {
            this.notificationService.error('Revoking access failed');
          }
        );
      }
    });
  }

  reactivateAccess(surrogate: Surrogate) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Are you sure you want to grant access to this account?',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.familyMemberService.reactivateAccess(surrogate.id).subscribe(
          () => {
            surrogate.accessRevoked = false;
            surrogate.isUserAccountActive = true;
            this.notificationService.success('Successfully granted user access');
          },
          () => {
            this.notificationService.error('Granting access failed');
          }
        );
      }
    });
  }

  getPortalStatus(surrogate: Surrogate) {
    return surrogate.familyUserId !== null
      ? surrogate.accessRevoked
        ? 'Revoked'
        : surrogate.isFamilyMemberReadOnly
        ? 'Active - View Only'
        : 'Active'
      : '';
  }

  private convertToShortDate(date: Date) {
    return this.datePipe.transform(date, shortDateFormat);
  }

  private load() {
    this.subscriptions.add(
      forkJoin({
        surrogates: this.surrogateService.get(this.learnerId),
        reasonsForSurrogate: this.surrogateService.getReasonsForSurrogate(),
      }).subscribe((res) => {
        this.dataSource.data = res.surrogates;
        this.reasonForSurrogateOptions = res.reasonsForSurrogate.map((x) => new KeyValuePair(x.id, x.label));
      })
    );
  }

  private watchLimitedAccess() {
    this.subscriptions.add(
      this.formGroup.get('limitedAccess').valueChanges.subscribe((value) => {
        const formControl = this.formGroup.get('limitedAccessExplanation');
        if (value) {
          formControl.setValidators(Validators.required);
        } else {
          formControl.clearValidators();
        }
        formControl.updateValueAndValidity();
      })
    );
  }

  private createSurrogate(surrogate: Surrogate) {
    this.surrogateService.create(this.learnerId, surrogate).subscribe((res) => {
      surrogate.id = res;
      this.dataSource.data = [...this.dataSource.data, surrogate];
      this.isEditing = false;
      this.isFirstSurrogate = false;
      this.learnerService.learnerSummary.surrogates.push(surrogate);
      this.formGroup.reset();
      this.activeCall = false;
    });
  }

  private updateSurrogate(surrogate: Surrogate) {
    this.surrogateService.update(this.learnerId, surrogate).subscribe(() => {
      const index = this.dataSource.data.findIndex((x) => x.id === surrogate.id);
      this.dataSource.data[index] = surrogate;
      this.dataSource.data = [...this.dataSource.data];
      this.isEditing = false;
      this.showSurrogateEndForm = false;
      this.formGroup.reset();
      this.activeCall = false;
    });
  }
}
