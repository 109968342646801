<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Add ACHIEVE Users</h3>

<div mat-dialog-content class="pt-2 pb-2" ariaLabel="title">
  <mat-card>
    <form [formGroup]="formGroup">
      <app-text-field label="Search" formControlName="searchFilter"></app-text-field>
    </form>
  </mat-card>

  <mat-card class="mt-2 p-2">
    <div class="example-container">
      <div class="table-overflow app-table">
        <table mat-table [dataSource]="dataSource" matSort class="w-100">
          <ng-container matColumnDef="fullName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let element">
              {{ element.fullName }}
              {{ element.jobTitle ? ', ' + element.jobTitle : '' }}
            </td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
            <td mat-cell *matCellDef="let element">
              {{ element.email }}
            </td>
          </ng-container>

          <ng-container matColumnDef="actions">
            <th mat-header-cell *matHeaderCellDef>Actions</th>
            <td mat-cell *matCellDef="let element">
              <button
                mat-flat-button
                attr.aria-label="singleSelect ? 'Select' : element.isAdded ? 'Remove' : 'Add'"
                [color]="element.isAdded ? 'warn' : 'accent'"
                tabindex="0"
                (click)="onToggleTeamMember(element)"
              >
                {{ singleSelect ? 'Select' : element.isAdded ? 'Remove' : 'Add' }}
              </button>
            </td>
          </ng-container>

          <ng-container matColumnDef="noResults">
            <td mat-footer-cell *matFooterCellDef colspan="2">
              <i>{{ loading ? 'Loading...' : 'No results...' }}</i>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          <tr
            [hidden]="dataSource?.data?.length > 0"
            mat-footer-row
            *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"
          ></tr>
        </table>
      </div>
    </div>
    <app-table-paginator
      *ngIf="currentPageInfo.length > 0"
      [length]="currentPageInfo.length"
      [pageSize]="currentPageInfo.pageSize"
      [pageIndex]="currentPageInfo.pageIndex"
      (page)="pageChanged($event)"
    ></app-table-paginator>
  </mat-card>
</div>

<!-- <div mat-dialog-content class="py-3">
  <div class="row" [formGroup]="formGroup">
    <div class="col-md-3">
      <app-text-field label="Search" formControlName="searchFilter"></app-text-field>
    </div>
  </div>

  <div class="example-container">
    <table mat-table [dataSource]="dataSource" multiTemplateDataRows class="mat-elevation-z0" matSort #sort="matSort">
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox
            (click)="$event.stopPropagation()"
            [checked]="isSelected(element.id)"
            (change)="toggleSelection(element)"
          ></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.fullName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="jobTitle">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Job Title</th>
        <td mat-cell *matCellDef="let element">
          {{ element.jobTitle }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let element">
          {{ element.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Phone</th>
        <td mat-cell *matCellDef="let element">
          {{ element.phone }}
        </td>
      </ng-container>

      <ng-container matColumnDef="aea">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>AEA</th>
        <td mat-cell *matCellDef="let element">
          {{ element.aea }}
        </td>
      </ng-container>

      <ng-container matColumnDef="district">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>District</th>
        <td mat-cell *matCellDef="let element">
          {{ element.district }}
        </td>
      </ng-container>

      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef colspan="7">
          <i>No results...</i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr mat-footer-row *matFooterRowDef="dataSource.data.length === 0 ? ['noResults'] : []" class="example-second-footer-row"></tr>
    </table>
  </div>
  <app-table-paginator
    *ngIf="currentPageInfo.length > 0"
    [length]="currentPageInfo.length"
    [pageSize]="currentPageInfo.pageSize"
    [pageIndex]="currentPageInfo.pageIndex"
    (page)="pageChanged($event)"
  ></app-table-paginator>
</div> -->

<div mat-dialog-actions class="action__row">
  <button mat-raised-button mat-dialog-close>Cancel</button>
  <button mat-raised-button color="primary" (click)="onSaveClick()">Save</button>
</div>
