<app-page-header
  [pageTitle]="isLocationOnlySearch ? 'Location Search' : 'Child Search'"
  [navBack]="true"
  [navBackLabel]="isLocationOnlySearch ? 'Switch to Child Search' : 'Switch to Location Search'"
  backgroundColor="blue"
  (navBackToggled)="navBackToggle()"
>
</app-page-header>

<mat-card class="mt-2" *ngIf="referral">
  <mat-card-title>Submitted Referral</mat-card-title>
  <div class="row">
    <div class="col-md">
      <span class="mat-body-2">Name: </span>
      <span class="mat-body-1">
        {{ referral.childInfo.firstName }} {{ referral.childInfo.middleName }}
        {{ referral.childInfo.lastName }}
      </span>
    </div>
    <div class="col-md">
      <span class="mat-body-2">DOB: </span>
      <span class="mat-body-1">
        {{ referral.childInfo.dateOfBirth | dateFormat }}
      </span>
    </div>
  </div>
  <div class="row">
    <div class="col-md">
      <span class="mat-body-2">AEA: </span>
      <span class="mat-body-1">{{ referral.aea?.name || '-' }}</span>
    </div>
    <div class="col-md">
      <span class="mat-body-2">Attending School District: </span>
      <span class="mat-body-1">{{ referral.childInfo.childAttendingDistrict || '-' }}</span>
    </div>
  </div>
</mat-card>

<div class="">
  <mat-card class="mt-4">
    <mat-card-title class="mb-3">
      Search
      <mat-error class="display-inline text-sm" *ngIf="formGroup.hasError('simpleSearchRequired')">
        <span>Last name, DOB, or State ID <strong>required</strong></span>
      </mat-error>

      <mat-error class="display-inline text-sm" *ngIf="formGroup.hasError('constrainedSearchRequired')">
        Legal first name, last name, and DOB or State ID#
        <strong>required</strong>
      </mat-error>
      <mat-error class="display-inline text-sm" *ngIf="formGroup.hasError('locationSearchRequired')">
        AEA or District
        <strong>required</strong>
      </mat-error>
    </mat-card-title>
    <form [formGroup]="formGroup" (keyup.enter)="search()">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 no-form-field-padding" *ngIf="lastName.enabled">
            <app-text-field
              label="Last Name"
              formControlName="lastName"
              [showAsterisk]="formGroup.hasError('simpleSearchRequired') || formGroup.hasError('constrainedSearchRequired')"
              (input)="storeSearchInfo()"
              attr.aria-required="{{
                formGroup.hasError('simpleSearchRequired') || formGroup.hasError('constrainedSearchRequired') ? 'true' : 'false'
              }}"
            ></app-text-field>
          </div>
          <div class="col-lg-6 no-form-field-padding" *ngIf="middleName.enabled">
            <app-text-field label="Middle Name" formControlName="middleName" (input)="storeSearchInfo()"></app-text-field>
          </div>
          <div class="col-lg-6 no-form-field-padding" *ngIf="firstName.enabled">
            <app-text-field
              label="First Name"
              formControlName="firstName"
              [showAsterisk]="formGroup.hasError('constrainedSearchRequired')"
              (input)="storeSearchInfo()"
              attr.aria-required="{{ formGroup.hasError('constrainedSearchRequired') ? 'true' : 'false' }}"
            ></app-text-field>
          </div>
          <div class="col-lg-6 no-form-field-padding" *ngIf="dateOfBirth.enabled">
            <app-date-picker
              label="DOB"
              controlName="dateOfBirth"
              [disabled]="dateOfBirth.disabled"
              [max]="today"
              [min]="twentyFiveYearsAgo"
              (input)="storeSearchInfo()"
              [startView]="'multi-year'"
              [showAsterisk]="formGroup.hasError('simpleSearchRequired') || formGroup.hasError('constrainedSearchRequired')"
              attr.aria-required="{{
                formGroup.hasError('simpleSearchRequired') || formGroup.hasError('constrainedSearchRequired') ? 'true' : 'false'
              }}"
            ></app-date-picker>
          </div>
          <div class="col-lg-6 no-form-field-padding" *ngIf="stateAssignedId.enabled">
            <app-text-field
              label="State ID"
              formControlName="stateAssignedId"
              (input)="storeSearchInfo()"
              [showAsterisk]="formGroup.hasError('simpleSearchRequired') || formGroup.hasError('constrainedSearchRequired')"
              attr.aria-required="{{
                formGroup.hasError('simpleSearchRequired') || formGroup.hasError('constrainedSearchRequired') ? 'true' : 'false'
              }}"
            ></app-text-field>
            <mat-error
              style="display: inline"
              *ngIf="formGroup.controls.stateAssignedId.touched && formGroup.get('stateAssignedId').hasError('stateIdInvalidLength')"
            >
              <span class="text-sm">State ID must be 10 characters</span>
            </mat-error>
          </div>

          <ng-container *ngIf="isReady">
            <div class="col-lg-6 no-form-field-padding">
              <app-autocomplete label="AEA" formControlName="attendingAeaId" [options]="aeaOptions"></app-autocomplete>
            </div>
            <div class="col-lg-6 no-form-field-padding">
              <app-autocomplete label="District" formControlName="attendingDistrictId" [options]="aeaDistrictOptions"></app-autocomplete>
            </div>
            <div class="col-lg-6 no-form-field-padding" *ngIf="isLocationOnlySearch">
              <app-autocomplete label="Building" formControlName="buildingId" [options]="buildingOptions"></app-autocomplete>
            </div>
          </ng-container>

          <div class="col-lg-6 no-form-field-padding pt-3" *ngIf="searchDataSource.enabled">
            <app-radio-group [options]="dataSourceOptions" formControlName="searchDataSource" [useUndo]="false"></app-radio-group>
          </div>
        </div>
      </div>
    </form>
  </mat-card>

  <div class="mt-2 display-flex justify-content-between flex-wrap align-items-start">
    <div class="display-flex" *ngIf="!isLocationOnlySearch">
      <button
        mat-raised-button
        aria-label="New Learner"
        color="primary"
        class="align-self-center mr-3"
        [disabled]="!submitAttempted || !under6YearsOld || searchResults?.length > 0"
        (click)="createNewLearner()"
        *ngIf="canAddNewLearner"
        tabindex="0"
      >
        New Learner
      </button>
      <span *ngIf="canAddNewLearner" class="mat-caption new-student-warning ml-2">
        The new learner button only becomes active when a search has been conducted using the DOB field AND the child is under six-years old
        AND the search returns no results.
      </span>
    </div>

    <div class="search-buttons display-flex align-items-end justify-content-end flex-1">
      <div>
        <button
          type="button"
          class="mr-2 btn--icon"
          mat-raised-button
          aria-label="Clear"
          color="primary"
          (click)="clear()"
          [disabled]="activeCall"
          tabindex="0"
        >
          Clear
          <svg-icon src="assets/img/icons/Clear.svg" svgClass="svg-btn"></svg-icon>
        </button>
        <button
          mat-raised-button
          aria-label="Search"
          color="primary"
          (click)="search()"
          [disabled]="formGroup.invalid || activeCall"
          tabindex="0"
          [isBusy]="activeCall"
        >
          Search
          <i class="fas fa-search ml-2"></i>
        </button>
      </div>
    </div>
  </div>

  <mat-card class="card-spacing-top" *ngIf="searchResults">
    <app-child-search-results [isLocationOnlySearch]="isLocationOnlySearch" [searchResults]="searchResults" [referralId]="referral?.id">
      <div class="mat-horizontal-content-container">
        <router-outlet name="details"></router-outlet>
      </div>
    </app-child-search-results>
  </mat-card>
</div>
