<mat-form-field class="w-100" *ngIf="!readOnly || showReadOnlyControl; else readOnlyTemplate">
  <mat-label
    [ngClass]="{
      asterisk_input: (_formControl.hasError('required') || showAsterisk || required) && !hideAsterisk
    }"
    >{{ label }}</mat-label
  >
  <input
    #datePickerControl
    matInput
    autocomplete="off"
    attr.aria-required="{{ (_formControl.hasError('required') || showAsterisk || required) && !hideAsterisk ? 'true' : 'false' }}"
    [class]="klass"
    [formControl]="_formControl"
    [matDatepicker]="picker"
    [placeholder]="placeholder"
    [max]="max"
    [min]="min"
    [matDatepickerFilter]="dateFilter"
    [attr.readonly]="readOnly ? true : null"
    [attr.disabled]="_disabled ? true : null"
    (click)="!readOnly ? picker.open() : null"
    (blur)="onBlur($event)"
    (dateChange)="onDateChange($event)"
  />

  <mat-datepicker-toggle *ngIf="!readOnly" matSuffix [for]="picker"></mat-datepicker-toggle>
  <mat-datepicker #picker [startView]="startView" [startAt]="startAt"></mat-datepicker>
  <mat-error>
    <app-field-error-display [control]="_formControl"></app-field-error-display>
  </mat-error>
</mat-form-field>

<ng-template #readOnlyTemplate>
  <p class="read-only-container">
    <strong>{{ label }}</strong
    >{{ _formControl.value | dateFormat }}
  </p>
</ng-template>
