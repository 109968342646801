<app-page-header
  [pageTitle]="pageTitle"
  [backgroundColor]="'yellow'"
  (helpToggled)="helpToggled($event)"
  [condensed]="true"
  [shouldShowDhhBanner]="intake?.learner?.isDhh"
>
  <ng-container pageActions>
    <span class="mr-2">
      <app-navigate-to-eval-button
        *ngIf="intake"
        [activeEvaluationId]="activeEvaluationId"
        [caseId]="intake.caseId"
        [disabled]="evaluationButtonDisabled"
      ></app-navigate-to-eval-button>
    </span>
    <span class="mr-2">
      <button
        mat-flat-button
        color="primary"
        (click)="viewQuestionnaire()"
        matTooltip="View all fields with the family's responses to the questionnaire"
        aria-label="View all fields with the family's responses to the questionnaire"
        tabindex="0"
      >
        View Questionnaire
      </button>
    </span>
    <span class="mr-2">
      <button
        mat-flat-button
        color="primary"
        (click)="selectImportQuestionnaire()"
        matTooltip="Populate all fields with the family's responses to the questionnaire"
        aria-label="Populate all fields with the family's responses to the questionnaire"
        [disabled]="disableImportQuestionnaire"
        tabindex="0"
      >
        Import Questionnaire
      </button>
    </span>
    <span>
      <button
        *ngIf="isServiceCoordinator"
        mat-flat-button
        color="primary"
        (click)="resendQuestionnaire()"
        matTooltip="Resend the family questionnaire"
        aria-label="Resend the family questionnaire"
        [disabled]="!questionnaire?.familyHasBeenInvited || questionnaireInviteVerified"
        tabindex="0"
      >
        Re-Send Questionnaire
      </button>
    </span>
  </ng-container>
</app-page-header>
<app-learner-info-header [condensed]="true" [learnerId]="intake?.learner.id"> </app-learner-info-header>

<app-page-alert
  *ngIf="pagesIncomplete.status !== null && pagesIncomplete.message !== null"
  [alert]="pagesIncomplete"
  role="status"
  aria-live="polite"
></app-page-alert>

<mat-horizontal-stepper
  class="stepper"
  #stepper
  labelPosition="bottom"
  [disableRipple]="true"
  (selectionChange)="onStepperSelectionChange($event)"
  [ngClass]="{
    'mt-3': pagesIncomplete.status !== null && pagesIncomplete.message !== null
  }"
  aria-live="polite"
>
  <mat-step [stepControl]="childInfo" label="childInformation">
    <ng-template matStepLabel>
      <div
        [ngClass]="{
          'stepper__label--invalid': childInfoInvalid && submitAttempted
        }"
        class="stepper__label"
      >
        <div class="icon__container">
          <mat-icon aria-labelledby="Child Information">person</mat-icon>
        </div>
        <p>Child Information</p>
      </div>
    </ng-template>

    <div *ngIf="referral && canViewReferral(intake.caseId)" class="mb-2 actions align-items-center">
      <p>
        <strong class="mr-1">Referral Date: </strong>
        {{ referral.submittedOn | dateFormat }}
        <a target="_blank" class="ml-1" (click)="routeToEarlyAccessReferralReadOnlyForm()" aria-labelledby="Open in new tab">
          <mat-icon aria-labelledby="Open in new tab">open_in_new</mat-icon></a
        >
      </p>
    </div>

    <app-child-info-form
      *ngIf="intake"
      [intake]="intake"
      [formGroup]="childInfo"
      [childInfo]="intake.childInfo"
      [questionnaire]="questionnaire"
      [documentationInfo]="documentationInfo"
      [intakeLocked]="intakeLocked"
      [importQuestionnaireSelected]="importQuestionnaireSelected"
      (upload)="onUpload($event)"
      (deleteDocument)="onDeleteDocument($event)"
      (formInitialized)="trackFormInitialized('childInfo')"
    ></app-child-info-form>

    <div class="actions__container pb-3">
      <div class="actions"></div>
      <div class="actions">
        <button mat-raised-button aria-label="Next Tab" color="accent" matStepperNext tabindex="0">Next</button>
      </div>
    </div>
  </mat-step>

  <mat-step [stepControl]="familyInfo" label="familyInformation">
    <ng-template matStepLabel>
      <div
        [ngClass]="{
          'stepper__label--invalid': familyInfoInvalid && submitAttempted
        }"
        class="stepper__label"
      >
        <div class="icon__container">
          <mat-icon aria-labelledby="Family Information">people_alt</mat-icon>
        </div>
        <p>Family Information</p>
      </div>
    </ng-template>

    <div *ngIf="referral" class="mb-2 actions align-items-center">
      <p>
        <strong class="mr-1">Referral Date: </strong>
        {{ referral.submittedOn | dateFormat }}
        <a
          class="ml-1"
          target="_blank"
          [routerLink]="['/child-find/early-access-referral/', referral.id, 'read-only']"
          tabindex="0"
          role="link"
          aria-labelledby="Open in new tab"
          ><mat-icon aria-labelledby="Open in new tab">open_in_new</mat-icon></a
        >
      </p>
    </div>

    <app-family-info-form
      *ngIf="intake"
      [formGroup]="familyInfo"
      [referral]="referral"
      [intake]="intake"
      [intakeLocked]="intakeLocked"
      [questionnaire]="questionnaire"
      [importQuestionnaireSelected]="importQuestionnaireSelected"
      (formInitialized)="trackFormInitialized('familyInfo')"
      role="status"
    ></app-family-info-form>

    <div class="actions__container pb-3">
      <div class="actions"></div>
      <div class="actions">
        <button mat-raised-button aria-label="Previous" color="accent" matStepperPrevious tabindex="0">Previous</button>
        <button mat-raised-button aria-label="Next" color="accent" matStepperNext tabindex="0">Next</button>
      </div>
    </div>
  </mat-step>

  <mat-step [stepControl]="insuranceInfo" label="insuranceInformation">
    <ng-template matStepLabel>
      <div
        [ngClass]="{
          'stepper__label--invalid': insuranceInfoInvalid && submitAttempted
        }"
        class="stepper__label"
      >
        <div class="icon__container">
          <mat-icon aria-labelledby="Insurance Information">description</mat-icon>
        </div>
        <p>Insurance Information</p>
      </div>
    </ng-template>

    <div *ngIf="referral" class="mb-2 actions align-items-center">
      <p>
        <strong class="mr-1">Referral Date: </strong>
        {{ referral.submittedOn | dateFormat }}
        <a
          target="_blank"
          class="ml-1"
          [routerLink]="['/child-find/early-access-referral/', referral.id, 'read-only']"
          tabindex="0"
          role="link"
          aria-labelledby="Open in new tab"
          ><mat-icon aria-labelledby="Open in new tab">open_in_new</mat-icon></a
        >
      </p>
    </div>

    <app-insurance-info-form
      *ngIf="intake"
      [documentationInfo]="documentationInfo"
      [formGroup]="insuranceInfo"
      [insuranceInfo]="intake.insuranceInfo"
      [intake]="intake"
      [intakeLocked]="intakeLocked"
      [importQuestionnaireSelected]="importQuestionnaireSelected"
      [questionnaire]="questionnaire"
      (deleteDocument)="onDeleteDocument($event)"
      (upload)="onUpload($event)"
      (formInitialized)="trackFormInitialized('insuranceInfo')"
    >
    </app-insurance-info-form>

    <div class="actions__container pb-3">
      <div class="actions"></div>
      <div class="actions">
        <button mat-raised-button aria-label="Previous" color="accent" matStepperPrevious tabindex="0">Previous</button>
        <button mat-raised-button aria-label="Next" color="accent" matStepperNext tabindex="0">Next</button>
      </div>
    </div>
  </mat-step>

  <div *ngIf="referral" class="mb-2 actions align-items-center">
    <p>
      <strong class="mr-1">Referral Date: </strong>
      {{ referral.submittedOn | dateFormat }}
      <a
        class="ml-1"
        target="_blank"
        [routerLink]="['/child-find/early-access-referral/', referral.id, 'read-only']"
        tabindex="0"
        role="link"
        aria-labelledby="Open in new tab"
        ><mat-icon aria-labelledby="Open in new tab">open_in_new</mat-icon></a
      >
    </p>
  </div>

  <mat-step [stepControl]="healthInfo" label="healthInformation">
    <ng-template matStepLabel>
      <div
        [ngClass]="{
          'stepper__label--invalid': healthInfoInvalid && submitAttempted
        }"
        class="stepper__label"
      >
        <div class="icon__container">
          <svg-icon src="assets/img/icons/Health-Information.svg" svgClass="mat-icon icon--svg"></svg-icon>
        </div>
        <p>Health Information</p>
      </div>
    </ng-template>

    <div *ngIf="referral" class="mb-2 actions align-items-center">
      <p>
        <strong class="mr-1">Referral Date: </strong>
        {{ referral.submittedOn | dateFormat }}
        <a
          class="ml-1"
          target="_blank"
          [routerLink]="['/child-find/early-access-referral/', referral.id, 'read-only']"
          tabindex="0"
          role="link"
          aria-labelledby="Open in new tab"
          ><mat-icon aria-labelledby="Open in new tab">open_in_new</mat-icon></a
        >
      </p>
    </div>

    <app-health-info-form
      *ngIf="intake"
      #healthInfoForm
      (deleteDocument)="onDeleteDocument($event)"
      (upload)="onUpload($event)"
      (documentUploaded)="refreshIntakeOnDocumentUpload()"
      (pauseAutosaveEvent)="pauseAutosave($event)"
      [documentationInfo]="documentationInfo"
      [formGroup]="healthInfo"
      [healthInfo]="intake.healthInfo"
      [intake]="intake"
      [intakeLocked]="intakeLocked"
      [isQuestionnaire]="false"
      [questionnaire]="questionnaire"
      [importQuestionnaireSelected]="importQuestionnaireSelected"
      (tabChanged)="evaluateHealthInfoTabChange($event)"
      (medicalConditionUpdated)="medicalConditionUpdated = $event"
      (formInitialized)="trackFormInitialized('healthInfo')"
    >
    </app-health-info-form>

    <div class="actions__container pb-3">
      <div class="actions"></div>
      <div class="actions">
        <button mat-raised-button aria-label="Previous" color="accent" (click)="navigateFromHealthInfo('previous')" tabindex="0">
          Previous
        </button>
        <button mat-raised-button aria-label="Next" color="accent" (click)="navigateFromHealthInfo('next')" tabindex="0">Next</button>
      </div>
    </div>
  </mat-step>

  <mat-step [stepControl]="nextSteps" label="nextSteps">
    <ng-template matStepLabel>
      <div
        [ngClass]="{
          'stepper__label--invalid': nextStepsInvalid && submitAttempted
        }"
        class="stepper__label"
      >
        <div class="icon__container">
          <mat-icon aria-labelledby="Next Steps">next_week</mat-icon>
        </div>
        <p>Next Steps</p>
      </div>
    </ng-template>

    <div *ngIf="referral" class="mb-2 actions align-items-center">
      <p>
        <strong class="mr-1">Referral Date: </strong>
        {{ referral.submittedOn | dateFormat }}
        <a
          class="ml-1"
          target="_blank"
          [routerLink]="['/child-find/early-access-referral/', referral.id, 'read-only']"
          tabindex="0"
          role="link"
          aria-labelledby="Open in new tab"
          ><mat-icon aria-labelledby="Open in new tab">open_in_new</mat-icon></a
        >
      </p>
    </div>

    <app-next-steps-form
      *ngIf="intake && intake.nextSteps"
      [documentationInfo]="documentationInfo"
      [formGroup]="nextSteps"
      [familyInfo]="familyInfo"
      [nextSteps]="intake.nextSteps"
      [intake]="intake"
      [intakeLocked]="intakeLocked"
      [medicalConditionUpdated]="medicalConditionUpdated"
      (upload)="onUpload($event)"
      (deleteDocument)="onDeleteDocument($event)"
      (medicalConditionUpdatedEmit)="medicalConditionUpdated = $event"
      (evalButtonChange)="flipEvalButton($event)"
      (formInitialized)="trackFormInitialized('nextSteps')"
    >
    </app-next-steps-form>

    <div class="actions__container pb-3">
      <div class="actions"></div>
      <div class="actions">
        <button mat-raised-button aria-label="Previous" color="accent" matStepperPrevious tabindex="0">Previous</button>
        <button mat-raised-button aria-label="Next" color="accent" matStepperNext tabindex="0">Next</button>
      </div>
    </div>
  </mat-step>

  <mat-step [stepControl]="documentationInfo" label="documentation">
    <ng-template matStepLabel>
      <div
        [ngClass]="{
          'stepper__label--invalid': documentationInfoInvalid && submitAttempted
        }"
        class="stepper__label"
      >
        <div class="icon__container">
          <mat-icon aria-labelledby="Documentation">folder</mat-icon>
        </div>
        <p>Documentation</p>
      </div>
    </ng-template>

    <div *ngIf="referral" class="mb-2 actions align-items-center">
      <p>
        <strong class="mr-1">Referral Date: </strong>
        {{ referral.submittedOn | dateFormat }}
        <a
          class="ml-1"
          target="_blank"
          [routerLink]="['/child-find/early-access-referral/', referral.id, 'read-only']"
          tabindex="0"
          role="link"
          aria-labelledby="Open in new tab"
          ><mat-icon aria-labelledby="Open in new tab">open_in_new</mat-icon></a
        >
      </p>
    </div>

    <app-documentation-info-form
      *ngIf="intake"
      [locked]="intakeLocked"
      [formGroup]="documentationInfo"
      [documents]="intake?.documentationInfo"
      [caseId]="intake?.caseId"
      (upload)="onUpload($event)"
      (deleteDocument)="onDeleteDocument($event)"
      (formInitialized)="trackFormInitialized('documentationInfo')"
      documentType="EarlyAccessIntake"
      [learnersName]="childsName"
    >
    </app-documentation-info-form>

    <div class="actions__container pb-3">
      <div class="actions"></div>
      <div class="actions">
        <button mat-raised-button aria-label="Previous" color="accent" matStepperPrevious tabindex="0">Previous</button>
      </div>
    </div>
  </mat-step>

  <div class="invalid mt-5" *ngIf="!formGroup.valid">
    <mat-card>
      <div class="footer">
        <div class="footer__row footer__row--img">
          <img src="../../../assets/img/achieve-logo.svg" alt="ACHIEVE Logo" class="footer__logo" />
        </div>

        <div class="footer__row footer__row--text">
          <p class="color-maroon">This referral cannot be submitted because required fields are not completed.</p>

          <p>
            Call the
            <a (click)="openIowaIdeaInformationUrl()" tabindex="0" role="link" aria-labelledby="Call the Iowa Family Support Network">
              Iowa Family Support Network
            </a>
            at 1-888-425-4371 to talk with an Intake and Referral Specialist about this referral. Thank you!
          </p>
        </div>
      </div>
    </mat-card>
  </div>
</mat-horizontal-stepper>
