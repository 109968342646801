<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mb-0">Cancel Appointment</h3>

<div mat-dialog-content class="py-3 mt-3">
  <mat-card class="my-0">
    <ng-container [formGroup]="formGroup">
      <p class="py-2">Are you sure you want to cancel this appointment? To continue, select the reason for the cancellation below.</p>
      <app-autocomplete label="Reason" formControlName="cancellationReasonId" [options]="cancellationReasonOptions"></app-autocomplete>
    </ng-container>
  </mat-card>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button aria-label="No" (click)="onNo()" class="background-color--muted mr-1" [isBusy]="isSaving" tabindex="0">
    No
  </button>

  <button
    mat-raised-button
    aria-label="Yes"
    color="primary"
    (click)="onYes()"
    [disableAfterBusy]="formGroup.invalid"
    [disabled]="formGroup.invalid"
    [isBusy]="isSaving"
    tabindex="0"
  >
    Yes
  </button>
</div>
