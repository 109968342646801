<app-esy-choose-goal-modal
  *ngIf="dataLoaded"
  [importedIepId]="iepId"
  [importedSelectedGoals]="esyGoalsForChildComponent || []"
  [importedAllIepGoals]="allIepGoals"
  [importedAllPses]="allPses"
  [amendments]="amendments"
  [iepId]="iepId"
  [iepIsFinalized]="iepIsFinalized"
  [childFirstName]="childFirstName"
  [isInTransition]="caseSummary?.learner?.isSecondaryTransition"
  (goalsChanged)="refreshGoals()"
  (uiGoalsChanged)="uiGoalUpdate($event)"
></app-esy-choose-goal-modal>

<ng-container *ngIf="!dataLoaded">
  <mat-spinner color="primary" diameter="50"></mat-spinner>
</ng-container>
<ng-container *ngIf="dataLoaded">
  <mat-card *ngIf="noPSEsAvailable || noPSEsAvailable">
    <p class="my-0" *ngIf="noGoalsAvailable">
      No Goals{{ caseSummary?.learner.isSecondaryTransition ? '/Expectations' : '' }}
      Available
    </p>
    <p class="my-0" *ngIf="noPSEsAvailable">
      No Goals{{ caseSummary?.learner.isSecondaryTransition ? '/Expectations' : '' }}
      Selected
    </p>
  </mat-card>

  <mat-accordion *ngIf="esyGoals" class="accordion accordion--short" multi>
    <mat-expansion-panel class="mat-elevation-z0" *ngFor="let goal of esyGoals" [expanded]="true">
      <mat-expansion-panel-header
        [ngClass]="{
          'ended-item': isESYGoalEnded(goal)
        }"
      >
        <mat-panel-title *ngIf="goal.goalType === goalType.Goal">
          <h3 class="my-0 text-normal">Goal - {{ goal.conditionIndividualWillPerform }}</h3>
        </mat-panel-title>
        <mat-panel-title *ngIf="goal.goalType === goalType.PSE">
          <h3>Expectation - {{ goal.expectationType }}</h3>
        </mat-panel-title>
        <button
          *ngIf="!readonlyUser && hasOpenAmendment && !isESYGoalEnded(goal)"
          [disabled]="goal.amendingGoalDetails"
          mat-flat-button
          aria-label="Amend"
          color="primary"
          class="mr-2"
          (click)="$event.stopPropagation(); amendGoal(goal)"
          tabindex="0"
        >
          Amend
        </button>
      </mat-expansion-panel-header>
      <div class="display-flex flex-column" *ngIf="goal.goalType === goalType.Goal">
        <h4 class="text-primary-mt-0 mb-1">Annual Measurable Goal</h4>
        <p class="mt-0 mb-2">
          {{ goal.conditionIndividualWillPerform }}
        </p>
      </div>

      <div class="display-flex flex-column" *ngIf="goal.goalType === goalType.PSE">
        <h4 class="text-primary-mt-0 mb-1">Expectation</h4>
        <p class="mt-0 mb-2">
          {{ goal.expectation }}
        </p>
      </div>

      <div class="display-flex align-items-center justify-content-between">
        <div class="display-flex flex-column">
          <h4 class="text-primary-mt-0 mb-1">Progress Monitoring</h4>
          <p class="mt-0 mb-2" *ngIf="goal.goalType === goalType.Goal">
            {{ goal.monitoringFrequencyTime ? goal.monitoringFrequencyTime : goal?.iepGoal?.frequencyAmount }}
            {{ frequencyWording(goal) }} per
            {{ goal.monitoringFrequencyPeriod ? goal.monitoringFrequencyPeriod : goal?.iepGoal?.frequencyPeriod }}
          </p>
          <p class="mt-0 mb-2" *ngIf="goal.goalType === goalType.PSE">
            {{ goal.monitoringFrequencyTime ? goal.monitoringFrequencyTime : '' }}
            {{ goal.monitoringFrequencyTime > 1 ? ' times' : goal.monitoringFrequencyTime !== null ? ' time' : ' ' }}
            {{ goal.monitoringFrequencyTime ? ' per ' : ' ' }}
            {{ goal.monitoringFrequencyPeriod ? goal.monitoringFrequencyPeriod : 'N/A' }}
          </p>
        </div>
        <button
          *ngIf="allowFormEdit(goal.goalType === goalType.Goal, goal) && goal.goalType === goalType.Goal && !readonlyUser"
          mat-raised-button
          aria-label="Adjust for ESY"
          color="accent"
          (click)="openEsyAdjustModal(goal)"
          tabindex="0"
        >
          Adjust for ESY
        </button>
      </div>

      <div *ngIf="!readonlyUser" class="action__row mt-0">
        <button
          mat-raised-button
          aria-label="Create Service"
          color="primary"
          class="mr-2"
          *ngIf="!formGroup.get(goal.id + '_service')?.get('serviceVisible').value && allowFormEdit(goal.goalType === goalType.Goal, goal)"
          (click)="onOpenService(formGroup.get(goal.id + '_service'))"
          tabindex="0"
        >
          Create Service
        </button>
        <button
          mat-raised-button
          aria-label="Create Support /  Activity"
          color="primary"
          *ngIf="
            !formGroup.get(goal.id + '_support')?.get('supportActivityVisible').value &&
            allowFormEdit(goal.goalType === goalType.Goal, goal)
          "
          (click)="onOpenSupportActivity(formGroup.get(goal.id + '_support'))"
          tabindex="0"
        >
          Create Support / Activity
        </button>
      </div>

      <form
        [formGroup]="formGroup"
        *ngIf="
          formGroup.get(goal.id + '_support')?.get('supportActivityVisible').value ||
          formGroup.get(goal.id + '_service')?.get('serviceVisible').value
        "
      >
        <ng-container [formGroupName]="goal.id + '_service'" *ngIf="formGroup.get(goal.id + '_service').get('serviceVisible').value">
          <h4 class="text-primary mt-0 mb-2">{{ updatingService ? 'Update ' : 'Create New ' }} Service</h4>

          <mat-card class="mt-1">
            <div class="row">
              <div class="col-md-12 col-lg-6">
                <app-autocomplete [options]="serviceOptions" formControlName="serviceTypeId" label="Service"></app-autocomplete>
                <app-compare-amendment-output
                  *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                  [iepAmendments]="amendments"
                  [priorVersionId]="serviceForEdit?.priorVersionId"
                  [isActive]="serviceForEdit?.isActive"
                  [changedVersionText]="getServiceTypeLabel(formGroup.get(goal.id + '_service').get('serviceTypeId').value)"
                  [currentVersionText]="getServiceTypeLabel(serviceForEdit?.priorVersion?.serviceTypeId)"
                  [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                ></app-compare-amendment-output>
              </div>
              <div class="mt-2" *ngIf="showParaprofessionalSupportQuestions(goal.id)">
                <label>Select the type(s) of paraprofessional support</label>
                <app-checkbox controlName="paraprofessionalSupports" [options]="paraprofessionalSupportOptions"></app-checkbox>

                <app-compare-amendment-output
                  *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                  [iepAmendments]="amendments"
                  [priorVersionId]="serviceForEdit?.priorVersionId"
                  [isActive]="serviceForEdit?.isActive"
                  [changedVersionText]="formGroup.get(goal.id + '_service').get('paraprofessionalSupports').value"
                  [currentVersionText]="serviceForEdit?.priorVersion?.paraprofessionalSupports"
                  [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                ></app-compare-amendment-output>
                <ng-container *ngIf="hasParaprofessionalSupportBehaviorSafety(goal.id)">
                  <fieldset>
                    <app-radio-group
                      label="Does the learner have a Behavior Intervention Plan?"
                      formControlName="hasBehaviorInterventionPlan"
                      [options]="yesNoOptions"
                    ></app-radio-group>
                  </fieldset>
                  <app-compare-amendment-output
                    *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                    [iepAmendments]="amendments"
                    [priorVersionId]="serviceForEdit?.priorVersionId"
                    [isActive]="serviceForEdit?.isActive"
                    [changedVersionText]="formGroup.get(goal.id + '_service').get('hasBehaviorInterventionPlan').value | boolToYesNo"
                    [currentVersionText]="serviceForEdit?.priorVersion?.hasBehaviorInterventionPlan | boolToYesNo"
                    [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                  ></app-compare-amendment-output>
                </ng-container>

                <ng-container *ngIf="hasParaprofessionalSupportHealth(goal.id)">
                  <fieldset>
                    <app-radio-group
                      label="Does the learner have a Health Care Plan?"
                      formControlName="hasHealthCarePlan"
                      [options]="yesNoOptions"
                    ></app-radio-group>
                  </fieldset>
                  <app-compare-amendment-output
                    *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                    [iepAmendments]="amendments"
                    [priorVersionId]="serviceForEdit?.priorVersionId"
                    [isActive]="serviceForEdit?.isActive"
                    [changedVersionText]="formGroup.get(goal.id + '_service').get('hasHealthCarePlan').value | boolToYesNo"
                    [currentVersionText]="serviceForEdit?.priorVersion?.hasHealthCarePlan | boolToYesNo"
                    [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                  ></app-compare-amendment-output>
                </ng-container>
              </div>

              <ng-container *ngIf="showDirectConsultQuestions(goal.id)">
                <fieldset>
                  <app-radio-group
                    label="Service Method"
                    formControlName="directConsult"
                    [options]="directConsultOptions"
                  ></app-radio-group>
                </fieldset>
                <app-compare-amendment-output
                  *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                  [iepAmendments]="amendments"
                  [priorVersionId]="serviceForEdit?.priorVersionId"
                  [isActive]="serviceForEdit?.isActive"
                  [changedVersionText]="formGroup.get(goal.id + '_service').get('directConsult').value"
                  [currentVersionText]="serviceForEdit?.priorVersion?.directConsult"
                  [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                ></app-compare-amendment-output>
              </ng-container>

              <ng-container *ngIf="showInstructionalSupportTypeQuestions(goal.id)">
                <fieldset>
                  <app-radio-group
                    label="Select the type(s) of instructional support"
                    formControlName="instructionalSupportType"
                    [options]="instructionalSupportTypeOptions"
                  ></app-radio-group>
                </fieldset>
                <app-compare-amendment-output
                  *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                  [iepAmendments]="amendments"
                  [priorVersionId]="serviceForEdit?.priorVersionId"
                  [isActive]="serviceForEdit?.isActive"
                  [changedVersionText]="formGroup.get(goal.id + '_service').get('instructionalSupportType').value"
                  [currentVersionText]="serviceForEdit?.priorVersion?.instructionalSupportType"
                  [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                ></app-compare-amendment-output>
              </ng-container>

              <ng-container *ngIf="showTransportationServiceQuestions(goal.id)">
                <app-autocomplete-chips
                  formControlName="transportationServices"
                  [options]="transportationServiceOptions"
                  label="Select the type(s) of transportation services"
                >
                </app-autocomplete-chips>
                <app-compare-amendment-output
                  *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                  [iepAmendments]="amendments"
                  [priorVersionId]="serviceForEdit?.priorVersionId"
                  [isActive]="serviceForEdit?.isActive"
                  [changedVersionText]="formGroup.get(goal.id + '_service').get('transportationServices').value"
                  [currentVersionText]="serviceForEdit?.priorVersion?.transportationServices"
                  [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                ></app-compare-amendment-output>
                <app-textarea
                  *ngIf="hasTransportationServicesOther(goal.id)"
                  label="Please describe transportation services"
                  formControlName="transportationServicesDescription"
                  [isBankableField]="true"
                  [bankableFieldId]="bankableFieldIds.esyGoals.transportationServicesDescription"
                  [bankableFieldChildFname]="childFirstName"
                >
                </app-textarea>
                <app-compare-amendment-output
                  *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                  [iepAmendments]="amendments"
                  [priorVersionId]="serviceForEdit?.priorVersionId"
                  [isActive]="serviceForEdit?.isActive"
                  [changedVersionText]="formGroup.get(goal.id + '_service').get('transportationServicesDescription').value"
                  [currentVersionText]="serviceForEdit?.priorVersion?.transportationServicesDescription"
                  [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                ></app-compare-amendment-output>
                <fieldset>
                  <app-radio-group
                    *ngIf="hasTransportationServicesAttendant(goal.id)"
                    label="Attendant service Type"
                    formControlName="instructionalSupportType"
                    [options]="instructionalSupportTypeOptions"
                  ></app-radio-group>
                </fieldset>
                <app-compare-amendment-output
                  *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                  [iepAmendments]="amendments"
                  [priorVersionId]="serviceForEdit?.priorVersionId"
                  [isActive]="serviceForEdit?.isActive"
                  [changedVersionText]="formGroup.get(goal.id + '_service').get('instructionalSupportType').value"
                  [currentVersionText]="serviceForEdit?.priorVersion?.instructionalSupportType"
                  [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                ></app-compare-amendment-output>
              </ng-container>

              <div class="col-md-12 col-lg-6">
                <app-textarea
                  label="Description"
                  formControlName="description"
                  [isBankableField]="true"
                  [bankableFieldId]="bankableFieldIds.esyGoals.serviceDescription"
                  [bankableFieldChildFname]="childFirstName"
                  [rows]="2"
                ></app-textarea>
                <app-compare-amendment-output
                  *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                  [iepAmendments]="amendments"
                  [priorVersionId]="serviceForEdit?.priorVersionId"
                  [isActive]="serviceForEdit?.isActive"
                  [changedVersionText]="formGroup.get(goal.id + '_service').get('description').value"
                  [currentVersionText]="serviceForEdit?.priorVersion?.description"
                  [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                ></app-compare-amendment-output>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 col-xl-6">
                <div class="display-flex align-items-baseline flex-wrap">
                  <strong class="mr-2">Frequency</strong>
                  <app-number-field
                    label=""
                    formControlName="frequencyNumber"
                    class="input--small mr-2"
                    id="frequencyNumber"
                  ></app-number-field>
                  <app-autocomplete
                    label=""
                    [options]="serviceFrequencyPeriodOptions"
                    formControlName="frequencyPeriod"
                    class="mr-2"
                  ></app-autocomplete>
                  <app-number-field
                    label=""
                    formControlName="frequencyIncrement"
                    class="input--small"
                    id="frequencyIncrement"
                  ></app-number-field>
                  time(s)
                </div>
                <div *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)" class="display-flex align-items-baseline">
                  <app-compare-amendment-output
                    [iepAmendments]="amendments"
                    [priorVersionId]="serviceForEdit?.priorVersionId"
                    [isActive]="serviceForEdit?.isActive"
                    [changedVersionText]="getFrequencyText(formGroup.get(goal.id + '_service').value)"
                    [currentVersionText]="getFrequencyText(serviceForEdit?.priorVersion)"
                    [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                  ></app-compare-amendment-output>
                </div>
              </div>
              <div class="col-md-12 col-xl-3">
                <mat-form-field>
                  <mat-label
                    [ngClass]="{
                      asterisk_input: formGroup
                        .get(goal.id + '_service')
                        .get('projectedStartDate')
                        .hasError('required')
                    }"
                    >Projected Start Date</mat-label
                  >
                  <input
                    matInput
                    [matDatepicker]="projectedStartDate"
                    aria-labelledby="Projected Start Date"
                    attr.aria-required="{{
                      formGroup
                        .get(goal.id + '_service')
                        .get('projectedStartDate')
                        .hasError('required')
                        ? 'true'
                        : 'false'
                    }}"
                    formControlName="projectedStartDate"
                    [min]="minProjectedStartDate"
                    [max]="maxProjectedStartDate(goal)"
                    attr.aria-required="{{
                      formGroup
                        .get(goal.id + '_service')
                        .get('projectedStartDate')
                        .hasError('required')
                        ? 'true'
                        : 'false'
                    }}"
                  />
                  <mat-datepicker-toggle matSuffix [for]="projectedStartDate"></mat-datepicker-toggle>
                  <mat-datepicker #projectedStartDate></mat-datepicker>
                  <mat-error>Invalid date</mat-error>
                </mat-form-field>
                <app-compare-amendment-output
                  *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                  [iepAmendments]="amendments"
                  [priorVersionId]="serviceForEdit?.priorVersionId"
                  [isActive]="serviceForEdit?.isActive"
                  [changedVersionText]="formGroup.get(goal.id + '_service').get('projectedStartDate').value | dateFormat"
                  [currentVersionText]="serviceForEdit?.priorVersion?.projectedStartDate | dateFormat"
                  [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                ></app-compare-amendment-output>
              </div>
              <div class="col-md-12 col-xl-3">
                <mat-form-field>
                  <mat-label
                    [ngClass]="{
                      asterisk_input: formGroup
                        .get(goal.id + '_service')
                        .get('projectedEndDate')
                        .hasError('required')
                    }"
                    >Projected End Date</mat-label
                  >
                  <input
                    matInput
                    [matDatepicker]="projectedEndDate"
                    aria-labelledby="Projected End Date"
                    attr.aria-required="{{
                      formGroup
                        .get(goal.id + '_service')
                        .get('projectedEndDate')
                        .hasError('required')
                        ? 'true'
                        : 'false'
                    }}"
                    formControlName="projectedEndDate"
                    [min]="minProjectedEndDate(goal)"
                    attr.aria-required="{{
                      formGroup
                        .get(goal.id + '_service')
                        .get('projectedStartDate')
                        .hasError('required')
                        ? 'true'
                        : 'false'
                    }}"
                  />
                  <mat-datepicker-toggle matSuffix [for]="projectedEndDate"></mat-datepicker-toggle>
                  <mat-datepicker #projectedEndDate></mat-datepicker>
                  <mat-error>Invalid date</mat-error>
                </mat-form-field>
                <app-compare-amendment-output
                  *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                  [iepAmendments]="amendments"
                  [priorVersionId]="serviceForEdit?.priorVersionId"
                  [isActive]="serviceForEdit?.isActive"
                  [changedVersionText]="formGroup.get(goal.id + '_service').get('projectedEndDate').value | dateFormat"
                  [currentVersionText]="serviceForEdit?.priorVersion?.projectedEndDate | dateFormat"
                  [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                ></app-compare-amendment-output>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 col-xl-6">
                <app-autocomplete-chips
                  label="Provider(s)"
                  formControlName="providers"
                  [options]="providerOptions"
                ></app-autocomplete-chips>
                <app-compare-amendment-output
                  *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                  [iepAmendments]="amendments"
                  [priorVersionId]="serviceForEdit?.priorVersionId"
                  [isActive]="serviceForEdit?.isActive"
                  [changedVersionText]="getProviderNames(formGroup.get(goal.id + '_service').get('providers').value, true)"
                  [currentVersionText]="getProviderNames(serviceForEdit?.priorVersion?.providers)"
                  [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                ></app-compare-amendment-output>
                <app-checkbox-single
                  class="mt-2 mb-2"
                  formControlName="otherProvider"
                  aria-labelledby="Provider is outside of ACHIEVE"
                  label="Provider is outside of ACHIEVE"
                ></app-checkbox-single>
                <ng-container *ngIf="formGroup.get(goal.id + '_service').get('otherProvider').value">
                  <app-text-field label="Provider Name" formControlName="otherProviderName"></app-text-field>
                  <app-compare-amendment-output
                    *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                    [iepAmendments]="amendments"
                    [priorVersionId]="serviceForEdit?.priorVersionId"
                    [isActive]="serviceForEdit?.isActive"
                    [changedVersionText]="formGroup.get(goal.id + '_service').get('otherProviderName').value"
                    [currentVersionText]="serviceForEdit?.priorVersion ? serviceForEdit?.priorVersion?.otherProviderName : null"
                    [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                  ></app-compare-amendment-output>
                  <app-text-field label="Provider Agency" formControlName="otherProviderAgency"></app-text-field>
                  <app-compare-amendment-output
                    *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                    [iepAmendments]="amendments"
                    [priorVersionId]="serviceForEdit?.priorVersionId"
                    [isActive]="serviceForEdit?.isActive"
                    [changedVersionText]="formGroup.get(goal.id + '_service').get('otherProviderAgency').value"
                    [currentVersionText]="serviceForEdit?.priorVersion ? serviceForEdit?.priorVersion?.otherProviderAgency : null"
                    [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                  ></app-compare-amendment-output>
                  <app-text-field label="Provider Role" formControlName="otherProviderRole"></app-text-field>
                  <app-compare-amendment-output
                    *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                    [iepAmendments]="amendments"
                    [priorVersionId]="serviceForEdit?.priorVersionId"
                    [isActive]="serviceForEdit?.isActive"
                    [changedVersionText]="formGroup.get(goal.id + '_service').get('otherProviderRole').value"
                    [currentVersionText]="serviceForEdit?.priorVersion ? serviceForEdit?.priorVersion?.otherProviderRole : null"
                    [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                  ></app-compare-amendment-output>
                </ng-container>
              </div>
              <div class="col-md-12 col-xl-3">
                <mat-form-field>
                  <mat-label>Schedule of Services</mat-label>
                  <input
                    matInput
                    [matDatepicker]="scheduleServicesDate"
                    aria-labelledby="Schedule of Services"
                    formControlName="scheduleServicesDate"
                    [min]="minDate"
                  />
                  <mat-datepicker-toggle matSuffix [for]="scheduleServicesDate"></mat-datepicker-toggle>
                  <mat-datepicker #scheduleServicesDate></mat-datepicker>
                  <mat-error>Invalid date</mat-error>
                </mat-form-field>
              </div>
              <div class="col-md-12 col-xl-2">
                <app-timepicker label="Time" formControlName="scheduleServicesTime"></app-timepicker>
              </div>
              <div class="col-md-12 col-xl-1 justify-content-end align-self-start display-flex">
                <button mat-raised-button aria-label="Add Button" (click)="addToScheduleTable(goal.id, $event)" color="primary">Add</button>
              </div>
            </div>

            <div class="col-md-12 col-xl-6 offset-xl-6">
              <div class="table-overflow app-table">
                <table mat-table [dataSource]="dataSource" class="w-100" matSort>
                  <ng-container matColumnDef="actions">
                    <th mat-header-cell mat-sort-header *matHeaderCellDef class="table-actions mat-column-actions--more">Actions</th>
                    <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
                      <div class="display-flex align-items-center justify-content-start">
                        <button
                          mat-icon-button
                          aria-label="Delete Icon"
                          color="warn"
                          (click)="onScheduledServiceRemove(element)"
                          tabindex="0"
                        >
                          <mat-icon>close</mat-icon>
                        </button>
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="date">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
                    <td mat-cell *matCellDef="let element">
                      <!-- Do not use dateFormat pipe here as it produces a result a day earlier than expected -->
                      {{ element.date | date : shortDateFormat }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="time">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Time</th>
                    <td mat-cell *matCellDef="let element">
                      {{ element.time }}
                    </td>
                  </ng-container>

                  <ng-container matColumnDef="noResults">
                    <td mat-footer-cell *matFooterCellDef colspan="7">
                      <i>No results...</i>
                    </td>
                  </ng-container>

                  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                  <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
                  <tr
                    [hidden]="dataSource?.data?.length > 0"
                    mat-footer-row
                    *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"
                  ></tr>
                </table>
                <mat-paginator [dataSource]="dataSource"></mat-paginator>
              </div>
            </div>

            <div class="position-fix--location">
              <div class="row">
                <div class="col-md-12 col-xl-6">
                  <app-text-field
                    label="Location"
                    [isBankableField]="true"
                    [bankableFieldId]="bankableFieldIds.esyGoals.location"
                    [bankableFieldChildFname]="childFirstName"
                    formControlName="location"
                  ></app-text-field>
                  <app-compare-amendment-output
                    *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                    [iepAmendments]="amendments"
                    [priorVersionId]="serviceForEdit?.priorVersionId"
                    [isActive]="serviceForEdit?.isActive"
                    [changedVersionText]="formGroup.get(goal.id + '_service').get('location').value"
                    [currentVersionText]="serviceForEdit?.priorVersion?.location"
                    [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                  ></app-compare-amendment-output>
                </div>
              </div>

              <div class="row">
                <div class="col-md-12 col-xl-6">
                  <fieldset>
                    <app-radio-group
                      label="Will transportation be provided?"
                      formControlName="transportationNeeded"
                      [options]="yesNoOptions"
                    ></app-radio-group>
                  </fieldset>
                  <app-compare-amendment-output
                    *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                    [iepAmendments]="amendments"
                    [priorVersionId]="serviceForEdit?.priorVersionId"
                    [isActive]="serviceForEdit?.isActive"
                    [changedVersionText]="formGroup.get(goal.id + '_service').get('transportationNeeded').value | boolToYesNo"
                    [currentVersionText]="serviceForEdit?.priorVersion?.transportationNeeded | boolToYesNo"
                    [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                  ></app-compare-amendment-output>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12 col-xl-6">
                <app-textarea
                  [rows]="1"
                  *ngIf="formGroup.get(goal.id + '_service').get('transportationNeeded').value"
                  [isBankableField]="true"
                  [bankableFieldId]="bankableFieldIds.esyGoals.transportationServicesDescription"
                  [bankableFieldChildFname]="childFirstName"
                  label="Explanation"
                  formControlName="transportationServicesDescription"
                ></app-textarea>
                <app-compare-amendment-output
                  *ngIf="
                    formGroup.get(goal.id + '_service').get('transportationNeeded').value &&
                    showCompareOutput(goal.goalType === goalType.Goal, goal)
                  "
                  [iepAmendments]="amendments"
                  [priorVersionId]="serviceForEdit?.priorVersionId"
                  [isActive]="serviceForEdit?.isActive"
                  [changedVersionText]="formGroup.get(goal.id + '_service').get('transportationServicesDescription').value"
                  [currentVersionText]="serviceForEdit?.priorVersion?.transportationServicesDescription"
                  [currentPrefixText]="serviceForEdit?.priorVersion ? 'Current: ' : ''"
                ></app-compare-amendment-output>
              </div>
            </div>
          </mat-card>

          <div
            class="action__row action__row--between"
            [ngClass]="{ 'mb-3': formGroup.get(goal.id + '_service').get('serviceVisible').value }"
          >
            <div>
              <button
                mat-raised-button
                aria-label="Cancel Button"
                *ngIf="formGroup.get(goal.id + '_service').get('serviceVisible').value"
                (click)="onCancelService(goal.id, formGroup.get(goal.id + '_service'))"
                tabindex="0"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                aria-label="Complete and Add Another"
                color="primary"
                (click)="submitService(goal.id)"
                [disabled]="formGroup.get(goal.id + '_service').invalid"
                [disableAfterBusy]="true"
                [isBusy]="activeCall"
                *ngIf="!updatingService"
                tabindex="0"
              >
                Complete and Add Another
              </button>
              <button
                mat-raised-button
                aria-label="Complete and Close"
                color="primary"
                (click)="submitService(goal.id, true)"
                [disabled]="formGroup.get(goal.id + '_service').invalid"
                [disableAfterBusy]="true"
                [isBusy]="activeCall"
                *ngIf="!updatingService"
                tabindex="0"
              >
                Complete and Close
              </button>
              <button
                mat-raised-button
                aria-label="Update"
                color="primary"
                (click)="submitService(goal.id, true, true)"
                [disabled]="formGroup.get(goal.id + '_service').invalid"
                *ngIf="updatingService"
                tabindex="0"
              >
                Update
              </button>
            </div>
          </div>
        </ng-container>

        <ng-container
          [formGroupName]="goal.id + '_support'"
          *ngIf="formGroup.get(goal.id + '_support')?.get('supportActivityVisible').value"
        >
          <h4 class="text-primary mt-0 mb-2">{{ updatingSupport ? 'Update ' : 'Create New ' }} Support/Activity</h4>
          <mat-card class="mt-1">
            <div class="row">
              <div class="col-md-12 col-lg-6">
                <app-autocomplete
                  [options]="activityOptions"
                  formControlName="activitySupportTypeId"
                  label="Support or Activity"
                ></app-autocomplete>
                <app-compare-amendment-output
                  *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                  [iepAmendments]="amendments"
                  [priorVersionId]="supportForEdit?.priorVersionId"
                  [isActive]="supportForEdit?.isActive"
                  [changedVersionText]="getActivityTypeLabel(formGroup.get(goal.id + '_support').get('activitySupportTypeId').value)"
                  [currentVersionText]="getActivityTypeLabel(supportForEdit?.priorVersion?.activitySupportTypeId)"
                  [currentPrefixText]="supportForEdit?.priorVersion ? 'Current: ' : ''"
                ></app-compare-amendment-output>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-lg-6">
                <app-textarea
                  [rows]="1"
                  label="Description"
                  [isBankableField]="true"
                  [bankableFieldId]="bankableFieldIds.esyGoals.activitySupportDescription"
                  [bankableFieldChildFname]="childFirstName"
                  formControlName="description"
                ></app-textarea>
                <app-compare-amendment-output
                  *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                  [iepAmendments]="amendments"
                  [priorVersionId]="supportForEdit?.priorVersionId"
                  [isActive]="supportForEdit?.isActive"
                  [changedVersionText]="formGroup.get(goal.id + '_support').get('description').value"
                  [currentVersionText]="supportForEdit?.priorVersion?.description"
                  [currentPrefixText]="supportForEdit?.priorVersion ? 'Current: ' : ''"
                ></app-compare-amendment-output>
              </div>
              <div class="col-md-12 col-lg-6">
                <app-autocomplete
                  [options]="supportFrequencyPeriodOptions"
                  formControlName="frequencyPeriod"
                  label="Frequency"
                ></app-autocomplete>
                <app-compare-amendment-output
                  *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                  [iepAmendments]="amendments"
                  [priorVersionId]="supportForEdit?.priorVersionId"
                  [isActive]="supportForEdit?.isActive"
                  [changedVersionText]="formGroup.get(goal.id + '_support').get('frequencyPeriod').value"
                  [currentVersionText]="supportForEdit?.priorVersion?.frequencyPeriod"
                  [currentPrefixText]="supportForEdit?.priorVersion ? 'Current: ' : ''"
                ></app-compare-amendment-output>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12 col-lg-6">
                <app-autocomplete-chips
                  [options]="providerOptions"
                  formControlName="providers"
                  label="Provider(s)"
                ></app-autocomplete-chips>
                <app-compare-amendment-output
                  *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                  [iepAmendments]="amendments"
                  [priorVersionId]="supportForEdit?.priorVersionId"
                  [isActive]="supportForEdit?.isActive"
                  [changedVersionText]="getProviderNames(formGroup.get(goal.id + '_support').get('providers').value, true)"
                  [currentVersionText]="getProviderNames(supportForEdit?.priorVersion?.providers)"
                  [currentPrefixText]="supportForEdit?.priorVersion ? 'Current: ' : ''"
                ></app-compare-amendment-output>
                <app-checkbox-single
                  class="mt-2 mb-2"
                  formControlName="otherProvider"
                  aria-labelledby="Provider is outside of ACHIEVE"
                  label="Provider is outside of ACHIEVE"
                ></app-checkbox-single>
                <ng-container *ngIf="formGroup.get(goal.id + '_support').get('otherProvider').value">
                  <app-text-field label="Provider Name" formControlName="otherProviderName"></app-text-field>
                  <app-compare-amendment-output
                    *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                    [iepAmendments]="amendments"
                    [priorVersionId]="supportForEdit?.priorVersionId"
                    [isActive]="supportForEdit?.isActive"
                    [changedVersionText]="formGroup.get(goal.id + '_support').get('otherProviderName').value"
                    [currentVersionText]="supportForEdit?.priorVersion ? supportForEdit?.priorVersion?.otherProviderName : null"
                    [currentPrefixText]="supportForEdit?.priorVersion ? 'Current: ' : ''"
                  ></app-compare-amendment-output>
                  <app-text-field label="Provider Agency" formControlName="otherProviderAgency"></app-text-field>
                  <app-compare-amendment-output
                    *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                    [iepAmendments]="amendments"
                    [priorVersionId]="supportForEdit?.priorVersionId"
                    [isActive]="supportForEdit?.isActive"
                    [changedVersionText]="formGroup.get(goal.id + '_support').get('otherProviderAgency').value"
                    [currentVersionText]="supportForEdit?.priorVersion ? supportForEdit?.priorVersion?.otherProviderAgency : null"
                    [currentPrefixText]="supportForEdit?.priorVersion ? 'Current: ' : ''"
                  ></app-compare-amendment-output>
                  <app-text-field label="Provider Role" formControlName="otherProviderRole"></app-text-field>
                  <app-compare-amendment-output
                    *ngIf="showCompareOutput(goal.goalType === goalType.Goal, goal)"
                    [iepAmendments]="amendments"
                    [priorVersionId]="supportForEdit?.priorVersionId"
                    [isActive]="supportForEdit?.isActive"
                    [changedVersionText]="formGroup.get(goal.id + '_support').get('otherProviderRole').value"
                    [currentVersionText]="supportForEdit?.priorVersion ? supportForEdit?.priorVersion?.otherProviderRole : null"
                    [currentPrefixText]="supportForEdit?.priorVersion ? 'Current: ' : ''"
                  ></app-compare-amendment-output>
                </ng-container>
              </div>
            </div>
          </mat-card>
          <div class="action__row action__row--between">
            <div>
              <button
                mat-raised-button
                aria-label="Cancel Button"
                *ngIf="formGroup.get(goal.id + '_support').get('supportActivityVisible').value"
                (click)="onCancelSupportActivity(goal.id, formGroup.get(goal.id + '_support'))"
                tabindex="0"
              >
                Cancel
              </button>
              <button
                mat-raised-button
                aria-label="Complete and Add Another"
                color="primary"
                (click)="submitSupport(goal.id)"
                [disabled]="formGroup.get(goal.id + '_support').invalid"
                [disableAfterBusy]="true"
                [isBusy]="activeCall"
                *ngIf="!updatingSupport"
                tabindex="0"
              >
                Complete and Add Another
              </button>
              <button
                mat-raised-button
                aria-label="Complete and Close"
                color="primary"
                (click)="submitSupport(goal.id, true)"
                [disabled]="formGroup.get(goal.id + '_support').invalid"
                [disableAfterBusy]="true"
                [isBusy]="activeCall"
                *ngIf="!updatingSupport"
                tabindex="0"
              >
                Complete and Close
              </button>
              <button
                mat-raised-button
                aria-label="Update"
                color="primary"
                (click)="submitSupport(goal.id, true, true)"
                [disabled]="formGroup.get(goal.id + '_support').invalid"
                *ngIf="updatingSupport"
                tabindex="0"
              >
                Update
              </button>
            </div>
          </div>
        </ng-container>
      </form>

      <app-esy-table-display
        [amendments]="amendments"
        [isEditing]="updatingService || updatingSupport"
        [goal]="goal"
        [iepId]="iepId"
        [caseSummary]="caseSummary"
        (editService)="onEditService($event)"
        (editSupport)="onEditSupport($event)"
        (refreshGoals)="refreshGoals()"
        #tables
      ></app-esy-table-display>
    </mat-expansion-panel>
  </mat-accordion>

  <div class="page-bottom-leeway"></div>
</ng-container>
