import { Component, OnInit, ViewChild } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { catchError, finalize } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { CaseListItem } from 'src/app/shared/models/case';
import { District } from 'src/app/shared/models/district';
import { PaginatedList } from 'src/app/shared/models/paginated-list';
import { ServiceCoordinator } from 'src/app/shared/models/service-coordinator';
import { User } from 'src/app/shared/models/user';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { DistrictService } from 'src/app/shared/services/district/district.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { ServiceCoordinatorsComponent } from './service-coordinators/service-coordinators.component';
import { UnassignedCasesComponent } from './unassigned-cases/unassigned-cases.component';

@Component({
  selector: 'app-service-coordinator-assignment',
  templateUrl: './service-coordinator-assignment.component.html',
  styleUrls: ['./service-coordinator-assignment.component.scss'],
})
export class ServiceCoordinatorAssignmentComponent implements OnInit {
  @ViewChild(ServiceCoordinatorsComponent)
  serviceCoordinatorComponent: ServiceCoordinatorsComponent;
  @ViewChild(UnassignedCasesComponent)
  unassignCasesComponent: UnassignedCasesComponent;

  cases: CaseListItem[];
  casesPaginated: PaginatedList<CaseListItem>;
  serviceCoordinators: ServiceCoordinator[];
  districts: District[];
  dmpsUser: User;
  chscUser: User;
  isAeaUser: boolean;
  districtFilter = '';
  activeCall = false;

  selectedServiceCoordinator: ServiceCoordinator;
  selectedCases: CaseListItem[] = [];
  searchCriteria = '';
  orderBy = 'LearnerName';
  orderByDescending = false;
  currentPage = 0;
  pageSize = 20;
  totalRows = 0;

  get assignCaseDisabled() {
    return this.selectedCases.length === 0 || !this.selectedServiceCoordinator;
  }

  get chscDisabled() {
    return this.selectedCases.length === 0 || !!this.selectedServiceCoordinator || !this.chscUser;
  }

  get dmpsDisabled() {
    return this.selectedCases.length === 0 || !!this.selectedServiceCoordinator || !this.dmpsUser;
  }

  constructor(
    private caseService: CaseService,
    private districtService: DistrictService,
    private userService: UserService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.loadData();
    this.isAeaUser = !(this.authService.isChscZeroToThree || this.authService.isDmpsZeroToThree);
  }

  loadData() {
    this.activeCall = true;

    forkJoin([
      this.userService.getDefaultDmpsCaseUser().pipe<User>(
        catchError(() => {
          return of(undefined);
        })
      ),
      this.userService.getDefaultChscCaseUser().pipe<User>(
        catchError(() => {
          return of(undefined);
        })
      ),
    ]).subscribe(([dmpsUser, chscUser]) => {
      this.dmpsUser = dmpsUser;
      this.chscUser = chscUser;

      this.activeCall = false;
    });
  }

  setServiceCoordinator(serviceCoordinator: ServiceCoordinator) {
    this.selectedServiceCoordinator = serviceCoordinator;
  }

  setCases(cases: CaseListItem[]) {
    this.selectedCases = cases;
  }

  async setDistrictFilter(district: string) {
    this.districtFilter = district;
    this.unassignCasesComponent?.selection?.clear();
    this.serviceCoordinatorComponent?.selection?.clear();
  }

  // TODO Clean this up to use a single method
  assignCasesToSC() {
    this.activeCall = true;
    const serviceCoordinator = this.selectedServiceCoordinator;
    const observables: Observable<void>[] = [];
    for (const caseFile of this.selectedCases) {
      observables.push(this.caseService.assignServiceCoordinator(caseFile.id, serviceCoordinator.id));
    }
    this.updateCases(observables, true);
  }

  assignCasesToDMPS() {
    this.activeCall = true;
    const observables: Observable<void>[] = [];
    for (const caseFile of this.selectedCases) {
      observables.push(this.caseService.assignServiceCoordinator(caseFile.id, this.dmpsUser.id));
    }
    this.updateCases(observables);
  }

  updateCases(observables: Observable<void>[], scAssigned = false) {
    forkJoin(observables)
      .pipe(
        finalize(async () => {
          if (scAssigned) await this.serviceCoordinatorComponent.loadData();

          await this.unassignCasesComponent.loadData();
          this.unassignCasesComponent?.selection?.clear();
          this.serviceCoordinatorComponent?.selection?.clear();
          this.selectedCases = [];
          this.selectedServiceCoordinator = null;
          this.activeCall = false;
        })
      )
      .subscribe();
  }
}
