import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthUser } from 'src/app/auth/auth-user';
import { AuthService } from 'src/app/auth/auth.service';
import { ReferralService } from 'src/app/child-find/early-access-referral/early-access-referral.service';
import { Referral } from 'src/app/child-find/early-access-referral/referral';
import { Aea } from 'src/app/shared/models/aea';
import { FamilyMemberType } from 'src/app/shared/models/learner';

@Component({
  selector: 'app-thank-you-letter',
  templateUrl: './thank-you-letter.component.html',
  styleUrls: ['./thank-you-letter.component.scss'],
})
export class ThankYouLetterComponent implements OnInit {
  referral: Referral;
  user: AuthUser;
  aea: Aea;
  recipient: string;
  familyMemberType = FamilyMemberType;

  constructor(public authService: AuthService, private referralService: ReferralService, private route: ActivatedRoute) {}

  date = new Date();

  ngOnInit(): void {
    this.user = this.authService.user;
    if (this.authService.user) {
      this.aea = this.authService.user.aeas[0];
    }
    this.referralService.getReferral(this.route.snapshot.paramMap.get('referralId')).subscribe((referralResponse) => {
      this.referral = referralResponse;
      switch (this.referral.referralSource) {
        case FamilyMemberType.Parent1: {
          this.recipient = this.referral.parentInfo.parent1Name;
          break;
        }
        case FamilyMemberType.Parent2: {
          this.recipient = this.referral.parentInfo.parent2Name;
          break;
        }
        case FamilyMemberType.LivesWith1: {
          this.recipient = this.referral.familyInfo.livesWith1Name;
          break;
        }
        case FamilyMemberType.LivesWith2: {
          this.recipient = this.referral.familyInfo.livesWith2Name;
          break;
        }
        default:
          this.recipient = this.referral.referralSourceInfo.referralSourceName;
      }
    });
  }

  onPrint() {
    window.print();
  }

  onClose() {
    window.close();
  }
}
