<!--suppress XmlDuplicatedId -->
<div class="prefill">
  <div class="prefill__input">
    <mat-form-field class="w-100">
      <mat-label
        [ngClass]="{
          asterisk_input: control.hasError('required')
        }"
        >{{ label }}</mat-label
      >
      <input
        matInput
        type="text"
        *ngIf="inputType === 'text'"
        [class]="klass"
        id="{{ controlId }}"
        [formControl]="control"
        [placeholder]="placeholder"
        [attr.maxLength]="maxLength > 0 ? maxLength + 1 : null"
        [readonly]="readOnly"
        [attr.disabled]="disabledState ? true : null"
        (blur)="internalBlur($event)"
        attr.aria-required="{{ control.hasError('required') ? 'true' : 'false' }}"
      />
      <span matSuffix *ngIf="isBankableField && !disabledState && !readOnly">
        <button type="button" mat-icon-button aria-label="Bankable Text Icon" (click)="openBankableFieldOptions()" tabindex="0">
          <mat-icon class="bankable-icon">bookmark_add</mat-icon>
        </button>
      </span>
      <span matSuffix *ngIf="dataSourceValue && !undoValue && !disabledState">
        <button type="button" mat-icon-button aria-label="QR Arrow Symbol" (click)="replaceWithSourceValue()" tabindex="0">
          <mat-icon>call_merge</mat-icon>
        </button>
      </span>
      <span matSuffix (click)="undoReplaceWithSourceValue()" *ngIf="undoValue"> Undo </span>
      <span matPrefix *ngIf="useInputGroup">
        <ng-content select="[prepend]"></ng-content>
      </span>
      <span matSuffix *ngIf="useInputGroup">
        <ng-content select="[append]"></ng-content>
      </span>

      <input
        *ngIf="inputType === 'password'"
        matInput
        type="password"
        [class]="klass"
        id="{{ controlId }}"
        [formControl]="control"
        [placeholder]="placeholder"
        [readonly]="readOnly"
        [attr.disabled]="disabledState ? true : null"
        (blur)="internalBlur($event)"
      />
      <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
      <mat-error>
        <app-field-error-display [control]="control"></app-field-error-display>
      </mat-error>
    </mat-form-field>
  </div>
  <div
    class="input-qr-text__wrapper"
    [ngClass]="{ 'input-qr-text__wrapper--hint': disabledState || (!hint && control.valid) }"
    *ngIf="dataSourceValue"
  >
    QR: {{ dataSourceValue }}
  </div>
</div>
