<div class="header__row">
  <h3>Surrogate Contact Information</h3>
  <button
    *ngIf="hasWorkableCase && !isEditing && !isFirstSurrogate"
    mat-raised-button
    aria-label="Add Surrogate Information"
    color="primary"
    (click)="onAdd()"
    tabindex="0"
  >
    Add Surrogate Information
  </button>
</div>

<div [ngClass]="{ 'card__together-container': showForm }">
  <mat-card [ngClass]="{ 'card__together card__together--top': showForm }" *ngIf="showForm">
    <ng-container *ngIf="showSurrogateEndForm" [formGroup]="formGroup">
      <div class="row align-items-center flex-wrap">
        <div class="col-auto">
          <h4 class="text-primary mb-1">Ending Surrogacy for {{ formGroup.get('name').value }}</h4>
        </div>
        <div class="col">
          <app-date-picker controlName="discontinuedOn" label="Date that surrogacy was no longer needed"></app-date-picker>
        </div>
        <div class="col display-flex justify-content-end align-items-center">
          <button mat-raised-button aria-label="Cancel Button" class="mr-2" (click)="onCancel()" tabindex="0">Cancel</button>
          <button
            mat-raised-button
            aria-label="Save Button"
            color="primary"
            (click)="onSave(true)"
            [disabled]="formGroup.invalid"
            [disableAfterBusy]="formGroup.invalid"
            [isBusy]="activeCall"
            tabindex="0"
          >
            Save
          </button>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="isEditing || isFirstSurrogate" [formGroup]="formGroup">
      <div class="row">
        <div class="col">
          <app-date-picker label="Date determined surrogate needed" controlName="determinedNeededOn"></app-date-picker>
        </div>
        <div class="col">
          <app-autocomplete
            label="Reason for surrogate"
            formControlName="reasonForSurrogateId"
            [options]="reasonForSurrogateOptions"
          ></app-autocomplete>
        </div>
        <div class="col">
          <app-date-picker label="Appointed On" controlName="appointedOn"></app-date-picker>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <app-text-field label="Name" formControlName="name" [maxLength]="stringSizes.small"></app-text-field>
        </div>
        <div class="col">
          <app-text-field label="Address" formControlName="address" [maxLength]="stringSizes.small"></app-text-field>
        </div>
        <div class="col">
          <app-text-field label="Email" formControlName="email" [maxLength]="stringSizes.small"></app-text-field>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-text-field
            label="AEA Director/Designee Name"
            formControlName="aeaDirectorName"
            [maxLength]="stringSizes.small"
          ></app-text-field>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-4">
          <fieldset>
            <app-radio-group label="Limited Information" formControlName="limitedAccess" [options]="yesNoOptions"></app-radio-group>
          </fieldset>
        </div>
        <div *ngIf="formGroup.get('limitedAccess').value" class="col-md-12 col-lg-6 col-xl-4">
          <app-textarea label="Explain" formControlName="limitedAccessExplanation" [maxLength]="stringSizes.extraLarge"></app-textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-checkbox-single
            formControlName="noPortalAccess"
            aria-labelledby="No Portal Access"
            label="No Portal Access"
          ></app-checkbox-single>
          <app-checkbox-single formControlName="validated" aria-labelledby="Validated" label="Validated"></app-checkbox-single>
        </div>
      </div>
      <div class="row justify-content-end display-flex align-items-center mr-2">
        <button mat-raised-button aria-label="Cancel Button" class="mr-2" (click)="onCancel()" tabindex="0">Cancel</button>
        <button
          mat-raised-button
          aria-label="Save Button"
          color="primary"
          (click)="onSave()"
          [disabled]="formGroup.invalid"
          [disableAfterBusy]="formGroup.invalid"
          [isBusy]="activeCall"
          tabindex="0"
        >
          Save
        </button>
      </div>
    </ng-container>
  </mat-card>

  <mat-card class="py-0" [ngClass]="{ 'card__together card__together--bottom': showForm }">
    <div class="table-overflow">
      <table mat-table class="w-100" matSort [dataSource]="dataSource">
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="mat-column-actions--more"></th>
          <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
            <ng-container *ngIf="!showSurrogateEndForm && !isEditing">
              <ng-container *ngIf="hasWorkableCase && !element.discontinuedOn">
                <button aria-label="Edit Icon" mat-icon-button color="primary" (click)="onEdit(element)" tabindex="0">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button aria-labelledby="End" color="warn" (click)="onEnd(element)" tabindex="0">
                  <mat-icon aria-labelledby="End">event_busy</mat-icon>
                </button>
              </ng-container>
              <button mat-icon-button aria-label="View Icon" color="primary" (click)="onViewMore(element)" tabindex="0">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let element">
            {{ element.email }}
          </td>
        </ng-container>

        <ng-container matColumnDef="appointedOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Appointed On</th>
          <td mat-cell *matCellDef="let element">
            {{ element.appointedOn | dateFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="discontinuedOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Discontinued On</th>
          <td mat-cell *matCellDef="let element">
            {{ element.discontinuedOn | dateFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th mat-sort-header mat-header-cell *matHeaderCellDef>Portal Status</th>
          <td mat-cell *matCellDef="let element">
            {{ getPortalStatus(element) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="validated">
          <th mat-sort-header mat-header-cell *matHeaderCellDef>Validated</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.familyUserId === null || (!!element.familyUserId && element.accessRevoked)">
              <mat-checkbox
                [checked]="element.validated"
                [ngClass]="{ 'read-only-checkbox': !element.validated, 'read-only-checkbox-checked': element.validated }"
                [disabled]="!element.validated"
              ></mat-checkbox>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="familyAccess">
          <th mat-sort-header mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="element.familyUserId === null && element.email && !element.noPortalAccess && canInviteFamilyMember">
              <button
                mat-raised-button
                aria-label="Share"
                color="primary"
                (click)="onInviteFamilyMember(element.id)"
                tabindex="0"
                [isBusy]="activeCall"
              >
                Invite To Portal
              </button>
            </ng-container>
            <ng-container *ngIf="element.familyUserId !== null && canManageFamilyMemberAccess">
              <button
                mat-icon-button
                color="primary"
                (click)="$event.stopPropagation()"
                [matMenuTriggerFor]="actionsMenu"
                tabindex="0"
                aria-label="Quick Access Menu"
              >
                <mat-icon>more_vert</mat-icon>
              </button>
              <mat-menu #actionsMenu="matMenu">
                <button
                  *ngIf="element.isUserAccountActive"
                  class="mr-1"
                  aria-label="Reset Password"
                  (click)="resetPassword(element)"
                  mat-menu-item
                >
                  Reset Password
                </button>
                <button
                  *ngIf="!element.accessRevoked"
                  (click)="revokeAccess(element)"
                  class="mr-1"
                  aria-label="Revoke Access"
                  mat-menu-item
                >
                  Revoke Access
                </button>
                <button
                  *ngIf="element.accessRevoked"
                  (click)="reactivateAccess(element)"
                  class="mr-1"
                  aria-label="Reactivate Access"
                  mat-menu-item
                >
                  Reactivate Access
                </button>
              </mat-menu>
            </ng-container>
          </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef colspan="7">
            <i>No results...</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayColumns"></tr>
        <tr [hidden]="dataSource.data.length > 0" mat-footer-row *matFooterRowDef="dataSource.data.length === 0 ? ['noResults'] : []"></tr>
      </table>

      <mat-paginator [dataSource]="dataSource"></mat-paginator>
    </div>
    <button
      *ngIf="dataSource.data.length > 0"
      class="mb-3"
      mat-raised-button
      aria-labelledby="Print Surrogate Letter"
      color="primary"
      (click)="onPrintSurrogateLetter()"
    >
      Print Surrogate Letter
    </button>
  </mat-card>
</div>
