import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AppPermissions } from '../../permissions';
import { AuthService } from '../../auth/auth.service';

@Directive({
  selector: '[appAuthForPermission]',
})
export class AuthForPermissionsDirective {
  private hasView = false;

  @Input() set appAuthForPermission(data: { permission: AppPermissions[] }) {
    if (this.authService.isAllowedByPermissionAlone(...data.permission) && !this.hasView) {
      this.vcRef.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!this.authService.isAllowedByPermissionAlone(...data.permission) && this.hasView) {
      this.vcRef.clear();
      this.hasView = false;
    }
  }

  constructor(private templateRef: TemplateRef<any>, private vcRef: ViewContainerRef, private authService: AuthService) {}
}
