import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { EcoArea } from 'src/app/evaluation/models/evaluation';
import { IntakeType } from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { EcoAreaService } from 'src/app/shared/services/eco-areas/eco-area.service';
import { MemoryStorageService } from 'src/app/shared/services/memory-storage/memory-storage.service';
import { EcoMatrixPartBService } from '../eval-details-part-b/eco-matrix/services/eco-matrix-partb.service';
import { AgeExpect } from '../eval-eco-matrix/models/eco-matrix-item';
import { EcoMatrixService } from '../eval-eco-matrix/services/eco-matrix.service';
import { EcoRating, EcoRatingType } from './eco-rating';
import { EcoRatingService } from './eco-rating.service';

@Component({
  selector: 'app-eco-wizard',
  templateUrl: './eco-wizard.component.html',
  styleUrls: ['./eco-wizard.component.scss'],
})
export class EcoWizardComponent implements OnInit, OnDestroy {
  @Input() data: EcoWizardData;
  @Output() suggestedEcoRatingScore = new EventEmitter();
  @Output() ecoRatingScore = new EventEmitter();
  @Output() saveStatusChange = new EventEmitter<boolean>();
  formGroup = this.fb.group(
    {
      ageExpectedRegardOutcome: [],
      immediateFoundationalSkills: [],
      consideredAgeExpectedSetting: [],
      whatExtentFunctionSetting: [],
      doesAnyoneHaveConcerns: [],
      usesSkillsThatAreNotImmediate: [],
      occasionalUseOfSkill: [],
      usesImmediateSkillsAll: [],
      suggestedScore: [],
      score: [],
    },
    { updateOn: 'change' }
  );
  aeCount: number;
  fCount: number;
  ifCount: number;
  subscription = new Subscription();

  get ageExpectedRegardOutcome() {
    return this.formGroup.get('ageExpectedRegardOutcome').value;
  }

  get immediateFoundationalSkills() {
    return this.formGroup.get('immediateFoundationalSkills').value;
  }

  get consideredAgeExpectedSetting() {
    return this.formGroup.get('consideredAgeExpectedSetting').value;
  }

  get whatExtentFunctionSetting() {
    return this.formGroup.get('whatExtentFunctionSetting').value;
  }

  get doesAnyoneHaveConcerns() {
    return this.formGroup.get('doesAnyoneHaveConcerns').value;
  }

  get usesSkillsThatAreNotImmediate() {
    return this.formGroup.get('usesSkillsThatAreNotImmediate').value;
  }

  get occasionalUseOfSkill() {
    return this.formGroup.get('occasionalUseOfSkill').value;
  }

  get usesImmediateSkillsAll() {
    return this.formGroup.get('usesImmediateSkillsAll').value;
  }

  ecoAreaId: string;
  ecoArea: EcoArea;
  evaluationId: string;
  type: EcoRatingType;
  typeId: string;
  ecoRating: EcoRating;
  suggestedEcoRatingText: string;
  learnersFirstName: string;
  intakeType: IntakeType;
  amendmentId: string;
  isReady = false;

  ecoDescriptionOptions: KeyValuePair[] = [];
  private _saveQueue$ = new Subject<boolean>();
  private _closeAfterSave = false;

  saving = false;

  constructor(
    private route: ActivatedRoute,
    private ecoAreaService: EcoAreaService,
    private ecoRatingService: EcoRatingService,
    private ecoMatrixPartCService: EcoMatrixService,
    private ecoMatrixPartBService: EcoMatrixPartBService,
    private readonly storage: MemoryStorageService,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    if (this.data) {
      this.amendmentId = this.data.amendmentId;
      this.ecoAreaId = this.data.ecoAreaId;
      if (this.type !== null) {
        this.type = this.data.type;
      } else {
        this.type = EcoRatingType.Evaluation;
      }

      switch (this.type) {
        case EcoRatingType.Iep:
          this.typeId = this.data.iepId;
          break;
        case EcoRatingType.Ifsp:
          this.typeId = this.data.ifspId;
          break;
        case EcoRatingType.Evaluation:
          this.typeId = this.data.evaluationId;
          break;
        case EcoRatingType.Transition:
          this.typeId = this.data.transitionId;
          break;
        case EcoRatingType.Case:
          this.typeId = this.data.caseId;
          break;
        case EcoRatingType.FinalEcoPkCase:
          this.typeId = this.data.finalEcoPkCaseId;
          break;
        default:
          this.typeId = undefined;
      }

      this.evaluationId = this.data.evaluationId;
      this.learnersFirstName = this.data.learnersFirstName;
      this.intakeType = this.data.intakeType;
    } else {
      this.ecoAreaId = this.route.snapshot.paramMap.get('ecoAreaId');
      this.evaluationId = this.route.snapshot.parent.paramMap.get('evaluationId');
      this.type = EcoRatingType.Evaluation;
      this.typeId = this.evaluationId;
      const evaluation = this.storage.getKey('currentEvaluation', true);
      this.learnersFirstName = evaluation?.learner?.firstName;
      this.intakeType = evaluation.intakeType;
    }

    this.saveStatusChange.emit(true);
    forkJoin([
      this.ecoAreaService.get(),
      this.ecoRatingService.get(this.typeId, this.ecoAreaId, this.type, this.data?.amendmentId),
    ]).subscribe({
      next: ([ecoAreas, ecoRating]) => {
        this.ecoArea = ecoAreas.find((x) => x.id === this.ecoAreaId);
        if (ecoRating) {
          this.ecoRating = ecoRating;
          this.formGroup.patchValue(ecoRating, { emitEvent: false });
          this.getSuggestedRating();
        }
      },
      error: () => {},
      complete: () => {
        this.saveStatusChange.emit(false);
        this.isReady = true;
      },
    });

    if (this.evaluationId) {
      const doAeIfFCounts = (ecoMatrixItems) => {
        const ecoAreaItems = ecoMatrixItems.filter((i) => i.ecoAreaId === this.ecoAreaId);
        this.aeCount = ecoAreaItems.filter((i) => i.ageExpect === AgeExpect.AE).length;
        this.fCount = ecoAreaItems.filter((i) => i.ageExpect === AgeExpect.F).length;
        this.ifCount = ecoAreaItems.filter((i) => i.ageExpect === AgeExpect.IF).length;
      };

      if (this.intakeType === IntakeType.PartC) {
        this.ecoMatrixPartCService.getAllItems(this.evaluationId).subscribe(doAeIfFCounts);
      } else {
        this.ecoMatrixPartBService.getAllItems(this.evaluationId).subscribe(doAeIfFCounts);
      }
    }

    this.subscription.add(
      this.formGroup.valueChanges.subscribe(() => {
        this.startSave();
        this.queueSave();
      })
    );

    this.subscription.add(
      this._saveQueue$
        .pipe(
          tap((close) => {
            if (close) this._closeAfterSave = true;
          }),
          debounceTime(3000),
          switchMap(() => this.saveEco())
        )
        .subscribe({
          next: (res) => {
            if (this._closeAfterSave) {
              window.close();
            }
            if (res.id && !this.ecoRating?.id) {
              this.ecoRating = res;
              if (this.data) {
                this.data.ecoRatingId = res.id;
              }
            }

            this.finishSave();
          },
          error: (err) => this.finishSave(),
          complete: () => this.finishSave(),
        })
    );

    this.subscription.add(
      this.formGroup.get('score').valueChanges.subscribe(() => this.ecoRatingScore.emit(this.formGroup.get('score').value))
    );

    this.getEcoDescriptionOptions();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  queueSave(close?: boolean) {
    this._saveQueue$.next(close || false);
  }

  onYes(question: any) {
    if (this.formGroup.controls[question].value === true) {
      this.formGroup.controls[question].setValue(null);
      if (question === 'ageExpectedRegardOutcome') {
        Object.keys(this.formGroup.controls).forEach((key) => {
          this.formGroup.get(key).setValue(null);
        });
      } else if (question === 'immediateFoundationalSkills') {
        this.formGroup.get('occasionalUseOfSkill').setValue(null);
        this.formGroup.get('usesImmediateSkillsAll').setValue(null);
      } else if (question === 'consideredAgeExpectedSetting') {
        this.formGroup.get('doesAnyoneHaveConcerns').setValue(null);
      }
    } else {
      this.formGroup.controls[question].setValue(true);
    }
    this.calculateScore();
  }

  onNo(question: any) {
    if (this.formGroup.controls[question].value === false) {
      this.formGroup.controls[question].setValue(null);
      if (question === 'ageExpectedRegardOutcome') {
        Object.keys(this.formGroup.controls).forEach((key) => {
          this.formGroup.get(key).setValue(null);
        });
      } else if (question === 'immediateFoundationalSkills') {
        this.formGroup.get('usesSkillsThatAreNotImmediate').setValue(null);
      } else if (question === 'consideredAgeExpectedSetting') {
        this.formGroup.get('whatExtentFunctionSetting').setValue(null);
      }
    } else {
      this.formGroup.controls[question].setValue(false);
    }
    this.calculateScore();
  }

  calculateScore(): void {
    const lastQuestions = {
      one: this.usesSkillsThatAreNotImmediate,
      two: this.occasionalUseOfSkill,
      three: this.usesImmediateSkillsAll,
      fourFive: this.whatExtentFunctionSetting,
      sixSeven: this.doesAnyoneHaveConcerns,
    };

    this.formGroup.get('suggestedScore').setValue(null);

    if (lastQuestions.one === true) {
      this.formGroup.get('suggestedScore').setValue(1);
    }

    if (lastQuestions.two === true) {
      this.formGroup.get('suggestedScore').setValue(2);
    }

    if (lastQuestions.three === true) {
      this.formGroup.get('suggestedScore').setValue(3);
    }

    if (lastQuestions.fourFive === false) {
      this.formGroup.get('suggestedScore').setValue(4);
    }

    if (lastQuestions.fourFive === true) {
      this.formGroup.get('suggestedScore').setValue(5);
    }

    if (lastQuestions.sixSeven === false) {
      this.formGroup.get('suggestedScore').setValue(6);
    }

    if (lastQuestions.sixSeven === true) {
      this.formGroup.get('suggestedScore').setValue(7);
    }

    this.getSuggestedRating();
  }

  sameAsSuggested(decisionKey: number): boolean {
    return decisionKey === this.formGroup.get('suggestedScore').value;
  }

  getEcoDescriptionOptions() {
    this.ecoDescriptionOptions = [
      new KeyValuePair(
        1,
        `${this.learnersFirstName} has very early skills in this area, meaning that ${this.learnersFirstName} has the skills we would expect of a much younger child.`
      ),
      new KeyValuePair(
        2,
        `${this.learnersFirstName} has skills like that of a much
      younger child in this area, but is beginning to show some of the early
      skills that are necessary for development of more advanced
      skills.`
      ),
      new KeyValuePair(
        3,
        `${this.learnersFirstName} uses many early skills in this
      area that are necessary for development of more advanced skills, but
      is not yet using skills expected for this age.`
      ),
      new KeyValuePair(
        4,
        `${this.learnersFirstName} shows occasional use of some
      age-expected skills in this area, but has more skills of a younger
      child.`
      ),
      new KeyValuePair(
        5,
        `${this.learnersFirstName} shows many age-expected skills
      in this area, but continues to show some skills of a younger
      child.`
      ),
      new KeyValuePair(
        6,
        `${this.learnersFirstName} has the skills that we would
      expect for this age in this area, but there are some concerns that
      should be monitored.`
      ),
      new KeyValuePair(
        7,
        `${this.learnersFirstName} has all of the skills that we
      would expect for this age in this area and there are no
      concerns.`
      ),
    ];
  }

  private getSuggestedRating() {
    this.suggestedEcoRatingText = this.ecoRatingService.getSuggestedEcoRatingText(
      this.formGroup.get('suggestedScore').value,
      this.learnersFirstName
    );
    this.suggestedEcoRatingScore.emit(this.formGroup.get('suggestedScore').value);
  }

  private saveEco() {
    const ecoRating: EcoRating = { ...this.ecoRating, ...this.formGroup.value };
    if (this.amendmentId) {
      ecoRating.amendmentId = this.amendmentId;
    }

    return this.ecoRatingService.save(this.typeId, this.ecoAreaId, ecoRating, this.type);
  }

  private startSave() {
    this.saving = true;
    this.saveStatusChange.emit(true);
  }

  private finishSave() {
    this.saving = false;
    this.saveStatusChange.emit(false);
  }
}

export interface EcoWizardData {
  ecoRatingScore: number;
  suggestedEcoRatingScore: number;
  amendmentId: string;
  intakeType: IntakeType;
  learnersFirstName: string;
  evaluationId?: string;
  ifspId?: string;
  iepId?: string;
  transitionId?: string;
  caseId?: string;
  type: EcoRatingType;
  ecoAreaId: string;
  finalEcoPkCaseId?: string;
  ecoRatingId?: string;
}
