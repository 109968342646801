import { AfterContentChecked, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { forkJoin, merge, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, map, pairwise, startWith } from 'rxjs/operators';
import { IepType, IepView, TrialPlacementTarget } from 'src/app/iep/models/iep';
import { IepServiceDto, ServiceActivityTypeQuestion } from 'src/app/iep/models/iep-service';
import { IepServiceService } from 'src/app/iep/services/iep-service.service';
import { IepService } from 'src/app/iep/services/iep.service';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { dateDurationInDays, maxFrequencyValue, shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { Aea } from 'src/app/shared/models/aea';
import { CaseSummary } from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { ServiceActivityType, ServiceActivityTypeCategory } from 'src/app/shared/models/service-activity-type';
import { ProviderUser } from 'src/app/shared/models/user';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { conditionalValidator } from 'src/app/shared/validators';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { LearnerSummary } from '../../../../../shared/models/learner';
import { DateFormatPipe } from '../../../../../shared/pipes/date-transform.pipe';
import { DeactivationService, DeactivationStatus } from '../../../../../shared/services/deactivation.service';
import { FormService } from '../../../../../shared/services/form.service';
import { IepAmendment } from '../../../../models/iep-amendment';
import { IepServicesTableComponent } from '../shared/iep-services-table/iep-services-table.component';

export enum CustomServiceDate {
  PKOnly = 'PKOnly',
  KOnly = 'KOnly',
  BothPKAndK = 'BothPKAndK',
}

@Component({
  selector: 'app-iep-ssaa-services',
  templateUrl: './iep-ssaa-services.component.html',
  styleUrls: ['./iep-ssaa-services.component.scss'],
})
export class IepSsaaServicesComponent extends BaseComponent implements OnInit, AfterContentChecked, OnDestroy {
  // #region vars
  @Input() isGoalsPage = false;
  @Input() amendmentId: string;
  @Input() amendments: IepAmendment[];
  @Input() amendingService: IepServiceDto;
  @Input() caseSummary: CaseSummary;
  @Input() learner: LearnerSummary;
  @Input() isPK = false;
  @Input() iepIncludesTrialPlacement = false;
  @Input() trialPlacementTarget: TrialPlacementTarget;
  @Input() iep: IepView;
  trialPlacementTargetEnum = TrialPlacementTarget;
  startingKDuringIep = false;
  unModifiedService: IepServiceDto;
  activeCall = false;

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentIsFinalized() {
    return this.amendment?.finalizeDate !== null;
  }

  get hasAmendment() {
    return !!this.amendmentId;
  }

  get hasPriorVersion() {
    return !!this.amendingService?.priorVersionId;
  }

  get requireAgency() {
    if (!this.formGroup.get('providers').value) {
      return false;
    }

    let returnValue = false;
    this.formGroup.get('providers').value.some((provider) => {
      const providerOption = this.providers.find((x) => x.id === provider);
      if (!!providerOption && providerOption.aeas !== null && providerOption?.aeas?.length > 0) {
        if (this.selectedSASType && (this.selectedSASType.partBMedicaidBillable || this.selectedSASType.isWeightedService)) {
          returnValue = true;
          if (this.selectedSASType.partBMedicaidBillableConditional) {
            const transportationServices = this.formGroup.get('transportationServices').value;
            if (transportationServices.length === 1 && transportationServices.indexOf('Other') >= -1) {
              returnValue = false;
            }

            if (this.selectedSASType.id === this.sasTypes.find((x) => x.code === 'IN0').id) {
              returnValue = false;
            }
          }
        }
      }
    });

    return returnValue;
  }

  @ViewChild('formDirective') private formDirective: NgForm;
  @ViewChild('serviceForEdit') serviceForEdit: ElementRef<any>;
  @ViewChild('servicelist') servicelist: IepServicesTableComponent;

  service: IepServiceDto;
  private stopAutosaving$ = new Subject();
  public iepId: string;
  public caseId: string;
  public serviceId: string;
  isNew = true;
  selectedSASType: ServiceActivityType;
  shortDateFormat = shortDateFormat;
  providers: ProviderUser[] = [];

  formGroup = new FormGroup({
    isComplete: new FormControl(false),
    serviceTypeId: new FormControl('', Validators.required),
    directConsult: new FormControl(null),
    paraprofessionalSupports: new FormControl([]),
    instructionalSupportType: new FormControl(null),
    hasBehaviorInterventionPlan: new FormControl(null),
    hasHealthCarePlan: new FormControl(null),
    providers: new FormControl([], Validators.required),
    otherProvider: new FormControl(null),
    otherProviderName: new FormControl(null),
    otherProviderAgency: new FormControl(null),
    otherProviderRole: new FormControl(null),
    agencyId: new FormControl(
      null,
      conditionalValidator(() => this.requireAgency, Validators.required)
    ),
    includesTrialPlacement: new FormControl(null),
    transportationServices: new FormControl([]),
    transportationServicesDescription: new FormControl(null),
    description: new FormControl('', Validators.required),
    frequencyNumber: new FormControl('', [Validators.required, Validators.min(1), Validators.max(15600)]),
    frequencyIncrement: new FormControl('minutes', Validators.required),
    frequencyPeriod: new FormControl('', Validators.required),
    amountRemovedFromGeneralEducation: new FormControl('', [
      conditionalValidator(() => !this.disableAmountRemoved, Validators.required),
      Validators.min(0),
    ]),
    projectedStartDate: new FormControl(
      null,
      conditionalValidator(() => this.showServiceDates && !this.startingKDuringIep, Validators.required)
    ),
    projectedEndDate: new FormControl(
      null,
      conditionalValidator(() => this.showServiceDates && !this.startingKDuringIep, Validators.required)
    ),
    customServiceDate: new FormControl(
      '',
      conditionalValidator(() => this.startingKDuringIep, Validators.required)
    ),
    taggedForPwn: new FormControl(null),
  });

  sasTypes: ServiceActivityType[] = [];
  sasOptions: KeyValuePair[] = [];
  providerOptions: KeyValuePair[] = [];
  agencyOptions: KeyValuePair[] = [];
  yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];
  frequencyIncrementOptions: KeyValuePair[] = [new KeyValuePair('minutes', 'minutes'), new KeyValuePair('hours', 'hours')];
  frequencyPeriodOptions: KeyValuePair[] = [
    new KeyValuePair('per day', 'per day'),
    new KeyValuePair('per week', 'per week'),
    new KeyValuePair('per month', 'per month'),
  ];
  instructionalSupportTypeOptions: KeyValuePair[] = [new KeyValuePair('Individual', 'Individual'), new KeyValuePair('Shared', 'Shared')];
  directConsultOptions: KeyValuePair[] = [new KeyValuePair('Direct', 'Direct'), new KeyValuePair('Consult', 'Consult')];
  transportationServiceOptions: KeyValuePair[] = [
    new KeyValuePair('Attendant service', 'Attendant service'),
    new KeyValuePair('Specially equipped vehicle', 'Specially equipped vehicle'),
    new KeyValuePair('Special route in district', 'Special route in district'),
    new KeyValuePair('Special route out of district', 'Special route out of district'),
    new KeyValuePair('Other', 'Other'),
  ];
  paraprofessionalSupportOptions: KeyValuePair[] = [
    new KeyValuePair('BehaviorSafety', 'Behavior/Safety'),
    new KeyValuePair('Health', 'Health'),
    new KeyValuePair('Physical', 'Physical'),
  ];

  customDateOptions: KeyValuePair[] = [
    new KeyValuePair(CustomServiceDate.PKOnly, 'PK only'),
    new KeyValuePair(CustomServiceDate.KOnly, 'K only'),
    new KeyValuePair(CustomServiceDate.BothPKAndK, 'Both PK and K'),
  ];

  autosaveSubscription = new Subscription();
  save = new Observable<boolean>((observer) => {
    const done = () => {
      this.isSaving = false;
      observer.next(true);
    };

    if (!this.formGroup.dirty || this.isSaving || !this.canSave) {
      done();
      return;
    }
    this.isSaving = true;

    const service = {
      id: this.serviceId,
      ...this.formGroup.value,
      amendmentId: this.amendmentId,
    };

    service.providers = this.providers
      .filter((x) => this.formGroup.get('providers').value.includes(x.id))
      .map((p) => {
        return {
          userId: p.id,
        };
      });

    this.iepServiceService.update(this.iepId, service).subscribe((updateResult) => {
      this.serviceId = updateResult.value;
      // this.formGroup.reset(this.formGroup.value, { emitEvent: false });
      done();
    });
  });
  // #endregion
  // #region getters
  get serviceActivityTypeQuestion(): typeof ServiceActivityTypeQuestion {
    return ServiceActivityTypeQuestion;
  }

  get hasParaprofessionalSupportHealth() {
    return (
      this.formGroup.get('paraprofessionalSupports')?.value &&
      (this.formGroup.get('paraprofessionalSupports')?.value as string[])?.findIndex((x) => x === 'Health') > -1
    );
  }

  get hasParaprofessionalSupportBehaviorSafety() {
    return (
      this.formGroup.get('paraprofessionalSupports')?.value &&
      (this.formGroup.get('paraprofessionalSupports')?.value as string[])?.findIndex((x) => x === 'BehaviorSafety') > -1
    );
  }

  get hasTransportationServicesOther() {
    return (
      this.formGroup.get('transportationServices')?.value &&
      (this.formGroup.get('transportationServices')?.value as string[])?.findIndex((x) => x === 'Other') > -1
    );
  }

  get hasTransportationServicesAttendant() {
    return (
      this.formGroup.get('transportationServices')?.value &&
      (this.formGroup.get('transportationServices')?.value as string[])?.findIndex((x) => x === 'Attendant service') > -1
    );
  }

  get showServiceDates() {
    return !!this.iep?.startDate || !!this.iep?.endDate;
  }

  get endDateDayToAdd() {
    if (!!this.iep && this.iep.iepType === IepType.Interim) {
      return 50;
    }
    const endDate = dayjs(this.iep.endDate);
    const future = endDate.add(1, 'year').subtract(1, 'day').toDate();
    return dateDurationInDays(endDate.toDate(), future);
  }

  get minProjectedEndDate() {
    let returnDate = null;
    if (this.formGroup.get('projectedStartDate').value) {
      returnDate = dayjs(new DateFormatPipe().transform(this.formGroup.get('projectedStartDate').value))
        .add(1, 'day')
        .toDate();
    }

    return returnDate;
  }

  get maxProjectedEndDate() {
    let returnDate = null;
    if (this.iep?.endDate) {
      returnDate = dayjs(new DateFormatPipe().transform(this.iep.endDate)).toDate();
    }
    return returnDate;
  }

  get minProjectedStartDate() {
    let returnDate = null;
    if (this.iep?.startDate) {
      returnDate = dayjs(new DateFormatPipe().transform(this.iep.startDate)).toDate();
    }
    return returnDate;
  }

  get disableAmountRemoved() {
    const excludedCodes = ['PP0', 'TA0', 'FT0', 'TR0', 'SA0'];
    const selectedServiceCode = this.sasTypes.find((x) => x.id === this.formGroup.get('serviceTypeId').value)?.code;
    return excludedCodes.findIndex((code) => code === selectedServiceCode) > -1;
  }
  // #endregion

  constructor(
    private readonly iepService: IepService,
    private readonly iepServiceService: IepServiceService,
    private readonly route: ActivatedRoute,
    private readonly notificationService: NotificationService,
    private readonly userService: UserService,
    private readonly router: Router,
    private readonly cd: ChangeDetectorRef,
    private readonly formService: FormService,
    private readonly caseService: CaseService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
    this.saveSubscription = this.formGroup.valueChanges;
  }

  async ngOnInit() {
    this.caseId = this.route.parent?.snapshot.paramMap.get('caseId');
    this.iepId = this.route.parent?.snapshot.paramMap.get('iepId');
    if (!this.caseId) {
      this.caseId = this.route.snapshot.paramMap.get('caseId');
    }
    if (!this.iepId) {
      this.iepId = this.route.snapshot.paramMap.get('iepId');
    }
    this.startingKDuringIep = this.caseSummary?.activeIepIsPKToK;

    this.iepServiceService.setAmendingService$.subscribe((result) => {
      this.amendingService = result;
    });

    await this.caseService.refreshCaseSummary(this.caseId);
    this.caseSummary = this.caseService.caseSummary;
    this.learner = this.caseService.caseSummary.learner;

    forkJoin([
      this.iepService.getPartBSASTypes(ServiceActivityTypeCategory.Service),
      this.userService.getServiceActivityProviders(this.learner?.id).pipe(
        catchError(() => {
          return of([]);
        })
      ),
    ]).subscribe(([sasTypes, providers]) => {
      this.formGroup.controls.customServiceDate.valueChanges.subscribe((v) => this.onServiceDateChange(v));
      this.providers = providers;
      this.sasTypes = sasTypes;
      this.sasOptions = this.sasTypes.map((x) => new KeyValuePair(x.id, x.label));
      this.providerOptions = providers.map((x) => new KeyValuePair(x.id, x.fullName));

      if (this.showServiceDates) {
        if (!this.hasAmendment) {
          this.formGroup.get('projectedStartDate').setValue(this.iep?.startDate, { emitEvent: false });
        }
        this.formGroup.get('projectedEndDate').setValue(this.iep?.endDate, { emitEvent: false });
      }

      this.resetTrialPlacementControl();

      this.setupFormSubscriptions();

      if (this.amendingService) {
        this.onEditService(this.amendingService);
        this.startAutosaving();
      } else {
        this.isNew = true;
        this.startAutosaving();
      }
    });
  }

  ngAfterContentChecked() {
    // This isn't ideal because we now run the entire cycle twice, but it does resolve this issue of the expression changed error
    // https://github.com/angular/angular/issues/23657
    this.cd.detectChanges();
  }

  canDeactivate(): Observable<DeactivationStatus> | Promise<DeactivationStatus> | DeactivationStatus {
    return (super.canDeactivate() as Observable<DeactivationStatus>).pipe(
      map((status) => {
        return status === DeactivationStatus.Accepted && this.formGroup?.dirty ? DeactivationStatus.NeedsConfirmation : status;
      })
    );
  }

  onServiceDateChange(value) {
    const startDate = this.iep?.startDate;
    switch (value) {
      case 'PKOnly':
        // If meeting with roll call is done, calc start date
        this.formGroup.get('projectedStartDate').setValue(startDate ? startDate : null, { emitEvent: false });
        this.formGroup.get('projectedEndDate').setValue(this.learner.kindergartenStartDate, { emitEvent: false });
        break;
      case 'KOnly':
        // If meeting with roll call is done, calc end date
        this.formGroup.get('projectedStartDate').setValue(this.learner.kindergartenStartDate, { emitEvent: false });
        this.formGroup.get('projectedEndDate').setValue(startDate ? dayjs(startDate).add(this.endDateDayToAdd, 'day') : null, {
          emitEvent: false,
        });
        break;
      case 'BothPKAndK':
        // If meeting with roll call is done, calc start/end date
        this.formGroup.get('projectedStartDate').setValue(startDate ? startDate : null, { emitEvent: false });
        this.formGroup.get('projectedEndDate').setValue(startDate ? dayjs(startDate).add(this.endDateDayToAdd, 'day') : null, {
          emitEvent: false,
        });
        break;
      default:
        break;
    }
  }

  viewSummary() {
    const config: NewWindowConfig = {
      path: `cases/${this.caseId}/iep/${this.iepId}/services/summary`,
      popup: true,
      width: '1480px',
    };
    openNewWindow(config);
  }

  onEditService(service: IepServiceDto) {
    this.unModifiedService = Object.assign({}, service);
    this.isNew = false;
    service = this.updateServiceStartAndEndDate(service);
    this.service = service;
    this.serviceId = service.id;
    const patchObj = Object.assign({}, this.service);
    patchObj.providers = patchObj.providers.map((x) => x.userId);
    this.selectedSASType = this.sasTypes.find((x) => x.id === patchObj?.serviceTypeId);
    this.formGroup.patchValue(patchObj);
    if (this.formGroup.value.isComplete) {
      this.formGroup.controls.isComplete.setValue(false, { emitEvent: false });
      this.formGroup.controls.isComplete.markAsDirty();
    }
    this.cd.detectChanges();
    if (this.formGroup.controls.otherProvider?.value) {
      this.formGroup.get('providers').setValidators(null);
      this.formGroup.get('otherProviderName').setValidators(Validators.required);
    }
    this.formGroup.get('otherProviderName').updateValueAndValidity({ emitEvent: false });
    this.formGroup.get('providers').updateValueAndValidity({ emitEvent: false });
    this.formService.getAllInvalidControls(this.formGroup).forEach((x) => x.markAsTouched());
    this.scrollToSelectMethod(this.serviceForEdit);
  }

  updateServiceStartAndEndDate(service: IepServiceDto): IepServiceDto {
    if (!service.projectedStartDate && this.minProjectedStartDate && !service.projectedEndDate && this.maxProjectedEndDate) {
      service.projectedStartDate = this.minProjectedStartDate;
      service.projectedEndDate = this.maxProjectedEndDate;
    }
    return service;
  }

  saveAndClose() {
    this.activeCall = true;
    this.save.subscribe((x) => {
      this.resetForm();
      this.iepServiceService.serviceClose.next();
      this.scrollToSelectMethod(this.serviceForEdit);
      this.activeCall = false;
    });
  }

  onSubmit(callback?: () => void) {
    this.activeCall = true;
    if (this.formGroup.valid) {
      this.formGroup.controls.isComplete.setValue(true, { emitEvent: false });
      this.formGroup.controls.isComplete.markAsDirty();
      this.save.subscribe((x) => {
        this.iepServiceService.serviceClose.next();
        this.isNew = true;
        this.serviceId = null;
        this.resetForm();
        this.scrollToSelectMethod(this.serviceForEdit);
        this.activeCall = false;
      });
    }
  }

  submit() {
    this.onSubmit();
  }

  submitAndAddGoal() {
    this.onSubmit();
    this.router.navigate([`cases/${this.caseId}/iep/${this.iepId}/goals`]);
  }

  onDelete() {
    if (!this.serviceId || (this.hasAmendment && this.hasPriorVersion)) {
      this.stopAutosaving$.next(undefined);
      this.iepServiceService.serviceClose.next();
      this.resetForm();
      return;
    }

    const deleteIt = () => {
      this.stopAutosaving$.next(undefined);
      this.iepServiceService.deleteService(this.iepId, this.serviceId).subscribe(() => {
        this.notificationService.success('Service deleted');
        this.resetForm();
        this.isNew = true;
      });
    };

    if (this.isNew) {
      deleteIt();
    } else {
      this.notificationService.confirmation('Are you sure you want to delete this Service?', deleteIt);
    }
  }

  onCancel() {
    const closeFormCallback = () => {
      this.iepServiceService.serviceClose.next();
      this.isNew = true;
      this.serviceId = null;
      this.resetForm();
      this.scrollToSelectMethod(this.serviceForEdit);
    };

    if (this.isNew && !this.hasPriorVersion) {
      closeFormCallback();
    } else {
      if (this.unModifiedService) {
        this.onEditService(this.unModifiedService);
      }
      closeFormCallback();
    }
  }

  resetForm() {
    this.formGroup.reset();
    this.formDirective.resetForm();
    this.formGroup.get('frequencyIncrement').setValue('minutes');
    this.formGroup.get('providers').setValue([]);
    this.formGroup.get('transportationServices').setValue([]);
    this.service = null;
    this.serviceId = null;
    if (this.showServiceDates) {
      this.formGroup.get('projectedStartDate').setValue(this.iep?.startDate, { emitEvent: false });
      this.formGroup.get('projectedEndDate').setValue(this.iep?.endDate, { emitEvent: false });
    }
    this.formGroup.get('isComplete').setValue(false, { emitEvent: false });
    this.formGroup.get('amountRemovedFromGeneralEducation').clearValidators();

    this.resetTrialPlacementControl();
  }

  resetTrialPlacementControl() {
    const isAllTrialPlacementSelected =
      this.iepIncludesTrialPlacement && this.trialPlacementTarget === this.trialPlacementTargetEnum.AllItems;
    this.formGroup.controls.includesTrialPlacement.setValue(isAllTrialPlacementSelected);
  }

  get showParaprofessionalSupportQuestions() {
    return this.serviceHasQuestion(this.serviceActivityTypeQuestion.ParaprofessionalSupports);
  }

  get showDirectConsultQuestions() {
    return this.serviceHasQuestion(this.serviceActivityTypeQuestion.DirectConsult);
  }

  get showInstructionalSupportTypeQuestions() {
    return this.serviceHasQuestion(this.serviceActivityTypeQuestion.InstructionalSupportType);
  }

  get showTransportationServiceQuestions() {
    return this.serviceHasQuestion(this.serviceActivityTypeQuestion.TransportationServices);
  }

  public get canSave(): boolean {
    return !('typeahead' in (this.formGroup.controls.serviceTypeId.errors ?? {}));
  }

  tag(tagFlag: boolean) {
    this.formGroup.controls.taggedForPwn.setValue(tagFlag);
    this.formGroup.controls.taggedForPwn.updateValueAndValidity();
    this.formGroup.markAsDirty();
  }

  setupFormSubscriptions() {
    this.subscriptions.add(
      this.formGroup.controls.providers.valueChanges.subscribe((change) => {
        const numberOfAgencies = this.agencyOptions?.length;
        this.agencyOptions = [];
        const tempAgencyList: Aea[] = [];

        change?.forEach((providerId) => {
          const providerAgencies = this.providers.find((x) => x.id === providerId)?.aeas;
          providerAgencies?.forEach((providerAgency) => {
            if (!tempAgencyList.find((x) => x.id === providerAgency.id)) {
              tempAgencyList.push(providerAgency);
            }
          });
        });
        this.agencyOptions = tempAgencyList.map((x) => new KeyValuePair(x.id, x.name));

        // Set the only agency available
        if (this.agencyOptions?.length === 1) {
          this.formGroup.controls.agencyId.setValue(this.agencyOptions.find((x) => x.key === this.agencyOptions[0]?.key).key);
          // If there has been an actual change in options, set agencyId to null
        } else if (numberOfAgencies !== this.agencyOptions?.length) {
          this.formGroup.controls.agencyId.setValue(null);
        }
      })
    );
    this.subscriptions.add(
      this.formGroup.get('serviceTypeId').valueChanges.subscribe(() => {
        this.selectedSASType = this.sasTypes.find((x) => x.id === this.formGroup.get('serviceTypeId').value);
        this.resetAdditionalQuestions();

        if (this.showParaprofessionalSupportQuestions) {
          this.formGroup.get('paraprofessionalSupports').setValidators([Validators.required]);
        } else {
          this.formGroup.get('paraprofessionalSupports').clearValidators();
        }

        if (this.showDirectConsultQuestions) {
          this.formGroup.get('directConsult').setValidators([Validators.required]);
        } else {
          this.formGroup.get('directConsult').clearValidators();
        }
        this.formGroup.get('directConsult').updateValueAndValidity();

        if (this.showInstructionalSupportTypeQuestions) {
          this.formGroup.get('instructionalSupportType').setValidators([Validators.required]);
        } else {
          this.formGroup.get('instructionalSupportType').clearValidators();
        }

        if (this.showTransportationServiceQuestions) {
          this.formGroup.get('transportationServices').setValidators([Validators.required]);
        } else {
          this.formGroup.get('transportationServices').clearValidators();
        }

        this.formGroup.get('amountRemovedFromGeneralEducation').setValue(null);
      })
    );
    this.subscriptions.add(
      this.formGroup.get('paraprofessionalSupports').valueChanges.subscribe((result) => {
        if (this.hasParaprofessionalSupportBehaviorSafety) {
          this.formGroup.get('hasBehaviorInterventionPlan').setValidators([Validators.required]);
        } else {
          this.formGroup.get('hasBehaviorInterventionPlan').clearValidators();
        }
        if (this.hasParaprofessionalSupportHealth) {
          this.formGroup.get('hasHealthCarePlan').setValidators([Validators.required]);
        } else {
          this.formGroup.get('hasHealthCarePlan').clearValidators();
        }
      })
    );
    this.subscriptions.add(
      this.formGroup.get('transportationServices').valueChanges.subscribe((result) => {
        if (this.hasTransportationServicesOther) {
          this.formGroup.get('transportationServicesDescription').setValidators([Validators.required]);
        } else {
          this.formGroup.get('transportationServicesDescription').clearValidators();
        }
        if (this.hasTransportationServicesAttendant) {
          this.formGroup.get('instructionalSupportType').setValidators([Validators.required]);
        } else {
          this.formGroup.get('instructionalSupportType').clearValidators();
          this.formGroup.get('instructionalSupportType').setValue(null);
        }
      })
    );
    this.subscriptions.add(
      this.formGroup.get('frequencyNumber').valueChanges.subscribe((value) => {
        if (!this.disableAmountRemoved) {
          this.formGroup
            .get('amountRemovedFromGeneralEducation')
            .setValidators([Validators.required, Validators.min(0), Validators.max(value)]);
        }
        this.formGroup.updateValueAndValidity();
      })
    );
    this.subscriptions.add(
      this.formGroup.get('otherProvider').valueChanges.subscribe((isOther) => {
        const providersControl = this.formGroup.get('providers');
        const providerNameControl = this.formGroup.get('otherProviderName');
        if (isOther) {
          providersControl.clearValidators();
          providerNameControl.setValidators(Validators.required);
        } else {
          providerNameControl.setValue(null);
          this.formGroup.get('otherProviderAgency').setValue(null);
          this.formGroup.get('otherProviderRole').setValue(null);
          providersControl.setValidators(Validators.required);
          providerNameControl.clearValidators();
        }
        providersControl.updateValueAndValidity({
          emitEvent: false,
        });
        providerNameControl.updateValueAndValidity({ emitEvent: false });
      })
    );
    this.subscriptions.add(
      this.formGroup
        .get('serviceTypeId')
        .valueChanges.pipe(startWith(''), pairwise())
        .subscribe(([prev, next]) => {
          if (prev === next) {
            return;
          }
          this.providerOptions = this.providers
            .filter((x) => x.services.map((s) => s.id).includes(next))
            .map((x) => new KeyValuePair(x.id, x.fullName));
          this.formGroup.get('providers').setValue([]);
          this.formGroup.get('agencyId').setValue(null);
          this.cd.detectChanges();
        })
    );
    this.subscriptions.add(
      merge(
        this.formGroup.get('frequencyNumber').valueChanges,
        this.formGroup.get('frequencyIncrement').valueChanges,
        this.formGroup.get('frequencyPeriod').valueChanges
      ).subscribe((value) => {
        const frequencyNumber = this.formGroup.get('frequencyNumber');
        const frequencyIncrement = this.formGroup.get('frequencyIncrement');
        const frequencyPeriod = this.formGroup.get('frequencyPeriod');
        const maxFrequency = maxFrequencyValue(frequencyIncrement.value, frequencyPeriod.value);
        frequencyNumber.clearValidators();
        frequencyNumber.setValidators([Validators.required, Validators.min(1), Validators.max(maxFrequency)]);
        frequencyNumber.updateValueAndValidity({ emitEvent: false });
      })
    );
    this.cd.detectChanges();
  }

  resetAdditionalQuestions() {
    this.resetAdditionalQuestionValidators();
    this.formGroup.get('paraprofessionalSupports').setValue([], { emitEvent: false });
    this.formGroup.get('hasHealthCarePlan').setValue(null, { emitEvent: false });
    this.formGroup.get('directConsult').setValue(null, { emitEvent: false });
    this.formGroup.get('instructionalSupportType').setValue(null, { emitEvent: false });
    this.formGroup.get('transportationServices').setValue([], { emitEvent: false });
    this.formGroup.get('transportationServicesDescription').setValue(null, { emitEvent: false });
  }

  resetAdditionalQuestionValidators() {
    this.formGroup.get('paraprofessionalSupports').clearValidators();
    this.formGroup.get('hasHealthCarePlan').clearValidators();
    this.formGroup.get('directConsult').clearValidators();
    this.formGroup.get('instructionalSupportType').clearValidators();
    this.formGroup.get('transportationServices').clearValidators();
    this.formGroup.get('transportationServicesDescription').clearValidators();
  }

  serviceHasQuestion(question: ServiceActivityTypeQuestion) {
    if (!this.selectedSASType) {
      return false;
    }

    const questionIndex = this.selectedSASType.additionalQuestions.findIndex((x) => x.question === question);
    if (questionIndex > -1) {
      return true;
    }
    return false;
  }

  scrollToSelectMethod(element: ElementRef<any>) {
    element?.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  getInstructionalSupportTypeLabel(value) {
    return this.instructionalSupportTypeOptions.find((x) => x.key === value)?.value;
  }

  getServiceTypeLabel(value) {
    return this.sasOptions.find((x) => x.key === value)?.value;
  }

  getFrequencyPeriodLabel(value) {
    return this.frequencyPeriodOptions.find((x) => x.key === value)?.value;
  }

  getFrequencyIncrementLabel(value) {
    return this.frequencyIncrementOptions.find((x) => x.key === value)?.value;
  }

  getAmountRemovedText(amountRemoved, frequencyIncrement, frequencyPeriod) {
    if (!amountRemoved) {
      return '';
    }
    let label = null;
    label = amountRemoved + ' ' + this.getFrequencyIncrementLabel(frequencyIncrement) + ' ' + this.getFrequencyPeriodLabel(frequencyPeriod);
    return label;
  }

  getFrequencyOptionLabel(frequencyNumber, frequencyIncrement, frequencyPeriod) {
    if (!frequencyNumber) {
      return '';
    }
    let label = null;

    label =
      frequencyNumber + ' ' + this.getFrequencyIncrementLabel(frequencyIncrement) + ' ' + this.getFrequencyPeriodLabel(frequencyPeriod);
    return label;
  }

  getTransportationServicesLabel(services) {
    const serviceLabels = [];

    if (services) {
      services.forEach((_service) => {
        const serviceFind = this.transportationServiceOptions.find((x) => x.key === _service);

        if (serviceFind) {
          serviceLabels.push(serviceFind.value);
        }
      });
    }

    return serviceLabels.join(', ');
  }

  getProviderLabels(providers) {
    const providerLabels = [];

    if (providers) {
      providers.forEach((_provider) => {
        const providerFind = this.providerOptions.find((x) => x.key === _provider);

        if (providerFind) {
          providerLabels.push(providerFind.value);
        }
      });
    }

    return providerLabels.join(', ');
  }

  getPriorProviderLabels(providers) {
    const providerLabels = [];

    if (providers) {
      providers.forEach((_provider) => {
        const providerFind = this.providerOptions.find((x) => x.key === _provider.userId);

        if (providerFind) {
          providerLabels.push(providerFind.value);
        }
      });
    }

    return providerLabels.join(', ');
  }

  ngOnDestroy() {
    this.autosaveSubscription.unsubscribe();
    if (this.formGroup.dirty) {
      this.onSubmit();
    }
    super.ngOnDestroy();
  }

  lastFinalizedDate(amendmentId: string) {
    if (this.amendments && this.amendments.length > 0) {
      const amendment = this.amendments.find((a) => a.id === amendmentId);
      return amendment?.finalizeDate;
    }
    return null;
  }
}
