<div class="display-flex align-items-center justify-content-between card-spacing-top mb-2">
  <h3 class="my-0">
    {{ dsDecisionHelp.TeamMembersInvolved }}
  </h3>
</div>
<div class="row card-spacing-bottom">
  <div class="col">
    <mat-card class="mb-0">
      <app-help-directional-text>
        <p>
          Document the team members who are involved in the decision by adding them individually or
          <a
            (click)="onOpenHelp($event, helpSection.DsForm, dsDecisionHelp.SelectingTeam)"
            class="text-underline text-lower"
            tabindex="0"
            role="link"
            >{{ dsDecisionHelp.SelectingTeam }}</a
          >.
        </p>
      </app-help-directional-text>

      <app-team-member
        [team]="{ id: null, name: null, users: dsForm.participants }"
        [learnerId]="learnerId"
        [intakeType]="intakeType.PartB"
        (teamEvent)="updateParticipants($event)"
        (removeTeamUserEvent)="removeTeamUser($event)"
      ></app-team-member>

      <app-ds-nonachieve-team-member
        [dsFormId]="dsForm.id"
        [nonAchieveParticipants]="dsForm.nonAchieveParticipants"
      ></app-ds-nonachieve-team-member>

      <div class="mt-2">
        <h4 class="mb-0 mt-3">Family Members Involved in Decision</h4>
        <form [formGroup]="formGroup">
          <mat-checkbox
            *ngFor="let fm of familyMembers"
            [checked]="familyMemberIsParticipant(fm)"
            (change)="updateFamilyParticipant(fm, $event.checked)"
            attr.aria-label="Check {{ fm.fullName }}!"
            >{{ fm.fullName }}</mat-checkbox
          >
          <ng-container *ngIf="surrogates?.length > 0">
            <mat-checkbox
              *ngFor="let surrogate of surrogates"
              [checked]="surrogateIsParticipant(surrogate)"
              (change)="updateSurrogateParticipant(surrogate, $event.checked)"
              attr.aria-label="Check {{ surrogate.name }}"
              >{{ surrogate.name }}</mat-checkbox
            >
          </ng-container>
        </form>
      </div>
    </mat-card>
  </div>
</div>

<mat-card class="pt-1">
  <div class="display-flex align-items-baseline">
    <label
      [ngClass]="{
        asterisk_input: formGroup.controls.parentOriginated.hasError('required')
      }"
      class="vertical-label display-block"
      attr.aria-label="{{ formGroup.controls.parentOriginated.hasError('required') && 'Control is Required' }}"
    >
      Did the parents request an evaluation?
    </label>
    <button
      mat-icon-button
      aria-label="Clear Icon"
      type="button"
      color="primary"
      (click)="onClearRadio('parentOriginated')"
      class="text-sm"
      tabindex="0"
    >
      <i class="fas fa-undo" aria-label="Clear Icon"></i>
    </button>
  </div>
  <mat-radio-group [formControl]="parentOriginated" aria-labelledby="Parent Originated Yes or No" role="radiogroup">
    <mat-radio-button [value]="true" aria-label="Yes">Yes</mat-radio-button>
    <mat-radio-button [value]="false" aria-label="No">No</mat-radio-button>
  </mat-radio-group>
</mat-card>

<h3 class="card-spacing-top mb-2">Conditions to Consider</h3>
<mat-card>
  <app-help-directional-text [flat]="true">
    <p class="my-0">
      All relevant, available data reviewed may be used in guiding the team’s consideration of the four conditions for suspecting a
      disability. A disability is suspected and triggers the need for a Full and Individual Initial Evaluation when any one of the following
      conditions exists:
    </p>
  </app-help-directional-text>
</mat-card>

<button
  class="mt-3"
  mat-raised-button
  aria-label="Copy from Transition Planning"
  type="button"
  color="primary"
  (click)="onFromTransition()"
  *ngIf="dsForm.transitionValues"
  tabindex="0"
>
  Copy from Transition Planning
</button>

<mat-horizontal-stepper
  class="stepper mt-3"
  labelPosition="bottom"
  [disableRipple]="true"
  (selectionChange)="onStepperSelectionChange($event)"
  [formGroup]="conditions"
>
  <mat-step [stepControl]="diagnosisOfCondition">
    <ng-template matStepLabel>
      <div class="stepper__label">
        <app-validated-icon [isValid]="isDiagnosisOfConditionValid">
          <app-diagnosis-condition-icon></app-diagnosis-condition-icon>
        </app-validated-icon>
        <p>Diagnosis of Condition</p>
      </div>
    </ng-template>
    <app-ds-form-question [questionFormGroup]="diagnosisOfCondition" role="status">
      <span class="title" [ngClass]="{ asterisk_input: !diagnosisOfCondition.valid }"> Diagnosis of Condition </span>
      <div class="body">
        <p class="mt-1">
          <strong>
            Are there
            <a
              class="text-underline text-lower"
              (click)="onOpenHelp($event, helpSection.DsForm, dsFormHelp.DataToSuggest)"
              tabindex="0"
              role="link"
              attr.aria-label="{{ dsFormHelp.DataToSuggest }}"
              >{{ dsFormHelp.DataToSuggest }}
            </a>
            that:
          </strong>
        </p>
        <ul class="ds-list">
          <li>
            <p>
              the learner has a
              <a
                class="text-underline text-lower"
                (click)="onOpenHelp($event, helpSection.DsForm, dsFormHelp.DiagnosisOfCondition)"
                tabindex="0"
                role="link"
              >
                {{ dsFormHelp.DiagnosisOfCondition }}
              </a>
              that is specifically listed in IDEA's disability categories or is clearly included within one of IDEA's disability categories,
              <i>and</i>
            </p>
          </li>
          <li>
            <p>
              the condition has a
              <a
                class="text-underline text-lower"
                (click)="onOpenHelp($event, helpSection.DsForm, dsFormHelp.DiscernibleEffect)"
                tabindex="0"
                role="link"
              >
                {{ dsFormHelp.DiscernibleEffect }}
              </a>
              on educational performance
            </p>
          </li>
        </ul>
      </div>
    </app-ds-form-question>

    <div class="actions mt-2">
      <button mat-raised-button color="accent" matStepperNext aria-label="Next Tab" tabindex="0">Next</button>
      <button
        [hidden]="!!requiresRationaleComments"
        mat-raised-button
        aria-label="Save and Close"
        color="primary"
        [isBusy]="activeCall"
        [disableAfterBusy]="false"
        (click)="saveAndClose.emit(formGroup)"
        tabindex="0"
      >
        Save and Close
      </button>
      <button
        [hidden]="!!requiresRationaleComments"
        mat-raised-button
        aria-label="Finalize"
        color="primary"
        [disabled]="!formGroup.valid"
        [disableAfterBusy]="!formGroup.valid"
        [isBusy]="activeCall"
        (click)="onSubmit()"
        tabindex="0"
      >
        Finalize
      </button>
    </div>
  </mat-step>

  <mat-step [stepControl]="uniqueComparedToPeers">
    <ng-template matStepLabel>
      <div class="stepper__label">
        <app-validated-icon [isValid]="isUniqueComparedToPeersValid">
          <mat-icon aria-labelledby="Unique Compared to Peers">people</mat-icon>
        </app-validated-icon>
        <p>Unique Compared to Peers</p>
      </div>
    </ng-template>
    <app-ds-form-question [questionFormGroup]="uniqueComparedToPeers" role="status">
      <span class="title" [ngClass]="{ asterisk_input: !uniqueComparedToPeers.valid }"> Unique Compared to Peers </span>
      <div class="body">
        <p class="mt-1">
          <strong
            >Are there
            <a
              class="text-underline text-lower"
              (click)="onOpenHelp($event, helpSection.DsForm, dsFormHelp.DataToSuggest)"
              tabindex="0"
              role="link"
              attr.aria-label="{{ dsFormHelp.DataToSuggest }}"
              >{{ dsFormHelp.DataToSuggest }}
            </a>
            that:</strong
          >
        </p>
        <ul class="ds-list">
          <li>
            <p>The learner is not meeting standards, <i>and</i></p>
          </li>
          <li>
            <p>
              The learner is
              <a
                class="text-underline text-lower"
                (click)="onOpenHelp($event, helpSection.DsForm, dsFormHelp.Unique)"
                tabindex="0"
                role="link"
              >
                {{ dsFormHelp.Unique }}</a
              >
              when compared to peers?
            </p>
          </li>
        </ul>
      </div>
    </app-ds-form-question>

    <div class="actions mt-2">
      <button mat-raised-button aria-label="Previous" color="accent" matStepperPrevious tabindex="0">Previous</button>
      <button mat-raised-button aria-label="Next" color="accent" matStepperNext tabindex="0">Next</button>
      <button
        [hidden]="!!requiresRationaleComments"
        mat-raised-button
        aria-label="Save and Close"
        color="primary"
        [isBusy]="activeCall"
        (click)="saveAndClose.emit(formGroup)"
        tabindex="0"
      >
        Save and Close
      </button>
      <button
        [hidden]="!!requiresRationaleComments"
        mat-raised-button
        aria-label="Finalize"
        color="primary"
        [disabled]="!formGroup.valid"
        [disableAfterBusy]="!formGroup.valid"
        [isBusy]="activeCall"
        (click)="onSubmit()"
        tabindex="0"
      >
        Finalize
      </button>
    </div>
  </mat-step>

  <mat-step [stepControl]="progressionStandards">
    <ng-template matStepLabel>
      <div class="stepper__label">
        <app-validated-icon [isValid]="isProgressionStandardsValid">
          <mat-icon aria-labelledby="Open Graph">auto_graph</mat-icon>
        </app-validated-icon>
        <p>Progression and Meeting Standards</p>
      </div>
    </ng-template>

    <app-ds-form-question [questionFormGroup]="progressionStandards" role="status">
      <span class="title" [ngClass]="{ asterisk_input: !progressionStandards.valid }"> Progression and Meeting Standards </span>
      <div class="body">
        <p class="mt-1">
          <strong
            >Are there
            <a
              class="text-underline text-lower"
              (click)="onOpenHelp($event, helpSection.DsForm, dsFormHelp.DataToSuggest)"
              tabindex="0"
              role="link"
              attr.aria-label="{{ dsFormHelp.DataToSuggest }}"
              >{{ dsFormHelp.DataToSuggest }}
            </a>
            that:</strong
          >
        </p>
        <ul class="ds-list">
          <li>
            <p>
              the learner has received high quality instruction (general instruction and evidence-based supplemental instruction),
              <i>and</i>
            </p>
          </li>
          <li>
            <p>
              the learner's performance is
              <a
                class="text-underline text-lower"
                (click)="onOpenHelp($event, helpSection.DsForm, dsFormHelp.NotMeetingStandards)"
                tabindex="0"
                role="link"
              >
                {{ dsFormHelp.NotMeetingStandards }}</a
              >
              and is not progressing towards meeting standards?
            </p>
          </li>
        </ul>
      </div>
    </app-ds-form-question>

    <div class="actions mt-2">
      <button mat-raised-button aria-label="Previous" color="accent" matStepperPrevious tabindex="0">Previous</button>

      <button mat-raised-button aria-label="Next" color="accent" matStepperNext tabindex="0">Next</button>
      <button
        [hidden]="!!requiresRationaleComments"
        mat-raised-button
        aria-label="Save and Close"
        color="primary"
        [isBusy]="activeCall"
        (click)="saveAndClose.emit(formGroup)"
        tabindex="0"
      >
        Save and Close
      </button>
      <button
        [hidden]="!!requiresRationaleComments"
        mat-raised-button
        aria-label="Finalize"
        color="primary"
        [disabled]="!formGroup.valid"
        [disableAfterBusy]="!formGroup.valid"
        [isBusy]="activeCall"
        (click)="onSubmit()"
        tabindex="0"
      >
        Finalize
      </button>
    </div>
  </mat-step>

  <mat-step [stepControl]="requiresContinuedEffort">
    <ng-template matStepLabel>
      <div class="stepper__label">
        <app-validated-icon [isValid]="isRequiresContinuedEffortValid">
          <app-team-effort-icon></app-team-effort-icon>
        </app-validated-icon>
        <p>Requires Continued Substantial Effort</p>
      </div>
    </ng-template>

    <app-ds-form-question [questionFormGroup]="requiresContinuedEffort" role="status">
      <span class="title" [ngClass]="{ asterisk_input: !requiresContinuedEffort.valid }"> Requires Continued Substantial Effort </span>
      <div class="body">
        <p class="mt-1">
          <strong
            >Are there
            <a
              class="text-underline text-lower"
              (click)="onOpenHelp($event, helpSection.DsForm, dsFormHelp.DataToSuggest)"
              tabindex="0"
              role="link"
              attr.aria-label="{{ dsFormHelp.DataToSuggest }}"
              >{{ dsFormHelp.DataToSuggest }}
            </a>
            that:</strong
          >
        </p>
        <ul class="ds-list">
          <li>
            <p>
              the learner has received high quality instruction (general instruction and evidence-based supplemental instruction),
              <i>and</i>
            </p>
          </li>
          <li>
            <p>
              is meeting standards or is progressing towards meeting standards;
              <i>but</i>
            </p>
          </li>
          <li>
            <p>
              requires continued and substantial effort that may include the
              <a
                class="text-underline text-lower"
                (click)="onOpenHelp($event, helpSection.DsForm, dsFormHelp.ProvisionOfServices)"
                tabindex="0"
                role="link"
                >{{ dsFormHelp.ProvisionOfServices }}</a
              >?
            </p>
          </li>
        </ul>
      </div>
    </app-ds-form-question>

    <div class="actions mt-2">
      <button mat-raised-button aria-label="Previous" color="accent" matStepperPrevious tabindex="0">Previous</button>
      <button
        [hidden]="!!requiresRationaleComments"
        mat-raised-button
        aria-label="Save and Close"
        color="primary"
        [isBusy]="activeCall"
        (click)="saveAndClose.emit(formGroup)"
        tabindex="0"
      >
        Save and Close
      </button>
      <button
        [hidden]="!!requiresRationaleComments"
        mat-raised-button
        aria-label="Finalize"
        color="primary"
        [disabled]="!formGroup.valid"
        [disableAfterBusy]="!formGroup.valid"
        [isBusy]="activeCall"
        (click)="onSubmit()"
        tabindex="0"
      >
        Finalize
      </button>
    </div>
  </mat-step>
</mat-horizontal-stepper>

<div *ngIf="requiresRationaleComments">
  <mat-card class="pb-2">
    <div class="row mt-1">
      <div class="col-12">
        <div>
          <mat-form-field class="w-100">
            <mat-label class="asterisk_input">{{ rationaleCommentLabel }}</mat-label>
            <textarea matInput type="text" [rows]="4" [formControl]="rationaleComments" [maxLength]="10000"></textarea>
            <mat-error>Field is <strong>required</strong></mat-error>
          </mat-form-field>
        </div>
      </div>
    </div>
  </mat-card>

  <div class="action__row">
    <button
      mat-raised-button
      aria-labelledby="Save and Close"
      color="primary"
      (click)="saveAndClose.emit(formGroup)"
      [isBusy]="activeCall"
      tabindex="0"
    >
      Save and Close
    </button>
    <button
      mat-raised-button
      aria-label="Finalize"
      color="primary"
      [disabled]="!formGroup.valid"
      (click)="onSubmit()"
      [disableAfterBusy]="true"
      [isBusy]="activeCall"
      tabindex="0"
    >
      Finalize
    </button>
  </div>
</div>
<div #rationaleCommentsEl></div>

<div class="page-bottom-leeway"></div>
