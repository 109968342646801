import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import dayjs from 'dayjs';
import { DateFormatPipe } from './pipes/date-transform.pipe';

export const validationKeys = {
  noNumbers: 'no-numbers',
  noNumbersLimitedSpecialChars: 'no-numbers-limited-special-chars',
  uniqueIdentifier: 'unique-identifier',
  zipCode: 'zip-code',
};

export const phoneValidator = Validators.pattern(/.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*\d.*/);

export const decimalValidator = Validators.pattern('^[0-9]\\d*(\\.\\d+)?$');

export const onlyNumberValidator = Validators.pattern('\\-?\\d*\\.?\\d{1,2}');

export const zipCodeValidator = createPatternValidator(/(^\d{5}$)|(^\d{5}-\d{4}$)/, validationKeys.zipCode);
export const noNumbersValidator = createPatternValidator(/^[^\d]+$/, validationKeys.noNumbers);
export const uniqueIdentifierValidator = createPatternValidator(
  /^[{]?[0-9a-fA-F]{8}-([0-9a-fA-F]{4}-){3}[0-9a-fA-F]{12}[}]?$/,
  validationKeys.uniqueIdentifier
);

// Still allow dashes - and apostrophes ' and periods .
export const noNumbersLimitedSpecialCharsValidator = createPatternValidator(
  /^[^\d~`!@#$%^&*()_+={}[\]:;"?/<,>]+$/,
  validationKeys.noNumbersLimitedSpecialChars
);

export const todayOrLater: ValidatorFn = (control: FormControl): ValidationErrors | null => {
  const date = dayjs(control.value);
  if (date.isValid() && date.isBefore(dayjs().startOf('day'))) {
    return { todayOrLater: true };
  }
  return null;
};

export const tooOld =
  (age: number): ValidatorFn =>
  (control: AbstractControl): { [key: string]: any } | null => {
    if (!control.value) {
      return null;
    }
    const dateToCompare = new Date();
    dateToCompare.setFullYear(dateToCompare.getFullYear() - age);
    return control.value < dateToCompare ? { tooOld: { value: control.value } } : null;
  };

// For FormControls that contain arrays
export const minimumCount =
  (x: number): ValidatorFn =>
  (control: FormControl): { [key: string]: any } | null =>
    control.value?.length < x ? { minimumCount: { value: control.value.length } } : null;

export const conditionalValidator =
  (predicate, validator): ValidatorFn =>
  (formControl) => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate()) {
      return validator(formControl);
    }
    return null;
  };

export const conditionalWithContextValidator =
  (predicate: (control: AbstractControl) => boolean, validator: ValidatorFn): ValidatorFn =>
  (formControl) => {
    if (!formControl.parent) {
      return null;
    }
    if (predicate(formControl)) {
      return validator(formControl);
    }
    return null;
  };

function createPatternValidator(pattern: RegExp, errorKey: string): ValidatorFn {
  return (control: FormControl): ValidationErrors | null => {
    if (!control.value || pattern.test(control.value)) {
      return null;
    }
    const errors = {};
    errors[errorKey] = true;
    return errors;
  };
}

export const patternWithMessage = (pattern: string | RegExp, message: string): ValidatorFn => {
  const delegateFn = Validators.pattern(pattern);
  return (control) => (delegateFn(control) === null ? null : { message });
};

export class ValidationExtensions {
  /**
   * Touches each control of the form group, which will cause
   * the validators to be run.
   */
  static validateAll(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAll(control);
      }
    });

    formGroup.markAllAsTouched();
  }

  /**
   * Recursively gets all the errors on a form and returns an object.
   */
  static getFormErrors(form: AbstractControl) {
    if (form instanceof FormControl) {
      // Return FormControl errors or null
      return form.errors ?? null;
    }
    if (form instanceof FormGroup) {
      const groupErrors = form.errors;
      // Form group can contain errors itself, in that case add 'em
      const formErrors = groupErrors ? { groupErrors } : {};
      Object.keys(form.controls).forEach((key) => {
        // Recursive call of the FormGroup fields
        const error = this.getFormErrors(form.get(key));
        if (error !== null) {
          // Only add error if not null
          formErrors[key] = error;
        }
      });
      // Return FormGroup errors or null
      return Object.keys(formErrors).length > 0 ? formErrors : null;
    }
  }

  static range(config: { min: number; max: number }): ValidatorFn {
    return (control: FormControl): { [key: string]: any } => {
      const val: number = control.value;

      if (isNaN(val) || /\D/.test(val.toString())) {
        return { number: true };
      } else if (!isNaN(config.min) && val < config.min) {
        return { min: true };
      } else if (!isNaN(config.max) && val > config.max) {
        return { max: true };
      } else {
        return null;
      }
    };
  }
}

export function createDateRangeValidator(startField: string, endField: string): ValidatorFn {
  return (form: FormGroup): ValidationErrors | null => {
    const start: Date = dayjs(new DateFormatPipe().transform(form.get(startField).value)).toDate();
    const end: Date = dayjs(new DateFormatPipe().transform(form.get(endField).value)).toDate();
    if (start && end) {
      const isRangeValid = end.getTime() - start.getTime() > 0;
      return isRangeValid ? null : { dateRange: true };
    }
    return null;
  };
}

export function createDateGreaterThanValidator(minDate: string) {
  return (control: FormControl): ValidationErrors | null => {
    //if control value is less than the minDate return an error
    if (control.value && dayjs(control.value).isBefore(dayjs(minDate))) {
      return { dateGreaterThan: true };
    }
  };
}

export function wholeNumberRangeValidator(min: number, max: number): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const value = control.value;
    if ((value || value === 0) && (!Number.isInteger(Number(value)) || value < min || value > max)) {
      return { invalidWholeNumberRange: true };
    }
    return null;
  };
}

export const basalAgeRangePattern = Validators.pattern(/^(0:[4-9]|0:10|0:11|[1-3]:[0-9]|[1-3]:10|[1-3]:11|4:[0-9])$/);
