import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { PseSummary } from 'src/app/iep/iep-plaafp/secondary-transition/models/pse-summary';
import { PseSummaryService } from 'src/app/iep/iep-plaafp/secondary-transition/services/pse-summary.service';
import { IepService } from 'src/app/iep/services/iep.service';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { CaseSummary, IntakeType } from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { User } from 'src/app/shared/models/user';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { openPdfWindow } from 'src/app/shared/windowHelpers';
import { DeactivationService } from '../../shared/services/deactivation.service';
import { PostSecondarySummary } from './post-secondary-summary';
import { PostSecondarySummaryService } from './post-secondary-summary.service';

@Component({
  selector: 'app-postsecondary-summary',
  templateUrl: './postsecondary-summary.component.html',
  styleUrls: ['./postsecondary-summary.component.scss'],
})
export class PostsecondarySummaryComponent extends BaseComponent implements OnInit {
  caseSummary: CaseSummary;
  postSecondarySummary: PostSecondarySummary;
  showTeamMemberForm = false;
  ieps: any[] = [];
  recentPse: PseSummary;
  isReadOnly = false;

  formGroup = new FormGroup({
    currentFunctionalAchievement: new FormControl(null, Validators.required),
    highSchoolContacts: new FormControl(null),
    contributingMembers: new FormControl(null),
    additionalMembers: new FormControl([]),
    livingExpectations: this.createFormGroup(),
    learningExpectations: this.createFormGroup(),
    workingExpectations: this.createFormGroup(true),
  });

  get livingFormGroup(): FormGroup {
    return this.formGroup.get('livingExpectations') as FormGroup;
  }

  get learningFormGroup(): FormGroup {
    return this.formGroup.get('learningExpectations') as FormGroup;
  }

  get workingFormGroup(): FormGroup {
    return this.formGroup.get('workingExpectations') as FormGroup;
  }

  resourceOptions: KeyValuePair[];
  highSchoolContactsOptions: KeyValuePair[] = [];
  additionalTeamMemberOptions: KeyValuePair[] = [];
  dataSource = new MatTableDataSource<User>();
  displayedColumns: string[] = ['actions', 'name', 'jobTitle', 'email', 'phone'];

  constructor(
    private readonly caseService: CaseService,
    private readonly learnerService: LearnerService,
    private readonly iepService: IepService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly fb: FormBuilder,
    private readonly cd: ChangeDetectorRef,
    private readonly postSecondarySummaryService: PostSecondarySummaryService,
    private readonly userService: UserService,
    private readonly dialog: MatDialog,
    private pseSummaryService: PseSummaryService,
    public authService: AuthService,
    private notificationService: NotificationService,
    private reportingService: ReportingService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
    this.initializeFromFormGroup(this.formGroup, (value) => this.saveData(), this.cd);
    this.startAutosaving();
  }

  async ngOnInit(): Promise<void> {
    this.caseSummary = this.caseService.caseSummary;
    if (!this.caseSummary) {
      this.caseSummary = (
        await this.learnerService.getCases(this.route.snapshot.parent.paramMap.get('learnerId'), IntakeType.PartB).toPromise()
      )[0];
      await this.loadData();
    } else {
      await this.loadData();
    }

    if (this.caseExited || this.authService.isPortalUser) {
      this.formGroup.disable();
      this.displayedColumns = this.displayedColumns.splice(1);
    }

    if (this.authService.IsReadOnly && !this.authService.isAllowedByCaseId(this.caseSummary?.id, null, null)) {
      this.isReadOnly = true;
    }
  }

  get caseExited() {
    return this.caseSummary?.exitFinalized;
  }

  addNonAchieveMember(nonAchieveMember: User) {
    this.dataSource.data = [...this.dataSource.data, nonAchieveMember];

    this.formGroup.markAsDirty();
    this.formGroup.controls.additionalMembers.setValue(this.dataSource.data);
  }

  removeMember(selectedUser: User) {
    const warning = this.dialog.open(AreYouSureComponent, {
      data: {
        question: 'Are you sure you want to remove this team member?',
      },
    });

    warning.afterClosed().subscribe((res) => {
      if (!res) {
        return;
      }

      const item = this.dataSource.data.find((x) => x.id === selectedUser.id);
      if (item) {
        this.dataSource.data = this.dataSource.data.filter((x) => x.id !== item.id);
      }

      this.formGroup.markAsDirty();
      this.formGroup.controls.additionalMembers.setValue(this.dataSource.data);
    });
  }

  private async loadData() {
    if (this.caseSummary.postSecondarySummaryId) {
      const summary = await this.postSecondarySummaryService
        .getPostSecondarySummary(this.caseSummary.id, this.caseSummary.postSecondarySummaryId)
        .toPromise();
      this.postSecondarySummary = summary.value;
      this.formGroup.patchValue(this.postSecondarySummary, {
        emitEvent: false,
      });
      this.dataSource.data = this.postSecondarySummary.additionalMembers.map(
        (x) =>
          ({
            id: x.id,
            email: x.email,
            fullName: x.fullName,
            jobTitle: x.jobTitle,
            phoneNumber: x.phoneNumber,
          } as User)
      );
    } else {
      const newSummary: PostSecondarySummary = {
        id: null,
        caseId: this.caseSummary.id,
        currentFunctionalAchievement: null,
        livingLearningWorkingExpectations: [],
        communityContacts: [],
        contributingMembers: [],
        highSchoolContacts: [],
        additionalMembers: [],
      };

      const summary = await this.postSecondarySummaryService.addPostSecondarySummary(newSummary).toPromise();
      this.postSecondarySummary = summary?.value;
    }

    const resourceOptions = await this.postSecondarySummaryService.getResourceOptions().toPromise();
    this.resourceOptions = resourceOptions.value?.map((x) => new KeyValuePair(x.id, x.label));

    const teamMembers = await this.iepService.getTeamFromMeetings(this.caseSummary.id).toPromise();
    this.highSchoolContactsOptions = teamMembers.map((x) => new KeyValuePair(x.id, x.fullName));
    this.additionalTeamMemberOptions = teamMembers.map((x) => new KeyValuePair(x.id, x.fullName));

    const ieps = await this.iepService.getPastThreeYearGoalAreas(this.caseSummary.id).toPromise();
    this.ieps = ieps.value;
    const plaafp = await this.iepService.getPlaafp(this.ieps[0]?.id, this.ieps[0]?.recentAmendmentId).toPromise();
    const recentPse = this.pseSummaryService.getCurrentPse(plaafp.iepId, plaafp.id, plaafp.secondaryTransitionId).toPromise();
    this.recentPse = await recentPse;
  }

  onFinalize() {
    if (!this.formGroup.valid) {
      this.formGroup.markAsTouched();
      this.formGroup.markAsDirty();
      return;
    }

    this.notificationService.confirmation('Are you sure you want to finalize this Post Secondary Summary?', () => {
      this.postSecondarySummary.finalizedOn = new Date();

      this.postSecondarySummaryService.updatePostSecondarySummary(this.getPostSecondarySummaryModel()).subscribe((x) => {
        this.postSecondarySummary = x.value;
        // this.router.navigate([`learner-management/${this.caseSummary.learnerId}/learner-dashboard`]);
        this.notificationService.success('Post Secondary Summary finalized');
        this.viewPostsecondarySummary();
      });
    });
  }

  viewPostsecondarySummary() {
    this.reportingService.createPostSecondarySummaryOutput(this.postSecondarySummary?.id).subscribe({
      next: (outputDocumentId: string) => openPdfWindow(this.caseSummary.learnerId, outputDocumentId),
      error: (err) => this.reportingService.handleOutputError(err),
    });
  }

  private getPostSecondarySummaryModel() {
    const updatedPostSecondarySummary = this.formGroup.value as PostSecondarySummary;
    updatedPostSecondarySummary.id = this.postSecondarySummary?.id;
    updatedPostSecondarySummary.caseId = this.postSecondarySummary?.caseId;
    updatedPostSecondarySummary.finalizedOn = this.postSecondarySummary?.finalizedOn;
    return updatedPostSecondarySummary;
  }

  private async saveData(): Promise<void> {
    this.postSecondarySummary = (
      await this.postSecondarySummaryService.updatePostSecondarySummary(this.getPostSecondarySummaryModel()).toPromise()
    ).value;
  }

  private createFormGroup(working = false): FormGroup {
    const formGroup = this.fb.group({
      changesToExpectation: new FormControl(null),
      describeFunctionalImpact: new FormControl(null, Validators.required),
      describeResponseToInstruction: new FormControl(null, Validators.required),
      describeCurrentLevelOfPerformance: new FormControl(null, Validators.required),
      servicesMostEffective: new FormControl(null, Validators.required),
      thoughtsPrepareLeave: new FormControl(null, Validators.required),
      ideaFutureSteps: new FormControl(null),
    });

    if (working) {
      formGroup.addControl('workBasedExperience', new FormControl(null, Validators.required));
    }

    return formGroup;
  }
}
