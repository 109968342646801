import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatExpansionPanel } from '@angular/material/expansion';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { yesNoOptions } from 'src/app/shared/formHelpers';
import { BaseComponent } from '../../../../../../shared/components/base-component/base-component';
import { KeyValuePair } from '../../../../../../shared/models/key-value-pair';
import { DeactivationService } from '../../../../../../shared/services/deactivation.service';
import { NotificationService } from '../../../../../../shared/services/notification.service';
import { PseSummary } from '../../../../../iep-plaafp/secondary-transition/models/pse-summary';
import { IepGoal } from '../../../../../models/iep';
import { IepAmendment } from '../../../../../models/iep-amendment';
import { GoalAreaService } from '../../../../../services/goalarea.service';
import { ESYGoalType, ExpectationType, IepESYGoal, PSEInESY } from '../../../../esy';
import { ESYService } from '../../../../esy-service';

@Component({
  selector: 'app-esy-choose-goal-modal',
  templateUrl: './esy-choose-goal-modal.component.html',
  styleUrls: ['./esy-choose-goal-modal.component.scss'],
})
export class EsyChooseGoalModalComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChildren(MatExpansionPanel) expansionPanels: QueryList<MatExpansionPanel>;
  yesNoOptions = yesNoOptions;
  formGroup = new FormGroup({});
  iepGoals: IepGoal[];
  pses: PSEInESY[] = [];
  pseId: string;
  selectedGoals: string[] = [];
  selectedPSEs: ExpectationType[] = [];
  goalAreaOptions: KeyValuePair[] = [];
  autoSaveEsySubscription: Subscription;
  updateUISubscription: Subscription;
  @Input() importedIepId;
  @Input() importedSelectedGoals: IepESYGoal[] = [];
  @Input() importedAllIepGoals: IepGoal[] = [];
  @Input() importedAllPses: PseSummary;
  @Input() amendments: IepAmendment[] = [];
  @Input() iepId: string;
  @Input() iepIsFinalized: boolean;
  @Input() isInTransition = false;
  @Input() childFirstName: string;
  @Output() goalsChanged = new EventEmitter();
  @Output() uiGoalsChanged = new EventEmitter();
  canUpdateUI = true;

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentId() {
    return this.amendment?.id;
  }

  get amendmentIsFinalized() {
    return !!this.amendment && this.amendment?.finalizeDate !== null;
  }

  get hasOpenAmendment() {
    return !!this.amendment && !this.amendmentIsFinalized;
  }

  get lastFinalizedDate() {
    if (this.amendments && this.amendments.length > 0) {
      const latest = this.amendments.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  get hasAmendment() {
    return this.amendmentId && this.amendmentId !== null;
  }

  get readonlyUser() {
    return this.authService.IsReadOnly;
  }

  constructor(
    private iepEsyService: ESYService,
    private notificationService: NotificationService,
    private goalAreaService: GoalAreaService,
    deactivationService: DeactivationService,
    public cd: ChangeDetectorRef,
    private authService: AuthService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.initializeGoalControls(this.importedAllIepGoals);
    this.iepGoals = this.importedAllIepGoals;

    this.importedSelectedGoals.forEach((x) => {
      if (x.goalType === ESYGoalType.Goal) {
        this.addGoal(x.iepGoalId, true);
      }
      if (x.goalType === ESYGoalType.PSE) {
        this.addPSE(x.expectationType);
      }
    });

    this.goalAreaService.getAllGoalAreas().subscribe((areas) => {
      this.goalAreaOptions = areas.map((x) => new KeyValuePair(x.id, x.label));
    });

    this.pseId = this.importedAllPses?.id;
    if (this.importedAllPses) {
      this.initializePseControls(this.importedAllPses);
    }

    this.iepEsyService.esyGoalsUpdated$.subscribe((esyGoals) => {
      this.importedSelectedGoals = esyGoals;
    });

    this.startAutosaving();
  }

  ngOnDestroy(): void {
    this.autoSaveEsySubscription.unsubscribe();
    this.updateUISubscription.unsubscribe();
  }

  amendGoal(goal, expansionPanelIndex) {
    if (this.getESYGoal(goal.id)) {
      const esyGoal = this.getESYGoal(goal.id);
      this.iepEsyService.startESYGoalAmendment(esyGoal.id, this.iepId, this.amendmentId, false).subscribe((result) => {
        this.goalsChanged.emit();
        this.formGroup.get(goal.id).patchValue({
          id: result.value,
          amendingGoal: true,
          amendmentId: this.amendmentId,
        });
      });
    } else {
      this.formGroup.get(goal.id)?.get('amendmentId').setValue(this.amendmentId);
      this.formGroup.get(goal.id)?.get('amendingGoal').setValue(true);
    }
    this.expansionPanels.get(expansionPanelIndex).open();
  }

  amendPSE(pseSummaryId, expectionType) {
    if (this.getPSEGoal(expectionType)) {
      const esyPSEGoal = this.getPSEGoal(expectionType);
      this.iepEsyService.startESYGoalAmendment(esyPSEGoal.id, this.iepId, this.amendmentId, false).subscribe((result) => {
        this.goalsChanged.emit();
        this.formGroup.get(pseSummaryId + '_' + expectionType).patchValue({
          id: result.value,
          amendingGoal: true,
          amendmentId: this.amendmentId,
        });
      });
    } else {
      this.formGroup
        .get(pseSummaryId + '_' + expectionType)
        ?.get('amendmentId')
        ?.setValue(this.amendmentId);
      this.formGroup
        .get(pseSummaryId + '_' + expectionType)
        ?.get('amendingGoal')
        ?.setValue(true);
    }
  }

  private resetControls(formControl, formControlName) {
    Object.keys(formControl.controls)
      .filter((x) => x !== 'includeExpectation' && x !== 'includeGoal')
      .forEach((x) => {
        this.formGroup.get(formControlName).get(x).setValue(null);
        this.formGroup.get(formControlName).get(x).markAsUntouched();
        this.formGroup.get(formControlName).get(x).updateValueAndValidity();
      });
    this.goalsChanged.emit();
  }

  initializePseControls(pse: PseSummary) {
    const selectedLearningPse = this.importedSelectedGoals.find((x) => x.expectationType === ExpectationType.Learning);
    const selectedLivingPse = this.importedSelectedGoals.find((x) => x.expectationType === ExpectationType.Living);
    const selectedWorkingPse = this.importedSelectedGoals.find((x) => x.expectationType === ExpectationType.Working);

    if (pse.learningExpectations) {
      this.pses = this.pses.concat({
        pseSummaryId: pse.id,
        expectationType: ExpectationType.Learning,
        expectation: pse.learningExpectations,
      });
      this.formGroup.addControl(
        pse.id + '_' + ExpectationType.Learning,
        new FormGroup({
          id: new FormControl(selectedLearningPse ? selectedLearningPse.id : null),
          amendingGoal: new FormControl(false),
          amendmentId: new FormControl(null),
          amendmentEndDate: new FormControl(selectedLearningPse ? selectedLearningPse.amendmentEndDate : null),
          includeExpectation: new FormControl(
            selectedLearningPse !== undefined && selectedLearningPse?.amendmentEndDate === null,
            Validators.required
          ),
          significantRegression: new FormControl(
            selectedLearningPse ? selectedLearningPse.significantRegression : null,
            Validators.required
          ),
          significantRegressionExplanation: new FormControl(
            selectedLearningPse ? selectedLearningPse.significantRegressionExplanation : ''
          ),
          rareCircumstances: new FormControl(selectedLearningPse ? selectedLearningPse.rareCircumstances : null, Validators.required),
          rareCircumstancesExplanation: new FormControl(selectedLearningPse ? selectedLearningPse.rareCircumstancesExplanation : ''),
          otherFactors: new FormControl(selectedLearningPse ? selectedLearningPse.otherFactors : null, Validators.required),
          otherFactorsExplanation: new FormControl(selectedLearningPse ? selectedLearningPse.otherFactorsExplanation : ''),
        })
      );

      this.formGroup
        .get(pse.id + '_' + ExpectationType.Learning)
        .get('includeExpectation')
        .valueChanges.subscribe((val) => {
          if (val) {
            this.addPSE(ExpectationType.Learning);
          } else {
            this.canUpdateUI = false;
            this.removePSE(ExpectationType.Learning, pse.id);
          }
        });

      this.formGroup
        .get(pse.id + '_' + ExpectationType.Learning)
        .get('significantRegression')
        .valueChanges.subscribe((val) => {
          if (val) {
            this.formGroup
              .get(pse.id + '_' + ExpectationType.Learning)
              .get('significantRegressionExplanation')
              .addValidators(Validators.required);
          } else {
            this.formGroup
              .get(pse.id + '_' + ExpectationType.Learning)
              .get('significantRegressionExplanation')
              .clearValidators();
          }
          this.formGroup
            .get(pse.id + '_' + ExpectationType.Learning)
            .get('significantRegressionExplanation')
            .updateValueAndValidity();
        });

      this.formGroup
        .get(pse.id + '_' + ExpectationType.Learning)
        .get('rareCircumstances')
        .valueChanges.subscribe((val) => {
          if (val) {
            this.formGroup
              .get(pse.id + '_' + ExpectationType.Learning)
              .get('rareCircumstancesExplanation')
              .addValidators(Validators.required);
          } else {
            this.formGroup
              .get(pse.id + '_' + ExpectationType.Learning)
              .get('rareCircumstancesExplanation')
              .clearValidators();
          }
          this.formGroup
            .get(pse.id + '_' + ExpectationType.Learning)
            .get('rareCircumstancesExplanation')
            .updateValueAndValidity();
        });

      this.formGroup
        .get(pse.id + '_' + ExpectationType.Learning)
        .get('otherFactors')
        .valueChanges.subscribe((val) => {
          if (val) {
            this.formGroup
              .get(pse.id + '_' + ExpectationType.Learning)
              .get('otherFactorsExplanation')
              .addValidators(Validators.required);
          } else {
            this.formGroup
              .get(pse.id + '_' + ExpectationType.Learning)
              .get('otherFactorsExplanation')
              .clearValidators();
          }
          this.formGroup
            .get(pse.id + '_' + ExpectationType.Learning)
            .get('otherFactorsExplanation')
            .updateValueAndValidity();
        });
    }

    if (pse.livingExpectations) {
      this.pses = this.pses.concat({
        pseSummaryId: pse.id,
        expectationType: ExpectationType.Living,
        expectation: pse.livingExpectations,
      });
      this.formGroup.addControl(
        pse.id + '_' + ExpectationType.Living,
        new FormGroup({
          id: new FormControl(selectedLivingPse ? selectedLivingPse.id : null),
          amendmentId: new FormControl(null),
          amendingGoal: new FormControl(false),
          amendmentEndDate: new FormControl(selectedLivingPse ? selectedLivingPse.amendmentEndDate : null),
          includeExpectation: new FormControl(
            selectedLivingPse !== undefined && selectedLivingPse?.amendmentEndDate === null,
            Validators.required
          ),
          significantRegression: new FormControl(selectedLivingPse ? selectedLivingPse.significantRegression : null, Validators.required),
          significantRegressionExplanation: new FormControl(selectedLivingPse ? selectedLivingPse.significantRegressionExplanation : ''),
          rareCircumstances: new FormControl(selectedLivingPse ? selectedLivingPse.rareCircumstances : null, Validators.required),
          rareCircumstancesExplanation: new FormControl(selectedLivingPse ? selectedLivingPse.rareCircumstancesExplanation : ''),
          otherFactors: new FormControl(selectedLivingPse ? selectedLivingPse.otherFactors : null, Validators.required),
          otherFactorsExplanation: new FormControl(selectedLivingPse ? selectedLivingPse.otherFactorsExplanation : ''),
        })
      );

      this.formGroup
        .get(pse.id + '_' + ExpectationType.Living)
        .get('includeExpectation')
        .valueChanges.subscribe((val) => {
          if (val) {
            this.addPSE(ExpectationType.Living);
          } else {
            this.canUpdateUI = false;
            this.removePSE(ExpectationType.Living, pse.id);
          }
        });

      this.formGroup
        .get(pse.id + '_' + ExpectationType.Living)
        .get('significantRegression')
        .valueChanges.subscribe((val) => {
          if (val) {
            this.formGroup
              .get(pse.id + '_' + ExpectationType.Living)
              .get('significantRegressionExplanation')
              .addValidators(Validators.required);
          } else {
            this.formGroup
              .get(pse.id + '_' + ExpectationType.Living)
              .get('significantRegressionExplanation')
              .clearValidators();
          }
          this.formGroup
            .get(pse.id + '_' + ExpectationType.Living)
            .get('significantRegressionExplanation')
            .updateValueAndValidity();
        });

      this.formGroup
        .get(pse.id + '_' + ExpectationType.Living)
        .get('rareCircumstances')
        .valueChanges.subscribe((val) => {
          if (val) {
            this.formGroup
              .get(pse.id + '_' + ExpectationType.Living)
              .get('rareCircumstancesExplanation')
              .addValidators(Validators.required);
          } else {
            this.formGroup
              .get(pse.id + '_' + ExpectationType.Living)
              .get('rareCircumstancesExplanation')
              .clearValidators();
          }
          this.formGroup
            .get(pse.id + '_' + ExpectationType.Living)
            .get('rareCircumstancesExplanation')
            .updateValueAndValidity();
        });

      this.formGroup
        .get(pse.id + '_' + ExpectationType.Living)
        .get('otherFactors')
        .valueChanges.subscribe((val) => {
          if (val) {
            this.formGroup
              .get(pse.id + '_' + ExpectationType.Living)
              .get('otherFactorsExplanation')
              .addValidators(Validators.required);
          } else {
            this.formGroup
              .get(pse.id + '_' + ExpectationType.Living)
              .get('otherFactorsExplanation')
              .clearValidators();
          }
          this.formGroup
            .get(pse.id + '_' + ExpectationType.Living)
            .get('otherFactorsExplanation')
            .updateValueAndValidity();
        });
    }

    if (pse.workingExpectations) {
      this.pses = this.pses.concat({
        pseSummaryId: pse.id,
        expectationType: ExpectationType.Working,
        expectation: pse.workingExpectations,
      });
      this.addPSE(ExpectationType.Working);
      this.formGroup.addControl(
        pse.id + '_' + ExpectationType.Working,
        new FormGroup({
          id: new FormControl(selectedWorkingPse ? selectedWorkingPse.id : null),
          amendmentId: new FormControl(null),
          amendingGoal: new FormControl(false),
          amendmentEndDate: new FormControl(selectedWorkingPse ? selectedWorkingPse.amendmentEndDate : null),
          includeExpectation: new FormControl(
            selectedWorkingPse !== undefined && selectedWorkingPse?.amendmentEndDate === null,
            Validators.required
          ),
          significantRegression: new FormControl(selectedWorkingPse ? selectedWorkingPse.significantRegression : null, Validators.required),
          significantRegressionExplanation: new FormControl(selectedWorkingPse ? selectedWorkingPse.significantRegressionExplanation : ''),
          rareCircumstances: new FormControl(selectedWorkingPse ? selectedWorkingPse.rareCircumstances : null, Validators.required),
          rareCircumstancesExplanation: new FormControl(selectedWorkingPse ? selectedWorkingPse.rareCircumstancesExplanation : ''),
          otherFactors: new FormControl(selectedWorkingPse ? selectedWorkingPse.otherFactors : null, Validators.required),
          otherFactorsExplanation: new FormControl(selectedWorkingPse ? selectedWorkingPse.otherFactorsExplanation : ''),
        })
      );

      this.formGroup
        .get(pse.id + '_' + ExpectationType.Working)
        .get('includeExpectation')
        .valueChanges.subscribe((val) => {
          if (val) {
            this.addPSE(ExpectationType.Working);
          } else {
            this.canUpdateUI = false;
            this.removePSE(ExpectationType.Working, pse.id);
          }
        });

      this.formGroup
        .get(pse.id + '_' + ExpectationType.Working)
        .get('significantRegression')
        .valueChanges.subscribe((val) => {
          if (val) {
            this.formGroup
              .get(pse.id + '_' + ExpectationType.Working)
              .get('significantRegressionExplanation')
              .addValidators(Validators.required);
          } else {
            this.formGroup
              .get(pse.id + '_' + ExpectationType.Working)
              .get('significantRegressionExplanation')
              .clearValidators();
          }
          this.formGroup
            .get(pse.id + '_' + ExpectationType.Working)
            .get('significantRegressionExplanation')
            .updateValueAndValidity();
        });

      this.formGroup
        .get(pse.id + '_' + ExpectationType.Working)
        .get('rareCircumstances')
        .valueChanges.subscribe((val) => {
          if (val) {
            this.formGroup
              .get(pse.id + '_' + ExpectationType.Working)
              .get('rareCircumstancesExplanation')
              .addValidators(Validators.required);
          } else {
            this.formGroup
              .get(pse.id + '_' + ExpectationType.Working)
              .get('rareCircumstancesExplanation')
              .clearValidators();
          }
          this.formGroup
            .get(pse.id + '_' + ExpectationType.Working)
            .get('rareCircumstancesExplanation')
            .updateValueAndValidity();
        });

      this.formGroup
        .get(pse.id + '_' + ExpectationType.Working)
        .get('otherFactors')
        .valueChanges.subscribe((val) => {
          if (val) {
            this.formGroup
              .get(pse.id + '_' + ExpectationType.Working)
              .get('otherFactorsExplanation')
              .addValidators(Validators.required);
          } else {
            this.formGroup
              .get(pse.id + '_' + ExpectationType.Working)
              .get('otherFactorsExplanation')
              .clearValidators();
          }
          this.formGroup
            .get(pse.id + '_' + ExpectationType.Working)
            .get('otherFactorsExplanation')
            .updateValueAndValidity();
        });
    }
  }

  private setIncludeExpectation(formControl) {
    this.formGroup.get(formControl).get('includeExpectation').setValue(true, { emitEvent: false });
    this.saveGoals();
  }

  private setIncludeGoal(formControl) {
    this.formGroup.get(formControl).get('includeGoal').setValue(true, { emitEvent: false });
    this.saveGoals();
  }

  getESYGoal(goalId) {
    return this.importedSelectedGoals.find((x) => x.iepGoalId === goalId);
  }

  getPSEGoal(expectationType: ExpectationType) {
    return this.importedSelectedGoals.find((x) => x.expectationType === expectationType);
  }

  allowFormEdit(isGoal: boolean, goal: any) {
    if (isGoal) {
      return (
        !this.authService.IsReadOnly &&
        ((!this.hasOpenAmendment && !this.hasAmendment && !this.iepIsFinalized && !this.getESYGoal(goal.id)) ||
          (!!this.getESYGoal(goal.id) && !this.getESYGoal(goal.id)?.iepIsFinalized) ||
          (this.hasOpenAmendment &&
            (this.getESYGoal(goal.id)?.amendingGoal || this.formGroup.get(goal.id).get('amendingGoal').value === true)))
      );
    } else {
      return (
        !this.authService.IsReadOnly &&
        ((!this.hasOpenAmendment && !this.hasAmendment && !this.iepIsFinalized && !this.getPSEGoal(goal.expectationType)) ||
          (!!this.getPSEGoal(goal.expectationType) && !this.getPSEGoal(goal.expectationType)?.iepIsFinalized) ||
          (this.hasOpenAmendment &&
            (this.getPSEGoal(goal.expectationType)?.amendingGoal ||
              this.formGroup.get(goal.pseSummaryId + '_' + goal.expectationType).get('amendingGoal').value === true)))
      );
    }
  }

  showCompareOutput(isGoal: boolean, goal: any) {
    if (isGoal) {
      return (
        (!!this.getESYGoal(goal.id) && this.getESYGoal(goal.id)?.amendingGoal) ||
        this.formGroup.get(goal.id).get('amendingGoal').value === true
      );
    } else {
      return (
        (!!this.getPSEGoal(goal.expectationType) && this.getPSEGoal(goal.expectationType)?.amendingGoal) ||
        this.formGroup.get(goal.pseSummaryId + '_' + goal.expectationType).get('amendingGoal').value === true
      );
    }
  }

  initializeGoalControls(goals: IepGoal[]) {
    goals.forEach((goal) => {
      const esyGoal = this.importedSelectedGoals.find((x) => x.iepGoalId === goal.id);
      this.formGroup.addControl(
        goal.id,
        new FormGroup({
          id: new FormControl(esyGoal ? esyGoal.id : null),
          amendmentId: new FormControl(null),
          amendingGoal: new FormControl(null),
          amendmentEndDate: new FormControl(esyGoal ? esyGoal.amendmentEndDate : null),
          includeGoal: new FormControl(esyGoal !== undefined && esyGoal.amendmentEndDate === null, Validators.required),
          significantRegression: new FormControl(esyGoal ? esyGoal.significantRegression : null, Validators.required),
          significantRegressionExplanation: new FormControl(esyGoal ? esyGoal.significantRegressionExplanation : ''),
          rareCircumstances: new FormControl(esyGoal ? esyGoal.rareCircumstances : null, Validators.required),
          rareCircumstancesExplanation: new FormControl(esyGoal ? esyGoal.rareCircumstancesExplanation : ''),
          otherFactors: new FormControl(esyGoal ? esyGoal.otherFactors : null, Validators.required),
          otherFactorsExplanation: new FormControl(esyGoal ? esyGoal.otherFactorsExplanation : ''),
        })
      );

      this.formGroup
        .get(goal.id)
        .get('includeGoal')
        .valueChanges.subscribe((val) => {
          if (val) {
            this.addGoal(goal.id);
          } else {
            this.canUpdateUI = false;
            this.removeGoal(goal.id);
          }
        });

      this.formGroup
        .get(goal.id)
        .get('significantRegression')
        .valueChanges.subscribe((val) => {
          if (val) {
            this.formGroup.get(goal.id).get('significantRegressionExplanation').addValidators(Validators.required);
          } else {
            this.formGroup.get(goal.id).get('significantRegressionExplanation').clearValidators();
          }
          this.formGroup.get(goal.id).get('significantRegressionExplanation').updateValueAndValidity();
        });

      this.formGroup
        .get(goal.id)
        .get('rareCircumstances')
        .valueChanges.subscribe((val) => {
          if (val) {
            this.formGroup.get(goal.id).get('rareCircumstancesExplanation').addValidators(Validators.required);
          } else {
            this.formGroup.get(goal.id).get('rareCircumstancesExplanation').clearValidators();
          }
          this.formGroup.get(goal.id).get('rareCircumstancesExplanation').updateValueAndValidity();
        });

      this.formGroup
        .get(goal.id)
        .get('otherFactors')
        .valueChanges.subscribe((val) => {
          if (val) {
            this.formGroup.get(goal.id).get('otherFactorsExplanation').addValidators(Validators.required);
          } else {
            this.formGroup.get(goal.id).get('otherFactorsExplanation').clearValidators();
          }
          this.formGroup.get(goal.id).get('otherFactorsExplanation').updateValueAndValidity();
        });
    });
  }

  startAutosaving() {
    this.autoSaveEsySubscription = this.formGroup.valueChanges.pipe(debounceTime(2000)).subscribe((form) => {
      if (this.formGroup.dirty && this.canUpdateUI) {
        this.saveGoals();
      }
    });

    this.updateUISubscription = this.formGroup.valueChanges.pipe(debounceTime(100)).subscribe((form) => {
      if (this.formGroup.dirty) {
        this.saveGoals(false);
      }
    });
  }

  goalSelected(goalId: string) {
    return this.selectedGoals.length > 0 && this.selectedGoals.find((x) => x === goalId) != null;
  }

  pseSelected(expectationType: ExpectationType) {
    return this.selectedPSEs.length > 0 && this.selectedPSEs.find((x) => x === expectationType) != null;
  }

  removeGoal(goalId: string) {
    this.notificationService.confirmation(
      `Unselecting this goal will delete all services and activities associated,
      are you sure you want to unselect this goal? `,
      () => {
        if (this.selectedGoals) {
          this.selectedGoals = this.selectedGoals.filter((x) => x !== goalId);
          this.resetControls(this.formGroup.get(goalId), goalId);
        }
        this.canUpdateUI = true;
      },
      'Are you sure?',
      () => {
        this.setIncludeGoal(goalId);
        this.canUpdateUI = true;
      }
    );
  }

  removePSE(expectationType: ExpectationType, pseId: string) {
    this.notificationService.confirmation(
      `Unselecting this expectation will delete all services and activities associated,
      are you sure you want to unselect this expectation? `,
      () => {
        if (this.selectedPSEs) {
          this.selectedPSEs = this.selectedPSEs.filter((x) => x !== expectationType);
          this.resetControls(this.formGroup.get(pseId + '_' + expectationType), pseId + '_' + expectationType);
        }
        this.canUpdateUI = true;
      },
      'Are you sure?',
      () => {
        this.setIncludeExpectation(pseId + '_' + expectationType);
        this.canUpdateUI = true;
      }
    );
  }

  addGoal(goalId: string, existingGoal = false) {
    if (!this.selectedGoals.find((x) => x === goalId)) {
      const goal = existingGoal
        ? this.importedSelectedGoals.find((x) => x.iepGoalId === goalId).iepGoalId
        : this.iepGoals.find((x) => x.id === goalId).id;

      this.selectedGoals = this.selectedGoals.concat(goal);
    }
  }

  addPSE(expectationType: ExpectationType) {
    if (!this.selectedPSEs.includes(expectationType)) {
      this.selectedPSEs = this.selectedPSEs.concat(expectationType);
    }
  }

  saveGoals(updateDb = true) {
    let goals: IepESYGoal[] = [];
    this.selectedGoals.forEach((x) => {
      if (this.formGroup.get(x) !== null) {
        let goalArea = '';
        this.iepGoals
          .find((igs) => igs.id === x)
          ?.goalAreaIds.map((gai) => (goalArea += ', ' + this.goalAreaOptions.find((gao) => gao.key === gai).value));

        const hasAmendmentIdSetInForm = !!this.formGroup.get(x)?.value.amendmentId;

        const goal = {
          id: this.formGroup.get(x).value.id,
          iepId: this.importedIepId,
          amendmentId: hasAmendmentIdSetInForm ? this.formGroup.get(x).value.amendmentId : null,
          amendmentEndDate: this.formGroup.get(x).value.amendmentEndDate,
          iepGoalId: x,
          iepGoal: this.importedAllIepGoals.find((y) => y.id === x),
          pseSummaryId: '00000000-0000-0000-0000-000000000000',
          conditionIndividualWillPerform: this.iepGoals.find((g) => g.id === x).conditionIndividualWillPerform,
          goalArea: goalArea.slice(1, goalArea.length),
          iepESYServices: this.importedSelectedGoals?.find((y) => y.id === x)?.iepESYServices,
          iepESYSupportActivities: this.importedSelectedGoals?.find((y) => y.id === x)?.iepESYSupportActivities,
          significantRegression: this.formGroup.get(x).value.significantRegression,
          significantRegressionExplanation: this.formGroup.get(x).value.significantRegressionExplanation,
          rareCircumstances: this.formGroup.get(x).value.rareCircumstances,
          rareCircumstancesExplanation: this.formGroup.get(x).value.rareCircumstancesExplanation,
          otherFactors: this.formGroup.get(x).value.otherFactors,
          otherFactorsExplanation: this.formGroup.get(x).value.otherFactorsExplanation,
          goalType: ESYGoalType.Goal,
          includeGoal: this.formGroup.get(x).value.includeGoal,
        } as IepESYGoal;

        if (!this.formGroup.get(x).invalid && this.formGroup.get(x).value.includeGoal) {
          goals = goals.concat(goal);
        }
      }
    });

    this.selectedPSEs.forEach((x) => {
      const pseControlName = this.pseId + '_' + x;

      if (this.formGroup.get(pseControlName) !== null) {
        const hasAmendmentIdSetInForm = !!this.formGroup.get(pseControlName).value.amendmentId;

        const pse = {
          id: this.formGroup.get(pseControlName).value.id,
          iepId: this.importedIepId,
          amendmentId: hasAmendmentIdSetInForm ? this.formGroup.get(pseControlName).value.amendmentId : null,
          amendmentEndDate: this.formGroup.get(pseControlName).value.amendmentEndDate,
          iepGoalId: '00000000-0000-0000-0000-000000000000',
          pseSummaryId: this.pseId,
          conditionIndividualWillPerform: null,
          goalArea: null,
          iepESYServices: this.importedSelectedGoals?.find((y) => y.expectationType === x)?.iepESYServices,
          iepESYSupportActivities: this.importedSelectedGoals?.find((y) => y.expectationType === x)?.iepESYSupportActivities,
          significantRegression: this.formGroup.get(pseControlName).value.significantRegression,
          significantRegressionExplanation: this.formGroup.get(pseControlName).value.significantRegressionExplanation,
          rareCircumstances: this.formGroup.get(pseControlName).value.rareCircumstances,
          rareCircumstancesExplanation: this.formGroup.get(pseControlName).value.rareCircumstancesExplanation,
          otherFactors: this.formGroup.get(pseControlName).value.otherFactors,
          otherFactorsExplanation: this.formGroup.get(pseControlName).value.otherFactorsExplanation,
          goalType: ESYGoalType.PSE,
          expectationType: x,
          expectation: this.pses.find((pe) => pe.expectationType === x).expectation,
          includeGoal: this.formGroup.get(pseControlName).value.includeExpectation,
        } as IepESYGoal;

        if (!this.formGroup.get(pseControlName).invalid && this.formGroup.get(pseControlName).value.includeExpectation) {
          goals = goals.concat(pse);
        }
      }
    });

    if (updateDb) {
      this.iepEsyService.updateESYGoals(this.importedIepId, goals, this.amendmentId).subscribe((res) => {
        this.notificationService.success('ESY Selected Goals Saved.');
        this.goalsChanged.emit();
      });
    } else {
      if (this.canUpdateUI) {
        this.uiGoalsChanged.emit(goals);
      }
    }
  }

  isGoalIncluded(groupName: string): boolean {
    return this.formGroup.get(groupName).get('includeGoal').value;
  }

  isExpectationIncluded(groupName: string): boolean {
    return this.formGroup.get(groupName).get('includeExpectation').value;
  }

  shouldShowExplanation(groupName: string, controlName: string): boolean {
    return this.formGroup.get(groupName).get(controlName).value === true;
  }
}
