<form [formGroup]="formGroup">
  <app-help-directional-text>
    <p class="mb-2">
      The
      <a
        (click)="onOpenHelp($event, helpSections.IepPlaafp, iepPlaafpHelp.TransferRights)"
        class="text-underline text-lower"
        tabindex="0"
        role="link"
        >{{ iepPlaafpHelp.TransferRights }}</a
      >
      occurs at the
      <a
        (click)="onOpenHelp($event, helpSections.IepPlaafp, iepPlaafpHelp.AgeMajority)"
        class="text-underline text-lower"
        tabindex="0"
        role="link"
        >{{ iepPlaafpHelp.AgeMajority }}</a
      >
      for a learner. Document when and how the transfer of rights occurs. If the rights will
      <a
        (click)="onOpenHelp($event, helpSections.IepPlaafp, iepPlaafpHelp.NotTransfer)"
        class="text-underline text-lower"
        tabindex="0"
        role="link"
        >{{ iepPlaafpHelp.NotTransfer }}</a
      >, please indicate such.
    </p>
  </app-help-directional-text>
  <div class="row">
    <div class="col">
      <p>
        <!-- Do not use dateFormat pipe here as it produces a result a day earlier than expected -->
        Date rights will transfer:
        {{ eighteenthBirthday | date : shortDateFormat }}
      </p>
    </div>
    <div class="col">
      <app-checkbox-single
        *ngIf="!readonly; else readOnlyTemplate"
        formControlName="willNotTransfer"
        aria-labelledby="Rights will not transfer at age 18"
        label="Rights will not transfer at age 18"
      ></app-checkbox-single>
      <ng-template #readOnlyTemplate>
        Rights will
        {{ formGroup.get('willNotTransfer').value ? 'not transfer' : 'transfer' }}
        at age 18
      </ng-template>
    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-lg-6">
      <app-date-picker
        label="Date family notified that rights will transfer"
        controlName="familyNotifiedOn"
        [readOnly]="readonly"
        [showReadOnlyControl]="false"
        [max]="today"
      ></app-date-picker>
    </div>
    <div class="col-md-12 col-lg-6">
      <app-autocomplete
        label="Family Notification Method"
        formControlName="familyNotificationMethod"
        [readOnly]="readonly"
        [showReadOnlyControl]="false"
        [options]="notificationMethods"
      ></app-autocomplete>
    </div>
    <div class="col-md-12 col-lg-6" *ngIf="formGroup.get('familyNotificationMethod').value === 'Other'">
      <app-text-field
        label="Other"
        formControlName="familyNotificationMethodOther"
        [readOnly]="readonly"
        [showReadOnlyControl]="false"
      ></app-text-field>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-6">
      <app-date-picker
        label="Learner Notification Date"
        controlName="learnerNotifiedOn"
        [max]="today"
        [readOnly]="readonly"
        [showReadOnlyControl]="false"
      ></app-date-picker>
    </div>

    <div class="col-md-12 col-lg-6">
      <app-autocomplete
        label="Learner Notification Method"
        formControlName="learnerNotificationMethod"
        [options]="notificationMethods"
        [readOnly]="readonly"
        [showReadOnlyControl]="false"
      ></app-autocomplete>
    </div>
    <div class="col-md-12 col-lg-6" *ngIf="formGroup.get('learnerNotificationMethod').value === 'Other'">
      <app-text-field
        label="Other"
        [isBankableField]="true"
        [bankableFieldId]="bankableFieldIds.secondaryTransition.transferOfRights.learnerNotificationMethodOther"
        formControlName="learnerNotificationMethodOther"
        [readOnly]="readonly"
        [showReadOnlyControl]="false"
      ></app-text-field>
    </div>
  </div>
  <section *ngIf="willNotTransferChecked">
    <div class="row">
      <div class="col">
        <label
          class="vertical-label text-md display-block"
          [ngClass]="{
            asterisk_input: formGroup.get('willNotTransferExplanation').hasError('required')
          }"
        >
          Explain why rights won't transfer (ex: rights retained by parent, rights granted to a third person, power of attorney established
          (parent), power of attorney established (third party), or other reason)
        </label>

        <app-select
          [options]="willNotTransferOptionOptions"
          [hideAsterisk]="true"
          formControlName="willNotTransferOption"
          [readOnly]="readonly"
          [showReadOnlyControl]="false"
          attr.aria-required="{{ formGroup.get('willNotTransfer').hasError('required') ? 'true' : 'false' }}"
        >
        </app-select>

        <app-textarea
          *ngIf="hasWillNotTransferOptionOther"
          label="Describe Other"
          [hideAsterisk]="true"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.secondaryTransition.transferOfRights.willNotTransferExplanation"
          formControlName="willNotTransferExplanation"
          [maxLength]="10000"
          [readOnly]="readonly"
          [showReadOnlyControl]="false"
          attr.aria-required="{{ formGroup.get('willNotTransfer').hasError('required') ? 'true' : 'false' }}"
        ></app-textarea>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-6 col-xl-4">
        <app-date-picker
          label="New date of transfer of rights"
          controlName="dateOfTransfer"
          [min]="newDateTransferWillStart"
          [startAt]="newDateTransferWillStart"
        ></app-date-picker>
      </div>
    </div>
  </section>
</form>
