import { Building } from 'src/app/shared/models/building';
import { FileDocument } from 'src/app/shared/models/file-document';

export interface Fba {
  id: string;
  caseId: string;
  nonAchieveMembers: string[];
  fbaAssessment: FbaAssessment;
  dataCollections: FbaDataCollection[];
  commonTriggers: string;
  commonResponses: string;
  otherInformationLearned: string;
  hypothesisStatements: FbaHypothesisStatement[];
  timeLikelyEngaging: string;
  status: FbaStatus;
  finalizedOn?: Date;
  bipNeeded: string;
  reviews: FbaReview[];
  documentId?: string;
}

export interface FbaReview {
  id: string;
  fbaId: string;
  nonAchieveMembers: string[];
  reviewUsers: FbaReviewUser[];
  startedOn?: Date;
  finalizedOn?: Date;
}

export interface FbaUser {
  id: string;
  fbaId: string;
  userId: string;
  jobTitle: string;
  email: string;
  aea: string;
  phone: string;
  district: string;
  buildings: Building[];
  name: string;
}

export interface FbaReviewUser {
  id: string;
  fbaReviewId: string;
  userId: string;
  jobTitle: string;
  email: string;
  aea: string;
  district: string;
  buildings: Building[];
  name: string;
}

export interface FbaAssessment {
  id: string;
  fbaId: string;
  strengthComments: FbaAssessmentComment[];
  preferenceComments: FbaAssessmentComment[];
  evidence: FileDocument[];
  targetBehaviors: FbaTargetBehavior[];
}

export interface FbaAssessmentComment {
  id: string;
  fbaAssessmentId: string;
  fbaAssessmentCommentType: string;
  comments: string;
  dataSource: string;
  fromPlaafp?: boolean;
  selectForBip?: boolean;
}

export interface FbaTargetBehavior {
  id: string;
  fbaAssessmentId: string;
  categoryOfBehaviorId: string;
  categoryOfBehaviorOther: string;
  operationalDefinition: string;
  dimensionOfBehaviorMeasured: string;
  dimensionOfBehaviorMeasuredOther: string;
  selectForBip?: boolean;
}

export interface FbaDataCollection {
  id: string;
  dataDate?: Date;
  userId: string;
  fbaId: string;
  review?: boolean;
  reviewTopics: string[];
  reviewOther: string;
  interview?: boolean;
  interviewees: string[];
  interviewOther: string;
  observation?: boolean;
  observationData: string[];
  observationOther: string;
  test?: boolean;
  testSelections: string[];
  testOther: string;
  evidence: FileDocument[];
  summaryNotes: string;
}

export interface FbaHypothesisStatement {
  id: string;
  fbaId: string;
  categoryOfBehavior: string;
  categoryOfBehaviorId: string;
  hypothesizedFunction: string;
  during: string;
  when: string;
  inOrderTo: string;
  fullHypothesisStatement: string;
}

export interface FbaNonAchieveReviewMemberUpdate {
  oldName: string;
  newName: string;
}

export enum FbaStatus {
  Draft = 'Draft',
  Finalized = 'Finalized',
  Discontinued = 'Discontinued',
}

export enum FbaBipNeeded {
  ReviewBip = 'Review and revise the most recent Behavioral Intervention Plan.',
  CreateNewBip = 'Create a new Behavioral Intervention Plan.',
  DiscontinueBip = 'Discontinue the current Behavioral Intervention Plan.  Behaviors may be addressed with goals/services/supports.',
  BipNeeded = 'Yes, a Behavior Intervention Plan is needed.',
  BipNeededExistingBip = 'Yes, a Behavioral Intervention Plan is needed, however there is an existing BIP from WebIEP.  A new BIP will be created during the next drafting of the next IEP.',
  BipNotNeeded = 'No, a Behavior Intervention Plan is not needed.',
  BehaviorAddressed = 'Behaviors will be addressed with goals, services, and supports.',
}
