import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ChildInformation } from 'src/app/evaluation/shared/models/child-information';
import { Intake } from 'src/app/evaluation/models/intake';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { DatabaseLinksService } from '../../../../../shared/services/database-links/database-links.service';

@Component({
  selector: 'app-part-c-consent-postref-screening',
  templateUrl: './part-c-consent-postref-screening.component.html',
  styleUrls: ['./part-c-consent-postref-screening.component.scss'],
})
export class PartCConsentPostrefScreeningComponent implements OnInit {
  childInfo: ChildInformation;
  displayName = '';
  shortDateFormat = shortDateFormat;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly databaseLinksService: DatabaseLinksService,
    private readonly title: Title
  ) {}

  ngOnInit(): void {
    const intake = this.route.snapshot.data.intake as Intake;
    this.childInfo = intake?.childInfo;
    this.setDisplayName();
    this.title.setTitle('Consent for Early ACCESS Post-Referral Screening');
  }

  openParentInformation() {
    window.open(this.databaseLinksService.getDatabaseLink('parentInformation'), '_blank');
  }

  private setDisplayName() {
    if (!this.childInfo || !this.childInfo.firstName || !this.childInfo.lastName) {
      return;
    }

    this.displayName = `${this.childInfo.firstName}${this.childInfo.middleName ? ` ${this.childInfo.middleName}` : ''} ${
      this.childInfo.lastName
    }`;
  }
}
