import { Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { AuthService } from '../../../../auth/auth.service';
import { AreYouSureComponent } from '../../../../shared/components/are-you-sure-modal/are-you-sure.component';
import { KeyValuePair } from '../../../../shared/models/key-value-pair';
import { FamilyRelationshipService } from '../../../../shared/services/family-relationship/family-relationship.service';
import { LocationService } from '../../../../shared/services/location/location.service';
import {
  DhhEventDto,
  DhhFamilyMember,
  DhhLearnerAppointmentDto,
  DhhLearnerAppointmentParticipantDto,
  DhhLearnerDto,
  LearnerAppointmentStatus,
} from '../../../models/DhhDtos';
import { DhhLookupsService } from '../../../services/dhh-lookups.service';
import { DhhRoutingService } from '../../../services/dhh-routing.service';
import { DhhService } from '../../../services/dhh.service';
import { DhhCancelLearnerAppointmentComponent } from '../modals/dhh-cancel-learner-appointment/dhh-cancel-learner-appointment.component';
import { openPdfWindow } from 'src/app/shared/windowHelpers';
import { ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-dhh-event-detail',
  templateUrl: './dhh-event-detail.component.html',
  styleUrls: ['./dhh-event-detail.component.scss'],
})
export class DhhEventDetailComponent implements OnInit, OnChanges {
  private _dhhEvent: DhhEventDto;
  get dhhEvent() {
    return this._dhhEvent;
  }

  @Input() set dhhEvent(value: DhhEventDto) {
    if (value) {
      this._dhhEvent = value;
      if (this.formGroup) this.formGroup.get('dhhAppointmentId').setValue(this.dhhEvent.appointmentId);
    }
  }

  @Input() learner: DhhLearnerDto;
  @Input() learnerAppointment: DhhLearnerAppointmentDto;
  @Input() readonly = true;
  @Input() summaryView = true;
  @Input() shouldPrepopulateForm = false;
  @Output() eventDetailClosed = new EventEmitter();

  relationshipOptions: KeyValuePair[] = [];
  appointmentTypeOptions: KeyValuePair[] = [];
  rescheduleReasonOptions: KeyValuePair[] = [];
  familyMembersOptions: KeyValuePair[] = [];
  learnerAppointments: DhhLearnerAppointmentDto[] = [];
  formGroup: FormGroup;
  editMode: boolean;
  isSaving = false;
  today = dayjs().startOf('day').toDate();
  priorStartTime: string;
  priorEndTime: string;
  priorApptType: string;

  get canCancelRescheduleAppointment() {
    return this.authService.isDhhAudiologist || this.authService.isDhhScheduler || this.authService.isSuperAdmin;
  }

  get getParticipantFormGroup() {
    return this.formGroup.get('participant') as FormGroup;
  }

  get appointmentUpdated() {
    return (
      this.learnerAppointment &&
      (this.priorStartTime !== this.formGroup.get('startTime').value ||
        this.priorEndTime !== this.formGroup.get('endTime').value ||
        this.priorApptType !== this.formGroup.get('appointmentTypeId').value)
    );
  }

  constructor(
    private ngZone: NgZone,
    private readonly dhhService: DhhService,
    private readonly dhhLookupService: DhhLookupsService,
    private readonly familyMemberRelationshipService: FamilyRelationshipService,
    private readonly locationService: LocationService,
    public readonly authService: AuthService,
    private readonly dhhRoutingService: DhhRoutingService,
    private readonly fb: FormBuilder,
    private dialog: MatDialog,
    private readonly reportingService: ReportingService,
    private notificationService: NotificationService
  ) {}

  async ngOnInit(): Promise<void> {
    this.editMode = !this.readonly;

    if (this.learner) {
      this.setupFamilyMemberOptions(this.learner?.familyMembers);
    }

    await this.getLookups().then(() => {
      if (this.shouldPrepopulateForm) {
        this.priorStartTime = this.learnerAppointment.startTime;
        this.priorEndTime = this.learnerAppointment.endTime;
        this.priorApptType = this.learnerAppointment.appointmentTypeId;
        this.initFormWithValue();
      } else {
        this.initForm();
      }
    });
  }

  setupFamilyMemberOptions(familyMembers: DhhFamilyMember[]) {
    this.familyMembersOptions = familyMembers?.map((f) => new KeyValuePair(f.id, f.fullName));
    if (!this.familyMembersOptions) {
      this.familyMembersOptions = [];
    }
    this.familyMembersOptions.push(new KeyValuePair('Other', 'Other Recipient'));
  }

  initForm() {
    this.formGroup = this.fb.group({
      id: [null],
      dhhAppointmentId: [this.dhhEvent.appointmentId],
      learnerId: [this.learner?.learnerId],
      startTime: [null, [Validators.required]],
      endTime: [null, [Validators.required]],
      appointmentTypeId: [null, [Validators.required]],
      status: [LearnerAppointmentStatus.Pending],
      familyMembers: [null, [Validators.required]],
      rescheduleReasonId: [null],
      participant: this.fb.group({
        id: [null],
        familyMemberId: [null],
        fullName: [null],
        email: [null],
        familyRelationshipId: [null],
        streetAddress: [null],
        zipCode: [null],
        city: [null],
        state: [null],
      }),
    });
    if (!!this.learnerAppointment) {
      this.setRequiredValidator(this.formGroup.controls.rescheduleReasonId);
      this.formGroup.get('id').patchValue(this.learnerAppointment.id);
    }

    this.setFormSubscription();
  }

  initFormWithValue() {
    let hasOtherParticipant = false;
    let fmIds = this.learnerAppointment.participants?.filter((p) => p.familyMemberId !== null)?.map((f) => f.familyMemberId);
    if (this.learnerAppointment.participants.some((p) => p.familyMemberId === null)) {
      hasOtherParticipant = true;
      if (fmIds === null) {
        fmIds = ['Other'];
      } else {
        fmIds.push('Other');
      }
    }

    this.initForm();
    this.formGroup.patchValue(this.learnerAppointment);
    this.formGroup.get('rescheduleReasonId').patchValue(null);
    this.formGroup.controls.familyMembers.setValue(fmIds);

    if (hasOtherParticipant) {
      const otherParticipant = this.learnerAppointment.participants.find((p) => p.familyMemberId === null);
      this.getParticipantFormGroup.patchValue(otherParticipant);
    }
  }

  setFormSubscription() {
    this.formGroup.controls.familyMembers.valueChanges.subscribe((values: any[]) => {
      if (values?.includes('Other')) {
        this.setRequiredValidator(this.getParticipantFormGroup.controls.fullName);
        this.setRequiredValidator(this.getParticipantFormGroup.controls.familyRelationshipId);
        this.setRequiredValidator(this.getParticipantFormGroup.controls.streetAddress);
        this.setRequiredValidator(this.getParticipantFormGroup.controls.zipCode);
        this.setRequiredValidator(this.getParticipantFormGroup.controls.city);
        this.setRequiredValidator(this.getParticipantFormGroup.controls.state);
      } else {
        this.removeValidators(this.getParticipantFormGroup);
      }
    });

    this.formGroup.controls.startTime.valueChanges.subscribe((startTime) => {
      const endTime = this.formGroup.controls.endTime.value;
      this.watchFormTimeRangeValidation(startTime, endTime);
    });

    this.formGroup.controls.endTime.valueChanges.subscribe((endTime) => {
      const startTime = this.formGroup.controls.startTime.value;
      this.watchFormTimeRangeValidation(startTime, endTime);
    });
  }

  populateLocationFromZipCode() {
    const zipCodeCtrl = this.getParticipantFormGroup.get('zipCode');
    if (zipCodeCtrl.valid && zipCodeCtrl.value) {
      this.locationService.getLocationData(zipCodeCtrl.value).subscribe((res) => {
        if (res) {
          this.getParticipantFormGroup.patchValue({
            city: res.city,
            state: 'IA',
          });
        }
      });
    }
  }

  private setRequiredValidator(control: AbstractControl) {
    control.setValidators(Validators.required);
    control.updateValueAndValidity();
  }

  private removeValidators(form: FormGroup) {
    for (const key in form.controls) {
      form.get(key).clearValidators();
      form.get(key).setValue(null);
      form.get(key).updateValueAndValidity();
    }
  }

  async getLookups() {
    const relationships = await this.familyMemberRelationshipService.get().toPromise();
    this.relationshipOptions = relationships.map((r) => new KeyValuePair(r.id, r.label));

    const learnerAppointmentTypes = await this.dhhLookupService.getLearnerAppointmentTypes().toPromise();
    this.appointmentTypeOptions = learnerAppointmentTypes.value.map((r) => new KeyValuePair(r.id, r.label));

    const rescheduleReasons = await this.dhhLookupService.getLearnerAppointmentRescheduleReason().toPromise();
    this.rescheduleReasonOptions = rescheduleReasons.value?.map((r) => new KeyValuePair(r.id, r.label));
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      const changedProp = changes[propName];
      if (propName === 'dhhEvent') {
        this.dhhEvent = changedProp.currentValue;
        this.getLearnerAppointments();
        this.editMode = !this.readonly;
      } else if (propName === 'learner') {
        this.learner = changedProp.currentValue;
        this.setupFamilyMemberOptions(this.learner?.familyMembers);
      }
    }
  }

  getLearnerAppointments() {
    this.dhhService.getLearnerAppointments(this.dhhEvent.appointmentId, null).subscribe(
      (response) => {
        if (response.succeeded) {
          this.learnerAppointments = response.value;
          this.learnerAppointments.sort((a, b) => {
            return (
              Date.parse('1970/01/01 ' + a.startTime.slice(0, -3) + a.startTime.slice(-3)) -
              Date.parse('1970/01/01 ' + b.startTime.slice(0, -3) + b.startTime.slice(-3))
            );
          });
        } else {
          this.dhhService.handleError('Failed to load learner appointments.', response);
        }
      },
      (error) => {
        this.dhhService.handleError('There was an error while loading learner appointments', error);
      }
    );
  }

  onCancel() {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Any data entered will be lost. Please confirm that you wish to continue.',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.editMode = false;
        this.formGroup.reset();
      }
    });
  }

  onSave() {
    if (this.formGroup.invalid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    const participants = [] as DhhLearnerAppointmentParticipantDto[];
    const recepientsList = this.formGroup.controls.familyMembers.value as string[];
    if (recepientsList && recepientsList.length > 0) {
      recepientsList.forEach((r) => {
        let participant = {} as DhhLearnerAppointmentParticipantDto;
        if (r.includes('Other')) {
          participant = {
            id: this.getParticipantFormGroup.controls.id.value,
            familyMemberId: null,
            fullName: this.getParticipantFormGroup.controls.fullName.value,
            email: this.getParticipantFormGroup.controls.email.value,
            familyRelationshipId: this.getParticipantFormGroup.controls.familyRelationshipId.value,
            streetAddress: this.getParticipantFormGroup.controls.streetAddress.value,
            zipCode: this.getParticipantFormGroup.controls.zipCode.value,
            city: this.getParticipantFormGroup.controls.city.value,
            state: this.getParticipantFormGroup.controls.state.value,
          } as DhhLearnerAppointmentParticipantDto;
        } else {
          const fm = this.learner?.familyMembers.find((f) => f.id === r);
          if (fm) {
            participant = {
              id: this.getParticipantFormGroup.controls.id.value,
              familyMemberId: fm.id,
              fullName: fm.fullName,
              email: fm.email,
              familyRelationshipId: fm.familyRelationshipId,
              streetAddress: fm.streetAddress,
              zipCode: fm.zipCode,
              city: fm.city,
              state: fm.state,
            } as DhhLearnerAppointmentParticipantDto;
          }
        }
        participants.push(participant);
      });
    }

    const appointment = {
      id: this.formGroup.controls.id.value,
      dhhAppointmentId: this.formGroup.controls.dhhAppointmentId.value,
      learnerId: this.learner?.learnerId,
      appointmentTypeId: this.formGroup.controls.appointmentTypeId.value,
      startTime: this.formGroup.controls.startTime.value,
      endTime: this.formGroup.controls.endTime.value,
      status: this.formGroup.controls.status.value,
      rescheduleReasonId: this.formGroup.controls.rescheduleReasonId.value,
      participants: participants.length > 0 ? participants : null,
    } as DhhLearnerAppointmentDto;

    this.isSaving = true;

    const savingDone = () => {
      this.formGroup.reset();
      this.getLearnerAppointments();
      this.editMode = false;
    };

    if (appointment.id !== null) {
      this.dhhService.rescheduleLearnerAppointment(appointment).subscribe(
        (result) => {
          if (result.succeeded) {
            this.reportingService.generateDhhAppointmentConfirmationLetter(result.value).subscribe({
              next: (documentId: string) => this.viewConfirmationLetter(appointment.learnerId, documentId),
              error: (err) => {
                this.notificationService.errorWithAction("Couldn't open confirmation letter", 'Why?', () =>
                  this.notificationService.alert(err.error, "Couldn't open confirmation letter")
                );
              },
            });
            savingDone();
          } else {
            this.dhhService.handleError('Failed to reschedule learner appointment', result);
          }
        },
        (error) => {
          this.dhhService.handleError('There was an error while rescheduling learner appointment', error);
        },
        () => {
          this.isSaving = false;
        }
      );
    } else {
      this.dhhService.addLearnerAppointment(appointment).subscribe(
        (result) => {
          if (result.succeeded) {
            this.reportingService.generateDhhAppointmentConfirmationLetter(result.value.id).subscribe({
              next: (documentId: string) => this.viewConfirmationLetter(result.value.learnerId, documentId),
              error: (err) => {
                this.notificationService.errorWithAction("Couldn't open confirmation letter", 'Why?', () =>
                  this.notificationService.alert(err.error, "Couldn't open confirmation letter")
                );
              },
            });
            savingDone();
          } else {
            this.dhhService.handleError('Failed to save learner appointment', result);
          }
        },
        (error) => {
          this.dhhService.handleError('There was an error while saving learner appointment', error);
        },
        () => {
          this.isSaving = false;
        }
      );
    }
  }

  onChooseAnotherDay() {
    this.formGroup.reset();
    this.editMode = false;
    this.eventDetailClosed.emit();
  }

  onCancelAppointment(learnerAppointmentId: string) {
    const dialogRef = this.dialog.open(DhhCancelLearnerAppointmentComponent, {
      width: '650px',
      data: {
        learnerAppointmentId: learnerAppointmentId,
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.getLearnerAppointments();
      }
    });
  }

  onRescheduleAppointment(learnerId: string, learnerAppointmentId: string) {
    const callBackFunc = () => {
      this.ngZone.run(() => {
        this.getLearnerAppointments();
      });
    };

    this.dhhRoutingService.openLearnerAppointmentScheduler(learnerId, learnerAppointmentId, callBackFunc);
  }

  private viewConfirmationLetter(learnerId, documentId) {
    openPdfWindow(learnerId, documentId);
  }

  get isEventPast() {
    return new Date(this.dhhEvent.startDateAndTime) < this.today;
  }

  private watchFormTimeRangeValidation(startTime: string, endTime: string) {
    if (!startTime || !endTime) return false;

    const convertToMinutes = (time: string) => {
      const [timePart, modifier] = time.split(' ');
      // eslint-disable-next-line prefer-const
      let [hours, minutes] = timePart.split(':').map(Number);
      if (modifier === 'PM' && hours !== 12) {
        hours += 12;
      }
      if (modifier === 'AM' && hours === 12) {
        hours = 0;
      }
      return hours * 60 + minutes;
    };
    const start = convertToMinutes(startTime);
    const end = convertToMinutes(endTime);

    const minEndTime = start + 15;
    //Appointment start time validation
    if (start > end) {
      this.formGroup.controls.startTime.setErrors({ invalidStartTime: true });
      return;
    } else if (end < minEndTime) {
      this.formGroup.controls.startTime.setErrors({ invalidMinDuration: true });
      return;
    } else {
      this.formGroup.controls.startTime.setErrors(null);
    }

    //Appointment within Event range validation
    const eventStartTime = convertToMinutes(this.dhhEvent.startTime);
    const eventEndTime = convertToMinutes(this.dhhEvent.endTime);
    if (start < eventStartTime || end > eventEndTime) {
      this.formGroup.controls.startTime.setErrors({ outOfRange: true });
      return;
    } else {
      this.formGroup.controls.startTime.setErrors(null);
    }

    //Overlap validation
    const appointments = !!this.learnerAppointment
      ? this.learnerAppointments.filter((x) => x.id !== this.learnerAppointment.id)
      : this.learnerAppointments;
    const hasOverlap = appointments.some((appointment) => {
      const aStart = convertToMinutes(appointment.startTime);
      const aEnd = convertToMinutes(appointment.endTime);

      return start < aEnd && end > aStart && !(end === aStart);
    });
    hasOverlap ? this.formGroup.controls.startTime.setErrors({ overlap: true }) : this.formGroup.controls.startTime.setErrors(null);
  }
}
