<div class="calendar">
  <div class="calendar__header">
    <h3 class="mb-0">ACHIEVE Calendar</h3>
  </div>

  <app-help-directional-text [outsideCard]="true">
    <p class="mb-2">Click on any event in the calendar to see additional details.</p>
  </app-help-directional-text>

  <mat-card class="card__calendar">
    <div class="row" *ngIf="uniqueLearnerDistricts?.length > 1">
      <div class="col-md-9"></div>
      <div class="col-md-3">
        <app-autocomplete
          [options]="uniqueLearnerDistricts"
          label="Filter by District"
          (selected)="filterDistricts($event)"
        ></app-autocomplete>
      </div>
    </div>
    <full-calendar [options]="calendarOptions" #calendar></full-calendar>

    <div class="event-detail" #eventDetailContainer *ngIf="currentEvent">
      <hr class="input__divider" />
      <div class="event-detail__status">
        <div class="display-flex align-items-baseline">
          <h3 class="my-0 mr-2">{{ currentTodo.status === meetingStatus.Cancelled ? 'CANCELED' : '' }} Event:</h3>
          <p class="my-0">
            {{ currentTodoTitle }}
          </p>
        </div>
        <button mat-raised-button aria-label="Close Button" color="primary" (click)="setEventToNull()" tabindex="0">Close</button>
      </div>

      <div>
        <app-family-meeting-list
          *ngIf="currentTodo && currentTodo.familyMeeting"
          [showMeetingInfo]="true"
          [familyMeeting]="currentTodo.familyMeeting"
          [intakeType]="currentTodo.intakeType"
          [caseId]="currentTodo.caseId"
          [learnerId]="currentTodo.learnerId"
          [caseFamilyMembers]="currentTodo.familyMembers"
          [readOnly]="readOnly"
          (refreshData)="refreshData($event)"
        ></app-family-meeting-list>
      </div>
    </div>
  </mat-card>
  <div #eventDetailScrollZone></div>
</div>
