export interface SearchParams {
  firstName: string;
  middleName: string;
  lastName: string;
  dateOfBirth: string;
  stateAssignedId: string;
  attendingAeaId: string;
  attendingDistrictId: string;
  buildingId: string;
  isLocationOnlySearch: boolean;
  isFinalizedIepOnly: boolean;
  dataSourceType: string;
}

export enum SearchDataSource {
  AllLearners = 'AllLearners',
  ACHIEVELearnersOnly = 'ACHIEVELearnersOnly',
}
