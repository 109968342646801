import { Component, Inject, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { KeyValuePair } from '../../models/key-value-pair';
import { NotificationService } from '../../services/notification.service';

export interface DialogData {
  documentationTypes: KeyValuePair[];
  title: string;
  fileName: string;
  allowedFileTypes: string;
  type: string;
  multiple: boolean;
  showComments: boolean;
  learnersName: string;
  fileSizeLimit?: number;
}

export interface UploadResult {
  title: string;
  comment: string;
  type: string;
  file: File;
}

@Component({
  selector: 'app-upload-documentation-modal',
  templateUrl: './upload-documentation-modal.component.html',
  styleUrls: ['./upload-documentation-modal.component.scss'],
})
export class UploadDocumentationModalComponent implements OnInit {
  formGroup = this.fb.group({ files: this.fb.array([]) });
  documentationTypeOptions: KeyValuePair[];
  cardHeader: string;
  fileTitle: string;
  documentType: string;
  disableTitle = false;
  multiple = true;
  listOfFiles: File[];
  allowedFileTypes = 'TXT, CSV, PDF, DOC, DOCX, ODT, PPTX, PPT, RTF, XLS, XLSX, PNG, JPEG, JPG, GIF, BMP';
  accepts = this.allowedFileTypes
    .toLocaleLowerCase()
    .split(', ')
    .map((x) => `.${x}`)
    .join(',');
  learnersName: string;
  fileSizeLimit = 200;

  get fileArray(): FormArray {
    return this.formGroup.get('files') as FormArray;
  }

  get fileFormGroups() {
    return this.fileArray.controls as FormGroup[];
  }

  get fileSizeLimitAsBytes() {
    return this.fileSizeLimit * 1024 * 1024;
  }

  constructor(
    private dialogRef: MatDialogRef<UploadDocumentationModalComponent>,
    private snackbar: MatSnackBar,
    private fb: FormBuilder,
    private notification: NotificationService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.documentationTypeOptions = data.documentationTypes;
    this.fileTitle = data.fileName;
    this.cardHeader = data.title;
    this.documentType = data.type;
    this.multiple = data.multiple === undefined || data.multiple === null || data.multiple;
    this.allowedFileTypes = data.allowedFileTypes ? data.allowedFileTypes : this.allowedFileTypes;
    this.learnersName = data.learnersName;
    this.fileSizeLimit = data.fileSizeLimit ?? this.fileSizeLimit;
  }

  ngOnInit(): void {
    this.cardHeader = this.cardHeader ? this.cardHeader : 'Upload Documentation';
  }

  onOpenDocumentationInput() {
    // TODO: Do not manipulate DOM elements directly.
    document.getElementById('documentationInput').click();
  }

  documentationFileChange($event: Event) {
    const files = Array.from(($event.target as HTMLInputElement).files);
    let error = false;
    files.forEach((file, i) => {
      const extension = file.name.split('.').reverse()[0];
      if (!this.allowedFileTypes.toLowerCase().includes(extension)) {
        this.notification.error('One or more of the files selected to upload is not a supported file type.');
        error = true;
      }
    });
    if (error) {
      return;
    }
    this.addFilesToList(files);
  }

  onRemoveFile(index: number) {
    (this.formGroup.get('files') as FormArray).removeAt(index);
  }

  addFilesToList($event) {
    this.listOfFiles = $event;
    for (const file of this.listOfFiles) {
      if (file.size > this.fileSizeLimitAsBytes) {
        this.snackbar.open(`File too large. Please limit files to ${this.fileSizeLimit}MB or less.`, '', { duration: 1000 });
        return;
      }
      if (file.size === 0) {
        this.snackbar.open('File is empty.', '', { duration: 1000 });
        return;
      }
    }
    for (const [fileName, file] of this.getFilesWithName(this.listOfFiles)) {
      (this.formGroup.get('files') as FormArray).push(
        this.fb.group({
          title: [this.fileTitle ?? fileName ?? null, [Validators.required]],
          comment: [null],
          type: this.documentType,
          file: [file, [Validators.required]],
        })
      );
    }
  }

  // Modal Functions
  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.formGroup.get('files').value);
  }

  private getFilesWithName(files: File[]): Map<string, File> {
    const map = new Map<string, File>();
    files.forEach((f) => {
      const originalFileName = this.getFileNameWithoutExtension(f);
      let currentNumber = 2;
      let fileName = originalFileName;
      while (map.has(fileName) || this.fileArray.value?.some((x) => x.title === fileName)) {
        fileName = `${fileName}${currentNumber++}`;
      }
      map.set(fileName, f);
    });
    return map;
  }

  private getFileNameWithoutExtension(file: File): string {
    if (!file.name || file.name.lastIndexOf('.') === -1) {
      return file.name;
    }
    return file.name.substring(0, file.name.lastIndexOf('.')).trim();
  }
}
