import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { FamilyMeetingService } from 'src/app/learner-management/family-meeting/family-meeting.service';
import { MeetingParticipantRead } from 'src/app/learner-management/family-meeting/participants-list/meeting-participants';
import { SignedFormService } from 'src/app/signed-forms/signed-form.service';
import { FeatureFlagService } from '../../services/feature-flags/feature-flag.service';
import { FamilyMeetingRead } from '../../models/case';
import { ConsentFormType, ConsentForm } from '../../models/fiie-consent/consent-form';
import { FamilyConsentStatus } from '../../models/fiie-consent/family-consent-status';
import { ConsentFormService } from '../../services/consent-form/consent-form.service';
import { NotificationService } from '../../services/notification.service';
import { RoutingService } from '../../services/routing.service';

@Component({
  selector: 'app-request-excusal-modal',
  templateUrl: './request-excusal-modal.component.html',
  styleUrls: ['./request-excusal-modal.component.scss'],
})
export class RequestExcusalModalComponent implements OnInit {
  familyMeeting: FamilyMeetingRead;
  caseId: string;
  isBusy = false;
  excusalAttendanceId: string;
  selectedParticipants: Array<MeetingParticipantRead> = [];

  displayedColumns = ['fullName', 'requestExcusal'];
  dataSource = new MatTableDataSource<{
    id: string;
    fullName: string;
    requestExcusal: boolean;
  }>();

  get familyPortalEnabled() {
    return this.featureFlagService?.featureOn('familyPortalEnabled');
  }

  constructor(
    private dialogRef: MatDialogRef<RequestExcusalModalComponent>,
    private readonly dialog: MatDialog,
    private consentFormService: ConsentFormService,
    private signedFormService: SignedFormService,
    private notificationService: NotificationService,
    private routingService: RoutingService,
    private familyMeetingService: FamilyMeetingService,
    @Inject(MAT_DIALOG_DATA)
    public data: { caseId: string; familyMeeting: FamilyMeetingRead },
    private featureFlagService: FeatureFlagService
  ) {
    this.familyMeeting = data.familyMeeting;
    this.selectedParticipants = this.familyMeeting.participants.filter((x) => Boolean(x.excusalDate));
    this.caseId = data.caseId;
  }

  ngOnInit(): void {
    this.getDataSource();
    this.familyMeetingService.getMeetingAttendances(this.caseId).subscribe((meetingAttendances) => {
      this.excusalAttendanceId = meetingAttendances.find((x) => x.label === 'Excusal Agreement Needed').id;
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSendToPortal(): void {
    const now = new Date();
    const consentForm: ConsentForm = {
      caseId: this.caseId,
      type: ConsentFormType.AgreementToExcuseIep,
      statuses: [
        {
          status: FamilyConsentStatus.Requested,
          dateReceived: now,
          dateSigned: now,
        },
      ],
      createdOn: new Date(),
      isComplete: false,
      additionalData: {
        meetingId: this.familyMeeting.id,
        participantIds: this.selectedParticipants.map((p) => p.id),
      },
    };

    this.isBusy = true;

    this.consentFormService.createConsentForms(this.caseId, consentForm).subscribe((result) => {
      this.isBusy = false;
      this.notificationService.success('Consent created successfully.');
      this.onClose();
    });
  }

  onPrint(): void {
    // TODO: Need answer from IA on how to handle the generation of the output if there is more than one participant selected
    // this.reportingService.createAgreementToExcuseOutput()
    const now = new Date();
    const consentForm: ConsentForm = {
      caseId: this.caseId,
      type: ConsentFormType.AgreementToExcuseIep,
      statuses: [
        {
          status: FamilyConsentStatus.Requested,
          dateReceived: now,
          dateSigned: now,
        },
      ],
      createdOn: new Date(),
      isComplete: false,
      additionalData: {
        meetingId: this.familyMeeting.id,
      },
    };
    this.isBusy = true;
    this.consentFormService.createAgreementToExcuse(this.caseId, consentForm).subscribe(async (result) => {
      this.isBusy = false;
      if (result.succeeded) {
        this.routingService.openBase64Blob(result.value, 'application/pdf');
        this.dialogRef.close();
      } else {
        this.notificationService.alert(result.errors.toString(), 'Error creating Agreement to Excuse');
      }
    });
  }

  async viewSignedForm(signedFormId: string) {
    const pdf = await this.signedFormService.getSignedFormPdf(signedFormId);
    window.open(pdf.url);
  }

  requestExcusal(participantId: string) {
    const meetingParticipant = this.familyMeeting.participants?.find((x) => x.id === participantId);

    this.toggleSelectedParticipants(meetingParticipant);

    if (meetingParticipant) {
      meetingParticipant.excusalDate = meetingParticipant.excusalDate ? null : new Date();

      meetingParticipant.meetingAttendanceId = this.excusalAttendanceId;

      this.getDataSource();

      this.familyMeetingService.updateExcusal(this.caseId, this.familyMeeting.id, meetingParticipant).subscribe(() => {});
    }
  }

  enableButtons() {
    return this.dataSource.data.some((x) => x.requestExcusal);
  }

  private toggleSelectedParticipants(participant: MeetingParticipantRead): void {
    const participantPreviouslySelected = this.selectedParticipants.includes(participant);

    participantPreviouslySelected
      ? (this.selectedParticipants = this.selectedParticipants.filter((x: MeetingParticipantRead) => x !== participant))
      : this.selectedParticipants.push(participant);
  }

  private getDataSource() {
    this.dataSource = new MatTableDataSource(
      this.familyMeeting.participants.map((x) => ({
        id: x.id,
        fullName: x.fullName,
        requestExcusal: Boolean(x.excusalDate),
      }))
    );
  }
}
