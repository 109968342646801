import { Component, Input, OnInit } from '@angular/core';
import { DisabilitySuspectFormLearner } from '../models/disability-suspect-form';
import { Learner } from 'src/app/shared/models/learner';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';

@Component({
  selector: 'app-child-information',
  templateUrl: './child-information.component.html',
  styleUrls: ['./child-information.component.scss'],
})
export class ChildInformationComponent implements OnInit {
  @Input() learner: DisabilitySuspectFormLearner;

  shortDateFormat = shortDateFormat;

  constructor() {}

  ngOnInit() {}

  loadData() {}
}
