<div *ngIf="!validSurvey">Sorry, no Questionnaire was found.</div>
<div *ngIf="validSurvey && !hideSurvey">
  <app-page-header
    [pageTitle]="reviewTitle"
    backgroundColor="yellow"
    [helpLinks]="true"
    [condensed]="true"
    [pageTitleColor]="'blue'"
    [shouldShowDhhBanner]="learner?.isDhh"
  >
  </app-page-header>
  <app-dynamic-learner-header></app-dynamic-learner-header>
  <form [formGroup]="formGroup">
    <app-help-directional-text [outsideCard]="true">
      <p>
        Please respond to the questionnaire in preparation for the
        {{ generalSurvey.surveyName }} Review of the Individualized Family Service Plan (IFSP) for {{ learner.fullName }}
      </p>
    </app-help-directional-text>

    <ng-container *ngFor="let category of enabledQuestions; trackBy: trackByCategory">
      <h3 *ngIf="category.categoryName" class="mb-2 card-spacing-top">
        {{ category.categoryName }}
      </h3>
      <mat-card>
        <ul class="list">
          <li *ngFor="let question of category.questions">
            <div class="list__item" *ngIf="question.controlType === 'TextArea'">
              <span class="my-0">{{ question.question }}</span>
              <app-textarea [formControlName]="question.id" label="Answer"> </app-textarea>
            </div>
            <div class="list__item" *ngIf="question.controlType === 'Radio'">
              <fieldset>
                <app-radio-group
                  label="{{ question.question }}"
                  [options]="yesNoOptions"
                  [formControlName]="question.id"
                  [inline]="false"
                  [useUndo]="generalSurvey.completedDateTime === null"
                ></app-radio-group>
              </fieldset>
            </div>
          </li>
        </ul>
      </mat-card>
    </ng-container>
    <div class="action__row">
      <button
        mat-raised-button
        aria-label="Save and Close"
        color="primary"
        (click)="saveAndClose()"
        [disabled]="generalSurvey.completedDateTime"
        class="mr-2"
        tabindex="0"
      >
        Save and Close
      </button>

      <button
        mat-raised-button
        aria-label="Complete"
        color="primary"
        (click)="submit()"
        [disabled]="submitDisabled()"
        class="mr-2"
        tabindex="0"
      >
        Complete
      </button>
    </div>
  </form>
</div>
