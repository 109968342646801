import { Component, Input, OnInit } from '@angular/core';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { DisabilitySuspectForm } from '../models/disability-suspect-form';

@Component({
  selector: 'app-ds-form-summary',
  templateUrl: './ds-form-summary.component.html',
  styleUrls: ['./ds-form-summary.component.scss'],
})
export class DsFormSummaryComponent implements OnInit {
  @Input() dsForm: DisabilitySuspectForm;
  displayedColumns: string[] = ['fullName', 'jobTitle', 'email', 'phone', 'aea', 'district', 'primaryOffice'];

  shortDateFormat = shortDateFormat;

  constructor() {}

  ngOnInit() {}

  allNoResponses() {
    return (
      !this.dsForm.requiresContinuedEffort &&
      !this.dsForm.progressionStandards &&
      !this.dsForm.uniqueComparedToPeers &&
      !this.dsForm.diagnosisOfCondition
    );
  }

  oneYesResponse() {
    return (
      this.dsForm.requiresContinuedEffort ||
      this.dsForm.progressionStandards ||
      this.dsForm.uniqueComparedToPeers ||
      this.dsForm.diagnosisOfCondition
    );
  }
}
