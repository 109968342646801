import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthService } from '../../../auth/auth.service';
import { MemoryStorageService } from '../../../shared/services/memory-storage/memory-storage.service';
import { SpinnerService } from '../../../shared/services/spinner/spinner.service';
import { DhhLearnerDto } from '../../models/DhhDtos';
import { DhhRoutingService } from '../../services/dhh-routing.service';
import { DhhService } from '../../services/dhh.service';

@Component({
  selector: 'app-dhh-learner-entry',
  templateUrl: './dhh-learner-entry.component.html',
  styleUrls: ['./dhh-learner-entry.component.scss'],
})
export class DhhLearnerEntryComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();
  learner: DhhLearnerDto;
  pageTitle = 'New Learner Entry';

  constructor(
    private readonly dhhService: DhhService,
    public readonly authService: AuthService,
    private readonly memoryStorageService: MemoryStorageService,
    private readonly spinnerService: SpinnerService,
    private readonly dhhRoutingService: DhhRoutingService
  ) {}

  ngOnInit(): void {
    this.learner = this.memoryStorageService.getKey('dhh-learner-entry') as DhhLearnerDto;
  }

  onComplete(learner: DhhLearnerDto) {
    setTimeout(() => this.spinnerService.incrementLoading());

    this.dhhService.addLearner(learner).subscribe(
      (result) => {
        if (result.succeeded && learner.stateAssignedId) {
          this.dhhRoutingService.goToLearnerHearingDashboard(result.value);
        } else if (result.succeeded) {
          this.onClose();
        } else {
          this.dhhService.handleError('Adding learner failed', result);
        }
        setTimeout(() => this.spinnerService.decrementLoading());
      },
      (error) => {
        this.dhhService.handleError('Adding learner failed', error);
        setTimeout(() => this.spinnerService.decrementLoading());
      }
    );
  }

  onClose() {
    this.dhhRoutingService.goToChildSearch();
  }

  ngOnDestroy() {
    this.memoryStorageService.clearKey('dhh-learner-entry');
    this.subscriptions.unsubscribe();
  }
}
