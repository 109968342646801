<mat-card class="py-2">
  <mat-card-content>
    <div class="display-flex justify-content-end align-items-center mb-2" *ngIf="ivrsCase?.id && !ivrsCase?.owner">
      <app-help-directional-text>
        <p>To transfer ownership of a VR case, a case owner must be assigned first.</p>
      </app-help-directional-text>
    </div>
    <div class="display-flex justify-content-between align-items-center">
      <div class="display-flex align-items-baseline">
        <h3 class="mb-0 mt-0 mr-1">VR Case Owner:</h3>
        <span>
          {{ ivrsCase?.owner?.firstName }} {{ ivrsCase?.owner?.lastName }}
          <span *ngIf="ivrsCase?.transferredUserInfo">
            {{ ' | ' }}
            {{ !!transferredUser ? transferredUser : '' }} Transferred On
            {{ ivrsCase.transferredUserInfo.dateAssigned | dateFormat }}
          </span>
        </span>
      </div>
      <div *ngIf="learnerHasWorkableCase && canShareTransfer">
        <button mat-raised-button aria-label="Share" class="mr-2" color="primary" (click)="onShareCase(ivrsCase)" tabindex="0">
          Share
          <i class="fas fa-share-alt ml-2" aria-labelledby="Share"></i>
        </button>
        <button
          mat-raised-button
          aria-label="Transfer"
          color="primary"
          (click)="onTransferCase(ivrsCase)"
          tabindex="0"
          [disabled]="!ivrsCase?.owner"
        >
          Transfer
          <i class="fas fa-exchange-alt ml-2" aria-labelledby="Transfer"></i>
        </button>
      </div>
    </div>
  </mat-card-content>
</mat-card>
