import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-page-under-construction',
  templateUrl: './page-under-construction.component.html',
  styleUrls: ['./page-under-construction.component.scss'],
})
export class PageUnderConstructionComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
