<app-page-header [pageTitle]="pageTitle" backgroundColor="blue"></app-page-header>

<ng-container *ngIf="learner">
  <app-dhh-learner-entry-form
    [learner]="learner"
    [showSaveAndCloseButton]="true"
    [autoSave]="true"
    (completeEvent)="onComplete()"
    (closeEvent)="onClose()"
  >
  </app-dhh-learner-entry-form>
</ng-container>
