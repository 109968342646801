<hr class="input__divider mt-0 mb-2 hide-normal" />

<div class="row">
  <div class="col">
    <h3 class="mb-2">Available Statewide Assessment Results</h3>
  </div>
</div>
<div class="row">
  <div class="col">
    <div class="print__container mb-0">
      <!-- Data retrieved from the state system for now, hard coded -->
      <ul class="list">
        <li class="list__item"><strong>English Language Arts Grade 5:</strong> Not yet proficient</li>
        <li class="list__item"><strong>Mathematics Grade 5:</strong> Advanced</li>
        <li class="list__item"><strong>Science Grade 5:</strong> Proficient</li>
      </ul>
    </div>
  </div>
</div>

<hr class="input__divider card-spacing-top mb-0 hide-normal" />
