<app-print-head title="Consent for Early ACCESS Evaluation and Assessment" [showLogo]="true"></app-print-head>

<div class="print__container">
  <p>
    The purpose of evaluation is to determine whether your child is eligible for Early ACCESS services. The purpose of assessment is to
    determine your child and family’s strengths and needs to develop your child’s Individualized Family Service Plan.
  </p>

  <p>The areas to be evaluated and/or assessed are:</p>

  <div class="row">
    <div class="col-md-6">
      <ul>
        <li>Adaptive skills (self-help skills)</li>
        <li>Communication (language and speech)</li>
        <li>Cognitive (thinking/learning)</li>
        <li>Health</li>
      </ul>
    </div>
    <div class="col-md-6">
      <ul>
        <li>Hearing</li>
        <li>Physical (large and small motor)</li>
        <li>Social/Emotional</li>
        <li>Vision</li>
      </ul>
    </div>
  </div>

  <p class="mb-3">I understand the purpose of this notice and hereby give my consent for evaluation/assessment for</p>

  <div class="row mb-3">
    <div class="col-6"><strong>Child’s Name:</strong> {{ displayName }}</div>
    <div class="col-6"><strong>DOB:</strong> {{ childInfo?.dateOfBirth | dateFormat }}</div>
  </div>

  <p class="mb-5">
    I understand that this consent is voluntary and that I may revoke this consent at any time by contacting my service coordinator or the
    service coordinator’s agency. I also understand this consent is valid until the child's third birthday or until consent is revoked. If
    there is a question about whether my child continues to be eligible for Early ACCESS services that requires a new evaluation, I
    understand that I will need to sign a new consent for a subsequent evaluation.
  </p>

  <div class="row pb-5" style="border-bottom: 1px solid black">
    <div class="col-4 text-center">Parent/Guardian Signature</div>
    <div class="col-4 text-center">Relationship to child</div>
    <div class="col-4 text-center">Date</div>
  </div>

  <div class="row mt-4 pb-5" style="border-bottom: 1px solid black">
    <div class="col-4 text-center">Parent/Guardian Signature</div>
    <div class="col-4 text-center">Relationship to child</div>
    <div class="col-4 text-center">Date</div>
  </div>

  <p class="mt-4">Parent rights and procedural safeguards were provided and reviewed by:</p>

  <div class="row pb-5" style="border-bottom: 1px solid black">
    <div class="col-4 text-center">Name</div>
    <div class="col-4 text-center">Agency</div>

    <div class="col-4 text-center">Date</div>
  </div>

  <p class="mt-5">
    You may ask questions about your parental rights and obtain additional copies of the manual by contacting your Service Coordinator or or
    online:
    <a (click)="openParentInformation()" target="_blank" tabindex="0" role="link"
      >https://educateiowa.gov/pk-12/special-education/parent-information</a
    >
  </p>

  <div class="text-center mt-4" style="font-weight: bold">
    Health Insurance Portability and Accountability Act (HIPAA)/ Family Educational Rights and Privacy Act (FERPA) Notice
  </div>

  <p class="mt-4">
    Any and all personally identifiable information regarding children and families receiving Early ACCESS services funded under the
    Individuals with Disabilities Education Act (20 U.S.C. §1400 et seq.) is protected from unauthorized disclosure under FERPA. Personally
    identifiable information protected by FERPA is specifically <strong>exempted</strong> from HIPAA privacy standards. FERPA prohibits
    disclosure of personally identifiable information without parent consent except in limited circumstances, requires notice to be provided
    to the child’s family regarding their privacy rights, requires providers to keep records of access to a child’s records, and contains
    complaint and appeal procedures which apply to disputes over records in possession of Early ACCESS or its providers, among other
    provisions. All Early ACCESS providers comply with these procedures.
  </p>
</div>
