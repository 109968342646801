import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroup,
  FormGroupDirective,
  NgControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { LoggerService } from '../../services/logger/logger.service';

// noinspection AngularMissingOrInvalidDeclarationInModule
@Component({
  template: '',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => BaseWrapperComponent),
      multi: true,
    },
  ],
})
export class BaseWrapperComponent implements OnInit, OnChanges, ControlValueAccessor {
  // for overwrite
  @Input() sourceData: { [key: string]: any };
  @Input() sourceFieldName: string;
  @Input() sourceFieldShownLabel: string;
  @Input() useOverwrite = false;
  @Input() dataSourceValue: any;
  @Input() undoValue: string;
  // end overwrite

  @Input() label: string;
  @Input() labelTemplate: TemplateRef<any>;
  @Input() required = false;
  @Input() extraClasses = '';
  @Input() readOnly = false;
  @Input() placeholder = '';
  @Input() idPrefix = '';
  @Input() showAsterisk = false;
  @Input() hideAsterisk = false;
  @Input() showReadOnlyControl = true;

  /**
   * This is a shortcut to control.disable() or control.enable().
   */
  @Input() disabledState: boolean;
  control = new FormControl();
  klass = '';
  controlId = '';
  controlName = 'notBound';
  formGroup: FormGroup;

  get isControlValid(): boolean {
    return this.control.invalid && (this.control.dirty || this.control.touched);
  }

  constructor(
    public readonly logger: LoggerService,
    @Self()
    @Optional()
    public ngControl: NgControl,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    if (this.ngControl) {
      this.ngControl.valueAccessor = this;
    }
  }

  ngOnInit() {
    if (this.ngControl instanceof FormControlName) {
      const formGroupDirective = this.ngControl.formDirective as FormGroupDirective;
      if (formGroupDirective) {
        this.formGroup = formGroupDirective.form;
        this.control = this.ngControl.formDirective?.form?.get(this.ngControl.path) as FormControl;
      }
    } else if (this.ngControl instanceof FormControlDirective) {
      this.control = this.ngControl.control;
      if (this.control.parent instanceof FormGroup) {
        this.formGroup = this.control.parent as FormGroup;
      }
    } else if (!this.ngControl) {
      this.control = new FormControl();
      // todo this might be ok and not need a warning. I'll have to come back
      // to this if we need to use a control outside of a formGroup
      this.logger.warn('BaseWrapperComponent: Control was not declared or is unbound');
    }
    this.controlName = this.ngControl?.path.join('_') || '';

    if (!this.klass) {
      this.klass = 'form-control';
    }
    if (this.extraClasses) {
      this.klass = `${this.klass} ${this.extraClasses}`;
    }

    this.controlId = this.controlName;
    if (this.idPrefix) {
      this.controlId = `${this.idPrefix}-${this.controlName}`;
    }

    if (this.useOverwrite) {
      this.dataSourceValue = this.sourceData[this.sourceFieldName || this.controlName];
    }

    if (this.control) {
      this.checkDisabled(this.disabledState);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.checkDisabled(this.disabledState);
  }

  writeValue(value: any): void {
    if (this.control === this.ngControl.control) {
      this.changeDetectorRef.markForCheck();
    } else if (this.control.value !== value) {
      this.control.setValue(value);
    }
  }

  /**
   * Write form disabledState state to the DOM element (model => view)
   */
  setDisabledState(isDisabled: boolean): void {
    this.disabledState = isDisabled;
  }

  /**
   * Update form when DOM element value changes (view => model)
   */
  registerOnChange(fn: any): void {
    // Store the provided function as an internal method.
    this.onChange = fn;
  }

  /**
   * Update form when DOM element is blurred (view => model)
   */
  registerOnTouched(fn: any): void {
    // Store the provided function as an internal method.
    this.onTouched = fn;
  }

  replaceWithSourceValue() {
    this.undoValue = this.control.value;
    this.control.setValue(this.dataSourceValue);
    this.control.markAsTouched();
    this.control.markAsDirty();
    this.control.updateValueAndValidity();

    const undoClearer = this.control.valueChanges.subscribe(() => {
      this.undoValue = null;
      undoClearer.unsubscribe();
    });
  }

  undoReplaceWithSourceValue() {
    this.control.setValue(this.undoValue);
    this.control.markAsTouched();
    this.control.updateValueAndValidity();
    this.undoValue = null;
  }

  checkDisabled(disable: boolean) {
    if (!this.control) {
      return;
    }
    if (disable && this.control.enabled) {
      this.control.disable();
    } else if (!disable && !this.control.enabled) {
      this.control.enable();
    }
  }

  onBlur(event: any) {
    this.onTouched(event);
  }

  onTouched: (_: any) => void = () => {};
  onChange: (_: any) => void = () => {};
}
