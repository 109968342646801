import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { DhhTestAudiogramDto, DhhTestAudiogramValueDto } from 'src/app/dhh/models/DhhDtos';
import { DhhTestAudiogramConductionIds } from 'src/app/dhh/models/static-lookups';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { getMonthDayYearString } from '../../../../../shared/dateTimeHelpers';

@Component({
  selector: 'app-audiogram-chart',
  templateUrl: './audiogram-chart.component.html',
  styleUrls: ['./audiogram-chart.component.scss'],
})
export class AudiogramChartComponent implements OnInit {
  @Input() previousValues: DhhTestAudiogramValueDto[];
  @Input() conductions: KeyValuePair[];
  @Input() frequencies: KeyValuePair[];
  @Input() size = '550px';
  @Input() iconSize = { width: 49, height: 42, radius: 13 };
  @Input() dateOfTesting: Date;

  ngOnInit(): void {
    this.buildAudiogramChart();
    Chart.pluginService.register(this.lineSplitterPlugin());
  }

  chart: any = {
    datasets: [],
    originalDatasets: [],
    labels: [],
    options: {
      title: {
        display: true,
        text: '         Audiogram',
        fontFamily: "'Nunito', sans-serif",
        fontColor: 'black',
        fontSize: 16,
      },
      spanGaps: true,
      aspectRatio: 1,
      hover: { mode: null },
      legend: {
        display: false,
      },
      tooltips: {
        enabled: false,
      },
      scales: {
        yAxes: [
          {
            beginAtZero: true,
            reverse: true,
            scaleLabel: {
              display: true,
              labelString: 'Loudness (dB)',
              fontFamily: "'Nunito', sans-serif",
              fontColor: 'black',
            },
            ticks: {
              beginAtZero: true,
              reverse: true,
              max: 120,
              min: -10,
              stepSize: 10,
            },
          },
        ],
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: 'Pitch (Hz)',
              fontFamily: "'Nunito', sans-serif",
              fontColor: 'black',
            },
          },
        ],
      },
      plugins: {
        lineSplitterPlugin: true,
        tooltip: {
          enabled: false,
        },
        legend: {
          display: false,
        },
      },
    },
  };

  buildAudiogramChart(): void {
    if (!!this.dateOfTesting) this.chart.options.title.text = '         Audiogram (' + getMonthDayYearString(this.dateOfTesting) + ')';
    if (this.previousValues?.length) {
      const groupedDatasets = this.createDatasets(this.previousValues);

      Object.entries(groupedDatasets).forEach(([conductionId, data]) => {
        const lineColor = this.getLineColor(conductionId);
        const sortedData = data.sort((a, b) => parseInt(a.x, 10) - parseInt(b.x, 10));
        this.chart.datasets.push({
          conductionId: conductionId,
          data: sortedData,
          borderColor: lineColor,
          showLine: false,
          pointStyle: this.getPointStyleArray(sortedData),
          backgroundColor: 'transparent',
          pointsBackgroundColor: 'transparent',
          fill: false,
          borderWidth: 1,
          pointRadius: this.iconSize.radius,
          tension: 0,
          segment: {
            borderColor: (ctx) =>
              sortedData[ctx.p1DataIndex].originalValue.includes('N') || sortedData[ctx.p0DataIndex].originalValue.includes('N')
                ? 'transparent'
                : lineColor,
          },
          spanGaps: true,
        });
      });
    }

    this.chart.labels = this.frequencies.map((freq) => freq.value);
  }

  getLineColor(conductionId: string): string {
    switch (conductionId) {
      case 'ac-right':
        return '#ff0000';
      case 'ac-left':
        return '#0000ff';
      default:
        return 'transparent';
    }
  }

  skipLine(ctx, value, sortedData) {
    return sortedData[ctx.p1DataIndex].originalValue.includes('N') || sortedData[ctx.p0DataIndex].originalValue.includes('N')
      ? 'transparent'
      : value;
  }

  createDatasets(audiogramValues: DhhTestAudiogramValueDto[]) {
    const grouped = audiogramValues.reduce<{ [key: string]: any[] }>((acc: { [x: string]: any[] }, item: DhhTestAudiogramValueDto) => {
      const id = this.getConductionType(item.dhhTestAudiogramConductionId);
      if (!acc[id]) {
        acc[id] = [];
      }
      if (item.value && item.value !== '') {
        acc[id].push({
          x: this.getFrequency(item.dhhTestingEquipmentFrequencyId),
          y: this.getNumberValue(item.value),
          originalValue: item.value,
          conductionId: item.dhhTestAudiogramConductionId,
        });
      }
      return acc;
    }, {});

    return grouped;
  }

  getConductionType(conductionId: string) {
    switch (conductionId.toUpperCase()) {
      case DhhTestAudiogramConductionIds.RightEarAirConductionUnmasked:
      case DhhTestAudiogramConductionIds.RightEarAirConductionMasked:
        return 'ac-right';
      case DhhTestAudiogramConductionIds.LeftEarAirConductionUnmasked:
      case DhhTestAudiogramConductionIds.LeftEarAirConductionMasked:
        return 'ac-left';
      default:
        return conductionId;
    }
  }

  getNumberValue(value: string): number {
    if (value) {
      const cleanedItem = value.replace('N', '');
      return parseFloat(cleanedItem);
    }
    return null;
  }

  getFrequency(frequency: string): string {
    return this.frequencies.find((freq) => freq.key === frequency).value;
  }

  private getPointStyleArray(values: any[]) {
    return values.map((val) => this.getPointStyle(val.conductionId, val.originalValue.includes('N')));
  }

  private getPointStyle(conductionId: string, noResponse: boolean) {
    const myImage = new Image(this.iconSize.width, this.iconSize.height);
    const suffix = noResponse ? '_nr' : '';

    const imageSrcMap: { [key: string]: string } = {
      [DhhTestAudiogramConductionIds.RightEarAirConductionUnmasked]: `right_ac_unmasked${suffix}.svg`,
      [DhhTestAudiogramConductionIds.LeftEarAirConductionUnmasked]: `left_ac_unmasked${suffix}.svg`,
      [DhhTestAudiogramConductionIds.RightEarAirConductionMasked]: `right_ac_masked${suffix}.svg`,
      [DhhTestAudiogramConductionIds.LeftEarAirConductionMasked]: `left_ac_masked${suffix}.svg`,
      [DhhTestAudiogramConductionIds.RightEarBoneConductionMasked]: `right_bc_mastoid_masked${suffix}.svg`,
      [DhhTestAudiogramConductionIds.LeftEarBoneConductionMasked]: `left_bc_mastoid_masked${suffix}.svg`,
      [DhhTestAudiogramConductionIds.RightEarBoneConductionUnmasked]: `right_bc_mastoid_unmasked${suffix}.svg`,
      [DhhTestAudiogramConductionIds.LeftEarBoneConductionUnmasked]: `left_bc_mastoid_unmasked${suffix}.svg`,
      [DhhTestAudiogramConductionIds.SoundfieldAided]: `sound_field${suffix}.svg`,
      [DhhTestAudiogramConductionIds.SoundfieldUnaided]: `sound_field${suffix}.svg`,
      [DhhTestAudiogramConductionIds.CenterBoneConductionUnmasked]: `bc_center${suffix}.svg`,
    };

    const src = imageSrcMap[conductionId.toUpperCase()] || 'circle';
    myImage.src = `assets/img/icons/audiogram/${src}`;
    return myImage;
  }

  lineSplitterPlugin() {
    return {
      id: 'lineSplitterPlugin',

      beforeDatasetDraw: function (chart) {
        const ctx = chart.chart.ctx;

        chart.data.datasets.forEach((dataset, index) => {
          const meta = chart.getDatasetMeta(index);
          for (let i = 0; i < meta.data.length - 1; i++) {
            const point1 = meta.data[i];
            const point2 = meta.data[i + 1];
            const value1 = dataset.data[i].originalValue;
            const value2 = dataset.data[i + 1].originalValue;

            if (value1.includes('N') || value2.includes('N')) {
              ctx.strokeStyle = 'rgba(0, 0, 0, 0)';
            } else {
              ctx.strokeStyle = dataset.borderColor;
            }

            const pointRadius = dataset.pointRadius;

            const angle = Math.atan2(point2._model.y - point1._model.y, point2._model.x - point1._model.x);

            const newPoint1X = point1._model.x + pointRadius * Math.cos(angle);
            const newPoint1Y = point1._model.y + pointRadius * Math.sin(angle);
            const newPoint2X = point2._model.x - pointRadius * Math.cos(angle);
            const newPoint2Y = point2._model.y - pointRadius * Math.sin(angle);

            ctx.lineWidth = 0.1;
            ctx.beginPath();
            ctx.moveTo(newPoint1X, newPoint1Y);
            ctx.lineTo(newPoint2X, newPoint2Y);
            ctx.stroke();
          }
        });
      },
    };
  }
}
