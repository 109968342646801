<div class="user-guide-link w-100 mb-3">
  <a
    *ngIf="surveyType === surveyTypes.EarlyAccessFamilyEngagementSurvey"
    [href]="linkService.getEarlyAccessLink('familyEngagementSurvey')"
    target="_blank"
    role="link"
    aria-label="Early ACCESS Family Engagement Survey User Guide"
    >Early ACCESS Family Engagement Survey User Guide</a
  >
  <a
    *ngIf="surveyType === surveyTypes.SpecialEducationFamilyEngagementSurvey"
    [href]="linkService.getSpecialEducationLink('familyEngagementSurvey')"
    target="_blank"
    role="link"
    aria-label="Special Education Family Engagement Survey User Guide"
    >Special Education Family Engagement Survey User Guide</a
  >
</div>
<div class="row w-100" [formGroup]="formGroup">
  <div class="col">
    <mat-form-field>
      <mat-label>Filter by AEA</mat-label>
      <mat-select formControlName="aeas" multiple placeholder="AEA">
        <mat-option>
          <ngx-mat-select-search [formControl]="aeaFilterControl" placeholderLabel="" noEntriesFoundLabel=""></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let aea of filteredAeas | async" [value]="aea.id">{{ aea.label }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col">
    <mat-form-field>
      <mat-label>Filter by LEA</mat-label>
      <mat-select formControlName="leas" multiple placeholder="LEA">
        <mat-option>
          <ngx-mat-select-search [formControl]="leaFilterControl" placeholderLabel="" noEntriesFoundLabel=""></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let lea of filteredLeas | async" [value]="lea.id">{{ lea.label }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col">
    <mat-form-field>
      <mat-label>Filter by Building</mat-label>
      <mat-select formControlName="buildings" multiple placeholder="Building">
        <mat-option>
          <ngx-mat-select-search [formControl]="buildingFilterControl" placeholderLabel="" noEntriesFoundLabel=""></ngx-mat-select-search>
        </mat-option>
        <mat-option *ngFor="let building of filteredBuildings | async" [value]="building.id">{{ building.label }} </mat-option>
      </mat-select>
    </mat-form-field>
  </div>
  <div class="col">
    <mat-form-field>
      <mat-label>Filter by School Year</mat-label>
      <mat-select formControlName="schoolYearId" placeholder="School Year">
        <mat-option *ngFor="let schoolYear of schoolYears" [value]="schoolYear.id">{{ schoolYear.label }}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>
</div>
<ng-container *ngIf="!learnerSurveyReport?.hasData">Not enough data...</ng-container>
<ng-container *ngIf="learnerSurveyReport?.hasData">
  <mat-card class="card-spacing-top">
    <mat-card-header>
      <h3>Overall</h3>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col-6">
          <div class="table-overflow w-100">
            <mat-table [dataSource]="dataSource" class="mb-5">
              <ng-container matColumnDef="sent">
                <mat-header-cell *matHeaderCellDef>Surveys Sent</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.sent }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="responses">
                <mat-header-cell *matHeaderCellDef>Responses</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.responses }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="responseRate">
                <mat-header-cell *matHeaderCellDef>Response Rate</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.responseRate }}%</mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="['sent', 'responses', 'responseRate']"></mat-header-row>
              <mat-row *matRowDef="let element; columns: ['sent', 'responses', 'responseRate']"></mat-row>
            </mat-table>
          </div>
        </div>
        <div class="col-6" *ngIf="overallPieChart.data?.length > 0">
          <app-survey-reporting-chart [pieChart]="overallPieChart"></app-survey-reporting-chart>
        </div>
      </div>
      <hr class="input__divider my-2" />
      <div class="row">
        <div class="col">
          <div class="table-overflow w-100">
            <mat-table [dataSource]="dataSourceSurveyStatus" class="mb-5">
              <ng-container matColumnDef="status">
                <mat-header-cell *matHeaderCellDef>Status</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.status }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="count">
                <mat-header-cell *matHeaderCellDef>Count</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.cnt }}</mat-cell>
              </ng-container>

              <ng-container matColumnDef="percentOfAllSentSurveys">
                <mat-header-cell *matHeaderCellDef>Percent of All Sent Surveys</mat-header-cell>
                <mat-cell *matCellDef="let element"> {{ element.pct }}%</mat-cell>
              </ng-container>

              <mat-header-row *matHeaderRowDef="['status', 'count', 'percentOfAllSentSurveys']"></mat-header-row>
              <mat-row *matRowDef="let element; columns: ['status', 'count', 'percentOfAllSentSurveys']"></mat-row>
            </mat-table>
          </div>
        </div>
        <div class="col-6" *ngIf="statusPieChart.data?.length > 0">
          <app-survey-reporting-chart [pieChart]="statusPieChart"></app-survey-reporting-chart>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button
            mat-flat-button
            aria-label="Download Summary Data"
            color="accent"
            class="mat-elevation-z0 mr-3"
            tabindex="0"
            (click)="onOpenExport('summary')"
          >
            Download Summary Data
          </button>
          <button
            mat-flat-button
            aria-label="Download Survey Responses"
            color="accent"
            class="mat-elevation-z0 mr-3"
            tabindex="0"
            (click)="onOpenExport('details')"
          >
            Download Survey Responses
          </button>
          <button
            mat-flat-button
            aria-label="Download Raw Data"
            color="accent"
            class="mat-elevation-z0 mr-3"
            tabindex="0"
            (click)="onOpenExport('raw')"
          >
            Download Raw Data
          </button>
        </div>
      </div>
    </mat-card-content>
  </mat-card>

  <mat-card class="card-spacing-top" *ngIf="learnerSurveyReport?.races?.length > 0">
    <mat-card-header>
      <h3>Race</h3>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <app-survey-reporting-table [data]="learnerSurveyReport.races"></app-survey-reporting-table>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p><strong>Average Response Rate:</strong> {{ getAverageResponseRate(learnerSurveyReport?.races) }}%</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <ng-container *ngFor="let demographic of learnerSurveyReport?.races">
            <button
              mat-raised-button
              aria-label="Send Nudge Button"
              color="primary"
              tabindex="0"
              class="m-1"
              (click)="sendNudge('demographics', demographic.id)"
            >
              Send Nudge for Demographic {{ demographic.label }}
            </button>
          </ng-container>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="card-spacing-top" *ngIf="learnerSurveyReport?.genders?.length > 0">
    <mat-card-header>
      <h3>Gender</h3>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <app-survey-reporting-table [data]="learnerSurveyReport.genders"></app-survey-reporting-table>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p><strong>Average Response Rate:</strong> {{ getAverageResponseRate(learnerSurveyReport?.genders) }}%</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <ng-container *ngFor="let gender of learnerSurveyReport?.genders">
            <button
              mat-raised-button
              aria-label="Send Nudge Button"
              color="primary"
              tabindex="0"
              class="m-1"
              (click)="sendNudge('gender', gender.id)"
            >
              Send Nudge for Gender {{ gender.label }}
            </button>
          </ng-container>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="card-spacing-top" *ngIf="dataSourceFamilyInvolvementResult?.data?.length > 0">
    <mat-card-header>
      <h3>Early ACCESS Family Involvement Survey Results</h3>
    </mat-card-header>
    <mat-card-content>
      <p>Early ACCESS Services Helped Families:</p>
      <div class="table-overflow w-100">
        <mat-table [dataSource]="dataSourceFamilyInvolvementResult" class="mb-5">
          <ng-container matColumnDef="label">
            <mat-header-cell *matHeaderCellDef></mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.rowlabel }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="totalSurveyResponsesIncluded">
            <mat-header-cell *matHeaderCellDef>Total Survey Responses Included</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.totalSurveys }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="yesResponses">
            <mat-header-cell *matHeaderCellDef>Yes Responses</mat-header-cell>
            <mat-cell *matCellDef="let element"> {{ element.yesCnt }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="yesPercentage">
            <mat-header-cell *matHeaderCellDef>Percentage</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.yesPerc | percent }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="noResponses">
            <mat-header-cell *matHeaderCellDef>No Responses</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.noCnt }}</mat-cell>
          </ng-container>

          <ng-container matColumnDef="noPercentage">
            <mat-header-cell *matHeaderCellDef>Percentage</mat-header-cell>
            <mat-cell *matCellDef="let element">{{ element.noPerc | percent }}</mat-cell>
          </ng-container>

          <mat-header-row
            *matHeaderRowDef="['label', 'totalSurveyResponsesIncluded', 'yesResponses', 'yesPercentage', 'noResponses', 'noPercentage']"
          ></mat-header-row>
          <mat-row
            *matRowDef="
              let element;
              columns: ['label', 'totalSurveyResponsesIncluded', 'yesResponses', 'yesPercentage', 'noResponses', 'noPercentage']
            "
          ></mat-row>
        </mat-table>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card class="card-spacing-top" *ngIf="learnerSurveyReport?.ages?.length > 0">
    <mat-card-header>
      <h3>Age</h3>
    </mat-card-header>
    <mat-card-content>
      <div class="row">
        <div class="col">
          <app-survey-reporting-table [data]="learnerSurveyReport.ages"></app-survey-reporting-table>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p><strong>Average Response Rate:</strong> {{ getAverageResponseRate(learnerSurveyReport?.ages) }}%</p>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <ng-container *ngFor="let age of learnerSurveyReport?.ages">
            <button
              mat-raised-button
              aria-label="Send Nudge Button"
              color="primary"
              tabindex="0"
              class="m-1"
              (click)="sendNudge('age', age.altId)"
            >
              Send Nudge for age {{ age.label }}
            </button>
          </ng-container>
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</ng-container>
