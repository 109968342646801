<div #activitySupportForEdit></div>
<form [formGroup]="formGroup" #formDirective="ngForm">
  <div class="row">
    <div class="col-md-12 col-xl-6">
      <app-autocomplete label="Activity or Support" formControlName="activitySupportTypeId" [options]="sasOptions"> </app-autocomplete>
      <app-compare-amendment-output
        *ngIf="amendingActivitySupport"
        class="mr-1"
        [amendmentId]="amendmentId"
        [amendmentFinalized]="amendmentIsFinalized"
        [amendmentDate]="lastFinalizedDate(amendingActivitySupport?.amendmentId)"
        [priorVersionId]="amendingActivitySupport?.priorVersionId"
        [isActive]="amendingActivitySupport.isActive"
        [changedVersionText]="getActivitySupportTypeLabel(formGroup.get('activitySupportTypeId').value)"
        [currentVersionText]="
          amendingActivitySupport?.priorVersion
            ? getActivitySupportTypeLabel(amendingActivitySupport?.priorVersion.activitySupportTypeId)
            : null
        "
        [currentPrefixText]="amendingActivitySupport?.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      >
      </app-compare-amendment-output>
      <div
        class="col-md-12"
        *ngIf="
          (!aTIsSpecialFactor && showRequireDeviceType) ||
          (isAssistiveEducationalMaterials && !aemIsSpecialFactor) ||
          (isAssistiveTechnologySupport && !aTIsSpecialFactor)
        "
      >
        NOTE: Please review and update the PLAAFP Special Factors for Assistive Technology.
      </div>
      <div class="col-md-12" *ngIf="isLinkagesInterAgencyResponsibilities">
        NOTE: Parental consent is required to invite external agency representatives.
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
      <app-textarea
        label="Description"
        [isBankableField]="true"
        [bankableFieldId]="bankableFieldIds.iepSsaaActivities.description"
        formControlName="description"
        [rows]="4"
      ></app-textarea>
      <app-compare-amendment-output
        *ngIf="amendingActivitySupport"
        class="mr-1"
        [amendmentId]="amendmentId"
        [amendmentFinalized]="amendmentIsFinalized"
        [amendmentDate]="lastFinalizedDate(amendingActivitySupport?.amendmentId)"
        [priorVersionId]="amendingActivitySupport?.priorVersionId"
        [isActive]="amendingActivitySupport.isActive"
        [changedVersionText]="formGroup.get('description').value"
        [currentVersionText]="amendingActivitySupport?.priorVersion?.description"
        [currentPrefixText]="amendingActivitySupport?.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      >
      </app-compare-amendment-output>
    </div>

    <div class="col-md-12" [ngClass]="{ 'col-lg-6': !amendingActivitySupport }">
      <app-autocomplete label="Frequency" formControlName="frequencyPeriod" [options]="frequencyPeriodOptions"> </app-autocomplete>
      <app-compare-amendment-output
        *ngIf="amendingActivitySupport"
        class="mr-1"
        [amendmentId]="amendmentId"
        [amendmentFinalized]="amendmentIsFinalized"
        [amendmentDate]="lastFinalizedDate(amendingActivitySupport?.amendmentId)"
        [priorVersionId]="amendingActivitySupport?.priorVersionId"
        [isActive]="amendingActivitySupport.isActive"
        [changedVersionText]="getFrequencyPeriodLabel(formGroup.get('frequencyPeriod').value)"
        [currentVersionText]="
          amendingActivitySupport?.priorVersion ? getFrequencyPeriodLabel(amendingActivitySupport?.priorVersion.frequencyPeriod) : null
        "
        [currentPrefixText]="amendingActivitySupport?.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      >
      </app-compare-amendment-output>
    </div>

    <div class="col-md-12" [ngClass]="{ 'col-lg-6': !amendingActivitySupport }">
      <app-autocomplete-chips formControlName="providers" [options]="providerOptions" label="Providers"> </app-autocomplete-chips>
      <app-compare-amendment-output
        *ngIf="amendingActivitySupport"
        class="mr-1"
        [amendmentId]="amendmentId"
        [amendmentFinalized]="amendmentIsFinalized"
        [amendmentDate]="lastFinalizedDate(amendingActivitySupport?.amendmentId)"
        [priorVersionId]="amendingActivitySupport?.priorVersionId"
        [isActive]="amendingActivitySupport.isActive"
        [changedVersionText]="getProviderLabels(formGroup.get('providers').value)"
        [currentVersionText]="
          amendingActivitySupport?.priorVersion ? getPriorProviderLabels(amendingActivitySupport?.priorVersion.providers) : null
        "
        [currentPrefixText]="amendingActivitySupport?.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      ></app-compare-amendment-output>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-6 offset-lg-6">
      <app-checkbox-single
        class="mt-2 mb-2"
        formControlName="otherProvider"
        aria-labelledby="Provider is outside of ACHIEVE"
        label="Provider is outside of ACHIEVE"
      >
      </app-checkbox-single>
    </div>
    <div class="col-md-12" *ngIf="formGroup.get('otherProvider').value">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <app-text-field label="Provider Name" formControlName="otherProviderName"></app-text-field>
          <app-compare-amendment-output
            *ngIf="amendingActivitySupport"
            [amendmentId]="amendingActivitySupport.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate(amendingActivitySupport?.amendmentId)"
            [priorVersionId]="amendingActivitySupport?.priorVersionId"
            [isActive]="amendingActivitySupport?.isActive"
            [changedVersionText]="formGroup.get('otherProviderName').value"
            [currentVersionText]="amendingActivitySupport?.priorVersion ? amendingActivitySupport?.priorVersion.otherProviderName : null"
            [currentPrefixText]="amendingActivitySupport?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
        <div class="col-md-12 col-lg-6">
          <app-text-field label="Provider Agency" formControlName="otherProviderAgency"></app-text-field>
          <app-compare-amendment-output
            *ngIf="amendingActivitySupport"
            [amendmentId]="amendingActivitySupport.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate(amendingActivitySupport?.amendmentId)"
            [priorVersionId]="amendingActivitySupport?.priorVersionId"
            [isActive]="amendingActivitySupport?.isActive"
            [changedVersionText]="formGroup.get('otherProviderAgency').value"
            [currentVersionText]="amendingActivitySupport?.priorVersion ? amendingActivitySupport?.priorVersion.otherProviderAgency : null"
            [currentPrefixText]="amendingActivitySupport?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
        <div class="col-md-12 col-lg-6">
          <app-text-field label="Provider Role" formControlName="otherProviderRole"></app-text-field>
          <app-compare-amendment-output
            *ngIf="amendingActivitySupport"
            [amendmentId]="amendingActivitySupport.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate(amendingActivitySupport?.amendmentId)"
            [priorVersionId]="amendingActivitySupport?.priorVersionId"
            [isActive]="amendingActivitySupport?.isActive"
            [changedVersionText]="formGroup.get('otherProviderRole').value"
            [currentVersionText]="amendingActivitySupport?.priorVersion ? amendingActivitySupport?.priorVersion.otherProviderRole : null"
            [currentPrefixText]="amendingActivitySupport?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="iepIncludesTrialPlacement" class="row">
    <div class="col-md-12">
      <app-checkbox-single
        class="display-inline-block"
        formControlName="includesTrialPlacement"
        aria-labelledby="Include in Trial Placement"
        label="Include in Trial Placement"
      >
      </app-checkbox-single>
    </div>
  </div>

  <ng-container *ngIf="showRequireDeviceType">
    <div class="row">
      <div class="col-md-6">
        <mat-label> Does the learner require this device at home or in other settings? </mat-label>
        <app-autocomplete-chips formControlName="requireDeviceType" [options]="requireDeviceTypeOptions"> </app-autocomplete-chips>
        <app-compare-amendment-output
          *ngIf="amendingActivitySupport"
          class="mr-1"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate(amendingActivitySupport?.amendmentId)"
          [priorVersionId]="amendingActivitySupport?.priorVersionId"
          [isActive]="amendingActivitySupport.isActive"
          [changedVersionText]="getRequireDeviceTypeLabel(formGroup.get('requireDeviceType').value)"
          [currentVersionText]="getRequireDeviceTypeLabel(amendingActivitySupport?.priorVersion?.requireDeviceType)"
          [currentPrefixText]="amendingActivitySupport?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </div>
      <div class="col-md-6" *ngIf="showOtherSettingField">
        <app-text-field label="Other Device Setting" formControlName="otherDeviceSetting"> </app-text-field>
        <app-compare-amendment-output
          *ngIf="amendingActivitySupport"
          class="mr-1"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate(amendingActivitySupport?.amendmentId)"
          [isActive]="amendingActivitySupport.isActive"
          [priorVersionId]="amendingActivitySupport?.priorVersionId"
          [changedVersionText]="formGroup.get('otherDeviceSetting').value"
          [currentVersionText]="amendingActivitySupport.priorVersion?.otherDeviceSetting"
          [currentPrefixText]="amendingActivitySupport?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showDeviceRequireAEM">
    <div class="row">
      <div class="col-md-12">
        <fieldset>
          <app-radio-group label="Does the use of this device require AEM?" formControlName="deviceRequireAEM" [options]="yesNoOptions">
          </app-radio-group>
        </fieldset>
        <app-compare-amendment-output
          *ngIf="amendingActivitySupport"
          class="mr-1"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate(amendingActivitySupport?.amendmentId)"
          [priorVersionId]="amendingActivitySupport?.priorVersionId"
          [isActive]="amendingActivitySupport.isActive"
          [changedVersionText]="getYesNoLabel(formGroup.get('deviceRequireAEM').value)"
          [currentVersionText]="getYesNoLabel(amendingActivitySupport.priorVersion?.deviceRequireAEM)"
          [currentPrefixText]="amendingActivitySupport?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
        <div *ngIf="formGroup.controls.deviceRequireAEM.value && !aemIsSpecialFactor">
          NOTE: consider making AEM a Special Factor on PLAAFP.
        </div>
      </div>
    </div>
  </ng-container>

  <div class="action__row action__row--between mt-0">
    <div>
      <button
        *ngIf="!formGroup.controls.taggedForPwn?.value"
        class="ml-1"
        mat-raised-button
        aria-label="Tag for Prior Written Notice Button"
        (click)="tag(true)"
        tabindex="0"
      >
        Tag for Prior Written Notice
      </button>
      <button
        *ngIf="formGroup.controls.taggedForPwn?.value"
        mat-raised-button
        aria-label="Untag for Prior Written Notice"
        color="primary"
        (click)="tag(false)"
        tabindex="0"
      >
        Untag for Prior Written Notice
      </button>
    </div>

    <div>
      <button
        type="button"
        mat-raised-button
        aria-label="View Summary Button"
        color="accent"
        (click)="viewSummary()"
        *ngIf="!hasAmendment"
        tabindex="0"
      >
        View Summary
      </button>
      <button
        mat-raised-button
        aria-label="Cancel Button"
        color="warn"
        (click)="onCancel()"
        [disabled]="!activitySupportId && !hasAmendment"
        *ngIf="isNew || hasPriorVersion"
        type="button"
        tabindex="0"
      >
        Cancel
        <mat-icon class="ml-2" aria-label="Cancel Button">close</mat-icon>
      </button>
      <button
        mat-raised-button
        aria-label="Delete Button"
        color="warn"
        (click)="onDelete()"
        [disabled]="!activitySupportId && !hasAmendment"
        *ngIf="!isNew && !hasPriorVersion"
        type="button"
        tabindex="0"
      >
        Delete
        <mat-icon class="ml-2" aria-label="Delete">delete</mat-icon>
      </button>
      <button
        mat-raised-button
        aria-label="Save Button"
        color="primary"
        (click)="saveAndClose()"
        [disabled]="!activitySupportId"
        [disableAfterBusy]="!activitySupportId"
        [isBusy]="activeCall"
        tabindex="0"
      >
        Save
      </button>
      <button
        *ngIf="!isGoalsPage && !hasAmendment"
        mat-raised-button
        aria-label="Complete and Add Goal Button"
        color="primary"
        (click)="submitAndAddGoal()"
        [disabled]="formGroup.invalid"
        [disableAfterBusy]="formGroup.invalid"
        [isBusy]="activeCall"
        tabindex="0"
      >
        Complete and Add Goal
      </button>
      <button
        mat-raised-button
        aria-label="Complete"
        color="primary"
        [disabled]="formGroup.invalid"
        (click)="submit()"
        [disableAfterBusy]="formGroup.invalid"
        [isBusy]="activeCall"
        tabindex="0"
      >
        Complete
      </button>
    </div>
  </div>
</form>
<ng-container *ngIf="!hasAmendment">
  <hr class="input__divider" />

  <h3 class="mt-0 mb-2">Activities and Supports List</h3>
  <mat-card class="my-0">
    <app-iep-activities-supports-table
      #activitySupportList
      (editActivitySupport)="onEdit($event)"
      [amendmentId]="amendmentId"
      [amendments]="amendments"
      [iepId]="iepId"
      [isEditing]="!!this.activitySupport"
      [iepIncludesTrialPlacement]="iepIncludesTrialPlacement"
      [trialPlacementTarget]="trialPlacementTarget"
    >
    </app-iep-activities-supports-table>
  </mat-card>
</ng-container>
