import { Component } from '@angular/core';

@Component({
  selector: 'app-choose-download-type',
  templateUrl: './choose-download-type.component.html',
  styleUrls: ['./choose-download-type.component.scss'],
})
export class ChooseDownloadTypeComponent {
  formats = ['XLSX', 'CSV'];

  constructor() {}
}
