<ng-container *ngIf="!willPrint && !readonly">
  <header>
    <app-page-header
      [pageTitle]="pageTitle"
      [helpLinks]="false"
      [condensed]="true"
      backgroundColor="yellow"
      [shouldShowDhhBanner]="currentCase?.learner.isDhh"
    >
    </app-page-header>
  </header>
  <app-learner-info-header [learnerId]="currentCase?.learnerId" [condensed]="true"></app-learner-info-header>

  <main class="">
    <ng-container [formGroup]="formGroup">
      <mat-card class="pt-2">
        <p class="my-0">
          <strong>The school is proposing</strong>

          to complete an initial special education evaluation of
          {{ currentCase.learner.firstName }} and to obtain your consent for this evaluation.
        </p>
        <p class="my-1">
          The purpose of the evaluation is to determine interventions needed to resolve presenting problem(s) or behavior(s) of concern and
          to determine whether or not those interventions require special education.
        </p>
        <p class="my-0">
          The team, including you, and, as appropriate, other qualified professionals will address the following questions:
        </p>
        <ul class="mt-1 mb-0">
          <li>Does {{ currentCase.learner.firstName }} have a disability?</li>
          <li>
            In comparison to peers or standards, what are
            {{ currentCase.learner.firstName | firstNamePossessive }} present levels of performance, rate of progress and educational needs?
          </li>
          <li>Does {{ currentCase.learner.firstName }} need special education and related services?</li>
        </ul>
      </mat-card>
      <mat-card class="card-spacing-top py-1">
        <div class="row">
          <div class="col-md-12">
            <app-textarea
              formControlName="evaluationExplanation"
              label="The school is proposing this evaluation because:"
              [rows]="4"
            ></app-textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <app-textarea
              formControlName="otherOptionsAndReasonsForRejection"
              label="Other options the school considered and the reason why those options were rejected prior to this proposal included:"
              [rows]="4"
            ></app-textarea>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <fieldset>
              <app-radio-group
                label="Are there other factors relevant to the school’s proposed action?"
                [options]="yesNoOptions"
                formControlName="otherFactorsRelevant"
              >
              </app-radio-group>
            </fieldset>
            <app-textarea
              *ngIf="formGroup.controls.otherFactorsRelevant.value"
              formControlName="otherFactors"
              label="Description of the relevant factors:"
              [rows]="4"
            ></app-textarea>
          </div>
        </div>
      </mat-card>
      <mat-card class="card-spacing-top">
        <h4 class="text-primary mt-0 mb-2">
          The following sources of information were used in the development of this Consent for and Notice of Full and Individual Initial
          Evaluation
        </h4>
        <p class="mt-0 mb-1">
          All available information was reviewed, including the results of previous interventions, educational records, information provided
          by individuals with knowledge of
          {{ currentCase.learner.firstName }} (e.g., you, the parents; teachers; caregivers; {{ currentCase.learner.firstName }}; etc.),
          observations of {{ currentCase.learner.firstName }} in learning environments, and any tests or other assessments.
        </p>
        <div class="row my-1">
          <div class="col-md-12">
            <mat-label
              [ngClass]="{
                asterisk_input: formGroup.controls.domains.hasError('minimumCount')
              }"
            >
              The following
              <a
                class="text-underline"
                (click)="onOpenHelp($event)"
                tabindex="0"
                role="link"
                attr.aria-label="{{ fiieConsentHelp.Domains }}"
              >
                {{ fiieConsentHelp.Domains }}</a
              >
              to be included in the Full and Individual Initial Evaluation:
            </mat-label>
          </div>
          <div class="mt-2">
            <div class="col-md-12">
              <mat-button-toggle-group multiple>
                <mat-button-toggle
                  *ngFor="let domain of domains"
                  class="domain__button"
                  (change)="domainChanged(domain)"
                  [checked]="isChecked(domain)"
                  [ngClass]="{
                    'background-color--secondary text--white': isChecked(domain)
                  }"
                  attr.aria-label="{{ domain.label }}"
                  >{{ domain.label }}
                </mat-button-toggle>
              </mat-button-toggle-group>
            </div>
          </div>
        </div>
        <mat-error *ngIf="formGroup.controls.domains.touched && formGroup.controls.domains.hasError('minimumCount')">
          Included domains are <strong>required</strong>
        </mat-error>

        <p class="my-1">
          If a need for evaluation information of other domains arises during the evaluation they will be included in the evaluation.
        </p>

        <p class="mt-0 mb-1">
          Evaluation methods will be determined by the individual professionals involved in the evaluation and may include:
        </p>
        <ul class="my-0">
          <li>
            Review of the results of previous instruction/interventions and relevant records (e.g., general education data, school records,
            work samples, previous evaluations, private evaluation reports, etc.)
          </li>
          <li>
            Interviews of individuals with knowledge of
            {{ currentCase.learner.firstName }} (teachers, parents, {{ currentCase.learner.firstName }}, etc.)
          </li>
          <li>Observations completed by team members</li>
          <li>Tests/tasks (classroom-based, district-wide, and individually administered)</li>
        </ul>
      </mat-card>

      <mat-card class="card-spacing-top py-1">
        <div class="row">
          <div class="col-md-6">
            <app-autocomplete
              [options]="participantOptions"
              formControlName="evaluationFacilitatorId"
              label="Evaluation Facilitator"
            ></app-autocomplete>
          </div>
          <div class="col-md-6">
            <app-date-picker
              label="Notification Date:"
              controlName="notificationDate"
              [max]="today"
              [min]="dsFinalizedDate"
            ></app-date-picker>
          </div>
        </div>
      </mat-card>

      <mat-card class="card-spacing-top py-1">
        <div class="row display-flex justify-content-between align-items-center">
          <div class="col-md-12 col-lg-4">
            <app-autocomplete [options]="availableContacts" formControlName="contactName" label="Contact Person"></app-autocomplete>
          </div>
          <div class="col-md-12 col-lg-4 display-flex justify-content-end">
            <mat-checkbox
              formControlName="alternateContact"
              (change)="addAlternativeContact()"
              aria-labelledby="Check Add Alternate Contact"
            >
              Add Alternate Contact
            </mat-checkbox>
          </div>
        </div>

        <div
          class="row mt-3"
          *ngIf="
            formGroup.contains('alternateContactName') &&
            formGroup.contains('alternateContactPosition') &&
            formGroup.contains('alternateContactEmail') &&
            formGroup.contains('alternateContactPhone')
          "
        >
          <div class="col-md-6">
            <app-text-field label="Name" formControlName="alternateContactName"></app-text-field>
          </div>
          <div class="col-md-6">
            <app-text-field label="Position" formControlName="alternateContactPosition"></app-text-field>
          </div>
          <div class="col-md-6">
            <app-text-field label="Email" formControlName="alternateContactEmail"></app-text-field>
          </div>
          <div class="col-md-6">
            <app-masked-input label="Phone" formControlName="alternateContactPhone" mask="(000) 000-0000"> </app-masked-input>
          </div>
        </div>
      </mat-card>

      <div class="display-flex justify-content-end align-items-center mt-4">
        <mat-error *ngIf="submitAttempted && !isValid" class="mr-5 text-sm">Some input is <b>invalid</b>. See above</mat-error>
        <button
          mat-raised-button
          aria-label="Print"
          (click)="onTogglePrint($event)"
          [disabled]="!isValid"
          [title]="!isValid ? 'You must enter all required fields to print' : 'Print'"
          color="accent"
          class="mr-2"
          tabindex="0"
        >
          Print
        </button>

        <button
          mat-raised-button
          aria-label="Save Button"
          color="accent"
          class="mr-2"
          (click)="onSaveAndClose()"
          [isBusy]="activeCall"
          tabindex="0"
        >
          Save
        </button>
        <button
          mat-raised-button
          aria-label="Complete"
          color="primary"
          class="mr-2"
          [title]="!isValid ? 'You must enter all required fields to continue' : 'Complete'"
          (click)="onSubmit()"
          [isBusy]="activeCall"
          [disabled]="!isValid"
          tabindex="0"
        >
          Complete
        </button>
      </div>
    </ng-container>
  </main>
</ng-container>

<div class="print-preview" *ngIf="!fiieConsentForm?.isSubmitted">
  <h1 class="print-preview__text">DRAFT</h1>
</div>

<ng-container *ngIf="willPrint || readonly">
  <section [ngClass]="{ print__container: willPrint === true }">
    <ng-container *ngIf="readonly">
      <app-page-header
        [pageTitle]="pageTitle"
        [helpLinks]="false"
        backgroundColor="yellow"
        [condensed]="true"
        [shouldShowDhhBanner]="currentCase?.learner.isDhh"
      >
        <ng-container pageActions>
          <button mat-raised-button aria-label="Print Button" color="primary" (click)="onPrint()" tabindex="0">Print</button>
        </ng-container>
      </app-page-header>
      <app-learner-info-header [learnerId]="currentCase?.learnerId"></app-learner-info-header>
    </ng-container>

    <ng-container *ngIf="willPrint">
      <app-print-head title="FIIE Consent" [showLogo]="true"></app-print-head>
    </ng-container>

    <div class="printable">
      <ul class="list">
        <li class="list__item avoid-break">
          <h4 class="text-primary mt-0 mb-1">The school is proposing this evaluation because:</h4>
          <p class="my-0">
            {{ formGroup.get('evaluationExplanation').value }}
          </p>
        </li>

        <li class="list__item avoid-break">
          <h4 class="text-primary mt-0 mb-1">
            Other options the school considered and the reason why those options were rejected prior to this proposal included:
          </h4>
          <p class="my-0">
            {{ formGroup.get('otherOptionsAndReasonsForRejection').value }}
          </p>
        </li>

        <li class="list__item avoid-break">
          <h4 class="text-primary mt-0 mb-1">Description of the factors relevant to the school’s proposed action?:</h4>
          <p class="my-0">
            {{ formGroup.get('otherFactors').value }}
          </p>
        </li>

        <h4 class="text-primary mt-0 mb-1">
          The following sources of information were used in the development of this Consent for and Notice of Full and Individual Initial
          Evaluation
        </h4>
        <p>
          All available information was reviewed, including the results of previous interventions, educational records, information provided
          by individuals with knowledge of
          {{ currentCase.learner.firstName }} (e.g., you, the parents; teachers; caregivers; {{ currentCase.learner.firstName }}; etc.),
          observations of {{ currentCase.learner.firstName }} in learning environments, and any tests or other assessments.
        </p>

        <li class="list__item avoid-break">
          <h4 class="text-primary mt-0 mb-1">The following domain(s) to be included in the Full and Individual Initial Evaluation:</h4>
          <p class="my-0">
            <span class="printable__answer--multiple" *ngFor="let domain of getSortedDomains(formGroup.get('domains').value)">
              {{ domain.label }}
            </span>
          </p>
          <p class="mt-2">
            If a need for evaluation information of other domains arises during the evaluation they will be included in the evaluation.
          </p>

          <p class="mt-2">
            Evaluation methods will be determined by the individual professionals involved in the evaluation and may include:
          </p>
          <ul class="mt-2">
            <li>
              Review of the results of previous instruction/interventions and relevant records (e.g., general education data, school
              records, work samples, previous evaluations, private evaluation reports, etc.)
            </li>
            <li>
              Interviews of individuals with knowledge of
              {{ currentCase.learner.firstName }} (teachers, parents, {{ currentCase.learner.firstName }}, etc.)
            </li>
            <li>Observations completed by team members</li>
            <li>Tests/tasks (classroom-based, district-wide, and individually administered)</li>
          </ul>
        </li>

        <li class="list__item avoid-break">
          <h4 class="text-primary mt-0 mb-1">Evaluation Facilitator:</h4>
          <p class="my-0">
            {{
              formGroup.get('evaluationFacilitatorId').value
                ? getFacilitatorReviewer(formGroup.get('evaluationFacilitatorId').value).fullName
                : ''
            }}
          </p>
        </li>

        <li class="list__item avoid-break">
          <div class="display-flex align-items-start">
            <div class="mr-2">
              <h4 class="text-primary mt-0 mb-1">Procedural safeguards were reviewed by:</h4>
              <p class="my-0">
                {{ formGroup.get('reviewedById')?.value ? getFacilitatorReviewer(formGroup.get('reviewedById')?.value).fullName : '' }}
              </p>
            </div>

            <div class="ml-3">
              <h4 class="text-primary mt-0 mb-1">Procedural safeguards were reviewed on:</h4>
              <p class="my-0">
                {{ formGroup.get('reviewedOn')?.value | dateFormat }}
              </p>
            </div>

            <div class="ml-3">
              <h4 class="text-primary mt-0 mb-1">How:</h4>
              <p class="my-0">
                {{ formGroup.get('reviewedHow')?.value }}
              </p>
            </div>
          </div>
        </li>

        <li class="list__item avoid-break">
          <h4 class="text-primary mt-0 mb-1">Printed Copy Declined:</h4>
          <p class="my-0">
            {{ formGroup.get('hardCopyDeclined')?.value ? 'Yes' : 'No' }}
          </p>
        </li>
      </ul>

      <hr class="input__divider mt-2 mb-4" />

      <div class="mb-3 display-flex">
        <div class="signature__fill mb-0 mr-4">
          <p class="mb-0">Full Name:</p>
          <div></div>
        </div>
        <div class="signature__fill mb-0">
          <p class="mb-0">Date:</p>
          <div></div>
        </div>
      </div>

      <p class="mb-3">
        I understand my rights related to this evaluation and I give my permission for the evaluation to begin as soon as possible.
      </p>
      <div class="display-flex">
        <div class="signature__fill mb-0 mr-4">
          <p class="mb-0">Parent/Guardian Signature:</p>
          <div></div>
        </div>
        <div class="signature__fill mb-0">
          <p class="mb-0">Date:</p>
          <div></div>
        </div>
      </div>
    </div>
  </section>
  <section class="mt-3">
    <h3>PARENTS WERE PROVIDED NOTICE ON ({{ formGroup.get('notificationDate').value | dateFormat }})</h3>
  </section>
  <section *ngIf="contactPeopleSource?.data?.length > 0">
    <h3>If you have questions, please contact the school principal or district/AEA designee:</h3>
    <table mat-table class="w-100 column-padding" [dataSource]="contactPeopleSource">
      <ng-container matColumnDef="contactPerson">
        <th mat-header-cell *matHeaderCellDef>Contact Person</th>
        <td mat-cell *matCellDef="let element">
          {{ element.contactPerson }}
        </td>
      </ng-container>

      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>Role / Discipline</th>
        <td mat-cell *matCellDef="let element">
          {{ element.position }}
        </td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef>Email</th>
        <td mat-cell *matCellDef="let element">
          {{ element.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="phone">
        <th mat-header-cell *matHeaderCellDef>Phone</th>
        <td mat-cell *matCellDef="let element">
          {{ element.phone }}
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </section>
</ng-container>
