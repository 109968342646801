import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from 'src/app/shared/models/user';
import { UserSearchParams } from 'src/app/shared/services/user/user.service';
import { OperationResultWithValue } from '../../shared/models/operation-result';
import { PaginatedList } from '../../shared/models/paginated-list';
import { VrCounselor } from '../models/ivrs-case';

@Injectable({
  providedIn: 'root',
})
export class IvrsUserManagementService {
  private baseUrl = 'api/ivrs';

  constructor(private http: HttpClient) {}

  getUsers(userSearchParameters: UserSearchParams) {
    let params = new HttpParams();

    userSearchParameters.roleIds?.forEach((id) => (params = params.append('roleIds', id)));
    userSearchParameters.aoIds?.forEach((id) => (params = params.append('aoIds', id)));
    params = params.append('includeInactiveRecords', userSearchParameters.includeInactiveRecords ?? false);
    params = params.append('searchFilter', userSearchParameters.searchFilter ?? '');

    return this.http.get<OperationResultWithValue<User[]>>(`${this.baseUrl}/users`, { params });
  }

  getUsersForCaseShareTransfer(userSearchParameters: UserSearchParams) {
    let params = new HttpParams();

    params = params.append('firstName', userSearchParameters.firstName ?? '');
    params = params.append('lastName', userSearchParameters.lastName ?? '');
    params = params.append('aeaId', userSearchParameters.aeaId ?? '');
    params = params.append('schoolDistrictId', userSearchParameters.schoolDistrictId ?? '');
    params = params.append('buildingId', userSearchParameters.buildingId ?? '');
    params = params.append('professionId', userSearchParameters.professionId ?? '');

    return this.http.get<OperationResultWithValue<User[]>>(`${this.baseUrl}/users/share-transfer`, { params });
  }

  getRoles = () => this.http.get(`${this.baseUrl}/roles`);

  addUser = (value) => this.http.post(`${this.baseUrl}/users`, value);
  updateUser = (value) => this.http.put(`${this.baseUrl}/users`, value);

  deactivateUser = (value) => this.http.put(`${this.baseUrl}/users/${value}/deactivate`, null);
  activateUser = (value) => this.http.put(`${this.baseUrl}/users/${value}/activate`, null);

  addRoleLocations = (value) => this.http.post(`${this.baseUrl}/role-locations`, value);
  updateRoleLocations = (value) => this.http.put(`${this.baseUrl}/role-locations`, value);
  removeRoleLocations = (value) => this.http.put(`${this.baseUrl}/role-locations/${value}/remove`, null);
  getRoleLocations = (value) => this.http.get(`${this.baseUrl}/role-locations/${value}`);

  getVrCounselors(
    pageNumber: number,
    pageSize: number,
    orderBy: string,
    orderByDescending: boolean,
    searchCriteria?: string,
    aoId?: string
  ) {
    let params = new HttpParams();
    params = params.append('searchCriteria', searchCriteria ?? '');
    params = params.append('aoId', aoId ?? '');
    params = params.append('pageNumber', pageNumber);
    params = params.append('pageSize', pageSize);
    params = params.append('orderBy', orderBy);
    params = params.append('orderByDescending', orderByDescending);

    return this.http.get<PaginatedList<VrCounselor>>(`${this.baseUrl}/users/vr-counselors`, { params });
  }
}
