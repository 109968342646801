import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { Observable, of } from 'rxjs';
import { catchError, concatMap, debounceTime, map, pairwise, startWith } from 'rxjs/operators';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { TaggedForCategory } from 'src/app/tags/tagged-category';
import { TaggedItem } from 'src/app/tags/tagged-item';
import { TaggingService } from 'src/app/tags/taggingService.service';
import { BaseComponent } from '../../../shared/components/base-component/base-component';
import { shortDateFormat } from '../../../shared/dateTimeHelpers';
import { subscribeToValueChanges } from '../../../shared/formHelpers';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { ChangeTrackerService } from '../../../shared/services/change-tracker.service';
import { DeactivationService, DeactivationStatus } from '../../../shared/services/deactivation.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { IfspView } from '../../models/ifsp';
import { IfspModification } from '../../models/ifsp-modification';
import { IfspServiceDto, IfspServiceStatus, IfspServiceSummaryDto } from '../../models/ifsp-service-models';
import { GetAllOutcomesForServiceDto } from '../../models/outcome-models';
import { IfspServicesService } from '../../services/ifsp-service.service';
import { IfspService } from '../../services/ifsp.service';
import { OutcomeService } from '../../services/outcome.service';
import { DatabaseLinksService } from '../../../shared/services/database-links/database-links.service';

@Component({
  selector: 'app-ifsp-services',
  templateUrl: './ifsp-services.component.html',
  styleUrls: ['./ifsp-services.component.scss'],
  providers: [ChangeTrackerService],
})
export class IfspServicesComponent extends BaseComponent implements OnInit {
  @ViewChild('formDirective') private formDirective: NgForm;
  @ViewChild('serviceForEdit') serviceForEdit: ElementRef<any>;

  @Input() modificationId: string;
  @Input() modifications: IfspModification[];
  @Input() modifyingService: IfspServiceDto;

  get modification() {
    return this.modifications.find((x) => x.id === this.modificationId);
  }

  get modificationIsFinalized() {
    return this.modification?.finalizeDate !== null;
  }

  get lastFinalizedDate() {
    if (this.modifications && this.modifications.length > 0) {
      const latest = this.modifications.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  get hasModification() {
    return !!this.modificationId;
  }

  get hasPriorVersion() {
    return !!this.modifyingService?.priorVersionId;
  }

  ifspId: string;
  serviceId: string;
  outcomeId: string;
  service: IfspServiceDto;
  today = dayjs().startOf('day').toDate();
  shortDateFormat = shortDateFormat;
  unModifiedService: IfspServiceDto;
  taggedForCategory = TaggedForCategory;
  isBusy = false;
  learnerId: string;

  formGroup = this.fb.group({
    outcomes: [[], [Validators.required]],
    service: ['', [Validators.required]],
    location: ['', [Validators.required]],
    justificationLocation: [''],
    length: ['', [Validators.required, Validators.min(0)]],
    howIds: [[], [Validators.required]],
    howExplanation: [''],
    frequencyNumber: ['', [Validators.required, Validators.min(0)]],
    frequencyPeriod: ['', [Validators.required]],
    with: ['', [Validators.required]],
    startDate: ['', [Validators.required]],
    provider: ['', [Validators.required]],
    otherProvider: [null],
    otherProviderName: [null],
    otherProviderAgency: [null],
    otherProviderRole: [null],
    isServiceCoordinatorService: [false],
  });

  outcomes: GetAllOutcomesForServiceDto[] = [];

  frequencyPeriodOptions: KeyValuePair[] = [new KeyValuePair('per week'), new KeyValuePair('per month'), new KeyValuePair('per year')];
  initialServiceTypeOptions: KeyValuePair[] = [];
  serviceTypeOptions: KeyValuePair[] = [];
  howOptions: KeyValuePair[] = [];
  whoOptions: KeyValuePair[] = [];
  locationOptions: KeyValuePair[] = [];
  providerOptions: KeyValuePair[] = [];
  providers = [];
  agencyOptions: KeyValuePair[] = [];
  submitAttempted = false;
  locationValuesForJustification: Array<string> = [];
  showJustificationQuestion = false;
  isNew: boolean;
  showHowExplanation = false;
  ifsp: IfspView;
  ifspCreatedOn: Date;
  displayProvider = true;
  patchingDone = false;

  get showServiceDetails() {
    return this.formGroup.get('service').value ? true : false;
  }

  get allOutcomesSelected() {
    return this.formGroup.get('outcomes').value?.length === this.outcomes.length;
  }

  get outcomeNotRequired() {
    return this.formGroup.get('isServiceCoordinatorService').value;
  }

  constructor(
    private readonly route: ActivatedRoute,
    private readonly fb: FormBuilder,
    private readonly ifspServicesService: IfspServicesService,
    private readonly notificationService: NotificationService,
    private readonly cd: ChangeDetectorRef,
    private readonly datePipe: DatePipe,
    private readonly changeTracker: ChangeTrackerService,
    private readonly taggingService: TaggingService,
    private readonly ifspService: IfspService,
    private readonly userService: UserService,
    private readonly caseService: CaseService,
    private ifspOutcomeService: OutcomeService,
    private readonly databaseLinksService: DatabaseLinksService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  canDeactivate(): DeactivationStatus | Observable<DeactivationStatus> | Promise<DeactivationStatus> {
    return (super.canDeactivate() as Observable<DeactivationStatus>).pipe(
      concatMap((status) =>
        status === DeactivationStatus.Accepted
          ? this.changeTracker.hasChanged.pipe(map((x) => (x ? DeactivationStatus.NeedsConfirmation : DeactivationStatus.Accepted)))
          : of(status)
      )
    );
  }

  async ngOnInit(): Promise<void> {
    this.ifspId = this.route.parent?.snapshot.paramMap.get('ifspId') ?? this.route.snapshot.paramMap.get('ifspId');
    this.serviceId = this.route.snapshot.paramMap.get('serviceId') ?? this.route.parent?.snapshot.paramMap.get('serviceId');
    this.outcomeId = this.route.snapshot.paramMap.get('outcomeId');
    this.ifspOutcomeService.outcomesUpdated$.subscribe(() => this.getOutcomes());

    this.ifspService.get(this.ifspId).subscribe(async (res) => {
      this.ifsp = res;
      this.ifspCreatedOn = dayjs(this.ifsp.createdOn).startOf('day').toDate();
      const learner = await this.caseService.getLearnerId(this.ifsp.caseId).toPromise();
      this.userService
        .getServiceActivityProviders(learner?.value)
        .pipe(
          catchError(() => {
            return of([]);
          })
        )
        .subscribe((providers) => {
          this.providers = providers;
          this.filterProviders(this.formGroup.get('service').value);
        });
    });

    this.ifspServicesService.setModifyingService$.subscribe((result) => {
      this.modifyingService = result;
      this.onEditService(this.modifyingService);
    });

    this.subscriptions.add(
      this.ifspServicesService.getLookupData(this.ifspId).subscribe((lookupData) => {
        this.outcomes = lookupData.allIfspOutcomes;
        this.initialServiceTypeOptions = KeyValuePair.createFromLookups(lookupData.serviceTypes);
        this.updateSCS();
        this.howOptions = KeyValuePair.createFromLookups(lookupData.hows);
        this.whoOptions = KeyValuePair.createFromLookups(lookupData.whos);
        this.locationOptions = KeyValuePair.createFromLookups(lookupData.locations);
        this.locationValuesForJustification = lookupData.locations.filter((x) => x.showJustificationQuestion).map((x) => x.id);

        this.subscriptions.add(
          subscribeToValueChanges(this.formGroup, {
            outcomes: (x) => this.onOutcomesChanged(x),
            service: (x) => this.onServiceChanged(x),
            otherProvider: (x) => this.onOtherProviderChanged(x),
            howIds: (x) => this.onHowIdsChanged(x),
            location: (x) => this.onLocationChanged(x),
            isServiceCoordinatorService: (x) => this.updateSCS(),
          })
        );
      })
    );
    this.isNew = true;

    this.subscriptions.add(
      this.formGroup
        .get('service')
        .valueChanges.pipe(debounceTime(250), startWith(''), pairwise())
        .subscribe(([prev, next]) => {
          if (prev === next) {
            return;
          }
          this.filterProviders(next);
        })
    );

    if (this.modifyingService) {
      this.onEditService(this.modifyingService);
    }

    if (this.serviceId) {
      this.getServiceAndPatch(this.serviceId);
    }

    if (this.outcomeId) {
      this.formGroup.get('outcomes').patchValue([this.outcomeId]);
    }

    this.initializeFromFormGroup(
      this.formGroup,
      () =>
        this.makeSaveRequest({
          submitting: false,
          cloneForm: false,
          deactivateLastIfspConsentForm: false,
        }),
      this.cd
    );
    this.startAutosaving();
  }

  updateSCS() {
    this.formGroup.get('outcomes')?.setValidators(this.outcomeNotRequired ? [] : [Validators.required]);
    if (this.outcomeNotRequired) {
      this.serviceTypeOptions = this.initialServiceTypeOptions.filter((x) => x.value === 'Service Coordination');
      this.formGroup.get('service').setValue(this.initialServiceTypeOptions?.find((x) => x.value === 'Service Coordination').key);
    } else {
      this.serviceTypeOptions = this.initialServiceTypeOptions;
      const service = this.formGroup.get('service');
      if (!service.value) {
        service.setValue('', { emitEvent: false });
      }
    }
    this.formGroup.get('outcomes')?.updateValueAndValidity();
    this.cd.detectChanges();
    this.patchingDone = true;
  }

  private filterProviders(serviceId: string) {
    if (this.providers.length === 0) return;

    this.displayProvider = false;

    this.providerOptions = this.providers
      .filter((x) => x.services.map((s) => s.id).includes(serviceId))
      .map((x) => new KeyValuePair(x.id, x.fullName));

    if (!this.providerOptions.find((x) => x.key === this.formGroup.get('provider').value)) {
      this.formGroup.get('provider').setValue(null);
    }

    setTimeout(() => {
      this.displayProvider = true;
      this.cd.detectChanges();
    }, 250);
  }

  startNew(): void {
    this.service = {} as IfspServiceDto;
    this.isNew = true;
    this.patchingDone = true;
  }

  onOutcomesChanged(val): void {
    if (val && val.length > 0 && !this.service) {
      this.startNew();
    }
  }

  onServiceChanged(val): void {
    if (!!val && !this.service) {
      this.startNew();
    }
  }

  onOtherProviderChanged(isOther): void {
    const providerControl = this.formGroup.get('provider');
    const otherProviderNameControl = this.formGroup.get('otherProviderName');

    if (isOther) {
      providerControl.setValue(null);
      providerControl.setValidators(null);
      otherProviderNameControl.setValidators(Validators.required);
    } else {
      otherProviderNameControl.setValue(null);
      this.formGroup.get('otherProviderAgency').setValue(null);
      this.formGroup.get('otherProviderRole').setValue(null);
      providerControl.setValidators(Validators.required);
      otherProviderNameControl.setValidators(null);
    }
    providerControl.updateValueAndValidity();
    otherProviderNameControl.updateValueAndValidity();
  }

  onHowIdsChanged(result: KeyValuePair[]): void {
    this.showHowExplanation = result?.find((h) => h === this.howOptions.find((x) => x.value === 'Other')?.key) != null;
    if (!this.showHowExplanation) {
      this.formGroup.get('howExplanation').setValue(null);
    }
  }

  onLocationChanged(value): void {
    if (this.locationValuesForJustification.indexOf(value) > -1) {
      this.showJustificationQuestion = true;
      this.formGroup.get('justificationLocation').setValidators(Validators.required);
    } else {
      this.showJustificationQuestion = false;
      this.formGroup.get('justificationLocation').setValue(null);
      this.formGroup.get('justificationLocation').clearValidators();
    }
    this.formGroup.get('justificationLocation').updateValueAndValidity();
  }

  getFrequencyText(element) {
    let monitoringProgress = '';
    if (element && element.frequencyNumber) {
      monitoringProgress += element?.frequencyNumber;
    }
    monitoringProgress += ' time(s) per ';
    if (element && element.frequencyPeriod) {
      monitoringProgress += element?.frequencyPeriod;
    }
    return monitoringProgress;
  }

  getHowText(howIds) {
    let howText = '';
    howIds.forEach((howId, index) => {
      let _how = null;
      _how = this.howOptions.find((x) => x.key === howId);
      howText += _how?.value;
      if (index + 1 !== howIds.length) {
        howText += ', ';
      }
    });
    return howText;
  }

  getWhoText(whoId) {
    let returnVar = '';
    const who = this.whoOptions.find((x) => x.key === whoId);
    if (who) {
      returnVar = who.value;
    }
    return returnVar;
  }

  getProviderText(providerId) {
    let returnVar = '';
    const provider = this.providerOptions.find((x) => x.key === providerId);
    if (provider) {
      returnVar = provider.value;
    }

    return returnVar;
  }

  getServiceProjectedStartDateText(startDate) {
    return this.datePipe.transform(startDate, shortDateFormat);
  }

  getOutcomesText(element, isPrior) {
    let outcomes = '';
    if (element.outcomes) {
      element.outcomes.forEach((outcome, index) => {
        let _outcome = null;
        if (isPrior) {
          _outcome = this.outcomes.find((x) => x.id === outcome.outcomeId);
        } else {
          _outcome = this.outcomes.find((x) => x.id === outcome);
        }
        outcomes += _outcome?.title;
        if (index + 1 !== element.outcomes.length) {
          outcomes += ', ';
        }
      });
    }
    return outcomes;
  }

  getLocationText(locationId) {
    let returnVar = '';
    const location = this.locationOptions.find((x) => x.key === locationId);
    if (location) {
      returnVar = location.value;
    }
    return returnVar;
  }

  getTypeOfServiceText(serviceId) {
    let returnVar = '';
    const service = this.serviceTypeOptions.find((x) => x.key === serviceId);
    if (service) {
      returnVar = service.value;
    }
    return returnVar;
  }

  private resetForm(cloneForm = false) {
    this.isNew = false;
    this.service = null;
    this.submitAttempted = false;
    this.unModifiedService = null;
    this.formGroup.markAsPristine();
    this.formGroup.markAsUntouched();
    if (!cloneForm) {
      this.formDirective.resetForm();
      this.formGroup.reset({ outcomes: [] });
      //reset back to default value
      this.setDefaultFormValues();
    }
  }

  private setDefaultFormValues() {
    this.formGroup.patchValue({
      isServiceCoordinatorService: false,
    });
  }

  async makeSaveRequest({
    submitting = false,
    closing = false,
    cloneForm = false,
    callback = null,
    cancelling = false,
    deactivateLastIfspConsentForm = false,
  }): Promise<void> {
    const processSaveResult = (result) => {
      if (!result.succeeded) {
        this.notificationService.error(result.errors[0].description);
        return;
      }

      this.service = result.value;
      if (submitting || closing) {
        this.resetForm(cloneForm);

        if (submitting) {
          this.notificationService.success('Completed Service successfully');
        }
      }

      if (callback) {
        callback();
      }
    };

    let isComplete = submitting || cancelling;

    // The Provider field is not *required* to submit but the Service
    // record isn't "Complete" until it's filled out
    if (!this.formGroup.value.provider && !this.formGroup.value.otherProviderName) {
      isComplete = false;
    }

    if (submitting || closing) {
      this.stopAutosaving();
    }

    let serviceDto: IfspServiceDto;
    if (!this.service?.id) {
      serviceDto = await this.ifspServicesService
        .createService(this.ifspId, {
          ...this.formGroup.value,
          status: isComplete ? IfspServiceStatus.Complete : IfspServiceStatus.InComplete,
          modificationId: this.modificationId,
          deactivateLastIfspConsentForm: deactivateLastIfspConsentForm,
        })
        .toPromise();
    } else {
      const service = {
        id: this.service.id,
        ...this.formGroup.value,
        status: isComplete ? IfspServiceStatus.Complete : IfspServiceStatus.InComplete,
        modificationId: this.modificationId,
        deactivateLastIfspConsentForm: deactivateLastIfspConsentForm,
      };

      serviceDto = await this.ifspServicesService.updateService(this.ifspId, service).toPromise();
    }

    processSaveResult(serviceDto);
  }

  async saveAndClose() {
    this.isBusy = true;
    const _callback = () => {
      this.ifspServicesService.serviceClose.next();
      this.startAutosaving();
      this.isBusy = false;
    };
    await this.makeSaveRequest({
      submitting: false,
      closing: true,
      callback: _callback,
      deactivateLastIfspConsentForm: false,
    });
  }

  async deleteOrCancel() {
    const deleteIt = () => {
      this.stopAutosaving();
      this.ifspServicesService.deleteService(this.ifspId, this.service.id, this.hasModification).subscribe(
        () => {
          this.resetForm();
          this.isNew = true;
          this.startAutosaving();
        },
        (error) => {
          this.notificationService.error(error.error[0]?.description);
        }
      );
    };

    if (this.isNew && this.service && this.service?.id) {
      deleteIt();
    } else {
      this.stopAutosaving();
      if (this.service?.id) {
        this.patchValues(this.unModifiedService);
        await this.makeSaveRequest({
          submitting: false,
          cloneForm: false,
          closing: true,
          cancelling: true,
          deactivateLastIfspConsentForm: false,
        });
      }
      this.ifspServicesService.serviceClose.next();
      this.resetForm();
      this.startAutosaving();
      return;
    }
  }

  async onSubmit(cloneForm: boolean, callback?: () => void) {
    if (this.formGroup.valid) {
      this.stopAutosaving();
      await this.makeSaveRequest({
        submitting: true,
        cloneForm,
        callback,
        deactivateLastIfspConsentForm: !!this.isNew,
      });
      this.isNew = true;
    }
  }

  async submit(cloneForm: boolean) {
    this.isBusy = true;
    this.submitAttempted = true;
    const callback = () => {
      this.ifspServicesService.serviceClose.next();
      this.startAutosaving();
      this.isBusy = false;
    };
    await this.onSubmit(cloneForm, callback);
  }

  patchValues(service: IfspServiceDto, isEditing = false) {
    if (service.outcomes) {
      service.outcomes = service.outcomes.map((x) => x.outcomeId);
    }

    this.service = service;

    this.formGroup.patchValue(service);
    this.formGroup.get('howIds').setValue(service.how);
    this.formGroup.get('service').setValue(service.service, { emitEvent: false });
    this.formGroup.get('provider').setValue(service.provider, { emitEvent: false });

    if (!!this.modifyingService) {
      setTimeout(() => this.formGroup.get('otherProvider').setValue(service.otherProvider), 250);
    }
    setTimeout(() => {
      this.patchingDone = true;
      if (isEditing) this.formGroup.markAllAsTouched();
    }, 1000);
  }

  getServiceAndPatch(serviceId, isEditing = false) {
    this.ifspServicesService.getServiceById(this.ifspId, serviceId).subscribe((result) => {
      this.isNew = false;
      this.unModifiedService = { ...result };
      this.patchValues(result, isEditing);
      this.scrollToSelectMethod(this.serviceForEdit);
      this.startAutosaving();
    });
  }

  scrollToSelectMethod(element: ElementRef<any>) {
    element.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  onEditService(service: IfspServiceSummaryDto) {
    this.getServiceAndPatch(service.id, true);
  }

  onSelectAll() {
    if (this.allOutcomesSelected) {
      this.formGroup.get('outcomes').setValue([]);
    } else {
      this.formGroup.get('outcomes').setValue(this.outcomes.map((x) => x.id));
    }
  }

  openTags() {
    const config: NewWindowConfig = {
      path: `tags/cases/${this.route.parent.snapshot.params?.caseId}/servicesc`,
      popup: true,
    };
    openNewWindow(config);
  }

  openIThree() {
    window.open(`${this.databaseLinksService.getDatabaseLink('i3SpecialEducationServicesAndActivities')}`, '_blank');
  }

  async getOutcomes() {
    const lookupData = await this.ifspServicesService.getLookupData(this.ifspId).toPromise();
    this.outcomes = lookupData.outcomes;
  }

  tag(service: IfspServiceDto, taggedForCategory: TaggedForCategory) {
    if (service?.taggedItem) {
      service.taggedItem.taggedForPwn =
        taggedForCategory === TaggedForCategory.Pwn ? !service.taggedItem.taggedForPwn : service.taggedItem.taggedForPwn;
      service.taggedItem.taggedForOutcomes =
        taggedForCategory === TaggedForCategory.Outcomes ? !service.taggedItem.taggedForOutcomes : service.taggedItem.taggedForOutcomes;
      this.taggingService.updateTag(service?.taggedItem).subscribe((tagReceived) => {
        service.taggedItem = tagReceived;
      });
    } else {
      const newlyTaggedItem = {
        ifspServiceId: service.id,
        caseId: this.route.parent.snapshot.params?.caseId,
        taggedForPwn: taggedForCategory === TaggedForCategory.Pwn,
        taggedForOutcomes: taggedForCategory === TaggedForCategory.Outcomes,
      } as TaggedItem;
      this.taggingService.addTag(newlyTaggedItem).subscribe((tagReceived) => {
        service.taggedItem = tagReceived;
      });
    }
  }
}
