import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MatAccordion, MatExpansionPanel } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { CaseSummary, FamilyMeetingRead } from 'src/app/shared/models/case';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { IepServiceHelp } from 'src/app/shared/services/help/models/iep.help';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { LearnerSummary } from '../../../shared/models/learner';
import { IepView, TrialPlacementTarget } from '../../models/iep';
import { IepService } from '../../services/iep.service';
import { IepServiceService } from '../../services/iep-service.service';
import { IncompleteDataReportElement } from '../../../shared/components/incomplete-data-report/incomplete-data-report-item';
import { DatabaseLinksService } from '../../../shared/services/database-links/database-links.service';

@Component({
  selector: 'app-iep-services-supports-activities',
  templateUrl: './iep-services-supports-activities.component.html',
  styleUrls: ['./iep-services-supports-activities.component.scss'],
})
export class IepServicesSupportsActivitiesComponent implements OnInit, AfterViewInit {
  helpSection = HelpSection;
  iepServiceHelp = IepServiceHelp;

  @ViewChild(MatAccordion) serviceAccordion: MatAccordion;

  @ViewChild('lrePanel') lrePanel: MatExpansionPanel;
  @ViewChild('lrePanel', { read: ElementRef })
  private leastRestrictiveEnvPanelElem: ElementRef;

  isAccordionsExpanded = false;
  learner: LearnerSummary;
  viewOverallSummary = false;
  iepId: string;
  caseId: string;
  previousIepId: string;
  caseSummary: CaseSummary;
  iepMeetings: FamilyMeetingRead[];
  iepView: IepView;

  get showTrialPlacementCheckBox(): boolean {
    return this.iepIncludesTrialPlacement && this.caseSummary.activeIepTrialPlacementTarget === TrialPlacementTarget.SomeItems;
  }

  get iepIncludesTrialPlacement(): boolean {
    return this.caseSummary.activeIepIncludesTrialPlacement;
  }

  constructor(
    private caseService: CaseService,
    private route: ActivatedRoute,
    private helpService: HelpService,
    private iepService: IepService,
    private iepServiceService: IepServiceService,
    private cd: ChangeDetectorRef,
    private readonly databaseLinksService: DatabaseLinksService
  ) {}

  ngOnInit(): void {
    this.caseId = this.route.parent.snapshot.paramMap.get('caseId');
    this.iepId = this.route.parent.snapshot.paramMap.get('iepId');
    forkJoin([
      this.iepService.getIepMeetings(this.caseId),
      this.caseService.getCaseSummary(this.caseId),
      this.iepService.get(this.iepId),
    ]).subscribe(([iepMeetings, caseSummaryResult, iepView]) => {
      this.iepView = iepView;
      this.previousIepId = caseSummaryResult.previousIepId;
      this.learner = caseSummaryResult.learner;
      this.caseSummary = caseSummaryResult;
      this.iepMeetings = iepMeetings;
    });
  }

  ngAfterViewInit() {
    if (this.route.snapshot.paramMap.get('section')) {
      const section = this.route.snapshot.paramMap.get('section') as string;
      const element = this.route.snapshot.paramMap.get('element') as IncompleteDataReportElement;
      switch (section) {
        case 'leastRestrictiveEnv':
          if (this.lrePanel._getExpandedState() === 'collapsed') {
            this.lrePanel.toggle();
            this.leastRestrictiveEnvPanelElem?.nativeElement.scrollIntoView({
              behavior: 'smooth',
            });
          }
          if (element === IncompleteDataReportElement.RECPNotAccurate) {
            this.iepServiceService.focusLRERECPBtn.next(undefined);
          }
          break;
        default:
          break;
      }
    }
    this.cd.detectChanges();
  }

  toggleAccordion() {
    this.isAccordionsExpanded = !this.isAccordionsExpanded;
    this.isAccordionsExpanded ? this.serviceAccordion.openAll() : this.serviceAccordion.closeAll();
  }

  onViewOverallSummary() {
    const config: NewWindowConfig = {
      path: `cases/${this.caseId}/iep/${this.iepId}/services/summary`,
      popup: true,
      width: '1480px',
    };
    openNewWindow(config);
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm) {
    e.stopPropagation();

    const help = this.helpService.getIepServiceDictionary();
    this.helpService.openHelpModal({
      help,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }

  openIThree() {
    window.open(`${this.databaseLinksService.getDatabaseLink('i3SpecialEducationServicesAndActivities')}`, '_blank');
  }

  openTags() {
    const config: NewWindowConfig = {
      path: `tags/cases/${this.caseId}/services`,
      popup: true,
    };
    openNewWindow(config);
  }
}
