<form [formGroup]="formGroup">
  <mat-card>
    <mat-card-title>Health Insurance Information</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoOptions"
              formControlName="hasInsurance"
              label="Does the child have health insurance?"
              [sourceData]="questionnaire.insuranceInfo"
            ></app-radio-group>
          </fieldset>
          <a
            *ngIf="!isQuestionnaire && formGroup.get('hasInsurance').value === false"
            target="_blank"
            (click)="openIDHPHawkiOutreachInformation()"
            >IDPH: Hawki Outreach Information</a
          >
        </div>
      </div>
      <div *ngIf="formGroup.get('hasInsurance').value">
        <hr class="input__divider" />

        <div class="row mb-0">
          <div class="col-lg">
            <mat-label
              [ngClass]="{
                asterisk_input: requiresInsurance()
              }"
              class="mt-0 display-block"
            >
              Type(s) of Insurance:
            </mat-label>
          </div>
        </div>
        <div class="row align-items-center mb-0">
          <div class="col-md-12 col-lg-12 col-xl-3">
            <app-checkbox-single
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              formControlName="hasMedicaid"
              aria-labelledby="Check Medicaid"
              label="Medicaid"
              [sourceData]="questionnaire.insuranceInfo"
            ></app-checkbox-single>
          </div>
          <ng-container *ngIf="formGroup.get('hasMedicaid').value === true">
            <div class="col-md-12 col-lg-6 col-xl-4">
              <app-text-field
                [disabledState]="locked"
                *ngIf="isQuestionnaire"
                label="Medicaid # (reported by family)"
                formControlName="medicaidNumber"
              ></app-text-field>
              <app-overwrite-text-field
                [disabledState]="locked"
                *ngIf="!isQuestionnaire"
                label="Medicaid # (reported by family)"
                formControlName="medicaidNumber"
                [sourceData]="questionnaire.insuranceInfo"
              ></app-overwrite-text-field>
            </div>

            <div class="col-md-12 col-lg-6 col-xl-4">
              <app-text-field
                [disabledState]="locked"
                *ngIf="!isQuestionnaire && formGroup.get('hasMedicaid').value === true"
                label="DHS Medicaid #"
                formControlName="dhsMedicaidNumber"
              ></app-text-field>
            </div>
          </ng-container>
        </div>

        <div class="row align-items-center mb-0">
          <div class="col-md-12 col-lg-6 col-xl-3">
            <app-checkbox-single
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              formControlName="hasPrivateInsurance"
              aria-labelledby="Check Private Insurance"
              label="Private Insurance"
              [sourceData]="questionnaire.insuranceInfo"
            ></app-checkbox-single>
          </div>
          <ng-container *ngIf="formGroup.get('hasPrivateInsurance').value === true">
            <div class="col-md-12 col-lg-6 col-xl-4">
              <app-text-field
                [disabledState]="locked"
                *ngIf="isQuestionnaire"
                label="Insurance Company Name"
                formControlName="insuranceCompanyName"
              ></app-text-field>
              <app-overwrite-text-field
                [disabledState]="locked"
                *ngIf="!isQuestionnaire"
                label="Insurance Company Name"
                formControlName="insuranceCompanyName"
                [sourceData]="questionnaire.insuranceInfo"
              ></app-overwrite-text-field>
            </div>
          </ng-container>
        </div>
        <div class="row mb-2">
          <div class="col-md-12 col-lg-6 col-xl-3">
            <app-checkbox-single
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              formControlName="hasHawki"
              aria-labelledby="Check Hawki"
              label="Hawki"
              [sourceData]="questionnaire.insuranceInfo"
            ></app-checkbox-single>
          </div>
        </div>
      </div>

      <hr class="input__divider" *ngIf="isQuestionnaire" />

      <div class="row mb-0">
        <div class="col-lg">
          <ng-container class="mt-2">
            Does the child have
            <a
              (click)="openHCBSWaiversProgram()"
              tabindex="0"
              role="link"
              target="_blank"
              aria-labelledby="Waiver Services"
              class="text-underline"
              >waiver
            </a>
            services?
          </ng-container>
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="appliedYesNo"
              id="doesTheChildHaveAWaiver"
              formControlName="doesTheChildHaveAWaiver"
              [sourceData]="questionnaire.insuranceInfo"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>
      <div class="row" *ngIf="formGroup.get('doesTheChildHaveAWaiver').value !== waiverStatus.No">
        <div class="col-lg" *ngIf="formGroup.get('doesTheChildHaveAWaiver').value === waiverStatus.Yes">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="waiverTypes"
              [column]="true"
              formControlName="waiverType"
              label="Type of Waiver:"
              [sourceData]="questionnaire.insuranceInfo"
            ></app-radio-group>
          </fieldset>
        </div>
        <div class="col-lg" *ngIf="formGroup.get('doesTheChildHaveAWaiver').value === waiverStatus.AppliedForWaiver">
          <app-checkbox [disabled]="locked" [options]="waiverTypesAll" controlName="waiverTypes" label="Type of Waiver:"></app-checkbox>
        </div>
      </div>
    </div>
  </mat-card>
</form>

<!-- <div class="card-spacing-top">
  <app-documentation-info-form
    *ngIf="!isQuestionnaire"
    title="Relevant Form(s)"
    documentLink="https://idoestorage.blob.core.usgovcloudapi.net/public/Part_C_Medicaid_consent_blank.pdf"
    documentTitle="Parent / Guardian Authorization for Medicaid Reimbursement"
    uploadCardTitle="Upload Completed Form"
    uploadButtonTitle="Upload Completed Form"
    [locked]="locked"
    [formGroup]="documentationInfo"
    [documents]="intake?.documentationInfo"
    [caseId]="intake?.caseId"
    [section]="intakeSections.InsuranceInfo"
    (upload)="onUpload($event)"
    (deleteDocument)="onDeleteDocument($event)"
    documentType="MedicaidReimbursement"
  >
  </app-documentation-info-form>
</div> -->
