import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { yesNoOptions } from 'src/app/shared/formHelpers';
import { MedicalCondition } from 'src/app/shared/models/medical-condition';

@Component({
  selector: 'app-early-access-referral-medical-diagnosis-table',
  templateUrl: './referral-medical-diagnosis-table.component.html',
  styleUrls: ['./referral-medical-diagnosis-table.component.scss'],
})
export class EarlyAccessReferralMedicalDiagnosisTableComponent implements OnInit {
  dataSource = new MatTableDataSource<MedicalCondition>([]);
  @Input() formGroup: FormGroup;
  @Output() refreshDataSource = new EventEmitter();
  modelFormGroup: FormGroup;
  editingModel: MedicalCondition;
  displayedColumns = ['actions', 'name', 'icdCode'];

  yesNoOptions = yesNoOptions;

  private emptyRow: MedicalCondition = {
    name: null,
    icdCode: null,
  };

  constructor(private fb: FormBuilder) {}

  get models() {
    return this.formGroup.controls.referralReasonDiagnosedConditions.value as MedicalCondition[];
  }

  ngOnInit(): void {
    this.dataSource.data = [...this.models, this.emptyRow];

    this.modelFormGroup = this.fb.group({
      name: [null, [Validators.required]],
      icdCode: [null],
    });
  }

  edit(model: MedicalCondition) {
    this.editingModel = model;
    this.modelFormGroup.patchValue(model);
  }

  save() {
    const idx = this.models.indexOf(this.editingModel);
    this.editingModel = null;
    let updatedModels: MedicalCondition[];
    if (idx >= 0) {
      updatedModels = [...this.models.slice(0, idx), this.modelFormGroup.value, ...this.models.slice(idx + 1)];
    } else {
      updatedModels = [...this.models, this.modelFormGroup.value];
    }
    this.updateModels(updatedModels);
  }

  remove(model: MedicalCondition) {
    const idx = this.models.indexOf(model);
    this.updateModels([...this.models.slice(0, idx), ...this.models.slice(idx + 1)]);
  }

  cancel() {
    this.editingModel = null;
  }

  private updateModels(models: MedicalCondition[]) {
    this.formGroup.patchValue({
      referralReasonDiagnosedConditions: models,
    });
    this.formGroup.markAsDirty();
    this.formGroup.updateValueAndValidity();
    this.dataSource.data = [...this.models, this.emptyRow];
  }
}
