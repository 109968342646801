import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { AppPermissions } from 'src/app/permissions';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { shortDateFormat, timeDurationInMinutes } from 'src/app/shared/dateTimeHelpers';
import { CaseSummary } from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { NewWindowConfig, openNewWindow, openPdfWindow } from 'src/app/shared/windowHelpers';
import { AuthService } from '../../../../auth/auth.service';
import { BaseComponent } from '../../../../shared/components/base-component/base-component';
import { DeactivationService } from '../../../../shared/services/deactivation.service';
import { ServiceLogFlatDto, ServiceLogSearchDto } from '../../models/service-log';
import { ServiceLogService } from '../../services/service-log.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-ifsp-service-logs',
  templateUrl: './ifsp-service-logs.component.html',
  styleUrls: ['./ifsp-service-logs.component.scss'],
})
export class IfspServiceLogsComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() caseSummary: CaseSummary;

  subscriptions = new Subscription();

  shortDateFormat = shortDateFormat;
  dataSource = new MatTableDataSource<ServiceLogFlatDto>([]);
  displayedColumns = ['actions', 'sessionDate', 'startTime', 'duration', 'serviceId', 'provider', 'ifspDate'];
  formGroup: FormGroup;
  ifspServiceOptions: KeyValuePair[] = [];
  ifspOptions: KeyValuePair[] = [];
  ifspServiceProviderOptions: KeyValuePair[] = [];
  @ViewChild(MatSort) sort: MatSort;
  permissions = AppPermissions;

  constructor(
    private readonly routingService: RoutingService,
    private readonly serviceLogService: ServiceLogService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private reportingService: ReportingService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.formGroup = this.formBuilder.group({
      ifspServiceId: [''],
      ifspId: [''],
      providerId: [''],
      quickDate: new FormControl({
        dateShortcut: 'AllTime',
        startDate: dayjs().subtract(100, 'year').startOf('day').toDate(),
        endDate: dayjs().add(2, 'day').startOf('day').toDate(),
      }),
    });

    const initialFilter = {
      caseId: this.caseSummary.id,
      ifspServiceId: null,
      ifspId: null,
      providerId: null,
      startDate: null,
      endDate: null,
    } as ServiceLogSearchDto;
    this.getServiceLogData(initialFilter);

    this.subscriptions.add(
      this.serviceLogService.getServiceLogLookups(this.caseSummary.id).subscribe(
        (operationResult) => {
          if (operationResult.succeeded) {
            this.ifspServiceOptions = operationResult.value.ifspServiceTypes.map((val) => new KeyValuePair(val.id, val.typeOfService));

            for (const ifsp of operationResult.value.ifsps) {
              if (ifsp.startDate && ifsp.endDate) {
                this.ifspOptions.push(
                  new KeyValuePair(ifsp.id, dayjs(ifsp.startDate).format('M/D/YYYY') + ' - ' + dayjs(ifsp.endDate).format('M/D/YYYY'))
                );
              } else if (ifsp.startDate) {
                this.ifspOptions.push(new KeyValuePair(ifsp.id, dayjs(ifsp.startDate).format('M/D/YYYY')));
              }
            }

            this.ifspServiceProviderOptions = operationResult.value.ifspServiceProviders.map(
              (val) => new KeyValuePair(val.id, val.fullName)
            );
          }

          this.subscriptions.add(
            this.formGroup.valueChanges.pipe(debounceTime(100)).subscribe(() => {
              this.filterServiceLog();
            })
          );
        },
        (error) => {
          this.serviceLogService.handleError('An error occured while retrieving service log lookup data', error);
        }
      )
    );
    this.subscriptions.add(this.serviceLogService.serviceLogUpdated$.subscribe(() => this.filterServiceLog()));
  }

  get userId() {
    return this.authService.user?.id;
  }

  filterServiceLog() {
    // this.formGroup.updateValueAndValidity();
    if (this.formGroup.valid) {
      // TODO: check with PMs if we have to consider other parameters while using date range search: isDate
      const serviceLogSearchDto = {
        caseId: this.caseSummary.id,
        ifspServiceId: this.formGroup.value.ifspServiceId,
        ifspId: this.formGroup.value.ifspId,
        providerId: this.formGroup.value.providerId,
        startDate: this.formGroup.value.quickDate.startDate,
        endDate: this.formGroup.value.quickDate.endDate,
      } as ServiceLogSearchDto;

      this.getServiceLogData(serviceLogSearchDto);
    }
  }

  getServiceLogData(serviceLogSearch: ServiceLogSearchDto) {
    this.serviceLogService.getServiceLogs(serviceLogSearch).subscribe((operationResult) => {
      if (operationResult.succeeded) {
        this.dataSource.data = operationResult.value;
      }
    });
  }

  getServiceName(serviceId: string) {
    const ifspService = this.ifspServiceOptions.find((s) => s.key === serviceId);
    if (ifspService) {
      return ifspService.value;
    }
    return '';
  }

  getTimeDuration(element: ServiceLogFlatDto) {
    if (element.appointmentDidNotHappen === true) {
      return "Appointment Didn't Happen";
    }
    return `${timeDurationInMinutes(element.startTime, element.endTime)} min.`;
  }

  getIfspDate(element: ServiceLogFlatDto) {
    if (element.ifspStartDate && element.ifspEndDate) {
      return dayjs(element.ifspStartDate).format('M/D/YYYY') + ' - ' + dayjs(element.ifspEndDate).format('M/D/YYYY');
    } else if (element.ifspStartDate) {
      return dayjs(element.ifspStartDate).format('M/D/YYYY');
    }
    return '';
  }

  onUpdateServiceLog(element: any, serviceDetailRequired = false) {
    const config: NewWindowConfig = {
      path: this.routingService.updateServiceLogPath(this.caseSummary.id, element.serviceLogId).join('/'),
      popup: true,
      width: '1280px',
      params: { serviceDetailRequired },
    };
    openNewWindow(config);
  }

  onDeleteServiceLog(element: ServiceLogFlatDto) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion:
          'Clicking Yes will remove the service log session, service details, family guided intervention plan and related documents.',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.serviceLogService.deleteServiceLog(element.serviceLogId).subscribe(
          () => {
            this.notificationService.success('Deleted!');
          },
          (error) => {
            this.serviceLogService.handleError('An error occured while deleting service log', error);
          }
        );
      }
    });
  }

  onViewServiceLog(element: ServiceLogFlatDto) {
    const config: NewWindowConfig = {
      path: this.routingService.updateServiceLogPath(this.caseSummary.id, element.serviceLogId).join('/'),
      popup: true,
      width: '1280px',
      params: { readOnly: 'true' },
    };
    openNewWindow(config);
  }

  canEdit(element: ServiceLogFlatDto): boolean {
    return this.userId === element.serviceLogProvider.id || this.userId === element.serviceDetailProvider.id;
  }

  onViewOutput(serviceLogId: string): void {
    this.reportingService.createFgrbiOutput(serviceLogId).subscribe({
      next: (documentId: string) => openPdfWindow(this.caseSummary.learnerId, documentId),
      error: (err) => this.reportingService.handleOutputError(err),
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
