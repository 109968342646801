import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-key-value-label',
  templateUrl: 'key-value-label.component.html',
  styleUrls: ['key-value-label.component.scss'],
})
export class KeyValueLabelComponent {
  @Input() public key: string;
  @Input() public value: string;
  @Input() public showColor = true;
}
