import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { merge, Observable, Subscription } from 'rxjs';
import { CaseSummary, FamilyMeetingRead } from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { TimeUnit } from 'src/app/shared/models/time';
import { HelpSection } from 'src/app/shared/services/help/help';
import { IepServiceHelp } from 'src/app/shared/services/help/models/iep.help';
import { conditionalValidator } from 'src/app/shared/validators';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { BaseComponent } from '../../../../../shared/components/base-component/base-component';
import { shortDateFormat } from '../../../../../shared/dateTimeHelpers';
import { LearnerSummary } from '../../../../../shared/models/learner';
import { BooleanYesNoPipe } from '../../../../../shared/pipes/boolean-yesno.pipe';
import { DeactivationService } from '../../../../../shared/services/deactivation.service';
import { IepAmendment } from '../../../../models/iep-amendment';
import { IepLREDto } from '../../../../models/iep-lre-dto';
import { IepAmendmentService } from '../../../../services/iep-amendment.service';
import { IepServiceService } from '../../../../services/iep-service.service';
import { IepService } from '../../../../services/iep.service';

@Component({
  selector: 'app-iep-ssaa-least-restrictive-env',
  templateUrl: './iep-ssaa-least-restrictive-env.component.html',
  styleUrls: ['./iep-ssaa-least-restrictive-env.component.scss'],
  providers: [BooleanYesNoPipe],
})
export class IepSsaaLeastRestrictiveEnvComponent extends BaseComponent implements OnInit {
  @ViewChild('recpsAccurateAnchor', { read: ElementRef })
  private recpsAccurateAnchor: ElementRef;

  //#region Inputs
  @Input() learner: LearnerSummary;
  @Input() caseSummary: CaseSummary;
  @Input() previousIepId: string;

  @Input() isAmendment: boolean;
  @Input() amendmentId: string;
  @Input() amendments: IepAmendment[];
  @Input() isDetailsView = false;
  @Input() iepMeetings: FamilyMeetingRead[];

  @Input() amendingLRE: boolean;
  @Input() summaryView = false;
  @Input() isDeleting = false;
  //#endregion
  //#region Vars
  shortDateFormat = shortDateFormat;
  lreModel: IepLREDto;
  lrePKModel: IepLREDto;
  today = dayjs().startOf('day').toDate();
  iepMeetingDate: Date;
  caseId: string;
  iepId: string;
  lreStatsData: any;
  autosaveSubscription = new Subscription();
  minutesRecievingInstructionInvalid = false;

  recpsDisplayedColumns = ['startDate', 'program', 'frequency', 'attendRECP'];
  recpsDataSource = new MatTableDataSource<any>();

  formGroup = this.fb.group({
    reasonsCantProvide: ['', conditionalValidator(() => this.askReasonsCantProvideQuestion, Validators.required)],
    specialServicesProvided: ['', conditionalValidator(() => this.askSpecialServicesProvidedQuestion, Validators.required)],
    specialServicesProvidedExp: ['', conditionalValidator(() => this.specialServicesProvided === false, Validators.required)],
    recpsAccurate: ['', conditionalValidator(() => this.iepIsPK, Validators.required)],
    whereReceiveSpecialEd: ['', conditionalValidator(() => this.askWhereReceiveSpecialEdQuestion, Validators.required)],
    attendSchoolIf: ['', conditionalValidator(() => this.askAttendSchoolIfQuestion, Validators.required)],
    attendSchoolIfExp: ['', conditionalValidator(() => this.attendSchoolIf === false, Validators.required)],
    minutesRecievingInstruction: [
      null,
      [
        conditionalValidator(() => this.showMinutesRecievingInstruction, Validators.required),
        conditionalValidator(() => this.showMinutesRecievingInstruction, Validators.min(0)),
        conditionalValidator(() => this.showMinutesRecievingInstruction, Validators.max(2400)),
      ],
    ],
    minutesRecievingInstructionRationale: [null],
    attendSpecialSchool: ['', conditionalValidator(() => this.attendSchoolIf === false, Validators.required)],
    cannotBeProvided: ['', conditionalValidator(() => this.attendSpecialSchool === true, Validators.required)],
    supplementaryAids: [
      '',
      conditionalValidator(() => this.attendSpecialSchool === true || this.attendSpecialSchoolPK === true, Validators.required),
    ],
    notPossibleForAids: [
      '',
      conditionalValidator(() => this.attendSpecialSchool === true || this.attendSpecialSchoolPK === true, Validators.required),
    ],
    continuumServiceAvailable: [
      '',
      conditionalValidator(
        () => (this.attendSpecialSchool === true || this.attendSpecialSchoolPK === true) && this.isInitialIep === false,
        Validators.required
      ),
    ],
  });

  helpSection = HelpSection;
  iepServiceHelp = IepServiceHelp;
  showK: boolean;

  yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];

  whereReceiveSpecialEdOptions: KeyValuePair[] = [
    new KeyValuePair('Special Education', 'Special Education'),
    new KeyValuePair('Special School', 'Special School'),
    new KeyValuePair('Residential Facility', 'Residential Facility'),
    new KeyValuePair('Home', 'Home'),
    new KeyValuePair('Service Provider Location', 'Service Provider Location'),
  ];

  isInitialIep = false;
  generalEducationServices = 0;
  specialEducationServices = 0;
  generalEducationEnv = 0;
  specialEducationEnv = 0;
  startingKDuringIep = false;
  defaultEducationalMinutes = 2400;
  iepIsPK = false;
  //#endregion
  //#region Getters
  get formValid() {
    return this.formGroup.valid;
  }

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentIsFinalized() {
    return this.amendment?.finalizeDate !== null;
  }

  get lastFinalizedDate() {
    if (!this.amendments || this.amendments.length === 0) {
      return null;
    }

    const latest = this.amendments.reduce((r, a) => (r.finalizeDate > a.finalizeDate ? r : a));
    return latest?.finalizeDate;
  }

  get hasAmendment() {
    return !!this.amendmentId;
  }

  get hasOpenAmendment() {
    return !!this.amendment && !this.amendmentIsFinalized;
  }

  get showMinutesRecievingInstruction() {
    return !this.iepIsPK || this.startingKDuringIep;
  }

  get recpsStartPriorThanIEPMeeting() {
    return this.currentRECPS?.filter((x) => x.projectedStartDate < this.iepMeetingDate);
  }

  get recpsStartOnOrAfterIEPMeeting() {
    const currentAndFutureRECPS = this.currentRECPS?.concat(this.futureRECPS);
    return currentAndFutureRECPS?.filter((x) => x.projectedStartDate >= this.iepMeetingDate);
  }

  get validationCriteria1() {
    return this.currentRECPS?.concat(this.futureRECPS)?.filter((x) => x.projectedStartDate < this.iepMeetingDate).length > 0;
  }

  get validationCriteria2() {
    return (
      this.recpsStartPriorThanIEPMeeting?.length > 0 &&
      this.recpsStartPriorThanIEPMeeting?.every((x) => !x.attendRecp) &&
      this.futureRECPS?.filter((fp) => fp.projectedStartDate >= this.iepMeetingDate).length > 0 &&
      this.lreModel?.specialEducationEnv > 0 &&
      this.lreModel?.specialEducationEnv <= 100
    );
  }

  get validationCriteria3() {
    return (
      this.iepIsPK &&
      this.recpsStartPriorThanIEPMeeting?.length > 0 &&
      this.recpsStartPriorThanIEPMeeting?.every((x) => !x.attendRecp) &&
      this.futureRECPS?.filter((fp) => fp.projectedStartDate >= this.iepMeetingDate).length === 0 &&
      this.lreModel?.specialEducationEnv === 100
    );
  }

  get validationCriteria4() {
    return (
      this.recpsStartPriorThanIEPMeeting?.length > 0 &&
      this.recpsStartPriorThanIEPMeeting?.every((x) => x.attendRecp) &&
      this.lreModel?.specialEducationEnv === 100
    );
  }

  get validationCriteria6() {
    return this.iepIsPK && this.currentRECPS?.concat(this.futureRECPS)?.length === 0;
  }

  get specialServicesProvided() {
    return this.formGroup.get('specialServicesProvided').value;
  }

  get hasEarlyChildhoodPrograms() {
    return this.lreModel?.earlyChildhoodPrograms && this.lreModel?.earlyChildhoodPrograms.length > 0;
  }

  get currentRECPS() {
    return this.lreModel?.earlyChildhoodPrograms?.filter(
      (x) => dayjs(x.projectedStartDate).toDate() <= this.today && (x.endDate === null || dayjs(x.endDate).toDate() >= this.today)
    );
  }

  get isInEarlyChildProgram() {
    return this.hasEarlyChildhoodPrograms && this.currentRECPS?.length > 0;
  }

  get futureRECPS() {
    return this.lreModel?.earlyChildhoodPrograms?.filter((x) => dayjs(x.projectedStartDate).toDate() > this.today);
  }

  get hasFutureEarlyChildPrograms() {
    return this.hasEarlyChildhoodPrograms && this.futureRECPS?.length > 0;
  }

  get ecpsHoursInWeek() {
    let totalHours = 0;
    const filteredRecps = this.lreModel?.earlyChildhoodPrograms?.filter((recp) => recp.attendRecp);
    filteredRecps?.forEach((x) => {
      if (x.timeUnit === TimeUnit.Hours) {
        totalHours += x.timeQuantity;
      } else if (x.timeUnit === TimeUnit.Minutes) {
        totalHours += x.timeQuantity / 60;
      }
    });
    return totalHours;
  }

  get totalRecpsUsedForIep() {
    const attendRecps = this.currentRECPS?.concat(this.futureRECPS)?.filter((x) => x.attendRecp);
    return attendRecps ? attendRecps.length : 0;
  }

  get askSpecialServicesProvidedQuestion() {
    return this.validationCriteria1 || this.validationCriteria2 || this.validationCriteria3 || this.validationCriteria4;
  }

  get askWhereReceiveSpecialEdQuestion() {
    return this.validationCriteria3 || this.validationCriteria6;
  }

  get askReasonsCantProvideQuestion() {
    if (
      (this.lreModel?.totalServicesRemoved > 0 || (this.iepIsPK && !this.hasFutureEarlyChildPrograms)) &&
      this.generalEducationEnv < 100
    ) {
      return true;
    }

    if (this.lreModel?.totalServicesRemoved === 0 && this.generalEducationEnv === 100) {
      return false;
    }

    return this.validationCriteria2 || this.validationCriteria3 || this.validationCriteria4 || this.validationCriteria6;
  }

  get askAttendSchoolIfQuestion() {
    if (!this.iepIsPK) {
      return true;
    }

    return (
      !this.hasEarlyChildhoodPrograms || (this.recpsStartOnOrAfterIEPMeeting.length > 0 && this.recpsStartPriorThanIEPMeeting.length === 0)
    );
  }

  get lreFormData() {
    return this.formGroup.value;
  }

  get attendSchoolIf() {
    return this.formGroup.get('attendSchoolIf').value;
  }

  get attendSpecialSchool() {
    return this.formGroup.get('attendSpecialSchool').value === true;
  }

  get attendSpecialSchoolPK() {
    return this.iepIsPK && this.formGroup.get('whereReceiveSpecialEd').value === 'Special School';
  }
  //#endregion

  save = new Observable<boolean>((observer) => {
    this.cd.detectChanges();

    const done = () => {
      this.isSaving = false;
      observer.next(true);
    };

    if (!this.formGroup.dirty || this.isSaving) {
      done();
      return;
    }

    this.isSaving = true;
    this.onSave().then(() => {
      done();
    });
  });

  constructor(
    private readonly iepService: IepService,
    private readonly iepServiceService: IepServiceService,
    private readonly route: ActivatedRoute,
    private readonly fb: FormBuilder,
    private readonly cd: ChangeDetectorRef,
    private iepAmendmentService: IepAmendmentService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  async ngOnInit(): Promise<void> {
    this.caseId = this.route.parent?.snapshot.paramMap.get('caseId');
    this.iepId = this.route.parent?.snapshot.paramMap.get('iepId');
    if (!this.caseId) {
      this.caseId = this.route.snapshot.paramMap.get('caseId');
    }
    if (!this.iepId) {
      this.iepId = this.route.snapshot.paramMap.get('iepId');
    }

    this.iepMeetingDate = this.iepMeetings
      ?.filter((x) => dayjs(x.dateAndTime).toDate() >= dayjs().add(-90, 'day').toDate() && !!x.meetingRollCallDate)
      ?.reverse()[0]?.dateAndTime;
    this.startingKDuringIep = this.caseSummary?.activeIepIsPKToK;
    await this.getLREData();

    this.subscriptions.add(
      merge(this.iepServiceService.servicesUpdated$, this.iepService.lreUpdated$).subscribe(() => {
        this.getLREData(this.summaryView);
      })
    );

    if (this.learner?.building?.defaultEducationalMinutes) {
      this.defaultEducationalMinutes = this.learner?.building?.defaultEducationalMinutes;
    }

    this.subscriptions.add(
      this.formGroup.controls.minutesRecievingInstruction.valueChanges.subscribe((val) => {
        const rationaleCtrl = this.formGroup.controls.minutesRecievingInstructionRationale;
        if (val < this.defaultEducationalMinutes) {
          rationaleCtrl.setValidators([Validators.required]);
        } else {
          rationaleCtrl.clearValidators();
          rationaleCtrl.setValue(null);
        }
        rationaleCtrl.updateValueAndValidity();
      })
    );

    this.subscriptions.add(
      this.formGroup.controls.whereReceiveSpecialEd.valueChanges.subscribe((x) => {
        if (x === 'Special School') {
          this.formGroup.get('attendSpecialSchool').setValue(true, { emitEvent: false });
        } else {
          this.formGroup.get('attendSpecialSchool').setValue(false, { emitEvent: false });
        }
      })
    );

    this.subscriptions.add(
      this.iepServiceService.focusLRERECPBtn$.subscribe(() => {
        setTimeout(() => {
          this.recpsAccurateAnchor.nativeElement.scrollIntoView({
            behavior: 'smooth',
          });
        }, 500);
      })
    );

    if (!this.summaryView) {
      this.saveSubscription = this.formGroup.valueChanges;
      this.startAutosaving();
    }
  }

  onAmend() {
    if (!this.amendment.amendingLRE) {
      this.amendment.amendingLRE = true;
      this.iepAmendmentService.updateAmendment(this.iepId, this.amendment).subscribe(() => {});
    }
  }

  goToLearnerManagement() {
    const config: NewWindowConfig = {
      path: '/learner-management/' + this.caseSummary.learnerId + '/learner-dashboard',
      popup: true,
      width: '1480px',
    };
    openNewWindow(config);
  }

  resetFields() {
    if (!this.iepIsPK) {
      this.formGroup.get('recpsAccurate').setValue(null);
    }

    if (!this.askReasonsCantProvideQuestion) {
      this.formGroup.get('reasonsCantProvide').setValue(null, { emitEvent: false });
    }

    if (!this.askSpecialServicesProvidedQuestion) {
      this.formGroup.get('specialServicesProvided').setValue(null, { emitEvent: false });
    }

    if (this.formGroup.get('specialServicesProvided').value === true) {
      this.formGroup.get('specialServicesProvidedExp').setValue(null, { emitEvent: false });
    }

    if (!this.askAttendSchoolIfQuestion) {
      this.formGroup.get('attendSchoolIf').setValue(null, { emitEvent: false });
    }

    if (this.attendSchoolIf === true) {
      this.formGroup.get('attendSchoolIfExp').setValue(null, { emitEvent: false });
    }

    if (!this.askWhereReceiveSpecialEdQuestion) {
      this.formGroup.get('whereReceiveSpecialEd').setValue(null, { emitEvent: false });
    }

    if (this.iepIsPK && this.attendSchoolIf === true) {
      this.formGroup.get('attendSpecialSchool').setValue(null, { emitEvent: false });
    }

    if (!this.attendSpecialSchool && !this.attendSpecialSchoolPK) {
      this.formGroup.get('supplementaryAids').setValue(null, { emitEvent: false });
      this.formGroup.get('notPossibleForAids').setValue(null, { emitEvent: false });
      this.formGroup.get('continuumServiceAvailable').setValue(null, { emitEvent: false });
      this.formGroup.get('cannotBeProvided').setValue(null, { emitEvent: false });
    }
  }

  async getLREData(patchFormData = true): Promise<void> {
    const lreDto = await this.iepService.getLREFromIep(this.iepId, '', this.amendmentId ? this.amendmentId : '').toPromise();
    if (!lreDto) {
      return;
    }

    this.lreModel = lreDto;
    this.iepIsPK = this.lreModel?.iepIsPK;
    this.showK = this.lreModel?.showK;
    if (this.lreModel?.earlyChildhoodPrograms) {
      this.recpsDataSource.data = this.lreModel?.earlyChildhoodPrograms.map((p) => {
        const { timeQuantity, timeUnit, frequency } = p;
        const howMuch = `${timeQuantity} ${timeQuantity === 1 ? timeUnit.slice(0, -1) : timeUnit} ${frequency}`.toLocaleLowerCase();
        return { ...p, howMuch };
      });
    }

    if (!this.iepMeetingDate) {
      this.iepMeetingDate = this.lreModel?.iepOpenDate;
    }
    if (patchFormData) {
      this.formGroup.patchValue({
        reasonsCantProvide: lreDto.reasonsCantProvide,
        specialServicesProvided: lreDto.specialServicesProvided,
        specialServicesProvidedExp: lreDto.specialServicesProvidedExp,
        recpsAccurate: lreDto.recpsAccurate,
        whereReceiveSpecialEd: lreDto.whereReceiveSpecialEd,
        attendSchoolIf: lreDto.attendSchoolIf,
        attendSchoolIfExp: lreDto.attendSchoolIfExp,
        attendSpecialSchool: lreDto.attendSpecialSchool,
        cannotBeProvided: lreDto.cannotBeProvided,
        supplementaryAids: lreDto.supplementaryAids,
        notPossibleForAids: lreDto.notPossibleForAids,
        continuumServiceAvailable: lreDto.continuumServiceAvailable,
        minutesRecievingInstructionRationale: lreDto.minutesRecievingInstructionRationale,
      });
      if (this.iepIsPK && !this.startingKDuringIep) {
        this.formGroup.get('minutesRecievingInstruction').setValue(0);
      } else {
        if (lreDto.minutesRecievingInstruction !== null) {
          this.formGroup.get('minutesRecievingInstruction').setValue(lreDto.minutesRecievingInstruction);
        } else {
          this.formGroup.get('minutesRecievingInstruction').setValue(this.defaultEducationalMinutes);
        }
      }
      this.resetFields();
    }
    this.lreStatsData = {
      generalEducationServices: lreDto.generalEducationServices,
      specialEducationServices: lreDto.specialEducationServices,
      generalEducationEnv: lreDto.generalEducationEnv,
      specialEducationEnv: lreDto.specialEducationEnv,
    };
    this.populateStats(this.lreStatsData);
  }

  populateStats(event) {
    this.generalEducationServices = event.generalEducationServices;
    this.specialEducationServices = event.specialEducationServices;
    this.generalEducationEnv = event.generalEducationEnv;
    this.specialEducationEnv = event.specialEducationEnv;
  }

  openSummary() {
    const config: NewWindowConfig = {
      path: `cases/${this.caseId}/iep/${this.iepId}/services/summary`,
      popup: true,
      width: '1480px',
    };
    openNewWindow(config);
  }

  async onSave(): Promise<void> {
    const formValues = this.formGroup.value;
    this.minutesRecievingInstructionInvalid = formValues.minutesRecievingInstruction > 2400;
    if (this.minutesRecievingInstructionInvalid || this.isDeleting) {
      return;
    }
    formValues.amendmentId = this.amendmentId;
    await this.iepService.updateLRE(this.iepId, formValues).toPromise();
  }
}
