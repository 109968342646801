import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';
import { ReferralReasonInformation } from 'src/app/child-find/early-access-referral/referral';
import { StringSizes } from 'src/app/shared/components/form/constants/constants';
import { notSureYesNoOptions, weightUnitOptions } from 'src/app/shared/formHelpers';
import { WeightUnits } from 'src/app/shared/models/weight-units';
import { UnknownYesNo } from 'src/app/shared/models/yes-no';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { ReferralReasonHelp } from 'src/app/shared/services/help/models/referral.help';
import { MedicalConditionService } from 'src/app/shared/services/medical-condition/medical-condition-service';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { ReferralService } from '../early-access-referral.service';

//#region Custom Validation

const developmentValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const cognitive = control.get('referralReasonCognitive').value;
  const motor = control.get('referralReasonMotor').value;
  const communication = control.get('referralReasonCommunication').value;
  const adaptive = control.get('referralReasonAdaptive').value;
  const social = control.get('referralReasonSocialEmotional').value;
  const other = control.get('referralReasonOther').value;
  const visionHearing = control.get('referralReasonDiagnosedCondition').value;
  const childPremature = control.get('referralReasonBornLessThan32Week').value;
  const childWeight = control.get('referralReasonBirthWeightLessThan3lb').value;
  const dhsNeglect = control.get('referralReasonAbuseOrNeglect').value;
  const dhsPIDs = control.get('referralReasonPIDs').value;
  const dhsCAPTA = control.get('referralReasonNonCAPTA').value;

  return !cognitive &&
    !motor &&
    !communication &&
    !adaptive &&
    !social &&
    !other &&
    !visionHearing &&
    !childPremature &&
    !childWeight &&
    !dhsNeglect &&
    !dhsPIDs &&
    !dhsCAPTA
    ? { delayValidator: true }
    : null;
};

const screeningToolValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const cognitive = control.get('referralReasonCognitive').value;
  const motor = control.get('referralReasonMotor').value;
  const communication = control.get('referralReasonCommunication').value;
  const adaptive = control.get('referralReasonAdaptive').value;
  const social = control.get('referralReasonSocialEmotional').value;
  const other = control.get('referralReasonOther').value;
  const toolUsed = control.get('referralReasonScreeningToolUsed').value;

  const delaySelected = cognitive || motor || communication || adaptive || social || other;

  return delaySelected && typeof toolUsed !== 'string' ? { toolRequired: true } : null;
};

const toolNameValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const toolUsed = control.get('referralReasonScreeningToolUsed').value;
  const toolIds = control.get('screeningToolIds').value;

  return toolUsed === UnknownYesNo.Yes && toolIds.length === 0 ? { nameRequired: true } : null;
};

const descriptionValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const otherCheckbox = control.get('referralReasonOther');
  const description = control.get('referralReasonDesc');
  return otherCheckbox.value === true && !description.value ? { descRequired: true } : null;
};

const birthPrematureValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const childPremature = control.get('referralReasonBornLessThan32Week');
  const weeks = parseFloat(control.get('referralReasonBornInWeeks').value) || 0;
  const days = parseFloat(control.get('referralReasonBornInDays').value) || 0;

  return weeks * 7 + days >= 7 * 32 ? { ageInvalid: true } : null;
};

const birthWeightValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const childWeight = control.get('referralReasonBirthWeightLessThan3lb');
  const weightUnits = control.get('referralReasonBirthWeightUnits');

  switch (weightUnits.value) {
    case 'lbOz':
      const lb = parseFloat(control.get('referralReasonBirthInLb').value) || 0;
      const oz = parseFloat(control.get('referralReasonBirthInOz').value) || 0;
      return lb * 16 + oz >= 3 * 16 + 5 ? { weightInvalid: true } : null;
    case 'g':
      const g = parseFloat(control.get('referralReasonBirthInGrams').value) || 0;
      return g >= 1500 ? { weightInvalid: true } : null;
    case 'kg':
      const kg = parseFloat(control.get('referralReasonBirthInKilograms').value) || 0;
      return kg >= 1.5 ? { weightInvalid: true } : null;
    default:
      return null;
  }
};

const diagnosedConditionValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const condition = control.get('referralReasonDiagnosedCondition');
  const conditionName = control.get('referralReasonNameMedicallyDiagnosedCondition');

  return condition && condition.value === true && conditionName && conditionName.value === '' ? { conditionRequired: true } : null;
};

//#endregion

@Component({
  selector: 'app-referral-reason-info-form',
  templateUrl: './referral-reason-info-form.component.html',
  styleUrls: ['../early-access-referral.component.scss', './referral-reason-info-form.component.scss'],
})
export class ReferralReasonInfoFormComponent implements OnInit, OnDestroy, OnChanges {
  constructor(
    private medicalConditionService: MedicalConditionService,
    private readonly referralService: ReferralService,
    private helpService: HelpService
  ) {}
  private subscription: Subscription = new Subscription();
  @Input() referralId: string;
  @Input() formGroup: FormGroup;
  @Input() referralReasonInformation: ReferralReasonInformation;
  screeningToolOptions: KeyValuePair[] = [];
  weightUnits = WeightUnits;
  unknownYesNo = UnknownYesNo;
  notSureYesNoOptions = notSureYesNoOptions;
  weightUnitOptions = weightUnitOptions;
  screeningToolOtherId: string;

  helpSection = HelpSection;
  referralReasonHelp = ReferralReasonHelp;
  stringSizes = StringSizes;

  ngOnInit() {
    this.initializeControls();
    this.initializeTools();
  }

  ngOnChanges(changes: SimpleChanges) {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm) {
    e.preventDefault();

    const dictionary = this.helpService.getReferralDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }

  private initializeTools() {
    this.referralService.getScreeningTools().subscribe((res) => {
      this.screeningToolOptions = res.map((x) => new KeyValuePair(x.id, x.label));
      this.screeningToolOtherId = res.find((x) => x.isOther).id;
    });
  }

  private initializeControls() {
    const referralReasonInfo = this.referralReasonInformation || ({} as ReferralReasonInformation);
    this.formGroup.addControl(
      'referralReasonCognitive',
      new FormControl(referralReasonInfo.referralReasonCognitive, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl(
      'referralReasonMotor',
      new FormControl(referralReasonInfo.referralReasonMotor, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl(
      'referralReasonCommunication',
      new FormControl(referralReasonInfo.referralReasonCommunication, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl(
      'referralReasonAdaptive',
      new FormControl(referralReasonInfo.referralReasonAdaptive, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl(
      'referralReasonSocialEmotional',
      new FormControl(referralReasonInfo.referralReasonSocialEmotional, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl(
      'referralReasonOther',
      new FormControl(referralReasonInfo.referralReasonOther, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl(
      'referralReasonDesc',
      new FormControl(referralReasonInfo.referralReasonDesc, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl(
      'referralReasonScreeningToolUsed',
      new FormControl(referralReasonInfo.referralReasonScreeningToolUsed, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl('screeningToolIds', new FormControl(referralReasonInfo.screeningTools?.map((st) => st.id) || []));
    this.formGroup.addControl(
      'screeningToolOther',
      new FormControl(referralReasonInfo.screeningToolOther, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl(
      'referralReasonDiagnosedCondition',
      new FormControl(referralReasonInfo.referralReasonDiagnosedCondition, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl(
      'referralReasonDiagnosedConditions',
      new FormControl(referralReasonInfo.referralReasonDiagnosedConditions || [], {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl(
      'referralReasonNameMedicallyDiagnosedCondition',
      new FormControl(referralReasonInfo.referralReasonNameMedicallyDiagnosedCondition)
    );
    this.formGroup.addControl('referralReasonICDCode', new FormControl(referralReasonInfo.referralReasonICDCode));
    this.formGroup.addControl(
      'referralReasonBornLessThan32Week',
      new FormControl(referralReasonInfo.referralReasonBornLessThan32Week, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl(
      'referralReasonBornInWeeks',
      new FormControl(referralReasonInfo.referralReasonBornInWeeks, [Validators.min(0), Validators.max(31)])
    );
    this.formGroup.addControl(
      'referralReasonBornInDays',
      new FormControl(referralReasonInfo.referralReasonBornInDays, [Validators.min(0), Validators.max(6)])
    );
    this.formGroup.addControl(
      'referralReasonBirthWeightLessThan3lb',
      new FormControl(referralReasonInfo.referralReasonBirthWeightLessThan3lb, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl('referralReasonBirthWeightUnits', new FormControl('lbOz'));
    this.formGroup.addControl(
      'referralReasonBirthInLb',
      new FormControl(referralReasonInfo.referralReasonBirthInLb, [Validators.min(0), Validators.max(3)])
    );
    this.formGroup.addControl(
      'referralReasonBirthInOz',
      new FormControl(referralReasonInfo.referralReasonBirthInOz, [Validators.min(0), Validators.max(15)])
    );
    this.formGroup.addControl(
      'referralReasonBirthInGrams',
      new FormControl(referralReasonInfo.referralReasonBirthInGrams, [Validators.min(0), Validators.max(1500)])
    );
    this.formGroup.addControl(
      'referralReasonBirthInKilograms',
      new FormControl(referralReasonInfo.referralReasonBirthInKilograms, [Validators.min(0), Validators.max(1.5)])
    );
    this.formGroup.addControl(
      'referralReasonAbuseOrNeglect',
      new FormControl(referralReasonInfo.referralReasonAbuseOrNeglect, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl(
      'referralReasonPIDs',
      new FormControl(referralReasonInfo.referralReasonPIDs, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl(
      'referralReasonNonCAPTA',
      new FormControl(referralReasonInfo.referralReasonNonCAPTA, {
        updateOn: 'change',
      })
    );
    this.formGroup.setValidators([
      developmentValidator,
      birthPrematureValidator,
      birthWeightValidator,
      screeningToolValidator,
      toolNameValidator,
      diagnosedConditionValidator,
      descriptionValidator,
    ]);
    this.formGroup.updateValueAndValidity();
  }

  resetWeights() {
    this.formGroup.patchValue({
      referralReasonBirthInLb: null,
      referralReasonBirthInOz: null,
      referralReasonBirthInGrams: null,
      referralReasonBirthInKilograms: null,
    });
  }

  resetGestationalAge(event: MatCheckboxChange) {
    if (!event.checked) {
      this.formGroup.get('referralReasonBornInWeeks').setValue(null);
      this.formGroup.get('referralReasonBornInDays').setValue(null);
    }
  }
}
