<main>
  <div class="hide-print">
    <app-page-header [pageTitle]="'Early ACCESS Referral'" [helpLinks]="false">
      <ng-container pageActions>
        <button mat-raised-button aria-label="Print Button" color="primary" (click)="onPrint()" tabindex="0">Print</button>
      </ng-container>
    </app-page-header>
  </div>

  <h1 class="hide-normal">Early ACCESS Referral</h1>

  <div *ngIf="referral && referral?.childInfo" class="card-spacing-top padding-fix print__container">
    <h3 class="text-semibold mb-3 mt-0">Child Information</h3>
    <section *ngIf="referral" class="avoid-break">
      <div class="display-flex flex-wrap">
        <p *ngIf="referral.childInfo.firstName">
          <strong>Name:</strong>
          {{ referral.childInfo.firstName }}
          {{ referral.childInfo.middleName ? referral.childInfo.middleName : '' }}
          {{ referral.childInfo.lastName }}
        </p>

        <p *ngIf="referral.childInfo.dateOfBirth">
          <strong>DOB:</strong>
          {{ referral.childInfo.dateOfBirth | dateFormat }}
        </p>

        <p *ngIf="referral.childInfo.gender"><strong>Gender:</strong> {{ referral.childInfo.gender }}</p>

        <p *ngIf="referral.childInfo.languageSpokenInHome">
          <strong>Primary Language Spoken In Home:</strong>
          {{ referral.childInfo.languageSpokenInHome }}
        </p>

        <p *ngIf="referral.childInfo.interpreterNeeded !== null">
          <strong>Interpreter Needed:</strong>
          {{ returnYesOrNo(referral.childInfo.interpreterNeeded) }}
        </p>
      </div>
    </section>

    <hr class="input__divider" />

    <section *ngIf="referral && referral?.childInfo" class="card-spacing-top avoid-break">
      <h3 class="text-semibold mb-3 mt-0">Residential Information</h3>
      <div *ngIf="referral">
        <div class="display-flex flex-wrap">
          <p *ngIf="referral.childInfo.streetAddress">
            <strong>Address:</strong>
            {{ referral.childInfo.streetAddress }},
            {{ referral.childInfo.city }}
            ({{ referral.childInfo.county }}),
            {{ referral.childInfo.state }}
            {{ referral.childInfo.zipCode }}
          </p>
        </div>

        <div class="display-flex flex-wrap">
          <p *ngIf="referral.childInfo.childAttendingDistrict" class="mr-2">
            <strong>Child's Attending School District:</strong>
            {{ referral.childInfo.childAttendingDistrict }}
          </p>
          <p *ngIf="referral.childInfo.childResidentDistrict">
            <strong>Child's Resident School District:</strong>
            {{ referral.childInfo.childResidentDistrict }}
          </p>
        </div>
      </div>
    </section>

    <hr class="input__divider" />

    <section *ngIf="referral?.childInfo?.attendsChildCare" class="card-spacing-top avoid-break">
      <h3 class="text-semibold mb-3 mt-0">Child Care</h3>
      <div>
        <div class="display-flex flex-wrap">
          <p *ngIf="referral.childInfo.childCareProviderName">
            <strong>Child Care Provider Name:</strong>
            {{ referral.childInfo.childCareProviderName }}
          </p>
          <p *ngIf="referral.childInfo.childCareProviderStreetAddress">
            <strong>Address:</strong>
            {{ referral.childInfo.childCareProviderStreetAddress }}, {{ referral.childInfo.childCareProviderCity }},
            {{ referral.childInfo.childCareProviderState }}
            {{ referral.childInfo.childCareProviderZipCode }}
          </p>
        </div>
      </div>
      <hr class="input__divider" />
    </section>

    <section *ngIf="referral?.parentInfo?.parent1Name" class="card-spacing-top avoid-break">
      <h3 class="text-semibold mb-3 mt-0">Parent 1 Information</h3>
      <div class="display-flex flex-wrap">
        <p *ngIf="referral.parentInfo.parent1Name"><strong>Name:</strong> {{ referral.parentInfo.parent1Name }}</p>
        <p *ngIf="referral.parentInfo.parent1Email"><strong>Email:</strong> {{ referral.parentInfo.parent1Email }}</p>
        <p *ngIf="referral.parentInfo.parent1LivesWithChild !== null">
          <strong>Lives With Child:</strong>
          {{ returnYesOrNo(referral.parentInfo.parent1LivesWithChild) }}
        </p>
        <p *ngIf="referral.parentInfo.parent1HomePhone">
          <strong>Home Phone Number:</strong>
          {{ referral.parentInfo.parent1HomePhone | phone }}
        </p>
        <p *ngIf="referral.parentInfo.parent1CellPhone">
          <strong>Cell Phone Number:</strong>
          {{ referral.parentInfo.parent1CellPhone | phone }}
        </p>
        <p *ngIf="referral.parentInfo.parent1WorkPhone">
          <strong>Work Phone Number:</strong>
          {{ referral.parentInfo.parent1WorkPhone | phone }}
        </p>
        <p *ngIf="getBestWayToReach('parent1').length > 0">
          <strong>Best Way To Contact:</strong>
          {{ getBestWayToReach('parent1') }}
        </p>
        <p *ngIf="referral.parentInfo.parent1BestTimeToContact !== null">
          <strong>Best Time to Contact:</strong>
          {{ referral.parentInfo.parent1BestTimeToContact }}
        </p>
        <div *ngIf="!referral.parentInfo.parent1LivesWithChild">
          <p>
            <strong>Address:</strong>
            {{ referral.parentInfo.parent1StreetAddress }}, {{ referral.parentInfo.parent1City }},
            {{ referral.parentInfo.parent1State }}
            {{ referral.parentInfo.parent1ZipCode }}
          </p>
        </div>
      </div>
    </section>

    <section *ngIf="referral?.parentInfo?.parent2Name" class="card-spacing-top avoid-break">
      <h3 class="text-semibold mb-3 mt-0">Parent 2 Information</h3>
      <div class="display-flex flex-wrap">
        <p *ngIf="referral.parentInfo.parent2Name"><strong>Name:</strong> {{ referral.parentInfo.parent2Name }}</p>
        <p *ngIf="referral.parentInfo.parent2Email"><strong>Email:</strong> {{ referral.parentInfo.parent2Email }}</p>
        <p *ngIf="referral.parentInfo.parent2LivesWithChild !== null">
          <strong>Lives With Child:</strong>
          {{ returnYesOrNo(referral.parentInfo.parent2LivesWithChild) }}
        </p>
        <p *ngIf="referral.parentInfo.parent2HomePhone">
          <strong>Home Phone Number:</strong>
          {{ referral.parentInfo.parent2HomePhone | phone }}
        </p>
        <p *ngIf="referral.parentInfo.parent2CellPhone">
          <strong>Cell Phone Number:</strong>
          {{ referral.parentInfo.parent2CellPhone | phone }}
        </p>
        <p *ngIf="referral.parentInfo.parent2WorkPhone">
          <strong>Work Phone Number:</strong>
          {{ referral.parentInfo.parent2WorkPhone | phone }}
        </p>
        <p *ngIf="getBestWayToReach('parent2').length > 0">
          <strong>Best Way To Contact:</strong>
          {{ getBestWayToReach('parent2') }}
        </p>
        <p *ngIf="referral.parentInfo.parent2BestTimeToContact !== null">
          <strong>Best Time to Contact:</strong>
          {{ referral.parentInfo.parent2BestTimeToContact }}
        </p>
        <div *ngIf="!referral.parentInfo.parent2LivesWithChild">
          <p>
            <strong>Address:</strong>
            {{ referral.parentInfo.parent2StreetAddress }}, {{ referral.parentInfo.parent2City }},
            {{ referral.parentInfo.parent2State }}
            {{ referral.parentInfo.parent2ZipCode }}
          </p>
        </div>
      </div>
    </section>

    <section *ngIf="referral?.familyInfo?.livesWith1Name" class="card-spacing-top avoid-break">
      <h3 class="text-semibold mb-3 mt-0">Lives With 1 Information</h3>
      <div class="display-flex flex-wrap">
        <p *ngIf="referral.familyInfo.livesWith1Name"><strong>Name:</strong> {{ referral.familyInfo.livesWith1Name }}</p>
        <p *ngIf="referral.familyInfo.livesWith1Email"><strong>Email:</strong> {{ referral.familyInfo.livesWith1Email }}</p>
        <p *ngIf="referral.familyInfo.livesWith1HomePhone">
          <strong>Home Phone Number:</strong>
          {{ referral.familyInfo.livesWith1HomePhone | phone }}
        </p>
        <p *ngIf="referral.familyInfo.livesWith1CellPhone">
          <strong>Cell Phone Number:</strong>
          {{ referral.familyInfo.livesWith1CellPhone | phone }}
        </p>
        <p *ngIf="referral.familyInfo.livesWith1WorkPhone">
          <strong>Work Phone Number:</strong>
          {{ referral.familyInfo.livesWith1WorkPhone | phone }}
        </p>
        <p *ngIf="getBestWayToReach('livesWith1').length > 0">
          <strong>Best Way To Contact:</strong>
          {{ getBestWayToReach('livesWith1') }}
        </p>
        <p *ngIf="referral.familyInfo.livesWith1BestTimeToContact !== null">
          <strong>Best Time to Contact:</strong>
          {{ referral.familyInfo.livesWith1BestTimeToContact }}
        </p>
      </div>
    </section>
    <div *ngIf="referral.referralSource === 'LivesWith1'">
      <p class="w-100" *ngIf="referral.documentationInfo.legalGuardian !== null">
        <strong>Is the parent or legal guardian aware that the referral has been made to Early ACCESS:</strong>
        {{ returnYesOrNo(referral.legalGuardian) }}
      </p>
      <p class="w-100" *ngIf="referral.documentationInfo.exchangeInformation !== null">
        <strong>Do you have a signed release from the family to exchange information about the child with Early ACCESS?:</strong>
        {{ returnYesOrNo(referral.exchangeInformation) }}
      </p>
    </div>

    <section *ngIf="referral?.familyInfo?.livesWith2Name" class="card-spacing-top avoid-break">
      <h3 class="text-semibold mb-3 mt-0">Lives With Information</h3>
      <div class="display-flex flex-wrap">
        <p *ngIf="referral.familyInfo.livesWith2Name"><strong>Name:</strong> {{ referral.familyInfo.livesWith2Name }}</p>
        <p *ngIf="referral.familyInfo.livesWith2Email"><strong>Email:</strong> {{ referral.familyInfo.livesWith2Email }}</p>
        <p *ngIf="referral.familyInfo.livesWith2HomePhone">
          <strong>Home Phone Number:</strong>
          {{ referral.familyInfo.livesWith2HomePhone | phone }}
        </p>
        <p *ngIf="referral.familyInfo.livesWith2CellPhone">
          <strong>Cell Phone Number:</strong>
          {{ referral.familyInfo.livesWith2CellPhone | phone }}
        </p>
        <p *ngIf="referral.familyInfo.livesWith2WorkPhone">
          <strong>Work Phone Number:</strong>
          {{ referral.familyInfo.livesWith2WorkPhone | phone }}
        </p>
        <p *ngIf="getBestWayToReach('livesWith2').length > 0">
          <strong>Best Way To Contact:</strong>
          {{ getBestWayToReach('livesWith2') }}
        </p>
        <p *ngIf="referral.familyInfo.livesWith2BestTimeToContact !== null">
          <strong>Best Time to Contact:</strong>
          {{ referral.familyInfo.livesWith2BestTimeToContact }}
        </p>
      </div>
    </section>
    <div *ngIf="referral.referralSource === 'LivesWith2'">
      <p class="w-100" *ngIf="referral.documentationInfo.legalGuardian !== null">
        <strong>Is the parent or legal guardian aware that the referral has been made to Early ACCESS:</strong>
        {{ returnYesOrNo(referral.legalGuardian) }}
      </p>
      <p class="w-100" *ngIf="referral.documentationInfo.exchangeInformation !== null">
        <strong> Do you have a signed release from the family to exchange information about the child with Early ACCESS?:</strong>
        {{ returnYesOrNo(referral.exchangeInformation) }}
      </p>
    </div>

    <section *ngIf="referral?.parentInfo?.othersInHome" class="card-spacing-top avoid-break">
      <h3 class="text-semibold mb-3 mt-0">Others in Home</h3>
      <div class="display-flex flex-wrap">
        <p>
          <strong>Others In Home:</strong>
          {{ referral.parentInfo.othersInHome }}
        </p>
      </div>
    </section>

    <hr class="input__divider" />

    <section *ngIf="referral?.referralSourceInfo.referralSourceName" class="card-spacing-top avoid-break">
      <h3 class="text-semibold mb-3 mt-0">Referral Source Information</h3>
      <div class="display-flex flex-wrap">
        <p *ngIf="referral.referralSourceInfo.referralSourceName">
          <strong>Name:</strong>
          {{ referral.referralSourceInfo.referralSourceName }}
        </p>
        <p *ngIf="referral.referralSourceInfo.referralSourceEmail">
          <strong>Email:</strong>
          {{ referral.referralSourceInfo.referralSourceEmail }}
        </p>
        <p *ngIf="referral.referralSourceInfo.referralSourceAgency">
          <strong>Agency/Organization:</strong>
          {{ referral.referralSourceInfo.referralSourceAgency }}
        </p>
        <p *ngIf="referral.referralSourceInfo.referralSourceHomePhone">
          <strong>Home Phone:</strong>
          {{ referral.referralSourceInfo.referralSourceHomePhone | phone }}
        </p>
        <p *ngIf="referral.referralSourceInfo.referralSourceCellPhone">
          <strong>Cell Phone:</strong>
          {{ referral.referralSourceInfo.referralSourceCellPhone | phone }}
        </p>
        <p *ngIf="referral.referralSourceInfo.referralSourceWorkPhone">
          <strong>Work Phone:</strong>
          {{ referral.referralSourceInfo.referralSourceWorkPhone | phone }}
        </p>
        <p *ngIf="referral.referralSourceInfo.referralSourceAddress">
          <strong>Address:</strong>
          {{ referral.referralSourceInfo.referralSourceAddress }}, {{ referral.referralSourceInfo.referralSourceCity }},
          {{ referral.referralSourceInfo.referralSourceState }}
          {{ referral.referralSourceInfo.referralSourceZipCode }}
        </p>
        <p *ngIf="referral.submittedOn">
          <strong>Referral Date:</strong>
          {{ referral.submittedOn | dateFormat }}
        </p>
        <p *ngIf="referral.referralSourceHowHearAboutUs">
          <strong>How did you hear about Early ACCESS:</strong>
          {{ referral.referralSourceHowHearAboutUs }}
        </p>
        <p *ngIf="referral.referralSourceEarlyAccessOtherText">
          <strong>How did you hear about Early ACCESS:</strong>
          {{ referral.referralSourceEarlyAccessOtherText }}
        </p>
        <p *ngIf="referral.referralSourceInfo.referralSourceCategory">
          <strong>Referral Source Category:</strong>
          {{ referral.referralSourceInfo.referralSourceCategory }}
        </p>
        <div *ngIf="referral.referralSource !== 'Parent1' && referral.referralSource !== 'Parent2'">
          <p class="w-100" *ngIf="referral.documentationInfo.legalGuardian !== null">
            <strong> Is the parent or legal guardian aware that the referral has been made to Early ACCESS: </strong>
            {{ returnYesOrNo(referral.legalGuardian) }}
          </p>
          <p class="w-100" *ngIf="referral.documentationInfo.exchangeInformation !== null">
            <strong> Do you have a signed release from the family to exchange information about the child with Early ACCESS?: </strong>
            {{ returnYesOrNo(referral.exchangeInformation) }}
          </p>
        </div>
      </div>
    </section>

    <hr class="input__divider" *ngIf="referral?.referralSourceInfo.referralSourceName" />

    <section *ngIf="referral?.referralReasonInfo" class="card-spacing-top avoid-break">
      <h3 class="text-semibold mb-3 mt-0">Reason For Referral</h3>
      <div class="display-flex flex-wrap">
        <div *ngIf="isADevelopmentalDelay()">
          <p>
            <strong>Areas of Developmental Delay:</strong>
            {{ developmentDelayList() }}
          </p>

          <p *ngIf="referral.referralReasonInfo">
            <strong>Description Of Concern:</strong>
            {{ referral.referralReasonInfo.referralReasonDesc }}
          </p>

          <p *ngIf="referral.referralReasonInfo.referralReasonScreeningToolUsed !== null">
            <strong>Was a screening tool used:</strong>
            {{ referral.referralReasonInfo.referralReasonScreeningToolUsed }}
          </p>
          <p *ngIf="referral.referralReasonInfo.referralReasonScreeningToolUsed === unknownYesNo.Yes">
            <strong>Name of Tool(s):</strong>
            {{ screeningTools }}
          </p>
        </div>
        <div *ngIf="referral.referralReasonInfo.referralReasonDiagnosedCondition !== null">
          <p>
            <strong>Diagnosed Condition, Including Vision and Hearing:</strong>
            {{ returnYesOrNo(referral.referralReasonInfo.referralReasonDiagnosedCondition) }}
          </p>

          <p>
            <strong>Medically Diagnosed Conditions:</strong><br />
            <span *ngFor="let condition of referral.referralReasonInfo.referralReasonDiagnosedConditions">
              {{ condition.name }} <span *ngIf="condition.icdCode">({{ condition.icdCode }})</span> <br />
            </span>
          </p>
        </div>

        <div *ngIf="referral.referralReasonInfo.referralReasonBornLessThan32Week !== null">
          <p>
            <strong>Child born less than 32 week gestation:</strong>
            {{ returnYesOrNo(referral.referralReasonInfo.referralReasonBornLessThan32Week) }}
          </p>
          <p *ngIf="referral.referralReasonInfo.referralReasonBornInWeeks">
            <strong>Weeks:</strong>
            {{ referral.referralReasonInfo.referralReasonBornInWeeks }}
          </p>

          <p *ngIf="referral.referralReasonInfo.referralReasonBornInDays">
            <strong>Days:</strong>
            {{ referral.referralReasonInfo.referralReasonBornInDays }}
          </p>
        </div>
        <div *ngIf="referral.referralReasonInfo.referralReasonBirthWeightLessThan3lb !== null">
          <p>
            <strong>Child's birth weight less than 3lb 5oz (1500g):</strong>
            {{ returnYesOrNo(referral.referralReasonInfo.referralReasonBirthWeightLessThan3lb) }}
          </p>
          <p>
            <strong>Birth Weight:</strong>
            {{ getBirthWeight() }}
          </p>
        </div>
        <div>
          <p *ngIf="referral.referralReasonInfo.referralReasonAbuseOrNeglect">
            <strong>Substantiated Case of Abuse or Neglect (CAPTA):</strong>
            {{ returnYesOrNo(referral.referralReasonInfo.referralReasonAbuseOrNeglect) }}
          </p>
          <p *ngIf="referral.referralReasonInfo.referralReasonPIDs">
            <strong>PIDs "Presence of illegal drugs" (CAPTA) Referral:</strong>
            {{ returnYesOrNo(referral.referralReasonInfo.referralReasonPIDs) }}
          </p>
          <p *ngIf="referral.referralReasonInfo.referralReasonNonCAPTA">
            <strong>Other, non-CAPTA:</strong>
            {{ returnYesOrNo(referral.referralReasonInfo.referralReasonNonCAPTA) }}
          </p>
        </div>
      </div>
    </section>

    <hr class="input__divider" *ngIf="referral && documents.length > 0" />

    <section *ngIf="referral && documents.length > 0" class="card-spacing-top avoid-break">
      <h3 class="text-semibold mb-3 mt-0">Documentation</h3>
      <table class="table table-bordered">
        <tbody>
          <tr *ngFor="let document of documents">
            <th>{{ document.title }}</th>
            <td>
              <a
                href="/api/early-access-referrals/{{ referralId }}/documents/{{ document.id }}"
                target="_blank"
                tabindex="0"
                role="link"
                aria-labelledby="File Name"
              >
                {{ document.fileName }}</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </section>
  </div>
</main>

<div class="page-bottom-leeway"></div>
