import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { LoginModel } from '../auth-models';
import { AuthService } from '../auth.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  loginFailed = false;
  isBusy = false;
  isMfaTokenVisible = false;
  mfaToken: any = {};
  mfaErrorMessage = '';
  email = '';

  constructor(private breakpointObserver: BreakpointObserver, private authService: AuthService, private route: ActivatedRoute) {}

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  ngOnInit() {}

  onSubmit(creds: LoginModel) {
    this.loginFailed = false;
    this.isBusy = true;
    this.authService.login(creds).subscribe(
      () => {
        if (this.authService.isAuthenticated) {
          console.log('Login successful');
          if (this.authService.isPortalUser) {
            if (this.authService.isMultifactorAuthenticationRequired) {
              this.email = creds.email;
              this.isMfaTokenVisible = true;
            } else {
              this.authService.navigateToPortal();
            }
          } else {
            this.authService.navigatePostAuth();
          }
        }
        this.isBusy = false;
      },
      (err: any) => {
        this.loginFailed = true;
        this.isBusy = false;
        console.log('Login error...');
        console.log(err);
      }
    );
  }

  onSubmitMfa(mfa) {
    this.mfaToken.pinCode = mfa.pin;
    this.mfaToken.email = this.email;
    this.loginFailed = false;
    this.isBusy = true;
    this.authService.mfa(this.mfaToken).subscribe(
      () => {
        if (this.authService.isAuthenticated) {
          if (this.authService.isPortalUser) {
            this.authService.navigateToPortal();
          } else {
            this.authService.navigatePostAuth();
          }
        }
        this.isBusy = false;
      },
      (err: any) => {
        this.loginFailed = true;
        this.isBusy = false;
        console.log('Login error...');
        console.log(err);
      }
    );
  }
}
