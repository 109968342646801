<div class="row">
  <div class="col">
    <h3>
      <ng-content select=".title"></ng-content>
    </h3>
  </div>
</div>
<div class="row">
  <div class="col">
    <mat-card class="pb-2" [formGroup]="questionFormGroup">
      <div class="row">
        <div class="col">
          <ng-content select=".body"></ng-content>
          <div class="divider mt-2"></div>
          <div class="display-flex align-items-center">
            <app-radio-group
              aria-labelledby="Select Yes or No option"
              formControlName="answer"
              [options]="yesNoOptions"
              [defaultValue]="null"
            ></app-radio-group>
          </div>
        </div>
      </div>
      <div class="row mt-1">
        <div class="col-12">
          <div>
            <app-textarea
              [hideAsterisk]="!answerFormControl.value"
              formControlName="comments"
              label="If yes, summarize the data used to conclude that the learner meets these condition(s)"
              [rows]="4"
              [maxLength]="10000"
              attr.aria-required="{{ answerFormControl.value ? 'true' : 'false' }}"
            ></app-textarea>
          </div>
        </div>
      </div>
    </mat-card>
  </div>
</div>
