<app-page-header [pageTitle]="pageTitle" backgroundColor="yellow" [condensed]="true" [helpLinks]="true" [shouldShowDhhBanner]="isDhh">
  <ng-container pageActions>
    <app-create-output-button
      *ngIf="isOutputEnabled"
      [output]="pdfOutputs.ClpOutput"
      [apiId]="iepId"
      [learnerId]="learnerId"
      [openNewIcon]="true"
      [documentId]="clp?.isCompleted ? clp?.outputDocumentId : null"
      [isDraft]="!clp?.isCompleted"
      buttonText="CLP Plan"
      buttonColor="primary"
      class="mr-2"
      buttonType="mat-flat-button"
    ></app-create-output-button>
    <button mat-flat-button (click)="goToIep()" color="primary" class="mat-elevation-z0 mr-3" tabindex="0" aria-label="Return To IEP">
      Return To IEP
    </button>
    <app-create-output-button
      *ngIf="isOutputEnabled && canPushToPortal"
      [output]="pdfOutputs.ClpOutput"
      [apiId]="iepId"
      [learnerId]="learnerId"
      [openNewIcon]="true"
      [isDraft]="true"
      [pushDraftToPortal]="true"
      buttonText="Push to Portal"
      buttonColor="primary"
      class="mr-2"
      buttonType="mat-flat-button"
    >
    </app-create-output-button>
  </ng-container>
</app-page-header>
<app-dynamic-learner-header></app-dynamic-learner-header>

<ng-container [formGroup]="clpForm">
  <div class="display-flex align-items-center justify-content-end mb-2">
    <app-global-expander></app-global-expander>
  </div>

  <mat-card class="card-spacing-bottom" *ngIf="clp">
    <mat-card-content>
      <p>
        This plan will be implemented if there is a disruption in educational services due to unusual circumstances; such as a virtual snow
        day, a natural disaster event or large-scale health event. The IEP team should reconvene if conditions are such that the plan needs
        to be revised.
      </p>
    </mat-card-content>
  </mat-card>

  <app-help-directional-text>
    <p class="card-spacing-bottom">
      Click on each header to describe how specific sections of the IEP will be addressed in the event of long-term and short-term
      disruptions.
    </p>
  </app-help-directional-text>

  <mat-card class="card-spacing-bottom" *ngIf="clp">
    <mat-card-content>
      <h3>Plan for Disruption</h3>
      <mat-accordion class="accordion accordion--close accordion--short" multi captureExpand>
        <mat-expansion-panel class="mat-elevation-z0 mb-2">
          <mat-expansion-panel-header>
            <mat-panel-title class="text-black"> <h3 class="my-0 text-normal">Goals</h3> </mat-panel-title>
          </mat-expansion-panel-header>
          <app-goal-plan
            *ngFor="let goalPlan of toFormArray(clpForm.controls.goalPlans).controls; let i = index"
            [goals]="clp.goals"
            [readOnly]="clp.isCompleted"
            [form]="goalPlan | formGroup"
            [attendingDistrictId]="attendingDistrictId"
          >
          </app-goal-plan>
          <p *ngIf="!clp.goals?.length">There are not completed goals associated with this IEP.</p>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title class="text-black"> <h3 class="my-0 text-normal text-black">Special Education Services</h3> </mat-panel-title>
          </mat-expansion-panel-header>
          <p>For each service, describe the frequency and delivery format</p>
          <ng-container *ngFor="let servicePlan of toFormArray(clpForm.controls.specialServicePlans).controls; let i = index">
            <mat-divider *ngIf="i > 0"></mat-divider>
            <app-special-education-service-plan
              [specialEducationServices]="clp.specialServices"
              [form]="servicePlan | formGroup"
              [frequencyOptions]="specialEducationServiceFrequencies"
              [deliveryFormatOptions]="specialEducationServiceDeliveryFormats"
              [readonly]="clp.isCompleted"
              [iepTrialPlacement]="iepTrialPlacement"
            >
            </app-special-education-service-plan>
          </ng-container>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title class="text-black">
              <h3 class="my-0 text-normal text-black">Supports, Activities, and Accommodations</h3>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <p>
            Describe changes in support, activities, and accommodations that are necessary to access the academic materials being provided
            during distance/eLearning.
          </p>
          <ng-container *ngFor="let asa of toFormArray(clpForm.controls.activitySupportAccommodationPlans).controls; let i = index">
            <mat-divider *ngIf="i > 0"></mat-divider>
            <app-support-activity-accommodation-plan
              [activitySupports]="clp.activitySupports"
              [accommodations]="clp.accommodations"
              [readOnly]="clp.isCompleted"
              [form]="asa | formGroup"
              [frequencyOptions]="specialEducationServiceFrequencies"
            >
            </app-support-activity-accommodation-plan>
          </ng-container>
        </mat-expansion-panel>
        <mat-expansion-panel class="mat-elevation-z0">
          <mat-expansion-panel-header>
            <mat-panel-title class="text-black"> <h3 class="my-0 text-normal text-black">Other Supports</h3> </mat-panel-title>
          </mat-expansion-panel-header>
          <app-other-supports [form]="clpForm.controls.otherSupports | formGroup" [readonly]="clp.isCompleted"></app-other-supports>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-card-content>
  </mat-card>

  <mat-card class="card-spacing-top py-1">
    <div class="row">
      <div class="col-md-12">
        <app-textarea
          label="Other Relevant Factors"
          formControlName="otherRelevantFactors"
          [rows]="4"
          [readOnly]="clp?.isCompleted"
          [disabledState]="clp?.isCompleted"
        ></app-textarea>
      </div>
    </div>
  </mat-card>

  <div *ngIf="!clp?.isCompleted" class="action__row">
    <button mat-raised-button aria-label="Save & Exit" color="primary" (click)="saveExit()" tabindex="0">Save & Exit</button>
  </div>
</ng-container>

<div class="page-bottom-leeway"></div>
