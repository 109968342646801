import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FileDocument } from '../../models/file-document';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UploadModalComponent } from '../document-upload/upload-modal/upload-modal.component';
import { ConfirmationDialogComponent } from '../../services/notification.service';
import { base64ToBlobUrl, toBase64 } from '../../base64Helpers';
import { DialogData } from '../document-upload/upload-modal/upload-modal.models';

@Component({
  selector: 'app-documentation-upload-local',
  templateUrl: './documentation-upload-local.component.html',
  styleUrls: ['./documentation-upload-local.component.scss'],
})
export class DocumentationUploadLocalComponent implements OnInit {
  @Input() title = 'Upload Documentation (optional)';
  @Input() allowedFileTypes = 'TXT, CSV, PDF, DOC, DOCX, ODT, PPTX, PPT, RTF, XLS, XLSX, PNG, JPEG, JPG, GIF, BMP';
  @Input() multiple = true;
  @Input() alignEnd = false;
  @Input() activeCall = false;
  @Input() readOnly = false;
  @Input() isRequired = false;
  @Input() shouldKeepExtension = true;
  @Input() baseUrl: string;
  @Input() formGroup: FormGroup;

  displayedColumns = ['actions', 'friendlyName', 'fileName'];
  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {
    this.formGroup.addControl('documents', new FormControl(null, this.isRequired ? Validators.required : []));
  }

  onRemoveDocument(document: any) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        title: 'Are you sure?',
        message: 'Clicking Confirm will delete the document.',
        buttons: { cancel: 'Cancel', ok: 'Confirm' },
      },
    });

    dialogRef.afterClosed().subscribe((val) => {
      if (val) this.formGroup.get('documents').setValue(this.formGroup.get('documents').value.filter((x) => x !== document));
    });
  }

  onOpenUpload() {
    this.activeCall = true;
    const dialogRef = this.dialog.open(UploadModalComponent, {
      data: {
        title: 'Upload',
        shouldKeepExtension: this.shouldKeepExtension,
      } as DialogData,
      width: '728px',
    });

    dialogRef.afterClosed().subscribe(async (documents) => {
      this.activeCall = false;
      if (documents) {
        let formFiles = [];
        formFiles = this.formGroup.get('documents').value || [];
        for (let i = 0; i < documents.length; i++) {
          const file = await toBase64(documents[i].file as File, documents[i].title).toPromise();
          formFiles.push(file);
          this.formGroup.get('documents').setValue([...formFiles]);
          this.formGroup.get('documents').updateValueAndValidity();
        }
      }
    });
  }

  loadDocument(document: any) {
    const url = base64ToBlobUrl(document.base64, document.contentType);
    window.open(url, '_blank');
  }
}
