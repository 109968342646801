import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddNewChildGuard, EarlyAccessReferralAddGuard, ViewPendingReferralsGuard } from 'src/app/child-find/child-find.guard';
import { NewChildComponent } from '../../shared/components/new-child/new-child.component';
import { DirectAccessGuard } from '../../shared/guards/direct-access.guard';
import { SearchComponent } from '../search/search.component';
import { EarlyAccessReferralComponent } from './early-access-referral.component';
import { ReadOnlyComponent } from './read-only/read-only.component';
import { ReferralListComponent } from './referral-list/referral-list.component';
import { ReferralSubmitComponent } from './referral-submit/referral-submit.component';
import { ThankYouLetterComponent } from './thank-you-letter/thank-you-letter.component';

const routes: Routes = [
  {
    path: '',
    component: EarlyAccessReferralComponent,
  },
  {
    path: ':referralId/new-child',
    component: NewChildComponent,
  },
  {
    path: 'referral-submitted',
    component: ReferralSubmitComponent,
  },
  {
    path: ':referralId/thank-you-letter',
    component: ThankYouLetterComponent,
  },
  {
    path: ':referralId/child-select',
    component: SearchComponent,
  },
  {
    path: ':referralId/read-only',
    component: ReadOnlyComponent,
  },
  {
    path: 'referral-list',
    component: ReferralListComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EarlyAccessReferralRoutingModule {}
