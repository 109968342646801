import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, of, throwError } from 'rxjs';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { LoginModel } from '../auth-models';
import { AuthService } from '../auth.service';
import { LearnerInvite } from './models/learner-invite';
import { LearnerPortalRegistrationDto } from './models/learner-portal-registration-dto';

@Component({
  selector: 'app-learner-registration',
  templateUrl: './learner-registration.component.html',
  styleUrls: ['./learner-registration.component.scss'],
})
export class LearnerRegistrationComponent implements OnInit {
  invite: LearnerInvite;
  registerError: string;
  constructor(
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private learnerService: LearnerService,
    private notificationService: NotificationService,
    private readonly spinnerService: SpinnerService
  ) {}

  ngOnInit(): void {
    setTimeout(() => this.spinnerService.incrementLoading());

    const inviteId = this.route.snapshot.paramMap.get('inviteId');
    this.learnerService.getLearnerInvite(inviteId).subscribe(
      (invite) => {
        this.spinnerService.decrementLoading();

        if (!invite) {
          this.router.navigate(['/']);
          return;
        }
        this.invite = invite;
        if (invite.verified) {
          this.router.navigate(['/auth/login']);
        }
      },
      (error) => {
        this.handleError('Error', error);
      }
    );
  }

  onSubmit(event) {
    if (this.invite.learnerUserId && this.invite.verified) {
      const loginModel = {
        email: event.email,
        password: event.password,
      } as LoginModel;

      this.loginVerifiedUser(loginModel);
    } else {
      this.registerLearner(event);
    }
  }

  registerLearner(event) {
    setTimeout(() => this.spinnerService.incrementLoading());

    this.registerError = undefined;
    const learnerRegistration = event as LearnerPortalRegistrationDto;
    this.learnerService.confirmInvitation(learnerRegistration).subscribe(
      (response) => {
        this.spinnerService.decrementLoading();

        if (response.succeeded) {
          this.notificationService.success('Confirmation succeeded');
          const loginModel = {
            email: learnerRegistration.email,
            password: learnerRegistration.password,
          } as LoginModel;

          this.loginVerifiedUser(loginModel);
        } else {
          this.registerError = response.errors?.map((x) => x.description).join(',');
          this.notificationService.error(this.registerError);
        }
      },
      (error) => {
        this.handleError('Error', error);
      }
    );
  }

  loginVerifiedUser(loginModel: LoginModel) {
    setTimeout(() => this.spinnerService.incrementLoading());

    this.authService.login(loginModel).subscribe(
      () => {
        if (this.authService.isAuthenticated) {
          this.authService.navigateToPortal();
        } else {
          this.loginFaild();
        }
      },
      (error) => {
        this.handleError('Error', error);
      }
    );
  }

  loginFaild() {
    this.notificationService.error('Login failed, redirecting you to login page...');
    setTimeout(() => {
      this.reddirectToLogin();
    }, 3000);
  }

  reddirectToLogin() {
    this.router.navigate(['/auth/login']);
  }

  protected handleError(errorTitle: string, errorMessage: any, result?: any): Observable<any> {
    this.spinnerService.decrementLoading();

    this.notificationService.errorWithAction(errorTitle, 'Why?', () => {
      this.notificationService.alert(errorMessage, errorTitle);
    });

    if (result) {
      return of(result);
    }

    return throwError(errorMessage);
  }
}
