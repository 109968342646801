import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import dayjs from 'dayjs';
import { AuthService } from 'src/app/auth/auth.service';
import { AppPermissions } from 'src/app/permissions';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { EvaluationDetailService } from '../../../evaluation/services/evaluation-detail.service';
import { CaseSummary, IntakeType } from '../../../shared/models/case';
import { LearnerSummary } from '../../../shared/models/learner';
import { ConsentFormService } from '../../../shared/services/consent-form/consent-form.service';
import { LearnerService } from '../../../shared/services/learner/learner.service';

@Component({
  selector: 'app-learner-management-nav',
  templateUrl: './learner-management-nav.component.html',
  styleUrls: ['./learner-management-nav.component.scss'],
})
export class LearnerManagementNavComponent implements OnInit, OnChanges {
  @Input() learner: LearnerSummary;
  partCCase: CaseSummary;
  evaluationDevAreasComplete: boolean;
  permissions = AppPermissions;

  get isMoveInOrEligibleLegacyLearner() {
    return this.learner?.movingFromOutOfState || this.learner?.eligibleInLegacySystem || this.learner?.eligibleInLegacySystemPartC;
  }

  get isMoveInOrEligibleLegacyBLearner() {
    return this.learner?.movingFromOutOfState || this.learner?.eligibleInLegacySystem;
  }

  get hasMoveInDetails() {
    return this.learner.isMigrated || this.learner.hasMoveInDetails;
  }

  get showIEPStepper() {
    return (
      this.learner?.movingFromOutOfState ||
      this.learner?.eligibleInLegacySystem ||
      (!this.isMoveInOrEligibleLegacyBLearner && this.learner.partBEligibilityDecision) ||
      this.learner.hasIep ||
      this.learner.addingFromOutOfState
    );
  }

  get showIFSPStepper() {
    return this.learner?.partCEligibilityDecision || this.learner?.hasIfsp;
  }

  get showPSSStepper() {
    return (this.learner.isSecondaryTransition && !this.partCCase) || dayjs().diff(this.learner.dateOfBirth, 'year') >= 16;
  }

  get showEvaluation() {
    return (this.learner.showEvaluation || this.learner.addingFromOutOfState) && !this.authService.isPortalUser;
  }

  get showFamilyContact() {
    return !this.authService.isPortalUser;
  }

  constructor(
    public authService: AuthService,
    public routingService: RoutingService,
    private readonly learnerService: LearnerService,
    private readonly evalService: EvaluationDetailService,
    private consentFormService: ConsentFormService
  ) {}

  ngOnInit(): void {
    this.consentFormService.consentsUpdated$.subscribe(() => this.checkEvaluationDevAreasComplete());
  }

  async ngOnChanges(changes: SimpleChanges): Promise<void> {
    if (changes.learner?.previousValue?.id !== changes.learner?.currentValue?.id) {
      if (this.learner.activeCases.some((x) => x.intakeType !== IntakeType.PartB)) {
        await this.loadPartCCase();
        return;
      }

      this.partCCase = null;
      this.evaluationDevAreasComplete = false;
    }
  }

  private async loadPartCCase(): Promise<void> {
    this.partCCase = (await this.learnerService.getCases(this.learner.id, IntakeType.PartC).toPromise())[0];
    this.partCCase ??= (await this.learnerService.getCases(this.learner.id, IntakeType.PartCLate).toPromise())[0];
    await this.checkEvaluationDevAreasComplete();
  }

  async checkEvaluationDevAreasComplete(): Promise<void> {
    if (this.partCCase) {
      this.evaluationDevAreasComplete = await this.evalService.getCompletedCaseDevAreas(this.partCCase.id).toPromise();
    }
  }
}
