import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IntakeType } from '../../shared/models/case';
import { OperationResultWithValue } from '../../shared/models/operation-result';
import { IvrsCase, IvrsCaseAssignmentRequest, IvrsCaseUserDto } from '../models/ivrs-case';

@Injectable({
  providedIn: 'root',
})
export class IvrsCaseService {
  private baseUrl = 'api/ivrs/cases';
  constructor(private http: HttpClient) {}

  getUnassignedCases(
    pageNumber: number,
    pageSize: number,
    orderBy: string,
    orderByDescending: boolean,
    searchCriteriaAo?: string,
    name?: string
  ) {
    let params = new HttpParams();
    params = params.append('pageNumber', pageNumber);
    params = params.append('pageSize', pageSize);
    params = params.append('orderBy', orderBy);
    params = params.append('orderByDescending', orderByDescending);
    params = params.append('searchCriteriaAo', searchCriteriaAo ?? '');
    params = params.append('name', name ?? '');
    return this.http.get<OperationResultWithValue<any>>(`${this.baseUrl}/unassigned`, { params });
  }

  checkUnassignedCases() {
    return this.http.get<OperationResultWithValue<boolean>>(`${this.baseUrl}/check-unassigned`);
  }

  assignVrCounselor(request: IvrsCaseAssignmentRequest) {
    return this.http.put<OperationResultWithValue<any>>(`${this.baseUrl}`, request);
  }

  getPaginatedCaseList(
    intakeType: IntakeType,
    currentPage: number,
    pageSize: number,
    orderByDescending: boolean,
    orderBy: string,
    searchCriteria?: string,
    searchCriteriaDistrict?: string,
    searchCriteriaBuilding?: string
  ) {
    let params = new HttpParams();
    params = params.set('intakeType', intakeType);
    params = params.set('pageNumber', currentPage);
    params = params.set('pageSize', pageSize);
    params = params.set('orderByDescending', orderByDescending);
    params = params.set('orderBy', orderBy);
    params = params.set('searchCriteria', searchCriteria ?? '');
    params = params.set('searchCriteriaDistrict', searchCriteriaDistrict ?? '');
    params = params.set('searchCriteriaBuilding', searchCriteriaBuilding ?? '');
    return this.http.get<any>(`${this.baseUrl}/paginated-list`, { params });
  }

  getIvrsCaseByLearnerId(learnerId: string) {
    return this.http.get<OperationResultWithValue<IvrsCase>>(`${this.baseUrl}/learner/${learnerId}`);
  }

  getIvrsCase(id: string) {
    return this.http.get<OperationResultWithValue<IvrsCase>>(`${this.baseUrl}/${id}`);
  }

  addIvrsCaseUser(ivrsCaseId: string, userId: string) {
    return this.http.post<OperationResultWithValue<boolean>>(`${this.baseUrl}/${ivrsCaseId}/caseusers/add/${userId}`, null);
  }

  removeIvrsCaseUser(ivrsCaseId: string, userId: string) {
    return this.http.put<OperationResultWithValue<IvrsCase>>(`${this.baseUrl}/${ivrsCaseId}/caseusers/remove/${userId}`, null);
  }

  transferCase(ivrsCaseId: string, userId: string) {
    return this.http.post<OperationResultWithValue<IvrsCase>>(`${this.baseUrl}/${ivrsCaseId}/caseusers/transfer/${userId}`, null);
  }

  isUserPartOfCase(learnerId: string, userId: string) {
    return this.http.get<OperationResultWithValue<boolean>>(`${this.baseUrl}/${learnerId}/caseusers/${userId}`);
  }
}
