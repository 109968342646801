import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { DeactivationService } from 'src/app/shared/services/deactivation.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { needsLabelOffset, openViewMore } from 'src/app/shared/tableHelpers';
import { BooleanYesNoPipe } from '../../../../../../shared/pipes/boolean-yesno.pipe';
import { TrialPlacementTarget } from '../../../../../models/iep';
import { IepAmendment } from '../../../../../models/iep-amendment';
import { IepServiceDto } from '../../../../../models/iep-service';
import { IepServiceService } from '../../../../../services/iep-service.service';

@Component({
  selector: 'app-iep-services-table',
  templateUrl: './iep-services-table.component.html',
  styleUrls: ['./iep-services-table.component.scss'],
  providers: [DatePipe, BooleanYesNoPipe],
})
export class IepServicesTableComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() iepId: string;
  @Input() isTransitionPK = false;
  @Input() isProfilePage = false;
  @Input() isEditing = false;
  @Input() isGoalsPage = false;
  @Input() amendmentId: string;
  @Input() amendments: IepAmendment[];
  @Input() iepIncludesTrialPlacement: boolean;
  @Input() trialPlacementTarget: TrialPlacementTarget;
  trialPlacementTargetEnum = TrialPlacementTarget;

  @Output() servicesCount = new EventEmitter<number>();
  @Output() editService = new EventEmitter<IepServiceDto>();

  servicesUpdatedSubscription: Subscription;

  services: Array<IepServiceDto> = [];

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentIsFinalized() {
    return this.amendment?.finalizeDate !== null;
  }

  //#region summary
  servicesDisplayedColumns: string[] = [
    'actions',
    'service',
    'serviceType',
    'frequency',
    'projectedStartDate',
    'projectedEndDate',
    'providers',
  ];

  servicesDataSource: MatTableDataSource<any>;
  //#endregion

  shortDateFormat = shortDateFormat;

  constructor(
    private iepServiceService: IepServiceService,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private yesNoPipe: BooleanYesNoPipe,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    if (!this.isTransitionPK) {
      this.servicesDisplayedColumns = this.servicesDisplayedColumns.filter((x) => x !== 'serviceType');
    }

    this.servicesDataSource = new MatTableDataSource([]);
    this.getServices();
    this.subscriptions.add(this.iepServiceService.servicesUpdated$.subscribe(() => this.getServices()));
    this.servicesDataSource.sortingDataAccessor = (item: IepServiceDto, columnId) => {
      switch (columnId) {
        case 'service':
          return item?.serviceType?.label;
        case 'serviceType':
          return this.getServiceType(item?.serviceType);
        case 'frequency':
          return this.getFrequencyText(item);
        case 'providers':
          return this.getProviderNames(item.providers);
        default:
          return item[columnId];
      }
    };
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  getServiceType(service, instructionType?: string) {
    if (instructionType) {
      return instructionType;
    }
    if (service?.customServiceDate === 'BothPKAndK') {
      return 'Both';
    } else {
      if (service?.customServiceDate === 'KOnly') {
        return 'K';
      }
      if (service?.customServiceDate === 'PKOnly') {
        return 'PK';
      }
    }
  }

  getFormattedProjectedDate(date: Date) {
    return !!date && date.toString() !== 'Invalid Date' ? this.datePipe.transform(date, this.shortDateFormat) : null;
  }

  onServiceView(service) {
    openViewMore(
      this.dialog,
      this.iepServiceService.getIepDevelopmentServiceViewMoreData(service, this.iepIncludesTrialPlacement, this.trialPlacementTarget)
    );
  }

  goToServices() {
    const caseId = this.route.parent?.snapshot.paramMap.get('caseId');
    this.router.navigate(['/', 'cases', caseId, 'iep', this.iepId, 'services']);
  }

  // #region Services
  getServices() {
    this.iepServiceService.getAllIepServices(this.iepId).subscribe((services) => {
      this.services = services;
      this.services.forEach((x) => {
        x.projectedStartDate = !!x.projectedStartDate ? dayjs(x.projectedStartDate).utcOffset(0).endOf('day').toDate() : null;
        x.projectedEndDate = !!x.projectedStartDate ? dayjs(x.projectedEndDate).utcOffset(0).endOf('day').toDate() : null;
      });
      this.services.forEach((service) => {
        if (service.paraprofessionalSupports) {
          service.paraprofessionalSupports = service.paraprofessionalSupports.split(',');
          service.paraprofessionalSupports = service.paraprofessionalSupports.filter((x) => x !== null && x !== '');
        } else {
          service.paraprofessionalSupports = [];
        }

        if (service.transportationServices) {
          service.transportationServices = service.transportationServices.split(',');
          service.transportationServices = service.transportationServices.filter((x) => x !== null && x !== '');
        } else {
          service.transportationServices = [];
        }
      });
      this.servicesDataSource.data = this.services;
      this.servicesCount.emit(this.services.length);
    });
  }

  onServiceEdit(service) {
    this.editService.emit(service);
  }

  onServiceRemove(service) {
    this.notificationService.confirmation('Are you sure you want to delete this service?', () => {
      this.iepServiceService.deleteService(this.iepId, service.id).subscribe(() => {
        this.notificationService.success('Service deleted');
      });
    });
  }
  // #endregion

  getProviderNames(providers, otherProvider?: string) {
    const providersMapped = providers?.map((x) => `${x.firstName} ${x.lastName || ''}`).join(', ');

    if (otherProvider && providersMapped) {
      return providersMapped + `, ${otherProvider}`;
    } else if (otherProvider) {
      return otherProvider;
    }

    return providersMapped;
  }

  getFrequencyText(element) {
    if (!element || !element?.frequencyNumber) {
      return '';
    }
    return `${element.frequencyNumber} ${element.frequencyIncrement === 'minutes' ? 'minute(s)' : 'hour(s)'} ${element.frequencyPeriod}`;
  }

  convertToShortDate(date: Date) {
    return this.datePipe.transform(date, shortDateFormat);
  }

  checkLabelOffset(row): boolean {
    return needsLabelOffset(row);
  }

  lastFinalizedDate(amendmentId: string) {
    if (this.amendments && this.amendments.length > 0) {
      const amendment = this.amendments.find((a) => a.id === amendmentId);
      return amendment?.finalizeDate;
    }
    return null;
  }
}
