<ng-container *ngIf="!readOnly || showReadOnlyControl; else readOnlyTemplate">
  <div class="prefill" *ngIf="useOverwrite; else basic">
    <div class="prefill__input">
      <ng-container *ngTemplateOutlet="basic"></ng-container>
    </div>

    <div class="prefill__text" *ngIf="dataSourceLabel">QR: {{ dataSourceLabel }}</div>

    <div class="prefill__container prefill__container--offset" (click)="replaceWithSourceValue()" *ngIf="dataSourceLabel && !undoValue">
      <div class="prefill__icon">
        <mat-icon aria-label="QR Arrow Symbol">call_merge</mat-icon>
      </div>
    </div>

    <div class="prefill__container prefill__container--undo prefill__container--offset" *ngIf="undoValue">
      <p class="prefill__icon mb-0" (click)="undoReplaceWithSourceValue()">Undo</p>
    </div>
  </div>
</ng-container>

<ng-template #basic>
  <mat-form-field class="w-100 position-relative">
    <mat-label
      [ngClass]="{
        asterisk_input: (control?.hasError('required') || showAsterisk) && hideAsterisk === false
      }"
      attr.aria-label="{{ control?.hasError('required') && 'Control is Required' }}"
    >
      <ng-container *ngIf="!!labelTemplate; else defaultLabel" [ngTemplateOutlet]="labelTemplate"></ng-container>
      <ng-template #defaultLabel>{{ label }}</ng-template>
    </mat-label>
    <input
      type="text"
      matInput
      role="combobox"
      aria-labelledby="Control"
      [formControl]="control"
      (keyup)="onKey($event)"
      (blur)="onBlur($event)"
      [matAutocomplete]="auto"
      [readonly]="readOnly"
      (click)="resetList()"
      attr.aria-required="{{ control?.hasError('required') ? 'true' : 'false' }}"
      [attr.aria-label]="label"
      [attr.tabindex]="tabindex"
    />
    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="displayWith"
      (optionSelected)="onOptionSelected($event)"
      [panelWidth]="panelWidth"
    >
      <ng-container *ngIf="!useGrouping">
        <mat-option
          *ngFor="let option of selectOptions"
          [value]="option.key"
          [hidden]="option.enabled !== undefined && !option.enabled"
          attr.aria-label="{{ option.value }}"
          role="option"
          tabindex="0"
        >
          {{ option.value }}
        </mat-option>
      </ng-container>
      <ng-container *ngIf="useGrouping">
        <mat-optgroup *ngFor="let group of groupOptions" [label]="group.label">
          <mat-option
            *ngFor="let option of group.options"
            [value]="option.key"
            attr.aria-label="{{ option.value }}"
            role="combobox"
            tabindex="0"
          >
            {{ option.value }}
          </mat-option>
        </mat-optgroup>
      </ng-container>
    </mat-autocomplete>

    <mat-error>
      <app-field-error-display [control]="control"></app-field-error-display>
    </mat-error>

    <div class="form__carrot"></div>
  </mat-form-field>
</ng-template>

<ng-template #readOnlyTemplate>
  <p class="read-only-container">
    <strong>{{ label }}</strong
    >{{ control.value }}
  </p>
</ng-template>
