<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle [ngClass]="{ 'mb-0': !subQuestion }">
  {{ question }}
</h3>

<div mat-dialog-content class="py-3" *ngIf="subQuestion">
  <mat-card class="my-0" *ngIf="subQuestion">
    <p *ngIf="!hasHtml" class="my-0" style="white-space: pre-line">{{ subQuestion }}</p>
    <p *ngIf="hasHtml" class="my-0" style="white-space: pre-line" [innerHTML]="subQuestion"></p>
  </mat-card>
</div>

<div mat-dialog-actions align="end">
  <button
    *ngIf="showCancel"
    mat-raised-button
    aria-label="Cancel Button"
    class="background-color--muted mr-1"
    (click)="cancel()"
    tabindex="0"
  >
    Cancel
  </button>
  <button *ngIf="showNo" mat-raised-button aria-label="No" class="background-color--muted mr-1" (click)="close()" tabindex="0">No</button>
  <button mat-raised-button aria-label="Yes" color="primary" (click)="yesImSure()" tabindex="0">Yes</button>
</div>
