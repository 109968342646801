<mat-card>
  <mat-card-title><h2 class="legends__title">Legend</h2></mat-card-title>
  <div class="legends display-flex flex-column">
    <div class="display-flex align-items-center">
      <mat-icon class="legends__dhh-icon teal" #tooltip="matTooltip" matTooltip="DHH"
        >radio_button_checked <span class="cdk-visually-hidden">DHH</span>
      </mat-icon>
      <mat-label>DHH</mat-label>
    </div>
    <div class="display-flex align-items-center">
      <mat-icon class="legends__dhh-icon" color="warn" #tooltip="matTooltip" matTooltip="Do Not Test">
        error <span class="cdk-visually-hidden">Do Not Test</span>
      </mat-icon>
      <mat-label>Do Not Test</mat-label>
    </div>
    <div class="display-flex align-items-center">
      <mat-icon color="primary" class="legends__dhh-icon" #tooltip="matTooltip" matTooltip="Do Not Screen">
        indeterminate_check_box <span class="cdk-visually-hidden">Do Not Screen</span>
      </mat-icon>
      <mat-label>Do Not Screen</mat-label>
    </div>
  </div>
</mat-card>
