<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  {{ title }}
</h3>
<mat-dialog-content>
  <div class="row mt-4 mb-4" [formGroup]="formGroup">
    <div class="col-md-12">
      <mat-form-field class="w-100">
        <mat-label
          [ngClass]="{
            asterisk_input: formGroup.get('status').hasError('required')
          }"
          >Status Type:</mat-label
        >
        <span class="mat-cell">
          <mat-select formControlName="status" placeholder="Consent Type">
            <mat-option
              *ngIf="
                data.currentStatus !== statuses.Approved &&
                data.currentStatus !== statuses.Override &&
                isSuperAdmin &&
                data.consentFormType === consentFormType.Reevaluation
              "
              [value]="statuses.Override"
              role="option"
            >
              {{ statuses.Override }}
            </mat-option>
            <mat-option
              *ngIf="
                isSuperAdmin ||
                (data.currentStatus !== statuses.Approved &&
                  data.currentStatus !== statuses.Denied &&
                  data.currentStatus !== statuses.Override &&
                  data.currentStatus !== statuses.Revoked)
              "
              [value]="statuses.Approved"
              role="option"
            >
              {{ statuses.Approved }}
            </mat-option>
            <mat-option
              *ngIf="
                isSuperAdmin ||
                (data.currentStatus !== statuses.Denied &&
                  data.currentStatus !== statuses.Revoked &&
                  data.currentStatus !== statuses.Approved &&
                  data.currentStatus !== statuses.Override)
              "
              [value]="statuses.Requested"
              role="option"
            >
              {{ statuses.Requested }}
            </mat-option>
            <mat-option
              *ngIf="
                isSuperAdmin ||
                (data.currentStatus !== statuses.Denied &&
                  data.currentStatus !== statuses.Revoked &&
                  data.currentStatus !== statuses.Approved &&
                  data.currentStatus !== statuses.Override)
              "
              [value]="statuses.Declined"
              role="option"
            >
              {{ statuses.Declined }}
            </mat-option>
            <mat-option
              *ngIf="data.currentStatus === statuses.Approved || data.currentStatus === statuses.Override"
              [value]="statuses.Revoked"
              role="option"
            >
              {{ statuses.Revoked }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="formGroup.controls.status.hasError('required') && formGroup.controls.status.touched">
            Type is <strong>required</strong>
          </mat-error>
        </span>
      </mat-form-field>
    </div>
    <div class="col-md-12">
      <div class="example-full-width w-100">
        <app-textarea formControlName="comments" label="Notes" [rows]="4"></app-textarea>
      </div>
    </div>
    <div class="col-md-12">
      <app-date-picker
        controlName="dateReceived"
        [max]="today"
        label="{{ isNotDeniedOrRequested ? 'Date Received' : 'Date' }}"
        [min]="data.parentsProvidedFiieConsentDate || data.dateReceived"
      ></app-date-picker>
    </div>
    <ng-container *ngIf="isNotDeniedOrRequested">
      <div class="col-md-12" *ngIf="isNotRevokedOrDenied">
        <app-text-field formControlName="signedBy" label="Signed By"> </app-text-field>
      </div>
      <div class="col-md-12" *ngIf="isNotRevokedOrDenied">
        <app-date-picker
          controlName="dateSigned"
          [max]="today"
          [min]="data.parentsProvidedFiieConsentDate || data.dateReceived"
          label="Date Signed"
        ></app-date-picker>
      </div>
    </ng-container>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button type="button" mat-raised-button aria-label="Cancel Button" [mat-dialog-close]="true" tabindex="0">Cancel</button>
  <button
    color="primary"
    type="button"
    mat-raised-button
    aria-label="Ok"
    (click)="onSubmit()"
    [isBusy]="activeCall"
    [disableAfterBusy]="isInvalid"
    [disabled]="isInvalid"
    tabindex="0"
  >
    Ok
  </button>
</mat-dialog-actions>
