<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Transfer Case</h3>

<div mat-dialog-content>
  <h3>Learner Information</h3>
  <app-learner-info-header [learnerId]="ivrsCase?.learnerId"></app-learner-info-header>

  <h3 class="card-spacing-top">Transfer Case</h3>
  <app-aea-transfer
    *ngIf="districts && aeas"
    [aeas]="aeas"
    [districts]="districts"
    [aeaUsers]="aeaUsers"
    [buildings]="buildings"
    [multiSelect]="false"
    (search)="onSearch($event)"
    (selected)="onAeaSelectionChanged($event)"
  ></app-aea-transfer>

  <div class="divider mb-4"></div>
</div>

<div mat-dialog-action class="display-flex justify-content-end align-items-center mt-4">
  <div class="display-flex direction-column">
    <div>
      <button mat-raised-button aria-label="Cancel Button" class="background-color--muted mr-2" (click)="onClose()" cdkFocusInitial>
        Cancel
      </button>
      <button
        mat-raised-button
        aria-label="Transfer"
        color="primary"
        [disableAfterBusy]="true"
        [disabled]="!selectedAeaUser"
        [isBusy]="activeCall"
        (click)="onTransfer()"
        tabindex="0"
      >
        Transfer
      </button>
    </div>
  </div>
</div>
