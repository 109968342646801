<form [formGroup]="formGroup">
  <div class="row">
    <div *ngIf="aeaOptions" class="col">
      <app-autocomplete
        [label]="aeaLabel"
        [options]="aeaOptions"
        [formControlName]="aeaFormControlName"
        [showAsterisk]="showAeaAsterisk"
      ></app-autocomplete>
    </div>
    <div *ngIf="districtOptions" class="col">
      <app-autocomplete
        [label]="districtLabel"
        [options]="districtOptions"
        [formControlName]="districtFormControlName"
        [showAsterisk]="showDistrictAsterisk"
      ></app-autocomplete>
    </div>
    <div *ngIf="buildingOptions" class="col">
      <app-autocomplete
        [label]="buildingLabel"
        [options]="buildingOptions"
        [formControlName]="buildingFormControlName"
        [showAsterisk]="showBuildingAsterisk"
      ></app-autocomplete>
    </div>
  </div>
</form>
