<header>
  <app-page-header [pageTitle]="'Service Coordinator Assignment'" [backgroundColor]="'blue'" [helpLinks]="false"> </app-page-header>
</header>
<div class="">
  <div class="assignment">
    <mat-card class="pt-2">
      <div class="row">
        <div class="col-md-12">
          <app-unassigned-cases (selectedCases)="setCases($event)" (filter)="setDistrictFilter($event)"> </app-unassigned-cases>
        </div>
      </div>
    </mat-card>

    <mat-card class="mt-3">
      <div class="row">
        <div class="col-md-12">
          <app-service-coordinators (selectedServiceCoordinator)="setServiceCoordinator($event)" [districtFilter]="districtFilter">
          </app-service-coordinators>
        </div>
      </div>
    </mat-card>
  </div>

  <div class="footer">
    <span
      class="chsc-button mr-2"
      *ngIf="isAeaUser"
      matTooltip="No CHSC user configured, please contact your administrator."
      [matTooltipDisabled]="!!chscUser"
    >
    </span>
    <span
      class="dmps-button mr-2"
      *ngIf="isAeaUser"
      matTooltip="No DMPS user configured, please contact your administrator."
      [matTooltipDisabled]="!!dmpsUser"
    >
      <button
        mat-raised-button
        aria-label="Assign to DMPS"
        color="primary"
        id="dmps"
        (click)="assignCasesToDMPS()"
        [disabled]="dmpsDisabled"
        [disableAfterBusy]="dmpsDisabled"
        [isBusy]="activeCall"
        tabindex="0"
      >
        Assign to DMPS
      </button>
    </span>
    <button
      mat-raised-button
      aria-label="Assign Cases"
      color="primary"
      id="sc"
      (click)="assignCasesToSC()"
      [disabled]="assignCaseDisabled"
      [disableAfterBusy]="assignCaseDisabled"
      [isBusy]="activeCall"
      tabindex="0"
    >
      Assign Cases
    </button>
  </div>
</div>
