import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ResetDate } from 'src/app/shared/components/ifsp-iep-reset-date/ifsp-iep-reset-date.component';
import { IncompleteDataReportItem } from 'src/app/shared/components/incomplete-data-report/incomplete-data-report-item';
import { FamilyMeetingRead } from 'src/app/shared/models/case';
import { GeneralSurvey } from 'src/app/shared/models/general-survey/general-survey';
import { ServiceActivityType, ServiceActivityTypeCategory } from 'src/app/shared/models/service-activity-type';
import { ServiceActivityTypeUser } from 'src/app/shared/models/service-activity-type-user';
import { ServiceActivityUserUpdate } from 'src/app/shared/models/service-activity-user-update';
import { User, UserLimitedDto } from 'src/app/shared/models/user';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { MeetingParticipantRead } from '../../learner-management/family-meeting/participants-list/meeting-participants';
import { OperationResultWithValue } from '../../shared/models/operation-result';
import { NotificationService } from '../../shared/services/notification.service';
import { IepAddUpdate, IepGetByCaseId, IepLearnerInfo, IepView } from '../models/iep';
import { IepAdditionalInfo } from '../models/iep-additional-info';
import { IepAssessment } from '../models/iep-assessment';
import { IepAssessmentAccommodationLookup } from '../models/iep-assessment-accomodation-lookup';
import { IepAssessmentDto } from '../models/iep-assessment-dto';
import { IepExtendedSchoolYear } from '../models/iep-extended-school-year';
import { IepExtendedSchoolYearDto } from '../models/iep-extended-school-year-dto';
import { IepExtendedSchoolYearUpdate } from '../models/iep-extended-school-year-update';
import { IepLREDto } from '../models/iep-lre-dto';
import { IepLREUpdate } from '../models/iep-lre-update';
import { IepPhysicalEducation } from '../models/iep-physical-education';
import { IepPhysicalEducationDto } from '../models/iep-physical-education-dto';
import { IepPhysicalEducationUpdate } from '../models/iep-physical-education-update';
import { IepUserSurveyCreator } from '../models/iep-user-survey-creator';
import { Plaafp } from '../models/plaafp';

@Injectable({
  providedIn: 'root',
})
export class IepService {
  private iepUpdated = new Subject<void>();
  iepUpdated$ = this.iepUpdated.asObservable();

  private plaafpUpdated = new Subject<void>();
  plaafpUpdated$ = this.plaafpUpdated.asObservable();

  private physicalEducationsUpdated = new Subject<void>();
  physicalEducationsUpdated$ = this.physicalEducationsUpdated.asObservable();

  private extendedSchoolYearUpdated = new Subject<void>();
  extendedSchoolYearUpdated$ = this.extendedSchoolYearUpdated.asObservable();

  private assessmentUpdated = new Subject<void>();
  assessmentUpdated$ = this.assessmentUpdated.asObservable();

  private lreUpdated = new Subject<void>();
  lreUpdated$ = this.lreUpdated.asObservable();

  constructor(
    private readonly http: HttpClient,
    private spinnerService: SpinnerService,
    private readonly notificationService: NotificationService
  ) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/iep\/.*lre.*/));
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/iep\/.*extended-school-year.*/));
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/iep\/.*physical-educations.*/));
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/iep\/.*assessment.*/));
  }

  private baseUrl(id = '') {
    return `api/iep/${id}`;
  }

  private plaafpUrl(iepId = '') {
    return `${this.baseUrl(iepId)}/plaafp`;
  }

  get(iepId: string) {
    return this.http.get<IepView>(`${this.baseUrl(iepId)}`);
  }

  getFinalized(iepId: string) {
    return this.http.get<boolean>(`${this.baseUrl(iepId)}/isfinalized`);
  }

  canCancelTrialPlacement(iepId: string) {
    return this.http.get<boolean>(`${this.baseUrl(iepId)}/canCancelTrialPlacement`);
  }

  getTeamMembers(caseId: string) {
    return this.http.get<MeetingParticipantRead[]>(`${this.baseUrl()}getTeamMembers/${caseId}`);
  }

  getTeamFromMeetings(caseId: string) {
    return this.http.get<UserLimitedDto[]>(`${this.baseUrl()}getTeamFromMeetings/${caseId}`);
  }

  getPersonContactedUsers(caseId: string) {
    return this.http.get<UserLimitedDto[]>(`${this.baseUrl()}getPersonContactedUsers/${caseId}`);
  }

  getPastThreeYearGoalAreas(caseId: string) {
    return this.http.get<any>(`${this.baseUrl('')}pastGoalAreas/${caseId}`);
  }

  getByCaseId(caseId: string) {
    return this.http.get<IepGetByCaseId[]>(`${this.baseUrl('')}getByCaseId/${caseId}`);
  }

  getByLearnerId(learnerId: string) {
    return this.http.get<IepGetByCaseId[]>(`api/learners/${learnerId}/ieps`);
  }

  createIEP(model: IepAddUpdate) {
    return this.http.post<IepView>(this.baseUrl(''), model);
  }

  setServiceActivityType(model: ServiceActivityUserUpdate) {
    return this.http.post<User>(this.baseUrl() + 'set-service-activity-user', model);
  }

  deleteServiceUser(userServiceId: string) {
    return this.http.put<OperationResultWithValue<User>>(this.baseUrl() + `service-activity-user/${userServiceId}`, null);
  }

  updateIEP(model: IepAddUpdate) {
    return this.http.put<OperationResultWithValue<IepView>>(this.baseUrl(model.id), model);
  }

  getPartBSASTypes(...types: ServiceActivityTypeCategory[]) {
    return this.http.get<ServiceActivityType[]>(this.baseUrl() + 'getPartBSASTypes?' + types.map((x) => 'types=' + x).join('&'));
  }

  getServiceActivityTypes() {
    return this.http.get<ServiceActivityType[]>(this.baseUrl() + 'get-service-activity-types');
  }

  getServiceAssignments(userId: string) {
    return this.http.get<ServiceActivityTypeUser[]>(this.baseUrl() + `get-service-activity-user/${userId}`);
  }

  getPlaafp(iepId: string, amendmentId = '') {
    return this.http.get<Plaafp>(this.plaafpUrl(iepId) + '/' + amendmentId);
  }

  getPhysicalEducations() {
    return this.http.get<IepPhysicalEducation[]>(`${this.baseUrl()}physical-educations`);
  }

  updatePhysicalEducation(iepId: string, model: IepPhysicalEducationUpdate) {
    return this.http.put<void>(`${this.baseUrl(iepId)}/physical-educations`, model).pipe(tap(() => this.physicalEducationsUpdated.next()));
  }

  getPhysicalEducationFromIep(iepId: string, amendmentId = '') {
    return this.http.get<IepPhysicalEducationDto>(`${this.baseUrl(iepId)}/physical-educations/` + amendmentId);
  }

  getExtendedSchoolYearOptions() {
    return this.http.get<IepExtendedSchoolYear[]>(`${this.baseUrl()}extended-school-year`);
  }

  updateExtendedSchoolYear(iepId: string, model: IepExtendedSchoolYearUpdate) {
    return this.http.put<void>(`${this.baseUrl(iepId)}/extended-school-year`, model).pipe(tap(() => this.extendedSchoolYearUpdated.next()));
  }

  getExtendedSchoolYearFromIep(iepId: string, amendmentId = '') {
    return this.http.get<IepExtendedSchoolYearDto>(`${this.baseUrl(iepId)}/extended-school-year/` + amendmentId);
  }

  getIncompleteDataReport(iepId: string, amendmentId = '') {
    return this.http.get<IncompleteDataReportItem[]>(`${this.baseUrl(iepId)}/incomplete-data-report/${amendmentId}`);
  }

  updateLRE(iepId: string, model: IepLREUpdate) {
    return this.http.put<void>(`${this.baseUrl(iepId)}/lre`, model).pipe(tap(() => this.lreUpdated.next()));
  }

  updateLREPercentages(iepId: string, model: any) {
    return this.http.put<void>(`${this.baseUrl(iepId)}/updateLREPercentages`, model);
  }

  getLREFromIep(iepId: string, type = '', amendmentId = '') {
    let url = `${this.baseUrl(iepId)}/lre/`;
    url = url.concat(type ? type : 'Both', '/');
    if (amendmentId) {
      url = url.concat(amendmentId);
    }
    return this.http.get<IepLREDto>(url);
  }

  getAccommodationsForAssessment(iepId: string) {
    return this.http.get<IepAssessmentAccommodationLookup[]>(`${this.baseUrl(iepId)}/assessment/getAccommodations`);
  }

  getAssessmentFromIep(iepId: string, amendmentId = '') {
    return this.http.get<IepAssessmentDto>(`${this.baseUrl(iepId)}/assessment/` + amendmentId);
  }

  updateAssessment(iepId: string, model: IepAssessment) {
    return this.http.put<void>(`${this.baseUrl(iepId)}/assessment`, model).pipe(tap(() => this.assessmentUpdated.next()));
  }

  updatePlaafp(iepId: string, model: any) {
    return this.http.put<void>(this.plaafpUrl(iepId), model).pipe(tap(() => this.plaafpUpdated.next()));
  }

  getLearnerInfo(iepId: string) {
    return this.http.get<IepLearnerInfo>(`${this.baseUrl(iepId)}/learner-info`);
  }

  getIepMeetings(caseId: string) {
    return this.http.get<FamilyMeetingRead[]>(`${this.baseUrl(caseId)}/iep-meetings`);
  }

  getOldestIncompleteIepMeeting(caseId: string, isAmendment: boolean) {
    return this.http.get<FamilyMeetingRead[]>(`${this.baseUrl(caseId)}/iep-meetings/oldest-incomplete/${isAmendment}`);
  }

  getIepDurationDate(caseId: string) {
    return this.http.get<Date>(`${this.baseUrl(caseId)}/iep-duration-date`);
  }

  finalize(iepId: string, amendmentId: string, lockInLegacySystem = false) {
    const params = new HttpParams().set('lockInLegacySystem', lockInLegacySystem);
    amendmentId = amendmentId ?? '';
    return this.http.post<void>(`${this.baseUrl(iepId)}/finalization/${amendmentId}`, {}).pipe(tap(() => this.iepUpdated.next()));
  }

  getIepAdditionalInfos(iepId: string) {
    return this.http.get<any>(`${this.baseUrl(iepId)}/additional-info`);
  }

  addOrUpdateIepAdditionalInfo(iepId: string, dto: IepAdditionalInfo) {
    if (dto.id) {
      return this.http.put(`${this.baseUrl(iepId)}/additional-info/${dto.id}`, dto);
    } else {
      return this.http.post(`${this.baseUrl(iepId)}/additional-info`, dto);
    }
  }

  deleteIepAdditionalInfo(iepId: string, iepAdditionalInfoId: string) {
    return this.http.put(`${this.baseUrl(iepId)}/additional-info/delete/${iepAdditionalInfoId}`, null);
  }

  deleteIep(iepId: string) {
    return this.http.put(`${this.baseUrl(iepId)}/delete`, null);
  }

  changeIepPkToK(iepId: string) {
    return this.http.put(`${this.baseUrl(iepId)}/change-pk-to-k`, null);
  }

  createBip(iepId: string, importFromFba: boolean) {
    return this.http.post<any>(`${this.baseUrl(iepId)}/bip/${importFromFba}`, null);
  }

  autoFillEcoMatrixTags(iepId: string) {
    return this.http.get<any>(this.baseUrl(iepId) + '/autoFillEcoMatrixTags');
  }

  uploadIndividualHealthPlanDocument(formData: FormData, iepId: string) {
    this.spinnerService.incrementLoading();
    const turnOffSpinner = () => this.spinnerService.decrementLoading();
    return this.http
      .post<FileList>(`${this.baseUrl(iepId)}/documents`, formData, this.getMultipartRequestHeaders())
      .pipe(tap(turnOffSpinner, turnOffSpinner));
  }

  createSurvey(survey: IepUserSurveyCreator) {
    return this.http.post<void>(`${this.baseUrl(survey.iepId)}/survey`, survey);
  }

  getSurveyCreator(iepId: string) {
    return this.http.get<IepUserSurveyCreator>(`${this.baseUrl(iepId)}/survey`);
  }

  getSurvey(iepId: string) {
    return this.http.get<GeneralSurvey>(`${this.baseUrl(iepId)}/survey/user-survey`);
  }

  getSurveyResults(iepId: string) {
    return this.http.get<any>(`${this.baseUrl(iepId)}/survey/survey-results`);
  }

  resetDates(iepId: string, resetDate: ResetDate) {
    return this.http.put(`${this.baseUrl(iepId)}/reset-dates`, resetDate);
  }

  getDocuments(iepId: string) {
    return this.http.get<any>(`${this.baseUrl(iepId)}/documents`);
  }

  rebuildSecondaryTransition(iepId: string, oldPlaafpId: string, newPlaafpId: string, amendmentId: string) {
    return this.http.put<any>(`${this.baseUrl(iepId)}/rebuild-secondary-transition/${newPlaafpId}/${oldPlaafpId}/${amendmentId}`, null);
  }

  protected getMultipartRequestHeaders(): {
    headers: HttpHeaders | { [header: string]: string | string[] };
  } {
    const headers = new HttpHeaders({
      'Content-Disposition': 'multipart/form-data',
      Accept: 'application/json, text/plain, */*',
    });

    return { headers };
  }

  handleError(errorTitle: string, error: any): void {
    let errorMessage = '';
    if (error && error.error && error.error.errors) {
      errorMessage = error.error.errors?.map((e) => e.description)?.join(',');
    } else if (error && error.errors) {
      errorMessage = error.errors?.map((e) => e.description)?.join(',');
    } else if (error && error.error) {
      errorMessage = error.error;
    } else if (error && error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = JSON.stringify(error);
    }

    this.notificationService.errorWithAction(errorTitle, 'Why?', () => {
      this.notificationService.alert(errorMessage, errorTitle);
    });
  }
}
