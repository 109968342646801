import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export interface DialogData {
  question: string;
  subQuestion: string;
  showCancel: boolean;
  hasHtml: boolean;
  showNo: boolean;
}

@Component({
  selector: 'app-are-you-sure',
  templateUrl: './are-you-sure.component.html',
  styleUrls: ['./are-you-sure.component.scss'],
})
export class AreYouSureComponent implements OnInit {
  question: string;
  subQuestion: string | SafeHtml;
  showCancel = false;
  showNo = true;
  hasHtml = false;

  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: DialogData, private sanitizer: DomSanitizer) {
    this.question = data?.question ? data.question : 'Are you sure?';
    this.showCancel = data?.showCancel ? data.showCancel : false;
    this.hasHtml = data?.hasHtml ? data.hasHtml : false;
    console.log(data?.showNo);
    if (data?.showNo === false) {
      this.showNo = false;
    }
    this.subQuestion =
      data?.subQuestion && this.hasHtml ? sanitizer.bypassSecurityTrustHtml(data.subQuestion) : data?.subQuestion ? data.subQuestion : null;
  }

  ngOnInit(): void {}

  yesImSure() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }

  cancel() {
    this.dialogRef.close(undefined);
  }
}
