import { Help, HelpSection } from '../help';
import { FbaAssessmentDetailsHelp, FbaSummaryHelp } from '../models/fba.help';
// eslint-disable max-len
export function getFbaDictionary(): Help[] {
  return [].concat(getFbaAssessmentDetailsDictionary(), getFbaSummaryDictionary());
}

export function getFbaAssessmentDetailsDictionary(): Help[] {
  return [
    {
      title: HelpSection.FbaAssessmentDetails,
      dictionary: [
        {
          label: FbaAssessmentDetailsHelp.CategoryBehavior,
          descriptor: `<ul>
            <li>Elopement/Wandering (leaving or entering an area within or outside the school grounds without permission)</li>
            <li>Inappropriate Language (profanity, threatening others, sexual comments)</li>
            <li>Physical Aggression (engaging in physical harm towards others: hitting, punching, slapping, kicking, biting, pushing, scratching, throwing items at, spitting at)</li>
            <li>Property Destruction (damaging school materials, furniture, or other school property)</li>
            <li>Physical Disruption (throwing items, dumping, tapping, swiping materials)</li>
            <li>Refusal/Noncompliance (refusal to complete school tasks, engage in school activities, or follow classroom rules/directions; bargaining and negotiating with adults regarding academic, social or behavioral expectations)</li>
            <li>Self-Injurious Behavior (engaging in physical self-harm; biting, slapping, pinching, scratching, poking, cutting, stabbing self; picking skin, pulling hair, banging head or other parts of body on surfaces that may cause damage)</li>
            <li>Vocal Disruption (screaming, growling, repetitive words/phrases)</li>
          </ul>`,
        },
        {
          label: FbaAssessmentDetailsHelp.DimensionBehavior,
          descriptor: `<ul>
            <li><strong>Frequency/Rate</strong> - number of incidents of the behavior or number of times the behavior occurs per a period of time</li>
            <li><strong>Latency</strong> - average of how long to respond</li>
            <li><strong>Intensity</strong> - or force of a behavior (e.g., What is the result of the behavior for the student/others/environment?)</li>
            <li><strong>Duration</strong> - average of how long behavior occurs</li>
            </ul>`,
        },
        {
          label: FbaAssessmentDetailsHelp.OperationalDef,
          descriptor:
            'Teams must describe all target behaviors of concern using clear, objective and measurable terms. A well written operational definition is one in which provided the definition, a person who is unfamiliar with the student could accurately identify and collect data on the behaviors of concern. Operational definitions often include examples and nonexamples.',
        },
      ],
    },
  ];
}

export function getFbaSummaryDictionary(): Help[] {
  return [
    {
      title: HelpSection.FbaSummary,
      dictionary: [
        {
          label: FbaSummaryHelp.HypothesisStatement,
          descriptor:
            'A hypothesis statement synthesizes the information gathered during a functional behavior assessment and provides a description of the context (antecedents and consequences) and function (purpose) of each target behavior of concern.',
        },
      ],
    },
  ];
}
