import { Component, Input, OnInit } from '@angular/core';
import { HelpSection } from '../../services/help/help';
import { HelpModalConfig, HelpService } from '../../services/help/help.service';
import { I3Help } from '../../services/help/models/i3.help';
import { DatabaseLinksService } from '../../services/database-links/database-links.service';

@Component({
  selector: 'app-i3-help',
  templateUrl: './i3-help.component.html',
  styleUrls: ['./i3-help.component.scss'],
})
export class I3HelpComponent implements OnInit {
  @Input() term: I3Help;

  constructor(private helpService: HelpService) {}

  ngOnInit(): void {}

  onOpenHelp() {
    const dictionary = this.helpService.getUpdatedI3IepDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      item: this.term,
      section: HelpSection.I3,
      canBrowse: false,
    } as HelpModalConfig);
  }
}
