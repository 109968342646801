<div class="card__together-container">
  <mat-card class="card__together card__together--top card--plain">
    <form [formGroup]="formGroupFilter">
      <div class="row d-flex flex-wrap filter">
        <!-- Hiding for now, will use in the future -->
        <!-- <div class="col-2">
        <app-autocomplete
          label="IFSP"
          [options]="ifspOptions"
          formControlName="ifsp"
        ></app-autocomplete>
      </div> -->
        <div class="col-2">
          <app-autocomplete label="Service" [options]="serviceOptions" formControlName="service"></app-autocomplete>
        </div>
        <div class="col-2">
          <app-autocomplete label="Status" [options]="statusOptions" formControlName="status"></app-autocomplete>
        </div>
        <div class="col-2">
          <app-autocomplete label="Provider" [options]="providerOptions" formControlName="provider"></app-autocomplete>
        </div>
        <div class="col-2">
          <app-autocomplete label="Transition" [options]="transitionOptions" formControlName="transition"></app-autocomplete>
        </div>
        <div class="col-2">
          <app-text-field label="Search Outcomes" formControlName="searchString"></app-text-field>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-9">
          <app-quick-date label="Label" formControlName="quickDate"></app-quick-date>
        </div>
      </div>
    </form>

    <ng-container *ngIf="filteredPlanStatuses">
      <app-plan-status [planStatuses]="filteredPlanStatuses" [outcomes]="outcomes"></app-plan-status>
    </ng-container>

    <mat-accordion class="accordion accordion--short" multi>
      <mat-expansion-panel class="mat-elevation-z0" *ngFor="let outcome of filteredOutcomes">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="display-flex flex-1 flex-wrap justify-content-between align-items-center">
              <h3 class="my-0 text-normal">{{ outcome.ended ? outcome.name + ' (Ended)' : outcome.name }}</h3>
              <span style="font-size: medium" *ngIf="outcome.allCriteriaComplete">
                Based on progress monitoring information, this outcome has been met.
              </span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <ng-template matExpansionPanelContent>
          <app-plan-status [planStatuses]="outcome.planStatuses"></app-plan-status>

          <mat-expansion-panel class="mat-elevation-z0" *ngFor="let criterion of outcome.criteria">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <h3 class="my-0 text-normal">{{ criterion.name }}</h3>
              </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-template matExpansionPanelContent>
              <mat-expansion-panel
                class="mat-elevation-z0 mt-2"
                *ngIf="criterion.scaleRatings?.length > 0 || criterion.milestones?.length > 0"
              >
                <mat-expansion-panel-header>
                  <mat-panel-title><h3 class="my-0 text-normal">Scale Rating / Milestones</h3></mat-panel-title>
                </mat-expansion-panel-header>
                <ng-template matExpansionPanelContent>
                  <div class="row">
                    <div class="col" *ngIf="criterion.scaleRatings?.length > 0">
                      <h4>Scale Ratings</h4>
                      <table mat-table class="w-100" [dataSource]="criterion.scaleRatings">
                        <ng-container matColumnDef="rating">
                          <th mat-header-cell *matHeaderCellDef>Rating</th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.rating }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="description">
                          <th mat-header-cell *matHeaderCellDef>Description</th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.description }}
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsScaleRatings"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsScaleRatings"></tr>
                      </table>
                    </div>
                    <div class="col" *ngIf="criterion.milestones?.length > 0">
                      <h4>Milestones</h4>
                      <table mat-table class="w-100" [dataSource]="criterion.milestones">
                        <ng-container matColumnDef="completionDate">
                          <th mat-header-cell *matHeaderCellDef>Completion Date</th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.completionDate | dateFormat }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="description">
                          <th mat-header-cell *matHeaderCellDef>Description</th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.description }}
                          </td>
                        </ng-container>
                        <ng-container matColumnDef="value">
                          <th mat-header-cell *matHeaderCellDef>Value</th>
                          <td mat-cell *matCellDef="let element">
                            {{ element.value }}
                          </td>
                        </ng-container>

                        <tr mat-header-row *matHeaderRowDef="displayedColumnsMilestones"></tr>
                        <tr mat-row *matRowDef="let row; columns: displayedColumnsMilestones"></tr>
                      </table>
                    </div>
                  </div>
                </ng-template>
              </mat-expansion-panel>

              <mat-expansion-panel class="mat-elevation-z0" expanded>
                <mat-expansion-panel-header>
                  <mat-panel-title>
                    <h3 class="my-0 text-normal">
                      {{ criterion.measurementType === 'numeric' ? 'Previously Entered Data and Graph' : 'Previously Entered Data' }}
                    </h3>
                  </mat-panel-title>
                </mat-expansion-panel-header>

                <ng-template matExpansionPanelContent>
                  <app-progress-data [criterion]="criterion"></app-progress-data>

                  <ng-container *ngIf="criterion.measurementType === 'numeric'">
                    <div class="row">
                      <div class="col-md-12 col-lg-9 col-xl-9 chart-viewer">
                        <app-chart-viewer [params]="{ OutcomeCriteriaId: criterion.id }" [graph]="'objective'"></app-chart-viewer>
                      </div>
                    </div>

                    <mat-expansion-panel class="mat-elevation-z0 mt-2">
                      <mat-expansion-panel-header>
                        <mat-panel-title><h3 class="my-0 text-normal">Phase Lines</h3></mat-panel-title>
                      </mat-expansion-panel-header>
                      <ng-template matExpansionPanelContent>
                        <app-phase-line [phaseLines]="criterion.phaseLines"></app-phase-line>
                      </ng-template>
                    </mat-expansion-panel>

                    <mat-expansion-panel class="mat-elevation-z0">
                      <mat-expansion-panel-header>
                        <mat-panel-title><h3 class="my-0 text-normal">Comments</h3></mat-panel-title>
                      </mat-expansion-panel-header>
                      <ng-template matExpansionPanelContent>
                        <app-comment [comments]="criterion.comments"></app-comment>
                      </ng-template>
                    </mat-expansion-panel>
                  </ng-container>
                </ng-template>
              </mat-expansion-panel>
            </ng-template>
          </mat-expansion-panel>
        </ng-template>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card>
</div>
