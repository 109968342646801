import { BroadcastChannel } from 'broadcast-channel';

/**
 * Safari is now the new IE and doesn't support native BroadcastChannel api
 * https://github.com/pubkey/broadcast-channel
 * Broadcast Channel's allow cross tab/window communication.
 * Examples:
 * To send an object or message use something like:
 * PopupProxy.familyMeetingClose.postMessage({} as FamilyMeetingCreate);
 * To receive a message do:
 * PopupProxy.familyMeetingClose.onmessage = (meeting) => {};
 */
export class PopupProxy {
  /**
   * Should send a FamilyMeetingCreate object
   */
  static familyMeetingClose = new BroadcastChannel('familyMeetingClose');
  /**
   * Should send a FIT/CSA Summary object
   */
  static fitCsaSummary = new BroadcastChannel('fitCsaSummary');
  /**
   * Should send a PDF ArrayBuffer
   */
  static draftPdf = new BroadcastChannel('draftPdf');
}
