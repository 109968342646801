import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { IvrsCase } from '../../../ivrs/models/ivrs-case';
import { IvrsCaseService } from '../../../ivrs/services/ivrs-case.service';
import { IvrsUserManagementService } from '../../../ivrs/services/ivrs-user-management.service';
import { Aea } from '../../../shared/models/aea';
import { Building } from '../../../shared/models/building';
import { District } from '../../../shared/models/district';
import { User } from '../../../shared/models/user';
import { AeaService } from '../../../shared/services/aea/aea.service';
import { BuildingService } from '../../../shared/services/building/building.service';
import { DistrictService } from '../../../shared/services/district/district.service';
import { UserSearchParams } from '../../../shared/services/user/user.service';

@Component({
  selector: 'app-transfer-ivrs-case',
  templateUrl: './transfer-ivrs-case.component.html',
  styleUrls: ['./transfer-ivrs-case.component.scss'],
})
export class TransferIvrsCaseComponent implements OnInit {
  ivrsCaseId: string;
  ivrsCase: IvrsCase;

  aeas: Array<Aea>;
  aeaUsers: Array<User>;
  selectedAeaUser: User;

  districts: Array<District>;
  buildings: Array<Building>;

  showSharedUsers: boolean;
  sharedUsersNames: string[];
  activeCall = false;

  constructor(
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: string,
    private userManagementService: IvrsUserManagementService,
    private aeaService: AeaService,
    private districtService: DistrictService,
    private buildingService: BuildingService,
    private readonly ivrsCaseService: IvrsCaseService
  ) {
    this.ivrsCaseId = data;
  }

  ngOnInit(): void {
    this.loadData();
  }

  loadData() {
    this.ivrsCaseService.getIvrsCase(this.ivrsCaseId).subscribe((res) => {
      this.ivrsCase = res.value;

      forkJoin([this.aeaService.getPublicAeas(), this.districtService.getDistricts(), this.buildingService.getAllBuildings()]).subscribe(
        ([aeas, districts, buildings]) => {
          this.districts = districts;
          this.aeas = aeas;
          this.buildings = buildings;
        }
      );
    });
  }

  onAeaSelectionChanged(selectedUsers: Array<User>) {
    this.selectedAeaUser = selectedUsers[0];
  }

  onSearch(formGroup: FormGroup) {
    const control = formGroup.get('filterBy');
    if (control) {
      control.setValue('');
    }
    const userSearchParamenters = {
      firstName: formGroup.get('firstName').value,
      lastName: formGroup.get('lastName').value,
      aeaId: formGroup.get('aeaId').value,
      schoolDistrictId: formGroup.get('schoolDistrictId').value,
      buildingId: formGroup.get('buildingId').value,
      professionId: formGroup.get('professionId').value,
    } as UserSearchParams;
    this.userManagementService.getUsersForCaseShareTransfer(userSearchParamenters).subscribe(
      (res) =>
        (this.aeaUsers = res.value.filter(
          (x) =>
            !this.ivrsCase.caseUsers
              .filter((x) => x.userId == this.ivrsCase.ownerId)
              .map((x) => x.userId)
              .includes(x.id)
        ))
    );
  }

  onClose() {
    this.dialogRef.close();
  }

  onTransfer() {
    this.activeCall = true;

    this.ivrsCaseService.transferCase(this.ivrsCaseId, this.selectedAeaUser.id).subscribe(() => {
      this.activeCall = false;
      this.dialogRef.close(this.ivrsCase);
    });
  }
}
