import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-learner-vr-status',
  templateUrl: './learner-vr-status.component.html',
  styleUrls: ['./learner-vr-status.component.scss'],
})
export class LearnerVrStatusComponent implements OnInit {
  @Input() isActive: boolean;
  @Output() statusChanged = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  changeStatus(event: MatRadioChange) {
    this.statusChanged.emit(event.value);
  }
}
