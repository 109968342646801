<app-page-header
  pageTitle="{{ headerTitle }}"
  backgroundColor="yellow"
  [helpLinks]="true"
  [condensed]="true"
  [shouldShowDhhBanner]="caseSummary?.learner.isDhh"
>
  <ng-container pageActions>
    <button
      mat-flat-button
      aria-label="View IFSP"
      (click)="onViewIfsp()"
      color="primary"
      class="mat-elevation-z0 mr-2"
      tabindex="0"
      [isBusy]="activeCalls.ifspPdf"
    >
      <mat-icon class="text-lg" aria-labelledby="Open in new tab">open_in_new</mat-icon>
      View IFSP
    </button>

    <button
      mat-flat-button
      aria-label="Push to Portal"
      (click)="onPushToPortal()"
      color="primary"
      class="mat-elevation-z0 mr-2"
      tabindex="0"
      [isBusy]="activeCalls.pushToPortal"
      *ngIf="canPushToPortal"
    >
      <mat-icon class="text-lg" aria-labelledby="Open in new tab">open_in_new</mat-icon>
      Push to Portal
    </button>

    <button
      mat-flat-button
      aria-label="View ECO Matrix"
      class="mr-2"
      color="primary"
      (click)="onOpenMatrix()"
      *ngIf="caseSummary?.evaluationId"
      tabindex="0"
    >
      <mat-icon class="text-lg" aria-labelledby="Open in new tab">open_in_new</mat-icon>
      View ECO Matrix
    </button>
  </ng-container>
</app-page-header>

<app-dynamic-learner-header></app-dynamic-learner-header>

<app-ifsp-nav></app-ifsp-nav>
