<div [ngClass]="{ 'container container--not-centered container--form': !alignEnd }">
  <div
    *ngIf="!readOnly"
    [ngClass]="{
      'display-flex align-items-center justify-content-end': alignEnd
    }"
  >
    <button
      mat-raised-button
      aria-label="Open Documentation Upload"
      type="button"
      (click)="onOpenUpload()"
      [isBusy]="activeCall"
      tabindex="0"
    >
      {{ title }}
    </button>
  </div>
  <div class="w-100 mt-2" *ngIf="formGroup.get('documents').value">
    <div class="table-overflow w-100">
      <table class="w-100" mat-table [dataSource]="formGroup.get('documents').value">
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let file">
            <button
              mat-icon-button
              aria-label="Delete Icon"
              color="warn"
              type="button"
              aria-label="Delete"
              (click)="onRemoveDocument(file)"
              tabindex="0"
            >
              <mat-icon>close</mat-icon>
            </button>
          </td>
        </ng-container>
        <ng-container matColumnDef="friendlyName">
          <th mat-header-cell *matHeaderCellDef>Title</th>
          <td mat-cell *matCellDef="let file">
            <a href="{{ baseUrl }}/documents/{{ file.id }}" target="_blank" *ngIf="file.id" tabindex="0" role="link" aria-labelledby="">
              {{ file.fileName }}
            </a>
            <a (click)="loadDocument(file)" *ngIf="!file.id">{{ file.title }}</a>
          </td>
        </ng-container>

        <ng-container matColumnDef="fileName">
          <th mat-header-cell *matHeaderCellDef>File</th>
          <td mat-cell *matCellDef="let file">
            {{ file.fileName }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <p *ngIf="!formGroup.get('documents').value">No data yet...</p>
    </div>
  </div>
</div>
