import { Component, Input, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { PrintDocumentsModalComponent } from '../print-documents-modal/print-documents-modal.component';

@Component({
  selector: 'app-print-documents-button',
  templateUrl: './print-documents-button.component.html',
  styleUrls: ['./print-documents-button.component.scss'],
})
export class PrintDocumentsButtonComponent implements OnInit {
  @Input() canMerge = true;
  @Input() showAll = false;
  @Input() documents: any[];
  @Input() buttonLabel = 'Print Documents';
  @Input() mergeLabel = '';
  @Input() map: { [col: string]: string };

  constructor(private readonly dialog: MatDialog) {}

  ngOnInit(): void {}

  async onPrintDocument(e) {
    this.dialog.open(PrintDocumentsModalComponent, {
      width: '728px',
      data: {
        canMerge: this.canMerge,
        showAll: this.showAll,
        documents: this.documents,
        mergeLabel: this.mergeLabel,
        map: this.map,
      },
    });
  }
}
