import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LoginModel } from '../../auth-models';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-login-mfa',
  templateUrl: './login-mfa.component.html',
  styleUrls: ['./login-mfa.component.scss'],
})
export class LoginMfaComponent implements OnInit {
  @Input() mfaToken: any;
  @Input() loginFailed: boolean;
  @Input() isBusy = false;

  @Output() submitted = new EventEmitter<LoginModel>();
  options: string[];

  loginForm = this.fb.group({
    pin: [
      '',
      {
        validators: [Validators.required],
      },
    ],
  });

  get pin() {
    return this.loginForm.get('pin');
  }

  constructor(private fb: FormBuilder) {}

  ngOnInit() {}

  onSubmit() {
    this.loginForm.markAllAsTouched();
    this.loginForm.updateValueAndValidity();

    if (this.loginForm.valid) {
      this.submitted.emit(this.loginForm.value);
    }
  }

  getErrorMessage() {
    return this.pin.hasError('required') ? 'You must enter a value' : '';
  }
}
