import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddNewChildGuard } from 'src/app/child-find/child-find.guard';
import { DirectAccessGuard } from 'src/app/shared/guards/direct-access.guard';
import { NewChildComponent } from '../../shared/components/new-child/new-child.component';
import { DetailsComponent } from './details/details.component';
import { ResultsComponent } from './results/results.component';
import { SearchComponent } from './search.component';

const routes: Routes = [
  {
    path: '',
    component: SearchComponent,
    children: [
      {
        path: '',
        component: ResultsComponent,
      },
    ],
  },
  {
    path: 'details/:id',
    component: DetailsComponent,
    canActivate: [DirectAccessGuard],
  },
  {
    path: 'new-child',
    component: NewChildComponent,
    canActivate: [AddNewChildGuard],
    data: {
      isNewChild: true,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SearchRoutingModule {}
