<ng-container *ngIf="alert?.status && alert?.message">
  <div
    class="alert"
    [ngClass]="{
      'alert--info': alert.status === 'info',
      'alert--warning': alert.status === 'warning',
      'alert--success': alert.status === 'success'
    }"
  >
    <div class="alert__icon">
      <i class="fas fa-info-circle" *ngIf="alert.status === 'info'"></i>
      <i class="fas fa-exclamation" *ngIf="alert.status === 'warning'"></i>
    </div>
    <div class="alert__message">
      <h4 *ngIf="alert?.title" style="padding-top: 10px; margin-bottom: 0">
        {{ alert.title }}
      </h4>
      <p class="my-0">
        {{ alert.message }}
      </p>
      <p *ngIf="alert.showRefreshMessage" class="my-0">Refresh your page after making changes</p>
    </div>
    <div class="alert__actions">
      <ng-content select="[alertActions]"></ng-content>
    </div>
  </div>
</ng-container>
