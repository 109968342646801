<div class="container-fluid">
  <div>
    <form [formGroup]="searchFormGroup">
      <app-text-field label="Search" formControlName="searchCriteria"></app-text-field>
    </form>
  </div>
  <div class="row">
    <div class="col-12">
      <div class="service-coordinator-table-container">
        <div class="table-overflow app-table">
          <table mat-table [dataSource]="dataSource" matSort>
            <!-- Checkbox Column -->
            <ng-container matColumnDef="select">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let row">
                <mat-checkbox
                  (click)="$event.stopPropagation()"
                  (change)="$event ? selection.toggle(row) : null"
                  [checked]="selection.isSelected(row)"
                >
                </mat-checkbox>
              </td>
            </ng-container>

            <!-- Service Coordinator Name Column -->
            <ng-container matColumnDef="fullname">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Service Coordinator</th>
              <td mat-cell *matCellDef="let serviceCoordinator">
                {{ serviceCoordinator.name }}
              </td>
            </ng-container>

            <!-- Number of Cases Column -->
            <ng-container matColumnDef="numberOfCases">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>Number of Cases</th>
              <td mat-cell *matCellDef="let serviceCoordinator">
                <span class="mat-cell--position-fix">
                  {{ serviceCoordinator.numberOfCases }}
                </span>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
          </table>
          <app-table-paginator
            *ngIf="currentPageInfo.length > 0"
            [length]="currentPageInfo.length"
            [pageSize]="currentPageInfo.pageSize"
            [pageIndex]="currentPageInfo.pageIndex"
            (page)="pageChanged($event)"
          ></app-table-paginator>
          <p *ngIf="!dataSource.data">No data yet...</p>
        </div>
      </div>
    </div>
  </div>
</div>
