import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Referral } from 'src/app/child-find/early-access-referral/referral';
import { ReferralService } from 'src/app/child-find/early-access-referral/early-access-referral.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';

@Component({
  selector: 'app-in-progress-referral-modal',
  templateUrl: './in-progress-referral-modal.component.html',
  styleUrls: ['./in-progress-referral-modal.component.scss'],
})
export class InProgressReferralModalComponent implements OnInit {
  selectedReferral: Referral;
  displayedColumns: string[] = ['select', 'name', 'createdOn', 'updatedOn'];
  dataSource: MatTableDataSource<Referral>;
  selection = new SelectionModel<Referral>(true, []);
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  shortDateFormat = shortDateFormat;

  constructor(
    private referralService: ReferralService,
    public dialogRef: MatDialogRef<InProgressReferralModalComponent>,
    @Inject(MAT_DIALOG_DATA) public referrals: Referral[]
  ) {}

  ngOnInit() {
    this.dataSource = new MatTableDataSource(this.referrals);
    this.dataSource.filterPredicate = (data: Referral, filter: string) => {
      filter.toLowerCase().trim();
      const fullName = data.childInfo.firstName.concat(' ').concat(data.childInfo.lastName).toLowerCase().trim();

      return !filter || fullName.includes(filter);
    };
    this.dataSource.sortingDataAccessor = (data: Referral, property: string) => {
      switch (property) {
        case 'name':
          return data.childInfo.firstName.concat(' ').concat(data.childInfo.lastName).toLowerCase().trim();
        default:
          return data[property];
      }
    };
    this.dataSource.sort = this.sort;
  }

  clearReferrals() {
    const observables = [];

    this.selection.selected.forEach((referralSelected) => {
      observables.push(
        this.referralService.deleteReferral(referralSelected?.id).pipe(
          catchError(() => {
            console.log('Error');
            return of(undefined);
          })
        )
      );
    });

    forkJoin(observables).subscribe(() => {
      // Reset data
      this.referralService.getInProgressReferrals().subscribe((res) => {
        this.dataSource = new MatTableDataSource(res);
        if (this.dataSource.data.length === 0) {
          this.dialogRef.close();
        }
      });
    });
  }

  selectAll() {
    this.dataSource.data.forEach((referral) => {
      if (!this.selection.isSelected(referral)) {
        this.selection.toggle(referral);
      }
    });
  }

  isAllSelected() {
    if (this.selection.selected.length !== this.dataSource.data.length) {
      return false;
    }
    return true;
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: Referral): string {
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  compareObjects(o1, o2): boolean {
    return o1?.id === o2?.id;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
