<div class="display-flex justify-content-between align-items-center flex-wrap">
  <div>
    <h3 class="mt-0 mb-0">Assign Cases</h3>
  </div>

  <div class="display-flex flex-wrap">
    <div class="cases__filter-container">
      <form [formGroup]="formGroup">
        <div class="row">
          <div class="col">
            <app-text-field formControlName="name" label="Name"></app-text-field>
          </div>
          <div class="col">
            <app-autocomplete [options]="districtOptions" formControlName="district" label="Filter by District"></app-autocomplete>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="unassigned-cases-table-container">
      <div class="table-overflow">
        <table mat-table [dataSource]="dataSource" matSort #sort="matSort">
          <!-- Checkbox Column -->
          <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
              <mat-checkbox
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                [aria-label]="checkboxLabel()"
              >
                Select All
              </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
              <mat-checkbox
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(row) : null"
                [checked]="selection.isSelected(row)"
                [aria-label]="checkboxLabel(row)"
              >
              </mat-checkbox>
            </td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let unassignedCase">
              {{ unassignedCase.fullName }}
            </td>
          </ng-container>

          <!-- AEA Column -->
          <ng-container matColumnDef="aea">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>AEA</th>
            <td mat-cell *matCellDef="let unassignedCase">
              {{ unassignedCase.attendingAeaName }}
            </td>
          </ng-container>

          <!-- DOB Column -->
          <ng-container matColumnDef="learnerDateOfBirth">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>DOB</th>
            <td mat-cell *matCellDef="let unassignedCase">
              {{ unassignedCase.dateOfBirth | date : shortDateFormat }}
            </td>
          </ng-container>

          <!-- District Column -->
          <ng-container matColumnDef="district">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>District</th>
            <td mat-cell *matCellDef="let unassignedCase">
              {{ unassignedCase.attendingDistrictName }}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns" (click)="selection.toggle(row)"></tr>
        </table>
        <p *ngIf="!dataSource.data">No data yet...</p>

        <app-table-paginator
          *ngIf="currentPageInfo.length > 0"
          [length]="currentPageInfo.length"
          [pageSize]="currentPageInfo.pageSize"
          [pageIndex]="currentPageInfo.pageIndex"
          (page)="pageChanged($event)"
        ></app-table-paginator>
      </div>
    </div>
  </div>
</div>
