<h3 class="mat-dialog-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Are you sure?</h3>

<div [formGroup]="formGroup" class="mat-dialog-content py-2">
  <mat-card>
    <ng-container *ngIf="isPossibleDuplicate; else noDuplicate">
      <p>
        This learner appears to already be in ACHIEVE based on demographic data from the student information system (SIS/SIF). Are you sure
        you want to continue? Doing so could result in a duplicate record being created in the ACHIEVE.
      </p>
      <p>Select No for further research prior to adding the learner.</p>
    </ng-container>
    <ng-template #noDuplicate>
      <p>Are you sure you want to continue? Doing so will result in a new case being created in the ACHIEVE system.</p>
    </ng-template>
    <ng-container *ngIf="!isPartCLearner">
      <p>If any of the following conditions apply, please check the applicable option before choosing to continue.</p>
      <p>
        <app-checkbox-single
          formControlName="movingFromOutOfState"
          attr.aria-label="{{ learnersName }} is moving in from out-of-state and has an active IEP."
          label="{{ learnersName }} is moving in from out-of-state and has an active IEP."
        ></app-checkbox-single>
        <app-checkbox-single
          *ngIf="!hideEligibleInLegacySystem"
          formControlName="eligibleInLegacySystem"
          attr.aria-label="{{ learnersName }} was found eligible in the legacy system."
          label="{{ learnersName }} was found eligible in the legacy system."
        ></app-checkbox-single>
        <app-checkbox-single
          *ngIf="isOutOfState"
          formControlName="addingFromOutOfState"
          attr.aria-label="{{ learnersName }} was found eligible in the legacy system."
          label="{{
            learnersName
          }} was found eligible, placed out of state, has a current IEP from that state, and does not have a current IEP in the ACHIEVE system."
        ></app-checkbox-single>
      </p>
    </ng-container>
  </mat-card>
  <mat-card *ngIf="isOutOfState && isAddingFromOutOfState">
    <p>Out-of-State Placement information</p>
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-4">
          <app-date-picker label="IEP Date" controlName="iepDate"></app-date-picker>
        </div>
        <div class="col-lg-4">
          <app-date-picker label="IEP Due Date" controlName="iepDueDate"></app-date-picker>
        </div>
        <div class="col-lg-4">
          <app-date-picker label="Reevaluation Date" controlName="reevalDate"></app-date-picker>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-8">
          <app-radio-group
            label="Learner eligible in Iowa?"
            [options]="optionsForEligibleInIowa"
            formControlName="eligibleInIowa"
            [useUndo]="false"
          ></app-radio-group>
        </div>
        <div class="col-lg-4">
          <app-autocomplete label="Weighted Level" formControlName="weightedLevel" [options]="optionsForWeightedLevel"></app-autocomplete>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <app-document-upload-button
            buttonText="Upload IEP"
            outputType="Base64"
            (uploaded)="uploadOospDocument($event)"
          ></app-document-upload-button>
          <div>
            <p *ngFor="let file of oospFiles" style="margin: 0px">
              <button mat-icon-button aria-label="Remove Icon" color="warn" (click)="removeOospDocument(file)" tabindex="0">
                <mat-icon>close</mat-icon>
              </button>
              {{ file.title }} ({{ file.fileName }})
            </p>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>

<div class="mat-dialog-actions justify-content-end">
  <button mat-raised-button aria-label="No" (click)="onClose()" tabindex="0">No</button>
  <button
    mat-raised-button
    color="primary"
    aria-label="Yes"
    (click)="onYes()"
    [disabled]="submitting || formGroup.invalid || (isAddingFromOutOfState && oospFiles.length === 0)"
    cdkFocusInitial
    tabindex="1"
  >
    Yes
  </button>
</div>
