<div #editingGoal></div>

<div class="display-flex flex-1 justify-content-between align-items-center flex-wrap mt-0 mb-2">
  <h3 class="mt-0 mb-2">
    {{ iepGoalHelp.CurrentLevel }}
    of Academic Achievement and Functional Performance
  </h3>
  <div>
    <button mat-raised-button aria-label="View IEP Goal Tags" color="accent" (click)="openTags()" tabindex="0">View IEP Goal Tags</button>
    <button
      class="ml-3"
      mat-raised-button
      aria-label="Upload Document for Goals"
      [isBusy]="activeCall"
      color="primary"
      (click)="onUploadDocuments($event)"
      tabindex="0"
    >
      Upload Document for Goals
    </button>
  </div>
</div>

<form *ngIf="formGroup" [formGroup]="formGroup" #formDirective="ngForm">
  <mat-card class="my-0 card--shadow" [ngClass]="{ 'pt-1 pb-2': !formGroup.get('domain').value }">
    <div class="row">
      <div class="col-md-12 col-lg-6">
        <app-autocomplete-chips
          [options]="goalAreaOptions"
          formControlName="goalAreaIds"
          label="Select one or more goal area(s)"
        ></app-autocomplete-chips>
        <app-compare-amendment-output
          *ngIf="amendingGoal"
          class="mr-1"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [priorVersionId]="amendingGoal?.priorVersionId"
          [isActive]="amendingGoal.isActive"
          [changedVersionText]="getGoalAreas(formGroup.value)"
          [currentVersionText]="getGoalAreas(amendingGoal.priorVersion)"
          [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        >
        </app-compare-amendment-output>
        <label class="mt-3" *ngIf="formGroup.get('domain').value">
          <strong>
            <a
              class="text-underline"
              (click)="onOpenHelp(helpSection.IepGoal, iepGoalHelp.Domain)"
              tabindex="0"
              role="link"
              attr.aria-label="{{ iepGoalHelp.Domain }}"
            >
              {{ iepGoalHelp.Domain }}:</a
            >
          </strong>
          <app-compare-amendment-output
            *ngIf="amendingGoal"
            class="mr-1"
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="amendingGoal?.priorVersionId"
            [isActive]="amendingGoal?.isActive"
            [changedVersionText]="formGroup.get('domain').value"
            [currentVersionText]="amendingGoal.priorVersion.domain"
            [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          >
          </app-compare-amendment-output>
        </label>
      </div>
    </div>

    <ng-container *ngIf="showBody">
      <div class="mt-3">
        <label
          class="vertical-label display-block"
          [ngClass]="{
            asterisk_input: formGroup.get('currentLevelDescription').hasError('required')
          }"
        >
          Describe {{ caseSummary?.learner.firstName | firstNamePossessive }}
          <a
            (click)="onOpenHelp(helpSection.IepGoal, iepGoalHelp.CurrentLevel)"
            class="text-underline text-lower"
            tabindex="0"
            role="link"
            attr.aria-label="{{ iepGoalHelp.CurrentLevel }}"
          >
            {{ iepGoalHelp.CurrentLevel }}
          </a>
          of performance in the goal area(s).
        </label>
        <app-textarea
          formControlName="currentLevelDescription"
          [hideAsterisk]="true"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.iepGoals.currentLevelDescription"
          [rows]="4"
          attr.aria-required="{{ formGroup.get('currentLevelDescription').hasError('required') ? 'true' : 'false' }}"
        ></app-textarea>
        <app-compare-amendment-output
          *ngIf="amendingGoal"
          class="mr-1"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [priorVersionId]="amendingGoal?.priorVersionId"
          [isActive]="amendingGoal?.isActive"
          [changedVersionText]="formGroup.get('currentLevelDescription').value"
          [currentVersionText]="amendingGoal.priorVersion?.currentLevelDescription"
          [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        >
        </app-compare-amendment-output>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <div>
            <app-autocomplete
              formControlName="standardOfComparisonId"
              label="Select the Standard of Comparison"
              [options]="standardsOfComparisonOptions"
            ></app-autocomplete>
            <app-compare-amendment-output
              *ngIf="amendingGoal"
              class="mr-1"
              [amendmentId]="amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="amendingGoal?.priorVersionId"
              [isActive]="amendingGoal?.isActive"
              [changedVersionText]="getStandardOfComparisonText(formGroup.get('standardOfComparisonId').value)"
              [currentVersionText]="getStandardOfComparisonText(amendingGoal.priorVersion?.standardOfComparisonId)"
              [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
            <app-text-field
              *ngIf="isStandardOfComparisonOther(formGroup.controls.standardOfComparisonId?.value)"
              formControlName="standardOfComparisonOther"
              [isBankableField]="true"
              [bankableFieldId]="bankableFieldIds.iepGoals.standardOfComparisonOther"
              label="Other Standard of Comparison"
            ></app-text-field>
            <app-compare-amendment-output
              *ngIf="amendingGoal && isStandardOfComparisonOther(formGroup.controls.standardOfComparisonId?.value)"
              class="mr-1"
              [amendmentId]="amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="amendingGoal?.priorVersionId"
              [isActive]="amendingGoal?.isActive"
              [changedVersionText]="formGroup.get('standardOfComparisonOther').value"
              [currentVersionText]="amendingGoal.priorVersion?.standardOfComparisonOther"
              [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </div>
        </div>
        <div class="col-md-12 col-lg-6 justify-self-end display-flex align-items-center">
          <ng-container *ngFor="let resource of externalResources; last as isLast">
            <a
              [href]="resource.link"
              target="_blank"
              [ngClass]="{ 'mr-2': !isLast }"
              tabindex="0"
              role="link"
              aria-labelledby="External Resources"
            >
              {{ resource.label }}
            </a>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </mat-card>
  <div *ngIf="showBody">
    <div class="card-spacing-top mb-2 display-flex align-items-center justify-content-between flex-wrap">
      <h3 class="my-0">Annual Measurable Goal</h3>
    </div>
    <div>
      <mat-card class="my-0 pt-1">
        <label
          class="vertical-label"
          [ngClass]="{
            asterisk_input: formGroup.get('conditionIndividualWillPerform').hasError('required')
          }"
        >
          Create a measurable annual goal by describing the
          <strong>conditions</strong> (when and how the individual will perform); <strong>behavior</strong> (what the individual will do);
          and <strong>criterion</strong> (acceptable level of performance).
        </label>
        <app-textarea
          class="mb-3"
          formControlName="conditionIndividualWillPerform"
          [hideAsterisk]="true"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.iepGoals.conditionIndividualWillPerform"
          [bankableFieldChildFname]="caseSummary?.learner.firstName"
          attr.aria-required="{{ formGroup.get('conditionIndividualWillPerform').hasError('required') ? 'true' : 'false' }}"
        >
        </app-textarea>
        <app-compare-amendment-output
          *ngIf="amendingGoal"
          class="mr-1"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [priorVersionId]="amendingGoal?.priorVersionId"
          [isActive]="amendingGoal?.isActive"
          [changedVersionText]="formGroup.get('conditionIndividualWillPerform').value"
          [currentVersionText]="amendingGoal.priorVersion?.conditionIndividualWillPerform"
          [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        >
        </app-compare-amendment-output>

        <div class="row">
          <div class="col-md-12 col-xl-4">
            <label
              class="vertical-label text-md display-block position-fix--label"
              [ngClass]="{
                asterisk_input: formGroup.get('goalNickName').hasError('required')
              }"
            >
              Goal
              <a
                class="text-underline"
                (click)="onOpenHelp(helpSection.IepGoal, iepGoalHelp.Nickname)"
                tabindex="0"
                role="link"
                attr.aria-label="{{ iepGoalHelp.Nickname }}"
              >
                {{ iepGoalHelp.Nickname }}
              </a>
            </label>
            <app-text-field
              formControlName="goalNickName"
              [isBankableField]="true"
              [bankableFieldId]="bankableFieldIds.iepGoals.goalNickName"
              [hideAsterisk]="true"
              attr.aria-required="{{ formGroup.get('goalNickName').hasError('required') ? 'true' : 'false' }}"
            ></app-text-field>
            <app-compare-amendment-output
              *ngIf="amendingGoal"
              class="mr-1"
              [amendmentId]="amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="amendingGoal?.priorVersionId"
              [isActive]="amendingGoal?.isActive"
              [changedVersionText]="formGroup.get('goalNickName').value"
              [currentVersionText]="amendingGoal.priorVersion?.goalNickName"
              [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </div>
        </div>
        <app-iep-quantifiable-data
          *ngIf="formGroup"
          [parentFormGroup]="formGroup"
          [formGroup]="primaryQuantifiableData"
          [caseSummary]="caseSummary"
          (addQuantData)="addMilestone()"
          [amendmentId]="amendmentId"
          [amendments]="amendments"
          [amendingGoal]="amendingGoal"
          [advancedVisible]="quantDataAdvancedVisible"
          [secondMeasurement]="quantDataSecondMeasurement"
          [secondaryAdvancedVisible]="quantDataSecondaryAdvancedVisible"
        ></app-iep-quantifiable-data>
        <div *ngIf="quantData" class="display-flex justify-content-between align-items-center flex-wrap">
          <div
            *ngIf="
              quantData?.advancedVisible &&
              !formGroup.get('primaryQuantifiableData').get('tieredMilestoneSelected').value &&
              !formGroup.get('primaryQuantifiableData').get('scaleSelected').value
            "
            class="display-flex align-items-start"
          >
            <div class="mr-4"></div>
            <div *ngIf="combinedGoal">
              <app-number-field formControlName="rubricMax" label="Scale Values - 0 through:" id="rubricMax"></app-number-field>
              <app-compare-amendment-output
                *ngIf="amendingGoal"
                class="mr-1"
                [amendmentId]="amendmentId"
                [amendmentFinalized]="amendmentIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [priorVersionId]="amendingGoal?.priorVersionId"
                [isActive]="amendingGoal?.isActive"
                [changedVersionText]="formGroup.get('rubricMax').value"
                [currentVersionText]="amendingGoal.priorVersion?.rubricMax"
                [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
                changedPrefixText="Proposed: "
              >
              </app-compare-amendment-output>
            </div>
          </div>
        </div>

        <!-- Editing Quantifiable Data Section -->
        <ng-container *ngIf="editingQuantifiableFormGroup">
          <div class="display-flex justify-content-between align-items-center flex-wrap card-spacing-top mb-2">
            <h3 class="my-0">Objective</h3>

            <div *ngIf="addlQuantifiableDataFormArray?.length > 0" class="display-flex align-items-center justify-content-end">
              <app-checkbox-single
                [disabled]="combinedGoal"
                formControlName="useSameUnitAndTarget"
                aria-labelledby="Use the same goal unit of measurement and target for all objectives"
                label="Use the same goal unit of measurement and target for all objectives"
              >
              </app-checkbox-single>
            </div>
          </div>
          <mat-card>
            <app-iep-quantifiable-data
              [parentFormGroup]="formGroup"
              [formGroup]="editingQuantifiableFormGroup"
              [caseSummary]="caseSummary"
              [rubricMax]="rubricMax"
              [isAdditionalQuant]="true"
              [useSameUnitAndTarget]="formGroup.get('useSameUnitAndTarget')?.value"
              [primaryTargetValue]="primaryTargetValue"
              [primaryUnitOfMeasurement]="primaryUnitOfMeasurement"
              [advancedVisible]="quantDataAdvancedVisible"
              [secondMeasurement]="quantDataSecondMeasurement"
              [secondaryAdvancedVisible]="quantDataSecondaryAdvancedVisible"
              [amendmentId]="amendmentId"
              [amendments]="amendments"
              [amendingGoal]="amendingGoal"
            ></app-iep-quantifiable-data>
            <div class="action__row mb-0 mt-2">
              <button
                type="button"
                aria-label="Cancel Objective"
                *ngIf="!combinedGoal"
                (click)="onCancelMilestone()"
                mat-raised-button
                color="warn"
                tabindex="0"
              >
                Cancel Objective
              </button>
              <button
                *ngIf="!combinedGoal"
                aria-label="Complete and Close"
                (click)="onSubmitMilestone()"
                mat-raised-button
                color="primary"
                tabindex="0"
              >
                Complete and Close
              </button>
              <button
                *ngIf="combinedGoal"
                aria-label="Complete"
                (click)="onSubmitMilestone()"
                mat-raised-button
                color="primary"
                tabindex="0"
              >
                Complete
              </button>
            </div>
          </mat-card>
        </ng-container>

        <ng-container *ngIf="measurementMilestoneDataSource && measurementMilestoneDataSource.data?.length > 0">
          <h4 class="text-primary mt-2 mb-0">Objectives</h4>
          <div class="table-overflow app-table">
            <table mat-table [dataSource]="measurementMilestoneDataSource" class="w-100" matSort>
              <ng-container matColumnDef="actions">
                <th mat-header-cell *matHeaderCellDef class="mat-column-actions--more">Actions</th>
                <td mat-cell *matCellDef="let milestone" class="mat-column-actions--more">
                  <div class="display-flex align-items-center justify-content-start">
                    <button
                      type="button"
                      [disabled]="milestone?.id === editingMilestoneId"
                      (click)="editMilestone(milestone)"
                      mat-icon-button
                      aria-label="Edit Icon"
                      color="primary"
                      tabindex="0"
                    >
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button
                      type="button"
                      (click)="deleteMilestone(milestone)"
                      mat-icon-button
                      aria-label="Delete Icon"
                      class="icon-delete"
                      tabindex="0"
                    >
                      <mat-icon>close</mat-icon>
                    </button>
                    <button
                      type="button"
                      mat-icon-button
                      aria-label="Warning"
                      *ngIf="showWarning(milestone.id)"
                      class="icon-incomplete"
                      tabindex="0"
                    >
                      <mat-icon aria-label="Warning">warning</mat-icon>
                    </button>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="description">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Description</th>
                <td mat-cell *matCellDef="let element">
                  <app-compare-amendment-output
                    class="mr-1"
                    [amendmentId]="amendmentId"
                    [amendmentFinalized]="amendmentIsFinalized"
                    [amendmentDate]="lastFinalizedDate"
                    [priorVersionId]="element?.priorVersionId"
                    [isActive]="element?.isActive"
                    [changedVersionText]="getObjectiveDescription(element, measurementMilestoneDataSource?.data?.length)"
                    [currentVersionText]="getObjectiveDescription(element.priorVersion, measurementMilestoneDataSource?.data?.length)"
                    [currentPrefixText]="element.priorVersion ? 'Current: ' : ''"
                    changedPrefixText="Proposed: "
                  >
                  </app-compare-amendment-output>
                </td>
              </ng-container>

              <ng-container matColumnDef="baseline">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Baseline</th>
                <td mat-cell *matCellDef="let element">
                  <app-compare-amendment-output
                    class="mr-1"
                    [amendmentId]="amendmentId"
                    [amendmentFinalized]="amendmentIsFinalized"
                    [amendmentDate]="lastFinalizedDate"
                    [priorVersionId]="element?.priorVersionId"
                    [isActive]="element?.isActive"
                    [changedVersionText]="getObjectiveBaseline(element)"
                    [currentVersionText]="getObjectiveBaseline(element.priorVersion)"
                    [currentPrefixText]="element.priorVersion ? 'Current: ' : ''"
                    changedPrefixText="Proposed: "
                  >
                  </app-compare-amendment-output>
                </td>
              </ng-container>

              <ng-container matColumnDef="target">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Target</th>
                <td mat-cell *matCellDef="let element">
                  <app-compare-amendment-output
                    class="mr-1"
                    [amendmentId]="amendmentId"
                    [amendmentFinalized]="amendmentIsFinalized"
                    [amendmentDate]="lastFinalizedDate"
                    [priorVersionId]="element?.priorVersionId"
                    [isActive]="element?.isActive"
                    [changedVersionText]="getObjectiveTarget(element)"
                    [currentVersionText]="getObjectiveTarget(element.priorVersion)"
                    [currentPrefixText]="element.priorVersion ? 'Current: ' : ''"
                    changedPrefixText="Proposed: "
                  >
                  </app-compare-amendment-output>
                </td>
              </ng-container>

              <ng-container matColumnDef="noResults">
                <td mat-footer-cell *matFooterCellDef colspan="7">
                  <i>No results...</i>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="measurementMilestoneDisplayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: measurementMilestoneDisplayedColumns"></tr>
              <tr
                [hidden]="measurementMilestoneDataSource?.data?.length > 0"
                mat-footer-row
                *matFooterRowDef="measurementMilestoneDataSource.data?.length === 0 ? ['noResults'] : []"
              ></tr>
            </table>
            <mat-paginator [dataSource]="measurementMilestoneDataSource"></mat-paginator>
          </div>
        </ng-container>
      </mat-card>
    </div>

    <h3 class="mb-2 card-spacing-top">Monitoring for Progress</h3>
    <mat-card class="my-0 pb-0">
      <div class="display-flex align-items-baseline">
        <span class="mr-2">
          How often will progress towards
          {{ caseSummary?.learner.firstName | firstNamePossessive }} targeted value be measured?
        </span>
        <app-number-field formControlName="frequencyAmount" class="input--small" label="" [min]="1" id="frequencyAmount"></app-number-field>
        <app-compare-amendment-output
          *ngIf="amendingGoal"
          class="mr-1"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [priorVersionId]="amendingGoal?.priorVersionId"
          [isActive]="amendingGoal?.isActive"
          [changedVersionText]="formGroup.get('frequencyAmount').value"
          [currentVersionText]="amendingGoal.priorVersion?.frequencyAmount"
          [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        >
        </app-compare-amendment-output>
        <span class="mx-2">times per</span>
        <app-autocomplete formControlName="frequencyPeriod" [options]="frequencyPeriodOptions"></app-autocomplete>
        <app-compare-amendment-output
          *ngIf="amendingGoal"
          class="mr-1"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [priorVersionId]="amendingGoal?.priorVersionId"
          [isActive]="amendingGoal?.isActive"
          [changedVersionText]="formGroup.get('frequencyPeriod').value"
          [currentVersionText]="amendingGoal.priorVersion?.frequencyPeriod"
          [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        >
        </app-compare-amendment-output>
      </div>

      <div class="row">
        <div class="col-md-12">
          <app-autocomplete-chips
            [options]="userOptions"
            formControlName="peopleMonitoringGoal"
            label="Person(s) responsible for monitoring goal"
          ></app-autocomplete-chips>
          <app-compare-amendment-output
            *ngIf="amendingGoal"
            class="mr-1"
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="amendingGoal?.priorVersionId"
            [isActive]="amendingGoal?.isActive"
            [changedVersionText]="getPeopleMonitoringGoalText(formGroup.get('peopleMonitoringGoal').value)"
            [currentVersionText]="getPeopleMonitoringGoalText(amendingGoal.priorVersion?.peopleMonitoringGoal)"
            [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          >
          </app-compare-amendment-output>
        </div>

        <div class="col-md-12 col-lg-6">
          <app-text-field
            formControlName="collaborators"
            [isBankableField]="true"
            [bankableFieldId]="bankableFieldIds.iepGoals.collaborators"
            label="Collaborators"
          ></app-text-field>
          <app-compare-amendment-output
            *ngIf="amendingGoal"
            class="mr-1"
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="amendingGoal?.priorVersionId"
            [isActive]="amendingGoal?.isActive"
            [changedVersionText]="formGroup.get('collaborators').value"
            [currentVersionText]="amendingGoal.priorVersion?.collaborators"
            [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          >
          </app-compare-amendment-output>
        </div>
        <div class="col-md-12 col-lg-6">
          <app-textarea
            formControlName="monitoringProceduresDescription"
            label="Describe any additional monitoring procedures"
            [isBankableField]="true"
            [bankableFieldId]="bankableFieldIds.iepGoals.monitoringProceduresDescription"
            [maxLength]="10000"
            [rows]="4"
          ></app-textarea>
          <app-compare-amendment-output
            *ngIf="amendingGoal"
            class="mr-1"
            [amendmentId]="amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="amendingGoal?.priorVersionId"
            [isActive]="amendingGoal?.isActive"
            [changedVersionText]="formGroup.get('monitoringProceduresDescription').value"
            [currentVersionText]="
              amendingGoal.priorVersion?.monitoringProceduresDescription ? amendingGoal.priorVersion?.monitoringProceduresDescription : null
            "
            [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          >
          </app-compare-amendment-output>
        </div>
      </div>
      <div *ngIf="!hasAmendment" class="display-flex align-items-center justify-content-end">
        <button
          type="button"
          mat-raised-button
          aria-label="Add Service, Support, or Activity for this Goal"
          color="primary"
          class="mb-2"
          (click)="showSSA ? (showSSA = false) : (showSSA = true)"
          tabindex="0"
        >
          Add Service, Support, or Activity for this Goal
        </button>
      </div>

      <div *ngIf="showSSA">
        <h3 class="mt-0 mb-2">Service, Activity or Support</h3>
        <div class="row">
          <div class="col-md-12">
            <mat-accordion class="accordion accordion--short" [multi]="true">
              <mat-expansion-panel class="mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-panel-title><h3 class="my-0 text-normal">Services</h3></mat-panel-title>
                </mat-expansion-panel-header>
                <app-iep-ssaa-services
                  [caseSummary]="caseSummary"
                  [iepIncludesTrialPlacement]="caseSummary.activeIepIncludesTrialPlacement"
                  [trialPlacementTarget]="caseSummary.activeIepTrialPlacementTarget"
                  [learner]="caseSummary?.learner"
                  [isGoalsPage]="true"
                  [isPK]="isPK"
                  [iep]="iepView"
                ></app-iep-ssaa-services>
              </mat-expansion-panel>

              <mat-expansion-panel class="mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-panel-title><h3 class="my-0 text-normal">Activities and Supports</h3></mat-panel-title>
                </mat-expansion-panel-header>
                <app-iep-ssaa-activities-supports [isGoalsPage]="true"></app-iep-ssaa-activities-supports>
              </mat-expansion-panel>

              <mat-expansion-panel class="mat-elevation-z0">
                <mat-expansion-panel-header>
                  <mat-panel-title><h3 class="my-0 text-normal">Accommodations</h3></mat-panel-title>
                </mat-expansion-panel-header>
                <app-iep-ssaa-accommodations
                  *ngIf="caseSummary?.learnerId"
                  [learnerId]="caseSummary?.learnerId"
                  [isGoalsPage]="true"
                ></app-iep-ssaa-accommodations>
              </mat-expansion-panel>
            </mat-accordion>
          </div>
        </div>
      </div>
    </mat-card>

    <h3 class="card-spacing-top mb-2">Reporting Progress to Families</h3>
    <mat-card class="my-0">
      <div class="display-flex align-items-baseline">
        <label
          [ngClass]="{
            asterisk_input: formGroup.controls.sameRateAsPeersReported.value === null
          }"
          class="vertical-label display-block"
        >
          How often will progress be reported to
          {{ caseSummary?.learner.firstName | firstNamePossessive }} family?
        </label>
        <button
          mat-icon-button
          aria-label="Clear Icon"
          type="button"
          color="primary"
          (click)="onClearRadio('sameRateAsPeersReported')"
          class="text-sm"
          tabindex="0"
        >
          <i class="fas fa-undo" aria-label="Clear Icon"></i>
        </button>
      </div>
      <mat-radio-group formControlName="sameRateAsPeersReported" aria-labelledby="Same Rate as Peers Reported">
        <mat-radio-button [value]="true" class="column wrap w-fit" aria-labelledby="At the same rate as non-disabled peers">
          <div class="display-flex align-items-baseline flex-wrap">
            <span class="mr-2">At the same rate as non-disabled peers</span>
            <app-number-field
              formControlName="sameRateFrequency"
              [min]="1"
              class="input--small"
              label=""
              id="sameRateFrequency"
            ></app-number-field>
            <span class="mx-2">times per</span>
            <app-autocomplete formControlName="sameRatePeriod" [options]="frequencyPeriodOptions"></app-autocomplete>
          </div>
        </mat-radio-button>
        <mat-radio-button [value]="false" class="column wrap w-fit" aria-labelledby="At a different rate than non-disabled peers">
          <div class="display-flex align-items-baseline flex-wrap">
            <span class="mr-2">At a different rate than non-disabled peers</span>
            <app-number-field
              formControlName="differentRateFrequency"
              [min]="1"
              class="input--small"
              label=""
              id="differentRateFrequency"
            ></app-number-field>
            <span class="mx-2">times per</span>
            <app-autocomplete formControlName="differentRatePeriod" [options]="frequencyPeriodOptions"></app-autocomplete>
          </div> </mat-radio-button
      ></mat-radio-group>
      <mat-error
        class="error--fix"
        *ngIf="formGroup.controls.sameRateAsPeersReported.value === null && formGroup.controls.sameRateAsPeersReported.touched"
      >
        Field is <strong>required</strong>
      </mat-error>
      <app-compare-amendment-output
        *ngIf="amendingGoal"
        class="mr-1"
        [amendmentId]="amendmentId"
        [amendmentFinalized]="amendmentIsFinalized"
        [amendmentDate]="lastFinalizedDate"
        [priorVersionId]="amendingGoal?.priorVersionId"
        [isActive]="amendingGoal?.isActive"
        [changedVersionText]="getHowOftenProgressReportedText(formGroup.value)"
        [currentVersionText]="getHowOftenProgressReportedText(amendingGoal.priorVersion)"
        [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      >
      </app-compare-amendment-output>
      <div class="w-100">
        <app-checkbox
          label="How will progress be reported to the family?"
          [options]="methodsToReportOptions"
          controlName="methodsToReport"
        ></app-checkbox>
        <app-compare-amendment-output
          *ngIf="amendingGoal"
          class="mr-1"
          [amendmentId]="amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate"
          [priorVersionId]="amendingGoal?.priorVersionId"
          [isActive]="amendingGoal?.isActive"
          [changedVersionText]="getMethodsToReportText(formGroup.get('methodsToReport').value)"
          [currentVersionText]="getMethodsToReportText(amendingGoal.priorVersion?.methodsToReport)"
          [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        >
        </app-compare-amendment-output>
      </div>
      <app-text-field
        formControlName="otherMethodToReport"
        [isBankableField]="true"
        [bankableFieldId]="bankableFieldIds.iepGoals.otherMethodToReport"
        *ngIf="formGroup.get('methodsToReport').value?.includes('Other')"
        label="Describe Other"
      ></app-text-field>
      <app-compare-amendment-output
        *ngIf="amendingGoal && formGroup.get('methodsToReport').value?.includes('Other')"
        class="mr-1"
        [amendmentId]="amendmentId"
        [amendmentFinalized]="amendmentIsFinalized"
        [amendmentDate]="lastFinalizedDate"
        [priorVersionId]="amendingGoal?.priorVersionId"
        [isActive]="amendingGoal?.isActive"
        [changedVersionText]="formGroup.get('otherMethodToReport').value"
        [currentVersionText]="amendingGoal.priorVersion?.otherMethodToReport"
        [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      >
      </app-compare-amendment-output>
    </mat-card>

    <div class="action__row">
      <div class="display-flex justify-content-end flex-1">
        <button
          class="ml-1"
          *ngIf="!iepGoal?.taggedItem?.taggedForPwn"
          [disabled]="!iepGoalId"
          mat-raised-button
          aria-label="Tag for Prior Written Notice Button"
          (click)="tag(true)"
          tabindex="0"
        >
          Tag for Prior Written Notice
        </button>
        <button
          *ngIf="iepGoal?.taggedItem?.taggedForPwn"
          mat-raised-button
          aria-label="Untag for Prior Written Notice"
          color="primary"
          [disabled]="!iepGoalId"
          (click)="tag(false)"
          tabindex="0"
        >
          Untag for Prior Written Notice
        </button>
        <button
          type="button"
          mat-raised-button
          aria-label="Save Button"
          color="primary"
          (click)="onSaveAndClose()"
          [disabled]="!iepGoalId"
          [isBusy]="activeCall || isSaving"
          tabindex="0"
        >
          Save
        </button>
        <button
          type="button"
          (click)="onSubmit()"
          mat-raised-button
          aria-label="Complete and Close"
          color="primary"
          [disabled]="formGroup.invalid || !iepGoalId || (combinedGoal && addlQuantifiableDataCount === 0)"
          [disableAfterBusy]="formGroup.invalid"
          [isBusy]="activeCall || isSaving"
          tabindex="0"
        >
          Complete and Close
        </button>
        <button mat-raised-button aria-label="Show Required" color="accent" (click)="showAllErrors()" tabindex="0">Show Required</button>
      </div>
    </div>
  </div>
</form>

<ng-container *ngIf="!hasAmendment">
  <h3 class="mb-2 card-spacing-top">Summary of Goals</h3>
  <mat-card class="mb-0" [ngClass]="{ 'pt-1': iepGoals?.length > 0 }">
    <div *ngIf="iepGoals?.length === 0">No goals added yet..</div>
    <div class="table-overflow">
      <table *ngIf="iepGoals?.length > 0" mat-table [dataSource]="goalDataSource" class="w-100">
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef class="mat-column-actions--more">Actions</th>
          <td mat-cell *matCellDef="let goal" class="mat-column-actions--more">
            <div class="display-flex align-items-center justify-content-start">
              <button aria-label="View Icon" type="button" (click)="onViewGoal(goal)" mat-icon-button class="icon-view" tabindex="0">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
              <button
                aria-label="Edit Icon"
                type="button"
                [disabled]="goal.id === iepGoalId"
                (click)="onEditGoal(goal)"
                mat-icon-button
                color="primary"
                tabindex="0"
              >
                <mat-icon>edit</mat-icon>
              </button>
              <button
                aria-label="Delete Icon"
                type="button"
                (click)="onDeleteGoal(goal)"
                color="warn"
                mat-icon-button
                class="view_in_ar"
                tabindex="0"
              >
                <mat-icon>close</mat-icon>
              </button>
              <button
                type="button"
                mat-icon-button
                aria-label="Warning"
                color="warn"
                *ngIf="!goal.isComplete"
                class="icon-incomplete"
                tabindex="0"
              >
                <mat-icon aria-label="Warning">warning</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="area">
          <th mat-header-cell *matHeaderCellDef>Goal Area(s)</th>
          <td mat-cell *matCellDef="let goal">
            <app-compare-amendment-output
              class="mr-1"
              [amendmentId]="amendmentId"
              [tableData]="true"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="amendingGoal?.priorVersionId"
              [isActive]="amendingGoal?.isActive"
              [changedVersionText]="getGoalAreas(goal)"
              [currentVersionText]="getGoalAreas(goal.priorVersion)"
              [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </td>
        </ng-container>

        <ng-container matColumnDef="goalSentence">
          <th mat-header-cell *matHeaderCellDef>Goal</th>
          <td mat-cell *matCellDef="let goal">
            <app-compare-amendment-output
              class="mr-1"
              [amendmentId]="amendmentId"
              [tableData]="true"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="amendingGoal?.priorVersionId"
              [isActive]="amendingGoal?.isActive"
              [changedVersionText]="goal.conditionIndividualWillPerform"
              [currentVersionText]="goal.priorVersion?.conditionIndividualWillPerform"
              [currentPrefixText]="amendingGoal?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </td>
        </ng-container>

        <ng-container matColumnDef="graph">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let goal" class="mat-cell--actions text-right">
            <button
              *ngIf="goal.displayGraph"
              mat-icon-button
              aria-label="View Graph Icon"
              color="primary"
              class="mx-2 allow-click"
              (click)="onOpenGraph(goal)"
              tabindex="0"
            >
              <mat-icon>auto_graph</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedGoalColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedGoalColumns"></tr>
      </table>
    </div>
    <p *ngIf="!goalDataSource">No data yet...</p>
  </mat-card>

  <div class="page-bottom-leeway"></div>
</ng-container>
