import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { IepAccommodationService } from 'src/app/iep/services/iep-accommodation.service';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { UserService } from 'src/app/shared/services/user/user.service';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { BaseComponent } from '../../../../../shared/components/base-component/base-component';
import { NotifySave } from '../../../../../shared/decorators/notify-save.decorator';
import { ProviderUser } from '../../../../../shared/models/user';
import { DeactivationService } from '../../../../../shared/services/deactivation.service';
import { TrialPlacementTarget } from '../../../../models/iep';
import { IepAccommodationDto } from '../../../../models/iep-accommodation';
import { IepAmendment } from '../../../../models/iep-amendment';
import { IepAccommodationsTableComponent } from '../shared/iep-accommodations-table/iep-accommodations-table.component';

@Component({
  selector: 'app-iep-ssaa-accommodations',
  templateUrl: './iep-ssaa-accommodations.component.html',
  styleUrls: ['./iep-ssaa-accommodations.component.scss'],
})
export class IepSsaaAccommodationsComponent extends BaseComponent implements OnInit, OnDestroy {
  @ViewChild('formDirective') private formDirective: NgForm;
  @ViewChild('accommodationForEdit') serviceForEdit: ElementRef<any>;
  @ViewChild('accommodationlist')
  accommodationlist: IepAccommodationsTableComponent;

  @Input() isGoalsPage = false;
  @Input() amendmentId: string;
  @Input() learnerId: string;
  @Input() amendments: IepAmendment[];
  @Input() amendingAccommodation: IepAccommodationDto;
  @Input() iepIncludesTrialPlacement: boolean;
  @Input() trialPlacementTarget: TrialPlacementTarget;

  trialPlacementTargetEnum = TrialPlacementTarget;
  unModifiedAccommodation: IepAccommodationDto;

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentIsFinalized() {
    return this.amendment?.finalizeDate !== null;
  }

  get hasAmendment() {
    return this.amendmentId && this.amendmentId !== null;
  }

  get hasPriorVersion() {
    return !!this.amendingAccommodation?.priorVersionId;
  }

  accommodation: IepAccommodationDto;
  public iepId: string;
  public caseId: string;
  public accommodationId: string;
  private stopAutosaving$ = new Subject();
  activeCall = false;
  isNew = true;
  autosaveSubscription = new Subscription();
  providers: ProviderUser[] = [];
  formGroup = new FormGroup({
    description: new FormControl(null, [Validators.required]),
    frequencyPeriod: new FormControl(null, [Validators.required]),
    providers: new FormControl([], [Validators.required]),
    otherProvider: new FormControl(null),
    otherProviderName: new FormControl(null),
    otherProviderAgency: new FormControl(null),
    otherProviderRole: new FormControl(null),
    includesTrialPlacement: new FormControl(null),
    taggedForPwn: new FormControl(null),
  });

  frequencyPeriodOptions: KeyValuePair[] = [
    new KeyValuePair('Daily', 'Daily'),
    new KeyValuePair('Weekly', 'Weekly'),
    new KeyValuePair('Monthly', 'Monthly'),
    new KeyValuePair('Yearly', 'Yearly'),
    new KeyValuePair('During Assessments', 'During Assessments'),
    new KeyValuePair('As Outlined in the Description', 'As Outlined in the Description'),
  ];
  providerOptions: KeyValuePair[] = [];

  constructor(
    private userService: UserService,
    private notificationService: NotificationService,
    private iepAccommodationService: IepAccommodationService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private dialog: MatDialog,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.caseId = this.route.parent?.snapshot.paramMap.get('caseId');
    this.iepId = this.route.parent?.snapshot.paramMap.get('iepId');
    if (!this.caseId) {
      this.caseId = this.route.snapshot.paramMap.get('caseId');
    }
    if (!this.iepId) {
      this.iepId = this.route.snapshot.paramMap.get('iepId');
    }

    if (this.amendingAccommodation) {
      this.onEdit(this.amendingAccommodation);
    }

    this.iepAccommodationService.setAmendingAccommodation$.subscribe((result) => {
      this.amendingAccommodation = result;
      this.onEdit(this.amendingAccommodation);
    });

    forkJoin([this.userService.getProvidersByAea(this.learnerId)]).subscribe(([providers]) => {
      this.providers = providers;
      this.providerOptions = this.providers.map((x) => new KeyValuePair(x.id, x.fullName));
      this.isNew = true;

      this.subscriptions.add(
        this.formGroup.get('otherProvider').valueChanges.subscribe((isOther) => {
          const providersControl = this.formGroup.get('providers');
          const providerNameControl = this.formGroup.get('otherProviderName');
          if (isOther) {
            providersControl.clearValidators();
            providerNameControl.setValidators(Validators.required);
          } else {
            providerNameControl.setValue(null);
            this.formGroup.get('otherProviderAgency').setValue(null);
            this.formGroup.get('otherProviderRole').setValue(null);
            providersControl.setValidators(Validators.required);
            providerNameControl.clearValidators();
          }
          providersControl.updateValueAndValidity({ emitEvent: false });
          providerNameControl.updateValueAndValidity({ emitEvent: false });
        })
      );

      this.resetTrialPlacementControl();

      this.startAutosaving();
      console.log(this.accommodation, this.amendingAccommodation);
    });
  }

  viewSummary() {
    const config: NewWindowConfig = {
      path: `cases/${this.caseId}/iep/${this.iepId}/services/summary`,
      popup: true,
      width: '1480px',
    };
    openNewWindow(config);
  }

  onEdit(accommodation: IepAccommodationDto) {
    this.unModifiedAccommodation = Object.assign({}, accommodation);
    this.isNew = false;
    this.accommodation = accommodation;
    this.accommodationId = accommodation.id;

    const patchObj = Object.assign({}, this.accommodation);
    patchObj.providers = patchObj.providers.map((x) => x.userId);
    this.formGroup.patchValue(patchObj);
    this.scrollToSelectMethod(this.serviceForEdit);
    this.startAutosaving();
  }

  saveAndClose() {
    this.activeCall = true;
    const callback = () => {
      this.activeCall = false;
      this.resetForm();
      this.iepAccommodationService.accommodationClose.next();
      this.startAutosaving();
    };
    this.stopAutosaving$.next(undefined);
    this.makeSaveRequest({ callback });
  }

  onSubmit(callback?: () => void) {
    if (this.formGroup.valid) {
      this.stopAutosaving$.next(undefined);
      this.isNew = true;
      this.makeSaveRequest({
        submitting: true,
        callback,
      });
    }
  }

  submit() {
    this.activeCall = true;
    const callback = () => {
      this.iepAccommodationService.accommodationClose.next();
      this.startAutosaving();
      this.activeCall = false;
    };
    this.onSubmit(callback);
  }

  onDelete() {
    if (!this.accommodationId || (this.hasAmendment && this.hasPriorVersion)) {
      this.stopAutosaving$.next(undefined);
      this.iepAccommodationService.accommodationClose.next();
      this.resetForm();
      return;
    }

    const deleteIt = () => {
      this.stopAutosaving$.next(undefined);
      this.iepAccommodationService.deleteAccommodation(this.iepId, this.accommodationId).subscribe(() => {
        this.notificationService.success('Accommodation deleted');
        this.resetForm();
        this.isNew = true;
        this.startAutosaving();
      });
    };

    if (this.isNew) {
      deleteIt();
    } else {
      this.notificationService.confirmation('Are you sure you want to delete this Accommodation?', deleteIt);
    }
  }

  onCancel() {
    this.stopAutosaving$.next(undefined);
    const closeFormCallback = () => {
      this.iepAccommodationService.accommodationClose.next();
      this.resetForm();
      this.isNew = true;
      this.startAutosaving();
    };

    if (this.isNew && !this.accommodationId) {
      closeFormCallback();
    } else {
      if (this.unModifiedAccommodation) {
        this.onEdit(this.unModifiedAccommodation);
      }

      this.makeSaveRequest({
        submitting: true,
        callback: closeFormCallback,
      });
    }
  }

  resetForm() {
    this.formGroup.reset();
    this.formDirective.resetForm();
    this.formGroup.get('providers').setValue([]);
    this.accommodation = null;
    this.accommodationId = null;

    this.resetTrialPlacementControl();
  }

  resetTrialPlacementControl() {
    const isAllTrialPlacementSelected =
      this.iepIncludesTrialPlacement && this.trialPlacementTarget === this.trialPlacementTargetEnum.AllItems;
    this.formGroup.controls.includesTrialPlacement.setValue(isAllTrialPlacementSelected);
  }

  tag(tagFlag: boolean) {
    this.formGroup.controls.taggedForPwn.setValue(tagFlag);
    this.formGroup.controls.taggedForPwn.updateValueAndValidity();
    this.formGroup.markAsDirty();
  }

  @NotifySave
  private async makeSaveRequest({ submitting = false, callback = null, showNotification = true }): Promise<void> {
    const accommodation = {
      id: this.accommodationId,
      ...this.formGroup.value,
      isComplete: submitting,
      amendmentId: this.amendmentId,
    };

    accommodation.providers = this.providers
      .filter((x) => this.formGroup.get('providers').value.includes(x.id))
      .map((p) => {
        return {
          userId: p.id,
        };
      });

    const updateResult = await this.iepAccommodationService.update(this.iepId, accommodation).toPromise();
    this.accommodationId = updateResult.value;

    if (submitting) {
      this.resetForm();
      if (showNotification) {
        this.notificationService.success('Completed Accommodation successfully');
      }
      this.scrollToSelectMethod(this.serviceForEdit);
      this.startAutosaving();
    }
    if (callback) {
      callback();
    }
  }

  startAutosaving() {
    this.autosaveSubscription.unsubscribe();
    this.autosaveSubscription = this.formGroup.valueChanges
      .pipe(debounceTime(2000), takeUntil(this.stopAutosaving$))
      .subscribe(async () => {
        if (this.formGroup.dirty) {
          await this.makeSaveRequest({});
        }
      });
  }

  scrollToSelectMethod(element: ElementRef<any>) {
    element?.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  getFrequencyPeriodLabel(value) {
    return this.frequencyPeriodOptions.find((x) => x.key === value)?.value;
  }

  getProviderLabels(providers) {
    const providerLabels = [];

    if (providers) {
      providers.forEach((_provider) => {
        const providerFind = this.providerOptions.find((x) => x.key === _provider);

        if (providerFind) {
          providerLabels.push(providerFind.value);
        }
      });
    }

    return providerLabels.join(', ');
  }

  getPriorProviderLabels(providers) {
    const providerLabels = [];

    if (providers) {
      providers.forEach((_provider) => {
        const providerFind = this.providerOptions.find((x) => x.key === _provider.userId);

        if (providerFind) {
          providerLabels.push(providerFind.value);
        }
      });
    }

    return providerLabels.join(', ');
  }

  ngOnDestroy(): void {
    if (this.formGroup.dirty) {
      this.makeSaveRequest({
        submitting: false,
        callback: null,
        showNotification: false,
      });
    }
    super.ngOnDestroy();
  }

  lastFinalizedDate(amendmentId: string) {
    if (this.amendments && this.amendments.length > 0) {
      const amendment = this.amendments.find((a) => a.id === amendmentId);
      return amendment?.finalizeDate;
    }
    return null;
  }
}
