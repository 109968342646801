<div [ngClass]="{ referral__container: !isAuthenticated }">
  <div class="display-flex align-items-end justify-content-between" *ngIf="!isAuthenticated">
    <img src="../../../assets/img/ea-logo.png" alt="Early ACCESS Logo" class="early-access__image" />

    <p class="my-0 text-red">*indicates fields required</p>
  </div>

  <app-page-header
    [pageTitle]="'Early ACCESS Referral'"
    [backgroundColor]="'yellow'"
    [helpLinks]="true"
    (helpToggled)="helpToggled($event)"
  >
  </app-page-header>

  <app-page-alert
    *ngIf="pagesIncomplete.status !== null && pagesIncomplete.message !== null"
    [alert]="pagesIncomplete"
    role="status"
    aria-live="polite"
  ></app-page-alert>

  <div
    [ngClass]="{
      'card-spacing-bottom': pagesIncomplete.status !== null && pagesIncomplete.message !== null
    }"
  ></div>

  <mat-horizontal-stepper
    class="stepper"
    #stepper
    labelPosition="bottom"
    [disableRipple]="true"
    (selectionChange)="onStepperSelectionChange($event)"
  >
    <mat-step [stepControl]="childInfo">
      <ng-template matStepLabel>
        <div
          [ngClass]="{
            'stepper__label--invalid': childInfoInvalid && submitAttempted,
            'stepper__label--valid': childInfoComplete
          }"
          class="stepper__label"
        >
          <div class="icon__container">
            <mat-icon *ngIf="!childInfoComplete && !childInfoInvalid" aria-labelledby="Child Information">person</mat-icon>
            <mat-icon *ngIf="childInfoComplete" aria-labelledby="Check">check</mat-icon>
            <mat-icon *ngIf="childInfoInvalid" aria-labelledby="Child Information">person</mat-icon>
          </div>
          <p>Child Info</p>
        </div>
      </ng-template>

      <app-child-info-form [formGroup]="childInfo" aria-live="polite"></app-child-info-form>

      <div class="actions__container pb-3">
        <div class="actions"></div>
        <div class="actions">
          <button mat-raised-button color="accent" matStepperNext aria-label="Next Tab" tabindex="0">Next</button>
          <button
            mat-raised-button
            aria-label="Complete Referal"
            color="primary"
            [disabled]="formGroup.invalid"
            (click)="onSubmit()"
            tabindex="0"
          >
            Complete Referral
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step [stepControl]="parentInfo">
      <ng-template matStepLabel>
        <div
          [ngClass]="{
            'stepper__label--invalid': parentInfoInvalid && submitAttempted,
            'stepper__label--valid': parentInfoComplete
          }"
          class="stepper__label"
        >
          <div class="icon__container">
            <mat-icon *ngIf="parentInfoComplete" aria-labelledby="Check">check</mat-icon>

            <svg-icon
              src="assets/img/icons/Parent-Info.svg"
              svgClass="icon--svg"
              *ngIf="!parentInfoComplete && !parentInfoInvalid"
            ></svg-icon>

            <svg-icon src="assets/img/icons/Parent-Info.svg" svgClass="icon--svg" *ngIf="parentInfoInvalid"></svg-icon>
          </div>
          <p>Parent Info</p>
        </div>
      </ng-template>

      <app-parent-info-form [parentForm]="formGroup" role="status" aria-live="polite"></app-parent-info-form>

      <div class="actions__container pb-3">
        <div class="actions"></div>
        <div class="actions">
          <button mat-raised-button aria-label="Previous" color="accent" matStepperPrevious tabindex="0">Previous</button>
          <button mat-raised-button aria-label="Next" color="accent" matStepperNext tabindex="0">Next</button>
          <button
            mat-raised-button
            aria-label="Complete Referal"
            color="primary"
            [disabled]="formGroup.invalid"
            (click)="onSubmit()"
            tabindex="0"
          >
            Complete Referral
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step
      [stepControl]="familyInfo"
      *ngIf="parentInfo.get('parent1LivesWithChild').value === false && !parentInfo.get('parent2LivesWithChild').value"
    >
      <ng-template matStepLabel>
        <div
          [ngClass]="{
            'stepper__label--invalid': familyInfoInvalid && submitAttempted,
            'stepper__label--valid': familyInfoComplete
          }"
          class="stepper__label"
        >
          <div class="icon__container">
            <mat-icon *ngIf="!familyInfoComplete && !familyInfoInvalid" aria-labelledby="Family Information">people_alt</mat-icon>
            <mat-icon *ngIf="familyInfoComplete" aria-labelledby="Check">check</mat-icon>
            <mat-icon *ngIf="familyInfoInvalid" aria-labelledby="Family Information">people_alt</mat-icon>
          </div>
          <p>Family Info</p>
        </div>
      </ng-template>

      <app-family-info-form [parentForm]="formGroup" role="status" aria-live="polite"></app-family-info-form>

      <div class="actions__container pb-3">
        <div class="actions"></div>
        <div class="actions">
          <button mat-raised-button aria-label="Previous" color="accent" matStepperPrevious tabindex="0">Previous</button>
          <button mat-raised-button aria-label="Next" color="accent" matStepperNext tabindex="0">Next</button>
          <button
            mat-raised-button
            aria-label="Complete Referral"
            color="primary"
            [disabled]="formGroup.invalid"
            (click)="onSubmit()"
            tabindex="0"
          >
            Complete Referral
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step [stepControl]="referralSourceInfo" *ngIf="!formGroup.get('referralSource').value">
      <ng-template matStepLabel>
        <div
          [ngClass]="{
            'stepper__label--invalid': referralSourceInfoInvalid && submitAttempted,
            'stepper__label--valid': referralSourceInfoComplete
          }"
          class="stepper__label"
        >
          <div class="icon__container">
            <mat-icon *ngIf="!referralSourceInfoComplete && !referralSourceInfoInvalid" aria-labelledby="Chat Bubble">chat_bubble</mat-icon>
            <mat-icon *ngIf="referralSourceInfoComplete" aria-labelledby="Check">check</mat-icon>
            <mat-icon *ngIf="referralSourceInfoInvalid" aria-labelledby="Chat Bubble">chat_bubble</mat-icon>
          </div>
          <p>Referral Source Info</p>
        </div>
      </ng-template>

      <app-referral-source-info-form [parentForm]="formGroup" aria-live="polite"> </app-referral-source-info-form>

      <div class="actions__container pb-3">
        <div class="actions"></div>
        <div class="actions">
          <button mat-raised-button aria-label="Previous" color="accent" matStepperPrevious tabindex="0">Previous</button>
          <button mat-raised-button aria-label="Next" color="accent" matStepperNext tabindex="0">Next</button>
          <button
            mat-raised-button
            aria-label="Complete Referral"
            color="primary"
            [disabled]="formGroup.invalid"
            (click)="onSubmit()"
            tabindex="0"
          >
            Complete Referral
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step [stepControl]="referralReasonInfo">
      <ng-template matStepLabel>
        <div
          [ngClass]="{
            'stepper__label--invalid': referralReasonInfoInvalid && submitAttempted,
            'stepper__label--valid': referralReasonInfoComplete
          }"
          class="stepper__label"
        >
          <div class="icon__container">
            <mat-icon *ngIf="!referralReasonInfoComplete && !referralReasonInfoInvalid" aria-labelledby="Settings">settings</mat-icon>
            <mat-icon *ngIf="referralReasonInfoComplete" aria-labelledby="Check">check</mat-icon>
            <mat-icon *ngIf="referralReasonInfoInvalid" aria-labelledby="Settings">settings</mat-icon>
          </div>
          <p>Reason for Referral</p>
        </div>
      </ng-template>

      <app-referral-reason-info-form [referralId]="referralId" [formGroup]="referralReasonInfo" aria-live="polite">
      </app-referral-reason-info-form>

      <div class="actions__container pb-3">
        <div class="actions"></div>
        <div class="actions">
          <button mat-raised-button aria-label="Previous" color="accent" matStepperPrevious tabindex="0">Previous</button>
          <button mat-raised-button aria-label="Next" color="accent" matStepperNext tabindex="0">Next</button>
          <button
            mat-raised-button
            aria-label="Complete Referral"
            color="primary"
            [disabled]="formGroup.invalid"
            (click)="onSubmit()"
            tabindex="0"
          >
            Complete Referral
          </button>
        </div>
      </div>
    </mat-step>

    <mat-step [stepControl]="documentationInfo">
      <ng-template matStepLabel>
        <div
          [ngClass]="{
            'stepper__label--invalid': documentationInfoInvalid && submitAttempted,
            'stepper__label--valid': documentationInfoComplete
          }"
          class="stepper__label"
        >
          <div class="icon__container">
            <mat-icon *ngIf="!documentationInfoComplete && !documentationInfoInvalid" aria-labelledby="Documentation">folder</mat-icon>
            <mat-icon *ngIf="documentationInfoComplete" aria-labelledby="Check">check</mat-icon>
            <mat-icon *ngIf="documentationInfoInvalid" aria-labelledby="Documentation">folder</mat-icon>
          </div>
          <p>Documentation</p>
        </div>
      </ng-template>

      <app-documentation-info-form
        [formGroup]="documentationInfo"
        [parentForm]="formGroup"
        [isAuthenticated]="isAuthenticated"
        [documents]="documents"
        [learnersName]="childsName"
        aria-live="polite"
      >
      </app-documentation-info-form>

      <div class="actions__container pb-3">
        <div class="actions"></div>
        <div class="actions">
          <button mat-raised-button aria-label="Previous" color="accent" matStepperPrevious tabindex="0">Previous</button>
          <button
            mat-raised-button
            aria-label="Complete Referral"
            color="primary"
            [disabled]="formGroup.invalid"
            (click)="onSubmit()"
            tabindex="0"
          >
            Complete Referral
          </button>
        </div>
      </div>
    </mat-step>
  </mat-horizontal-stepper>

  <div class="invalid mt-5" *ngIf="!formGroup.valid">
    <mat-card>
      <div class="footer">
        <div class="footer__row footer__row--img">
          <img src="../../../assets/img/achieve-logo.svg" alt="ACHIEVE Logo" class="footer__logo" />
        </div>

        <div class="footer__row footer__row--text">
          <p class="color-maroon">This referral cannot be submitted yet because required fields are not completed.</p>

          <p>
            Call the
            <a (click)="openIowaIdeaInformationUrl()" tabindex="0" role="link" aria-labelledby="Call the Iowa Family Support Network">
              Iowa Family Support Network
            </a>
            at 1-888-425-4371 to talk with an Intake and Referral Specialist about this referral. Thank you!
          </p>
        </div>
      </div>
    </mat-card>
  </div>
</div>
