import { Location } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import { forkJoin, Subscription } from 'rxjs';
import { AuthService, ClaimTypes } from 'src/app/auth/auth.service';
import { AppPermissions } from 'src/app/permissions';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { Building } from 'src/app/shared/models/building';
import { District } from 'src/app/shared/models/district';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { LearnerSearchResult } from 'src/app/shared/models/learner';
import { AchieveConfigService } from 'src/app/shared/services/achieve-config-service/achieve-config.service';
import { AeaService } from 'src/app/shared/services/aea/aea.service';
import { BuildingService } from 'src/app/shared/services/building/building.service';
import { DistrictService } from 'src/app/shared/services/district/district.service';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { noNumbersValidator } from 'src/app/shared/validators';
import { AreYouSureComponent } from '../../shared/components/are-you-sure-modal/are-you-sure.component';
import { StringDateTimeToDateTimePipe } from '../../shared/pipes/date-transform.pipe';
import { MemoryStorageService } from '../../shared/services/memory-storage/memory-storage.service';
import { AlertDialogComponent } from '../../shared/services/notification.service';
import { ReferralService } from '../early-access-referral/early-access-referral.service';
import { Referral } from '../early-access-referral/referral';
import { SearchDataSource, SearchParams } from './search';
import { SearchService } from './search.service';
import { IntakeType } from 'src/app/shared/models/case';

const stateAssignedIdLengthValidator = (control: FormControl): ValidationErrors | null => {
  return control.value && control.value.length !== 10 ? { stateIdInvalidLength: true } : null;
};

const simpleSearchValidator = (control: FormGroup): ValidationErrors | null => {
  const lastName = control.get('lastName').value;
  const dobControl = control.get('dateOfBirth');
  const stateId = control.get('stateAssignedId').value;

  return !!lastName || (dobControl.valid && !!dobControl.value) || !!stateId ? null : { simpleSearchRequired: true };
};

const locationSearchValidator = (control: FormGroup): ValidationErrors | null => {
  const aea = control.get('attendingAeaId').value;
  const district = control.get('attendingDistrictId').value;

  return !!aea || !!district ? null : { locationSearchRequired: true };
};

const constrainedSearch = (control: FormGroup): ValidationErrors | null => {
  const firstName = control.get('firstName').value;
  const lastName = control.get('lastName').value;
  const dobControl = control.get('dateOfBirth');
  const stateId = control.get('stateAssignedId').value;

  return (!!firstName && !!lastName && dobControl.valid && !!dobControl.value) || !!stateId ? null : { constrainedSearchRequired: true };
};

@Component({
  selector: 'app-child-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  providers: [StringDateTimeToDateTimePipe],
})
export class SearchComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  private allDistricts: District[] = [];
  private allBuildings: Building[] = [];

  referral: Referral;
  formGroup: FormGroup;
  searchResults: LearnerSearchResult[] = null;
  today = dayjs().startOf('day').toDate();
  twentyFiveYearsAgo = dayjs(this.today).subtract(25, 'year').toDate();
  submitAttempted = false;
  under6YearsOld = false;
  aeaOptions: KeyValuePair[];
  districtOptions: KeyValuePair[];
  aeaDistrictOptions: KeyValuePair[];
  buildingOptions: KeyValuePair[];
  shortDateFormat = shortDateFormat;
  permissions = AppPermissions;
  activeCall = false;
  isLocationOnlySearch = false;
  isReady = false;
  dataSourceOptions: KeyValuePair[] = [
    new KeyValuePair(SearchDataSource.AllLearners, 'All Learners'),
    new KeyValuePair(SearchDataSource.ACHIEVELearnersOnly, 'ACHIEVE Learners Only'),
  ];

  constructor(
    private readonly fb: FormBuilder,
    private readonly aeaService: AeaService,
    private readonly districtService: DistrictService,
    private readonly buildingService: BuildingService,
    private readonly searchService: SearchService,
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    private readonly referralService: ReferralService,
    private readonly memoryStorageService: MemoryStorageService,
    public authService: AuthService,
    private readonly dialog: MatDialog,
    private location: Location,
    private readonly achieveConfigService: AchieveConfigService,
    private stringDateTimeToDateTimePipe: StringDateTimeToDateTimePipe
  ) {}

  get firstName() {
    return this.formGroup.get('firstName');
  }

  get lastName() {
    return this.formGroup.get('lastName');
  }

  get dateOfBirth() {
    return this.formGroup.get('dateOfBirth');
  }

  get stateAssignedId() {
    return this.formGroup.get('stateAssignedId');
  }

  get middleName() {
    return this.formGroup.get('middleName');
  }

  get searchDataSource() {
    return this.formGroup.get('searchDataSource');
  }

  get defaultSearchDataSourceValue() {
    return this.authService.hasClaim(ClaimTypes.StateWideSearch) ? SearchDataSource.ACHIEVELearnersOnly : SearchDataSource.AllLearners;
  }

  // TODO: Review with Rob
  get canAddNewLearner() {
    return (
      this.authService.canInitiateDisabilitySuspect ||
      this.authService.isDataTechnician ||
      this.achieveConfigService.settings.canAddAnyAgeLearner
    );
  }

  ngOnInit(): void {
    this.isLocationOnlySearch = this.location.path()?.includes('location-search');
    this.initializeFormGroup();
    forkJoin([this.aeaService.getAllAeas(), this.districtService.getDistricts(), this.buildingService.getAllBuildings()]).subscribe(
      ([aeas, districts, buildings]) => {
        this.aeaOptions = aeas.map((a) => new KeyValuePair(a.id, a.name));
        this.allDistricts = districts;
        this.allBuildings = buildings;
        this.filterDistricts();

        const storedCriteria = this.isLocationOnlySearch
          ? this.searchService.storedLocationSearchCriteria
          : this.searchService.storedSearchCriteria;
        const allowedToPrefill = this.isLocationOnlySearch || this.searchService.isReturningFromDetails;
        const referralId = this.route.snapshot.paramMap.get('referralId');
        if (storedCriteria && allowedToPrefill) {
          this.prefillSearchCriteria(storedCriteria);
          if (!this.isLocationOnlySearch) {
            this.searchService.flipStoredCriteriaFlag();
          }
          this.subscription.add(this.formGroup.valueChanges.subscribe(() => (this.submitAttempted = false)));
        } else if (referralId) {
          this.initializeFromReferral();
        }
        this.isReady = true;
      }
    );

    this.subscription.add(
      this.formGroup.get('attendingAeaId').valueChanges.subscribe((aeaId) => {
        this.formGroup.get('attendingDistrictId').reset();
        this.formGroup.get('buildingId').reset();
        this.filterDistricts();
      })
    );

    this.subscription.add(
      this.formGroup.get('attendingDistrictId').valueChanges.subscribe((districtId) => {
        this.formGroup.get('buildingId').reset();
        this.filterBuildings();
      })
    );
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initializeFormGroup() {
    this.formGroup = this.fb.group({
      firstName: [{ value: '', disabled: this.isLocationOnlySearch }, [noNumbersValidator]],
      middleName: [{ value: '', disabled: this.isLocationOnlySearch }, [noNumbersValidator]],
      lastName: [{ value: '', disabled: this.isLocationOnlySearch }, [noNumbersValidator]],
      dateOfBirth: [{ value: '', disabled: this.isLocationOnlySearch }, { updateOn: 'blur' }],
      stateAssignedId: [{ value: '', disabled: this.isLocationOnlySearch }, [stateAssignedIdLengthValidator]],
      attendingAeaId: [''],
      attendingDistrictId: [''],
      buildingId: [''],
      includeResidentInResults: [false],
      searchDataSource: [{ value: this.defaultSearchDataSourceValue, disabled: this.isLocationOnlySearch }],
    });

    if (!this.isLocationOnlySearch) {
      if (this.authService.isAllowed(AppPermissions.SimpleChildSearch)) {
        this.formGroup.setValidators([simpleSearchValidator]);
      } else {
        this.formGroup.setValidators([constrainedSearch]);
      }
      this.formGroup.updateValueAndValidity();
    } else {
      this.formGroup.setValidators([locationSearchValidator]);
      this.formGroup.updateValueAndValidity();
    }
  }

  clear() {
    this.aeaDistrictOptions = this.allDistricts.map((d) => new KeyValuePair(d.id, d.name));
    this.searchService.clearStored(this.isLocationOnlySearch);
    this.searchResults = null;
    this.formGroup.patchValue({
      firstName: null,
      middleName: null,
      lastName: null,
      dateOfBirth: null,
      stateAssignedId: null,
      attendingAeaId: null,
      attendingDistrictId: null,
      buildingId: null,
      includeResidentInResults: false,
      searchDataSource: this.defaultSearchDataSourceValue,
    });
    this.under6YearsOld = false;
  }

  filterDistricts() {
    if (this.formGroup.controls.attendingAeaId.value) {
      this.aeaDistrictOptions = this.allDistricts
        .filter((d) => d.aeaId === this.formGroup.controls.attendingAeaId.value)
        .map((d) => new KeyValuePair(d.id, d.name));
      this.filterBuildings();
    } else {
      this.aeaDistrictOptions = this.allDistricts.map((d) => new KeyValuePair(d.id, d.name));
    }
  }

  filterBuildings() {
    if (this.formGroup.controls.attendingDistrictId.value) {
      this.buildingOptions = this.allBuildings
        .filter((d) => d.districtId === this.formGroup.controls.attendingDistrictId.value)
        .map((d) => new KeyValuePair(d.id, d.name));
    } else if (this.formGroup.controls.attendingAeaId.value) {
      this.buildingOptions = this.allBuildings
        .filter((x) => this.aeaDistrictOptions?.some((d) => d.key === x.districtId))
        .map((d) => new KeyValuePair(d.id, d.name));
    } else {
      this.buildingOptions = this.allBuildings.map((d) => new KeyValuePair(d.id, d.name));
    }
  }

  search() {
    if (!this.formGroup.valid) {
      return;
    }
    this.activeCall = true;
    this.checkAge();
    this.searchService.search(this.convertFormToSearchParameters()).subscribe((results) => {
      this.submitAttempted = true;
      this.searchResults = results.map((x) => {
        x.grade = x.activeCase?.grade ?? x.grade;
        x.status =
          x.activeCase?.intakeType == IntakeType.PartB
            ? !!x.activeCase?.exitFinalizedOn
              ? 'Exited'
              : !!x.activeCase?.latestIep?.latestAmmendment?.finalizeDate
              ? 'IEP - Amended'
              : !!x.activeCase?.latestIep?.latestAmmendment?.iepAmmendmentId
              ? 'IEP - Amendment'
              : !!x.activeCase?.latestIep?.iepId
              ? `IEP - ${x.activeCase.latestIep.iepStatus}`
              : x.activeCase?.eligibilityDecision === true
              ? 'Eligible - Ready for IEP'
              : x.activeCase?.eligibilityDecision === false
              ? 'Not Eligible'
              : x.activeCase?.evaluation && !x.activeCase.evaluation.isFinalized
              ? 'Evaluation'
              : !!x.activeCase?.evaluation?.evaluationId
              ? 'Child Find'
              : ''
            : '';
        x.owner = x.activeCase?.facilitatorServiceCoordinator;
        x.annualReviewDate =
          x.activeCase?.intakeType == IntakeType.PartB ? x.activeCase?.iepAnnualReviewDate : x.activeCase?.ifspAnnualReviewStartDate;
        x.reevalDate = x.activeCase?.reevaluationDueOn;
        return x;
      });
      this.activeCall = false;
    });
  }

  storeSearchInfo() {
    this.searchService.storeSearch(this.convertFormToSearchParameters());
  }

  // This component is used in two different routing contexts...apparently we
  // need the `relativeTo` attribute to make that work!
  navigateToCreateNew(event: Event) {
    this.memoryStorageService.setKey('child-search', this.formGroup.value);
    this.router.navigate(['..', 'new-child'], {
      relativeTo: this.route,
    });
  }

  private initializeFromReferral() {
    this.referralService.getReferral(this.route.snapshot.paramMap.get('referralId')).subscribe((referral) => {
      this.referral = referral;
      this.formGroup.controls.firstName.setValue(referral.childInfo.firstName);
      this.formGroup.controls.middleName.setValue(referral.childInfo.middleName);
      this.formGroup.controls.lastName.setValue(referral.childInfo.lastName);

      if (referral.childInfo.dateOfBirth) {
        const childDateOfBirth = this.stringDateTimeToDateTimePipe.transform(referral.childInfo.dateOfBirth);
        this.formGroup.controls.dateOfBirth.setValue(childDateOfBirth);
      } else {
        this.formGroup.controls.dateOfBirth.setValue(null);
      }

      //this.formGroup.controls.attendingAeaId.setValue(referral.childInfo.aeaId);

      if (this.formGroup.controls.attendingAeaId.value) {
        this.filterDistricts();
      }
      //this.formGroup.controls.attendingDistrictId.setValue(referral.childInfo.childAttendingDistrictId);
      this.search();
      this.subscription.add(this.formGroup.valueChanges.subscribe(() => (this.submitAttempted = false)));
    });
  }

  private prefillSearchCriteria(storedCriteria: SearchParams) {
    this.formGroup.controls.attendingDistrictId.enable();
    this.formGroup.patchValue({
      firstName: storedCriteria.firstName,
      middleName: storedCriteria.middleName,
      lastName: storedCriteria.lastName,
      dateOfBirth: storedCriteria.dateOfBirth,
      stateAssignedId: storedCriteria.stateAssignedId,
      attendingAeaId: storedCriteria.attendingAeaId,
      attendingDistrictId: storedCriteria.attendingDistrictId,
      buildingId: storedCriteria.buildingId,
    });

    this.filterDistricts();
    this.search();
  }

  checkAge() {
    const dob = this.dateOfBirth;
    const current915 = dayjs(`${dayjs().year()}-09-15`).toString();
    if (this.achieveConfigService.settings.canAddAnyAgeLearner) {
      this.under6YearsOld = true;
    } else {
      this.under6YearsOld = LearnerService.goingToBeUnder(6, dob.value, current915).result;
    }
  }

  createNewLearner() {
    const dob = this.dateOfBirth;
    const current915 = dayjs(`${dayjs().year()}-09-15`).toString();
    const goingToBeUnder = LearnerService.goingToBeUnder(6, dob.value, current915);

    if (this.under6YearsOld) {
      if (goingToBeUnder.agebyFutureDate === 5) {
        const dialogRef = this.dialog.open(AreYouSureComponent, {
          data: {
            question: 'New Child Entry Part B',
            subQuestion: this.referral
              ? `Is ${this.referral?.childInfo.firstName} attending Kindergarten?`
              : 'Is the learner attending Kindergarten?',
          },
        });
        dialogRef.afterClosed().subscribe((res) => {
          if (res) {
            this.dialog.open(AlertDialogComponent, {
              data: {
                title: 'New Child Entry Part B',
                message: this.referral
                  ? `To activate ${this.referral?.childInfo.firstName} in ACHIEVE, first work with the school
                         to make sure the Kindergarten registration and enrollment process is complete.`
                  : `To activate the learner in ACHIEVE, first work with the school
                         to make sure the Kindergarten registration and enrollment process is complete.`,
              },
            });
          } else {
            this.navigateToCreateNew(null);
          }
        });
      } else {
        this.navigateToCreateNew(null);
      }
    }
  }

  navBackToggle() {
    if (this.isLocationOnlySearch) {
      this.router.navigate(['/', 'child-find', 'search']);
    } else {
      this.router.navigate(['/', 'child-find', 'location-search']);
    }
  }

  private convertFormToSearchParameters() {
    // Throw out any falsey terms
    const formValues = this.formGroup.value;
    const keys = Object.keys(formValues);
    return keys.reduce((accum, k) => {
      if (!!formValues[k]) {
        accum[k] = formValues[k];
      }
      accum['isLocationOnlySearch'] = this.isLocationOnlySearch;
      return accum;
    }, {} as SearchParams);
  }
}
