import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChartModule as VChartModule } from 'angular-highcharts';
import { ChartsModule } from 'ng2-charts';
import { AppMatModule } from '../shared/app-mat.module';
import { SharedModule } from '../shared/shared.module';
import { AddCombinedObjectiveProgressComponent } from './enter-learner-progress/components/enter-progress-part-b/components/add-combined-objective-progress/add-combined-objective-progress.component';
import { AddGoalProgressComponent } from './enter-learner-progress/components/enter-progress-part-b/components/add-goal-progress/add-goal-progress.component';
import { CombinedProgressDataTableBComponent } from './enter-learner-progress/components/enter-progress-part-b/components/combined-progress-data-table-b/combined-progress-data-table-b.component';
import { ProgressDataTableBComponent } from './enter-learner-progress/components/enter-progress-part-b/components/progress-data-table-b/progress-data-table-b.component';
import { EnterProgressPartBComponent } from './enter-learner-progress/components/enter-progress-part-b/enter-progress-part-b.component';
import { AddOutcomeProgressComponent } from './enter-learner-progress/components/enter-progress-part-c/add-outcome-progress/add-outcome-progress.component';
import { EnterProgressPartCComponent } from './enter-learner-progress/components/enter-progress-part-c/enter-progress-part-c.component';
import { PlanStatusComponent } from './enter-learner-progress/components/enter-progress-part-c/plan-status/plan-status.component';
import { PlanStatusTableComponent } from './enter-learner-progress/components/enter-progress-part-c/tables/plan-status-table/plan-status-table.component';
import { ProgressDataTableComponent } from './enter-learner-progress/components/enter-progress-part-c/tables/progress-data-table/progress-data-table.component';
import { CommentTableComponent } from './enter-learner-progress/components/tables/comment-table/comment-table.component';
import { PhaseLineTableComponent } from './enter-learner-progress/components/tables/phase-line-table/phase-line-table.component';
import { EnterLearnerProgressComponent } from './enter-learner-progress/enter-learner-progress.component';
import { AddProgressComponent } from './grade-book/add-progress/add-progress.component';
import { GradeBookComponent } from './grade-book/grade-book.component';
import { GraphModalComponent } from './grade-book/graph-modal/graph-modal.component';
import { MonitoringProgressRoutingModule } from './monitoring-progress-routing.module';
import { MonitoringProgressComponent } from './monitoring-progress.component';
import { ReportingProgressToFamilyComponent } from './enter-learner-progress/components/enter-progress-part-b/components/reporting-progress-to-family/reporting-progress-to-family.component';

@NgModule({
  declarations: [
    MonitoringProgressComponent,
    GradeBookComponent,
    EnterLearnerProgressComponent,
    EnterProgressPartBComponent,
    EnterProgressPartCComponent,
    AddGoalProgressComponent,
    PlanStatusComponent,
    AddOutcomeProgressComponent,
    PhaseLineTableComponent,
    CommentTableComponent,
    ProgressDataTableComponent,
    PlanStatusTableComponent,
    ProgressDataTableBComponent,
    CombinedProgressDataTableBComponent,
    AddCombinedObjectiveProgressComponent,
    AddProgressComponent,
    GraphModalComponent,
    ReportingProgressToFamilyComponent,
  ],
  imports: [
    AppMatModule,
    CommonModule,
    MonitoringProgressRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    ChartsModule,
    HttpClientModule,
    VChartModule,
  ],
  exports: [
    EnterLearnerProgressComponent,
    EnterProgressPartCComponent,
    PlanStatusTableComponent,
    EnterProgressPartBComponent,
    AddCombinedObjectiveProgressComponent,
  ],
})
export class MonitoringProgressModule {}
