import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OperationResult } from 'src/app/behavior-discipline/models/bip';
import { IncompleteDataReportItem } from 'src/app/shared/components/incomplete-data-report/incomplete-data-report-item';
import { EvaluationForLeanerManagement, FamilyMeetingRead } from 'src/app/shared/models/case';
import { LookupBase } from 'src/app/shared/models/lookup-base';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { ContinuedEligibilityInformation, EligibilityInformation, EligibilityWorksheet, Evaluation } from '../models/evaluation';
import { LearnerCalenderDto } from '../models/learner-calender-dto';

@Injectable({
  providedIn: 'root',
})
export class EvaluationService {
  constructor(private readonly http: HttpClient, private spinnerService: SpinnerService) {
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(`^${this.baseUrl()}`));
  }

  getLearnerEvaluations(learnerId: string) {
    return this.http.get<EvaluationForLeanerManagement[]>(`api/learners/${learnerId}/evaluations`);
  }

  add(caseId: string) {
    return this.http.post<Evaluation>(this.baseUrl(caseId), {});
  }

  get(evaluationId: string) {
    return this.http.get<Evaluation>(this.baseUrl(evaluationId), {});
  }

  getDomainAreas(evaluationId: string) {
    return this.http.get<LookupBase[]>(`${this.baseUrl(evaluationId)}/domain-areas/part-b`);
  }

  getDevelopmentalAreas(evaluationId: string) {
    return this.http.get<LookupBase[]>(`${this.baseUrl(evaluationId)}/domain-areas/part-c`);
  }

  getCalender(evaluationId: string) {
    return this.http.get<LearnerCalenderDto>(this.baseUrl(evaluationId) + '/learner-calender', {});
  }

  reassignFacilitator(evaluationId: string, _userId: string) {
    return this.http.post<void>(`${this.baseUrl(evaluationId)}/owner`, {
      userId: _userId,
    });
  }

  saveEligibilityInformation(evaluationId: string, model: EligibilityInformation) {
    return this.http.post<Evaluation>(`${this.baseUrl(evaluationId)}/eligibility-information`, model);
  }

  saveContinuedEligibilityInformation(evaluationId: string, model: ContinuedEligibilityInformation) {
    return this.http.post<Evaluation>(`${this.baseUrl(evaluationId)}/continued-eligibility-information`, model);
  }

  saveEligibilityWorksheet(evaluationId: string, model: any) {
    return this.http.post<void>(`${this.baseUrl(evaluationId)}/eligibility-worksheet`, model);
  }

  getEligibilityWorksheet(evaluationId: string) {
    return this.http.get<EligibilityWorksheet>(`${this.baseUrl(evaluationId)}/eligibility-worksheet`);
  }

  updateSld(evaluationId: string, consideringSld: boolean) {
    return this.http.put<Evaluation>(`${this.baseUrl(evaluationId)}/sld`, {
      consideringSld,
    });
  }

  finalizeEer(evaluationId: string) {
    return this.http.post<Evaluation>(`${this.baseUrl(evaluationId)}/final-eer`, { evaluationId });
  }

  finalizeEvaluationPartB(evaluationId: string) {
    return this.http.post<Evaluation>(`${this.baseUrl(evaluationId)}/final-evaluation-part-b`, { evaluationId });
  }

  finalizeEvaluationPartC(evaluationId: string) {
    return this.http.post<OperationResult>(`${this.baseUrl(evaluationId)}/final-evaluation-part-c`, { evaluationId });
  }

  draftEer(evaluationId: string) {
    return this.http.post<Evaluation>(`${this.baseUrl(evaluationId)}/draft-eer`, {
      evaluationId,
    });
  }

  getIncompleteDataReportPartB(evaluationId: string) {
    return this.http.get<IncompleteDataReportItem[]>(`${this.baseUrl(evaluationId)}/incomplete-data-report-part-b`);
  }

  getIncompleteDataReportPartC(evaluationId: string) {
    return this.http.get<IncompleteDataReportItem[]>(`${this.baseUrl(evaluationId)}/incomplete-data-report-part-c`);
  }

  getOldestIncompleteEligibilityMeeting(evaluationId: string, caseId: string) {
    return this.http.get<FamilyMeetingRead[]>(`${this.baseUrl(evaluationId)}/evaluation-meetings/oldest-incomplete/${caseId}`);
  }

  getInitialHomeVisitMeeting(evaluationId: string, caseId: string) {
    return this.http.get<FamilyMeetingRead[]>(`${this.baseUrl(evaluationId)}/evaluation-meetings/initial-visit-meeting/${caseId}`);
  }

  private baseUrl(id = '') {
    return `api/evaluations/${id}`;
  }
}
