import { Component, ContentChild, Input, TemplateRef } from '@angular/core';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { FamilyMemberType, LearnerSummary } from '../../../models/learner';
import { RoutingService } from '../../../services/routing.service';
import dayjs from 'dayjs';

@Component({
  selector: 'app-learner-info',
  templateUrl: 'learner-info.component.html',
  styleUrls: ['learner-info.component.scss'],
})
export class LearnerInfoComponent {
  @ContentChild('primaryInfo', { read: TemplateRef })
  public primaryInfoTemplate: TemplateRef<any>;
  @ContentChild('secondaryInfo', { read: TemplateRef })
  public secondaryInfoTemplate: TemplateRef<any>;

  @Input() public learnerSummary: LearnerSummary;
  @Input() public condensed = false;
  @Input() public isPrint = false;
  @Input() public fewFields = false;
  @Input() public showAgeInMonths = false;
  @Input() public showFamilyMembersInPrimary = false;
  @Input() public showLearnerLink = true;

  shortDateFormat = shortDateFormat;
  familyMemberTypes = [
    FamilyMemberType.Parent1,
    FamilyMemberType.Parent2,
    FamilyMemberType.Parent,
    FamilyMemberType.RelativeActingAsParentIdea,
    FamilyMemberType.LegalGuardianOfMinor,
    FamilyMemberType.LegalGuardianOfProtectedPerson,
    FamilyMemberType.CourtDesignee,
    FamilyMemberType.PowerOfAttorney,
  ];

  familyMemberLimitedTypes = [
    FamilyMemberType.LegalGuardianOfMinor,
    FamilyMemberType.LegalGuardianOfProtectedPerson,
    FamilyMemberType.PowerOfAttorney,
  ];

  get familyMembers() {
    return this.learnerSummary.familyMembers.filter((x) => this.familyMemberTypes.includes(x.type));
  }

  get familyMembersLimited() {
    return this.learnerSummary.familyMembers.filter((x) => this.familyMemberLimitedTypes.includes(x.type));
  }

  get surrogates() {
    return this.learnerSummary.surrogates.filter((x) => x.discontinuedOn === null);
  }

  get learner() {
    return this.learnerSummary.familyMembers.find((x) => x.type === FamilyMemberType.Learner);
  }

  get rightsHaveTransferred() {
    if (this.learnerSummary.iepInformation && this.learnerSummary.iepInformation.rightsWillNotTransfer) {
      if (this.learnerSummary.iepInformation.dateRightsWillTransfer) {
        return dayjs(this.learnerSummary.iepInformation.dateRightsWillTransfer).startOf('day').isBefore(dayjs());
      }
      return false;
    }

    return dayjs(this.learnerSummary.dateOfBirth).add(18, 'years').startOf('day').isBefore(dayjs());
  }

  get hasPoAorGuardian() {
    return this.learnerSummary.familyMembers.some(
      (x) =>
        x.type === FamilyMemberType.PowerOfAttorney ||
        x.type === FamilyMemberType.LegalGuardianOfMinor ||
        x.type === FamilyMemberType.LegalGuardianOfProtectedPerson
    );
  }

  get hasLearnerInContacts() {
    return this.learnerSummary.familyMembers.some((x) => x.type === FamilyMemberType.Learner);
  }

  constructor(private readonly routingService: RoutingService) {
    console.log(this.learnerSummary?.familyMembers);
  }

  getLearnerManagementLink() {
    return this.routingService.learnerDashboardPath(this.learnerSummary.id);
  }
}
