import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from '../../../../auth/auth.service';
import { AreYouSureComponent } from '../../../../shared/components/are-you-sure-modal/are-you-sure.component';
import { DhhPendingAppointmentDto, LearnerAppointmentStatus } from '../../../models/DhhDtos';
import { DhhRoutingService } from '../../../services/dhh-routing.service';

@Component({
  selector: 'app-dhh-pending-appointments-table',
  templateUrl: './dhh-pending-appointments-table.component.html',
  styleUrls: ['./dhh-pending-appointments-table.component.scss'],
})
export class DhhPendingAppointmentsTableComponent implements OnChanges {
  @Input() pendingAppointments: DhhPendingAppointmentDto[] = [];
  @Input() displayedColumns: string[];

  @Output() cancelEvent = new EventEmitter<string>();
  @Output() printConfirmationEvent = new EventEmitter<string>();
  @Output() rescheduleEvent = new EventEmitter<DhhPendingAppointmentDto>();
  @Output() keptEvent = new EventEmitter<DhhPendingAppointmentDto>();
  @Output() addNewTestEvent = new EventEmitter<string>();
  @Output() addNewConsentEvent = new EventEmitter<string>();

  dataSource = new MatTableDataSource<DhhPendingAppointmentDto>([]);
  toggleAll = true;
  statusEnum = LearnerAppointmentStatus;

  tomorrow: Date;

  get canPerfomAction() {
    return this.authService.isDhhAudiologist || this.authService.isDhhScheduler || this.authService.isSuperAdmin;
  }

  constructor(public readonly authService: AuthService, private dialog: MatDialog, public dhhRoutingService: DhhRoutingService) {
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'startTime':
          return new Date('1970/01/01 ' + item.startTime.slice(0, -3) + item.startTime.slice(-3));
        case 'endTime':
          return new Date('1970/01/01 ' + item.endTime.slice(0, -3) + item.endTime.slice(-3));
        default:
          return item[property];
      }
    };
    this.tomorrow = new Date();
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const propName in changes) {
      if (propName === 'pendingAppointments') {
        const changedProp = changes[propName];
        this.dataSource.data = changedProp.currentValue;
      }
    }
  }

  onCancelAppointment(learnerAppointmentId: string) {
    this.cancelEvent.emit(learnerAppointmentId);
  }

  onPrintConfirmationLetter(appointment: DhhPendingAppointmentDto) {
    this.printConfirmationEvent.emit(appointment.learnerAppointmentId);
  }

  onToggleAll(event: any) {
    this.toggleAll = !this.toggleAll;
    const selected = event.checked ? true : false;
    this.pendingAppointments.forEach((a) => (a.selected = selected));
  }

  onToggleElement(appointment: DhhPendingAppointmentDto) {
    this.toggleAll = false;
    appointment.selected = !appointment.selected;
  }

  onRescheduleAppointment(appointment: DhhPendingAppointmentDto) {
    this.rescheduleEvent.emit(appointment);
  }

  onAppointmentKept(appointment: DhhPendingAppointmentDto) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Please confirm that you wish to change this appointment status to Kept.',
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.keptEvent.emit(appointment);
      }
    });
  }

  canUpdateAppointment(status: string) {
    return this.canPerfomAction && status !== LearnerAppointmentStatus.Kept;
  }

  canAddNewTest(status: string) {
    return (this.authService.isDhhAudiologist || this.authService.isSuperAdmin) && status === LearnerAppointmentStatus.Kept;
  }

  onAddNewTest(appointment: DhhPendingAppointmentDto) {
    this.addNewTestEvent.emit(appointment.learnerId);
  }

  onAddNewConsent(appointment: DhhPendingAppointmentDto) {
    this.addNewConsentEvent.emit(appointment.learnerId);
  }

  isAppointmentPastOrPresent(appointment: DhhPendingAppointmentDto) {
    return new Date(appointment.appointmentDate) < this.tomorrow;
  }

  goToLearnerHearingDashboard(learnerId: string) {
    return this.dhhRoutingService.goToLearnerHearingDashboard(learnerId);
  }
}
