<header>
  <app-page-header [pageTitle]="pageTitle" backgroundColor="yellow"></app-page-header>
</header>

<ng-container *ngIf="formGroup">
  <mat-card>
    <ng-container [formGroup]="formGroup">
      <div class="row">
        <div class="col-lg-12 no-form-field-padding">
          <app-radio-group label="Type" formControlName="appointmentTypeId" [options]="appointmentTypeOptions"></app-radio-group>
        </div>
      </div>

      <div class="row mt-2" *ngIf="screeningEnabled || recheckEnabled">
        <div class="col-lg-6 no-form-field-padding">
          <app-autocomplete label="District" formControlName="districtId" [options]="districtOptions"></app-autocomplete>
        </div>
        <div class="col-lg-6 no-form-field-padding">
          <app-autocomplete label="Building" formControlName="buildingId" [options]="buildingOptions"></app-autocomplete>
        </div>
      </div>

      <ng-container *ngIf="screeningEnabled">
        <ng-container formGroupName="screening">
          <div class="row mt-2">
            <div class="col-lg-6 no-form-field-padding">
              <app-date-picker-v2 label="Start Date" formControlName="startDate" [required]="screeningEnabled"></app-date-picker-v2>
            </div>
            <div class="col-lg-6 no-form-field-padding">
              <app-date-picker-v2
                label="End Date"
                formControlName="endDate"
                [required]="screeningEnabled"
                [min]="formGroup.get('screening').get('startDate').value"
              ></app-date-picker-v2>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-6 no-form-field-padding">
              <app-autocomplete-chips
                label="Audiometrists"
                formControlName="audiometristIds"
                [options]="audiometristOptions"
                [showAsterisk]="formGroup.get('screening').hasError('needsAssignment')"
              ></app-autocomplete-chips>
            </div>
            <div class="col-lg-6 no-form-field-padding">
              <app-autocomplete-chips
                label="Audiologists"
                formControlName="audiologistIds"
                [options]="audiologistOptions"
                [showAsterisk]="formGroup.get('screening').hasError('needsAssignment')"
              ></app-autocomplete-chips>
            </div>
          </div>
          <div class="row" *ngIf="formGroup.get('screening').hasError('needsAssignment')">
            <div class="col">
              <mat-error style="font-size: 0.7rem">Either an Audiometrist or Audiologist is <strong>required</strong></mat-error>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="row mt-2" *ngIf="screeningEnabled && !appointment">
        <div class="col-lg-6 no-form-field-padding">
          <app-checkbox-single formControlName="scheduleRecheck" label="Schedule Recheck" aria-labelledby="Schedule Recheck">
          </app-checkbox-single>
        </div>
      </div>

      <ng-container *ngIf="recheckEnabled">
        <ng-container formGroupName="recheck">
          <div class="row mt-2">
            <div class="col-lg-6 no-form-field-padding">
              <app-date-picker-v2 label="Start Date" formControlName="startDate" [required]="recheckEnabled"></app-date-picker-v2>
            </div>
            <div class="col-lg-6 no-form-field-padding">
              <app-date-picker-v2
                label="End Date"
                formControlName="endDate"
                [required]="recheckEnabled"
                [min]="formGroup.get('recheck').get('startDate').value"
              ></app-date-picker-v2>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-6 no-form-field-padding">
              <app-autocomplete-chips
                label="Audiologists"
                formControlName="audiologistIds"
                [options]="audiologistOptions"
              ></app-autocomplete-chips>
            </div>
            <div class="col-lg-6 no-form-field-padding">
              <app-autocomplete-chips
                label="Audiometrists"
                formControlName="audiometristIds"
                [options]="audiometristOptions"
              ></app-autocomplete-chips>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="soundboothEnabled">
        <ng-container formGroupName="soundbooth">
          <div class="row mt-2">
            <div class="col-lg-6 no-form-field-padding">
              <app-autocomplete
                label="Location"
                formControlName="soundBoothLocationId"
                [options]="soundBoothLocationOptions"
              ></app-autocomplete>
            </div>
            <div class="col-lg-6 no-form-field-padding">
              <app-date-picker-v2 label="Date" formControlName="date" [required]="soundboothEnabled"></app-date-picker-v2>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-6 no-form-field-padding">
              <app-timepicker label="Start Time" formControlName="startTime"></app-timepicker>
            </div>
            <div class="col-lg-6 no-form-field-padding">
              <app-timepicker label="End Time" formControlName="endTime"></app-timepicker>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-lg-6 no-form-field-padding">
              <app-autocomplete-chips
                label="Audiologists"
                formControlName="audiologistIds"
                [options]="audiologistOptions"
              ></app-autocomplete-chips>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <div class="row mt-2" *ngIf="screeningEnabled || recheckEnabled">
        <div class="col-lg-12 no-form-field-padding">
          <app-textarea label="Notes" formControlName="notes" [noMaxLength]="true"></app-textarea>
        </div>
      </div>
    </ng-container>
  </mat-card>

  <div class="display-flex align-items-center justify-content-end mt-2">
    <button mat-raised-button aria-label="Cancel Button" (click)="onCancel()" class="mr-2" [disabled]="isSaving" tabindex="0">
      Cancel
    </button>
    <button
      *ngIf="formGroup.controls.appointmentTypeId.value"
      color="primary"
      mat-raised-button
      aria-label="Complete"
      (click)="onSave()"
      [disableAfterBusy]="formGroup.invalid"
      [disabled]="formGroup.invalid"
      [isBusy]="isSaving"
      tabindex="0"
    >
      Save
    </button>
  </div>
</ng-container>
