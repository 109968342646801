<main class="dashboard">
  <app-page-header [pageTitle]="title" [helpLinks]="false" [backgroundColor]="'blueDark'"></app-page-header>

  <div class="hide-print">
    <section class="mb-2" *ngIf="familyMembersNeedingValidation?.length > 0">
      <app-page-alert [alert]="alert" aria-live="polite">
        <ng-container alertActions>
          <button mat-raised-button color="primary" (click)="onNavigateToValidationDashboard()">Validation Dashboard</button>
        </ng-container>
      </app-page-alert>
    </section>

    <section>
      <app-user-case-list (uniqueLearnerDistricts)="uniqueLearnerDistricts = $event"></app-user-case-list>
    </section>

    <section class="mt-3" *ngIf="canViewPendingAppointments">
      <app-dhh-pending-appointments></app-dhh-pending-appointments>
    </section>

    <section class="calendar mt-3" *ngIf="canViewDhhCalendar">
      <app-dhh-calendar></app-dhh-calendar>
    </section>

    <section class="calendar mt-3" *ngIf="canViewAchieveCalendar">
      <app-user-calendar [uniqueLearnerDistricts]="uniqueLearnerDistricts"></app-user-calendar>
    </section>
  </div>
</main>

<div class="page-bottom-leeway"></div>
