<header class="mb-2">
  <app-page-header pageTitle="Pending Referrals" [helpLinks]="false" [backgroundColor]="'blue'"> </app-page-header>
</header>
<div class="mat-card">
  <app-text-field label="Filter" (keyup)="applyFilter($event)" placeholder="Ex. John" aria-labelledby="Filter"></app-text-field>
  <div class="table-overflow">
    <div class="table-overflow app-table">
      <table mat-table [dataSource]="dataSource" matSort class="w-100">
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" style="min-width: 60px">
            <ng-container>
              <button
                mat-icon-button
                aria-label="Delete"
                color="warn"
                title="Delete"
                type="button"
                (click)="onDelete(element.referralId)"
                tabindex="0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>
          </td>
        </ng-container>
        <ng-container matColumnDef="learnerName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Learner's Name</th>

          <td mat-cell *matCellDef="let element">
            <a
              [routerLink]="['/', 'child-find', 'early-access-referral', element['referralId'], 'child-select']"
              target="_self"
              tabindex="0"
              role="link"
            >
              {{ element.learnerName }}
            </a>
          </td>
        </ng-container>

        <ng-container matColumnDef="updatedOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Updated On</th>

          <td mat-cell *matCellDef="let element">
            {{ element.updatedOn | dateFormat }}
          </td>
        </ng-container>

        <ng-container matColumnDef="referralSourceName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Referral Source</th>

          <td mat-cell *matCellDef="let element">
            {{ element.referralSourceName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="aeaName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>AEA</th>

          <td mat-cell *matCellDef="let element">
            {{ element.aeaName }}
          </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef colspan="7">
            <i>No results...</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr mat-footer-row *matFooterRowDef="this.noResults ? ['noResults'] : []" class="example-second-footer-row"></tr>
      </table>
      <mat-paginator [dataSource]="dataSource"></mat-paginator>
    </div>
    <p *ngIf="!dataSource.data">No data yet...</p>
  </div>
</div>
