import {
  Component,
  OnChanges,
  forwardRef,
  HostBinding,
  Input,
  ViewChild,
  ElementRef,
  Inject,
  Host,
  Output,
  EventEmitter,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, FormControl, Validator } from '@angular/forms';
import { MatDatepicker } from '@angular/material/datepicker';
import { AchieveConfigService } from 'src/app/shared/services/achieve-config-service/achieve-config.service';
import { DateAdapter, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core';
import { Platform } from '@angular/cdk/platform';
import { formatDate } from '@angular/common';

export class CustomDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: any): string {
    return formatDate(date, 'MM/YYYY', this.locale);
  }
}

@Component({
  selector: 'app-date-picker-by-month',
  templateUrl: './date-picker-by-month.component.html',
  styleUrls: ['./date-picker-by-month.component.scss'],
  providers: [
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => DatePickerByMonthComponent),
      multi: true,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePickerByMonthComponent),
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: CustomDateAdapter,
      deps: [MAT_DATE_LOCALE, Platform],
    },
  ],
})
export class DatePickerByMonthComponent implements ControlValueAccessor, Validator {
  @ViewChild('datePickerControl', { read: ElementRef }) inputElement: ElementRef;

  _formControl = new FormControl();

  @Input() startView: 'month' | 'year' | 'multi-year' = 'month';
  @Input() startAt = new Date();
  @Input() min = new Date(1900, 0, 1);
  @Input() max: Date | null;
  @Input() hideAsterisk: boolean;
  @Input() extraClasses: string;
  @Input() showAsterisk = false;
  @Input() label = '';
  @Input() disabled? = false;
  @Input() required? = false;
  @Output() dateChange = new EventEmitter<Date>();
  formattedValue: string;
  klass: string;
  innerValue;

  constructor(private readonly achieveConfigService: AchieveConfigService) {}

  ngOnInit() {
    if (!this.klass) {
      this.klass = 'form-control';
    }
    if (this.extraClasses) {
      this.klass = `${this.klass} ${this.extraClasses}`;
    }
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  focus() {
    this.inputElement.nativeElement.focus();
  }

  writeValue(value: any) {
    this.innerValue = value;
    this._formControl.setValue(this.innerValue);
  }

  onDateChange(event) {
    this.onChange(event.value);
  }

  registerOnChange(fn: (value: any) => void) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
    if (isDisabled) {
      this._formControl.disable();
    } else {
      this._formControl.enable();
    }
  }

  validate(control: FormControl) {
    const errors = Object.assign({}, this._formControl.errors || {});
    return Object.keys(errors).length && this._formControl.invalid ? errors : null;
  }

  onBlur($event) {
    if ($event.target && $event.target.value && $event.target.value.length === 8 && !isNaN($event.target.value)) {
      const val: string = $event.target.value;
      const month = val.slice(0, 2);
      const day = val.slice(2, 4);
      const year = val.slice(4);
      this.innerValue = new Date(`${month}/${day}/${year}`);
      this._formControl.setValue(this.innerValue);
      this._formControl.updateValueAndValidity();
      this.onChange(this.innerValue);
    }
    if (this._formControl.hasError('matDatepickerParse')) {
      this._formControl.setValue(null);
      this._formControl.updateValueAndValidity();
    }

    this.onTouched();
  }

  onMonthSelected(event: Date, datepicker: MatDatepicker<any>) {
    datepicker.close();
    this.dateChange.emit(event);
  }
}
