import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { CaseUserRole, CaseUserSummary, IntakeType } from '../../models/case';
import { CaseService } from '../../services/case/case.service';

@Component({
  selector: 'app-shared-users',
  templateUrl: './shared-users.component.html',
  styleUrls: ['./shared-users.component.scss'],
})
export class SharedUsersComponent implements OnInit {
  @Input() caseUsers: CaseUserSummary[] = [];
  @Input() caseId: string;
  @Input() intakeType: IntakeType;
  intakeTypeEnum = IntakeType;
  sharedUsers: CaseUserSummary[] = [];

  public get canShare(): boolean {
    return this.authService.canShareCase(this.caseId, this.intakeType);
  }

  constructor(private readonly caseService: CaseService, private readonly authService: AuthService) {}

  ngOnInit(): void {
    this.sharedUsers = this.caseUsers.filter((x) => x.role === CaseUserRole.Shared);
  }

  onUnshareCase(user) {
    this.caseService.removeCaseUser(this.caseId, user.id).subscribe((res) => {
      const index = this.sharedUsers.indexOf(user);
      if (index > -1) {
        this.sharedUsers.splice(index, 1);
      }
    });
  }

  isMyself(caseSummary: CaseUserSummary) {
    return caseSummary.id === this.authService.user.id;
  }
}
