<app-page-header [pageTitle]="pageTitle" backgroundColor="blue"></app-page-header>

<ng-container *ngIf="pendingLearners">
  <app-dhh-learners-list
    [learners]="pendingLearners"
    [displayedColumns]="displayedColumns"
    (deleteEvent)="onDelete($event)"
    (updateEvent)="onUpdate($event)"
    (viewMoreEvent)="onViewMore($event)"
  ></app-dhh-learners-list>
</ng-container>
