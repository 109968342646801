<app-page-header
  [pageTitle]="iepHeaderTitle"
  backgroundColor="yellow"
  [helpLinks]="true"
  [condensed]="true"
  [shouldShowDhhBanner]="caseSummary?.learner.isDhh"
>
  <ng-container pageActions>
    <button
      *ngIf="caseSummary?.bipId"
      mat-flat-button
      aria-label="Open in new tab"
      (click)="onViewBip()"
      color="primary"
      class="mat-elevation-z0 mr-3"
      tabindex="0"
    >
      <mat-icon class="text-lg">open_in_new</mat-icon>
      View BIP
    </button>

    <app-create-output-button
      [output]="pdfOutputs.IepOutput"
      [apiId]="iepId"
      [learnerId]="caseSummary?.learnerId"
      [openNewIcon]="true"
      [isDraft]="true"
      buttonText="View IEP"
      buttonColor="primary"
      class="mr-2"
      buttonType="mat-flat-button"
    >
    </app-create-output-button>

    <app-create-output-button
      [output]="pdfOutputs.IepOutput"
      [apiId]="iepId"
      [learnerId]="caseSummary?.learnerId"
      [openNewIcon]="true"
      [isDraft]="true"
      [pushDraftToPortal]="true"
      buttonText="Push to Portal"
      buttonColor="primary"
      class="mr-2"
      buttonType="mat-flat-button"
      *ngIf="canPushToPortal"
    >
    </app-create-output-button>

    <button mat-flat-button aria-label="View CLP" routerLink="clp" color="primary" class="mat-elevation-z0 mr-3" tabindex="0">
      View CLP
    </button>

    <button
      mat-flat-button
      aria-label="View ECO Matrix"
      class="mr-2"
      color="primary"
      (click)="onOpenMatrix()"
      *ngIf="caseSummary?.evaluationId && !caseSummary?.evaluationFinalized && caseSummary?.learner?.isPK"
      tabindex="0"
    >
      <mat-icon class="text-lg" aria-label="Open in new tab">open_in_new</mat-icon>
      View ECO Matrix
    </button>
  </ng-container>
</app-page-header>
<app-dynamic-learner-header [learnerId]="caseSummary?.learnerId"></app-dynamic-learner-header>
<ng-container *ngIf="!isPopup">
  <app-iep-nav></app-iep-nav>
</ng-container>
