<app-page-header
  [pageTitle]="pageTitle"
  [helpLinks]="false"
  [backgroundColor]="'blue'"
  [shouldShowDhhBanner]="learnerSummary?.isDhh"
  [navBack]="true"
  (navBackToggled)="returnToSearch()"
>
  <ng-container pageActions> </ng-container>
</app-page-header>

<div class="mt-3">
  <app-learner-info-header [learnerSummary]="learnerSummary" [showLearnerLink]="isAllowedToContinueToDashboard"></app-learner-info-header>
  <div class="row">
    <div class="col">
      <mat-card class="detailed-search__actions-wrapper">
        <div class="row actions detailed-search__actions">
          <div class="detailed-search__button">
            <button mat-raised-button aria-label="Exit Search" color="primary" [routerLink]="['/']" tabindex="0">
              <i class="fas fa-chevron-left mr-2"></i>
              Exit Search
            </button>
          </div>
          <div class="detailed-search__button">
            <button mat-raised-button aria-label="Return to Results" color="primary" (click)="returnToSearch()" tabindex="0">
              Return to Results
              <i class="fas fa-not-equal ml-2"></i>
            </button>
          </div>
          <ng-container *ngIf="!!learnerSummary">
            <div class="detailed-search__button" *ngIf="isAllowedToContinueToDashboard && canContinueToDashboard">
              <button mat-raised-button aria-label="Go to Learner Dashboard" color="primary" (click)="continue()" tabindex="0">
                Go to Learner Dashboard
                <i class="fas fa-chevron-right ml-2"></i>
              </button>
            </div>
            <div class="detailed-search__button" *ngIf="canStartChildFind">
              <button mat-raised-button aria-label="Start Child Find" color="primary" (click)="startChildFind()" tabindex="0">
                Start Child Find
                <i class="fas fa-chevron-right ml-2"></i>
              </button>
            </div>
          </ng-container>
        </div>
      </mat-card>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <ng-container *ngIf="canViewLog">
        <mat-card class="card-spacing-top">
          <mat-card-title>Activity Log</mat-card-title>
          <mat-card-content>
            <div class="table-overflow">
              <table mat-table [dataSource]="activityLog">
                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef>Date</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.dateTime | dateFormat }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="actionType">
                  <th mat-header-cell *matHeaderCellDef>Action Type</th>
                  <td mat-cell *matCellDef="let element">
                    {{ activityLogLabels[element.actionType] }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="details">
                  <th mat-header-cell *matHeaderCellDef>Details</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.details || '--' }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="user">
                  <th mat-header-cell *matHeaderCellDef>User</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.user.fullName }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="['date', 'actionType', 'details', 'user']"></tr>
                <tr mat-row *matRowDef="let row; columns: ['date', 'actionType', 'details', 'user']"></tr>
              </table>
              <p *ngIf="!activityLog">No data yet...</p>

              <mat-paginator [dataSource]="activityLog"></mat-paginator>
            </div>
          </mat-card-content>
        </mat-card>
      </ng-container>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <mat-card class="card-spacing-top">
        <mat-card-title>Previous / Current IFSPs / IEPs</mat-card-title>
        <mat-card-content>
          <ng-container *ngFor="let caseSummary of activeCases">
            <mat-card class="py-2 card-spacing-bottom">
              <mat-card-content>
                <div class="row display-flex justify-content-between">
                  <div class="col-md-6 display-flex align-items-baseline">
                    <h3 class="mb-0 mt-0 mr-1">Active Case:</h3>
                    {{ caseSummary.intakeType === intakeTypeEnum.PartB ? 'Special Education' : 'Early ACCESS' }}
                  </div>
                  <div class="col-md-6 display-flex align-items-baseline">
                    <h3 class="mb-0 mt-0 mr-2">Created On:</h3>
                    {{ caseSummary.createdOn | dateFormat }}
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </ng-container>

          <ng-container *ngFor="let caseSummary of exitedCases">
            <app-case-exit-banner [caseSummary]="caseSummary"></app-case-exit-banner>
          </ng-container>

          <ng-container *ngIf="isAllowedToContinueToDashboard && canContinueToDashboard">
            <div class="action__row">
              <button mat-raised-button class="mr-2" color="primary" aria-label="Continue Button" (click)="goToLearnerDashboard()">
                Continue
              </button>
            </div>
          </ng-container>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
