import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { AreYouSureComponent } from '../../../../shared/components/are-you-sure-modal/are-you-sure.component';
import { NotificationService } from '../../../../shared/services/notification.service';
import { DisabilitySuspectFormNonAchieveParticipant } from '../../models/disability-suspect-form';
import { DisabilitySuspectService } from '../../services/disability-suspect.service';

@Component({
  selector: 'app-ds-nonachieve-team-member',
  templateUrl: './ds-nonachieve-team-member.component.html',
  styleUrls: ['./ds-nonachieve-team-member.component.scss'],
})
export class DsNonachieveTeamMemberComponent implements OnInit {
  @Input() dsFormId: string;
  @Input() nonAchieveParticipants: DisabilitySuspectFormNonAchieveParticipant[];

  nonAchiveFormGroup = new FormGroup({
    nonAchieveMember: new FormControl(null),
  });
  nonAchieveDataSource = new MatTableDataSource([]);
  nonAchieveDisplayedColumns = ['actions', 'name'];
  diableAddNonAchiveTeam = true;
  activeCall = false;

  constructor(private dsService: DisabilitySuspectService, private dialog: MatDialog, private notificationService: NotificationService) {}

  ngOnInit(): void {
    this.nonAchieveDataSource.data = this.nonAchieveParticipants;

    this.nonAchiveFormGroup.controls.nonAchieveMember.valueChanges.subscribe((value) => {
      this.diableAddNonAchiveTeam = value ? false : true;
    });
  }

  onAddNonAchieveMember() {
    if (this.nonAchiveFormGroup.valid) {
      const nonAchieveParticipant = {} as DisabilitySuspectFormNonAchieveParticipant;
      nonAchieveParticipant.disabilitySuspectFormId = this.dsFormId;
      nonAchieveParticipant.name = this.nonAchiveFormGroup.controls.nonAchieveMember.value;

      this.activeCall = true;
      this.dsService.saveNonAchieveParticipant(this.dsFormId, nonAchieveParticipant).subscribe(
        (operationResponse) => {
          this.activeCall = false;
          if (operationResponse.succeeded) {
            nonAchieveParticipant.id = operationResponse.value;

            this.nonAchieveParticipants = this.nonAchieveDataSource.data;
            this.nonAchieveParticipants.push(nonAchieveParticipant);
            this.nonAchieveDataSource.data = this.nonAchieveParticipants;

            this.notificationService.success('Saved!');

            this.nonAchiveFormGroup.reset();
          } else {
            this.notificationService.error('Failed to save.');
          }
        },
        () => {
          this.notificationService.error('Failed to save.');
        }
      );
    }
  }

  onRemoveNonAchieveMember(noneAchieveUserId: string) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: 'Clicking Yes will remove the Non-ACHIEVE Team Member.',
      },
    });

    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.dsService.deleteNonAchieveParticipant(this.dsFormId, noneAchieveUserId).subscribe(
          () => {
            this.nonAchieveParticipants = this.nonAchieveDataSource.data;
            this.nonAchieveParticipants = this.nonAchieveParticipants.filter((x) => x.id !== noneAchieveUserId);
            this.nonAchieveDataSource.data = this.nonAchieveParticipants;

            this.notificationService.success('Removed!');
          },
          () => {
            this.notificationService.error('Failed to remove.');
          }
        );
      }
    });
  }
}
