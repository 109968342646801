import { Component, Inject, Input, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { downloadBlob } from '../../../../../shared/downloadHelpers';
import { DhhScreeningSearchDto } from '../../../../models/DhhDtos';
import { DhhScreeningService } from '../../../dhh-screening/shared/dhh-screening.service';

@Component({
  selector: 'app-dhh-screening-recheck-print-running-list',
  templateUrl: './dhh-screening-recheck-print-running-list.component.html',
  styleUrls: ['./dhh-screening-recheck-print-running-list.component.scss'],
})
export class DhhScreeningRecheckPrintRunningListComponent implements OnInit {
  pageTitle = 'Print Runner List';
  displayedColumns = ['select', 'fullName', 'dateOfBirth'];
  dataSource = new MatTableDataSource<DhhScreeningSearchDto>();
  screeningLearners: DhhScreeningSearchDto[] = [];
  isPrinting = false;
  toggleAll = true;
  filtersData;
  fileName = 'RunnerList';

  get canPrint() {
    return this.screeningLearners.some((a) => a.selected);
  }

  constructor(
    private readonly dhhScreeningService: DhhScreeningService,
    public dialogRef: MatDialogRef<DhhScreeningRecheckPrintRunningListComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.screeningLearners = data.learners;
    this.filtersData = data.searchParams;
    this.fileName = data.fileName ?? this.fileName;
    this.screeningLearners?.forEach((a) => (a.selected = true));
    this.dataSource.data = this.screeningLearners;
  }

  ngOnInit(): void {}

  onToggleAll(event: any) {
    this.toggleAll = !this.toggleAll;
    const selected = event.checked ? true : false;
    this.screeningLearners.forEach((a) => (a.selected = selected));
  }

  onToggleElement(element: DhhScreeningSearchDto) {
    this.toggleAll = false;
    element.selected = !element.selected;
  }

  onCancel() {
    this.closeModal(null);
  }

  onPrint() {
    this.isPrinting = true;
    const selectedIds = this.dataSource.data.filter((a) => a.selected)?.map((l) => l.learnerId);

    if (selectedIds.length === 0) {
      this.dhhScreeningService.handleError('Cannot generate running list output', {
        message: 'No learner was selected, hence no out can be generated.',
      });

      this.isPrinting = false;
      return;
    }

    this.dhhScreeningService.getRunningListOutput(selectedIds, this.filtersData).subscribe(
      (res) => {
        if (res) {
          downloadBlob(res, this.fileName);
          this.closeModal(null);
        }
      },
      (error) => {
        this.dhhScreeningService.handleError('Cannot generate PDF report', error);
      },
      () => {
        this.isPrinting = false;
      }
    );
  }

  private closeModal(passCriteria: any) {
    this.dialogRef.close(passCriteria);
  }
}
