import { Time } from '@angular/common';
import dayjs, { Dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';
import isLeapYear from 'dayjs/plugin/isLeapYear';

dayjs.extend(duration);
dayjs.extend(isLeapYear);

export const dateTimeFromTimeString = (date: Date, time: string): Date => {
  // expected format for time is 24H as: HH:MM
  let hours = Number(time.match(/^(\d+)/)[1]);
  const minutes = Number(time.match(/:(\d+)/)[1]);
  const AMPM = time.match(/\s(.*)$/)[1];
  if (AMPM === 'PM' && hours < 12) {
    hours = hours + 12;
  }
  if (AMPM === 'AM' && hours === 12) {
    hours = hours - 12;
  }
  let sHours = hours.toString();
  let sMinutes = minutes.toString();
  if (hours < 10) {
    sHours = '0' + sHours;
  }
  if (minutes < 10) {
    sMinutes = '0' + sMinutes;
  }

  const _time: Time = {
    hours: +sHours,
    minutes: +sMinutes,
  };

  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), _time.hours, _time.minutes);
};

export const weekDays = (min: Date, max: Date): number => {
  if (min.valueOf > max.valueOf) {
    throw new Error('Invalid date span');
  }
  max.setHours(0, 0, 0, 0);
  min.setHours(0, 0, 0, 0);
  const maxPadded = new Date(max.getFullYear(), max.getMonth(), max.getDate() + 1);

  const daysBetween = (maxPadded.getTime() - min.getTime()) / (1000 * 3600 * 24);
  const minDayOfWeek = min.getDay();
  const maxDayOfWeek = 6 - max.getDay();
  const weekdays = [0, 0, 1, 2, 3, 4, 5];
  const daysToRemove = weekdays[minDayOfWeek] + weekdays[maxDayOfWeek];
  // 1.4 is number of weekdays per week, i.e., (t / 7) * 5
  return Math.round((daysBetween + minDayOfWeek + maxDayOfWeek) / 1.4 - daysToRemove);
};

/**
 * Returns a new dayjs object that is the given number of weekdays in the future
 */
export const addWeekdays = (target: Dayjs, days: number): Dayjs => {
  let count = 0;
  let endDate = target;
  while (count < days) {
    endDate = endDate.add(1, 'day');
    while (endDate.toDate().getDay() === 0 || endDate.toDate().getDay() === 6) {
      endDate = endDate.add(1, 'day');
    }
    count++;
  }
  return endDate;
};

/**
 * Returns a time duration in hours and minutes for a given start and end time.
 */
export const timeDurationInHoursAndMinutes = (startTime: string, endTime: string): string => {
  if (!startTime || !endTime) {
    return '';
  }
  const start = dayjs(`${dayjs().format('MM/DD/YYYY')} ${startTime}`);
  const end = dayjs(`${dayjs().format('MM/DD/YYYY')} ${endTime}`);
  const durationDif = dayjs.duration(end.diff(start));

  return `${durationDif.hours()} hr:${`${durationDif.minutes()} min`.padStart(2, '0')}`;
};

/**
 * Returns a time duration in munites for a given start and end time.
 */
export const timeDurationInMinutes = (startTime: string, endTime: string): number => {
  if (!startTime || !endTime) {
    return 0;
  }
  const start = dayjs(`${dayjs().format('MM/DD/YYYY')} ${startTime}`);
  const end = dayjs(`${dayjs().format('MM/DD/YYYY')} ${endTime}`);
  const durationDif = dayjs.duration(end.diff(start));

  return durationDif.hours() * 60 + durationDif.minutes();
};

/**
 * Returns the number of days for a given min and max date values
 * @param min
 * @param max
 */
export const dateDurationInDays = (min: Date, max: Date): number => {
  if (min.valueOf > max.valueOf) {
    throw new Error('Invalid date span');
  }
  max.setHours(0, 0, 0, 0);
  min.setHours(0, 0, 0, 0);

  return (max.getTime() - min.getTime()) / (1000 * 3600 * 24);
};

export const shortDateFormat = 'MM/dd/yyyy';
export const longDateFormat = 'MMMM d, yyyy';

export const maxFrequencyValue = (
  increment: 'minutes' | 'hours' = 'minutes',
  howOften: 'per day' | 'per week' | 'per month' = 'per day'
) => {
  let maxValue = 15600;
  switch (howOften) {
    case 'per day':
      maxValue = increment === 'minutes' ? 600 : 10;
      break;
    case 'per week':
      maxValue = increment === 'minutes' ? 3000 : 50;
      break;
    case 'per month':
      maxValue = increment === 'minutes' ? 15600 : 260;
      break;
    default:
      maxValue = 15600;
      break;
  }
  return maxValue;
};

/**
 * Returns the number of leap years for a given min and max date values
 * @param min
 * @param max
 */
export const countLeapYears = (min: Date, max: Date): number => {
  let counter = 0,
    initialYear = min.getFullYear();
  let totalYears = max.getFullYear() - initialYear;

  while (totalYears >= 0) {
    if (dayjs().year(initialYear).isLeapYear()) {
      counter++;
    }
    totalYears--;
    initialYear++;
  }

  return counter;
};

/**
 * Returns the date as an MM/dd/yyyy string for a Date value
 * @param date
 */
export const getMonthDayYearString = (date: Date): string => {
  const formattedDate = new Date(date);
  const year = formattedDate.getFullYear();
  const month = formattedDate.getMonth() + 1;
  const day = formattedDate.getDate();
  return month + '/' + day + '/' + year;
};
