import { UserNameOnly } from './user-name-only';

export interface ActivityLogEntry {
  dateTime: string;
  actionType: string;
  user: UserNameOnly;
  details: string;
}

export enum ActivityLogLabels {
  SearchResultViewed = 'Searched',
  CaseCreatedFromReferral = 'Case Created From Referral',
  CaseCreated = 'Case Created',
}
