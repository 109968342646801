import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'early-access-referral',
    loadChildren: () => import('./early-access-referral/early-access-referral.module').then((mod) => mod.EarlyAccessReferralModule),
  },
  {
    path: 'location-search',
    loadChildren: () => import('./search/search.module').then((mod) => mod.SearchModule),
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then((mod) => mod.SearchModule),
  },
  {
    path: 'service-coordinator-assignment',
    loadChildren: () =>
      import('./service-coordinator-assignment/service-coordinator-assignment.module').then(
        (mod) => mod.ServiceCoordinatorAssignmentModule
      ),
  },
  {
    path: 'disability-suspect',
    loadChildren: () => import('./disability-suspect/disability-suspect.module').then((mod) => mod.DisabilitySuspectModule),
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ChildFindRoutingModule {}
