//create pipe that will split words based on capital letters

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'prettyEnum',
})
export class SplitCapitalsPipe implements PipeTransform {
  transform(value: string): string {
    return value.split(/(?=[A-Z])/).join(' ');
  }
}
