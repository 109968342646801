import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DeleteConfirmationData {
  question: string;
  skipReason: boolean;
  label: string;
}

@Component({
  selector: 'app-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrls: ['./delete-confirmation-modal.component.scss'],
})
export class DeleteConfirmationComponent implements OnInit {
  formGroup = new FormGroup({
    cancelReason: new FormControl('', Validators.required),
  });

  get cancelReason() {
    return this.formGroup.get('cancelReason').value;
  }
  constructor(public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: DeleteConfirmationData) {}
  ngOnInit(): void {
    if (this.data?.skipReason) {
      this.formGroup.get('cancelReason').setValidators(null);
    }
  }

  dismiss() {
    this.dialogRef.close();
  }

  deleteItem() {
    this.dialogRef.close({
      delete: true,
      cancelReason: this.cancelReason,
    });
  }

  get label() {
    return this.data.label || 'Cancellation Rationale';
  }
}
