<form (submit)="onAddNonAchieveMember()">
  <ng-container [formGroup]="nonAchiveFormGroup">
    <div class="row mt-4">
      <div class="col-md-12 col-xl-10">
        <app-text-field label="Non-ACHIEVE Team Member" formControlName="nonAchieveMember"></app-text-field>
      </div>
      <div class="col-md-12 col-xl-2 display-flex justify-content-end align-items-center">
        <button
          mat-raised-button
          aria-label="Add"
          color="primary"
          class="ml-3"
          [disabled]="diableAddNonAchiveTeam"
          [isBusy]="activeCall"
          tabindex="0"
        >
          Add
        </button>
      </div>
    </div>
  </ng-container>
</form>

<div class="table-overflow app-table">
  <table mat-table [dataSource]="nonAchieveDataSource" class="w-100" matSort>
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let element">
        <button mat-icon-button aria-label="Close Button" (click)="onRemoveNonAchieveMember(element.id)" tabindex="0">
          <mat-icon class="text-danger">close</mat-icon>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Non-ACHIEVE Team Member</th>
      <td mat-cell *matCellDef="let element">
        {{ element.name }}
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="7">
        <i>No results...</i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="nonAchieveDisplayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: nonAchieveDisplayedColumns"></tr>
    <tr
      [hidden]="nonAchieveDataSource?.data?.length > 0"
      mat-footer-row
      *matFooterRowDef="nonAchieveDataSource.data?.length === 0 ? ['noResults'] : []"
    ></tr>
  </table>
  <mat-paginator [dataSource]="nonAchieveDataSource"></mat-paginator>
</div>
