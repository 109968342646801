import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-modify-existing-document',
  templateUrl: './modify-existing-document.component.html',
  styleUrls: ['./modify-existing-document.component.scss'],
})
export class ModifyExistingDocumentComponent implements OnInit {
  formGroup = this.fb.group({ id: ['', Validators.required], title: ['', Validators.required] });

  constructor(
    private dialogRef: MatDialogRef<ModifyExistingDocumentComponent>,
    @Inject(MAT_DIALOG_DATA) public document: any,
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.formGroup.patchValue({ id: this.document?.id });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    this.dialogRef.close(this.formGroup.value);
  }
}
