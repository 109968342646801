import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AppMatModule } from '../shared/app-mat.module';
import { SharedModule } from '../shared/shared.module';
import { ChildFindRoutingModule } from './child-find-routing.module';
import { DisabilitySuspectModule } from './disability-suspect/disability-suspect.module';
import { EarlyAccessReferralModule } from './early-access-referral/early-access-referral.module';
import { FiieConsentFormComponent } from './fiie-consent/fiie-consent-form.component';
import { ShareCaseComponent } from './modals/share-case/share-case.component';
import { AeaTransferComponent } from './modals/transfer-case/aea-transfer/aea-transfer.component';
import { ServiceCoordinatorsTransferComponent } from './modals/transfer-case/service-coordinators-transfer/service-coordinators-transfer.component';
import { TransferCaseComponent } from './modals/transfer-case/transfer-case.component';
import { SearchRoutingModule } from './search/search-routing.module';
import { ServiceCoordinatorAssignmentModule } from './service-coordinator-assignment/service-coordinator-assignment.module';
import { ShareIvrsCaseComponent } from './modals/share-ivrs-case/share-ivrs-case.component';
import { TransferIvrsCaseComponent } from './modals/transfer-ivrs-case/transfer-ivrs-case.component';

@NgModule({
  imports: [
    AppMatModule,
    ChildFindRoutingModule,
    CommonModule,
    DisabilitySuspectModule,
    EarlyAccessReferralModule,
    ReactiveFormsModule,
    SearchRoutingModule,
    ServiceCoordinatorAssignmentModule,
    SharedModule,
  ],
  declarations: [
    FiieConsentFormComponent,
    AeaTransferComponent,
    ServiceCoordinatorsTransferComponent,
    ShareCaseComponent,
    TransferCaseComponent,
    ShareIvrsCaseComponent,
    TransferIvrsCaseComponent,
  ],
  exports: [],
})
export class ChildFindModule {}
