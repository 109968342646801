import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { LearnerSummary } from 'src/app/shared/models/learner';
import { FirstNamePossessivePipe } from 'src/app/shared/pipes/first-name-possessive.pipe';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { MoveInDetailService } from '../move-in-details/move-in-detail.service';
import { AchieveConfigService } from '../../../shared/services/achieve-config-service/achieve-config.service';

@Component({
  selector: 'app-manage-migration-details',
  templateUrl: './manage-migration-details.component.html',
  providers: [FirstNamePossessivePipe],
})
export class ManageMigrationDetailsComponent implements OnInit {
  formGroup: FormGroup;
  activeCall = false;

  get learnersName() {
    return this.firstNamePossessivePipe.transform(this.data.firstName);
  }

  get hideEligibleInLegacySystem() {
    return this.achieveConfigService?.settings?.featureFlags?.hideEligibleInLegacySystem;
  }

  constructor(
    public dialogRef: MatDialogRef<ManageMigrationDetailsComponent>,
    @Inject(MAT_DIALOG_DATA) private readonly data: LearnerSummary,
    private readonly fb: FormBuilder,
    private readonly notificationService: NotificationService,
    private readonly learnerService: LearnerService,
    private readonly firstNamePossessivePipe: FirstNamePossessivePipe,
    private readonly moveInDetailService: MoveInDetailService,
    private readonly achieveConfigService: AchieveConfigService
  ) {}

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      eligibleInLegacySystem: !!this.data.eligibleInLegacySystem,
      movingFromOutOfState: !!this.data.movingFromOutOfState,
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSubmit() {
    if (this.formGroup.pristine) {
      this.dialogRef.close();
      return;
    }
    const v = this.formGroup.value;
    this.learnerService.updateMigrationDetails(this.data.id, v.eligibleInLegacySystem, v.movingFromOutOfState).subscribe((res) => {
      if (this.notificationService.showResponseMessage(res)) {
        this.moveInDetailService.moveInDetailsUpdated.next(this.data.id);
        this.notificationService.success('Migration details updated successfully.');
        this.dialogRef.close();
      }
    });
  }
}
