import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { StringSizes } from 'src/app/shared/components/form/constants/constants';
import { AlertConfig } from 'src/app/shared/components/page-alert/page-alert.component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { IntakeType } from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { Surrogate } from 'src/app/shared/models/surrogate';
import { Team, TeamUser } from 'src/app/shared/models/team';
import { checkForAlert, createAlertConfig } from 'src/app/shared/pageAlertHelper';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { DsDecisionHelp, DsFormHelp } from 'src/app/shared/services/help/models/ds.help';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { FamilyMember } from '../../../shared/models/learner';
import { HelpModalConfig, HelpService } from '../../../shared/services/help/help.service';
import { DisabilitySuspectForm } from '../models/disability-suspect-form';

const submitValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const diagnosisOfCondition = control.get('conditions.diagnosisOfCondition.answer').value;
  const diagnosisOfConditionComments = control.get('conditions.diagnosisOfCondition.comments');
  const uniqueComparedToPeers = control.get('conditions.uniqueComparedToPeers.answer').value;
  const uniqueComparedToPeersComments = control.get('conditions.uniqueComparedToPeers.comments');
  const progressionStandards = control.get('conditions.progressionStandards.answer').value;
  const progressionStandardsComments = control.get('conditions.progressionStandards.comments');
  const requiresContinuedEffort = control.get('conditions.requiresContinuedEffort.answer').value;
  const requiresContinuedEffortComments = control.get('conditions.requiresContinuedEffort.comments');

  if (diagnosisOfCondition && diagnosisOfConditionComments.value === '') {
    return { diagnosisOfConditionCommentRequired: true };
  } else if (uniqueComparedToPeers && uniqueComparedToPeersComments.value === '') {
    return { uniqueComparedToPeersCommentRequired: true };
  } else if (progressionStandards && progressionStandardsComments.value === '') {
    return { progressionStandardsCommentRequired: true };
  } else if (requiresContinuedEffort && requiresContinuedEffortComments.value === '') {
    return { requiresContinuedEffortCommentRequired: true };
  }

  if (
    diagnosisOfCondition === null &&
    uniqueComparedToPeers === null &&
    progressionStandards === null &&
    requiresContinuedEffort === null
  ) {
    return { oneAnswerRequired: true };
  }

  if (
    diagnosisOfCondition === false &&
    uniqueComparedToPeers === false &&
    progressionStandards === false &&
    requiresContinuedEffort === false
  ) {
    const rationaleComments = control.get('conditions.rationaleComments');
    if (rationaleComments.value === '') {
      return { rationaleCommentsRequired: true };
    } else {
      return null;
    }
  }

  if (diagnosisOfCondition || uniqueComparedToPeers || progressionStandards || requiresContinuedEffort) {
    return null;
  }

  return { invalid: true };
};

@Component({
  selector: 'app-ds-form',
  templateUrl: './ds-form.component.html',
  styleUrls: ['./ds-form.component.scss'],
})
export class DsFormComponent implements OnInit, OnChanges, OnDestroy {
  private subscriptions = new Array<Subscription>();
  @Input() learnerId: string;
  @Input() familyMembers: FamilyMember[];
  @Input() surrogates: Surrogate[];
  @Input() dsForm: DisabilitySuspectForm;
  @Input() activeCall = false;
  @Output() formChange = new EventEmitter();
  @Output() submitEvent = new EventEmitter<FormGroup>();
  @Output() saveAndClose = new EventEmitter<FormGroup>();
  @Output() teamUserRemovedEvent = new EventEmitter<TeamUser>();
  @ViewChild('rationaleCommentsEl') rationaleCommentsEl: ElementRef;

  formGroup: FormGroup;
  submitFired = false;

  pwnNotificationSent = false;
  intakeType = IntakeType;
  tabs: {
    message: string;
    check: boolean;
  }[];
  familyMemberOptions: KeyValuePair[];

  shortDateFormat = shortDateFormat;
  dsFormHelp = DsFormHelp;
  dsDecisionHelp = DsDecisionHelp;
  helpSection = HelpSection;

  displayedColumns = ['actions', 'createdBy', 'createdOn', 'comment'];

  yesNoOptions = [
    { label: 'Yes', value: true },
    { label: 'No', value: false },
  ];

  caseOwner: string;

  stepperHasChanged = {
    diagnosis: false,
    uniquePeers: false,
    progressMeeting: false,
    requiresEffort: false,
    comments: false,
  };

  userId: string;
  editingId: any;

  statusChecked = false;

  pagesIncomplete: AlertConfig = {
    status: null,
    message: null,
  };

  rationaleCommentLabel = 'If the team has responded "No" to all items above, provide a rationale for the decision not to suspect';

  //#region getters
  get diagnosisOfCondition(): FormGroup {
    return this.conditions.get('diagnosisOfCondition') as FormGroup;
  }

  get diagnosisOfConditionAnswer(): FormControl {
    return this.diagnosisOfCondition.get('answer') as FormControl;
  }

  get diagnosisOfConditionComments(): FormControl {
    return this.diagnosisOfCondition.get('comments') as FormControl;
  }

  get isDiagnosisOfConditionValid(): boolean {
    return this.diagnosisOfCondition.valid && this.diagnosisOfConditionAnswer.value !== null;
  }

  get uniqueComparedToPeers(): FormGroup {
    return this.conditions.get('uniqueComparedToPeers') as FormGroup;
  }

  get uniqueComparedToPeersAnswer(): FormControl {
    return this.uniqueComparedToPeers.get('answer') as FormControl;
  }

  get uniqueComparedToPeersComments(): FormControl {
    return this.uniqueComparedToPeers.get('comments') as FormControl;
  }

  get isUniqueComparedToPeersValid(): boolean {
    return this.uniqueComparedToPeers.valid && this.uniqueComparedToPeersAnswer.value !== null;
  }

  get progressionStandards(): FormGroup {
    return this.conditions.get('progressionStandards') as FormGroup;
  }

  get progressionStandardsAnswer(): FormControl {
    return this.progressionStandards.get('answer') as FormControl;
  }

  get progressionStandardsComments(): FormControl {
    return this.progressionStandards.get('comments') as FormControl;
  }

  get isProgressionStandardsValid(): boolean {
    return this.progressionStandards.valid && this.progressionStandardsAnswer.value !== null;
  }

  get requiresContinuedEffort(): FormGroup {
    return this.conditions.get('requiresContinuedEffort') as FormGroup;
  }

  get requiresContinuedEffortAnswer(): FormControl {
    return this.requiresContinuedEffort.get('answer') as FormControl;
  }

  get requiresContinuedEffortComments(): FormControl {
    return this.requiresContinuedEffort.get('comments') as FormControl;
  }

  get isRequiresContinuedEffortValid(): boolean {
    return this.requiresContinuedEffort.valid && this.requiresContinuedEffortAnswer.value !== null;
  }

  get rationaleComments() {
    return this.conditions.get('rationaleComments') as FormControl;
  }

  get parentOriginated() {
    return this.formGroup.get('parentOriginated') as FormControl;
  }

  get parentOriginatedInvalid() {
    return (this.parentOriginated.value === null || this.parentOriginated.invalid) && this.stepperHasChanged;
  }

  get conditions(): FormGroup {
    return this.formGroup.get('conditions') as FormGroup;
  }

  get hasConditionSelected(): boolean {
    return (
      this.diagnosisOfConditionAnswer.value ||
      this.uniqueComparedToPeersAnswer.value ||
      this.progressionStandardsAnswer.value ||
      this.requiresContinuedEffortAnswer.value
    );
  }

  get requiresRationaleComments(): boolean {
    return (
      this.diagnosisOfConditionAnswer.value === false &&
      this.uniqueComparedToPeersAnswer.value === false &&
      this.progressionStandardsAnswer.value === false &&
      this.requiresContinuedEffortAnswer.value === false
    );
  }

  // endregion

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private helpService: HelpService,
    private notificationService: NotificationService
  ) {
    this.initializeForm();
  }

  updateFamilyParticipant(familyMember: FamilyMember, event: boolean) {
    const familyParticipants = this.formGroup.get('familyParticipants') as FormArray;
    if (event) {
      familyParticipants.push(this.fb.control(familyMember));
    } else {
      familyParticipants.controls.forEach((control) => {
        const value = control.value as FamilyMember;
        if (value.id === familyMember.id) {
          familyParticipants.removeAt(familyParticipants.controls.indexOf(control));
        }
      });
    }
    this.formChange.emit(this.formGroup);
  }

  updateSurrogateParticipant(surrogate: Surrogate, event: boolean) {
    const surrogateParticipants = this.formGroup.get('surrogateParticipants') as FormArray;
    if (event) {
      surrogateParticipants.push(this.fb.control(surrogate));
    } else {
      surrogateParticipants.controls.forEach((control) => {
        const value = control.value as Surrogate;
        if (value.id === surrogate.id) {
          surrogateParticipants.removeAt(surrogateParticipants.controls.indexOf(control));
        }
      });
    }
    this.formChange.emit(this.formGroup);
  }

  ngOnInit() {
    this.userId = this.authService.user.id;
    this.caseOwner = this.dsForm.caseOwnerId;
    if (this.dsForm.participants.length > 0) {
      this.initializeParticipants(this.dsForm.participants);
    }
    this.subscriptions.push(
      this.formGroup.valueChanges.pipe(debounceTime(3000)).subscribe(() => {
        if (this.submitFired) return;
        if (this.formGroup.dirty) {
          this.formChange.emit(this.formGroup);
        }
        // Only update alerts if there is a change in value (object equality of tabs)
        if (JSON.stringify(this.getMessageTabsForAlert()) !== JSON.stringify(this.tabs)) {
          this.createAlert();
        }
        if (this.requiresRationaleComments) {
          this.rationaleComments.enable({ emitEvent: false });
          if (!this.pwnNotificationSent) {
            this.notificationService.alert('Selecting "no" for all conditions requires the creation of a PWN.', null, () => {
              if (this.rationaleCommentsEl) {
                this.rationaleCommentsEl.nativeElement.scrollIntoView({
                  behavior: 'smooth',
                  block: 'start',
                  inline: 'start',
                  alignToTop: true,
                });
              }
            });
            this.pwnNotificationSent = true;
          }
        } else {
          this.rationaleComments.disable({ emitEvent: false });
          this.pwnNotificationSent = false;
        }
      })
    );

    this.initializeConditions();

    this.formGroup.setValidators([submitValidator]);
    this.formGroup.updateValueAndValidity();
  }

  private initializeForm(): void {
    this.formGroup = this.fb.group({
      participants: this.fb.array([]),
      familyParticipants: this.fb.array([]),
      parentOriginated: [''],
      conditions: this.getConditions(),
    });
  }

  private getConditions(): FormGroup {
    return this.fb.group({
      diagnosisOfCondition: this.getFormQuestion(),
      uniqueComparedToPeers: this.getFormQuestion(),
      progressionStandards: this.getFormQuestion(),
      requiresContinuedEffort: this.getFormQuestion(),
      rationaleComments: [{ value: '', disabled: true }, Validators.required],
    });
  }

  private getFormQuestion(): FormGroup {
    return this.fb.group({
      answer: [null],
      comments: ['', [Validators.required, Validators.maxLength(StringSizes.extraLarge)]],
    });
  }

  private initializeConditions(): void {
    if (this.diagnosisOfConditionAnswer.value) {
      this.diagnosisOfConditionComments.enable();
      if (!this.diagnosisOfConditionComments.valid) {
        this.diagnosisOfConditionComments.markAsTouched();
      }
    } else {
      this.diagnosisOfConditionComments.disable();
    }

    if (this.uniqueComparedToPeersAnswer.value) {
      this.uniqueComparedToPeersComments.enable();
      if (!this.uniqueComparedToPeersComments.valid) {
        this.uniqueComparedToPeersComments.markAsTouched();
      }
    } else {
      this.uniqueComparedToPeersComments.disable();
    }

    if (this.progressionStandardsAnswer.value) {
      this.progressionStandardsComments.enable();
      if (!this.progressionStandardsComments.valid) {
        this.progressionStandardsComments.markAsTouched();
      }
    } else {
      this.progressionStandardsComments.disable();
    }

    if (this.requiresContinuedEffortAnswer.value) {
      this.requiresContinuedEffortComments.enable();
      if (!this.requiresContinuedEffortComments.valid) {
        this.requiresContinuedEffortComments.markAsTouched();
      }
    } else {
      this.requiresContinuedEffortComments.disable();
    }

    if (this.requiresRationaleComments && !this.rationaleComments.valid) {
      this.rationaleComments.markAsTouched();
    }

    this.subscriptions.push(
      this.diagnosisOfConditionAnswer.valueChanges.subscribe((value) => {
        if (value) {
          this.diagnosisOfConditionComments.enable();
        } else {
          this.diagnosisOfConditionComments.disable();
        }
      })
    );

    this.subscriptions.push(
      this.uniqueComparedToPeersAnswer.valueChanges.subscribe((value) => {
        if (value) {
          this.uniqueComparedToPeersComments.enable();
        } else {
          this.uniqueComparedToPeersComments.disable();
        }
      })
    );

    this.subscriptions.push(
      this.progressionStandardsAnswer.valueChanges.subscribe((value) => {
        if (value) {
          this.progressionStandardsComments.enable();
        } else {
          this.progressionStandardsComments.disable();
        }
      })
    );

    this.subscriptions.push(
      this.requiresContinuedEffortAnswer.valueChanges.subscribe((value) => {
        if (value) {
          this.requiresContinuedEffortComments.enable();
        } else {
          this.requiresContinuedEffortComments.disable();
        }
      })
    );
  }

  familyMemberIsParticipant(familyMember: FamilyMember) {
    return !!this.dsForm.familyParticipants.find((x) => x.id === familyMember.id);
  }

  surrogateIsParticipant(surrogate: Surrogate) {
    return !!this.dsForm.surrogateParticipants.find((x) => x.id === surrogate.id);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.activeCall?.currentValue !== changes.activeCall?.previousValue) {
      this.activeCall = changes.activeCall?.currentValue;
    }

    if (changes.familyMembers && changes.familyMembers.currentValue) {
      this.familyMemberOptions = changes.familyMembers.currentValue.map((m) => new KeyValuePair(m.id, m.fullName));
    }

    if (changes.dsForm?.firstChange) {
      this.formGroup.patchValue({
        ...this.dsForm,
        conditions: {
          diagnosisOfCondition: {
            answer: this.dsForm.diagnosisOfCondition,
            comments: this.dsForm.diagnosisOfConditionComments,
          },
          uniqueComparedToPeers: {
            answer: this.dsForm.uniqueComparedToPeers,
            comments: this.dsForm.uniqueComparedToPeersComments,
          },
          progressionStandards: {
            answer: this.dsForm.progressionStandards,
            comments: this.dsForm.progressionStandardsComments,
          },
          requiresContinuedEffort: {
            answer: this.dsForm.requiresContinuedEffort,
            comments: this.dsForm.requiresContinuedEffortComments,
          },
          rationaleComments: this.dsForm.rationaleComments,
        },
      });
      const toParticipantFormArray = (users: TeamUser[]) => this.fb.array(users.map((p) => this.fb.group({ ...p })));
      const toFamilyParticipantFormArray = (users: any[]) => this.fb.array(users.map((p) => this.fb.group({ ...p })));
      const toSurrogateParticipantFormArray = (users: any[]) => this.fb.array(users.map((p) => this.fb.group({ ...p })));

      this.formGroup.controls.familyParticipants = toFamilyParticipantFormArray(this.dsForm.familyParticipants || []);

      this.formGroup.controls.surrogateParticipants = toSurrogateParticipantFormArray(this.dsForm.surrogateParticipants || []);

      this.formGroup.controls.participants = toParticipantFormArray(this.dsForm.participants || []);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((x) => x.unsubscribe());
  }

  onSubmit() {
    this.submitEvent.emit(this.formGroup);
    this.submitFired = true;
  }

  onClearRadio(formControlName: string) {
    this.formGroup.get(formControlName).setValue(null);
  }

  initializeParticipants(participants) {
    const participantsFormGroup = this.formGroup.get('participants') as FormArray;

    participantsFormGroup.controls = [];
    for (let i = 0; i < participants.length; i++) {
      participantsFormGroup.controls[i] = new FormControl([]);
    }
    participantsFormGroup.setValue([...participants]);
  }

  updateParticipants(team: Team) {
    const participantsFormGroup = this.formGroup.get('participants') as FormArray;
    participantsFormGroup.controls = [];
    for (let i = 0; i < team.users.length; i++) {
      participantsFormGroup.controls[i] = new FormControl([]);
    }
    participantsFormGroup.setValue([...team.users]);
    this.formChange.emit(this.formGroup);
  }

  removeTeamUser(teamUser: TeamUser) {
    this.teamUserRemovedEvent.emit(teamUser);
  }

  onStepperSelectionChange(change: StepperSelectionEvent) {
    change.previouslySelectedStep.stepControl.markAllAsTouched();

    switch (change.previouslySelectedIndex) {
      case 0:
        this.stepperHasChanged.diagnosis = true;
        break;
      case 1:
        this.stepperHasChanged.uniquePeers = true;
        break;
      case 2:
        this.stepperHasChanged.progressMeeting = true;
        break;
      case 3:
        this.stepperHasChanged.requiresEffort = true;
        break;
      case 4:
        this.stepperHasChanged.comments = true;
        break;
    }

    this.createAlert();
  }

  onCheckStatus() {
    this.statusChecked = !this.statusChecked;
  }

  getMessageTabsForAlert() {
    return [
      {
        message: 'Diagnosis of Condition',
        check: this.diagnosisOfCondition.valid,
      },
      {
        message: 'Unique Compared to Peers',
        check: this.uniqueComparedToPeers.valid,
      },
      {
        message: 'Progression and Meeting Standards',
        check: this.progressionStandards.valid,
      },
      {
        message: 'Requires Continued Substantial Effort',
        check: this.requiresContinuedEffort.valid,
      },
    ];
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm) {
    e.preventDefault();

    const dictionary = this.helpService.getDsFormDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      item,
      section,
      canBrowse: true,
    } as HelpModalConfig);
  }

  createAlert(): void {
    this.tabs = this.getMessageTabsForAlert();
    const invalid = checkForAlert(this.tabs);
    this.pagesIncomplete = createAlertConfig('Incomplete Page(s): ', 'warning', invalid);
  }

  onFromTransition() {
    // if transition doesn't have values and the ds form does then don't erase that option
    if (!this.dsForm.transitionValues.diagnosisOfCondition === null) {
      this.dsForm.transitionValues.diagnosisOfCondition = this.diagnosisOfConditionAnswer?.value;
    }
    if (!this.dsForm.transitionValues.diagnosisOfConditionComments) {
      this.dsForm.transitionValues.diagnosisOfConditionComments = this.diagnosisOfConditionComments?.value;
    }
    if (!this.dsForm.transitionValues.uniqueComparedToPeers === null) {
      this.dsForm.transitionValues.uniqueComparedToPeers = this.uniqueComparedToPeersAnswer?.value;
    }
    if (!this.dsForm.transitionValues.uniqueComparedToPeersComments) {
      this.dsForm.transitionValues.uniqueComparedToPeersComments = this.uniqueComparedToPeersComments?.value;
    }
    if (!this.dsForm.transitionValues.progressionStandards === null) {
      this.dsForm.transitionValues.progressionStandards = this.progressionStandardsAnswer?.value;
    }
    if (!this.dsForm.transitionValues.progressionStandardsComments) {
      this.dsForm.transitionValues.progressionStandardsComments = this.progressionStandardsComments?.value;
    }
    if (!this.dsForm.transitionValues.requiresContinuedEffort === null) {
      this.dsForm.transitionValues.requiresContinuedEffort = this.requiresContinuedEffortAnswer?.value;
    }
    if (!this.dsForm.transitionValues.requiresContinuedEffortComments) {
      this.dsForm.transitionValues.requiresContinuedEffortComments = this.requiresContinuedEffortComments?.value;
    }
    this.formGroup.patchValue({
      conditions: {
        diagnosisOfCondition: {
          answer: this.dsForm.transitionValues.diagnosisOfCondition,
          comments: this.dsForm.transitionValues.diagnosisOfConditionComments,
        },
        uniqueComparedToPeers: {
          answer: this.dsForm.transitionValues.uniqueComparedToPeers,
          comments: this.dsForm.transitionValues.uniqueComparedToPeersComments,
        },
        progressionStandards: {
          answer: this.dsForm.transitionValues.progressionStandards,
          comments: this.dsForm.transitionValues.progressionStandardsComments,
        },
        requiresContinuedEffort: {
          answer: this.dsForm.transitionValues.requiresContinuedEffort,
          comments: this.dsForm.transitionValues.requiresContinuedEffortComments,
        },
      },
    });
    this.formGroup.markAsDirty();
  }
}
