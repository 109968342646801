<app-page-header
  *ngIf="caseSummary"
  [pageTitle]="'Service Log - ' + caseSummary.learner.fullName"
  backgroundColor="yellow"
  [helpLinks]="true"
  [condensed]="true"
  [shouldShowDhhBanner]="caseSummary?.learner.isDhh"
></app-page-header>

<app-dynamic-learner-header [learnerId]="caseSummary?.learnerId"></app-dynamic-learner-header>

<ng-container [formGroup]="serviceLogFormGroup">
  <mat-card class="card-spacing-top py-2">
    <div class="row align-items-baseline">
      <div class="col-md-12 col-lg-6 col-xl-4">
        <app-date-picker [max]="sessionMaxDate" controlName="sessionDate" label="Session Date"></app-date-picker>
      </div>
      <div class="col-md-12 col-lg-6 col-xl-4">
        <app-autocomplete label="Child Location" formControlName="childLocation" [options]="childLocationOptions"></app-autocomplete>
      </div>
      <div class="col-md-12 col-lg-6 col-xl-4 display-flex justify-content-end">
        <app-checkbox-single
          formControlName="appointmentDidNotHappen"
          aria-labelledby="Appointment Didn't Happen"
          label="Appointment Didn't Happen"
        >
        </app-checkbox-single>
      </div>
      <div class="col-md-12 col-lg-6 col-xl-4" *ngIf="serviceLogFormGroup.get('appointmentDidNotHappen').value">
        <app-autocomplete
          label="Reason"
          formControlName="appointmentDidNotHappenReason"
          [options]="appointmentDidNotHappenReasonOptions"
        ></app-autocomplete>
      </div>
      <div
        class="col-md-12 col-lg-6 col-xl-4"
        *ngIf="
          serviceLogFormGroup.get('appointmentDidNotHappen').value &&
          serviceLogFormGroup.get('appointmentDidNotHappenReason').value === 'Other'
        "
      >
        <app-text-field label="Describe Other" formControlName="appointmentDidNotHappenReasonOther"></app-text-field>
      </div>
    </div>
  </mat-card>

  <ng-container [formGroup]="serviceDetailFormGroup" *ngIf="editing">
    <mat-card class="card-spacing-top py-2">
      <div class="row align-items-baseline">
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-autocomplete label="Service" formControlName="serviceId" [options]="servicesOptions"> </app-autocomplete>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-timepicker label="Start Time" formControlName="startTime"></app-timepicker>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-4" *ngIf="!serviceLogFormGroup.get('appointmentDidNotHappen').value">
          <app-timepicker label="End Time" formControlName="endTime"></app-timepicker>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-4" *ngIf="!serviceLogFormGroup.get('appointmentDidNotHappen').value">
          <p class="my-0">
            <strong>Duration:</strong>
            {{ timeDuration }}
          </p>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-date-picker [min]="serviceDetailMinDate" label="Next Visit" controlName="nextVisit"></app-date-picker>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-4" *ngIf="!serviceLogFormGroup.get('appointmentDidNotHappen').value">
          <app-autocomplete
            label="How Services are Provided"
            formControlName="howServiceProvided"
            [options]="howServiceProvidedOptions"
          ></app-autocomplete>
          <ng-container *ngIf="serviceDetailFormGroup.get('howServiceProvided').value === 'Other'">
            <app-text-field label="Describe Other" formControlName="howServiceProvidedOther"></app-text-field>
          </ng-container>
        </div>
        <ng-container *ngIf="serviceDetailFormGroup.get('howServiceProvided').value === 'Virtual Conferencing'">
          <div class="col-md-12 col-lg-6 col-xl-4">
            <app-text-field
              label="Provider Location"
              formControlName="providerLocation"
              [isBankableField]="true"
              [bankableFieldId]="bankableFieldIds.ifpsServiceLogs.providerLocation"
            ></app-text-field>
          </div>
          <div class="col-md-12 col-lg-6 col-xl-4">
            <app-text-field
              label="Platform"
              formControlName="platform"
              [isBankableField]="true"
              [bankableFieldId]="bankableFieldIds.ifpsServiceLogs.platform"
            ></app-text-field>
          </div>
        </ng-container>
      </div>
      <div class="row">
        <div class="col-md-12">
          <app-textarea label="Comments" formControlName="comments" [rows]="2"></app-textarea>
        </div>
      </div>
      <div class="row" *ngIf="!hideLate && !appointmentDidNotHappen">
        <div class="col-md-12">
          <mat-panel-title><h3 class="my-0 text-normal">Reason for Delay in First Delivery</h3></mat-panel-title>
          <app-select formControlName="lateReasonId" [options]="lateReasonOptions"></app-select>
          <app-text-field
            *ngIf="serviceDetailFormGroup.controls.lateReasonId.value === otherLateReasonId"
            formControlName="lateReasonOther"
            label="Other Reason"
          ></app-text-field>
        </div>
      </div>

      <div class="action__row">
        <button
          mat-raised-button
          aria-label="Cancel Button"
          color="warn"
          (click)="onCancelServiceDetail()"
          [isBusy]="activeCall"
          tabindex="0"
        >
          Cancel
        </button>
        <button
          mat-raised-button
          aria-label="Save and Add Another"
          color="primary"
          class="ml-2"
          [disableAfterBusy]="serviceDetailFormGroup.invalid"
          [isBusy]="activeCall"
          [disabled]="serviceDetailFormGroup.invalid"
          (click)="onSaveServiceDetail(true)"
          tabindex="0"
        >
          Save and Add Another
        </button>
        <button
          mat-raised-button
          aria-label="Save Button"
          color="primary"
          class="ml-2"
          [disableAfterBusy]="serviceDetailFormGroup.invalid"
          [isBusy]="activeCall"
          [disabled]="serviceDetailFormGroup.invalid"
          (click)="onSaveServiceDetail()"
          tabindex="0"
        >
          Save
        </button>
      </div>
    </mat-card>
  </ng-container>

  <div class="action__row card-spacing-bottom">
    <button
      *ngIf="!readOnly && !editing"
      mat-raised-button
      aria-label="Add Service to the Same Session"
      color="primary"
      (click)="onAddNewServiceDetail()"
      tabindex="0"
    >
      Add Service to the Same Session
    </button>
  </div>
</ng-container>

<ng-container>
  <mat-card class="card-spacing-bottom mat-elevation-z0">
    <div class="table-overflow app-table">
      <table mat-table [dataSource]="serviceDetaildataSource" class="w-100" matSort>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element" class="mat-column-actions--more">
            <div class="display-flex align-items-center justify-content-start">
              <ng-container *ngIf="canEditServiceDetail(element)">
                <button mat-icon-button aria-label="Edit Icon" color="primary" (click)="onEditServiceDetail(element)" tabindex="0">
                  <mat-icon>edit</mat-icon>
                </button>
                <button mat-icon-button aria-label="Delete Icon" color="warn" (click)="onRemoveServiceDetail(element.id)" tabindex="0">
                  <mat-icon>close</mat-icon>
                </button>
              </ng-container>

              <button mat-icon-button aria-label="View Icon" color="primary" (click)="onViewMore(element)" tabindex="0">
                <mat-icon>remove_red_eye</mat-icon>
              </button>
            </div>
          </td>
        </ng-container>

        <ng-container matColumnDef="serviceId">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Service</th>
          <td mat-cell *matCellDef="let element">
            {{ serviceName(element.serviceId) }}
          </td>
        </ng-container>

        <ng-container matColumnDef="startTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Time</th>
          <td mat-cell *matCellDef="let element">
            {{ element.startTime }}
          </td>
        </ng-container>

        <ng-container matColumnDef="endTime">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>End Time</th>
          <td mat-cell *matCellDef="let element">
            {{ element.endTime }}
          </td>
        </ng-container>

        <ng-container matColumnDef="nextVisit">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Next Visit</th>
          <td mat-cell *matCellDef="let element">
            {{ element.nextVisit | dateFormat : 'medium' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="howServiceProvided">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>How Service Provided</th>
          <td mat-cell *matCellDef="let element">
            {{
              element.howServiceProvided === 'Virtual Conferencing'
                ? 'Virtual Conferencing, ' + element.providerLocation + ', ' + element.platform
                : element.howServiceProvided === 'Other'
                ? element.howServiceProvidedOther
                : element.howServiceProvided
            }}
          </td>
        </ng-container>

        <ng-container matColumnDef="comments">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Comments</th>
          <td mat-cell *matCellDef="let element">
            {{ element.comments | truncate : 150 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef colspan="7">
            <i>No service detail found.</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="serviceDetailDisplayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: serviceDetailDisplayedColumns"></tr>
        <tr
          [hidden]="serviceDetaildataSource?.data?.length > 0"
          mat-footer-row
          *matFooterRowDef="serviceDetaildataSource.data?.length === 0 ? ['noResults'] : []"
        ></tr>
      </table>
      <mat-paginator [dataSource]="serviceDetaildataSource"></mat-paginator>
    </div>
  </mat-card>
</ng-container>

<mat-accordion class="accordion accordion--short" multi *ngIf="!serviceLogFormGroup.get('appointmentDidNotHappen').value">
  <mat-expansion-panel class="mat-elevation-z0" [formGroup]="interventionFormGroup">
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Family Guided Routines Based Intervention Plan</h3></mat-panel-title>
    </mat-expansion-panel-header>

    <div class="row">
      <div class="col-md-12">
        <label class="vertical-label text-md display-block position-fix--label" for="outcomesToWorkOn">
          Select the IFSP Outcomes we plan to work on during and between the visit.
        </label>
        <ng-container *ngIf="interventionFormGroup.disabled">
          <p class="text-primary pt-3 mb-3">
            {{ selectedOutcomeTitles.join(', ') }}
          </p>
        </ng-container>
        <ng-container *ngIf="!interventionFormGroup.disabled">
          <app-autocomplete-chips
            formControlName="outcomesToWorkOn"
            id="outcomesToWorkOn"
            [options]="outcomesToWorkOnOptions"
          ></app-autocomplete-chips>
        </ng-container>
      </div>
      <div class="col-md-12">
        <label
          class="vertical-label text-md display-block position-fix--label"
          [ngClass]="{
            asterisk_input: interventionFormGroup.get('what').hasError('required')
          }"
        >
          What target(s) did the family identify to work on with
          {{ caseSummary?.learner.firstName }}? (What)
        </label>
        <app-textarea
          [hideAsterisk]="true"
          formControlName="what"
          [rows]="2"
          attr.aria-required="{{ interventionFormGroup.get('what').hasError('required') ? 'true' : 'false' }}"
        ></app-textarea>
      </div>
      <div class="col-md-12">
        <label
          class="vertical-label text-md display-block position-fix--label"
          [ngClass]="{
            asterisk_input: interventionFormGroup.get('why').hasError('required')
          }"
        >
          Why will the targets, strategies, and routines support
          {{ caseSummary?.learner.firstName | firstNamePossessive }} progress toward the outcome(s)? (Why)
        </label>
        <app-textarea
          [hideAsterisk]="true"
          formControlName="why"
          [rows]="2"
          attr.aria-required="{{ interventionFormGroup.get('why').hasError('required') ? 'true' : 'false' }}"
        ></app-textarea>
      </div>
      <div class="col-md-12">
        <label
          class="vertical-label text-md display-block position-fix--label"
          [ngClass]="{
            asterisk_input: interventionFormGroup.get('when').hasError('required')
          }"
        >
          Which routines, activities, places, times, and people did the family identify for {{ caseSummary?.learner.firstName }} to practice
          learning the target(s)? (When, Where, Who)
        </label>
        <app-textarea
          [hideAsterisk]="true"
          formControlName="when"
          [rows]="2"
          attr.aria-required="{{ interventionFormGroup.get('when').hasError('required') ? 'true' : 'false' }}"
        ></app-textarea>
      </div>
      <div class="col-md-12">
        <label
          class="vertical-label text-md display-block position-fix--label"
          [ngClass]="{
            asterisk_input: interventionFormGroup.get('how').hasError('required')
          }"
        >
          How will the strategies the family uses support
          {{ caseSummary?.learner.firstName | firstNamePossessive }} learning and participation? (How)
        </label>
        <app-textarea
          [hideAsterisk]="true"
          formControlName="how"
          [rows]="2"
          attr.aria-required="{{ interventionFormGroup.get('how').hasError('required') ? 'true' : 'false' }}"
        ></app-textarea>
      </div>
      <div class="col-md-12">
        <label
          class="vertical-label text-md display-block position-fix--label"
          [ngClass]="{
            asterisk_input: interventionFormGroup.get('howWorking').hasError('required')
          }"
        >
          How will the family know the plan is working and
          {{ caseSummary?.learner.firstName }} is making progress? (How we know it is working)
        </label>
        <app-textarea
          [hideAsterisk]="true"
          formControlName="howWorking"
          [rows]="2"
          attr.aria-required="{{ interventionFormGroup.get('howWorking').hasError('required') ? 'true' : 'false' }}"
        ></app-textarea>
      </div>
    </div>
    <div class="row" *ngIf="serviceLog?.interventionPlan">
      <div class="col-md-12">
        <app-documentation-upload
          title="Upload Documentation"
          [documents]="documents"
          [alignEnd]="false"
          [baseUrl]="baseUrl"
          [multiple]="true"
          [readOnly]="readOnly"
          (upload)="onUploadDocument($event)"
          (deleteDocument)="onDeleteDocument($event)"
          [activeCall]="activeCall"
        ></app-documentation-upload>
      </div>
    </div>
  </mat-expansion-panel>
</mat-accordion>

<div class="action__row card-spacing-bottom">
  <button *ngIf="!readOnly" mat-raised-button aria-label="Complete and Close" color="primary" (click)="submitAndClose()" tabindex="0">
    Complete and Close
  </button>
</div>

<ng-container
  *appAuthForRoles="{
    permission: [permissions.ViewProgressMonitoring],
    caseId: caseSummary?.id
  }"
>
  <mat-accordion class="accordion accordion--short" multi *ngIf="caseSummary">
    <mat-expansion-panel class="mat-elevation-z0" [formGroup]="interventionFormGroup">
      <mat-expansion-panel-header>
        <mat-panel-title> <h3 class="my-0 text-normal">Monitoring for Progress</h3> </mat-panel-title>
      </mat-expansion-panel-header>
      <div class="row" *ngIf="serviceLog?.interventionPlan">
        <div class="col-md-12">
          <app-enter-progress-part-c
            [caseSummary]="caseSummary"
            [showPlanStatus]="false"
            [readOnly]="readOnly"
            [enableForms]="canCreateEditProgressMonitoring()"
          ></app-enter-progress-part-c>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</ng-container>

<div class="page-bottom-leeway"></div>
