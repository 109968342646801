import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin } from 'rxjs';
import { ElpaDto, GradeLevelWhenAssessed, PerformanceLevel } from 'src/app/learner-management/data-history/models/elpa.model';
import { IsaspDto, IsaspProficiency } from 'src/app/learner-management/data-history/models/isasp.model';
import { DataHistoryService } from 'src/app/learner-management/data-history/services/data-history.service';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { DeactivationService } from '../../../../../../shared/services/deactivation.service';

@Component({
  selector: 'app-data-history-part-b-assessments',
  templateUrl: './data-history-part-b-assessments.component.html',
  styleUrls: ['./data-history-part-b-assessments.component.scss'],
})
export class DataHistoryPartBAssessmentsComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() caseId: string;
  @Input() stateAssignedId: string;
  displayedColumns = ['subject', 'averageScaleScore', 'proficiency', 'schoolYear', 'gradeWhenAssessed'];
  elpaDisplayedColumns = ['domain', 'scaleScore', 'performanceLevel', 'schoolYear', 'gradeWhenAssessed'];
  isaspDataSource: MatTableDataSource<any>;
  lsdDataSource: MatTableDataSource<any>;
  elpaDataSource: MatTableDataSource<any>;
  gradeLevelWhenAssessed = GradeLevelWhenAssessed;
  elpas: ElpaDto[];
  isasps: IsaspDto[];

  constructor(private dataHistoryService: DataHistoryService, deactivationService: DeactivationService) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.subscriptions.add(
      forkJoin([
        this.dataHistoryService.getElpas(this.caseId, this.stateAssignedId),
        this.dataHistoryService.getIsasps(this.caseId, this.stateAssignedId),
      ]).subscribe(([elpas, isasps]) => {
        this.elpas = elpas;
        this.isasps = isasps;
        this.elpaDataSource = this.getElpaDataSource();
        this.isaspDataSource = this.getIsaspDataSource();
      })
    );
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  getGradeLevelLabel(grade): string {
    return grade;
  }

  getPerformanceLevelLabel(performance): string {
    return PerformanceLevel[performance] || performance;
  }

  getProficiencyLabel(proficiency): string {
    return IsaspProficiency[proficiency];
  }

  private getIsaspDataSource(): MatTableDataSource<any> {
    const ds = [];
    this.isasps.forEach((x) => {
      ds.push(
        {
          subject: 'Mathematics',
          averageScaleScore: x.mathScore,
          proficiency: x.mathProficiency,
          schoolYear: x.schoolYear,
          gradeWhenAssessed: x.grade,
        },
        {
          subject: 'English/Language Arts',
          averageScaleScore: x.elaScore,
          proficiency: x.elaProficiency,
          schoolYear: x.schoolYear,
          gradeWhenAssessed: x.grade,
        },
        {
          subject: 'Science',
          averageScaleScore: x.scienceScore,
          proficiency: x.scienceProficiency,
          schoolYear: x.schoolYear,
          gradeWhenAssessed: x.grade,
        }
      );
    });
    return new MatTableDataSource(ds.sort((a, b) => (a.subject > b.subject ? 1 : -1)));
  }

  private getLsdDataSource(): MatTableDataSource<any> {
    return new MatTableDataSource();
  }

  private getElpaDataSource(): MatTableDataSource<any> {
    const ds = [];
    this.elpas.forEach((x) => {
      ds.push(
        {
          domain: 'Listening',
          scaleScore: x.listeningScaleScore,
          performanceLevel: x.listeningLevel,
          schoolYear: x.schoolYear,
          gradeWhenAssessed: x.gradeLevelWhenAssessed,
        },
        {
          domain: 'Reading',
          scaleScore: x.readingScaleScore,
          performanceLevel: x.readingLevel,
          schoolYear: x.schoolYear,
          gradeWhenAssessed: x.gradeLevelWhenAssessed,
        },
        {
          domain: 'Speaking',
          scaleScore: x.speakingScaleScore,
          performanceLevel: x.speakingLevel,
          schoolYear: x.schoolYear,
          gradeWhenAssessed: x.gradeLevelWhenAssessed,
        },
        {
          domain: 'Writing',
          scaleScore: x.writingScaleScore,
          performanceLevel: x.writingLevel,
          schoolYear: x.schoolYear,
          gradeWhenAssessed: x.gradeLevelWhenAssessed,
        }
      );
    });
    return new MatTableDataSource(ds.sort((a, b) => (a.subject > b.subject ? 1 : -1)));
  }
}
