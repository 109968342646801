import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Subscription } from 'rxjs';
import { yesNoOptions } from 'src/app/shared/formHelpers';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { ReferralSourceInfoHelp } from 'src/app/shared/services/help/models/referral.help';
import { AlertConfig } from '../../../shared/components/page-alert/page-alert.component';
import { FamilyRelationshipService } from '../../../shared/services/family-relationship/family-relationship.service';
import { noNumbersValidator, phoneValidator } from '../../../shared/validators';
import { ReferralService } from '../early-access-referral.service';
import { KeyValuePair } from './../../../shared/models/key-value-pair';

@Component({
  selector: 'app-family-info-form',
  templateUrl: './family-info-form.component.html',
  styleUrls: ['../early-access-referral.component.scss', './family-info-form.component.scss'],
})
export class FamilyInfoFormComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  @Input() parentForm: FormGroup;

  formGroup: FormGroup;
  yesNoOptions = yesNoOptions;
  relationshipOptions: KeyValuePair[];
  referralHowHearAboutUsOptions: KeyValuePair[];
  referralHowHearAboutUsOtherId: string;

  helpSection = HelpSection;
  referralSourceInfoHelp = ReferralSourceInfoHelp;

  emailPhoneRequirement: AlertConfig = {
    status: 'info',
    message: 'Please provide at least one phone number or email address for a parent or family member',
  };

  get referralSource() {
    return this.parentForm.get('referralSource').value;
  }

  get legalGuardian() {
    return this.parentForm.get('legalGuardian') as FormControl;
  }

  get exchangeInformation() {
    return this.parentForm.get('exchangeInformation') as FormControl;
  }

  constructor(
    private familyMemberRelationshipService: FamilyRelationshipService,
    private referralService: ReferralService,
    private helpService: HelpService
  ) {}

  ngOnInit() {
    this.formGroup = this.parentForm.controls.familyInfo as FormGroup;
    this.referralService.getReferralSourceHowHearAboutUs().subscribe((results) => {
      this.referralHowHearAboutUsOptions = results.map((val) => new KeyValuePair(val.id, val.label));
      this.referralHowHearAboutUsOtherId = results.find((x) => x.isOther)?.id;
    });
    this.familyMemberRelationshipService.get().subscribe((results) => {
      this.relationshipOptions = results.map((r) => new KeyValuePair(r.id, r.label));
    });

    this.initializeControls();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.formGroup.clearValidators();
    this.formGroup.reset();
    Object.keys(this.formGroup.controls).forEach((k) => this.formGroup.removeControl(k));
  }

  updateReferralSource(event: MatCheckboxChange, parent: string) {
    this.parentForm.get('referralSource').markAsDirty();
    if (event.checked) {
      this.parentForm.get('referralSource').setValue(parent);
    } else {
      this.parentForm.get('referralSource').setValue(null);
    }
    this.parentForm.get('referralSourceHowHearAboutUsId').setValue(null);
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm) {
    e.preventDefault();

    const dictionary = this.helpService.getReferralDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }

  private initializeControls() {
    this.formGroup.addControl('livesWith1Name', new FormControl(null, [Validators.required, noNumbersValidator]));
    this.formGroup.addControl('livesWith1Email', new FormControl(null, [Validators.email]));
    this.formGroup.addControl('livesWith1RelationshipId', new FormControl(null, [Validators.required]));
    this.formGroup.addControl('livesWith1HomePhone', new FormControl(null, [phoneValidator]));
    this.formGroup.addControl('livesWith1WorkPhone', new FormControl(null, [phoneValidator]));
    this.formGroup.addControl('livesWith1CellPhone', new FormControl(null, [phoneValidator]));
    this.formGroup.addControl('livesWith1BestWayToReachHomePhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('livesWith1BestWayToReachWorkPhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('livesWith1BestWayToReachCellPhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('livesWith1BestWayToReachEmail', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('livesWith1BestWayToReachText', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('livesWith1BestTimeToContact', new FormControl(''));

    // livesWith2...
    this.formGroup.addControl('livesWith2Name', new FormControl(null, [noNumbersValidator]));
    this.formGroup.addControl('livesWith2Email', new FormControl(null, [Validators.email]));
    this.formGroup.addControl('livesWith2RelationshipId', new FormControl(null));
    this.formGroup.addControl('livesWith2HomePhone', new FormControl(null, [phoneValidator]));
    this.formGroup.addControl('livesWith2WorkPhone', new FormControl(null, [phoneValidator]));
    this.formGroup.addControl('livesWith2CellPhone', new FormControl(null, [phoneValidator]));
    this.formGroup.addControl('livesWith2BestWayToReachHomePhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('livesWith2BestWayToReachWorkPhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('livesWith2BestWayToReachCellPhone', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('livesWith2BestWayToReachEmail', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('livesWith2BestWayToReachText', new FormControl(false, { updateOn: 'change' }));
    this.formGroup.addControl('livesWith2BestTimeToContact', new FormControl(''));

    this.formGroup.setValidators([
      livesWith2RelationshipValidator,
      bestWayToReachValidator('livesWith1'),
      bestWayToReachValidator('livesWith2'),
    ]);
    this.formGroup.updateValueAndValidity();
  }
}

const livesWith2RelationshipValidator: ValidatorFn = (control: FormGroup) => {
  const livesWith2Name = control.get('livesWith2Name').value;
  const livesWith2Relationship = control.get('livesWith2RelationshipId').value;
  return !!livesWith2Name && !livesWith2Relationship ? { livesWith2RelationshipRequired: true } : null;
};

const bestWayToReachValidator =
  (parent: string): ValidatorFn =>
  (control: FormGroup): ValidationErrors | null => {
    const homePhone = control.get(`${parent}HomePhone`);
    const homePhoneIsBest = control.get(`${parent}BestWayToReachHomePhone`);
    const cellPhone = control.get(`${parent}CellPhone`);
    const cellPhoneIsBest = control.get(`${parent}BestWayToReachCellPhone`);
    const textPhoneIsBest = control.get(`${parent}BestWayToReachText`);
    const workPhone = control.get(`${parent}WorkPhone`);
    const workPhoneIsBest = control.get(`${parent}BestWayToReachWorkPhone`);
    const email = control.get(`${parent}Email`);
    const emailIsBest = control.get(`${parent}BestWayToReachWorkEmail`);

    if (
      (!!homePhoneIsBest && !homePhone) ||
      (!!workPhoneIsBest && !workPhone) ||
      (!!cellPhoneIsBest && !cellPhone) ||
      (!!textPhoneIsBest && !cellPhone) ||
      (!!emailIsBest && !email)
    ) {
      const errors = {};
      errors[`${parent}OnePhoneNumberContactRequired`] = true;
      return errors;
    } else {
      return null;
    }
  };
