import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { forkJoin, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { shortDateFormat } from '../../../shared/dateTimeHelpers';
import { ConsentFormType } from '../../../shared/models/fiie-consent/consent-form';
import { FamilyConsentStatus } from '../../../shared/models/fiie-consent/family-consent-status';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { ConsentFormService } from '../../../shared/services/consent-form/consent-form.service';
import { IfspModificationService } from '../../services/ifsp-modification.service';
import { IfspService } from '../../services/ifsp.service';

@Component({
  selector: 'app-ifsp-modification-modal',
  templateUrl: './ifsp-modification-modal.component.html',
  styleUrls: ['./ifsp-modification-modal.component.scss'],
})
export class IfspModificationModalComponent implements OnInit, OnDestroy {
  caseId: string;
  ifspId: string;
  consentTypes: ConsentFormType[] = [ConsentFormType.ReceiveElectronicCommunication];
  hasElectronicCommunicationConsent = false;
  formGroup = this.fb.group({
    meetingRequired: [null, Validators.required],
    personContactedParentId: null,
    methodOfContact: null,
    dateOfModifyWithoutMeeting: null,
  });
  shortDateFormat: any;
  yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];

  personContactedParentOptions: KeyValuePair[] = [];

  methodOfContactOptions: KeyValuePair[] = [
    new KeyValuePair('Email', 'Email'),
    new KeyValuePair('InPerson', 'In Person'),
    new KeyValuePair('Phone', 'Phone'),
    new KeyValuePair('Text', 'Text'),
    new KeyValuePair('WrittenNote', 'Written Note'),
    new KeyValuePair('MeetingInProgress', 'Meeting In-Progress'),
  ];
  private subscriptions = new Subscription();
  today = dayjs().startOf('day').toDate();
  submittingForm: boolean;

  get meetingRequiredHasValue() {
    return this.formGroup.get('meetingRequired').value !== null && this.formGroup.get('meetingRequired').value !== '';
  }

  get meetingRequired() {
    return this.formGroup.get('meetingRequired').value;
  }

  get disableSubmitBtn() {
    return !this.formGroup.valid;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private fb: FormBuilder,
    private ifspService: IfspService,
    private ifspModificationService: IfspModificationService,
    private consentFormService: ConsentFormService,
    public dialogRef: MatDialogRef<IfspModificationModalComponent>,
    private authService: AuthService,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.shortDateFormat = shortDateFormat;
    this.caseId = this.data.caseId;
    this.ifspId = this.data.ifspId;

    forkJoin([
      this.ifspService.getPersonContactedUsers(this.caseId),
      this.consentFormService.getConsentForms(this.caseId, this.consentTypes),
    ]).subscribe(([teamMembers, consentForms]) => {
      teamMembers?.forEach((teamMember) => {
        this.personContactedParentOptions.push(new KeyValuePair(teamMember.id, teamMember.fullName));
      });

      if (this.personContactedParentOptions.length === 0) {
        this.personContactedParentOptions.push(new KeyValuePair(this.authService.user.id, this.authService.user.fullName));
      }
      this.hasElectronicCommunicationConsent =
        consentForms?.filter((x) => x.statuses.find((xx) => xx.status === FamilyConsentStatus.Approved)).length > 0;

      if (this.hasElectronicCommunicationConsent) {
        this.methodOfContactOptions.push(new KeyValuePair('Email', 'Email'));
        this.methodOfContactOptions.push(new KeyValuePair('Text', 'Text'));
      }
      this.methodOfContactOptions.sort();

      this.setupSubscriptions();
    });
  }

  onSubmit() {
    if (this.formGroup.valid) {
      this.submittingForm = true;
      const model = {
        id: null,
        ifspId: this.ifspId,
        meetingRequired: this.formGroup.value.meetingRequired,
        methodOfContact: this.formGroup.value.methodOfContact,
        personContactedParentId: this.formGroup.value.personContactedParentId,
      };

      this.ifspModificationService.updateModification(this.ifspId, model).subscribe((x) => {
        this.submittingForm = false;
        this.dialogRef.close(x);
      });
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  setupSubscriptions() {
    this.subscriptions.add(
      this.formGroup.get('meetingRequired').valueChanges.subscribe((meetingRequiredResult) => {
        if (this.meetingRequiredHasValue && !this.meetingRequired) {
          this.formGroup.get('personContactedParentId').setValidators([Validators.required]);
          this.formGroup.get('methodOfContact').setValidators([Validators.required]);
          this.formGroup.get('dateOfModifyWithoutMeeting').setValidators([Validators.required]);
        } else {
          this.formGroup.get('personContactedParentId').clearValidators();
          this.formGroup.get('methodOfContact').clearValidators();
          this.formGroup.get('dateOfModifyWithoutMeeting').clearValidators();
        }
        this.formGroup.get('personContactedParentId').setValue(null);
        this.formGroup.get('methodOfContact').setValue(null);
        this.formGroup.get('dateOfModifyWithoutMeeting').setValue(null);
        this.formGroup.updateValueAndValidity();
        this.cd.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
