<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mb-0">{{ pageTitle }}</h3>

<div mat-dialog-content class="py-3 mt-3" *ngIf="learnerScreening || learner">
  <mat-card class="my-0">
    <div class="mb-2 display-flex">
      <strong>Learner Name: </strong> <span class="ml-1">{{ learner?.lastName }}, {{ learner?.firstName }} </span>
      <mat-icon *ngIf="learner?.isDhh" class="dhh-icon teal" #tooltip="matTooltip" matTooltip="Known Loss">
        radio_button_checked <span class="cdk-visually-hidden">Known Loss</span>
      </mat-icon>
      <mat-icon
        *ngIf="learner?.isDoNotTest && learnerScreening?.isFinalized"
        class="dhh-icon"
        color="warn"
        #tooltip="matTooltip"
        matTooltip="Do Not Test"
      >
        error <span class="cdk-visually-hidden">Do Not Test</span>
      </mat-icon>
      <mat-icon *ngIf="learner?.isDoNotScreen" color="primary" class="dhh-icon" #tooltip="matTooltip" matTooltip="Do Not Screen">
        indeterminate_check_box <span class="cdk-visually-hidden">Do Not Screen</span>
      </mat-icon>
    </div>

    <ng-container [formGroup]="formGroup">
      <div class="row">
        <div class="col-lg-6 no-form-field-padding">
          <app-date-picker label="Screening Date" aria-label="Screening Date" controlName="screeningDate" [max]="today"></app-date-picker>
        </div>

        <div class="col-lg-6 no-form-field-padding">
          <app-autocomplete label="Screening Result" formControlName="screeningOptionId" [options]="screeningOptions"></app-autocomplete>
        </div>

        <div class="col-lg-12 no-form-field-padding">
          <app-textarea
            label="Screening Notes"
            aria-label="Screening Notes"
            formControlName="screeningNotes"
            [rows]="1"
            [showAsterisk]="screeningNotesRequired"
          ></app-textarea>
        </div>
      </div>
    </ng-container>
  </mat-card>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button aria-label="Cancel" (click)="onCancel()" class="background-color--muted mr-1" [isBusy]="isSaving" tabindex="0">
    Cancel
  </button>

  <button
    mat-raised-button
    aria-label="Submit"
    color="primary"
    (click)="onSubmit()"
    [disableAfterBusy]="formGroup.invalid"
    [disabled]="formGroup.invalid || !isFormGroupEdited"
    [isBusy]="isSaving"
    tabindex="0"
  >
    Submit
  </button>
</div>
