import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OosDetail, OospApplication, OospApplicationFilterCriteria, OospLearner, OospSummary } from '../models/oos-placement';
import { OperationResult, OperationResultWithValue } from '../models/operation-result';
import { User } from '../models/user';
import { SearchParams } from '../../child-find/search/search';
import { formatDate } from '@angular/common';
import { LearnerSearchResult } from '../models/learner';
import { KeyValuePair } from '../models/key-value-pair';

@Injectable({
  providedIn: 'root',
})
export class OosPlacementService {
  private baseUrl = 'api/oos-placement';

  constructor(private http: HttpClient) {}

  getOptionsForWeightedLevel() {
    return [new KeyValuePair('L1'), new KeyValuePair('L2'), new KeyValuePair('L3')];
  }

  getApplications(criteria: OospApplicationFilterCriteria) {
    return this.http.get<OperationResultWithValue<OospSummary[]>>(`${this.baseUrl}/search/${criteria.type}`, {
      params: {
        status: criteria.status,
        keyword: criteria.keyword,
        aeaId: criteria.aeaId,
        leaId: criteria.leaId,
      },
    });
  }

  create(learnerId: string) {
    return this.http.post<OperationResultWithValue<string>>(`${this.baseUrl}`, { value: learnerId });
  }

  getApplication(id: string) {
    return this.http.get<OperationResultWithValue<OospApplication>>(`${this.baseUrl}/${id}`);
  }

  saveApplication(app: OospApplication) {
    return this.http.put<OperationResult>(`${this.baseUrl}/${app.id}`, app);
  }

  submitApplication(app: OospApplication) {
    return this.http.post<OperationResult>(`${this.baseUrl}/${app.id}`, app);
  }

  removeApplication(id: string) {
    return this.http.post<OperationResult>(`${this.baseUrl}/${id}/delete`, {});
  }

  withdrawApplication(id: string) {
    return this.http.post<OperationResult>(`${this.baseUrl}/${id}/withdraw`, {});
  }

  getApplicationsForApproval(criteria: OospApplicationFilterCriteria) {
    return this.http.get<OperationResultWithValue<OospSummary[]>>(`${this.baseUrl}/approval/${criteria.type}`, {
      params: {
        status: criteria.status,
        keyword: criteria.keyword,
        aeaId: criteria.aeaId,
        leaId: criteria.leaId,
      },
    });
  }

  exportApplicationsForApproval(criteria: OospApplicationFilterCriteria) {
    return this.http.post(`${this.baseUrl}/export/approval`, criteria, { responseType: 'blob' });
  }

  approveApplication(id: string) {
    return this.http.post<OperationResult>(`${this.baseUrl}/${id}/approve`, {});
  }

  denyApplication(id: string) {
    return this.http.post<OperationResult>(`${this.baseUrl}/${id}/deny`, {});
  }

  endApplication(id: string) {
    return this.http.post<OperationResult>(`${this.baseUrl}/${id}/end`, {});
  }

  continueApplication(id: string) {
    return this.http.post<OperationResultWithValue<string>>(`${this.baseUrl}/${id}/continue`, {});
  }

  searchUser(aeaId: string, firstName: string, lastName: string) {
    return this.http.get<OperationResultWithValue<User[]>>(`${this.baseUrl}/search-user/${aeaId}`, {
      params: {
        firstName,
        lastName,
      },
    });
  }

  transfer(id: string, newOwnerId: string) {
    return this.http.post<OperationResult>(`${this.baseUrl}/${id}/transfer`, { value: newOwnerId });
  }

  searchLearners(searchParams: SearchParams) {
    let params = new HttpParams();

    Object.keys(searchParams).forEach((k) => {
      const val = searchParams[k];
      if (val instanceof Date) {
        params = params.set(k, formatDate(val, 'yyyy-MM-ddT00:00:00.000-0000', 'en-US'));
      } else {
        params = params.set(k, searchParams[k]);
      }
    });
    return this.http.get<LearnerSearchResult[]>(`${this.baseUrl}/search-learners`, { params });
  }

  getOospLearners() {
    return this.http.get<OperationResultWithValue<OospLearner[]>>(`${this.baseUrl}/learners`);
  }

  updateOosDetails(learnerId: string, details: OosDetail) {
    return this.http.put<OperationResultWithValue<OosDetail>>(`${this.baseUrl}/learners/${learnerId}/out-of-state-details`, details);
  }
}
