<form [formGroup]="formGroup">
  <mat-card>
    <mat-card-title>Child Information</mat-card-title>
    <div class="container-fluid container--not-centered container--form">
      <div class="row">
        <div class="col-lg-3">
          <app-text-field
            *ngIf="isQuestionnaire"
            label="First Name"
            formControlName="firstName"
            [disabledState]="locked || !canEditLearnerDobAndName"
          ></app-text-field>
          <app-overwrite-text-field
            *ngIf="!isQuestionnaire"
            label="First Name"
            formControlName="firstName"
            [disabledState]="locked || !canEditLearnerDobAndName"
            [sourceData]="questionnaire.childInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-2">
          <app-text-field
            [disabledState]="locked || !canEditLearnerDobAndName"
            *ngIf="isQuestionnaire"
            label="Middle Name"
            formControlName="middleName"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked || !canEditLearnerDobAndName"
            *ngIf="!isQuestionnaire"
            label="Middle Name"
            formControlName="middleName"
            [sourceData]="questionnaire.childInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-3">
          <app-text-field
            [disabledState]="locked || !canEditLearnerDobAndName"
            *ngIf="isQuestionnaire"
            label="Last Name"
            formControlName="lastName"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked || !canEditLearnerDobAndName"
            *ngIf="!isQuestionnaire"
            label="Last Name"
            formControlName="lastName"
            [sourceData]="questionnaire.childInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-4">
          <app-date-picker
            [disabled]="locked || !canEditLearnerDobAndName"
            *ngIf="isQuestionnaire"
            controlName="dateOfBirth"
            [max]="today"
            [min]="twentyFiveYearsAgo"
            startView="multi-year"
            label="DOB (MM/DD/YYYY)"
          ></app-date-picker>
          <app-overwrite-date-picker
            [disabled]="locked || !canEditLearnerDobAndName"
            *ngIf="!isQuestionnaire"
            controlName="dateOfBirth"
            [max]="today"
            [min]="threeYearsAgo"
            startView="multi-year"
            label="DOB (MM/DD/YYYY)"
            [sourceData]="questionnaire.childInfo"
          ></app-overwrite-date-picker>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3 col-lg-4">
          <div class="vertical-control">
            <fieldset>
              <app-radio-group
                [disabledState]="locked"
                [options]="genderOptions"
                formControlName="genderId"
                label="Gender"
                *ngIf="!isQuestionnaire || (isQuestionnaire && shownFields.genderId)"
                [useOverwrite]="!isQuestionnaire"
                [sourceData]="questionnaire.childInfo"
                [sourceFieldShownLabel]="questionnaire.childInfo.gender?.label"
              ></app-radio-group>
            </fieldset>
          </div>
        </div>
        <div class="col-xl-4 col-lg-4">
          <app-autocomplete
            [disabledState]="locked"
            *ngIf="(isQuestionnaire && shownFields.languageSpokenInHomeId) || !isQuestionnaire"
            [options]="languageOptions"
            formControlName="languageSpokenInHomeId"
            label="Primary Language Spoken In Home"
            [useOverwrite]="!isQuestionnaire"
            [sourceData]="questionnaire.childInfo"
          ></app-autocomplete>
        </div>
        <div class="col-xl-3 col-lg-4">
          <div class="vertical-control">
            <fieldset>
              <app-radio-group
                [disabledState]="locked"
                *ngIf="!isQuestionnaire || (isQuestionnaire && shownFields.interpreterNeeded)"
                [options]="yesNoOptions"
                formControlName="interpreterNeeded"
                label="Interpreter Needed?"
                [useOverwrite]="!isQuestionnaire"
                [sourceData]="questionnaire.childInfo"
              ></app-radio-group>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card
    class="card-spacing-top"
    *ngIf="
      !isQuestionnaire || shownFields.streetAddress || shownFields.zipCode || shownFields.city || shownFields.state || shownFields.county
    "
  >
    <mat-card-title>Residential Information</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg-4">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire && shownFields.streetAddress"
            label="Street Address"
            formControlName="streetAddress"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Street Address"
            formControlName="streetAddress"
            [sourceData]="questionnaire.childInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-3">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire && shownFields.zipCode"
            label="Zip Code"
            formControlName="zipCode"
            (blurMe)="populateLocationFromZipCode($event)"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Zip Code"
            formControlName="zipCode"
            (blurMe)="populateLocationFromZipCode($event)"
            [sourceData]="questionnaire.childInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-3">
          <app-text-field
            [disabledState]="locked"
            label="City/Town"
            *ngIf="isQuestionnaire && shownFields.city"
            formControlName="city"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="City/Town"
            formControlName="city"
            [sourceData]="questionnaire.childInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-2">
          <app-autocomplete
            [disabledState]="locked"
            *ngIf="(isQuestionnaire && shownFields.state) || !isQuestionnaire"
            [options]="usStates"
            formControlName="state"
            label="State"
            [sourceData]="questionnaire.childInfo"
            [useOverwrite]="!isQuestionnaire"
          ></app-autocomplete>
        </div>
      </div>
      <div class="row">
        <div class="col-lg">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire && shownFields.county"
            label="Child's County"
            formControlName="county"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Child's County"
            formControlName="county"
            [sourceData]="questionnaire.childInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg">
          <app-autocomplete
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            [options]="districtOptions"
            formControlName="childResidentDistrictId"
            label="Child's Resident District"
            [sourceData]="questionnaire.childInfo"
            [useOverwrite]="true"
          ></app-autocomplete>
        </div>
        <div class="col-lg">
          <app-autocomplete
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            [options]="districtOptions"
            formControlName="childAttendingDistrictId"
            label="Child's Attending District"
            [sourceData]="questionnaire.childInfo"
            [useOverwrite]="true"
          ></app-autocomplete>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top" *ngIf="showChildCare">
    <mat-card-title>Child Care</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row mt-3">
        <div class="col-lg-6">
          <div class="vertical-control">
            <fieldset>
              <app-radio-group
                [disabledState]="locked"
                *ngIf="!isQuestionnaire || (isQuestionnaire && shownFields.attendsChildCare)"
                [options]="yesNoOptions"
                formControlName="attendsChildCare"
                label="Does child attend child care? If unknown, leave this section blank."
                [sourceData]="questionnaire.childInfo"
                [useOverwrite]="!isQuestionnaire"
              ></app-radio-group>
            </fieldset>
          </div>
        </div>
      </div>
      <div *ngIf="!!formGroup.get('attendsChildCare').value" class="row">
        <div class="col-lg-6">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire && shownFields.childCareProviderName"
            label="Name of Child Care Provider"
            formControlName="childCareProviderName"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Name of Child Care Provider"
            formControlName="childCareProviderName"
            [sourceData]="questionnaire.childInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-6">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire && shownFields.childCareProviderStreetAddress"
            label="Street Address"
            formControlName="childCareProviderStreetAddress"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Street Address"
            formControlName="childCareProviderStreetAddress"
            [sourceData]="questionnaire.childInfo"
          ></app-overwrite-text-field>
        </div>
      </div>
      <div *ngIf="!!formGroup.get('attendsChildCare').value" class="row">
        <div class="col-lg-2">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire && shownFields.childCareProviderZipCode"
            label="Zip Code"
            formControlName="childCareProviderZipCode"
            (blurMe)="populateChildcareLocationFromZipCode()"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Zip Code"
            formControlName="childCareProviderZipCode"
            (blurMe)="populateChildcareLocationFromZipCode()"
            [sourceData]="questionnaire.childInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-4">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire && shownFields.childCareProviderCity"
            label="City"
            formControlName="childCareProviderCity"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="City"
            formControlName="childCareProviderCity"
            [sourceData]="questionnaire.childInfo"
          ></app-overwrite-text-field>
        </div>
        <div class="col-lg-2">
          <app-autocomplete
            [disabledState]="locked"
            *ngIf="(isQuestionnaire && shownFields.childCareProviderState) || !isQuestionnaire"
            [options]="usStates"
            formControlName="childCareProviderState"
            label="State"
            [useOverwrite]="!isQuestionnaire"
            [sourceData]="questionnaire.childInfo"
          ></app-autocomplete>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top" *ngIf="!isQuestionnaire">
    <mat-card-title>Race / Ethnicity</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row mt-3">
        <div class="col-lg">
          <app-checkbox
            [disabled]="locked"
            [options]="raceOptions"
            controlName="races"
            label="Race / Ethnicity  (check all that apply):"
          ></app-checkbox>
        </div>
      </div>
      <div class="row mt-3">
        <div class="col-lg">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [options]="determinationOptions"
              formControlName="raceDeterminedBy"
              label="Determination was made by:"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>
      <!--PBI 22545 - Remove Hispanic/Latino questions on intake form-->
      <!--<hr class="input__divider mb-1 mt-4" />-->
      <!--<div class="row mt-3">
    <div class="col-lg">
      <fieldset>
        <app-radio-group
          [disabledState]="locked"
          [options]="yesNoOptions"
          formControlName="hispanicLatino"
          label="Hispanic / Latino:"
        ></app-radio-group>
      </fieldset>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-lg">
      <fieldset>
        <app-radio-group
          [disabledState]="locked"
          [options]="determinationOptions"
          formControlName="hispanicLatinoDeterminedBy"
          label="Determination was made by:"
        ></app-radio-group>
      </fieldset>
    </div>
  </div>-->
    </div>
  </mat-card>
</form>

<ng-template #tooOld>
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Must Be Under Three Years of Age</h3>
  <mat-dialog-content class="py-3">
    <mat-card class="mb-0">
      <p class="my-0 line-height-16">
        Early ACCESS is a system of early intervention services for infants and toddlers (birth to age 3) with a developmental delay or
        disability, and their families. Based on the date of birth entered, this child is older than 3 and cannot be referred to Early
        ACCESS. Please call the
        <a
          (click)="openIowaFamilySupportNetworkUrl()"
          tabindex="0"
          role="link"
          aria-labelledby="Please call the Iowa Family Support Network"
        >
          Iowa Family Support Network</a
        >
        at 1-888-425-4371 and they will assist in connecting you to resources for children who are 3 years and older. Thank you!
      </p>
    </mat-card>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button [disabled]="locked" color="primary" aria-label="Ok" type="button" mat-raised-button [mat-dialog-close]="true" tabindex="0">
      Ok
    </button>
  </mat-dialog-actions>
</ng-template>
