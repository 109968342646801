<app-page-header
  [pageTitle]="pageTitle"
  backgroundColor="yellow"
  [helpLinks]="true"
  [condensed]="true"
  [pageTitleColor]="pageTitleColor"
  [shouldShowDhhBanner]="learner?.isDhh"
>
  <ng-container pageActions>
    <button mat-flat-button color="primary" class="mr-2" *ngIf="canCancelModification" (click)="onCancelModify()" tabindex="0">
      Cancel Modification
    </button>

    <app-create-output-button
      *ngIf="canPushToPortal"
      [output]="pdfOutputs.IfspOutput"
      [apiId]="ifspView?.id"
      [learnerId]="learnerId"
      [hasOpenModification]="hasOpenModification"
      [isDraft]="true"
      [pushDraftToPortal]="true"
      buttonText="Push to Portal"
      buttonColor="primary"
      class="mr-2"
      buttonType="mat-flat-button"
    >
    </app-create-output-button>

    <app-create-output-button
      [removeIfFeatureOff]="'outputIfspEnabled'"
      [output]="pdfOutputs.IfspOutput"
      [apiId]="ifspView?.id"
      [learnerId]="learnerId"
      [documentId]="!hasOpenModification ? getLastIfspDocumentId(ifspView) : null"
      [hasOpenModification]="hasOpenModification"
      buttonText="IFSP Report"
      buttonColor="primary"
      class="mr-2"
      buttonType="mat-flat-button"
    ></app-create-output-button>

    <button mat-flat-button aria-label="Open Scheduler" (click)="scheduleMeeting()" class="mr-2" color="primary" tabindex="0">
      Open Scheduler
    </button>

    <button mat-flat-button aria-label="Data History" (click)="onDataHistoryNavigate()" class="mr-2" color="primary" tabindex="0">
      Data History
    </button>
  </ng-container>
</app-page-header>
<app-dynamic-learner-header [learnerId]="caseSummary?.learnerId"></app-dynamic-learner-header>

<mat-accordion *ngIf="dataLoaded" class="accordion accordion--short card-spacing-top" multi>
  <mat-expansion-panel class="mat-elevation-z0" #plodPanel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="display-flex align-items-center justify-content-between flex-1 flex-wrap">
          <h3 class="my-0 text-normal">Present Levels of Development</h3>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-plod
      [modifications]="ifspModifications"
      [isModification]="true"
      [ifspSixMonthReviewMeeting]="ifspSixMonthReviewMeeting"
    ></app-plod>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0" #outcomesPanel [expanded]="isOutcomesAccordionExpanded">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="display-flex align-items-center justify-content-between flex-1 flex-wrap">
          <h3 class="my-0 text-normal">Outcomes</h3>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-ifsp-details-outcomes
      [learnerId]="caseSummary?.learnerId"
      [ifspStartDate]="caseSummary?.ifspStartDate"
      [modifications]="ifspModifications"
    ></app-ifsp-details-outcomes>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0" #servicesPanel>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="display-flex align-items-center justify-content-between flex-1 flex-wrap">
          <h3 class="my-0 text-normal">Services</h3>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-ifsp-details-services
      [ifspId]="ifspId"
      [ifspStartDate]="caseSummary?.ifspStartDate"
      [modifications]="ifspModifications"
    ></app-ifsp-details-services>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Services Setting(s)</h3></mat-panel-title>
    </mat-expansion-panel-header>
    <app-ifsp-setting [ifspId]="ifspId" [modificationId]="modification?.id"></app-ifsp-setting>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0 hide-print" #consent>
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Consent</h3></mat-panel-title>
    </mat-expansion-panel-header>
    <app-ifsp-consents
      [needsNewConsent]="needsNewConsent"
      *ngIf="caseSummary"
      [isModification]="true"
      [caseSummary]="caseSummary"
      [ifspId]="ifspId"
    ></app-ifsp-consents>
  </mat-expansion-panel>

  <mat-expansion-panel *ngFor="let review of ifspPeriodicAnnualReviews" class="mat-elevation-z0" #annualReview>
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="display-flex align-items-center justify-content-between flex-1">
          <h3 class="my-0 text-normal">
            {{ review.ifspReviewType }} Review
            {{ review.createdOn | dateFormat }}
          </h3>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <div class="display-flex align-items-baseline">
      <section class="card-spacing-bottom">
        <app-annual-review-questionnaire
          [learnerName]="caseSummary?.learner?.firstName"
          [ifspId]="review.ifspId"
          [ifspReviewId]="review.id"
          [evaluationId]="caseSummary?.evaluationId"
          [hasFinalizedEvaluation]="hasFinalizedEvaluation"
          [caseId]="caseId"
          [surveysByQuestion]="review.surveysByQuestion"
          [outcomeReviews]="outcomeReviews"
          [learnerId]="caseSummary?.learnerId"
          (annualReviewCompleted)="annualReviewCompleted($event)"
          (reviewCancelled)="annualReviewCancelled($event)"
          [surveys]="review.generalSurveyUserFullNameDtos"
          [isPeriodicReview]="review.ifspReviewType === 'Periodic'"
          [trackFormChanges]="annualReviewQuestionnaire.formGroup"
          [totalSurveysSent]="review.totalSurveysSent"
          #annualReviewQuestionnaire
        >
        </app-annual-review-questionnaire>
      </section>
    </div>
    <div class="divider mt-3"></div>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0 hide-print" #lateReason *ngIf="ifspView.isLate && !hasOpenModification">
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Late IFSP Rationale</h3></mat-panel-title>
    </mat-expansion-panel-header>
    <app-late-ifsp-reason
      [ifspId]="ifspView.id"
      [lateReasonId]="ifspView.lateReasonId"
      [lateReasonOther]="ifspView.lateReasonOther"
      [readOnly]="onViewOrModfyingPage()"
      (lateReasonUpdated)="lateReasonUpdated()"
    ></app-late-ifsp-reason>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0" *ngIf="hasOpenModification">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="display-flex align-items-center justify-content-between flex-1 flex-wrap">
          <h3 class="my-0 text-normal">Incomplete Data Report</h3>
          <div class="display-flex align-items-center">
            <span class="text-md position-fix-notice" [ngClass]="{ 'text-danger': incompleteItems?.length > 0 }">{{
              incompleteItemsAmount
            }}</span>

            <button
              mat-icon-button
              aria-label="Refresh Icon"
              color="primary"
              (click)="refreshIncompleteItems($event)"
              class="ml-2"
              matTooltip="Refresh"
              tabindex="0"
            >
              <mat-icon aria-label="Refresh Icon"> refresh </mat-icon>
            </button>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>

    <app-ifsp-completion
      [evaluationId]="evaluationId"
      [incompleteItems]="incompleteItems"
      [caseSummary]="caseSummary"
      [modificationId]="modification?.id"
      (gotoConsent)="scrollAndOpenConsent()"
      (gotoLateReason)="scrollAndOpenLateReason()"
      (gotoPlod)="scrollAndOpenPlod()"
      (gotoAnnualReview)="scrollAndOpenAnnualReview()"
      (gotoProceduralSafeguards)="gotoProceduralSafeguards()"
      (refreshData)="refreshData()"
    ></app-ifsp-completion>
  </mat-expansion-panel>
</mat-accordion>

<div class="action__row">
  <button
    mat-flat-button
    aria-label="Cancel Modification"
    color="primary"
    class="mr-2"
    *ngIf="canCancelModification"
    (click)="onCancelModify()"
    tabindex="0"
  >
    Cancel Modification
  </button>
  <button
    *ngIf="hasOpenModification && canFinalizeIfspModification && dataItemsLoaded"
    mat-flat-button
    aria-label="Finalize IFSP Modification"
    color="primary"
    [disabled]="incompleteItems?.length > 0"
    (click)="finalizeIfspModification()"
    [isBusy]="activeCall"
    tabindex="0"
  >
    Finalize IFSP Modification
  </button>
</div>

<app-ifsp-additional-info></app-ifsp-additional-info>
<div class="page-bottom-leeway"></div>
