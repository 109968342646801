import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { forkJoin, of } from 'rxjs';
import { catchError, debounceTime, withLatestFrom } from 'rxjs/operators';
import { EarlyAccessQuestionnaire } from 'src/app/evaluation/models/early-access-questionnaire';
import { DocumentationType, Intake } from 'src/app/evaluation/models/intake';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { LengthUnits } from 'src/app/shared/models/length-units';
import { NotSureYesNo, UnknownYesNo } from 'src/app/shared/models/yes-no';
import { MedicalConditionService } from 'src/app/shared/services/medical-condition/medical-condition-service';
import { ConfirmationDialogComponent } from 'src/app/shared/services/notification.service';
import { BaseComponent } from '../../../../../shared/components/base-component/base-component';
import { AlertConfig } from '../../../../../shared/components/page-alert/page-alert.component';
import { MedicalCondition, MedicalConditionDto } from '../../../../../shared/models/medical-condition';
import { WeightUnits } from '../../../../../shared/models/weight-units';
import { DeactivationService } from '../../../../../shared/services/deactivation.service';
import { conditionalValidator } from '../../../../../shared/validators';
import { MedicalHistoryInformation } from '../../../models/health-information';
import { DatabaseLinksService } from '../../../../../shared/services/database-links/database-links.service';

enum childBirthLocation {
  HospitalClinic = 'HospitalClinic',
  Other = 'Other',
}

@Component({
  selector: 'app-medical-history-info',
  templateUrl: './medical-history-info.component.html',
  styleUrls: ['./medical-history-info.component.scss'],
})
export class MedicalHistoryInfoComponent extends BaseComponent implements OnInit, OnChanges, AfterViewChecked {
  @Input() formGroup: FormGroup;
  @Input() medicalHistoryInfo: MedicalHistoryInformation;
  @Input() documentationInfo: FormGroup;
  @Input() intake: Intake;
  @Input() questionnaire: EarlyAccessQuestionnaire;
  @Input() isQuestionnaire: boolean;
  @Input() locked: boolean;
  @Input() importQuestionnaireSelected: boolean;
  @Output() upload = new EventEmitter();
  @Output() deleteDocument = new EventEmitter();
  @Output() medicalConditionUpdated = new EventEmitter();
  @Output() pauseAutosave = new EventEmitter<boolean>();

  unknownYesNo = UnknownYesNo;
  notSureYesNo = NotSureYesNo;
  dataSource = new MatTableDataSource<MedicalCondition>([]);
  questConditionsNotImported: MedicalCondition[];

  childBirthLocationOptions: KeyValuePair[] = [
    new KeyValuePair(childBirthLocation.HospitalClinic, 'Hospital/Clinic'),
    new KeyValuePair(childBirthLocation.Other, 'Other'),
  ];

  yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];

  yesNoUnknownOptions: KeyValuePair[] = [
    new KeyValuePair(UnknownYesNo.Yes, 'Yes'),
    new KeyValuePair(UnknownYesNo.No, 'No'),
    new KeyValuePair(UnknownYesNo.Unknown, 'Unknown'),
  ];

  yesNoNotSureOptions: KeyValuePair[] = [
    new KeyValuePair(UnknownYesNo.Yes, 'Yes'),
    new KeyValuePair(UnknownYesNo.No, 'No'),
    new KeyValuePair(UnknownYesNo.Unknown, 'Unknown'),
  ];

  birthLengthOptions: KeyValuePair[] = [new KeyValuePair(LengthUnits.In, 'Inches'), new KeyValuePair(LengthUnits.Cm, 'Centimeters')];

  documentationTypeOptions: KeyValuePair[] = [
    new KeyValuePair(DocumentationType.MedicalRecords, 'Medical Records'),
    new KeyValuePair(DocumentationType.ScreeningResults, 'Screening Results'),
  ];

  birthWeightUnitOptions: KeyValuePair[] = [
    new KeyValuePair(WeightUnits.lbOz, 'Pounds and Ounces'),
    new KeyValuePair(WeightUnits.g, 'Grams'),
    new KeyValuePair(WeightUnits.kg, 'Kilograms'),
  ];

  shownFields = {
    gestationalAgeWeeks: true,
    gestationalAgeDays: true,
    birthWeightUnits: true,
    birthWeightInLb: true,
    birthWeightInOz: true,
    birthWeightInGrams: true,
    birthWeightInKilograms: true,
    medicalDiagnosis: true,
  };

  autoEligibleAlert: AlertConfig = {
    status: 'info',
    message:
      'Child may be automatically eligible due to the information entered. Once confirmed, document in the Medical Diagnosis section below to indicate eligibility.',
  };

  get childFirstName() {
    return this.isQuestionnaire ? this.questionnaire?.childInfo?.firstName : this.intake?.childInfo?.firstName;
  }

  get childsName() {
    return this.childFirstName + ' ' + (this.isQuestionnaire ? this.questionnaire?.childInfo?.lastName : this.intake?.childInfo?.lastName);
  }

  get autoEligible() {
    return this.autoEligibleByAge || this.autoEligibleByWeight;
  }

  get autoEligibleByAge() {
    const gestationalAgeWeeks = this.formGroup.get('gestationalAgeWeeks').value;
    const gestationalAgeDays = this.formGroup.get('gestationalAgeDays').value;

    if (gestationalAgeWeeks || gestationalAgeDays) {
      let totalDays = 0;
      if (gestationalAgeWeeks) {
        totalDays += gestationalAgeWeeks * 7;
      }
      if (gestationalAgeDays) {
        totalDays += gestationalAgeDays;
      }

      if (totalDays <= 224) {
        return true;
      }
    }

    return false;
  }

  get autoEligibleByWeight() {
    const birthWeightUnits = this.formGroup.get('birthWeightUnits').value;
    if (birthWeightUnits) {
      if (birthWeightUnits === WeightUnits.lbOz) {
        let totalWeightInOz = 0;
        if (this.formGroup.get('birthWeightInLb').value) {
          totalWeightInOz += this.formGroup.get('birthWeightInLb').value * 16;
        }
        if (this.formGroup.get('birthWeightInOz').value) {
          totalWeightInOz += this.formGroup.get('birthWeightInOz').value;
        }
        if (!this.formGroup.get('birthWeightInLb').value && !this.formGroup.get('birthWeightInOz').value) {
          return false;
        }
        if (totalWeightInOz <= 53) {
          return true;
        }
      } else if (birthWeightUnits === WeightUnits.g) {
        const birthWeightInGrams = this.formGroup.get('birthWeightInGrams');
        if (birthWeightInGrams && birthWeightInGrams.value && birthWeightInGrams.value <= 1500) {
          return true;
        }
      } else if (birthWeightUnits === WeightUnits.kg) {
        const birthWeightInKilograms = this.formGroup.get('birthWeightInKilograms');
        if (birthWeightInKilograms && birthWeightInKilograms.value && birthWeightInKilograms.value <= 1500) {
          return true;
        }
      }
    }
    return false;
  }

  constructor(
    private changeDetector: ChangeDetectorRef,
    private fb: FormBuilder,
    private medicalConditionService: MedicalConditionService,
    deactivationService: DeactivationService,
    private readonly databaseLinksService: DatabaseLinksService,
    private readonly dialog: MatDialog
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.initializeControls();
    this.formGroup.patchValue(this.medicalHistoryInfo);
    if (this.isQuestionnaire) {
      this.updateFieldsValueAndVisibility();
    }

    if (!this.isQuestionnaire) {
      this.isQuestionnaire = false;
    }

    if (!this.isQuestionnaire) {
      this.formGroup.controls.childBirthLocation.valueChanges.subscribe((change) => {
        switch (change) {
          case childBirthLocation.HospitalClinic: {
            this.setHospitalInfoToRequired();
            break;
          }
          case childBirthLocation.Other: {
            this.setOtherBirthLocationInfoToRequired();
            break;
          }
        }
      });

      this.formGroup.controls.medicalDiagnosis.valueChanges.pipe(debounceTime(100), withLatestFrom()).subscribe((value) => {
        if (!value[0] && this.dataSource.data.length > 1) {
          this.pauseAutosave.emit(true);
          const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
            data: {
              title: 'Are you sure?',
              message: 'Clicking Confirm will delete the Medical Diagnosis(es) and any information entered will be lost.',
              buttons: { cancel: 'Cancel', ok: 'Confirm' },
            },
          });

          dialogRef.afterClosed().subscribe((res) => {
            if (res) {
              this.dataSource.data = this.dataSource.data.slice(-1);
              this.medicalConditionService.deleteAllIntakeMedicalCondition(this.intake?.caseId).subscribe(() => {
                this.getQuestionnaireConditionsNotImported();
                this.medicalConditionUpdatedEmit(true);
              });
            } else {
              this.formGroup.controls.medicalDiagnosis.setValue(true);
            }
            this.pauseAutosave.emit(false);
          });
        }
      });
    }
    this.loadData();
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.importQuestionnaireSelected?.currentValue !== changes.importQuestionnaireSelected?.previousValue &&
      this.importQuestionnaireSelected
    ) {
      this.appendQuestionnaireMedicalConditions();
      this.importMedicalHistoryInfoFromQuestionnaire();
      this.formGroup.markAsDirty();
    }
  }

  importMedicalHistoryInfoFromQuestionnaire() {
    const questionnaireMedicalInfo = this.questionnaire?.healthInfo?.medicalHistoryInformation;
    for (const prop in questionnaireMedicalInfo) {
      if (questionnaireMedicalInfo[prop] !== null && questionnaireMedicalInfo[prop] !== '') {
        this.medicalHistoryInfo[prop] = questionnaireMedicalInfo[prop];
      }
    }
    this.formGroup.patchValue(this.medicalHistoryInfo);
  }

  onUpload(formData: FormData) {
    this.upload.emit(formData);
  }

  onDeleteDocument(documentId: string) {
    this.deleteDocument.emit(documentId);
  }

  loadData() {
    if (this.isQuestionnaire) {
      this.medicalConditionService.getQuestionnaireMedicalConditions(this.questionnaire?.id).subscribe((newData) => {
        newData.push({
          name: null,
          icdCode: null,
          diagnosisQualifies: null,
        } as MedicalConditionDto);
        this.dataSource.data = newData;
      });
    } else {
      this.medicalConditionService.getIntakeMedicalConditions(this.intake?.caseId).subscribe((newData) => {
        newData.push({
          name: null,
          icdCode: null,
          diagnosisQualifies: null,
        } as MedicalConditionDto);
        this.dataSource.data = newData;
        this.getQuestionnaireConditionsNotImported();
      });
    }
  }

  getQuestionnaireConditionsNotImported() {
    if (this.questionnaire?.id) {
      this.medicalConditionService.getQuestionnaireMedicalConditions(this.questionnaire?.id).subscribe((medicalConditions) => {
        if (medicalConditions.length > 0) {
          this.questConditionsNotImported = medicalConditions.filter(
            (conditionRec) => !this.dataSource.data.some((condition) => condition.name === conditionRec.name)
          );
        }
      });
    }
  }

  setHospitalInfoToRequired() {
    [
      this.formGroup.controls.hospitalClinicName,
      this.formGroup.controls.hospitalClinicCity,
      this.formGroup.controls.hospitalClinicState,
    ].forEach((ctrl) => {
      ctrl.setValidators([Validators.required]);
      ctrl.updateValueAndValidity();
    });
    this.formGroup.controls.birthLocationDescription.setValidators(null);
    this.formGroup.controls.birthLocationDescription.updateValueAndValidity();
  }

  setOtherBirthLocationInfoToRequired() {
    [
      this.formGroup.controls.hospitalClinicName,
      this.formGroup.controls.hospitalClinicCity,
      this.formGroup.controls.hospitalClinicState,
    ].forEach((ctrl) => {
      ctrl.setValidators(null);
      ctrl.updateValueAndValidity();
    });
    this.formGroup.controls.birthLocationDescription.setValidators(Validators.required);
    this.formGroup.controls.birthLocationDescription.updateValueAndValidity();
  }

  initializeControls() {
    this.formGroup.addControl('healthConcernDescription', new FormControl(null));
    this.formGroup.addControl('childBirthLocation', new FormControl(null));
    this.formGroup.addControl('hospitalClinicName', new FormControl(null));
    this.formGroup.addControl('hospitalClinicCity', new FormControl(null));
    this.formGroup.addControl('hospitalClinicState', new FormControl(null));
    this.formGroup.addControl('birthLocationDescription', new FormControl(null));
    // TODO 'May indicate auto-eligible if <=1.5kg, 1,500g, or 3lb 5oz'
    // Stephanie needs to talk with Kimberly on how this should work
    this.formGroup.addControl('gestationalAgeWeeks', new FormControl(null, [Validators.min(0)]));
    this.formGroup.addControl('gestationalAgeDays', new FormControl(null, [Validators.min(0), Validators.max(6)]));
    this.formGroup.addControl('birthWeightUnits', new FormControl(null));
    this.formGroup.addControl('birthWeightInLb', new FormControl(null, { validators: [Validators.min(0)] }));
    this.formGroup.addControl('birthWeightInOz', new FormControl(null, { validators: [Validators.min(0)] }));
    this.formGroup.addControl('birthWeightInGrams', new FormControl(null, { validators: [Validators.min(0)] }));
    this.formGroup.addControl('birthWeightInKilograms', new FormControl(null, { validators: [Validators.min(0)] }));
    this.formGroup.addControl('birthLength', new FormControl(null, { validators: [Validators.min(0)] }));
    this.formGroup.addControl('birthLengthUnits', new FormControl(null));
    this.formGroup.addControl('prenatalCare', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('birthComplications', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('birthComplicationsDescription', new FormControl(null));
    this.formGroup.addControl('ivOrOxygen', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('ivOrOxygenLength', new FormControl(null));
    this.formGroup.addControl('geneticTesting', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('geneticTestingDescription', new FormControl(null));
    this.formGroup.addControl(
      'medicalDiagnosis',
      new FormControl(null, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl('medicalConditions', new FormArray([]));
    this.formGroup.addControl('hospitalized', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('hospitalizedDescription', new FormControl(null));
    this.formGroup.addControl('surgery', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('surgeryDescription', new FormControl(null));
    this.formGroup.addControl(
      'medication',
      new FormControl(null, {
        updateOn: 'change',
      })
    );
    this.formGroup.addControl(
      'medicationDescription',
      new FormControl(
        null,
        conditionalValidator(() => this.formGroup.get('medication').value === true, Validators.required)
      )
    );
    this.formGroup.addControl('immunization', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('immunizationDescription', new FormControl(null));
    this.formGroup.addControl('allergy', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('allergyDescription', new FormControl(null));
    this.formGroup.addControl('precaution', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('precautionDescription', new FormControl(null));
    this.formGroup.addControl('behaviorConcerns', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('behaviorConcernsDescription', new FormControl(null));
    this.formGroup.addControl('interactionConcern', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('interactionConcernDescription', new FormControl(null));
    this.formGroup.addControl('interactionDifference', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('interactionDifferenceDescription', new FormControl(null));
    this.formGroup.addControl('documentedDelay', new FormControl(null, { updateOn: 'change' }));
    this.formGroup.addControl('documentedDelayAreas', new FormControl(null));
    this.formGroup.addControl('recordedDelayDescription', new FormControl(null));
    this.formGroup.addControl('birthAdditionalInformation', new FormControl(null));
    this.formGroup.addControl('documentType', new FormControl(null));
    this.formGroup.addControl('documentTitle', new FormControl(null));

    if (!this.isQuestionnaire) {
      this.formGroup.addControl(
        'healthConcerns',
        new FormControl(null, {
          updateOn: 'change',
          validators: [Validators.required],
        })
      );
      this.formGroup.controls.medicalDiagnosis.setValidators(Validators.required);
    }

    this.formGroup.get('medication').valueChanges.subscribe((x) => {
      this.formGroup.get('medicationDescription').updateValueAndValidity();
    });
  }

  weightTypeChanged() {
    this.formGroup.patchValue({
      birthWeightInLb: null,
      birthWeightInOz: null,
      birthWeightInGrams: null,
      birthWeightInKilograms: null,
    });
  }

  lengthUnitsChanged() {
    this.formGroup.patchValue({ birthLength: null });
  }

  appendQuestionnaireMedicalConditions() {
    if (this.questConditionsNotImported) {
      const observables = [];
      this.questConditionsNotImported.forEach((medicalCondition) => {
        observables.push(
          this.medicalConditionService.addIntakeMedicalCondition(medicalCondition, this.intake?.caseId).pipe(
            catchError(() => {
              console.log('Error');
              return of(undefined);
            })
          )
        );
      });
      forkJoin(observables).subscribe(() => {
        this.loadData();
        this.questConditionsNotImported = null; // Resets Import Button
      });
    }
  }

  openIowaEarlyAccessDiagnosedConditionsEligibilityList() {
    window.open(this.databaseLinksService.getDatabaseLink('iowaEarlyAccessDiagnosedConditionsEligibilityList'), '_blank');
  }

  private updateFieldsValueAndVisibility() {
    const referral = this.questionnaire.referral;
    /* If the questionnaire respondent is the same as the referral source,
       the API provides us with the referral record so we can hide certain fields. */
    if (referral) {
      if (referral.referralReasonInfo?.referralReasonBornInWeeks) {
        this.setControlToNull('gestationalAgeWeeks');
      }
      if (referral.referralReasonInfo?.referralReasonBornInDays) {
        this.setControlToNull('gestationalAgeDays');
      }

      const hasWeight =
        referral.referralReasonInfo?.referralReasonBirthInLb ||
        referral.referralReasonInfo?.referralReasonBirthInOz ||
        referral.referralReasonInfo?.referralReasonBirthInGrams ||
        referral.referralReasonInfo?.referralReasonBirthInKilograms;

      if (referral.referralReasonInfo?.referralReasonBirthWeightUnits && hasWeight) {
        this.setControlToNull('birthWeightUnits');
      }
      if (referral.referralReasonInfo?.referralReasonBirthInLb) {
        this.setControlToNull('birthWeightInLb');
      }
      if (referral.referralReasonInfo?.referralReasonBirthInOz) {
        this.setControlToNull('birthWeightInOz');
      }
      if (referral.referralReasonInfo?.referralReasonBirthInGrams) {
        this.setControlToNull('birthWeightInGrams');
      }
      if (referral.referralReasonInfo?.referralReasonBirthInKilograms) {
        this.setControlToNull('birthWeightInKilograms');
      }
      if (referral.referralReasonInfo?.referralReasonNameMedicallyDiagnosedCondition) {
        this.setControlToNull('birthWeightInKilograms');
      }
    }
  }

  medicalConditionUpdatedEmit(e: boolean) {
    this.medicalConditionUpdated.emit(e);
  }

  private setControlToNull(controlName: string) {
    this.shownFields[controlName] = false;
    this.formGroup.controls[controlName].setValue(null);
    this.formGroup.controls[controlName].setValidators(null);
    this.formGroup.controls[controlName].updateValueAndValidity();
  }
}
