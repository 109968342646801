import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DhhPendingAppointmentDto } from '../../../../models/DhhDtos';
import { DhhService } from '../../../../services/dhh.service';
import { openPdfWindow } from 'src/app/shared/windowHelpers';

@Component({
  selector: 'app-dhh-print-appointment-confirmation-letter',
  templateUrl: './dhh-print-appointment-confirmation-letter.component.html',
  styleUrls: ['./dhh-print-appointment-confirmation-letter.component.scss'],
})
export class DhhPrintAppointmentConfirmationLetterComponent implements OnInit {
  pendingAppointments: DhhPendingAppointmentDto[] = [];
  displayedColumns = [
    'select',
    'appointmentDate',
    'startTime',
    'endTime',
    'learnerName',
    'dateOfBirth',
    'appointmentType',
    'audiologists',
    'birthHospital',
  ];
  isPrinting = false;

  get canPrint() {
    return this.pendingAppointments.some((a) => a.selected);
  }

  constructor(
    private readonly dhhService: DhhService,
    public dialogRef: MatDialogRef<DhhPrintAppointmentConfirmationLetterComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (!!data.selectedLearnerAppointmentId)
      data.pendingAppointments?.forEach((a) => (a.selected = a.learnerAppointmentId === data.selectedLearnerAppointmentId));
    else data.pendingAppointments?.forEach((a) => (a.selected = true));
    this.pendingAppointments = data.pendingAppointments;
  }

  ngOnInit(): void {}

  onCancel() {
    this.dialogRef.close();
  }

  onPrint() {
    this.isPrinting = true;
    const selectedAppointments = this.pendingAppointments.filter((a) => a.selected)?.map((a) => a.learnerAppointmentId);

    if (selectedAppointments.length === 0) {
      this.dhhService.handleError('Can not print Confirmation Letters', {
        message: 'No learner was selected, hence no letter can be printed.',
      });

      this.isPrinting = false;
      return;
    }

    this.dhhService.getPendingAppointmentsConfirmationLetters(selectedAppointments).subscribe({
      next: (res) => {
        if (res.succeeded) {
          res.value.forEach((learnerAppointment) => {
            if (learnerAppointment.confirmationLetterId) {
              this.viewConfirmationLetter(learnerAppointment.learnerId, learnerAppointment.confirmationLetterId);
            }
          });
        } else {
          this.dhhService.handleError('Can not print Confirmation Letters', res);
        }
      },
      error: (error) => {
        this.dhhService.handleError('Can not print Confirmation Letters', {
          message: error.error,
        });
      },
      complete: () => {
        this.isPrinting = false;
      },
    });
  }

  private viewConfirmationLetter(learnerId, documentId) {
    openPdfWindow(learnerId, documentId);
  }
}
