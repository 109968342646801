import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';

@Component({
  selector: 'app-audiogram-dialog',
  templateUrl: './audiogram-dialog.component.html',
  styleUrls: ['./audiogram-dialog.component.scss'],
})
export class AudiogramDialogComponent implements OnInit {
  shortDateFormat = shortDateFormat;
  constructor(public dialogRef: MatDialogRef<AudiogramDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {}
  ngOnInit(): void {}

  onClose() {
    this.dialogRef.close();
  }
}
