<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>{{ title }}</h3>

<div mat-dialog-content class="pt-3 pb-5" ariaLabel="title">
  <mat-card>
    <form [formGroup]="formGroup">
      <app-text-field formControlName="searchTerm" label="Search"></app-text-field>

      <div class="row">
        <div class="col-md-6">
          <app-autocomplete
            class="w-100"
            [options]="jobTitleOptions"
            label="Filter by Job Title"
            formControlName="jobTitle"
            (selected)="onFilter()"
          >
          </app-autocomplete>
        </div>

        <div *ngIf="data?.showBuildingFilter" class="col-md-6">
          <app-autocomplete
            class="w-100"
            [options]="buildingOptions"
            label="Filter by Building"
            formControlName="building"
            (selected)="onFilter()"
          >
          </app-autocomplete>
        </div>

        <div *ngIf="data?.showAeaFilter" class="col-md-6">
          <app-autocomplete class="w-100" [options]="aeaOptions" label="Filter by Agency" formControlName="aea" (selected)="onFilter()">
          </app-autocomplete>
        </div>
      </div>
    </form>
  </mat-card>

  <mat-card class="mt-3 p-0">
    <div class="table-overflow app-table" #teamMemberTable>
      <table mat-table [dataSource]="dataSource" matSort class="w-100">
        <ng-container matColumnDef="fullName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
          <td mat-cell *matCellDef="let element">
            {{ element.fullName }}
            {{ element.jobTitle ? ', ' + element.jobTitle : '' }}
          </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
          <td mat-cell *matCellDef="let element">
            {{ element.email }}
          </td>
        </ng-container>

        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef>Actions</th>
          <td mat-cell *matCellDef="let element">
            <button
              mat-flat-button
              attr.aria-label="singleSelect ? 'Select' : element.isAdded ? 'Remove' : 'Add'"
              [color]="element.isAdded ? 'warn' : 'accent'"
              tabindex="0"
              (click)="onToggleTeamMember(element)"
            >
              {{ singleSelect ? 'Select' : element.isAdded ? 'Remove' : 'Add' }}
            </button>
          </td>
        </ng-container>

        <ng-container matColumnDef="noResults">
          <td mat-footer-cell *matFooterCellDef colspan="2">
            <i>{{ loading ? 'Loading...' : 'No results...' }}</i>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        <tr
          [hidden]="dataSource?.data?.length > 0"
          mat-footer-row
          *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"
        ></tr>
      </table>
      <app-table-paginator
        *ngIf="currentPageInfo.length > 0"
        [length]="currentPageInfo.length"
        [pageSize]="currentPageInfo.pageSize"
        [pageIndex]="currentPageInfo.pageIndex"
        (page)="pageChanged($event)"
      ></app-table-paginator>
    </div>
  </mat-card>
</div>

<div mat-dialog-actions class="actions">
  <button mat-button aria-label="Cancel Button" class="background-color--muted" (click)="onCancel()" tabindex="0">Cancel</button>
  <button mat-flat-button aria-label="Add to List" color="primary" (click)="onAccept()" tabindex="0" *ngIf="!singleSelect">
    Add to List
  </button>
</div>
