<div class="backdrop">
  <div class="pic">
    <div class="links-bar">
      <button mat-button mat-flat-button color="primary">ACHIEVE Family Portal User Guide</button>
      <button mat-button mat-flat-button color="primary">ACHIEVE Information & Additional Support</button>
      <button mat-button mat-flat-button color="primary">ACHIEVE Family Portal Getting Started Guide</button>
    </div>
    <img class="graphic" src="/assets/img/family-portal-graphic.png" alt="ACHIEVE Family Portal" />
    <div class="banner">
      <span class="title">The ACHIEVE Family Portal:</span>
      <ul>
        <li>
          <strong>Supports</strong> families and children transition seamlessly as they age from Early ACCESS services through Special
          Education.
        </li>
        <li>
          <strong>Empowers</strong> families, learners, and teams to collaborate in a shared system and allows for more informed decisions
          on what works best for each individual learner.
        </li>
        <li>
          <strong>Engages</strong> families and learners to view, print, and sign documents electronically, and view real-time progress on
          outcomes and goals so all learners can “achieve” and succeed.
        </li>
      </ul>
    </div>
  </div>
  <div class="form-wrapper">
    <div class="links-bar mobile-display">
      <button mat-button mat-flat-button color="primary">ACHIEVE Family Portal User Guide</button>
      <button mat-button mat-flat-button color="primary">ACHIEVE Information & Additional Support</button>
      <button mat-button mat-flat-button color="primary">ACHIEVE Family Portal Getting Started Guide</button>
    </div>
    <div class="form">
      <ng-content></ng-content>
    </div>
    <div class="banner mobile-display">
      <span class="title">The ACHIEVE Family Portal:</span>
      <ul>
        <li>
          <strong>Supports</strong> families and children transition seamlessly as they age from Early ACCESS services through Special
          Education.
        </li>
        <li>
          <strong>Empowers</strong> families, learners, and teams to collaborate in a shared system and allows for more informed decisions
          on what works best for each individual learner.
        </li>
        <li>
          <strong>Engages</strong> families and learners to view, print, and sign documents electronically, and view real-time progress on
          outcomes and goals so all learners can “achieve” and succeed.
        </li>
      </ul>
    </div>
  </div>
</div>
