import { Component, Input } from '@angular/core';
import { KeyValuePair } from '../../models/key-value-pair';

@Component({
  selector: 'app-progress-indicator-bar',
  templateUrl: './progress-indicator-bar.component.html',
  styleUrls: ['./progress-indicator-bar.component.scss'],
})
export class ProgressIndicatorBarComponent {
  @Input() progressStages: KeyValuePair[];
  @Input() activeStage: string;

  get activeStageIndex() {
    const activeItem = this.progressStages.find((item) => item.key === this.activeStage);
    return this.progressStages.indexOf(activeItem);
  }
}
