import { SelectionModel } from '@angular/cdk/collections';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { FileDocument } from 'src/app/shared/models/file-document';
import { DocumentService } from 'src/app/shared/services/document/document.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ReportingService } from '../../../shared/services/reporting/reporting.service';

@Component({
  selector: 'app-print-documents-modal',
  templateUrl: './print-documents-modal.component.html',
  styleUrls: ['./print-documents-modal.component.scss'],
})
export class PrintDocumentsModalComponent implements OnInit {
  documents: FileDocument[];
  shortDateFormat = shortDateFormat;
  dataSource: MatTableDataSource<FileDocument>;
  selection = new SelectionModel<FileDocument>(true, []);
  displayedColumns: string[] = ['actions', 'type', 'status', 'submittedOn'];
  type: 'all' | 'iep' | 'ifsp' = 'all';
  activeCall = false;
  skipStatus = false;

  constructor(
    @Inject(MAT_DIALOG_DATA) private dialogData: any,
    private dialogRef: MatDialogRef<PrintDocumentsModalComponent>,
    private documentService: DocumentService,
    private notificationService: NotificationService,
    private reportingService: ReportingService
  ) {
    this.documents = this.dialogData.documents
      .map((x) => {
        return {
          id: x.id,
          title: x.title,
          isSubmitted: x.isSubmitted,
          submittedOn: x.submittedOn,
          createdOn: x.createdOn,
          additionalId: x.additionalId || x.ifspId,
        } as FileDocument;
      })
      .filter((x) => !x.isUploaded || !x.excludeFromPrintAll);

    if (this.dialogData.type) {
      this.type = this.dialogData.type;
    }

    if (this.dialogData.skipStatus) {
      this.skipStatus = this.dialogData.skipStatus;
      this.displayedColumns = ['actions', 'type', 'submittedOn'];
    }
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.documents);
    this.dataSource.sortingDataAccessor = (item, property) => {
      switch (property) {
        case 'status':
          return item.isSubmitted;
        case 'submittedOn':
          return item.submittedOn ? item.submittedOn : item.createdOn;
        default:
          return item[property];
      }
    };
  }

  onClose() {
    this.dialogRef.close();
  }

  onPrint() {
    if (this.selection.selected.length > 0) {
      this.activeCall = true;

      const getMergedPdf = () => {
        const documentIds = this.selection.selected.map((x) => x.id);
        this.documentService.getMergedDocument(documentIds).subscribe(
          (res: any) => {
            if (res) {
              if (res.missingDocuments.length > 0) {
                this.notificationService.errorWithAction(
                  'Some of the selected documents do not exist.',
                  'Why?',
                  () => this.notificationService.alert(res.missingDocuments?.join('<br>'), 'Unable to locate the below documents'),
                  5000
                );
              }

              if (res.file) {
                const blob = this.b64toBlob(res.file, 'application/pdf');
                const blobUrl = URL.createObjectURL(blob);
                const oWindow = window.open(blobUrl);
                setTimeout(() => {
                  oWindow.print();
                }, 500);
                this.onClose();
              }
            }
          },
          (error) => {
            this.notificationService.errorWithAction(
              'None of the selected documents exist.',
              'Why?',
              () => this.notificationService.alert(error?.error?.join('<br>'), 'Unable to locate the below documents'),
              5000
            );
          },
          () => {
            this.activeCall = false;
          }
        );
      };

      const ifspDraft = this.selection.selected.find((x) => x.title.includes('IFSP') && !x.id);
      const iepDraft = this.selection.selected.find((x) => x.title.includes('IEP') && !x.id);
      if (ifspDraft) {
        this.reportingService.createIfspOutput(ifspDraft.additionalId, false).subscribe(
          (docId) => {
            ifspDraft.id = docId;
            getMergedPdf();
          },
          (error) => {
            this.activeCall = false;
            this.handleError('An error occured while retrieving IFSP output', error);
          }
        );
      } else if (iepDraft) {
        this.reportingService.createIepOutput(iepDraft.additionalId, null, false).subscribe(
          (docId) => {
            iepDraft.id = docId;
            getMergedPdf();
          },
          (error) => {
            this.activeCall = false;
            this.handleError('An error occured while retrieving IEP output', error);
          }
        );
      } else {
        getMergedPdf();
      }
    }
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    if (this.isAllSelected()) {
      this.selection.clear();
      return;
    }

    this.selection.select(...this.dataSource.data);
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: FileDocument): string {
    if (!row) {
      return `${this.isAllSelected() ? 'deselect' : 'select'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
  }

  private b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  handleError(errorTitle: string, error: any): void {
    let errorMessage = '';
    if (error && error.error && error.error.errors) {
      errorMessage = error.error.errors.map((e) => e.description)?.join(',');
    } else if (error && error.errors) {
      errorMessage = error.errors.map((e) => e.description)?.join(',');
    } else if (error && error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = JSON.stringify(error);
    }

    this.notificationService.errorWithAction(errorTitle, 'Why?', () => {
      this.notificationService.alert(errorMessage, errorTitle);
    });
  }
}
