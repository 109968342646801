<ng-container *ngIf="canViewWeightedMatrix">
  <h3 class="mt-0 mb-2">Weighted Enrollment Matrix for data collection purposes</h3>

  <mat-card class="my-0" *ngIf="weightedMatrix">
    <app-iep-weight-additional-questions
      *ngIf="showSpecialCircumstances && canEditWeightedMatrix"
      [caseSummary]="caseSummary"
      [iepId]="iepId"
      [amendmentId]="amendmentId"
      [weightedMatrix]="weightedMatrix"
      (questionsSubmitted)="questionsSubmitted($event)"
      [readOnly]="readOnly"
      [canReviewSpecialCircumstances]="canReviewSpecialCircumstances"
    ></app-iep-weight-additional-questions>

    <ng-container *ngIf="showRecommendation && caseSummary">
      <app-iep-weight-recommendation
        *ngIf="canEditWeightedMatrix"
        [caseSummary]="caseSummary"
        (historyChange)="history.refreshHistory()"
        [weightedMatrix]="weightedMatrix"
        [weightedMatrixLevels]="weightedMatrixLevels"
        [isRLevelScore]="isRLevelScore"
        [isAutoScore]="isAutoScore"
      ></app-iep-weight-recommendation>

      <ng-container *ngIf="servedStatusOptions.length > 0 && canEditWeightedMatrix" [formGroup]="formGroup" class="ml-4">
        <div class="row mb-3">
          <div class="col-lg-12">
            <label
              [ngClass]="{
                asterisk_input: formGroup.get('servedStatus').hasError('required')
              }"
            >
              Served Status:
            </label>
            <fieldset>
              <app-radio-group formControlName="servedStatus" [hideAsterisk]="true" [options]="servedStatusOptions"></app-radio-group>
            </fieldset>
          </div>
        </div>
      </ng-container>

      <app-weight-matrix-score-history
        #history
        [weightedMatrixLevels]="weightedMatrixLevels"
        [scoreHistory]="weightedMatrix?.weightedMatrixScoreHistory"
        [readOnly]="readOnly || !canEditWeightedMatrix"
      ></app-weight-matrix-score-history>
    </ng-container>
  </mat-card>
</ng-container>
