<div class="mb-2" *ngIf="shouldShowDhhBanner">
  <app-dhh-alert></app-dhh-alert>
</div>
<mat-card
  class="page-header"
  [ngClass]="{
    'background--removed': backgroundColor === 'none',
    'background--red': backgroundColor === 'red',
    'background--blue': backgroundColor === 'blue',
    'background--blue-dark': backgroundColor === 'blueDark',
    'background--purple': backgroundColor === 'purple',
    'background--yellow': backgroundColor === 'yellow',
    'page-header--condensed': condensed === true
  }"
>
  <h1
    [ngClass]="{
      'text-danger': pageTitleColor === 'red',
      'mat-display-1': isBold,
      'text-normal text-lg': !isBold
    }"
  >
    {{ pageTitle }}
    <ng-container *ngIf="isDhh">
      <i class="material-icons ml-2" style="color: #007a7c"> radio_button_on </i>
    </ng-container>
    <ng-container *ngIf="isDoNotTest">
      <i class="material-icons ml-2" style="color: #9b2242"> error </i>
    </ng-container>
    <ng-container *ngIf="isDoNotScreen">
      <i class="material-icons ml-2" style="color: #24327b"> indeterminate_check_box </i>
    </ng-container>
  </h1>

  <div class="page-header__nav hide-print">
    <ng-content select="[pageActions]"></ng-content>

    <!-- <button
      mat-flat-button
      attr.aria-label="{{ helpLinkLabel }}"
      (click)="onHelpToggled($event)"
      color="accent"
      class="mat-elevation-z0 help-link-color"
      *ngIf="helpLinks"
      tabindex="0"
    >
      {{ helpLinkLabel }}
    </button> -->

    <button
      mat-flat-button
      (click)="onGenericToggled()"
      color="{{ genericButtonColor }}"
      *ngIf="showGenericButton"
      tabindex="0"
      attr.aria-label="{{ genericButtonLabel }}"
    >
      {{ genericButtonLabel }}
    </button>

    <button
      mat-flat-button
      (click)="onNavBackToggled($event)"
      class="background-color--white mat-elevation-z0 help-link-color"
      *ngIf="navBack"
      tabindex="0"
      attr.aria-label="{{ navBackLabel }}"
    >
      {{ navBackLabel }}
    </button>
    <button
      *ngIf="showSubmitBtn"
      [disabled]="submitDisabled"
      (click)="onSubmitToggled()"
      color="primary"
      mat-raised-button
      aria-label="Complete"
      tabindex="0"
      class="help-link-color"
    >
      Complete
    </button>
  </div>
</mat-card>
