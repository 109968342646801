import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { DhhLearnerAppointmentParticipantDto } from '../../../../models/DhhDtos';

@Component({
  selector: 'app-dhh-learner-appointment-participant-table',
  templateUrl: './dhh-learner-appointment-participant-table.component.html',
  styleUrls: ['./dhh-learner-appointment-participant-table.component.scss'],
})
export class DhhLearnerAppointmentParticipantTableComponent implements OnInit {
  @Input() participants: DhhLearnerAppointmentParticipantDto[];
  dataSource = new MatTableDataSource<DhhLearnerAppointmentParticipantDto>([]);
  displayedColumns = ['fullName', 'email', 'relationship', 'streetAddress', 'zipCode', 'city', 'state'];

  constructor() {}

  ngOnInit(): void {
    this.dataSource.data = this.participants;
    this.dataSource.sortingDataAccessor = (item: DhhLearnerAppointmentParticipantDto, columnId) => {
      switch (columnId) {
        case 'relationship':
          return item.familyRelationship?.label;
        default:
          return item[columnId];
      }
    };
  }
}
