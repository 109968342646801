<mat-accordion *ngIf="caseSummary" class="accordion" multi>
  <mat-expansion-panel class="mat-elevation-z0 no-body-padding">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <h3 class="my-0 text-normal" *ngIf="mostRecentProceduralSafeguard">
          The <i>Procedural Safeguards Manual for Parents</i> was last reviewed and provided to the parent(s) on
          {{ mostRecentProceduralSafeguard.providedOn | dateFormat }}. The parent(s)
          {{ mostRecentProceduralSafeguard.acceptedPrintedCopy ? 'accepted' : 'declined' }}
          the printed copy.
        </h3>

        <h3 class="my-0 text-normal" *ngIf="!mostRecentProceduralSafeguard">
          The parent has not yet been provided a printed copy of the
          <i>Procedural Safeguards Manual for Parents</i>
        </h3>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-help-directional-text *ngIf="!mostRecentProceduralSafeguard">
      <p class="mb-2"><i>Procedural Safeguards</i> must be provided to the family in order to access any consent(s)</p>
    </app-help-directional-text>
    <app-procedural-safeguards-list
      (mostRecentChanged)="onChanged($event)"
      [caseId]="intake.caseId"
      [showProvidedOn]="false"
      [learnerId]="caseSummary.learnerId"
    ></app-procedural-safeguards-list>
  </mat-expansion-panel>
</mat-accordion>

<form [formGroup]="formGroup">
  <mat-card class="card-spacing-top">
    <mat-card-title>Screening Information</mat-card-title>
    <div>
      <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-4">
          <fieldset>
            <app-radio-group
              [disabledState]="intakeLocked"
              class="mr-2"
              [options]="yesNoNotSureOptions"
              formControlName="preReferralScreeningToolUsed"
              label="Was a pre-referral screening tool used?"
            ></app-radio-group>
          </fieldset>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-8" *ngIf="formGroup.get('preReferralScreeningToolUsed').value === unknownYesNo.Yes">
          <app-autocomplete-chips
            [options]="screeningToolOptions"
            formControlName="preReferralScreeningToolIds"
            label="Name of Pre-Referral Screening Tool"
            [disabledState]="intakeLocked"
          ></app-autocomplete-chips>
          <app-text-field
            *ngIf="formGroup.controls.preReferralScreeningToolIds.value.includes(screeningToolOtherId)"
            label="Enter tool name..."
            formControlName="preReferralScreeningToolOther"
          ></app-text-field>
        </div>
      </div>
      <app-post-referral-consent
        (consentFormsLoaded)="onConsentFormsLoaded($event)"
        *ngIf="caseSummary"
        [caseSummary]="caseSummary"
        [inCard]="true"
        [psgProvided]="!!mostRecentProceduralSafeguard"
      ></app-post-referral-consent>

      <div class="row mt-3" *ngIf="formGroup.get('postReferralScreening').value">
        <div class="col-md-12 col-xl-4" *ngIf="formGroup.get('postReferralScreening').value === true">
          <app-autocomplete-chips
            [options]="postScreeningToolOptions"
            formControlName="postReferralScreeningToolIds"
            label="Name of Post-Referral Screening Tool"
            [disabledState]="intakeLocked"
          ></app-autocomplete-chips>
          <app-text-field
            *ngIf="formGroup.controls.postReferralScreeningToolIds.value.includes(screeningToolOtherId)"
            [isBankableField]="true"
            [bankableFieldId]="bankableFieldIds.nextStepsForm.postReferralScreeningToolOther"
            [bankableFieldChildFname]="childFirstName"
            label="Enter tool name..."
            formControlName="postReferralScreeningToolOther"
          ></app-text-field>
        </div>
        <div class="col-md-4" *ngIf="formGroup.get('postReferralScreening').value">
          <app-date-picker
            [disabled]="intakeLocked"
            controlName="postReferralScreeningCompleted"
            [max]="today"
            label="Date Screening Completed"
          ></app-date-picker>
        </div>
        <div class="col-md-4" *ngIf="formGroup.get('postReferralScreening').value">
          <app-text-field
            [disabledState]="intakeLocked"
            label="Screening Conducted By"
            formControlName="postReferralScreeningConductedBy"
          ></app-text-field>
        </div>
      </div>

      <ng-container *ngIf="formGroup.get('postReferralScreening').value">
        <hr class="input__divider my-2" />
        <fieldset>
          <app-radio-group
            [disabledState]="intakeLocked"
            [options]="interpretationOptions"
            formControlName="postReferralScreeningResults"
            [wrap]="true"
            [column]="true"
            label="Interpretation and Results"
          ></app-radio-group>
        </fieldset>
      </ng-container>

      <ng-container
        *ngIf="formGroup.get('postReferralScreeningResults').value === 'MayHaveConcerns' && formGroup.get('postReferralScreening').value"
      >
        <hr class="input__divider" />

        <app-checkbox
          [disabled]="intakeLocked"
          [options]="developmentalAreaOptions"
          controlName="postReferralScreeningConcerns"
          label="Developmental Areas (check all that apply)"
        >
        </app-checkbox>
        <hr class="input__divider mb-2" />
      </ng-container>

      <app-textarea
        [disabledState]="intakeLocked"
        *ngIf="formGroup.get('postReferralScreening').value"
        [rows]="4"
        label="Summary (optional)"
        formControlName="postReferralScreeningSummary"
      ></app-textarea>
      <app-textarea
        [disabledState]="intakeLocked"
        [useInputGroup]="true"
        [isBankableField]="true"
        [bankableFieldId]="bankableFieldIds.nextStepsForm.postReferralScreeningRecommendationFollowUp"
        [bankableFieldChildFname]="childFirstName"
        *ngIf="formGroup.get('postReferralScreening').value && !famWithdrew"
        label="Recommendations and Follow-Up Needed for Child / Family"
        formControlName="postReferralScreeningRecommendationFollowUp"
      >
        <ng-container append>
          <app-sentence-starters-button
            [sentenceStarters]="sentenceStarters"
            [isSuffix]="true"
            (selectedQuestion)="onSelectedQuestion($event, formGroup.get('postReferralScreeningRecommendationFollowUp'))"
          ></app-sentence-starters-button>
        </ng-container>
      </app-textarea>

      <div class="display-flex justify-content-end align-items-center" *ngIf="formGroup.get('postReferralScreening').value">
        <button class="mr-1" mat-raised-button aria-label="Print Results" (click)="printResults()" tabindex="0">Print Results</button>
        <button
          [disabled]="intakeLocked"
          class="ml-1"
          color="accent"
          (click)="startPwnProcess(false)"
          mat-raised-button
          aria-label="Create PWN"
          tabindex="0"
        >
          Create PWN
        </button>
        <button
          class="ml-1"
          *ngIf="!nextSteps?.taggedItem?.taggedForPwn"
          class="ml-1"
          mat-raised-button
          aria-label="Tag for Prior Written Notice Button"
          color="primary"
          (click)="tag(true)"
          tabindex="0"
        >
          Tag for Prior Written Notice
        </button>
        <button
          class="ml-1"
          *ngIf="nextSteps?.taggedItem?.taggedForPwn"
          mat-raised-button
          aria-label="Untag for Prior Written Notice"
          color="primary"
          (click)="tag(false)"
          tabindex="0"
        >
          Untag for Prior Written Notice
        </button>
      </div>
    </div>
  </mat-card>

  <mat-card *ngIf="!isMoveInOrEligibleLegacyLearner" class="card-spacing-top">
    <mat-card-title>Next Steps for Eligibility</mat-card-title>
    <app-page-alert [alert]="eligibleConditionAlert" role="status" aria-live="polite"></app-page-alert>
    <div class="mt-3 display-flex align-items-center">
      <mat-label class="mr-3">Child is...</mat-label>
      <fieldset>
        <app-radio-group
          class="mb-2"
          [disabledState]="intakeLocked"
          formControlName="eligibilitySubmittedOnIntake"
          [options]="eligibilityOptions"
        >
        </app-radio-group>
      </fieldset>
    </div>

    <hr class="input__divider mb-2" *ngIf="formGroup.get('eligibilitySubmittedOnIntake').value" />

    <div class="" *ngIf="formGroup.get('eligibilitySubmittedOnIntake').value">
      <h3 class="marginFix2">List Diagnosis(es)</h3>
      <div class="row">
        <div class="col">
          <div class="table-overflow">
            <table mat-table [dataSource]="dataSource" class="w-100">
              <!-- Name Column -->
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let condition">
                  {{ condition.name }}
                </td>
              </ng-container>
              <!-- ICD Code Column -->
              <ng-container matColumnDef="icdCode">
                <th mat-header-cell *matHeaderCellDef>ICD Code</th>
                <td mat-cell *matCellDef="let condition">
                  {{ condition.icdCode }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              <ng-container matColumnDef="noResults">
                <td mat-footer-cell *matFooterCellDef colspan="7">
                  <i>No results...</i>
                </td>
              </ng-container>
              <tr
                [hidden]="dataSource?.data?.length > 0"
                mat-footer-row
                *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"
              ></tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</form>
