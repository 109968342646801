import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { KeyValuePair } from '../../models/key-value-pair';

@Component({
  selector: 'app-table-paginator',
  templateUrl: './table-paginator.component.html',
  styleUrls: ['./table-paginator.component.scss'],
})
export class TablePaginatorComponent implements OnInit {
  @Input() length;
  @Input() pageSize: number;
  @Input() pageIndex: number;
  @Input() defaultPageSize = 20;
  @Input() pageSizeOptions: KeyValuePair[] = [
    new KeyValuePair(1, 1),
    new KeyValuePair(10, 10),
    new KeyValuePair(20, 20),
    new KeyValuePair(25, 25),
  ];

  @Output() page = new EventEmitter<PageEvent>();

  formGroup: FormGroup;

  get friendlyPageIndex(): number {
    return this.pageIndex + 1;
  }

  get amountOfPages(): number {
    return Math.ceil(this.length / this.pageSize) || 1;
  }

  constructor() {}

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      pageSize: new FormControl(this.defaultPageSize),
    });
    this.initializeFormSubscriptions();
    this.addMaxPageSizeOption();
  }

  onPageBack(): void {
    this.page.emit({
      length: this.length,
      pageSize: this.pageSize,
      pageIndex: this.pageIndex - 1,
    } as PageEvent);
  }

  onPageForward(): void {
    this.page.emit({
      length: this.length,
      pageSize: this.pageSize,
      pageIndex: this.pageIndex + 1,
    } as PageEvent);
  }

  private initializeFormSubscriptions(): void {
    this.formGroup.get('pageSize').valueChanges.subscribe((value: number) => this.handlePageSizeChange(value));
  }

  private handlePageSizeChange(value: number): void {
    this.pageIndex = 0;
    this.pageSize = value;

    this.page.emit({
      length: this.length,
      pageSize: this.pageSize,
      pageIndex: this.pageIndex,
    } as PageEvent);
  }

  private addMaxPageSizeOption(): void {
    // Ensure we're not duplicating last value or adding a value smaller than is in the list
    if (this.length > this.pageSizeOptions[this.pageSizeOptions.length - 1].value) {
      this.pageSizeOptions.push(new KeyValuePair(this.length, this.length));
    }
  }
}
