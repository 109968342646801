<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mb-0">{{ pageTitle }}</h3>

<div mat-dialog-content class="py-3 mt-3">
  <mat-card class="my-0">
    <ng-container [formGroup]="formGroup">
      <div class="row">
        <div class="col-lg-6 no-form-field-padding">
          <app-autocomplete label="Frequency" formControlName="frequencyLabel" [options]="frequencyOptions"></app-autocomplete>
        </div>

        <div class="col-lg-6 no-form-field-padding">
          <app-autocomplete label="Decibel" formControlName="decibelLabel" [options]="decibelOptions"></app-autocomplete>
        </div>
      </div>
    </ng-container>
  </mat-card>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button aria-label="Cancel" (click)="onCancel()" class="background-color--muted mr-1" tabindex="0">Cancel</button>

  <button mat-raised-button aria-label="Submit" color="primary" (click)="onSubmit()" [disabled]="formGroup.invalid" tabindex="0">
    Submit
  </button>
</div>
