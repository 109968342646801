<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mb-0">Print Confirmation Letters</h3>

<div mat-dialog-content class="py-3 mt-3">
  <mat-card class="my-0">
    <app-dhh-pending-appointments-table
      [pendingAppointments]="pendingAppointments"
      [displayedColumns]="displayedColumns"
    ></app-dhh-pending-appointments-table>
  </mat-card>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button aria-label="Cancel" color="primary" (click)="onCancel()" tabindex="0">Cancel</button>

  <button
    mat-raised-button
    aria-label="Print"
    color="primary"
    (click)="onPrint()"
    [disabled]="!canPrint"
    [isBusy]="isPrinting"
    tabindex="0"
  >
    Print
  </button>
</div>
