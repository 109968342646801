<div class="prefill">
  <div class="prefill__input">
    <div class="input-qr-header">
      <label
        class="input-qr-header__label text-md vertical-label"
        [ngClass]="{
          asterisk_input: control.hasError('required')
        }"
        [innerHTML]="label"
      ></label>
      <div class="input-qr-header__button" (click)="replaceWithSourceValue()" *ngIf="dataSourceValue && !undoValue && !disabledState">
        <div
          class="input-qr-header__button-icon"
          [matTooltip]="dataSourceValue === '' ? 'QR not available' : 'Replace with QR'"
          [ngClass]="{ 'input-qr-header__button-icon--disabled': dataSourceValue === '' }"
        >
          <mat-icon aria-label="QR Arrow Symbol">call_merge</mat-icon>
        </div>
      </div>

      <div class="input-qr-header__button" *ngIf="undoValue">
        <p class="input-qr-header__button-icon mb-0" (click)="undoReplaceWithSourceValue()">Undo</p>
      </div>
    </div>
    <div class="input-qr-text__wrapper" aria-label="Questionnaire reply" *ngIf="dataSourceValue && dataSourceValue !== ''">
      QR: {{ dataSourceValue }}
    </div>

    <mat-form-field class="w-100">
      <!-- <mat-label
        [ngClass]="{
          asterisk_input: control.hasError('required')
        }"
        >{{ label }}</mat-label
      > -->
      <textarea
        matInput
        [class]="klass"
        id="{{ controlId }}"
        [formControl]="control"
        autocomplete="off"
        [placeholder]="placeholder"
        [attr.maxLength]="maxLength > 0 ? maxLength + 1 : null"
        [readonly]="readOnly"
        [attr.disabled]="disabledState ? true : null"
        (blur)="internalBlur($event)"
        [rows]="rows ? rows : 2"
        attr.aria-required="{{ control.hasError('required') ? 'true' : 'false' }}"
      ></textarea>

      <mat-error>
        <app-field-error-display [control]="control"></app-field-error-display>
      </mat-error>
    </mat-form-field>
  </div>
</div>
