<div class="row">
  <div class="col">
    <div class="table-overflow">
      <table mat-table [dataSource]="dataSource" class="w-100">
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let model; let i = index">
            <ng-container *ngIf="i !== dataSource.filteredData.length - 1 && !editingModel">
              <button aria-label="Edit" mat-icon-button color="primary" (click)="edit(model)" title="Edit" tabindex="0">
                <mat-icon>edit</mat-icon>
              </button>
              <button aria-label="Close Button" mat-icon-button color="warn" (click)="remove(model)" title="Remove" tabindex="0">
                <mat-icon>close</mat-icon>
              </button>
            </ng-container>
            <ng-container *ngIf="model === editingModel">
              <button
                aria-label="Save Button"
                mat-icon-button
                (click)="save()"
                [disabled]="modelFormGroup.invalid"
                title="Save"
                color="primary"
                tabindex="0"
              >
                <mat-icon>save</mat-icon>
              </button>
              <button aria-label="Clear Icon" mat-icon-button color="warn" (click)="cancel()" title="Cancel" tabindex="0">
                <mat-icon>clear</mat-icon>
              </button>
            </ng-container>
            <ng-container *ngIf="i === dataSource.filteredData.length - 1 && !editingModel">
              <button mat-icon-button aria-label="Add Icon" color="primary" (click)="edit(model)" tabindex="0">
                <mat-icon>add_circle</mat-icon>
              </button>
            </ng-container>
          </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef>Diagnosis</th>
          <td mat-cell *matCellDef="let model">
            <app-inline-edit [editing]="editingModel === model">
              <ng-container viewMode>
                {{ model.name }}
              </ng-container>
              <ng-container editMode [formGroup]="modelFormGroup">
                <app-text-field label="Diagnosis" formControlName="name"> </app-text-field>
              </ng-container>
            </app-inline-edit>
          </td>
        </ng-container>

        <!-- ICD Code Column -->
        <ng-container matColumnDef="icdCode">
          <th mat-header-cell *matHeaderCellDef>ICD Code</th>
          <td mat-cell *matCellDef="let model">
            <app-inline-edit [editing]="editingModel === model">
              <ng-container viewMode>
                {{ model.icdCode }}
              </ng-container>
              <ng-container editMode [formGroup]="modelFormGroup">
                <app-text-field label="ICD Code" formControlName="icdCode"> </app-text-field>
              </ng-container>
            </app-inline-edit>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      </table>
      <p *ngIf="!dataSource.data">No data yet...</p>
    </div>
  </div>
</div>
