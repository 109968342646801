import { KeyValuePair } from 'src/app/shared/models/key-value-pair';

export class DhhAssistiveTechnologyType {
  static HearingAid = '8f23d130-3ecb-4592-8782-7f02dfa1d641';
  static Receiver = '739b6dff-63b5-4689-8f06-db1d1ad67fa1';
  static Other = '14a3db5a-54fc-417e-8bc0-ecb5298a5648';
}

export class DhhAssistiveTechnologyStatus {
  static Active = '016f23b9-70b1-4448-9dbd-416f8d71e2ab';
}

export class DhhAssistiveTechnologyBatterySize {
  static Other = 'f768ccf2-ccf4-4fd6-a78e-dd8fd76dfc4c';
}

export class DhhAssistiveTechnologyOwner {
  static Aea = 'fe38e306-98dd-42aa-8f5d-ff94b690bb8c';
  static District = 'b120a84b-84ab-48a6-90c7-476381f6352f';
  static Building = '9567e30f-b05b-4419-a863-1ea3ce9f029a';
  static OutOfStateAgency = '611417e4-aa48-4735-aa9f-9eef1344acd6';
  static Family = 'ab34dac6-b3a8-4eec-9398-b577a6e3187d';
}

export class DhhTestingEquipmentStatus {
  static Active = '6662f6a8-a03a-41ce-93dc-1d06b04db442';
  static Inactive = 'c71fb055-cc44-43f8-8d51-980b12d65ab9';
  static Lost = '6cbadf9f-8864-41b4-a05d-b1b14e44b0de';
  static Obsolete = 'de26b1f1-065c-460e-8eb6-f00749b2fb8b';
}

export class DhhTestingEquipmentType {
  static Audiometer = '0ff6e844-ef9a-40fd-bf1c-dc43726b4476';
  static Abr = '4bc9a309-8141-4788-949e-7b2bf27f3965';
  static Other = 'd60f7594-001c-4aca-b34f-b3b34610701f';
}

export class DhhTestingEquipmentSetting {
  static Both = 'b195b853-f95c-42f9-975d-e5548b5e3036';
}

export class DhhTestRating {
  static Good = 'c239e12a-eb7c-4121-a64d-5c278a8175c4';
}

export class DhhPurposeOfTesting {
  static Other = 'f3a7e945-1d46-4381-a907-d87e1cd95d49';
}

export class DhhFamilyRecommendation {
  static MedicalReferral = 'b2c3d4e5-f678-9012-3456-7890abcde123';
  static Other = '67890123-4567-8901-2345-67890abcdef1';
}

export class DhhSchoolRecommendation {
  static Other = '4b5c6d7e-8f9a-0b1c-2d3e-4f5a6b7c8d9e';
}

export class DhhAction {
  static FollowUpBooth = '8b9c0d1e-2f3a-4b5c-6d7e-8f9a0b1c2d3e';
  static FollowUpNonBooth = '9c0d1e2f-3a4b-5c6d-7e8f-9a0b1c2d3e4f';
  static Other = '1e2f3a4b-5c6d-7e8f-9a0b-1c2d3e4f5a6b';
}

export class DhhTestingEquipmentAudiometerTypes {
  static Headphones = '4c79f1d0-b68f-4202-9cae-974a53d22e74';
  static Inserts = '43bf49b5-0f1b-4695-ad1b-01d4d350452b';
  static Soundfield = '8fde5fee-2dbf-4470-a0fd-497ea7981d7e';
}

export class DhhTestingEquipmentVraTypes {
  static Headphones = '4c79f1d0-b68f-4202-9cae-974a53d22e74';
  static Inserts = '43bf49b5-0f1b-4695-ad1b-01d4d350452b';
  static Soundfield = '8fde5fee-2dbf-4470-a0fd-497ea7981d7e';
}

export class DhhTestingEquipmentAbrTypes {
  static Inserts = 'b090dd22-6294-4b3f-b1c3-d5d34fbb39e0';
  static Headphones = 'c98882f4-83be-4140-a212-7034f3f40eda';
  static Bone = '9407bf5c-ab67-4048-b3bf-520156895213';
}
export class DhhHearingLossLevel {
  static Other = 'd0e1f2a3-b4c5-6d7e-8f9a-b0c1d2e3f4a5';
}

export class DhhHearingLossType {
  static Other = 'd4e5f6a7-b8c9-0d1e-2f3a-b4c5d6e7f8a9';
}

export class DhhSoundLevel {
  static Other = '7a8b9c0d-1e2f-3a4b-5c6d-7e8f9a0b1c2d';
}

export class DhhAabr {
  static Other = '67f0a8b9-c1d2-4e3f-4a5b-6c7d8e9f0a1b';
}

export class DhhScreeningCriteriaFrequencyIds {
  static OneHundredTwentyFive = 'D59144AD-3562-44DC-972A-CABA9B80EFA9';
  static TwoHundredFifty = '1C1F2941-665A-47B4-9BB5-53F74BBB0C6B';
  static FiveHundred = '741D665B-6A9C-4A91-8A2C-39D8C8574600';
  static SevenHundredFifty = '848495A0-93A7-46AE-9F75-8679F0CCEFFD';
  static OneThousand = 'CA02450A-2A27-4228-93E7-D2CE0ED27924';
  static OneThousandFiveHundred = 'DDDE6E7F-C121-465A-B10F-04928F5221BD';
  static TwoThousand = '0D71EC0A-3BF3-4291-A9E8-D23B41D24292';
  static ThreeThousand = '5D0692B5-0024-496E-BF45-534CE117CCD2';
  static FourThousand = '9E475F4E-4290-494F-8C7B-D4DB5E3F2BA8';
  static SixThousand = '1F7B3122-C749-4758-94C7-3FEAA4F341E9';
  static EightThousand = '1B853C1D-A673-44C1-924F-EF26C905E447';
}

export class DhhTestAudiogramConductionIds {
  static RightEarAirConductionUnmasked = 'F5FBDB9E-2D3D-4B36-9FFC-81CC52815380';
  static LeftEarAirConductionUnmasked = 'F2B5612F-C273-4524-BF5C-2005120B90E2';
  static RightEarAirConductionMasked = '41367F5D-FA12-437C-9E39-B4A0C3333817';
  static LeftEarAirConductionMasked = 'F5F29B0E-82D6-41A4-BF0E-1C86A0C7219B';
  static RightEarBoneConductionUnmasked = '4CCADE6E-A32F-4D50-B4DB-BF56F8BFAAD6';
  static LeftEarBoneConductionUnmasked = 'E30C242F-ABC3-48FE-97EB-61139E651BC0';
  static RightEarBoneConductionMasked = 'F18D8D3F-3CB1-43CB-B70D-70306D1135C4';
  static LeftEarBoneConductionMasked = '5EBE28EE-1AB3-42D0-B21D-457DA0E7CF61';
  static CenterBoneConductionUnmasked = '64474BC5-3ADC-484C-ACFE-7CF696FDE3B2';
  static SoundfieldUnaided = 'C9CB902D-0CC1-471A-ADA7-8D3B17B66E70';
  static SoundfieldAided = '918DF76C-69DD-4758-820C-764FEBA63950';
}

export class DhhAbr {
  static NormalSweep = '8a2f97a3-fbc3-4d29-9e55-2b9f1f2736c1';
  static DidNotTest = '5d8a75b1-8241-4e73-8c2e-1aebb9f2d2e3';
  static Other = '7e6d45c0-6af1-43a7-ab19-897e0d4f5e1d';
}

export class DhhTympanometryType {
  static Other = 'bf1f5b2e-5c91-42f9-8c4c-600fb3b8fe7e';
}

export class DhhOtoscopyId {
  static Other = 'd8600a61-88ab-4fe5-9994-21c8ca8144d0';
}

export class DhhSpeechAudiometrySatResult {
  static Other = 'd2e3f4a5-b6c7-8d9e-0f1a-2b3c4d5e6f7a';
}

export class DhhSpeechAudiometrySrtResult {
  static Other = '8d9e0f1a-2b3c-4d5e-6f7a-8b9c0d1e2f3a';
}

export class DhhSpeechAudiometryWordDiscriminationResult {
  static Other = 'f6a2b3d9-0e4f-7a5f-9b2e-8e1a0f7d6b3a';
}

export class DhhDpoaeResultId {
  static Other = 'd3788e00-03c3-4f95-9924-17a69be21924';
}

export class DhhSifSchoolInfoRefIds {
  static ISDElementary = 'ece96a45-9203-6996-e053-b4ad16acd7e4';
  static ISDMiddleSchool = 'ece96a45-9205-6996-e053-b4ad16acd7e4';
  static ISDHighSchool = 'ece96a45-9204-6996-e053-b4ad16acd7e4';
}

export class DhhAssessmentType {
  static aeps3 = 'f7487237-ad55-47a5-9568-74a843e4bbeb';
  static aslest = 'f43d1ab1-f40e-46be-9514-5d0b64845a93';
  static aslrst = 'fac3338d-5f51-4191-a21a-63599f1aab7b';
  static celf5 = '112fdeb4-caa5-45ba-b157-276a5b104e26';
  static comMatrix = '054f13bf-ccd7-4e70-b69b-88557130f2c8';
  static dayc2 = 'd11808ca-ab18-45e9-a691-ee4df02eb608';
  static kendallPlvl = 'b9f43c6c-1078-43b1-8570-6a082ae12249';
  static pls5 = '02429d7b-dce4-4529-acab-820a1232f5cd';
  static reel4 = '915862b7-97de-4648-9057-8bfb50abdb9b';
  static skihi = '5eaad299-8416-4775-a936-531d02670fe8';
  static vcsl = '8ff8ff59-0ff9-47d9-afa8-5f3d97e58e91';
}

export class DhhDocumentCategoryId {
  static Other = 'e6f3c9d3-9b4f-4e1a-a9d4-0c7a3b5d7e3a';
  static SystemGenerated = 'f7d4e8a2-8a5e-4b2c-9b1a-1a3c2d4e5f1b';
  static AeaLeaGenerated = 'a8b5d7e9-7d3a-4b2f-8c9a-2b5c3d4e6f2a';
}

export class DhhAppointmentTypeIds {
  static Screening = '61e44b89-cffe-407b-959d-f65545680f67';
  static Recheck = '8761f10b-c37a-4b4b-bc21-9aecc1e14d1d';
}

export class DhhNoteCategoryIds {
  static ServiceLog = '5ecd0cc2-3d49-4b76-b46d-8b90db5062d4';
}
