import { Component, ElementRef, EventEmitter, Input, NgZone, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ShareCaseComponent } from 'src/app/child-find/modals/share-case/share-case.component';
import { TransferCaseComponent } from 'src/app/child-find/modals/transfer-case/transfer-case.component';
import { AnnualReviewUpdate, IfspReviewType, PeriodicReviewUpdate } from 'src/app/ifsp/models/ifsp';
import { IfspService } from 'src/app/ifsp/services/ifsp.service';
import { AppPermissions } from 'src/app/permissions';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { Building } from 'src/app/shared/models/building';
import { CaseListItem, IEPStatus, IntakeType } from 'src/app/shared/models/case';
import { District } from 'src/app/shared/models/district';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { LearnerService } from 'src/app/shared/services/learner/learner.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { AuthService } from '../../auth/auth.service';
import { DhhLearnerReassignAudiologistComponent } from '../../dhh/components/shared/modals/dhh-learner-reassign-audiologist/dhh-learner-reassign-audiologist.component';
import { DhhCaseDto, DhhLearnerSearchResultDto, DhhType } from '../../dhh/models/DhhDtos';
import { DhhRoutingService } from '../../dhh/services/dhh-routing.service';
import { DhhService } from '../../dhh/services/dhh.service';
import { KeyValuePair } from '../../shared/models/key-value-pair';
import { BuildingService } from '../../shared/services/building/building.service';
import { DistrictService } from '../../shared/services/district/district.service';
import { ConsentFormType } from '../../shared/models/fiie-consent/consent-form';

@Component({
  selector: 'app-user-case-list',
  templateUrl: './user-case-list.component.html',
  styleUrls: ['./user-case-list.component.scss'],
})
export class UserCaseListComponent implements OnInit, OnDestroy {
  private subscriptions = new Subscription();

  @ViewChild('dhhU3Sort', { read: MatSort })
  dhhU3Sort: MatSort = new MatSort();

  @ViewChild('dhhU3Table')
  dhhU3Table: ElementRef;

  @ViewChild('dhhSort', { read: MatSort })
  dhhSort: MatSort = new MatSort();

  @ViewChild('dhhTable')
  dhhTable: ElementRef;

  @ViewChild('partCSort', { read: MatSort })
  partCSort: MatSort = new MatSort();

  @ViewChild('partCTable')
  partCTable: ElementRef;

  @ViewChild('partBSort', { read: MatSort })
  partBSort: MatSort = new MatSort();

  @ViewChild('partBTable')
  partBTable: ElementRef;

  @Input()
  cases: CaseListItem[];

  @Output()
  uniqueLearnerDistricts = new EventEmitter<KeyValuePair | any>();

  dhhU3DataSource = new MatTableDataSource<DhhCaseDto>();
  dhhU3PageSize = 20;
  dhhU3CurrentPage = 0;
  dhhU3TotalRows = 0;
  dhhU3OrderBy = 'FullName';
  dhhU3OrderByDescending = false;
  dhhU3SearchCriteria = '';
  dhhU3SearchDistrict = '';
  dhhU3DisplayedColumns = ['action', 'fullName', 'dateOfBirth', 'attendingDistrict', 'activeCases', 'hasAssistiveTech'];
  dhhU3FormGroup = this.fb.group({
    nameFilter: [null],
    districtFilter: [null],
  });
  dhhU3Districts: District[] = [];
  dhhU3DistrictOptions: KeyValuePair[];

  dhhDataSource = new MatTableDataSource<DhhCaseDto>();
  dhhPageSize = 20;
  dhhCurrentPage = 0;
  dhhTotalRows = 0;
  dhhOrderBy = 'FullName';
  dhhOrderByDescending = false;
  dhhSearchCriteria = '';
  dhhSearchDistrict = '';
  dhhSearchBuilding = '';
  dhhDisplayedColumns = ['action', 'fullName', 'dateOfBirth', 'attendingDistrict', 'building', 'activeCases', 'hasAssistiveTech'];
  dhhFormGroup = this.fb.group({
    nameFilter: [null],
    districtFilter: [null],
    buildingFilter: [null],
  });
  dhhDistricts: District[] = [];
  dhhDistrictOptions: KeyValuePair[];
  dhhBuildings: Building[] = [];
  dhhBuildingOptions: KeyValuePair[];

  dhhDataSourceNames = ['dhhU3DataSource', 'dhhDataSource'];

  partCDataSource = new MatTableDataSource<CaseListItem>();
  partCPageSize = 20;
  partCCurrentPage = 0;
  partCTotalRows = 0;
  partCOrderBy = 'LearnerName';
  partCOrderByDescending = false;
  partCSearchCriteria = '';
  partCSearchDistrict = '';
  displayedColumnsPartC = [
    'action',
    'agency',
    'learnerName',
    'referralDate',
    'initialDueDate',
    'periodicDueDate',
    'annualDueDate',
    'learnerThirdBirthday',
    'beginTransitionDate',
    'transitionMtgDate',
  ];
  partCFormGroup = this.fb.group({
    nameFilter: [null],
    districtFilter: [null],
    buildingFilter: [null],
  });
  partCDistricts: District[] = [];
  partCDistrictOptions: KeyValuePair[];
  showPartCCaseTable = true;

  partBDataSource = new MatTableDataSource<CaseListItem>();
  partBPageSize = 20;
  partBCurrentPage = 0;
  partBTotalRows = 0;
  partBOrderBy = 'LearnerName';
  partBOrderByDescending = false;
  partBSearchCriteria = '';
  partBSearchDistrict = '';
  partBSearchBuilding = '';
  displayedColumnsPartB = ['action', 'learnerName', 'learnerDateOfBirth', 'annualDueDate', 'reevaluationDueDate', 'iepType', 'iepStatus'];
  partBFormGroup = this.fb.group({
    nameFilter: [null],
    districtFilter: [null],
    buildingFilter: [null],
  });
  partBDistricts: District[] = [];
  partBDistrictOptions: KeyValuePair[];
  partBBuildings: Building[] = [];
  partBBuildingOptions: KeyValuePair[];
  showPartBCaseTable = true;

  dataSourceNames = ['partBDataSource', 'partCDataSource'];

  districtOptions: KeyValuePair[];
  buildingOptions: KeyValuePair[];

  permissions = AppPermissions;
  shortDateFormat = shortDateFormat;
  iepStatuses = IEPStatus;
  emailString: string;
  requestForFiieConsentAdditionalRequirements = new Map<string, string[]>([['TeamMember', ['AeaEdit']]]);

  get partBCurrentSort() {
    return {
      orderBy: this.partBOrderBy,
      orderByDescending: this.partBOrderByDescending,
    };
  }

  get partCCurrentSort() {
    return {
      orderBy: this.partCOrderBy,
      orderByDescending: this.partCOrderByDescending,
    };
  }

  get dhhU3CurrentPageInfo() {
    return {
      pageIndex: this.dhhU3CurrentPage,
      pageSize: this.dhhU3PageSize,
      length: this.dhhU3TotalRows,
    } as PageEvent;
  }

  get dhhCurrentPageInfo() {
    return {
      pageIndex: this.dhhCurrentPage,
      pageSize: this.dhhPageSize,
      length: this.dhhTotalRows,
    } as PageEvent;
  }

  get partBCurrentPageInfo() {
    return {
      pageIndex: this.partBCurrentPage,
      pageSize: this.partBPageSize,
      length: this.partBTotalRows,
    } as PageEvent;
  }

  get partCCurrentPageInfo() {
    return {
      pageIndex: this.partCCurrentPage,
      pageSize: this.partCPageSize,
      length: this.partCTotalRows,
    } as PageEvent;
  }

  get partBFormGroupHasValue() {
    return (
      !!this.partBFormGroup.get('nameFilter').value ||
      !!this.partBFormGroup.get('districtFilter').value ||
      !!this.partBFormGroup.get('buildingFilter').value
    );
  }

  get partCFormGroupHasValue() {
    return !!this.partCFormGroup.get('nameFilter').value || !!this.partCFormGroup.get('districtFilter').value;
  }

  get dhhU3FormGroupHasValue() {
    return !!this.dhhU3FormGroup.get('nameFilter').value || !!this.dhhU3FormGroup.get('districtFilter').value;
  }

  get dhhFormGroupHasValue() {
    return (
      !!this.dhhFormGroup.get('nameFilter').value ||
      !!this.dhhFormGroup.get('districtFilter').value ||
      !!this.dhhFormGroup.get('buildingFilter').value
    );
  }

  get userId() {
    return this.authService?.user?.id?.toLowerCase();
  }

  get isLeaUserManager() {
    return this.authService.isLeaUserManager;
  }

  get isGeneralEducation() {
    return this.authService.isGeneralEducation;
  }

  get isSectionVisible() {
    return (
      ((this.isLeaUserManager || this.isGeneralEducation) && (this.showPartCCaseTable || this.showPartBCaseTable)) ||
      (!this.isLeaUserManager && !this.isGeneralEducation) ||
      (this.authService.isDhhAudiologist && (this.partCTotalRows > 0 || this.partBTotalRows > 0))
    );
  }

  get canViewDhhCaseList() {
    return this.authService.isDhhAudiologist;
  }

  get canScheduleDhhAppointments() {
    return this.authService.isDhhAudiologist || this.authService.isDhhScheduler || this.authService.isSuperAdmin;
  }

  ConsentFormType = ConsentFormType;

  constructor(
    private caseService: CaseService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    public authService: AuthService,
    public learnerService: LearnerService,
    private ngZone: NgZone,
    private router: Router,
    public routingService: RoutingService,
    private ifspService: IfspService,
    private notificationService: NotificationService,
    private fb: FormBuilder,
    private districtService: DistrictService,
    private buildingService: BuildingService,
    private readonly dhhService: DhhService,
    private readonly dhhRoutingService: DhhRoutingService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.getCases().then(() => this.initializeSortChanges());
    this.setupSubscriptions();
    this.caseService.caseSummary = null;
  }

  private initializeSortChanges() {
    this.dhhU3Sort?.sortChange?.subscribe(async (sort: Sort) => {
      this.dhhU3OrderBy = sort.active;
      this.dhhU3OrderByDescending = sort.direction === 'desc' ? true : false;
      await this.getDhhU3Cases();
    });

    this.dhhSort?.sortChange?.subscribe(async (sort: Sort) => {
      this.dhhOrderBy = sort.active;
      this.dhhOrderByDescending = sort.direction === 'desc' ? true : false;
      await this.getDhhCases();
    });

    this.partBSort?.sortChange?.subscribe(async (sort: Sort) => {
      this.partBOrderBy = sort.active;
      this.partBOrderByDescending = sort.direction === 'desc' ? true : false;
      await this.getPartBCases();
    });

    this.partCSort?.sortChange?.subscribe(async (sort: Sort) => {
      this.partCOrderBy = sort.active;
      this.partCOrderByDescending = sort.direction === 'desc' ? true : false;
      await this.getPartCCases();
    });
  }

  populateFilters() {
    this.districtService.getDistricts().subscribe((districts) => {
      this.districtOptions = districts
        .filter((district) => this.authService.user?.aeas?.map((aea) => aea.id)?.includes(district.aeaId) || this.authService.isSuperAdmin)
        .map((x) => new KeyValuePair(x.id, x.name));

      this.buildingService.getAllBuildings().subscribe((buildings) => {
        this.buildingOptions = buildings
          .filter((building) => this.districtOptions.map((district) => district.key).includes(building.districtId))
          .map((x) => new KeyValuePair(x.id, x.name));
      });
    });
  }

  setupSubscriptions() {
    const formSubscriptions = [
      {
        formGroup: this.partCFormGroup,
        cb: this.getPartCCases.bind(this),
      },
      {
        formGroup: this.partBFormGroup,
        cb: this.getPartBCases.bind(this),
      },
      {
        formGroup: this.dhhFormGroup,
        cb: this.getDhhCases.bind(this),
      },
      {
        formGroup: this.dhhU3FormGroup,
        cb: this.getDhhU3Cases.bind(this),
      },
    ];
    formSubscriptions.forEach((config) => {
      this.subscriptions.add(
        config.formGroup.valueChanges.pipe(debounceTime(1000)).subscribe((value) => {
          if (
            (value.districtFilter && value.districtFilter.length !== 36) ||
            (value.buildingFilter && value.buildingFilter.length !== 36)
          ) {
            return;
          }
          config.cb(value, true);
        })
      );
    });
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async dhhU3PageChanged(event: PageEvent) {
    this.dhhU3PageSize = event.pageSize;
    this.dhhU3CurrentPage = event.pageIndex;
    this.scrollToView(this.dhhU3Table);
    await this.getDhhU3Cases();
  }

  async dhhPageChanged(event: PageEvent) {
    this.dhhPageSize = event.pageSize;
    this.dhhCurrentPage = event.pageIndex;
    this.scrollToView(this.dhhTable);
    await this.getDhhCases();
  }

  async partBPageChanged(event: PageEvent) {
    this.partBPageSize = event.pageSize;
    this.partBCurrentPage = event.pageIndex;
    this.scrollToView(this.partBTable);
    await this.getPartBCases();
  }

  async partCPageChanged(event: PageEvent) {
    this.partCPageSize = event.pageSize;
    this.partCCurrentPage = event.pageIndex;
    this.scrollToView(this.partCTable);
    await this.getPartCCases();
  }

  private scrollToView(element: ElementRef): void {
    element?.nativeElement.scrollIntoView({ behavior: 'smooth' });
  }

  private async getDhhU3Cases(searchCriteria?, saveCriteria?: boolean) {
    if (saveCriteria) {
      this.dhhU3SearchCriteria = searchCriteria.nameFilter;
      this.dhhU3SearchDistrict = searchCriteria.districtFilter;
    }

    const dhhU3List = await this.dhhService
      .searchDhhCase(
        DhhType.DHHU3,
        this.dhhU3CurrentPage + 1,
        this.dhhU3PageSize,
        this.dhhU3OrderByDescending,
        this.dhhU3OrderBy,
        this.dhhU3SearchCriteria,
        this.dhhU3SearchDistrict
      )
      .toPromise();

    this.dhhU3DataSource = new MatTableDataSource(dhhU3List.value.caseList.items);
    this.dhhU3TotalRows = dhhU3List.value.caseList.totalCount;
    this.dhhU3Districts = dhhU3List.value.filterOptions.districts;
    this.dhhU3DistrictOptions = this.dhhU3Districts.map((x) => new KeyValuePair(x.id, x.name));
  }

  private async getDhhCases(searchCriteria?, saveCriteria?: boolean) {
    if (saveCriteria) {
      this.dhhSearchCriteria = searchCriteria.nameFilter;
      this.dhhSearchDistrict = searchCriteria.districtFilter;
      this.dhhSearchBuilding = searchCriteria.buildingFilter;
    }

    const dhhList = await this.dhhService
      .searchDhhCase(
        DhhType.DHH,
        this.dhhCurrentPage + 1,
        this.dhhPageSize,
        this.dhhOrderByDescending,
        this.dhhOrderBy,
        this.dhhSearchCriteria,
        this.dhhSearchDistrict,
        this.dhhSearchBuilding
      )
      .toPromise();

    this.dhhDataSource = new MatTableDataSource(dhhList.value.caseList.items);
    this.dhhTotalRows = dhhList.value.caseList.totalCount;
    this.dhhDistricts = dhhList.value.filterOptions.districts;
    this.dhhBuildings = dhhList.value.filterOptions.buildings;
    this.dhhDistrictOptions = this.dhhDistricts.map((x) => new KeyValuePair(x.id, x.name));
    if (this.dhhFormGroup.controls.districtFilter.value) {
      this.dhhBuildingOptions = this.dhhBuildings
        .filter((x) => x.districtId === this.dhhFormGroup.controls.districtFilter.value)
        .map((x) => new KeyValuePair(x.id, x.name));
    } else {
      this.dhhBuildingOptions = this.dhhBuildings.map((x) => new KeyValuePair(x.id, x.name));
    }
  }

  private async getPartCCases(searchCriteria?, saveCriteria?: boolean) {
    if (saveCriteria) {
      this.partCSearchCriteria = searchCriteria.nameFilter;
      this.partCSearchDistrict = searchCriteria.districtFilter;
    }
    const partCList = await this.caseService
      .getPaginatedCaseList(
        IntakeType.PartC,
        this.partCCurrentPage + 1,
        this.partCPageSize,
        this.partCOrderByDescending,
        this.partCOrderBy,
        this.partCSearchCriteria,
        this.partCSearchDistrict
      )
      .toPromise();

    // this.partCDataSource.data = partCList.caseList.items;
    this.partCDataSource = new MatTableDataSource(partCList.caseList.items);
    this.partCTotalRows = partCList.caseList.totalCount;

    this.showPartCCaseTable = this.partCDataSource.data?.length > 0 || this.partCFormGroupHasValue;

    this.partCDistricts = partCList.filterOptions.districts;

    this.partCDistrictOptions = this.partCDistricts.map((x) => new KeyValuePair(x.id, x.name));
  }

  private async getPartBCases(searchCriteria?, saveCriteria?: boolean) {
    if (saveCriteria) {
      this.partBSearchCriteria = searchCriteria.nameFilter;
      this.partBSearchDistrict = searchCriteria.districtFilter;
      this.partBSearchBuilding = searchCriteria.buildingFilter;
    }
    const partBList = await this.caseService
      .getPaginatedCaseList(
        IntakeType.PartB,
        this.partBCurrentPage + 1,
        this.partBPageSize,
        this.partBOrderByDescending,
        this.partBOrderBy,
        this.partBSearchCriteria,
        this.partBSearchDistrict,
        this.partBSearchBuilding
      )
      .toPromise();

    //this.partBDataSource.data = partBList.caseList.items;
    this.partBDataSource = new MatTableDataSource(partBList.caseList.items);
    // this.partBDataSource.sort = this.partBSort;
    this.partBTotalRows = partBList.caseList.totalCount;

    this.showPartBCaseTable = this.partBDataSource.data?.length > 0 || this.partBFormGroupHasValue;

    this.partBDistricts = partBList.filterOptions.districts;
    this.partBBuildings = partBList.filterOptions.buildings;

    this.partBDistrictOptions = this.partBDistricts.map((x) => new KeyValuePair(x.id, x.name));
    if (this.partBSearchDistrict) {
      this.partBBuildingOptions = this.partBBuildings
        .filter((x) => x.districtId === this.partBSearchDistrict)
        .map((x) => new KeyValuePair(x.id, x.name));
    } else {
      this.partBBuildingOptions = this.partBBuildings.map((x) => new KeyValuePair(x.id, x.name));
    }
  }

  async getCases() {
    await this.getDhhCases();
    await this.getDhhU3Cases();
    await this.getPartCCases();
    await this.getPartBCases();
    this.getDistinctDistricts();
    this.setSortingAccessors();
  }

  private getDistinctDistricts() {
    const districts = [];
    districts.push(...this.partCDataSource?.data?.map((x) => new KeyValuePair(x.attendingDistrictId, x.attendingDistrict)));
    districts.push(...this.partBDataSource?.data?.map((x) => new KeyValuePair(x.attendingDistrictId, x.attendingDistrict)));
    const uniqueDistricts = [...new Map(districts.map((x) => [x.key, x])).values()];

    if (uniqueDistricts?.length) this.uniqueLearnerDistricts.emit([new KeyValuePair('', 'All'), ...uniqueDistricts]);
    else this.uniqueLearnerDistricts.emit([]);
  }

  canCreateEditPwn(caseListItem: CaseListItem) {
    return this.authService.isAllowedByCaseId(
      caseListItem.id,
      null,
      caseListItem.intakeType === IntakeType.PartB ? AppPermissions.AddPwnB : AppPermissions.AddPwnC
    );
  }

  canEditIep(caseListItem: CaseListItem) {
    return caseListItem.draftIEPId && this.authService.isAllowedByCaseId(caseListItem.id, null, AppPermissions.EditIEP);
  }

  canAmendIep(caseListItem: CaseListItem) {
    return caseListItem.activeIep && this.authService.isAllowedByCaseId(caseListItem.id, null, AppPermissions.CreateIEPAmendment);
  }

  canEditIfsp(caseListItem: CaseListItem) {
    return this.authService.isAllowedByCaseId(caseListItem.id, null, AppPermissions.EditIFSP);
  }

  canEditProgressMonitoring(caseListItem: CaseListItem) {
    return this.authService.isAllowedByCaseId(caseListItem.id, null, AppPermissions.ViewProgressMonitoring);
  }

  enterProgress(caseId) {
    const config: NewWindowConfig = {
      path: this.routingService.enterProgressPath(caseId).join('/'),
      popup: true,
      width: '1280px',
    };
    openNewWindow(config);
  }

  caseListNavLink(caseListItem) {
    return this.routingService.learnerDashboardPath(caseListItem.learnerId, caseListItem.id);
  }

  setSortingAccessors() {
    this.dataSourceNames.forEach((dataSourceType) => {
      this[dataSourceType].sortingDataAccessor = (item: CaseListItem, columnId) => {
        switch (columnId) {
          case 'learnerName':
            return `${item.learnerLastName}, ${item.learnerFirstName}`;
          case 'agency':
            return item.attendingAeaName;
          default:
            return item[columnId];
        }
      };
    });

    this.dhhDataSourceNames.forEach((dataSourceType) => {
      this[dataSourceType].sortingDataAccessor = (item: DhhLearnerSearchResultDto, columnId) => {
        return item[columnId];
      };
    });
  }

  onTransferCase(caseListItem: CaseListItem) {
    const dialogRef = this.dialog.open(TransferCaseComponent, {
      data: caseListItem.id,
    });
    dialogRef.afterClosed().subscribe((res: CaseListItem) => {
      if (res) {
        this.getCases();
        this.snackBar.open('Case Transferred!', '', { duration: 1000 });
      }
    });
  }

  onShareCase(caseListItem: CaseListItem) {
    const dialogRef = this.dialog.open(ShareCaseComponent, {
      data: caseListItem.id,
    });
    dialogRef.afterClosed().subscribe((res: CaseListItem) => {
      if (res) {
        this.getCases();
        this.snackBar.open('Case Shared!', '', { duration: 1000 });
      }
    });
  }

  isThreeOrLower(dob: Date) {
    return LearnerService.isUnderOrEqual(3, dob);
  }

  isThirteenOrAbove(dob: Date) {
    return !LearnerService.isUnder(13, dob);
  }

  onScheduleMeeting(caseListItem: CaseListItem) {
    this.routingService.openScheduleMeeting(caseListItem.learnerId);
  }

  openCreatePWN(caseId: string) {
    const config: NewWindowConfig = {
      path: `cases/${caseId}/pwns`,
      popup: true,
      width: '1450px',
    };
    const window = openNewWindow(config);
    window.addEventListener('beforeunload', (ev) => {
      this.ngZone.run(() => this.getCases());
    });
  }

  openEditPWN(caseListItem) {
    const config: NewWindowConfig = {
      path: `cases/${caseListItem.id}/pwns/${caseListItem.activePwn}`,
      popup: true,
      width: '1450px',
    };
    const window = openNewWindow(config);
    window.addEventListener('beforeunload', (ev) => {
      this.ngZone.run(() => this.getCases());
    });
  }

  beginAnnualReview(caseListItem: CaseListItem) {
    if (!caseListItem.currentIFSPId) {
      throw new Error('currentIFSPId should not be null');
    }
    const annualReviewUpdate: AnnualReviewUpdate = {
      ifspType: IfspReviewType.Annual,
      reviewActive: true,
      annualReviewDate: new Date(),
    };

    this.ifspService.updateReviewStatus(caseListItem.currentIFSPId, annualReviewUpdate).subscribe(() => {
      caseListItem.canBeginAnnualReview = false;
      this.routingService.ifspDetails(caseListItem.id, caseListItem.currentIFSPId);
    });
  }

  beginPeriodicReview(caseListItem: CaseListItem) {
    if (!caseListItem.currentIFSPId) {
      throw new Error('currentIFSPId should not be null');
    }
    const periodicReviewUpdate: PeriodicReviewUpdate = {
      periodicReviewActive: true,
      periodicReviewDate: new Date(),
    };

    this.ifspService.updatePeriodicReview(caseListItem.currentIFSPId, periodicReviewUpdate).subscribe(() => {
      caseListItem.canBeginPeriodicReview = false;
      this.router.navigate(this.routingService.ifspListPath(caseListItem.learnerId));
    });
  }

  sendPeriodicReviewPoll(caseListItem: CaseListItem) {
    if (caseListItem.currentIFSPId === undefined) {
      throw new Error('currentIFSPId should not be null');
    }
    this.ifspService.sendPeriodicReviewPoll(caseListItem.currentIFSPId).subscribe(() => {
      caseListItem.canSendPeriodicReviewPoll = false;
      this.notificationService.success('Questionnaire Sent!');
    });
  }

  sendAnnualReviewPoll(caseListItem: CaseListItem) {
    if (caseListItem.currentIFSPId === undefined) {
      throw new Error('currentIFSPId should not be null');
    }
    this.ifspService.sendAnnualReviewPoll(caseListItem.currentIFSPId).subscribe(() => {
      caseListItem.canSendAnnualReviewPoll = false;
      this.notificationService.success('Questionnaire Sent!');
    });
  }

  showBeginEvaluationPartB(caseListItem: CaseListItem) {
    const hasEditPermission = this.authService.isAllowedByCaseId(
      caseListItem.id,
      new Map<string, string[]>([['TeamMember', ['ServiceCoordinator']]]),
      AppPermissions.EditEvaluationPartB
    );

    return (
      hasEditPermission &&
      !caseListItem.activeEvaluationId &&
      !caseListItem.hasCompleteEvaluation &&
      (caseListItem.fiieConsentIsApproved || caseListItem.consentIsApproved)
    );
  }

  showEditEvaluationPartB(caseListItem: CaseListItem) {
    const hasEditPermission = this.authService.isAllowedByCaseId(
      caseListItem.id,
      new Map<string, string[]>([['TeamMember', ['ServiceCoordinator']]]),
      AppPermissions.EditEvaluationPartB
    );

    return hasEditPermission && caseListItem.activeEvaluationId && (caseListItem.fiieConsentIsApproved || caseListItem.consentIsApproved);
  }

  showEvaluationPartC(caseListItem: CaseListItem) {
    const hasEditPermission = this.authService.isAllowedByCaseId(caseListItem.id, undefined, AppPermissions.EditEvaluationPartC);
    return hasEditPermission && caseListItem.activeEvaluationId && (caseListItem.fiieConsentIsApproved || caseListItem.consentIsApproved);
  }

  showBeginReevaluation(caseListItem: CaseListItem) {
    const hasEditPermission = this.authService.isAllowedByCaseId(
      caseListItem.id,
      new Map<string, string[]>([['TeamMember', ['ServiceCoordinator']]]),
      AppPermissions.EditEvaluationPartB
    );

    return hasEditPermission && !caseListItem.activeReevaluationId && caseListItem.hasCompleteEvaluation;
  }

  showEditReevaluation(caseListItem: CaseListItem) {
    const hasEditPermission = this.authService.isAllowedByCaseId(
      caseListItem.id,
      new Map<string, string[]>([['TeamMember', ['ServiceCoordinator']]]),
      AppPermissions.EditEvaluationPartB
    );

    return hasEditPermission && caseListItem.activeReevaluationId;
  }

  public canShare(caseListItem: CaseListItem): boolean {
    return this.authService.canShareCase(caseListItem.id, caseListItem.intakeType);
  }

  public canTransfer(caseListItem: CaseListItem): boolean {
    return this.authService.canTransferCase(caseListItem.id, caseListItem.intakeType);
  }

  public canViewMenu(caseListItem: CaseListItem): boolean {
    return this.authService.isReadOnlyByCaseId(caseListItem.id, AppPermissions.ViewCaseListMenu);
  }

  canScheduleMeeting(caseListItem: CaseListItem) {
    return this.authService.isAllowedByCaseId(caseListItem.id, null, AppPermissions.ScheduleMeeting);
  }

  canEmailTheFamily(caseListItem: CaseListItem) {
    return this.authService.isSuperAdmin || this.authService.isCaseOwner(caseListItem.id);
  }

  scheduleDhhAppointment(learnerId: string) {
    this.dhhRoutingService.openLearnerAppointmentScheduler(learnerId);
  }

  learnerHearingDashboardNavLink(caseListItem) {
    return this.dhhRoutingService.learnerHearingDashboardPath(caseListItem.learnerId);
  }

  dhhReassignAudiologist(learnerId: string) {
    const dialogRef = this.dialog.open(DhhLearnerReassignAudiologistComponent, {
      width: '650px',
      data: {
        learnerId: learnerId,
      },
    });

    dialogRef.afterClosed().subscribe(async (dataUpdated: boolean) => {
      if (dataUpdated) {
        await this.getDhhCases();
        await this.getDhhU3Cases();
      }
    });
  }

  get canAddConsent() {
    return this.canScheduleDhhAppointments;
  }

  addConsent(learnerId: string, consentFormType: ConsentFormType) {
    const queryParams = { shouldOpenModal: true, consentFormType: consentFormType };
    this.dhhRoutingService.openLhdConsents(learnerId, queryParams);
  }
}
