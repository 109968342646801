<mat-card class="card--form mb-3">
  <div class="display-flex justify-content-between mb-2">
    <h3 class="text-black">Consideration of Unique Circumstances</h3>
    <button
      *ngIf="canReviewSpecialCircumstances && weightedMatrix?.weightedMatrixSpecialCircumstance?.isFinalized"
      mat-raised-button
      aria-label="Review Special Circumstances"
      color="primary"
      (click)="onReviewSpecialCircumstances()"
    >
      Review Special Circumstances
    </button>
  </div>
  <mat-card-content>
    <app-help-directional-text>
      <p>
        If there is an individually assigned nurse, an individually assigned sign language interpreter or an individually assigned special
        education teacher it may impact the recommended weighting. Provide an answer to the following question(s) to view the recommended
        weightings.
      </p>
    </app-help-directional-text>

    <app-help-directional-text *ngIf="weightedMatrix.iepHasNRO">
      <p>
        Because nursing services have been selected, consider whether or not the learner has an individually assigned nurse; this may impact
        the weighting decision.
      </p>
    </app-help-directional-text>

    <ng-container [formGroup]="formGroup" class="ml-4">
      <ng-container *ngIf="hasSpeciallyDesignedInstruction">
        <div class="row mb-3">
          <div class="col-lg-12">
            <fieldset>
              <app-radio-group
                label="{{
                  caseSummary?.learner?.isPK
                    ? 'Does this learner have an <strong>individually</strong> assigned paraprofessional for any part of the day?'
                    : 'Does this learner have an <strong>individually</strong> assigned special education teacher for any part of the day?'
                }}"
                formControlName="assignedTeacher"
                [options]="yesNoOptions"
                [useUndo]="!readOnly"
              ></app-radio-group>
            </fieldset>
          </div>
        </div>
        <div class="row mb-3" *ngIf="formGroup.controls.assignedTeacher.value">
          <div class="col-lg-12">
            <fieldset>
              <app-radio-group
                label="What percentage of the day?"
                formControlName="whatPercentage"
                [options]="percentageOptions"
                [useUndo]="!readOnly"
              ></app-radio-group>
            </fieldset>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-12">
            <app-help-directional-text>
              <p>
                Full-Time = 20 hours or more per week in special education and general education. Part-Time = Up to 20 hours per week in
                special education and general education.
              </p>
            </app-help-directional-text>
            <fieldset>
              <app-radio-group
                label="Does the learner receive full-time or part time instruction?"
                formControlName="instructionLength"
                [options]="instructionLengthOptions"
                [useUndo]="!readOnly"
              ></app-radio-group>
            </fieldset>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="hasAssistiveTechnology">
        <div class="row mb-3">
          <div class="col-lg-12">
            <fieldset>
              <app-radio-group
                label="Is this device new?"
                formControlName="isDeviceNew"
                [options]="yesNoOptions"
                [useUndo]="!readOnly"
              ></app-radio-group>
            </fieldset>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-12">
            <app-date-picker label="Date of Purchase" controlName="dateOfPurchase" [readOnly]="!readOnly"></app-date-picker>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-12">
            <fieldset>
              <app-radio-group
                label="Is this minor or significant equipment?"
                formControlName="deviceSignificance"
                [options]="deviceSignificanceOptions"
                [useUndo]="!readOnly"
              ></app-radio-group>
            </fieldset>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-lg-12">
            <app-textarea
              label="Comment"
              [hideAsterisk]="true"
              formControlName="deviceComment"
              [maxLength]="stringSizes.extraLarge"
              [rows]="4"
            ></app-textarea>
          </div>
        </div>
      </ng-container>

      <div class="action__row" *ngIf="!readOnly">
        <button
          mat-raised-button
          aria-label="Sumbit"
          color="primary"
          [disableAfterBusy]="formGroup.invalid"
          [isBusy]="isSaving"
          [disabled]="formGroup.invalid"
          (click)="onSubmit()"
          tabindex="0"
        >
          Submit
        </button>
      </div>
    </ng-container>
  </mat-card-content>
</mat-card>
