import { Component, Input } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { yesNoOptions } from 'src/app/shared/formHelpers';

@Component({
  selector: 'app-ds-form-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.scss'],
})
export class QuestionComponent {
  @Input() questionFormGroup: FormGroup;
  yesNoOptions = yesNoOptions;

  get answerFormControl(): FormControl {
    return this.questionFormGroup.get('answer') as FormControl;
  }

  get commentsFormControl(): FormControl {
    return this.questionFormGroup.get('comments') as FormControl;
  }

  onClearRadio() {
    this.answerFormControl.setValue(null);
    this.answerFormControl.markAsDirty();
  }
}
