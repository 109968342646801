import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { Aea } from 'src/app/shared/models/aea';
import { Building } from 'src/app/shared/models/building';
import { CaseUserRole } from 'src/app/shared/models/case';
import { District } from 'src/app/shared/models/district';
import { User } from 'src/app/shared/models/user';
import { ProfessionService } from 'src/app/shared/services/profession/profession.service';
import { KeyValuePair } from './../../../../shared/models/key-value-pair';

@Component({
  selector: 'app-aea-transfer',
  templateUrl: './aea-transfer.component.html',
  styleUrls: ['./aea-transfer.component.scss'],
})
export class AeaTransferComponent implements OnInit, OnChanges, OnDestroy {
  @Input() aeaUsers: Array<User>;
  @Input() aeas: Array<Aea>;
  @Input() districts: Array<District>;
  @Input() buildings: Array<Building>;
  filteredDistricts: Observable<Array<District>>;
  @Input() multiSelect: boolean;
  @Output() search = new EventEmitter<FormGroup>();
  @Output() selected = new EventEmitter<Array<User>>();
  filteredBuildings: Observable<Array<string>>;
  filteredAeas: Observable<Array<Aea>>;
  aeasOptions: KeyValuePair[] = [];
  districtsOptions: KeyValuePair[] = [];

  dataSource = new MatTableDataSource<User>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  selection: SelectionModel<User>;
  displayedColumns: string[] = ['select', 'firstName', 'lastName', 'profession'];

  formGroup = new FormGroup({
    aeaId: new FormControl(''),
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    schoolDistrictId: new FormControl(''),
    buildingId: new FormControl(''),
    professionId: new FormControl(''),
    filterBy: new FormControl(CaseUserRole.Aea),
    includeGeneralEducation: new FormControl(true),
  });

  get canSearch() {
    return (
      !!this.formGroup.get('aeaId').value ||
      !!this.formGroup.get('firstName').value ||
      !!this.formGroup.get('lastName').value ||
      !!this.formGroup.get('schoolDistrictId').value ||
      !!this.formGroup.get('buildingId').value ||
      !!this.formGroup.get('professionId').value
    );
  }

  buildingOptions: KeyValuePair[] = [];

  professionOptions: KeyValuePair[] = [];

  constructor(private readonly professionService: ProfessionService) {}

  async ngOnInit() {
    await this.buildOptions();
    this.initDataSource();
    this.selection = new SelectionModel(this.multiSelect, []);
    this.selection.changed.subscribe((res) => {
      this.selected.emit(res.source.selected);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.aeaUsers?.previousValue !== changes.aeaUsers?.currentValue) {
      this.initDataSource();
    }

    if (changes.districts?.previousValue !== changes.districts?.currentValue) {
      this.filteredDistricts = changes.districts.currentValue;
    }
  }

  ngOnDestroy() {
    this.selection.changed.unsubscribe();
  }

  initDataSource() {
    if (this.aeaUsers) {
      this.aeaUsers = this.aeaUsers.map((x) =>
        this.professionOptions.some((p) => p.key === x.professionId)
          ? { ...x, ...{ jobTitle: this.professionOptions.find((p) => p.key === x.professionId)?.value } }
          : x
      );
    }

    this.dataSource.data = this.aeaUsers ? this.aeaUsers : [];
    this.dataSource.sort = this.sort;
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  checkboxLabel(row): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  private async buildOptions() {
    if (this.aeas) {
      this.aeasOptions = this.aeas.map((o) => new KeyValuePair(o.id, o.name));
    }
    if (this.districts) {
      this.districtsOptions = this.districts.map((o) => new KeyValuePair(o.id, o.name));
    }
    if (this.buildings) {
      this.buildingOptions = this.buildings.map((o) => new KeyValuePair(o.id, o.name));
    }

    const professions = await this.professionService.getProfessions().toPromise();
    this.professionOptions = professions.map((x) => new KeyValuePair(x.id, x.label));
  }

  onSearch() {
    this.search.emit(this.formGroup);
  }
}
