import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LearnerSearchResult } from 'src/app/shared/models/learner';
import { SearchParams } from './search';
import { formatDate } from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class SearchService {
  private baseUrl = 'api/learners/';
  storedSearchCriteria: SearchParams;
  private storedLocationSearchCriteria_key = 'storedLocationSearchCriteria';
  get storedLocationSearchCriteria(): SearchParams {
    const strValue = localStorage.getItem(this.storedLocationSearchCriteria_key);
    if (strValue) {
      return JSON.parse(strValue) as SearchParams;
    }
    return null;
  }
  set storedLocationSearchCriteria(v: SearchParams) {
    if (!!v) {
      localStorage.setItem(this.storedLocationSearchCriteria_key, JSON.stringify(v));
    } else {
      localStorage.removeItem(this.storedLocationSearchCriteria_key);
    }
  }
  isReturningFromDetails = false;

  constructor(private http: HttpClient) {}

  search(searchParams: SearchParams) {
    if (searchParams.isLocationOnlySearch) {
      this.storedLocationSearchCriteria = searchParams;
    } else {
      this.storedSearchCriteria = searchParams;
    }
    let params = new HttpParams();

    Object.keys(searchParams).forEach((k) => {
      const val = searchParams[k];
      if (val instanceof Date) {
        params = params.set(k, formatDate(val, 'yyyy-MM-ddT00:00:00.000-0000', 'en-US'));
      } else {
        params = params.set(k, searchParams[k]);
      }
    });
    return this.http.get<LearnerSearchResult[]>(this.baseUrl, { params });
  }

  flipStoredCriteriaFlag() {
    this.isReturningFromDetails = !this.isReturningFromDetails;
  }

  clearStored(isLocationOnlySearch: boolean) {
    if (isLocationOnlySearch) {
      this.storedLocationSearchCriteria = null;
    } else {
      this.storedSearchCriteria = null;
    }
  }

  storeSearch(searchParams: SearchParams) {
    if (searchParams.isLocationOnlySearch) {
      this.storedLocationSearchCriteria = searchParams;
    } else {
      this.storedSearchCriteria = searchParams;
    }
  }
}
