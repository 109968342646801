import { Component, OnInit } from '@angular/core';
import { DatabaseLinksService } from '../../../../shared/services/database-links/database-links.service';

@Component({
  selector: 'app-dhh-output-statements',
  templateUrl: './dhh-output-statements.component.html',
  styleUrls: ['./dhh-output-statements.component.scss'],
})
export class DhhOutputStatementsComponent implements OnInit {
  constructor(private readonly databaseLinksService: DatabaseLinksService) {}

  ngOnInit(): void {}

  openOutputStatements() {
    window.open(`${this.databaseLinksService.getDatabaseLink('dhhOutputStatements')}`, '_blank');
  }
}
