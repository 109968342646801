<main class="dashboard">
  <app-page-header pageTitle="Validation Dashboard" [helpLinks]="false" [backgroundColor]="'blueDark'"></app-page-header>

  <div class="actions mb-2" [formGroup]="formGroup">
    <div class="row">
      <div class="col">
        <app-text-field class="mr-2" label="Name" formControlName="name"></app-text-field>
      </div>
      <div class="col">
        <app-text-field class="mr-2" label="Case Manager" formControlName="caseManager"></app-text-field>
      </div>
      <div class="col" *ngIf="buildingOptions">
        <app-autocomplete class="mr-2" label="Filter by Building" formControlName="building" [options]="buildingOptions"></app-autocomplete>
      </div>
      <div class="col">
        <button mat-raised-button color="accent" type="button" (click)="clearFilters()" [disabled]="formGroup.pristine" tabindex="0">
          Clear
        </button>
      </div>
    </div>
  </div>
  <section class="table-overflow app-table">
    <table mat-table class="w-100" [dataSource]="dataSource" matSort>
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Learner Name</th>
        <td mat-cell *matCellDef="let element">
          <a (click)="onNavigateToLearnerDashboard(element.id)">{{ element.name }}</a>
        </td>
      </ng-container>

      <ng-container matColumnDef="dateOfBirth">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Date of Birth</th>
        <td mat-cell *matCellDef="let element">
          {{ element.dateOfBirth | date : shortDateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="caseManager">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Case Manager</th>
        <td mat-cell *matCellDef="let element">
          {{ element.caseManager }}
        </td>
      </ng-container>

      <ng-container matColumnDef="grade">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Grade</th>
        <td mat-cell *matCellDef="let element">
          {{ element.grade }}
        </td>
      </ng-container>

      <ng-container matColumnDef="attendingBuilding">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Attending Building</th>
        <td mat-cell *matCellDef="let element">
          {{ element.attendingBuilding }}
        </td>
      </ng-container>

      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef colspan="5">
          <i>No results...</i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns" class="element-row"></tr>
      <tr
        [hidden]="dataSource?.data?.length > 0"
        mat-footer-row
        *matFooterRowDef="dataSource?.data?.length === 0 ? ['noResults'] : []"
      ></tr>
    </table>
    <mat-paginator [dataSource]="dataSource" [pageSize]="10"></mat-paginator>
  </section>
</main>
