<section class="card-spacing-top" *ngIf="ivrsCaseUsers.length > 0">
  <div class="display-flex align-items-center">
    <h3 class="mb-2 mt-0 mr-1">Shared Users - IVRS</h3>
  </div>
  <mat-card>
    <mat-card-content>
      <div>
        <ul class="list">
          <li class="list__item" *ngFor="let user of ivrsCaseUsers">
            <div class="display-flex align-items-center justify-content-between">
              <p class="my-0">{{ user.firstName }} {{ user.lastName }}</p>

              <button
                *ngIf="canRemoveSharing || user.userId === userId"
                aria-label="Delete Icon"
                mat-icon-button
                (click)="onUnshareCase(user)"
                color="warn"
                tabindex="0"
              >
                <mat-icon>close</mat-icon>
              </button>
            </div>
          </li>
        </ul>
      </div>
    </mat-card-content>
  </mat-card>
</section>
