import { Component, Input, OnInit } from '@angular/core';
import { IvrsCaseUserDto } from '../../../ivrs/models/ivrs-case';
import { IvrsCaseService } from '../../../ivrs/services/ivrs-case.service';

@Component({
  selector: 'app-shared-ivrs-users',
  templateUrl: './shared-ivrs-users.component.html',
  styleUrls: ['./shared-ivrs-users.component.scss'],
})
export class SharedIvrsUsersComponent implements OnInit {
  @Input() ivrsCaseUsers: IvrsCaseUserDto[] = [];
  @Input() ivrsCaseId: string;
  @Input() ownerId: string;
  @Input() canRemoveSharing: boolean;
  @Input() userId: string;
  constructor(private readonly ivrsCaseService: IvrsCaseService) {}

  ngOnInit(): void {}

  onUnshareCase(user) {
    this.ivrsCaseService.removeIvrsCaseUser(this.ivrsCaseId, user.userId).subscribe((res) => {
      const index = this.ivrsCaseUsers.indexOf(user);
      if (index > -1) {
        this.ivrsCaseUsers.splice(index, 1);
      }
    });
  }
}
