<form [formGroup]="formGroup">
  <mat-card>
    <mat-card-title>Child Information</mat-card-title>
    <div class="container-fluid container--not-centered container--form">
      <div class="row">
        <div class="col-lg-3">
          <app-text-field label="First Name" formControlName="firstName"></app-text-field>
        </div>
        <div class="col-lg-2">
          <app-text-field label="Middle Name" formControlName="middleName"></app-text-field>
        </div>
        <div class="col-lg-3">
          <app-text-field label="Last Name" formControlName="lastName"></app-text-field>
        </div>
        <div class="col-lg-4">
          <app-date-picker
            controlName="dateOfBirth"
            startView="multi-year"
            label="DOB (MM/DD/YYYY)"
            [max]="today"
            [min]="twentyFiveYearsAgo"
          ></app-date-picker>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-3 col-lg-4">
          <fieldset>
            <app-radio-group label="Gender" formControlName="genderId" [options]="genderOptions"></app-radio-group>
          </fieldset>
        </div>
        <div class="col-xl-4 col-lg-6 col-md-12">
          <app-autocomplete
            [options]="languageOptions"
            formControlName="languageSpokenInHomeId"
            label="Primary Language Spoken in Home"
            aria-label="Primary Language Spoken in Home"
          ></app-autocomplete>
        </div>
        <div class="col-xl-3 col-lg-4">
          <fieldset>
            <app-radio-group label="Interpreter Needed" formControlName="interpreterNeeded" [options]="yesNoOptions"></app-radio-group>
          </fieldset>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top">
    <mat-card-title>Residential Information</mat-card-title>
    <mat-card-subtitle *ngIf="formGroup.controls.zipCode.touched && formGroup.controls.zipCode.hasError('required')">
      <!-- <mat-error style="font-size: medium;">
        Please contact the Iowa Family Support Network email (<a
          href="mailto:iafamilysupportnetwork@everystep.org"
          >iafamilysupportnetwork@everystep.org</a
        >) or phone number (1-888-425-4371) for assistance
      </mat-error> -->
    </mat-card-subtitle>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg-4">
          <app-text-field label="Street Address" formControlName="streetAddress"></app-text-field>
        </div>
        <div class="col-lg-3">
          <app-text-field label="Zip Code" formControlName="zipCode" (blurMe)="populateLocationFromZipCode()"></app-text-field>
        </div>
        <div class="col-lg-3">
          <app-text-field label="City/Town" formControlName="city"></app-text-field>
        </div>
        <div class="col-lg-2">
          <app-autocomplete label="State" [options]="usStateOptions" formControlName="state"></app-autocomplete>
        </div>
      </div>
      <div class="row">
        <div class="col-lg">
          <app-text-field label="Child's County" formControlName="county"></app-text-field>
        </div>
        <div class="col-lg">
          <app-autocomplete
            label="Child's Resident School District"
            formControlName="childResidentDistrictId"
            [options]="districtOptions"
          ></app-autocomplete>
        </div>
        <div class="col-lg">
          <app-autocomplete
            label="Child's Attending School District"
            formControlName="childAttendingDistrictId"
            [options]="districtOptions"
          ></app-autocomplete>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top">
    <mat-card-title>Child Care</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row mt-3">
        <div class="col-lg-6">
          <fieldset>
            <app-radio-group
              label="Does child attend child care? If unknown, leave this section blank."
              formControlName="attendsChildCare"
              [options]="yesNoOptions"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>
      <div *ngIf="!!formGroup.get('attendsChildCare').value" class="row">
        <div class="col-lg-6">
          <app-text-field label="Name of Child Care Provider" formControlName="childCareProviderName"> </app-text-field>
        </div>
        <div class="col-lg-6">
          <app-text-field label="Street Address" formControlName="childCareProviderStreetAddress"> </app-text-field>
        </div>
      </div>
      <div *ngIf="!!formGroup.get('attendsChildCare').value" class="row">
        <div class="col-lg-2">
          <app-text-field label="Zip Code" formControlName="childCareProviderZipCode" (blurMe)="populateChildcareLocationFromZipCode()">
          </app-text-field>
        </div>
        <div class="col-lg-4">
          <app-text-field label="City" formControlName="childCareProviderCity"> </app-text-field>
        </div>
        <div class="col-lg-2">
          <app-autocomplete label="State" formControlName="childCareProviderState" [options]="usStateOptions"></app-autocomplete>
        </div>
      </div>
    </div>
  </mat-card>
</form>

<ng-template #tooOld>
  <h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Must Be Under Three Years of Age</h3>
  <mat-dialog-content class="py-3">
    <mat-card class="mb-0">
      <p class="my-0 line-height-16">
        Early ACCESS is a system of early intervention services for infants and toddlers (birth to age 3) with a developmental delay or
        disability, and their families. Based on the date of birth entered, this child is older than 3 and cannot be referred to Early
        ACCESS. Please call the
        <a
          (click)="openIowaFamilySupportNetworkUrl()"
          tabindex="0"
          role="link"
          aria-labelledby="Please call the Iowa Family Support Network"
        >
          Iowa Family Support Network
        </a>
        at 1-888-425-4371 and they will assist in connecting you to resources for children who are 3 years and older. Thank you!
      </p>
    </mat-card>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button color="primary" type="button" mat-raised-button aria-label="Ok Button" [mat-dialog-close]="true" tabindex="0">
      Ok
    </button></mat-dialog-actions
  >
</ng-template>
