import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { forkJoin } from 'rxjs';
import { KeyValuePair } from '../../../../../shared/models/key-value-pair';
import { SpinnerService } from '../../../../../shared/services/spinner/spinner.service';
import { DhhLookupsService } from '../../../../services/dhh-lookups.service';
import { DhhScreeningPassCriteria } from '../../../dhh-administration/shared/models/testing-equipment.models';

@Component({
  selector: 'app-dhh-screening-recheck-pass-criteria-form',
  templateUrl: './dhh-screening-recheck-pass-criteria-form.component.html',
  styleUrls: ['./dhh-screening-recheck-pass-criteria-form.component.scss'],
})
export class DhhScreeningRecheckPassCriteriaFormComponent implements OnInit {
  pageTitle = 'Add Pass Criteria';
  passCriteria: DhhScreeningPassCriteria;
  frequencyOptions: KeyValuePair[] = [];
  decibelOptions: KeyValuePair[] = [];
  isSaving = false;

  formGroup = this.fb.group({
    id: [],
    frequencyLabel: [null, [Validators.required]],
    decibelLabel: [null, [Validators.required]],
  });

  constructor(
    private readonly dhhLookupService: DhhLookupsService,
    private readonly spinnerService: SpinnerService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DhhScreeningRecheckPassCriteriaFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.passCriteria = data.passCriteria;
  }

  async ngOnInit(): Promise<void> {
    await this.loadLookups().then(() => {
      if (this.passCriteria) {
        this.pageTitle = 'Edit Pass Criteria';
        this.formGroup.controls.id.setValue(this.passCriteria.id);
        this.formGroup.controls.frequencyLabel.setValue(this.passCriteria.frequencyLabel);
        this.formGroup.controls.decibelLabel.setValue(this.passCriteria.decibelLabel);
      }
    });
  }

  loadLookups() {
    setTimeout(() => this.spinnerService.incrementLoading());
    return new Promise((resolve, reject) => {
      forkJoin([this.dhhLookupService.getFrequencyLevels(), this.dhhLookupService.getDecibelLevels()]).subscribe({
        next: ([frequencyLevelsResult, decibelLevelsResult]) => {
          if (frequencyLevelsResult.succeeded) {
            this.frequencyOptions = frequencyLevelsResult.value?.map((x) => ({ key: x.label, value: x.label } as KeyValuePair)) ?? [];
            if (this.data.currentPassCriterion)
              this.frequencyOptions = this.frequencyOptions.filter(
                (f) => !this.data.currentPassCriterion.some((c) => c.frequencyLabel === f.key)
              );
          }
          if (decibelLevelsResult.succeeded) {
            this.decibelOptions = decibelLevelsResult.value?.map((x) => ({ key: x.label, value: x.label } as KeyValuePair)) ?? [];
          }
          this.spinnerService.decrementLoading();
          resolve({ frequencyLevelsResult, decibelLevelsResult });
        },
        error: (error) => {
          this.dhhLookupService.handleError('Error', error);
          reject(error);
        },
      });
    });
  }

  onCancel() {
    this.closeModal(null);
  }

  onSubmit() {
    this.isSaving = true;

    if (this.formGroup.valid) {
      const updatedScreening = this.formGroup.value as DhhScreeningPassCriteria;
      this.closeModal(updatedScreening);
    } else {
      this.dhhLookupService.handleError('Please completed all required fields', {
        message: 'Please completed all required fields and try again',
      });
    }
  }

  private closeModal(passCriteria: any) {
    this.dialogRef.close(passCriteria);
  }
}
