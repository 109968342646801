<form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <h1 class="mat-display-1">Sign In</h1>
  <p class="mb-5 mt-0">
    An email has been sent to you with a verification code. This code will be active for 15 minutes. Please enter the verification code to
    continue.
  </p>
  <strong class="mb-3">{{ mfaToken?.email }}</strong>
  <mat-form-field class="login-field">
    <input type="text" placeholder="Verification Code" aria-labelledby="Verification Code" matInput formControlName="pin" />
  </mat-form-field>
  <mat-error *ngIf="pin.touched && pin.invalid">{{ getErrorMessage() }}</mat-error>
  <mat-error *ngIf="loginFailed"> Invalid pin... </mat-error>
  <button mat-flat-button aria-label="Sign in" #submitButton class="buttons" type="submit" color="primary" [isBusy]="isBusy" tabindex="0">
    Sign in
  </button>
</form>
