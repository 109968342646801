import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UnknownYesNo } from 'src/app/shared/models/yes-no';
import { ReferralService } from '../early-access-referral.service';
import { ReferralDocument } from '../referral';

@Component({
  selector: 'app-documentation-info-form',
  templateUrl: './documentation-info-form.component.html',
  styleUrls: ['../early-access-referral.component.scss', './documentation-info-form.component.scss'],
})
export class DocumentationInfoFormComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  @Input() formGroup: FormGroup;
  @Input() parentForm: FormGroup;
  @Input() isAuthenticated: boolean;
  @Input() documents: ReferralDocument[] = [];
  @Input() activeCall = false;
  @Input() learnersName: string;

  unknownYesNo = UnknownYesNo;

  constructor(private svc: ReferralService, private cd: ChangeDetectorRef) {}

  get referralReasonInfo() {
    return this.parentForm.get('referralReasonInfo') as FormGroup;
  }

  ngOnInit() {
    this.initializeControls();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onUpload(formData: FormData) {
    this.activeCall = true;
    const docIdsCtrl = this.formGroup.get('documentIds');
    this.svc.addDocuments(formData).subscribe(
      (docs) => {
        this.documents = [...this.documents, ...docs];
        docIdsCtrl.markAsDirty();
        docIdsCtrl.setValue([...docIdsCtrl.value, ...docs.map((d) => d.id)]);
        this.cd.detectChanges();
        this.activeCall = false;
      },
      (err) => {
        console.log(err);
      }
    );
  }

  onDeleteDocument(id: string) {
    const docIdsCtrl = this.formGroup.get('documentIds');
    let idx = docIdsCtrl.value.indexOf(id);
    if (idx > -1) {
      docIdsCtrl.markAsDirty();
      docIdsCtrl.setValue([...docIdsCtrl.value.slice(0, idx), ...docIdsCtrl.value.slice(idx + 1)]);
    }

    const doc = this.documents.find((d) => d.id === id);
    idx = this.documents.indexOf(doc);
    this.documents = [...this.documents.slice(0, idx), ...this.documents.slice(idx + 1)];
  }

  private initializeControls() {
    this.formGroup.addControl('documentIds', new FormControl([]));
  }
}
