export interface IncompleteDataReportItem {
  notice: string;
  action: string;
  section: string;
  id: string;
  element: IncompleteDataReportElement;
  sequence?: number;
  meetingDate?: Date;
}

export enum IncompleteDataReportElement {
  RECPNotAccurate = 'RECPNotAccurate',
}

export enum EvaluationIncompleteDataReportAction {
  StrengthsConcernsBackground = 'strengths-concerns-background',
  LearnerPerformance = 'learner-performance',
  LearnerProgress = 'learner-progress',
  EducationalNeeds = 'educational-needs',
  AdditionalFactors = 'additional-factors',
  EcoMatrix = 'eco-matrix-part-b',
  Meeting = 'meeting',
  RollCall = 'roll-call',
  InitialHomeVisitRollCall = 'initial-home-visit-roll-call',
  FutureRollCall = 'future-roll-call',
  Summary = 'summary-part-b',
  Overview = 'overview',
  EvaluationDetails = 'details-part-c',
  SummaryPartC = 'summary-part-c',
  ContinuedEligibility = 'continued-eligibility',
  Documentation = 'documentation',
}

export enum EvaluationIncompleteDataReportSection {
  Summary = 'summary',
  Incomplete = 'incomplete',
  DetermineEligibility = 'determine-eligibility',
  EcoDecisionDescription = 'eco-decision-description',
  ConceptsAndSkills = 'concept-and-skills',
  MethodAndDeliveryOfInstruction = 'method-and-delivery-of-instruction',
  AccommodationsAndLearningSupports = 'accommodations-and-learning-supports',
  Routines = 'routines',
}

export enum ReevaluationIncompleteDataReportAction {
  Consent = 'consent',
  Details = 'details',
  AdditionalFactors = 'additional-factors',
  Summary = 'summary',
  Documentation = 'documentation',
  Meeting = 'meeting',
  RollCall = 'rollcall',
  FutureRollCall = 'futurerollcall',
  Pwn = 'pwn',
}

export enum IepIncompleteDataReportAction {
  Services = 'services',
  Goals = 'goals',
  Plaafp = 'plaafp',
  Meeting = 'meeting',
  Pwn = 'pwn',
  RollCall = 'rollcall',
  RollCallAmendment = 'rollcall-amendment',
  FutureRollCall = 'futurerollcall',
  Bip = 'bip',
  FamilyContact = 'family-contact',
  Consent = 'consent',
  ProceduralSafeguards = 'procedural-safeguards',
  ServiceDetails = 'service-details',
  ActivityDetails = 'activity-details',
  AccommodationDetails = 'accommodation-details',
  Details = 'details',
  Clp = 'clp',
}

export enum BipIncompleteDataReportAction {
  BehaviorGoals = 'BehaviorGoals',
  Strategies = 'Strategies',
  Implementation = 'Implementation',
  SafetyPlan = 'SafetyPlan',
}

export enum IepESYIncompleteDataReportAction {
  Goals = 'goals',
  Overview = 'overview',
}

export enum FbaIncompleteDataReportAction {
  Assessment = 'Assessment',
  DataCollection = 'DataCollection',
  Summary = 'Summary',
}

export enum IfspIncompleteItemAction {
  Plod = 'plod',
  Eco = 'eco',
  Outcomes = 'child-family-outcomes',
  Services = 'services',
  Consent = 'consent',
  ProceduralSafeguards = 'procedural-safeguards',
  Pwn = 'pwn',
  Meeting = 'meeting',
  RollCall = 'rollcall',
  RollCallModification = 'rollcall-modification',
  FutureRollCall = 'futurerollcall',
  LateReason = 'late-reason',
  TransitionPlan = 'TransitionPlan',
  Evaluation = 'evaluation',
  EvaluationOverview = 'evaluation-overview',
  EvaluationFamilyInterview = 'evaluation-family-interview',
  EvaluationAreas = 'evaluation-areas',
  EvaluationToolUsed = 'evaluation-tool-used',
  EvaluationNotesIncomplete = 'evaluation-notes-incomplete',
  EvaluationNoteStrengthIncomplete = 'evaluation-notes-strength-incomplete',
  EvaluationNotesResourceIncomplete = 'evaluation-notes-resource-incomplete',
  EvaluationNotesPriorityIncomplete = 'evaluation-notes-priority-incomplete',
  EvaluationNotesRoutineIncomplete = 'evaluation-notes-routine-incomplete',
  EvaluationNotesAdditionalResultsIncomplete = 'evaluation-notes-additional-results-incomplete',
  EvaluationNotesConcernsIncomplete = 'evaluation-notes-concerns-incomplete',
  EvaluationNotesOtherIncomplete = 'evaluation-notes-other-incomplete',
  ExternalConsent = 'external-consent',
  FamilyContact = 'family-contact',
  AnnualReview = 'annual-review',
  Intake = 'intake',
}
