import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatAccordion } from '@angular/material/expansion';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import {
  EcoArea,
  Evaluation,
  EvaluationDetail,
  EvaluationDetailRoutine,
  EvaluationNote,
  InterviewTypes,
} from 'src/app/evaluation/models/evaluation';
import { EvaluationFileDocument } from 'src/app/evaluation/models/filedocument';
import { EvaluationDetailService } from 'src/app/evaluation/services/evaluation-detail.service';
import { EvaluationNoteService } from 'src/app/evaluation/services/evaluation-note.service';
import { EvaluationService } from 'src/app/evaluation/services/evaluation.service';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { LookupBase } from 'src/app/shared/models/lookup-base';
import { DocumentService } from 'src/app/shared/services/document/document.service';
import { EcoAreaService } from 'src/app/shared/services/eco-areas/eco-area.service';
import { FormService } from 'src/app/shared/services/form.service';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { EvalAssessmentDetailsHelp } from 'src/app/shared/services/help/models/evaluation.help';
import { MemoryStorageService } from 'src/app/shared/services/memory-storage/memory-storage.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { DateToUtcPipe } from '../../../../shared/pipes/date-transform.pipe';
import { ChangeTrackerService } from '../../../../shared/services/change-tracker.service';
import { CanComponentDeactivate, DeactivationStatus } from '../../../../shared/services/deactivation.service';
import { EvaluationDetailInterviewQuestion } from '../../../models/evaluation-detail-interview-question';
import { EvaluationInterviewComponent } from './evaluation-interview/evaluation-interview.component';
import { EvaluationNoteComponent } from './evaluation-note/evaluation-note.component';
import { EvaluationFormService } from './services/evaluation-form.service';

enum EvaluationMethod {
  Review,
  Interview,
  Observation,
  Test,
}

@Component({
  selector: 'app-evaluation-details',
  templateUrl: './evaluation-details.component.html',
  styleUrls: ['./evaluation-details.component.scss'],
  providers: [ChangeTrackerService],
})
export class EvaluationDetailsComponent implements OnInit, OnDestroy, AfterViewInit, CanComponentDeactivate {
  @ViewChild('editingTitle') editingTitle: ElementRef<any>;
  @ViewChild(EvaluationInterviewComponent) evaluationInterviewComponent;
  @ViewChild(EvaluationNoteComponent) evaluationNoteComponent;
  @ViewChildren(MatAccordion) detailsAccordions: QueryList<MatAccordion>;
  @ViewChild('incomplete') incomplete: ElementRef;
  private subscriptions = new Subscription();
  isEditing: boolean;
  userId: string;
  evaluationId: string;
  evaluationMethod = EvaluationMethod;
  currentEvaluation: Evaluation;
  evaluationDetails: EvaluationDetail[] = [];
  currentEvaluationDetail: EvaluationDetail = {};
  currentEvaluationNote: EvaluationNote;
  documents: EvaluationFileDocument[] = [];
  interviewTypes = InterviewTypes;
  today = new Date();
  twentyTwoYearsAgo = dayjs(this.today).subtract(22, 'year').toDate();
  formGroup: FormGroup = this.fb.group(
    {
      dateOfEvaluation: ['', Validators.required],
    },
    { updateOn: 'change' }
  );
  ecoAreas: EcoArea[] = [];
  evaluationDomains: LookupBase[] = [];
  selectedEcoAreas: string[] = [];
  selectedEvaluationDomains: string[] = [];
  submitted$ = new Subject();
  baseUrl: string;
  locationOptions: KeyValuePair[] = [
    new KeyValuePair('home', 'Home'),
    new KeyValuePair('providerAgency', 'Provider Agency'),
    new KeyValuePair('childcare', 'Child Care'),
    new KeyValuePair('other', 'Other'),
  ];
  notesBeingEdited: EvaluationNote[] = [];
  detailEditing = false;
  editingRoutineId: string;
  isAccordionsExpanded = false;
  whileToggleActive = false;
  activeCall = false;
  isSuperAdmin = false;

  //#region Method & Form Validity Properties & Checks
  documentationValid: boolean;
  interviewValid: boolean;
  observationValid: boolean;
  testValid: boolean;
  shortDateFormat = shortDateFormat;
  hasAllFamilyInterviewNotes = false;
  hasAllRoutineInterviewNotes = false;

  helpSection = HelpSection;
  evalAssessmentDetailsHelp = EvalAssessmentDetailsHelp;

  getLocationOption(value) {
    return this.locationOptions.find((x) => x.key === value)?.value;
  }

  getIsEditing(value) {
    return this.notesBeingEdited.findIndex((x) => x.id === value) !== -1;
  }

  async submitAllNotes() {
    if (this.currentEvaluationDetail.interview && this.currentEvaluationDetail.interviewType === 'Family') {
      const components: QueryList<EvaluationNoteComponent> = this.evaluationInterviewComponent.evaluationNoteComponents;
      components.forEach(async (component) => {
        const interviewNote = component.formGroup.get('note');
        if (interviewNote && interviewNote.value !== null && interviewNote.value !== '') {
          await component.onInterviewNoteSubmit();
        }
      });
    }

    const note = this.evaluationNoteComponent?.formGroup.get('note');
    if (
      (this.currentEvaluationDetail.observation || this.currentEvaluationDetail.test) &&
      this.evaluationNoteComponent?.currentEvaluationNote?.domainAreas?.length === 0
    ) {
      return;
    }
    if (note && note.value !== null && note.value !== '') {
      await this.evaluationNoteComponent.onSubmit();
    }
    return true;
  }

  get allSubmitted() {
    let foundNotSubmitted = false;
    if (this.currentEvaluationDetail.interview && this.currentEvaluationDetail.interviewType === 'Family') {
      const components: QueryList<EvaluationNoteComponent> = this.evaluationInterviewComponent.evaluationNoteComponents;
      components.forEach((component) => {
        const interviewNote = component.formGroup.get('note');
        if (interviewNote && interviewNote.value !== null && interviewNote.value !== '') {
          foundNotSubmitted = true;
        }
      });
    }

    const note = this.evaluationNoteComponent?.formGroup.get('note');
    if (note && note.value !== null && note.value !== '') {
      foundNotSubmitted = true;
    }

    if (foundNotSubmitted) {
      this.notificationService.error('You must submit all notes before attempting to complete this Evaluation Detail.');
      return false;
    }
    return true;
  }

  get isValid() {
    if (!this.formGroup.valid) {
      return false;
    }
    if (this.currentEvaluationDetail.review && !this.documentationValid) {
      return false;
    }
    if (this.currentEvaluationDetail.interview && !this.interviewValid) {
      return false;
    }
    if (this.currentEvaluationDetail.observation && !this.observationValid) {
      return false;
    }
    if (this.currentEvaluationDetail.test && !this.testValid) {
      return false;
    }
    if (!this.currentEvaluationDetail.interview && this.currentEvaluationDetail.notes.length === 0) {
      return false;
    }
    if (
      this.currentEvaluationDetail.interview &&
      this.currentEvaluationDetail.interviewType !== 'Family' &&
      this.currentEvaluationDetail.notes.length === 0
    ) {
      return false;
    }
    if (
      this.currentEvaluationDetail.interview &&
      (this.currentEvaluationDetail.review || this.currentEvaluationDetail.observation || this.currentEvaluationDetail.test) &&
      this.currentEvaluationDetail.notes.length === 0
    ) {
      return false;
    }
    return true;
  }

  //#endregion
  constructor(
    private route: ActivatedRoute,
    private evaluationDetailService: EvaluationDetailService,
    private evaluationNoteService: EvaluationNoteService,
    private fb: FormBuilder,
    private readonly notificationService: NotificationService,
    private readonly evaluationFormService: EvaluationFormService,
    private readonly storage: MemoryStorageService,
    private ecoAreaService: EcoAreaService,
    private evaluationService: EvaluationService,
    private authService: AuthService,
    private documentService: DocumentService,
    private dialog: MatDialog,
    private spinner: SpinnerService,
    private memoryStorage: MemoryStorageService,
    private formService: FormService,
    private readonly changeTracker: ChangeTrackerService,
    private helpService: HelpService
  ) {
    this.subscriptions.add(
      evaluationFormService.noteSubmitted$.subscribe((event) => {
        this.onNoteSubmit(event);
      })
    );
    this.subscriptions.add(
      evaluationFormService.noteEdit$.subscribe((note) => {
        const detailId = this.notesBeingEdited[0]?.evaluationDetailId;
        if (detailId && detailId !== note.evaluationDetailId) {
          this.notesBeingEdited = [];
        }
        if (detailId && detailId === note.evaluationDetailId && !note.evaluationDetailInterviewQuestionId) {
          this.notesBeingEdited = [];
        }
        this.notesBeingEdited.push(note);
      })
    );
  }

  ngOnInit(): void {
    this.userId = this.authService.user.id;
    this.isSuperAdmin = this.authService.isSuperAdmin;
    this.evaluationId = this.route.snapshot.parent.paramMap.get('evaluationId');
    this.currentEvaluation = this.storage.getKey('currentEvaluation', true);
    this.getIndicators();
    this.getEvaluationDetails();
    this.getDocuments();
    this.startAutoSaving();
    this.subscriptions.add(this.formService.showAllErrors$.subscribe(() => this.formGroup.markAllAsTouched()));
    this.formGroup.get('dateOfEvaluation').valueChanges.subscribe((x) => {
      this.currentEvaluationDetail.dateOfEvaluation = dayjs(x).toDate();
      if (!!this.evaluationNoteComponent) this.evaluationNoteComponent.refresh();
    });
  }

  ngAfterViewInit() {
    const section = this.route.snapshot.queryParamMap.get('section');
    setTimeout(
      () =>
        this[section]?.nativeElement?.scrollIntoView({
          behavior: 'smooth',
        }),
      500
    );
  }

  private startAutoSaving() {
    this.subscriptions.add(
      this.formGroup.valueChanges.pipe(debounceTime(3000), takeUntil(this.submitted$)).subscribe(() => {
        if (this.formGroup.dirty) {
          this.saveEvaluationDetail();
        }
      })
    );
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm): void {
    e.preventDefault();

    const dictionary = this.helpService.getEvalAssessmentDetailsDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }

  resetDeleteMethod() {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        subQuestion: 'Clicking Yes will remove all contents of the current evaluation detail, as well as its associated notes.',
      },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.evaluationDetailService.delete(this.evaluationId, this.currentEvaluationDetail.id).subscribe(
          (res) => {
            this.getDocuments();
            this.resetEvaluationDetail();
          },
          (error) => console.log(error)
        );
      }
    });
  }

  scrollToSelectMethod(element: ElementRef<any>) {
    element.nativeElement?.scrollIntoView({ behavior: 'smooth' });
  }

  showDocumentedDelay(details: EvaluationDetail[], domain: LookupBase): boolean {
    const filteredDetails = details
      .filter((x) => !!x.dateOfEvaluation && !!x.test && x.notes.some((y) => y.ageEquivalentLow))
      .map((detail) => {
        const detailWithFilteredNotes = {
          ...detail,
          notes: detail.notes.filter((note) => note.domainAreas.map((n) => n.id).includes(domain.id) && note.ageEquivalentLow),
        };
        return detailWithFilteredNotes;
      });

    if (filteredDetails.length > 0) {
      const mostRecentDetail = filteredDetails.reduce((a, b) => (dayjs(a.dateOfEvaluation) > dayjs(b.dateOfEvaluation) ? a : b));
      return mostRecentDetail.notes.some((note) => note.calculatedPercentDelayHigh >= 0.25 || note.calculatedPercentDelayLow >= 0.25);
    }
    return false;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  getEvaluationDetails(update?: boolean) {
    this.evaluationDetailService.get(this.evaluationId).subscribe((evaluationDetails) => {
      this.evaluationDetails = evaluationDetails;
      if (update) {
        this.currentEvaluationDetail = this.evaluationDetails.find((x) => x.id === this.currentEvaluationDetail.id);
      }
      this.updateIndicators();
    });
  }

  getCurrentEvaluationDetail() {
    this.evaluationDetailService.getDetail(this.evaluationId, this.currentEvaluationDetail.id).subscribe((res) => {
      this.currentEvaluationDetail = res;
    });
  }

  getDocuments() {
    this.documentService.getAll(this.currentEvaluation?.caseId).subscribe((res) => {
      this.documents = res;
    });
  }

  getIndicators() {
    forkJoin([this.ecoAreaService.get(), this.evaluationService.getDevelopmentalAreas(this.evaluationId)]).subscribe(
      ([ecoAreas, evaluationDomains]) => {
        this.ecoAreas = ecoAreas;
        this.evaluationDomains = evaluationDomains;
      }
    );
  }

  updateIndicators() {
    const notes = this.evaluationDetails?.flatMap((detail) => detail.notes);
    const routines = this.evaluationDetails?.flatMap((detail) => detail.routines);

    const noteEcoAreas = notes.flatMap((note) => note.ecoAreas).map((ecoArea) => ecoArea.id);
    const routineEcoAreas = routines.flatMap((routine) => routine.ecoAreas).map((ecoArea) => ecoArea.id);
    this.selectedEcoAreas = [].concat(noteEcoAreas, routineEcoAreas);

    const noteDomains = notes.flatMap((note) => note.domainAreas).map((evaluationDomain) => evaluationDomain.id);
    const routineDomains = routines.flatMap((routine) => routine.domainAreas).map((domain) => domain.id);
    this.selectedEvaluationDomains = [].concat(noteDomains, routineDomains);
    this.evaluationFormService.devAreasAnnounceUpdate();
  }

  isEvaluationDomainChecked(evaluationDomainId) {
    return this.selectedEvaluationDomains.includes(evaluationDomainId);
  }

  isEcoAreaChecked(ecoAreaId) {
    return this.selectedEcoAreas.includes(ecoAreaId);
  }

  updateEvaluationMethod(method: EvaluationMethod) {
    switch (method) {
      case this.evaluationMethod.Review:
        this.currentEvaluationDetail.review = !this.currentEvaluationDetail.review;
        break;
      case this.evaluationMethod.Interview:
        this.currentEvaluationDetail.interview = !this.currentEvaluationDetail.interview;
        break;
      case this.evaluationMethod.Observation:
        this.currentEvaluationDetail.observation = !this.currentEvaluationDetail.observation;
        break;
      case this.evaluationMethod.Test:
        this.currentEvaluationDetail.test = !this.currentEvaluationDetail.test;
        break;
      default:
        this.resetEvaluationDetail();
        break;
    }
    this.cleanEvaluationDetail();
    if (!this.currentEvaluationDetail.id) {
      this.formGroup.get('dateOfEvaluation').setValue(new Date());
      this.saveInitialEvaluationDetail();
    } else {
      this.saveEvaluationDetail();
    }
  }

  //#region Note Actions

  onNoteSubmit(event: EvaluationNote) {
    this.notesBeingEdited = this.notesBeingEdited.filter((x) => x.id !== event.id);
    if (event.evaluationDetailInterviewQuestionId) {
      const index = this.currentEvaluationDetail.notes.findIndex((x) => x.id === event.id);
      if (index !== -1) {
        this.currentEvaluationDetail.notes[index] = event;
      } else {
        this.currentEvaluationDetail.notes.push(event);
      }
    } else {
      this.currentEvaluationDetail.notes.push(event);
      let detail = this.evaluationDetails.find((x) => x.id === this.currentEvaluationDetail.id);
      if (!detail) {
        this.evaluationDetails.push(this.currentEvaluationDetail);
      } else {
        detail = this.currentEvaluationDetail;
      }
    }
  }

  onNoteEdit(note: EvaluationNote) {
    this.evaluationFormService.editNote(note);
    const detail = this.evaluationDetails.find((x) => x.id === note.evaluationDetailId);
    forkJoin([
      this.evaluationDetailService.getDetail(this.evaluationId, detail.id),
      this.evaluationNoteService.getNote(this.evaluationId, detail.id, note.id),
    ]).subscribe(
      ([evalDetail, evalNote]) => {
        this.currentEvaluationDetail = evalDetail;
        this.currentEvaluationNote = evalNote;
        this.formGroup.patchValue(this.currentEvaluationDetail);
        this.isEditing = true;
        this.updateBaseUrl();
        if (note.evaluationDetailInterviewQuestionId) {
          this.editInterviewQuestion(note);
        } else {
          this.scrollToSelectMethod(this.editingTitle);
        }
      },
      (err) => console.error(err)
    );
  }

  onNoteDelete(note: EvaluationNote) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: { subQuestion: 'Clicking Yes will delete this note.' },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.evaluationNoteService.delete(this.evaluationId, note.evaluationDetailId, note.id).subscribe(
          (res) => {
            this.evaluationFormService.deleteNote(note);
            if (this.currentEvaluationDetail.id) {
              this.currentEvaluationDetail.notes = this.currentEvaluationDetail.notes.filter((x) => x.id !== note.id);
            }
            this.getEvaluationDetails();
          },
          (error) => console.log(error)
        );
      }
    });
  }

  onCancelAndClose() {
    if (this.currentEvaluationDetail.notes.length === 0) {
      const dialogRef = this.dialog.open(AreYouSureComponent, {
        width: '450px',
        data: {
          showCancel: true,
          subQuestion:
            'No notes have been entered for this detail.  Would you like to retain the detail information so notes may be entered at a later time?',
        },
      });

      dialogRef.afterClosed().subscribe((confirm) => {
        if (confirm) {
          this.resetEvaluationDetail();
        } else if (confirm === false) {
          this.evaluationDetailService.delete(this.evaluationId, this.currentEvaluationDetail.id).subscribe(
            (res) => {
              this.getDocuments();
              this.resetEvaluationDetail();
            },
            (error) => console.log(error)
          );
        }
      });
    } else {
      if (this.isValid) {
        if (this.currentEvaluationDetail.interview && this.currentEvaluationDetail.interviewType === 'Routines') {
          if (this.hasAllRoutineInterviewNotes) {
            this.currentEvaluationDetail.isSubmitted = true;
            this.saveEvaluationDetail(true);
          } else {
            this.resetEvaluationDetail();
          }
        } else {
          this.currentEvaluationDetail.isSubmitted = true;
          this.saveEvaluationDetail(true);
        }
      } else {
        this.resetEvaluationDetail();
      }
    }
  }

  //#endregion

  //#region Detail Actions

  onDetailEdit(detail: EvaluationDetail, routine?: string) {
    this.evaluationDetailService.getDetail(this.evaluationId, detail.id).subscribe(
      (res) => {
        this.currentEvaluationDetail = res;
        this.currentEvaluationNote = undefined;
        this.formGroup.patchValue(this.currentEvaluationDetail);
        this.updateBaseUrl();
      },
      (err) => console.error(err),
      () => {
        if (routine) {
          this.editingRoutineId = routine;
        }
        this.scrollToSelectMethod(this.editingTitle);
        this.detailEditing = true;
        this.isEditing = true;
      }
    );
  }

  onDetailDelete(detail: EvaluationDetail) {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: { subQuestion: 'Clicking Yes will remove this detail.' },
    });

    dialogRef.afterClosed().subscribe((confirm) => {
      if (confirm) {
        this.evaluationDetailService.delete(this.evaluationId, detail.id).subscribe(
          (res) => {
            this.getDocuments();
            this.getEvaluationDetails();
          },
          (error) => console.log(error)
        );
      }
    });
  }

  //#endregion

  async onClose() {
    this.activeCall = true;
    const mainInterviewQuestions: EvaluationDetailInterviewQuestion[] = this.memoryStorage.getKey('mainInterviewQuestions', true);
    let totalNotesFound = 0;
    if (mainInterviewQuestions) {
      mainInterviewQuestions.forEach((question) => {
        const note = this.currentEvaluationDetail.notes.find((x) => x.interviewQuestion === question.label);
        if (note) {
          totalNotesFound += 1;
        }
      });
    }

    if (totalNotesFound === mainInterviewQuestions?.length) {
      this.hasAllFamilyInterviewNotes = true;
    }

    let totalRoutineNotesFound = 0;
    const routineInterviewQuestions: EvaluationDetailInterviewQuestion[] = this.memoryStorage.getKey('routineInterviewQuestions', true);
    if (routineInterviewQuestions) {
      routineInterviewQuestions.forEach((question) => {
        const note = this.currentEvaluationDetail.notes.find((x) => x.interviewQuestion === question.label);
        if (note) {
          totalRoutineNotesFound += 1;
        }
      });
    }

    if (totalRoutineNotesFound === routineInterviewQuestions?.length) {
      this.hasAllRoutineInterviewNotes = true;
    }

    if (
      this.currentEvaluationDetail.interview &&
      this.currentEvaluationDetail.interviewType === 'Family' &&
      !this.hasAllFamilyInterviewNotes
    ) {
      this.activeCall = false;
      this.notificationService.error('Please add a note for all interview questions.', 2000);
      return;
    }

    if (
      this.currentEvaluationDetail.interview &&
      this.currentEvaluationDetail.interviewType === 'Routines' &&
      !this.hasAllRoutineInterviewNotes
    ) {
      this.activeCall = false;
      this.notificationService.error('Please add a note for all routine interview questions.', 2000);
      return;
    }

    this.spinner.incrementLoading();
    await this.submitAllNotes();
    if (this.allSubmitted && this.isValid) {
      this.submitted$.next(undefined);
      this.notesBeingEdited = [];
      this.currentEvaluationDetail.isSubmitted = true;
      this.saveEvaluationDetail(true);
      this.detailEditing = false;
    } else {
      this.notificationService.error('Please complete all required fields.', 2000);
      this.getEvaluationDetails();
    }
    this.activeCall = false;
    this.evaluationFormService.submitForm();
    this.formGroup.markAllAsTouched();
    this.spinner.decrementLoading();
  }

  updateBaseUrl() {
    this.baseUrl = `api/evaluations/${this.currentEvaluation.id}/details/${this.currentEvaluationDetail.id}`;
  }

  saveInitialEvaluationDetail() {
    this.currentEvaluationDetail.dateOfEvaluation = new DateToUtcPipe().transform(new Date(this.formGroup.controls.dateOfEvaluation.value));
    this.evaluationDetailService.save(this.evaluationId, this.currentEvaluationDetail).subscribe((res) => {
      this.currentEvaluationDetail = res;
      this.formGroup.updateValueAndValidity();
      this.updateBaseUrl();
    });
  }

  saveEvaluationDetail(isSubmit?: boolean) {
    if (!this.isValid) {
      this.currentEvaluationDetail.isSubmitted = false;
    }
    if (this.currentEvaluationDetail.id) {
      this.currentEvaluationDetail.dateOfEvaluation = new DateToUtcPipe().transform(
        new Date(this.formGroup.controls.dateOfEvaluation.value)
      );
      this.evaluationDetailService.save(this.evaluationId, this.currentEvaluationDetail).subscribe((res) => {
        if (res && res.isSubmitted && isSubmit) {
          this.resetEvaluationDetail();
        }
      });
    }
  }

  private cleanEvaluationDetail(): void {
    if (!this.currentEvaluationDetail.review) {
      this.currentEvaluationDetail.documentationNotes = null;
      this.currentEvaluationDetail.reviewedDocuments = [];
    }
    if (!this.currentEvaluationDetail.interview) {
      this.currentEvaluationDetail.interviewType = null;
      this.currentEvaluationDetail.interviewee = null;
      this.currentEvaluationDetail.toolUsed = null;
      this.currentEvaluationDetail.toolOtherDescription = null;
      this.currentEvaluationDetail.childFamilyStrengths = null;
      this.currentEvaluationDetail.familyResources = null;
      this.currentEvaluationDetail.childFamilyConcerns = null;
      this.currentEvaluationDetail.familyPriorities = null;
      this.currentEvaluationDetail.otherInformation = null;
      this.currentEvaluationDetail.toolAdditionalResults = null;
      this.currentEvaluationDetail.typicalDay = null;
      this.currentEvaluationDetail.routines = [];
    }
    if (!this.currentEvaluationDetail.observation) {
      this.currentEvaluationDetail.location = null;
      this.currentEvaluationDetail.descriptionOfLocation = null;
    }
    if (!this.currentEvaluationDetail.test) {
      this.currentEvaluationDetail.testGiven = null;
    }
  }

  resetEvaluationDetail() {
    this.currentEvaluationDetail = {} as EvaluationDetail;
    this.currentEvaluationNote = undefined;
    this.notesBeingEdited = [];
    this.isEditing = false;
    this.formGroup.reset();
    this.getEvaluationDetails();
  }

  //#region Documentation Uploads
  onUpload(formData: FormData) {
    this.activeCall = true;
    this.evaluationDetailService.uploadDocumentation(formData, this.evaluationId, this.currentEvaluationDetail.id).subscribe(
      () => {
        this.getDocuments();
        this.getEvaluationDetails(true);
        this.notificationService.success('Upload complete');
        this.activeCall = false;
      },
      (err) => {
        this.activeCall = false;
        console.log(err);
        this.getCurrentEvaluationDetail();
      }
    );
  }

  onDeleteDocument(documentId: string) {
    this.evaluationDetailService.deleteDocument(this.evaluationId, this.currentEvaluationDetail.id, documentId).subscribe(
      (res) => {
        this.getDocuments();
        this.getEvaluationDetails(true);
      },
      (err) => {
        console.log(err);
        this.getCurrentEvaluationDetail();
      }
    );
  }

  //#endregion

  onChildChange(event) {
    const compare = JSON.stringify(this.currentEvaluationDetail) !== JSON.stringify({ ...this.currentEvaluationDetail, ...event });

    this.currentEvaluationDetail = {
      ...this.currentEvaluationDetail,
      ...event,
    };
    if (this.currentEvaluationDetail.id && compare) {
      this.saveEvaluationDetail();
    }
  }

  getAssociatedDetailNotes(notes: EvaluationNote[], id: string) {
    return notes.filter((x) => x.domainAreas.map((domain) => domain.id).includes(id));
  }

  getAssociatedDetails(details: EvaluationDetail[], id: string) {
    return details.filter(
      (x) =>
        x.notes
          .flatMap((note) => note.domainAreas)
          .map((n) => n.id)
          .includes(id) ||
        x.routines
          .flatMap((routine) => routine.domainAreas)
          .map((r) => r.id)
          .includes(id)
    );
  }

  notesNotTagged(notes: EvaluationNote[]) {
    return notes.some((x) => x.domainAreas.length === 0);
  }

  routinesNotTagged(routines: EvaluationDetailRoutine[]) {
    return routines.some((x) => x.domainAreas.length === 0 && !!x.satisfaction);
  }

  anyIncomplete() {
    return this.evaluationDetails.some((x) => !x.isSubmitted || (x.isSubmitted && x.notes.length === 0));
  }

  incompleteEvalDetails() {
    return this.evaluationDetails.filter((x) => !x.isSubmitted || (x.isSubmitted && x.notes.length === 0));
  }

  anyNotesNotTagged() {
    return this.evaluationDetails
      .filter((x) => x.isSubmitted)
      .flatMap((detail) => detail.notes)
      .some((note) => note.domainAreas.length === 0);
  }

  anyRoutinesNotTagged() {
    return this.evaluationDetails
      .filter((x) => x.isSubmitted)
      .flatMap((detail) => detail.routines)
      .some((routine) => routine.domainAreas.length === 0);
  }

  evaluationNoteSubmit(e: any) {
    this.detailEditing = false;
  }

  getNotes(notes: EvaluationNote[], id: string) {
    return notes.filter((x) => x.note && x?.domainAreas.map((domain) => domain.id).includes(id));
  }

  getRoutines(routines: EvaluationDetailRoutine[], id: string) {
    return routines.filter((x) => x?.domainAreas.map((domain) => domain.id).includes(id) && x?.satisfaction);
  }

  joinEcoAndDomain(ecoAreas: EcoArea[], domains: LookupBase[]): string {
    const ecoLabels = ecoAreas.map((ea) => ea.label);
    const domainLabels = domains.map((ea) => ea.label);

    return ecoLabels.length > 0 ? ecoLabels.join(', ') + ', ' + domainLabels.join(', ') : domainLabels.join(', ');
  }

  getSatisfactionText(satisfaction: number) {
    switch (satisfaction) {
      case 1:
        return 'Very worried / sad';
      case 2:
        return 'Thinking about often';
      case 3:
        return 'Satisfied';
      case 4:
        return 'Happy';
      case 5:
        return 'Very Happy';
      default:
        return 'N/A';
    }
  }

  toggleAccordion() {
    // Timeout used so they can't click it twice quickly and break the loop when expanding/collapsing
    setTimeout(() => {
      this.isAccordionsExpanded = !this.isAccordionsExpanded;
      this.detailsAccordions.forEach((a) => (this.isAccordionsExpanded ? a.openAll() : a.closeAll()));
      this.whileToggleActive = false;
    }, 200);
  }

  private async editInterviewQuestion(note) {
    const timer = (ms) => new Promise((res) => setTimeout(res, ms));

    while (!this.evaluationInterviewComponent) {
      await timer(500);
    }

    while (this.evaluationInterviewComponent?.interviewQuestionDivs?.length === 0) {
      await timer(500);
    }

    const div = this.evaluationInterviewComponent.interviewQuestionDivs.find(
      (question) => question.nativeElement.id === note.evaluationDetailInterviewQuestionId
    ) as ElementRef;

    if (div) {
      await timer(500);
      this.scrollToSelectMethod(div);
    }
  }

  showAllErrors() {
    this.formService.showAllErrors();
  }

  canDeactivate(): Observable<DeactivationStatus> | Promise<DeactivationStatus> | DeactivationStatus {
    return this.changeTracker.hasChanged.pipe(map((x) => (x ? DeactivationStatus.NeedsConfirmation : DeactivationStatus.Accepted)));
  }
}
