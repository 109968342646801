import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../shared/services/notification.service';
import { ForgotPasswordViewModel } from '../../auth-models';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-forgot-password-form',
  templateUrl: './forgot-password-form.component.html',
  styleUrls: ['./forgot-password-form.component.scss'],
})
export class ForgotPasswordFormComponent implements OnInit {
  isBusy = false;
  requestCompleted = false;

  @Input() loginUrl = '/auth/login';

  formGroup = new FormGroup({
    email: new FormControl('', { validators: [Validators.required, Validators.email] }),
  });

  get email() {
    return this.formGroup.get('email');
  }

  constructor(private notificationService: NotificationService, private authService: AuthService) {}

  ngOnInit(): void {}

  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' : this.email.hasError('email') ? 'Not a valid email' : '';
  }

  onSubmit() {
    if (this.formGroup.valid) {
      const model = this.formGroup.value as ForgotPasswordViewModel;

      this.authService.forgotPassword(model).subscribe(
        (response) => {
          this.requestCompleted = true;
        },
        (error) => {
          this.notificationService.error(error?.error);
        }
      );
    }
  }
}
