import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ServiceCoordinator } from 'src/app/shared/models/service-coordinator';
import { UserService } from 'src/app/shared/services/user/user.service';

@Component({
  selector: 'app-service-coordinators',
  templateUrl: './service-coordinators.component.html',
  styleUrls: ['./service-coordinators.component.scss'],
})
export class ServiceCoordinatorsComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @Input() serviceCoordinators: Array<ServiceCoordinator>;
  @Input() districtFilter = '';
  @Output() selectedServiceCoordinator: EventEmitter<ServiceCoordinator> = new EventEmitter<ServiceCoordinator>();

  private subscriptions = new Subscription();

  searchCriteria = '';
  orderBy = 'fullname';
  orderByDescending = false;
  currentPage = 0;
  pageSize = 20;
  totalRows = 0;

  searchFormGroup = this.fb.group({
    searchCriteria: '',
  });

  get currentPageInfo(): PageEvent {
    return {
      pageIndex: this.currentPage,
      pageSize: this.pageSize,
      length: this.totalRows,
    } as PageEvent;
  }

  // Mat-Table Service Coordinators
  displayedColumns: string[] = ['select', 'fullname', 'numberOfCases'];
  dataSource = new MatTableDataSource<ServiceCoordinator>();
  selection = new SelectionModel<ServiceCoordinator>(false, []);
  //#endregion

  constructor(private userService: UserService, private fb: FormBuilder) {}

  ngOnInit() {
    this.loadData();
    this.subscriptions.add(
      this.selection.changed.subscribe((res) => {
        this.selectedServiceCoordinator.emit(res.source.selected[0]);
      })
    );

    this.subscriptions.add(
      this.sort?.sortChange.subscribe((sort: Sort) => {
        this.orderBy = !!sort.direction ? sort.active : 'fullname';
        this.orderByDescending = sort.direction === 'desc' ? true : false;
        this.loadData();
      })
    );

    this.subscriptions.add(
      this.searchFormGroup
        .get('searchCriteria')
        .valueChanges.pipe(debounceTime(250))
        .subscribe((value) => this.loadData(value, true))
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    // // On init, no changes have actually happened
    if (!changes.districtFilter?.firstChange) {
      this.loadData();
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async loadData(searchCriteria?: string, savedCriteria?: boolean) {
    if (savedCriteria) {
      this.searchCriteria = searchCriteria;
    }

    const res = await this.userService
      .getServiceCoordinators(
        this.currentPage + 1,
        this.pageSize,
        this.orderBy,
        this.orderByDescending,
        undefined,
        undefined,
        this.searchCriteria,
        this.districtFilter
      )
      .toPromise();

    this.serviceCoordinators = res.items;
    this.totalRows = res.totalCount;
    this.dataSource.data = this.serviceCoordinators;
    this.selection.clear();
  }

  async pageChanged(event: PageEvent): Promise<void> {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    await this.loadData();
  }
}
