import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyValuePair } from '../../../../../shared/models/key-value-pair';
import { SpinnerService } from '../../../../../shared/services/spinner/spinner.service';
import { DhhLearnerDto } from '../../../../models/DhhDtos';
import { DhhLookupsService } from '../../../../services/dhh-lookups.service';
import { DhhService } from '../../../../services/dhh.service';

@Component({
  selector: 'app-dhh-learner-reassign-audiologist',
  templateUrl: './dhh-learner-reassign-audiologist.component.html',
  styleUrls: ['./dhh-learner-reassign-audiologist.component.scss'],
})
export class DhhLearnerReassignAudiologistComponent implements OnInit {
  learnerId: string;
  audiologistOptions: KeyValuePair[] = [];
  learner: DhhLearnerDto;
  isSaving = false;
  formGroup = this.fb.group({
    audiologistUserId: [null, [Validators.required]],
  });

  constructor(
    private readonly dhhLookupService: DhhLookupsService,
    private readonly dhhService: DhhService,
    private readonly spinnerService: SpinnerService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DhhLearnerReassignAudiologistComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit(): Promise<void> {
    this.learnerId = this.data.learnerId;

    this.dhhService.getLearner(this.learnerId).subscribe(
      async (result) => {
        if (result.succeeded) {
          this.learner = result.value;
          await this.getLookups();
        } else {
          this.dhhService.handleError('Failed to retrieving learner data.', result);
        }
        setTimeout(() => this.spinnerService.decrementLoading(), 400);
      },
      (error) => {
        setTimeout(() => this.spinnerService.decrementLoading(), 400);
        this.dhhService.handleError('There was an error while retrieving learner data.', error);
      }
    );
  }

  async getLookups() {
    const audiologists = await this.dhhLookupService.getAudiologists(this.learner?.attendingDistrictId).toPromise();
    this.audiologistOptions = audiologists.value.map((r) => new KeyValuePair(r.id, r.label));
  }

  onCancel() {
    this.closeModal(false);
  }

  onSubmit() {
    this.isSaving = true;
    this.dhhService.reassignAudiologist(this.learnerId, this.formGroup.controls.audiologistUserId.value).subscribe(
      (result) => {
        if (result.succeeded) {
          this.closeModal(true);
        } else {
          this.dhhService.handleError('Failed to reassign audiologist.', result);
        }
      },
      (error) => {
        this.dhhService.handleError('There was an error while reassigning audiologist.', error);
      },
      () => {
        this.isSaving = false;
      }
    );
  }

  private closeModal(updated: boolean) {
    this.dialogRef.close(updated);
  }
}
