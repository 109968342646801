import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { ConfirmationDialogComponent } from 'src/app/shared/services/notification.service';
import { openViewMore } from 'src/app/shared/tableHelpers';
import { DeactivationService } from '../../../shared/services/deactivation.service';
import { CommunityContactStatus, PostSecondaryCommunityContact, PostSecondarySummary } from '../post-secondary-summary';
import { PostSecondarySummaryService } from '../post-secondary-summary.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-community-contacts',
  templateUrl: './community-contacts.component.html',
  styleUrls: ['./community-contacts.component.scss'],
})
export class CommunityContactsComponent extends BaseComponent implements OnInit, OnDestroy, OnChanges {
  @Input() communityContacts: PostSecondaryCommunityContact[];
  @Input() resourceOptions: KeyValuePair[];
  @Input() postSecondarySummary: PostSecondarySummary;
  @Input() isReadonly = false;

  isEditing: boolean;
  datePipe: DatePipe;
  saveSubscription: Observable<any>;
  activeCall = false;
  save = new Observable<boolean>((observer) => {
    this.cd.detectChanges();

    const done = () => {
      this.isSaving = false;
      observer.next(true);
    };

    if (!this.formGroup.dirty || this.isSaving) {
      done();
      return;
    }

    this.isSaving = true;

    const communityContact = this.formGroup.value as PostSecondaryCommunityContact;
    communityContact.postSecondarySummaryId = this.postSecondarySummary?.id;

    if (communityContact.id) {
      this.postSecondarySummaryService
        .updatePostCommunityContact(this.postSecondarySummary.caseId, communityContact)
        .subscribe((updatedContacts) => {
          this.communityContacts = updatedContacts.value;
          this.dataSource = new MatTableDataSource(this.communityContacts);
          done();
        });
    } else {
      this.postSecondarySummaryService
        .addPostSecondaryCommunityContact(this.postSecondarySummary.caseId, communityContact)
        .subscribe((updatedContacts) => {
          this.formGroup.patchValue(
            updatedContacts.value.find((x) => !this.communityContacts.some((y) => x.id === y.id)),
            { emitEvent: false }
          );
          this.communityContacts = updatedContacts.value;
          this.dataSource = new MatTableDataSource(this.communityContacts);
          done();
        });
    }
  });

  formGroup = new FormGroup({
    id: new FormControl(null),
    resourceOption: new FormControl(null, Validators.required),
    resourceOther: new FormControl({ value: null, disabled: true }, Validators.required),
    agencyResource: new FormControl(null, Validators.required),
    status: new FormControl(null),
    namePosition: new FormControl(null),
    phone: new FormControl(null),
    dateConnected: new FormControl(null),
    website: new FormControl(null),
    focusServiceNeeds: new FormControl(null),
    identifiedNextSteps: new FormControl(null),
  });

  communityContactStatus = CommunityContactStatus;
  statusOptions = Object.entries(CommunityContactStatus).map(
    // eslint-disable-next-line id-blacklist
    ([number, word]) => new KeyValuePair(number, word)
  );
  highSchoolContactsOptions: KeyValuePair[] = [];
  contributingToSummaryOptions: KeyValuePair[] = [];
  resourceOtherKey: string;

  displayedColumns = [
    'actions',
    'resource',
    'organizationInformation',
    'dateConnected',
    'focusOfService',
    'contactPerson',
    'status',
    'identifiedSteps',
  ];
  dataSource = new MatTableDataSource<PostSecondaryCommunityContact>();

  constructor(
    private readonly cd: ChangeDetectorRef,
    private readonly postSecondarySummaryService: PostSecondarySummaryService,
    private dialog: MatDialog,
    deactivationService: DeactivationService
  ) {
    super(null);
  }

  ngOnInit(): void {
    this.resourceOtherKey = this.resourceOptions.find((x) => x.value === 'Other')?.key;

    this.saveSubscription = this.formGroup.valueChanges;
    this.startAutosaving();

    this.dataSource = new MatTableDataSource(this.communityContacts);

    this.formGroup.controls.resourceOption.valueChanges.subscribe((change: string) => {
      if (change === this.resourceOtherKey) {
        this.formGroup.controls.resourceOther.enable();
      } else {
        this.formGroup.controls.resourceOther.disable();
      }
    });

    if (this.isReadonly) {
      this.displayedColumns = this.displayedColumns.splice(1);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.communityContacts?.currentValue !== changes.communityContacts?.previousValue) {
      this.communityContacts = changes.communityContacts?.currentValue;
      this.dataSource = new MatTableDataSource(this.communityContacts);
    }
  }

  submit() {
    this.activeCall = true;
    const communityContacts = this.formGroup.value as PostSecondaryCommunityContact;
    communityContacts.postSecondarySummaryId = this.postSecondarySummary?.id;
    if (communityContacts.id) {
      this.postSecondarySummaryService
        .updatePostCommunityContact(this.postSecondarySummary.caseId, communityContacts)
        .subscribe((updatedContacts) => {
          this.communityContacts = updatedContacts.value;
          this.dataSource = new MatTableDataSource(this.communityContacts);
          this.formGroup.reset({ emitEvent: false });
          this.formGroup.markAsUntouched();
          this.formGroup.markAsPristine();
          this.activeCall = false;
        });
    } else {
      this.postSecondarySummaryService
        .addPostSecondaryCommunityContact(this.postSecondarySummary.caseId, communityContacts)
        .subscribe((updatedContacts) => {
          this.formGroup.patchValue(
            updatedContacts.value.filter((x) => !this.communityContacts.some((y) => x.id === y.id)),
            { emitEvent: false }
          );
          this.communityContacts = updatedContacts.value;
          this.dataSource = new MatTableDataSource(this.communityContacts);
          this.formGroup.reset({ emitEvent: false });
          this.formGroup.markAsUntouched();
          this.formGroup.markAsPristine();
          this.activeCall = false;
        });
    }

    this.isEditing = this.isEditing ? !this.isEditing : this.isEditing;
  }

  onEdit(contact: PostSecondaryCommunityContact) {
    this.isEditing = true;
    this.formGroup.patchValue(contact, { emitEvent: false });
  }

  onCancelEdit() {
    this.isEditing = false;
    this.formGroup.reset();
  }

  onDelete(contact: PostSecondaryCommunityContact) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '728px',
      data: {
        title: 'Remove',
        message: 'Are you sure you want to remove this adult / community contact?',
      },
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.postSecondarySummaryService.deletePostCommunityContact(this.postSecondarySummary.caseId, contact).subscribe(() => {
          this.communityContacts = this.communityContacts.filter((x) => x.id !== contact.id);
          this.dataSource.data = this.communityContacts;
        });
      }
    });
  }

  onViewMore(contact: PostSecondaryCommunityContact) {
    const modalData: ViewMoreModalData[] = [
      {
        name: 'Resource',
        value: this.getResourceLabel(contact),
      },
      {
        name: 'Organization / Information',
        value: contact.agencyResource,
      },
      {
        name: 'Date Connected',
        value: null, // this.datePipe.transform(contact.dateConnected, shortDateFormat),
      },
      {
        name: 'Focus of Service Needs',
        value: contact.focusServiceNeeds,
      },
      {
        name: 'Contact Person',
        value: contact.namePosition,
      },
      {
        name: 'Status',
        value: contact.status,
      },
      {
        name: 'Identified Next Steps',
        value: contact.identifiedNextSteps,
      },
    ];

    openViewMore(this.dialog, modalData);
  }

  getResourceLabel(contact: PostSecondaryCommunityContact) {
    if (contact.resourceOption === this.resourceOtherKey) {
      return contact.resourceOther;
    }
    return this.resourceOptions.find((x) => x.key === contact.resourceOption)?.value;
  }

  isComplete(contact: PostSecondaryCommunityContact) {
    return contact.resourceOption && contact.agencyResource;
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
