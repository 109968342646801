import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ShareIvrsCaseComponent } from '../../../child-find/modals/share-ivrs-case/share-ivrs-case.component';
import { TransferIvrsCaseComponent } from '../../../child-find/modals/transfer-ivrs-case/transfer-ivrs-case.component';
import { IvrsCase } from '../../../ivrs/models/ivrs-case';

@Component({
  selector: 'app-vr-case-owner-header',
  templateUrl: './vr-case-owner-header.component.html',
  styleUrls: ['./vr-case-owner-header.component.scss'],
})
export class VrCaseOwnerComponent implements OnInit {
  @Input() ivrsCase: IvrsCase;
  @Input() learnerHasWorkableCase = true;
  @Input() isOwner = false;
  @Input() canShareTransfer = false;
  @Output() public caseShared = new EventEmitter();
  @Output() public caseTransferred = new EventEmitter();

  get transferredUser() {
    return this.ivrsCase.transferredUserInfo.transferredUser.fullName;
  }

  constructor(private dialog: MatDialog) {}

  ngOnInit(): void {}

  async onShareCase(ivrsCase: IvrsCase) {
    const dialogRef = this.dialog.open(ShareIvrsCaseComponent, {
      data: ivrsCase.id,
    });
    await dialogRef.afterClosed().toPromise();
    this.caseShared.emit();
  }

  async onTransferCase(ivrsCase: IvrsCase) {
    const dialogRef = this.dialog.open(TransferIvrsCaseComponent, {
      data: ivrsCase.id,
    });
    await dialogRef.afterClosed().toPromise();
    this.caseTransferred.emit();
  }
}
