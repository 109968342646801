import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerService } from '../../../../shared/services/spinner/spinner.service';
import { DhhLearnerDto } from '../../../models/DhhDtos';
import { DhhRoutingService } from '../../../services/dhh-routing.service';
import { DhhService } from '../../../services/dhh.service';

@Component({
  selector: 'app-dhh-screening-recheck-new-learner',
  templateUrl: './dhh-screening-recheck-new-learner.component.html',
  styleUrls: ['./dhh-screening-recheck-new-learner.component.scss'],
})
export class DhhScreeningRecheckNewLearnerComponent implements OnInit {
  constructor(
    private readonly dhhService: DhhService,
    private readonly spinnerService: SpinnerService,
    private readonly dhhRoutingService: DhhRoutingService,
    public dialogRef: MatDialogRef<DhhScreeningRecheckNewLearnerComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  onClose() {
    this.closeModal(false);
  }

  onComplete(learner: DhhLearnerDto) {
    setTimeout(() => this.spinnerService.incrementLoading());

    this.dhhService.addLearner(learner).subscribe(
      (result) => {
        if (result.succeeded && learner.stateAssignedId) {
          this.closeModal(true);
          this.dhhRoutingService.goToLearnerHearingDashboard(result.value);
        } else if (result.succeeded) {
          this.closeModal(true);
        } else {
          this.dhhService.handleError('Adding learner failed', result);
        }
        setTimeout(() => this.spinnerService.decrementLoading());
      },
      (error) => {
        this.dhhService.handleError('Adding learner failed', error);
        setTimeout(() => this.spinnerService.decrementLoading());
      }
    );
  }

  closeModal(updated: boolean) {
    this.dialogRef.close(updated);
  }
}
