<!--suppress XmlDuplicatedId -->
<mat-form-field *ngIf="!readOnly || showReadOnlyControl; else readOnlyTemplate" class="w-100">
  <mat-label
    [ngClass]="{
      asterisk_input: control?.hasError('required') || showAsterisk,
      'asterisk_input--hide': hideAsterisk
    }"
    >{{ label }}</mat-label
  >
  <input
    matInput
    [type]="type"
    *ngIf="inputType === 'text'"
    [class]="klass"
    id="{{ controlId }}"
    [formControl]="control"
    [placeholder]="placeholder"
    [attr.maxLength]="noMaxLength === false && maxLength > 0 ? maxLength : null"
    [readonly]="readOnly"
    [attr.disabled]="disabledState ? true : null"
    (blur)="internalBlur($event)"
    attr.aria-required="{{ control?.hasError('required') || showAsterisk ? 'true' : 'false' }}"
    [attr.aria-label]="label"
    [attr.tabindex]="tabindex"
  />
  <span matSuffix *ngIf="isBankableField && !disabledState && !readOnly">
    <button type="button" mat-icon-button aria-label="Bankable Text Icon" (click)="openBankableFieldOptions()" tabindex="0">
      <mat-icon class="bankable-icon">bookmark_add</mat-icon>
    </button>
  </span>
  <span matPrefix *ngIf="useInputGroup">
    <ng-content select="[prepend]"></ng-content>
  </span>
  <span matSuffix *ngIf="useInputGroup">
    <ng-content select="[append]"></ng-content>
  </span>

  <input
    *ngIf="inputType === 'password'"
    matInput
    type="password"
    [class]="klass"
    id="{{ controlId }}"
    [formControl]="control"
    [placeholder]="placeholder"
    [readonly]="readOnly"
    [attr.disabled]="disabledState ? true : null"
    (blur)="internalBlur($event)"
  />
  <mat-hint *ngIf="hint">{{ hint }}</mat-hint>
  <mat-error>
    <app-field-error-display [control]="control"></app-field-error-display>
  </mat-error>
  <mat-hint *ngIf="control?.value?.length >= maxLength && !noMaxLength"
    ><div class="mb-5">Max character limit reached of {{ maxLength }}</div>
  </mat-hint>
</mat-form-field>

<ng-template #readOnlyTemplate>
  <p class="read-only-container">
    <strong>{{ label }}</strong
    >{{ control.value }}
  </p>
</ng-template>
