import { SelectionModel } from '@angular/cdk/collections';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { CaseListItem } from 'src/app/shared/models/case';
import { District } from 'src/app/shared/models/district';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { CaseService } from 'src/app/shared/services/case/case.service';

@Component({
  selector: 'app-unassigned-cases',
  templateUrl: './unassigned-cases.component.html',
  styleUrls: ['./unassigned-cases.component.scss'],
})
export class UnassignedCasesComponent implements OnInit, OnDestroy {
  @Input() cases: CaseListItem[];
  @Input() districts: District[];
  @Input() counties: string[];
  @Output() selectedCases: EventEmitter<CaseListItem[]> = new EventEmitter<CaseListItem[]>();
  @Output() filter: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  private subscriptions = new Subscription();

  shortDateFormat = shortDateFormat;
  districtOptions: KeyValuePair[] = [];

  displayedColumns: string[] = ['select', 'name', 'aea', 'learnerDateOfBirth', 'district'];
  dataSource = new MatTableDataSource<CaseListItem>();
  selection = new SelectionModel<CaseListItem>(true, []);
  searchCriteriaDistrict = '';
  orderBy = 'name';
  orderByDescending = false;
  currentPage = 0;
  pageSize = 20;
  totalRows = 0;

  formGroup = this.fb.group({
    name: '',
    district: '',
  });

  get currentPageInfo(): PageEvent {
    return {
      pageIndex: this.currentPage,
      pageSize: this.pageSize,
      length: this.totalRows,
    } as PageEvent;
  }

  constructor(private caseService: CaseService, private authService: AuthService, private fb: FormBuilder) {}

  ngOnInit() {
    this.loadData();

    this.subscriptions.add(
      this.selection.changed.subscribe((res) => {
        this.selectedCases.emit(res.source.selected);
      })
    );

    this.subscriptions.add(
      this.sort?.sortChange.subscribe((sort: Sort) => {
        this.orderBy = !!sort.direction ? sort.active : 'name';
        this.orderByDescending = sort.direction === 'desc' ? true : false;
        this.loadData();
      })
    );

    this.subscriptions.add(
      this.formGroup.valueChanges.pipe(debounceTime(250)).subscribe((value) => {
        if (this.formGroup.get('district').valid) {
          this.filter.emit(value.district);
          this.loadData(value.district, true);
        }
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async loadData(searchCriteria?: string, savedCriteria?: boolean) {
    if (savedCriteria) {
      this.searchCriteriaDistrict = searchCriteria;
    }
    const res = await this.caseService
      .getUnassigned(
        this.currentPage + 1,
        this.pageSize,
        this.orderBy,
        this.orderByDescending,
        this.authService.isSuperAdmin,
        this.searchCriteriaDistrict,
        this.formGroup.get('name').value
      )
      .toPromise();

    this.cases = res.unassignedCases.items;
    this.totalRows = res.unassignedCases.totalCount;
    this.dataSource.data = this.cases;
    this.districtOptions = res.districts.map((x) => new KeyValuePair(x.id, x.name));
    this.selection.clear();
  }

  isAllSelected() {
    const numSelected = this.selection.selected?.length;
    const numRows = this.dataSource.data?.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ? this.selection.clear() : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: CaseListItem): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id + 1}`;
  }

  async pageChanged(event: PageEvent): Promise<void> {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    await this.loadData();
  }
}
