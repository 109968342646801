import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LoginModel } from '../auth-models';
import { AuthService } from '../auth.service';
import { EarlyAccessQuestionnaireInvite } from './models/early-access-questionnaire-invite';
import { FamilyMemberAdd, FamilyMemberRegistration } from './models/family-member-registration';

@Component({
  selector: 'app-family-member-registration',
  templateUrl: './family-member-registration.component.html',
  styleUrls: ['./family-member-registration.component.scss'],
})
export class FamilyMemberRegistrationComponent implements OnInit {
  questionnaireInvite: EarlyAccessQuestionnaireInvite;
  registerError: string;
  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    const inviteId = this.route.snapshot.paramMap.get('inviteId');
    forkJoin([
      this.authService.nonInterceptedAuthenticate().pipe(
        catchError(() => {
          return of(undefined);
        })
      ),
      this.authService.getFamilyInvite(inviteId).pipe(
        catchError(() => {
          return of(undefined);
        })
      ),
    ]).subscribe(
      ([authUser, invite]) => {
        if (!invite) {
          this.router.navigate(['/']);
          return;
        }
        this.questionnaireInvite = invite;

        // If the invite has already been verified, and user chose to skip creating account
        // they are no longer able to access the questionnaire, redirect to login.
        if (invite.verified && invite.skipAccountCreation) {
          this.router.navigate(['/auth/family/login']);
        }
        if (authUser) {
          if (authUser.id === invite.userId && invite.verified) {
            this.router.navigate(['/', 'cases', invite.caseId, 'evaluation', 'early-access-questionnaire', invite.caseId], {
              queryParams: { popup: 'true' },
            });
          } else {
            this.authService.logout(`/auth/family-invite/${invite.id}`);
          }
        }
      },
      (error) => console.log(error)
    );
  }

  onSubmit(event) {
    if (this.questionnaireInvite.familyUserId && this.questionnaireInvite.verified) {
      this.loginVerifiedUser(event);
    } else if (this.questionnaireInvite.familyUserId && !this.questionnaireInvite.hasAnonAccount) {
      this.addFamilyMember(event);
    } else {
      this.registerFamilyMember(event);
    }
  }

  registerFamilyMember(event) {
    this.registerError = undefined;
    const familyMemberRegistration = event as FamilyMemberRegistration;
    this.authService.registerFamilyMember(familyMemberRegistration).subscribe(
      (res) => {
        if (res) {
          this.router.navigate(
            [`/cases/${this.questionnaireInvite.caseId}/evaluation/early-access-questionnaire/`, this.questionnaireInvite.caseId],
            {
              queryParams: { popup: 'true' },
            }
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.registerError = error.error.complexity ? error.error.complexity : '';
      }
    );
  }

  addFamilyMember(event) {
    const familyMemberAdd = event as FamilyMemberAdd;
    this.authService.addFamilyMember(familyMemberAdd).subscribe(
      (res) => {
        if (res) {
          this.router.navigate(
            [`/cases/${this.questionnaireInvite.caseId}/evaluation/early-access-questionnaire/`, this.questionnaireInvite.caseId],
            {
              queryParams: { popup: 'true' },
            }
          );
        }
      },
      (error: HttpErrorResponse) => {
        this.registerError = error.error.message ? error.error.message : 'Error submitting form.';
      }
    );
  }

  loginVerifiedUser(event) {
    const loginModel = {
      email: event.username,
      password: event.password,
    } as LoginModel;
    this.authService.login(loginModel).subscribe((res) => {
      if (res) {
        this.router.navigate(
          [`/cases/${this.questionnaireInvite.caseId}/evaluation/early-access-questionnaire/`, this.questionnaireInvite.caseId],
          {
            queryParams: { popup: 'true' },
          }
        );
      }
    });
  }
}
