<mat-card class="mt-2" *ngIf="participants">
  <h4>Appointment Letter Recipient</h4>
  <div class="table-overflow app-table">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="fullName" matSortDirection="asc" class="mat-elevation-z0">
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Recipient Name</th>
        <td mat-cell *matCellDef="let element">{{ element.fullName }}</td>
      </ng-container>

      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let element">
          {{ element.email }}
        </td>
      </ng-container>

      <ng-container matColumnDef="relationship">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Relationship</th>
        <td mat-cell *matCellDef="let element">
          {{ element.familyRelationship?.label }}
        </td>
      </ng-container>

      <ng-container matColumnDef="streetAddress">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Street Address</th>
        <td mat-cell *matCellDef="let element">
          {{ element.streetAddress }}
        </td>
      </ng-container>

      <ng-container matColumnDef="zipCode">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Zip Code</th>
        <td mat-cell *matCellDef="let element">
          {{ element.zipCode }}
        </td>
      </ng-container>

      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>City / Town</th>
        <td mat-cell *matCellDef="let element">{{ element.city }}</td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
        <td mat-cell *matCellDef="let element">
          {{ element.state }}
        </td>
      </ng-container>

      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef colspan="7">
          <i>No results...</i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr [hidden]="dataSource?.data?.length > 0" mat-footer-row *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"></tr>
    </table>

    <mat-paginator [dataSource]="dataSource"></mat-paginator>
  </div>
</mat-card>
