import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { HttpStatusCode } from '@angular/common/http';
import { AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { ActivatedRoute, Router } from '@angular/router';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { catchError, debounceTime, takeUntil } from 'rxjs/operators';
import { Referral } from 'src/app/child-find/early-access-referral/referral';
import { AlertConfig } from 'src/app/shared/components/page-alert/page-alert.component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ConsentFormType } from 'src/app/shared/models/fiie-consent/consent-form';
import { FamilyConsentStatus } from 'src/app/shared/models/fiie-consent/family-consent-status';
import { checkForAlert, createAlertConfig } from 'src/app/shared/pageAlertHelper';
import { ConsentFormService } from 'src/app/shared/services/consent-form/consent-form.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { TaggedItem } from 'src/app/tags/tagged-item';
import { TaggingService } from 'src/app/tags/taggingService.service';
import { AuthService } from '../../auth/auth.service';
import { AppPermissions } from '../../permissions';
import { AreYouSureComponent } from '../../shared/components/are-you-sure-modal/are-you-sure.component';
import { NotifySave } from '../../shared/decorators/notify-save.decorator';
import { IntakeType } from '../../shared/models/case';
import { QuestionnaireService } from '../early-access-questionnaire/services/questionnaire.service';
import { EarlyAccessQuestionnaire } from '../models/early-access-questionnaire';
import { Intake } from '../models/intake';
import { IntakeService } from '../services/intake.service';
import { HealthInfoFormComponent, HealthInfoTabChanged } from '../shared/components/health-info-form/health-info-form.component';
import { DatabaseLinksService } from '../../shared/services/database-links/database-links.service';

dayjs.extend(utc);

@Component({
  selector: 'app-early-access-intake',
  templateUrl: './early-access-intake.component.html',
  styleUrls: ['./early-access-intake.component.scss'],
})
export class EarlyAccessIntakeComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('stepper', { static: true }) stepper: MatStepper;

  private subscription: Subscription = new Subscription();
  caseId: string;
  evaluationButtonDisabled = true;
  intake: Intake;
  submitAttempted = false;
  pageTitle: string;
  referral: Referral;
  questionnaire: EarlyAccessQuestionnaire;
  submitted$ = new Subject();
  navigateToLearnerMgmnt = false;
  activeEvaluationId: string;
  intakeLocked = false;
  medicalConditionUpdated = false;
  importQuestionnaireSelected = false;
  tabsValidState = {
    childInfo: undefined,
    familyInfo: undefined,
    insuranceInfo: undefined,
    healthInfo: undefined,
    nextSteps: undefined,
    documentationInfo: undefined,
  };
  tabs: {
    message: string;
    check: boolean;
  }[];
  allTabsLoaded = false;
  today = new Date();

  formGroup = this.fb.group(
    {
      childInfo: this.fb.group({}),
      familyInfo: this.fb.group({}, { updateOn: 'blur' }),
      insuranceInfo: this.fb.group({}),
      healthInfo: this.fb.group({}),
      nextSteps: this.fb.group({}),
      documentationInfo: this.fb.group({}),
    },
    { updateOn: 'blur' }
  );

  pagesIncomplete: AlertConfig = {
    status: null,
    message: null,
    showRefreshMessage: false,
  };

  @ViewChild('stepper') private matStepper: MatStepper;
  @ViewChild('healthInfoForm')
  private healthInfoFormComponent: HealthInfoFormComponent;
  healthTabStatus: HealthInfoTabChanged = {
    canMovePrevious: false,
    canMoveNext: true,
  };
  shortDateFormat = shortDateFormat;

  constructor(
    private fb: FormBuilder,
    private intakeService: IntakeService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private changeDetector: ChangeDetectorRef,
    private readonly notificationService: NotificationService,
    private readonly consentFormService: ConsentFormService,
    private readonly questionnaireService: QuestionnaireService,
    private readonly authService: AuthService,
    private readonly routingService: RoutingService,
    private readonly taggingService: TaggingService,
    private readonly databaseLinksService: DatabaseLinksService,
    private readonly router: Router
  ) {}

  get childInfo() {
    return this.formGroup.controls.childInfo as FormGroup;
  }

  get childsName() {
    return this.intake?.childInfo?.firstName + ' ' + this.intake?.childInfo?.lastName;
  }

  get childInfoInvalid() {
    return this.childInfo.touched && this.childInfo.invalid;
  }

  get familyInfo() {
    return this.formGroup.controls.familyInfo as FormGroup;
  }

  get familyInfoInvalid() {
    if (this.familyInfo.touched && this.formGroup.hasError('atLeastOnePhoneRequired')) {
      return false;
    }
    return this.familyInfo.touched && this.familyInfo.invalid;
  }

  get insuranceInfo() {
    return this.formGroup.controls.insuranceInfo as FormGroup;
  }

  get insuranceInfoInvalid() {
    return this.insuranceInfo.touched && this.insuranceInfo.invalid;
  }

  get healthInfo() {
    return this.formGroup.controls.healthInfo as FormGroup;
  }

  get healthInfoInvalid() {
    return this.healthInfo.touched && this.healthInfo.invalid;
  }

  get healthInfoComplete() {
    return this.healthInfo.touched && this.healthInfo.valid;
  }

  get healthInfoProviderInformation() {
    return this.healthInfo.controls.providerInformation as FormGroup;
  }

  get healthInfoProviderInformationInvalid() {
    return this.healthInfoProviderInformation?.touched && this.healthInfoProviderInformation?.invalid;
  }

  get healthInfoBirthMedical() {
    return this.healthInfo.controls.medicalHistoryInformation as FormGroup;
  }

  get healthInfoBirthMedicalInvalid() {
    return this.healthInfoBirthMedical?.touched && this.healthInfoBirthMedical?.invalid;
  }

  get healthInfoNutritionGrowth() {
    return this.healthInfo.controls.nutritionGrowthInformation as FormGroup;
  }

  get healthInfoNutritionGrowthInvalid() {
    return this.healthInfoNutritionGrowth?.touched && this.healthInfoNutritionGrowth?.invalid;
  }

  get healthInfoDentalVisionHearing() {
    return this.healthInfo.controls.dentalVisionHearingInformation as FormGroup;
  }

  get healthInfoDentalVisionHearingInvalid() {
    return this.healthInfoDentalVisionHearing?.touched && this.healthInfoDentalVisionHearing?.invalid;
  }

  get healthInfoHealthConcernsOverallHealth() {
    return this.healthInfo.controls.additionalConcernsInformation as FormGroup;
  }

  get healthInfoHealthConcernsOverallHealthInvalid() {
    return this.healthInfoHealthConcernsOverallHealth?.touched && this.healthInfoHealthConcernsOverallHealth?.invalid;
  }

  get nextSteps() {
    return this.formGroup.controls.nextSteps as FormGroup;
  }

  get nextStepsInvalid() {
    return this.nextSteps.touched && this.nextSteps.invalid;
  }

  get documentationInfo() {
    return this.formGroup.controls.documentationInfo as FormGroup;
  }

  get documentationInfoInvalid() {
    return this.documentationInfo.touched && this.documentationInfo.invalid;
  }

  get questionnaireInviteVerified() {
    return this.questionnaire?.questionnaireInviteVerified;
  }

  get isServiceCoordinator() {
    return this.authService.isServiceCoordinator;
  }

  get disableImportQuestionnaire() {
    const questionnaireLockDate = new Date(this.questionnaire?.lockedOn);
    if (this.today >= questionnaireLockDate) {
      return false;
    }
    return !this.questionnaire?.isSubmitted;
  }

  ngOnInit() {
    this.caseId = this.route.snapshot.paramMap.get('caseId');

    forkJoin([
      this.questionnaireService.getQuestionnaire(this.caseId).pipe(
        catchError((error) => {
          if (error.status === HttpStatusCode.NotFound) this.router.navigate(['/not-found']);
          return of(undefined);
        })
      ),
      this.intakeService.getIntake(this.caseId).pipe(
        catchError((error) => {
          if (error.status === HttpStatusCode.NotFound) this.router.navigate(['/not-found']);
          return of(undefined);
        })
      ),
    ]).subscribe(([questionnaire, intake]) => {
      if (!questionnaire && !intake) {
        return;
      }
      this.questionnaire = questionnaire;
      this.intake = intake;
      this.pageTitle = 'Early ACCESS Intake - ' + this.intake.learner.fullName;
      this.referral = intake.referral;
      this.activeEvaluationId = intake.activeEvaluationId;
      this.getEvalConsentApproval(this.caseId);
      if (this.intake.lockedOn && this.intake.intakeType === IntakeType.PartB) {
        const meetingDate = new Date(this.intake.lockedOn);
        if (this.today >= meetingDate) {
          this.intakeLocked = true;
        }
      }

      if (!this.canEdit(this.caseId)) {
        this.intakeLocked = true;
      }

      if (!this.intake.nextSteps?.taggedItem) {
        this.taggingService
          .addTag({
            caseId: this.caseId,
            learnerId: this.intake.learner?.id,
            taggedForPwn: true,
          } as TaggedItem)
          .subscribe((taggedItem) => {
            this.intake.nextSteps.taggedItem = taggedItem;
          });
      }

      this.startAutosaving();
    });

    if (this.route.snapshot.paramMap.get('tab')) {
      this.goToTab(+this.route.snapshot.paramMap.get('tab'));
    }
  }

  pauseAutosave(pause = false) {
    if (!!pause) {
      this.subscription.unsubscribe();
    } else {
      this.subscription = new Subscription();
      this.startAutosaving();
      this.formGroup.markAsDirty();
      this.formGroup.updateValueAndValidity();
    }
  }

  canViewReferral(caseId: string) {
    return this.authService.isAllowedByCaseId(
      caseId,
      new Map<string, string[]>([['TeamMember', ['AeaEdit', 'ServiceCoordinator']]]),
      AppPermissions.PartCViewReferral
    );
  }
  getEvalConsentApproval(caseId: string) {
    this.consentFormService.getConsentForms(caseId).subscribe((forms) => {
      const evalForm = forms.find((x) => x.type === ConsentFormType.InitialFamilyEvaluationAndAssessment);
      this.evaluationButtonDisabled = evalForm?.statuses[0]?.status !== FamilyConsentStatus.Approved;
    });
  }

  flipEvalButton(evalStatus: boolean) {
    this.evaluationButtonDisabled = evalStatus;
  }

  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  routeToEarlyAccessReferralReadOnlyForm() {
    const config: NewWindowConfig = {
      path: `child-find/early-access-referral/${this.referral.id}/read-only`,
      popup: true,
    };
    openNewWindow(config);
  }

  onStepperSelectionChange(change: StepperSelectionEvent) {
    // change.previouslySelectedStep.stepControl.markAllAsTouched();
    this.createAlert();
  }

  goToTab(tab: number) {
    this.stepper.selectedIndex = tab;
  }

  async onSubmit(): Promise<void> {
    this.submitAttempted = true;
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      // If a debounced auto-save is pending...this will cancel it via the
      // takeUntil call in that subscription
      this.submitted$.next(undefined);
      await this.saveIntake();
    }
  }

  helpToggled(e: any) {
    // TODO: Toggle Sidebar
    this.dialog.open(DialogTemporaryHelpComponent, { disableClose: false });
  }

  trackFormInitialized(formName: string) {
    const currentForm = this.formGroup.get(formName) as FormGroup;
    this.tabsValidState[formName] = currentForm.valid;
    this.allTabsLoaded = true;

    for (const tabName of Object.keys(this.tabsValidState)) {
      if (this.tabsValidState[tabName] === undefined) {
        this.allTabsLoaded = false;
        break;
      }
    }

    if (this.allTabsLoaded) {
      if (this.intake?.intakeModified) {
        this.createAlert(true);
      }
    }
  }

  evaluateHealthInfoTabChange($event: HealthInfoTabChanged) {
    this.healthTabStatus.canMovePrevious = $event.canMovePrevious;
    this.healthTabStatus.canMoveNext = $event.canMoveNext;
  }

  navigateFromHealthInfo(direction: 'previous' | 'next') {
    if (direction === 'previous') {
      if (this.healthTabStatus.canMovePrevious) {
        this.healthInfoFormComponent.moveTab('previous');
      } else {
        this.matStepper.previous();
      }
    } else {
      if (this.healthTabStatus.canMoveNext) {
        this.healthInfoFormComponent.moveTab('next');
      } else {
        this.matStepper.next();
      }
    }
  }

  getMessageTabsForAlert() {
    return [
      { message: 'Child Info', check: this.childInfoInvalid },
      { message: 'Family Info', check: this.familyInfoInvalid },
      { message: 'Insurance Info', check: this.insuranceInfoInvalid },
      {
        message: 'Health Info: Provider(s) Information',
        check: this.healthInfoProviderInformationInvalid,
      },
      {
        message: 'Health Info: Birth / Medical History',
        check: this.healthInfoBirthMedicalInvalid,
      },
      {
        message: 'Health Info: Nutrition and Growth',
        check: this.healthInfoNutritionGrowthInvalid,
      },
      {
        message: 'Health Info: Dental / Vision / Hearing',
        check: this.healthInfoDentalVisionHearingInvalid,
      },
      {
        message: 'Health Info: Health Concerns / Overall Current Health',
        check: this.healthInfoHealthConcernsOverallHealthInvalid,
      },
      { message: 'Next Steps', check: this.nextStepsInvalid },
      { message: 'Documentation', check: this.documentationInfoInvalid },
    ];
  }

  getMessageTabsForInitAlert() {
    const tabs = [
      { message: 'Child Info', check: this.childInfo.invalid },
      { message: 'Family Info', check: this.familyInfo.invalid },
      { message: 'Insurance Info', check: this.insuranceInfo.invalid },
      {
        message: 'Health Info: Provider(s) Information',
        check: this.healthInfoProviderInformation?.invalid,
      },
      {
        message: 'Health Info: Birth / Medical History',
        check: this.healthInfoBirthMedical?.invalid,
      },
      {
        message: 'Health Info: Nutrition and Growth',
        check: this.healthInfoNutritionGrowth?.invalid,
      },
      {
        message: 'Health Info: Dental / Vision / Hearing',
        check: this.healthInfoDentalVisionHearing?.invalid,
      },
      {
        message: 'Health Info: Health Concerns / Overall Current Health',
        check: this.healthInfoHealthConcernsOverallHealth?.invalid,
      },
      { message: 'Next Steps', check: this.nextSteps?.invalid },
      { message: 'Documentation', check: this.documentationInfo?.invalid },
    ];
    return tabs;
  }

  createAlert(isInit = false): void {
    if (isInit || this.intake?.intakeModified) {
      this.tabs = this.getMessageTabsForInitAlert();
    } else {
      this.tabs = this.getMessageTabsForAlert();
    }

    const invalid = checkForAlert(this.tabs);
    this.pagesIncomplete = createAlertConfig('Incomplete Page(s): ', 'warning', invalid, true);
  }

  selectImportQuestionnaire() {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        question: 'Are you sure?',
        subQuestion: `Importing this questionnaire will replace all previously entered data.
                      Clicking the undo button for a specific field will replace the response
                      with the referral information for that field. Do you wish to proceed? \n
                      Note, not all intake questions appear on the family questionnaire. Scroll through each page to find and complete blank questions.`,
      },
    });
    dialogRef.afterClosed().subscribe((confirmed) => {
      if (confirmed) {
        this.importQuestionnaireSelected = true;
      }
    });
  }

  viewQuestionnaire() {
    const config: NewWindowConfig = {
      path: `cases/${this.caseId}/evaluation/early-access-questionnaire/${this.caseId}/read-only`,
      popup: true,
      width: '1300px',
    };
    openNewWindow(config);
  }

  resendQuestionnaire() {
    this.questionnaireService.resendQuestionnaire(this.caseId, this.questionnaire?.id).subscribe((result) => {
      if (!result.succeeded) {
        this.notificationService.alert(result.errors[0].description);
        return;
      }
      this.notificationService.alert('Re-sent questionnaire invite successfully.');
    });
  }

  private getIntake() {
    this.intakeService.getIntake(this.intake.caseId).subscribe((intake) => {
      this.intake = intake;
    });
  }

  private startAutosaving() {
    if (!this.authService.isAllowedByCaseId(this.intake?.caseId, null, AppPermissions.EditIntake)) {
      return;
    }
    this.subscription.add(
      this.formGroup.valueChanges.pipe(debounceTime(3000), takeUntil(this.submitted$)).subscribe(async () => {
        if (this.formGroup.dirty) {
          await this.saveIntake();
        }
      })
    );
  }

  private makeSaveRequest(): Observable<Intake> {
    const intake = {
      ...this.intake,
      ...this.formGroup.getRawValue(), // use .getRawValue or else disabled field values won't be included
      aeaId: this.formGroup.value.childInfo.aeaId,
    };

    if (intake.childInfo.dateOfBirth) {
      intake.childInfo.dateOfBirth = dayjs(intake.childInfo.dateOfBirth).format().substring(0, 10);
    }

    return this.intakeService.saveIntake(intake);
  }

  onUpload(formData: FormData) {
    const upload = () =>
      this.intakeService.uploadDocumentation(formData, this.intake.caseId).subscribe(
        () => {
          this.getIntake();
          this.notificationService.success('Upload complete');
        },
        (err) => {
          console.log(err);
          this.getIntake();
        }
      );

    if (!!this.intake?.caseId) {
      upload();
    } else {
      if (!this.intakeLocked) {
        this.makeSaveRequest().subscribe((res: Intake) => {
          this.intake = res;
          upload();
        });
      }
    }
  }

  onDeleteDocument(documentId: string) {
    this.intakeService.deleteDocument(this.intake.caseId, documentId).subscribe(
      () => this.getIntake(),
      (err) => {
        console.log(err);
        this.getIntake();
      }
    );
  }

  openIowaIdeaInformationUrl() {
    window.open(this.databaseLinksService.getDatabaseLink('iowaFamilySupportNetwork'), '_blank');
  }

  private async saveIntake(): Promise<void> {
    if (!this.intakeLocked) {
      await this.sendSaveRequest();
    } else {
      this.navigateToLearnerMgmnt = false;
      this.routingService.learnerDashboard(this.intake.learner.id);
    }
  }

  @NotifySave
  private async sendSaveRequest(): Promise<void> {
    this.intake = await this.makeSaveRequest().toPromise();
    if (this.navigateToLearnerMgmnt) {
      this.navigateToLearnerMgmnt = false;
      this.routingService.learnerDashboard(this.intake.learner.id);
    }
  }

  refreshIntakeOnDocumentUpload() {
    const caseId = this.route.snapshot.paramMap.get('caseId');
    this.intakeService.getIntake(caseId).subscribe((intake) => {
      this.intake = intake;
    });
  }

  private canEdit(caseId: string) {
    return this.authService.isAllowedByCaseId(caseId, undefined, AppPermissions.EditIntake);
  }
}

// noinspection AngularMissingOrInvalidDeclarationInModule
@Component({
  selector: 'app-dialog-temporary-help',
  template: `<h3>Help: Early ACCESS Intake</h3>
    <hr />
    <p>Help content is pending for this feature.</p>
    <p>Click anywhere outside of this box to close.</p>`,
})
export class DialogTemporaryHelpComponent {
  constructor(public dialogRef: MatDialogRef<DialogTemporaryHelpComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
