import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { AppMatModule } from 'src/app/shared/app-mat.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SearchModule } from '../search/search.module';
import { ChildInfoFormComponent } from './child-info-form/child-info-form.component';
import { DocumentationInfoFormComponent } from './documentation-info-form/documentation-info-form.component';
import { EarlyAccessReferralRoutingModule } from './early-access-referral-routing.module';
import { EarlyAccessReferralComponent } from './early-access-referral.component';
import { FamilyInfoFormComponent } from './family-info-form/family-info-form.component';
import { InProgressReferralModalComponent } from './modals/in-progress-referral-modal/in-progress-referral-modal.component';
import { ParentInfoFormComponent } from './parent-info-form/parent-info-form.component';
import { ReadOnlyComponent } from './read-only/read-only.component';
import { ReferralListComponent } from './referral-list/referral-list.component';
import { EarlyAccessReferralMedicalDiagnosisTableComponent } from './referral-reason-info-form/medical-diagnosis-table/referral-medical-diagnosis-table.component';
import { ReferralReasonInfoFormComponent } from './referral-reason-info-form/referral-reason-info-form.component';
import { ReferralSourceInfoFormComponent } from './referral-source-info-form/referral-source-info-form.component';
import { ReferralSubmitComponent } from './referral-submit/referral-submit.component';
import { ThankYouLetterComponent } from './thank-you-letter/thank-you-letter.component';

@NgModule({
  declarations: [
    ChildInfoFormComponent,
    DocumentationInfoFormComponent,
    EarlyAccessReferralComponent,
    FamilyInfoFormComponent,
    InProgressReferralModalComponent,
    ParentInfoFormComponent,
    ReferralSourceInfoFormComponent,
    ReferralReasonInfoFormComponent,
    ReferralSubmitComponent,
    ReadOnlyComponent,
    ReferralListComponent,
    ThankYouLetterComponent,
    EarlyAccessReferralMedicalDiagnosisTableComponent,
  ],
  entryComponents: [InProgressReferralModalComponent],
  imports: [
    AppMatModule,
    CommonModule,
    EarlyAccessReferralRoutingModule,
    ReactiveFormsModule,
    SearchModule,
    SharedModule,
    AngularSvgIconModule,
  ],
  providers: [DatePipe],
  exports: [
    ChildInfoFormComponent,
    EarlyAccessReferralComponent,
    FamilyInfoFormComponent,
    ParentInfoFormComponent,
    ReferralSubmitComponent,
    ReadOnlyComponent,
    EarlyAccessReferralMedicalDiagnosisTableComponent,
  ],
})
export class EarlyAccessReferralModule {}
