<header class="card-spacing-bottom">
  <section>
    <app-page-header
      [condensed]="true"
      [pageTitle]="pageTitle"
      backgroundColor="yellow"
      [shouldShowDhhBanner]="evaluation?.learner.isDhh"
    ></app-page-header>
  </section>

  <app-learner-info-header [learnerId]="evaluation?.learner.id" [condensed]="true"></app-learner-info-header>
</header>

<form [formGroup]="formGroup">
  <mat-card class="mb-0 card--plain card--shadow card-spacing-top" *ngIf="survey">
    <mat-accordion displayMode="flat" multi class="accordion accordion--close">
      <mat-expansion-panel class="mat-elevation-z0" *ngIf="questionsK12.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title> <h3 class="my-0 text-normal">K-12 Questions</h3></mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="input__divider mt-0" />
        <ul class="list">
          <li class="list__item" *ngFor="let familySurveyAnswer of questionsK12">
            <h4 class="text-primary my-0">{{ familySurveyAnswer.question }}</h4>
            <app-textarea [formControlName]="familySurveyAnswer.id" [disabledState]="readOnly" label="Answer"></app-textarea>
          </li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0" *ngIf="questionsTransition.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title> <h3 class="my-0 text-normal">Transition Questions</h3></mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="input__divider mt-0" />
        <ul class="list">
          <li class="list__item" *ngFor="let familySurveyAnswer of questionsTransition">
            <h4 class="text-primary my-0">{{ familySurveyAnswer.question }}</h4>
            <app-textarea [formControlName]="familySurveyAnswer.id" [disabledState]="readOnly" label="Answer"></app-textarea>
          </li>
        </ul>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0" *ngIf="questionsPreschool.length > 0">
        <mat-expansion-panel-header>
          <mat-panel-title> <h3 class="my-0 text-normal">Preschool Questions</h3></mat-panel-title>
        </mat-expansion-panel-header>
        <hr class="input__divider mt-0" />
        <ul class="list">
          <li class="list__item" *ngFor="let familySurveyAnswer of questionsPreschool">
            <h4 class="text-primary my-0">{{ familySurveyAnswer.question }}</h4>
            <app-textarea [formControlName]="familySurveyAnswer.id" [disabledState]="readOnly" label="Answer"></app-textarea>
          </li>
        </ul>
      </mat-expansion-panel>
    </mat-accordion>

    <ng-container *ngIf="questionsCustom">
      <hr class="input__divider" />
      <h3 class="text-semibold">{{ questionsCustom.question }}</h3>
      <app-textarea [formControlName]="questionsCustom.id" [disabledState]="readOnly" label="Answer"></app-textarea>
    </ng-container>
  </mat-card>
  <div class="display-flex justify-content-end align-items-center mt-2">
    <button
      mat-raised-button
      aria-label="Save Button"
      color="primary"
      (click)="onSubmit(false)"
      [disabled]="readOnly"
      class="mr-2"
      tabindex="0"
    >
      Save
    </button>
    <button
      mat-raised-button
      aria-label="Complete"
      color="primary"
      [disabled]="readOnly || formGroup.invalid"
      (click)="onSubmit(true)"
      tabindex="0"
    >
      Complete
    </button>
  </div>
</form>

<div class="page-bottom-leeway"></div>
