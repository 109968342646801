import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { forkJoin, Subscription } from 'rxjs';
import { usStateOptions, yesNoOptions } from 'src/app/shared/formHelpers';
import { DistrictService } from 'src/app/shared/services/district/district.service';
import { GenderService } from 'src/app/shared/services/gender/gender.service';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { LocationService } from 'src/app/shared/services/location/location.service';
import * as AppValidators from 'src/app/shared/validators';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { Referral } from '../referral';
import { DatabaseLinksService } from '../../../shared/services/database-links/database-links.service';

@Component({
  selector: 'app-child-info-form',
  templateUrl: './child-info-form.component.html',
  styleUrls: ['../early-access-referral.component.scss', './child-info-form.component.scss'],
})
export class ChildInfoFormComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();
  today = dayjs().startOf('day').toDate();
  twentyFiveYearsAgo = dayjs(this.today).subtract(25, 'year').toDate();
  usStateOptions = usStateOptions;
  @Input() currentReferral: Referral;
  @Input() formGroup: FormGroup;
  @ViewChild('tooOld') tooOldWarning: TemplateRef<any>;

  genderOptions: KeyValuePair[];
  languageOptions: KeyValuePair[];
  districtOptions: KeyValuePair[];

  constructor(
    private locationService: LocationService,
    private genderService: GenderService,
    private languageService: LanguageService,
    private districtService: DistrictService,
    private readonly databaseLinksService: DatabaseLinksService,
    private dialog: MatDialog
  ) {}

  yesNoOptions = yesNoOptions;

  ngOnInit() {
    this.loadData();
    this.initializeControls();
    this.monitorForValidDateOfBirth();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  loadData() {
    forkJoin([this.languageService.getLanguages(), this.genderService.get(), this.districtService.getDistricts()]).subscribe(
      ([languages, genders, districts]) => {
        this.languageOptions = languages.map((val) => new KeyValuePair(val.id, val.label));
        this.genderOptions = genders.map((g) => new KeyValuePair(g.id, g.label));
        this.districtOptions = districts.map((d) => new KeyValuePair(d.id, d.name));
      }
    );
  }

  private initializeControls() {
    this.formGroup.addControl('firstName', new FormControl('', [Validators.required, AppValidators.noNumbersValidator]));
    this.formGroup.addControl('middleName', new FormControl('', AppValidators.noNumbersValidator));
    this.formGroup.addControl('lastName', new FormControl('', [Validators.required, AppValidators.noNumbersValidator]));

    this.formGroup.addControl('dateOfBirth', new FormControl('', [Validators.required, AppValidators.tooOld(3)]));

    this.formGroup.addControl('genderId', new FormControl(null, { validators: [Validators.required], updateOn: 'change' }));

    this.formGroup.addControl('languageSpokenInHomeId', new FormControl(null, [Validators.required]));

    this.formGroup.addControl(
      'interpreterNeeded',
      new FormControl('', {
        updateOn: 'change',
        validators: [Validators.required],
      })
    );

    this.formGroup.addControl('streetAddress', new FormControl('', [Validators.required]));

    this.formGroup.addControl('city', new FormControl('', [Validators.required, AppValidators.noNumbersLimitedSpecialCharsValidator]));

    this.formGroup.addControl('state', new FormControl('', [Validators.required]));

    this.formGroup.addControl('zipCode', new FormControl('', [Validators.required, AppValidators.zipCodeValidator]));

    this.formGroup.addControl('county', new FormControl('', [AppValidators.noNumbersLimitedSpecialCharsValidator]));
    this.formGroup.addControl('childResidentDistrictId', new FormControl(''));
    this.formGroup.addControl('childAttendingDistrictId', new FormControl(''));
    this.formGroup.addControl('attendsChildCare', new FormControl('', { updateOn: 'change' }));
    this.formGroup.addControl('childCareProviderName', new FormControl(''));
    this.formGroup.addControl('childCareProviderStreetAddress', new FormControl(''));
    this.formGroup.addControl('childCareProviderCity', new FormControl('', [AppValidators.noNumbersLimitedSpecialCharsValidator]));
    this.formGroup.addControl('childCareProviderState', new FormControl(''));
    this.formGroup.addControl('childCareProviderZipCode', new FormControl('', [AppValidators.zipCodeValidator]));

    this.formGroup.addControl('aeaId', new FormControl(null));
  }

  private monitorForValidDateOfBirth() {
    const dobControl = this.formGroup.controls.dateOfBirth;
    this.subscription.add(
      dobControl.valueChanges.subscribe((dob: string) => {
        if (this.formGroup.controls.dateOfBirth.getError('tooOld')) {
          this.dialog
            .open(this.tooOldWarning, { width: '728px' })
            .afterClosed()
            .subscribe(() => {
              dobControl.setValue('');
            });
        }
      })
    );
  }

  openIowaFamilySupportNetworkUrl() {
    window.open(this.databaseLinksService.getDatabaseLink('iowaFamilySupportNetwork'), '_blank');
  }

  populateLocationFromZipCode() {
    const zipCodeControl = this.formGroup.get('zipCode');
    const zipCode = zipCodeControl.value;
    if (!zipCodeControl.valid) {
      return;
    }

    this.locationService.getLocationData(zipCode.substring(0, 5)).subscribe(
      (res) => {
        if (res) {
          this.formGroup.patchValue({
            aeaId: res.aeaId,
            city: res.city,
            county: res.county,
            state: 'IA',
          });
        }
      },
      () => {
        this.formGroup.patchValue({
          aeaId: null,
          city: null,
          county: null,
          state: null,
        });
      }
    );
  }

  populateChildcareLocationFromZipCode() {
    const zipCodeControl = this.formGroup.get('childCareProviderZipCode');
    const zipCode = zipCodeControl.value;

    if (!zipCode || !zipCodeControl.valid) {
      return;
    }

    this.locationService.getLocationData(zipCode.substring(0, 5)).subscribe((res) => {
      if (res) {
        this.formGroup.patchValue({
          childCareProviderCity: res.city,
          childCareProviderState: 'IA',
        });
      }
    });
  }
}
