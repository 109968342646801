import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { OperationResult } from '../../shared/models/operation-result';
import { ProgressMonitorReportItem } from '../models/progress-monitor-report-item';

@Injectable({
  providedIn: 'root',
})
export class ProgressMonitorReportService {
  public showForm = new Subject<void>();

  constructor(private readonly http: HttpClient) {}

  get(learnerId: string) {
    return this.http.get<Array<ProgressMonitorReportItem>>(`api/learners/${learnerId}/progress-monitoring/reports`);
  }

  create(learnerId: string, item: ProgressMonitorReportItem, createReport = false) {
    let params = new HttpParams();
    params = params.append('createReport', createReport);
    return this.http.post<ProgressMonitorReportItem>(`api/learners/${learnerId}/progress-monitoring/reports`, item, { params });
  }

  update(learnerId: string, item: ProgressMonitorReportItem, createReport = false) {
    let params = new HttpParams();
    params = params.append('createReport', createReport);
    return this.http.put<ProgressMonitorReportItem>(`api/learners/${learnerId}/progress-monitoring/reports`, item, { params });
  }

  delete(id: string, learnerId: string) {
    return this.http.delete<OperationResult>(`api/learners/${learnerId}/progress-monitoring/reports/${id}`);
  }
}
