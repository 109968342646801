<h3 class="mat-dialog-title" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle></h3>

<div class="mat-dialog-content py-2">
  <mat-card *ngIf="familyMember">
    <ul class="list">
      <li class="list__item">
        <h3>Name</h3>
        <app-compare-amendment-output [changedVersionText]="familyMember.fullName"></app-compare-amendment-output>
      </li>

      <li class="list__item">
        <h3>Lives with child?</h3>
        <app-compare-amendment-output [changedVersionText]="familyMember.livesWithChild ? 'Yes' : 'No'"></app-compare-amendment-output>
      </li>

      <li class="list__item">
        <h3>Type</h3>
        <app-compare-amendment-output [changedVersionText]="familyMember.familyRelationship"></app-compare-amendment-output>
      </li>

      <li class="list__item" *ngIf="familyMember.email">
        <h3>Email</h3>
        <app-compare-amendment-output [changedVersionText]="familyMember.email"></app-compare-amendment-output>
      </li>

      <li class="list__item" *ngIf="familyMember.streetAddress">
        <h3>Street Address</h3>
        <app-compare-amendment-output [changedVersionText]="familyMember.streetAddress"></app-compare-amendment-output>
      </li>

      <li class="list__item" *ngIf="familyMember.zipCode">
        <h3>Zip Code</h3>
        <app-compare-amendment-output [changedVersionText]="familyMember.zipCode"></app-compare-amendment-output>
      </li>

      <li class="list__item" *ngIf="familyMember.city">
        <h3>City / Town</h3>
        <app-compare-amendment-output [changedVersionText]="familyMember.city"></app-compare-amendment-output>
      </li>

      <li class="list__item" *ngIf="familyMember.state">
        <h3>State</h3>
        <app-compare-amendment-output [changedVersionText]="familyMember.state"></app-compare-amendment-output>
      </li>

      <li class="list__item" *ngIf="familyMember.bestTimeToContact">
        <h3>Best time to contact</h3>
        <app-compare-amendment-output [changedVersionText]="familyMember.bestTimeToContact"></app-compare-amendment-output>
      </li>

      <li class="list__item" *ngIf="bestWayToContact">
        <h3>Best way to Contact</h3>
        <app-compare-amendment-output [changedVersionText]="bestWayToContact"></app-compare-amendment-output>
      </li>

      <li class="list__item" *ngIf="familyMember.homePhone">
        <h3>Home Phone</h3>
        <app-compare-amendment-output [changedVersionText]="familyMember.homePhone"></app-compare-amendment-output>
      </li>

      <li class="list__item" *ngIf="familyMember.workPhone">
        <h3>Work Phone</h3>
        <app-compare-amendment-output [changedVersionText]="familyMember.workPhone"></app-compare-amendment-output>
      </li>

      <li class="list__item" *ngIf="familyMember.cellPhone">
        <h3>Cell Phone</h3>
        <app-compare-amendment-output [changedVersionText]="familyMember.cellPhone"></app-compare-amendment-output>
      </li>
    </ul>
  </mat-card>
</div>

<div class="mat-dialog-actions justify-content-end">
  <button mat-raised-button aria-label="Delete Icon" (click)="onClose()" cdkFocusInitial tabindex="0">Close</button>
</div>
