<mat-card>
  <mat-card-title>{{ cardTitle }}</mat-card-title>
  <div class="container container--not-centered container--form" [formGroup]="formGroup">
    <div class="row">
      <div class="col-md-12 col-lg-6 col-xl-4">
        <app-text-field label="Name (first and last)" formControlName="fullName"></app-text-field>
      </div>
      <div class="col-md-12 col-lg-6 col-xl-4">
        <app-text-field label="Email" type="email" formControlName="email"></app-text-field>
      </div>
      <div *ngIf="isParent || isFromFamilyContact" class="col-md-12 col-lg-6 col-xl-4">
        <fieldset>
          <app-radio-group label="Lives With Child" formControlName="livesWithChild" [options]="yesNoOptions"></app-radio-group>
        </fieldset>
      </div>
      <div *ngIf="!isEdit && isFromFamilyContact && !isPartCLearner" class="col-md-12 col-lg-6 col-xl-4">
        <app-autocomplete label="Type" formControlName="type" [options]="familyMemberTypes"></app-autocomplete>
      </div>
      <div *ngIf="isFromFamilyContact" class="col-md-12 col-lg-6 col-xl-4">
        <app-autocomplete label="Relationship" formControlName="familyRelationshipId" [options]="relationshipOptions"></app-autocomplete>
      </div>

      <ng-container *ngIf="isFromFamilyContact">
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-text-field label="Street Address" formControlName="streetAddress"></app-text-field>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-text-field label="Zip Code" formControlName="zipCode" (blurMe)="populateLocationFromZipCode()"></app-text-field>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-text-field label="City/Town" formControlName="city"></app-text-field>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-autocomplete label="State" formControlName="state" [options]="usStateOptions"></app-autocomplete>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-checkbox-single
            formControlName="addressUnknown"
            aria-labelledby="Current Address Unknown"
            label="Current Address Unknown"
          ></app-checkbox-single>
        </div>
      </ng-container>
    </div>

    <div class="row" *ngIf="parentForm.hasError('atLeastOnePhoneRequired') || formGroup.hasError('parent1OnePhoneNumberContactRequired')">
      <div class="col-lg">
        <mat-error *ngIf="parentForm.hasError('atLeastOnePhoneRequired') || formGroup.hasError('parent1OnePhoneNumberContactRequired')">
          At least one phone number or email address is
          <strong>required</strong>
        </mat-error>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-6 col-xl-4">
        <app-masked-input label="Home Phone Number" formControlName="homePhone" mask="(000) 000-0000"></app-masked-input>
      </div>
      <div class="col-md-12 col-lg-6 col-xl-4">
        <app-masked-input label="Work Phone Number" formControlName="workPhone" mask="(000) 000-0000"></app-masked-input>
      </div>
      <div class="col-md-12 col-lg-6 col-xl-4">
        <app-masked-input label="Cell Phone Number" formControlName="cellPhone" mask="(000) 000-0000"></app-masked-input>
      </div>
    </div>
    <div class="row">
      <div class="col-lg">
        <mat-error *ngIf="formGroup.controls.bestWayToReachHomePhone.value && !formGroup.controls.homePhone.value">
          Home phone number is <strong>required</strong> if it is the best way to contact
        </mat-error>
        <mat-error *ngIf="formGroup.controls.bestWayToReachWorkPhone.value && !formGroup.controls.workPhone.value">
          Work phone number is <strong>required</strong> if it is the best way to contact
        </mat-error>
        <mat-error
          *ngIf="
            (formGroup.controls.bestWayToReachCellPhone.value || formGroup.controls.bestWayToReachText.value) &&
            !formGroup.controls.cellPhone.value
          "
        >
          Cell phone number is <strong>required</strong> if it is the best way to contact
        </mat-error>
        <mat-error *ngIf="formGroup.controls.bestWayToReachEmail.value && !formGroup.controls.email.value">
          Email is <strong>required</strong> if it is the best way to contact
        </mat-error>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12 col-lg-6 col-xl-4">
        <div class="vertical-control">
          <label class="text-md vertical-label" for="bestTimeToContact">Best Way to Contact</label>
          <div>
            <app-checkbox-single formControlName="bestWayToReachHomePhone" aria-labelledby="Home" label="Home"></app-checkbox-single>
            <app-checkbox-single formControlName="bestWayToReachWorkPhone" aria-labelledby="Work" label="Work"></app-checkbox-single>
            <app-checkbox-single formControlName="bestWayToReachCellPhone" aria-labelledby="Cell" label="Cell"></app-checkbox-single>
            <app-checkbox-single formControlName="bestWayToReachEmail" aria-labelledby="Email" label="Email"></app-checkbox-single>
            <app-checkbox-single formControlName="bestWayToReachText" aria-labelledby="Texting" label="Texting"></app-checkbox-single>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <app-text-field label="Best Time to Contact" formControlName="bestTimeToContact" id="bestTimeToContact"></app-text-field>
      </div>
      <div class="col-md-12 col-lg-6 col-xl-4">
        <div class="vertical-control">
          <ng-container *ngIf="isFromFamilyContact && relationshipAllowed(formGroup.get('familyRelationshipId').value ?? null)">
            <app-checkbox-single formControlName="validated" aria-labelledby="Validated" label="Validated"></app-checkbox-single>
          </ng-container>
          <ng-container *ngIf="isFromFamilyContact && portalRelationship" class="col-md-12 col-lg-6 col-xl-2">
            <app-checkbox-single
              formControlName="noPortalAccess"
              aria-labelledby="No Portal Access"
              label="No Portal Access"
            ></app-checkbox-single>
            <app-checkbox-single
              formControlName="noSigningRights"
              aria-labelledby="No Signing Rights"
              label="No Signing Rights"
            ></app-checkbox-single>
          </ng-container>
        </div>
      </div>
      <div class="col-xl-4 col-lg-6 col-md-12">
        <div class="col-lg-12 align-self-center" *ngIf="!isFromFamilyContact && !isPk">
          <mat-checkbox [checked]="referralSource.value === familyMemberType" (change)="updateReferralSource($event)"
            >This is the person completing the referral
          </mat-checkbox>
        </div>
      </div>
    </div>
    <div class="row mt-0 mb-0" *ngIf="formGroup.get('isPersonCompletingReferral').value === true">
      <div class="col-xl-4 col-lg-6">
        <app-autocomplete
          [options]="referralHowHearAboutUsOptions"
          [formControl]="referralSourceHowHearAboutUsId"
          label="How did you hear about Early ACCESS?"
        ></app-autocomplete>
      </div>
      <div class="col-md-8" *ngIf="referralSourceHowHearAboutUsId.value === howHearAboutUsOtherId">
        <div class="container">
          <app-text-field label="Describe" [formControl]="referralSourceEarlyAccessOtherText"></app-text-field>
        </div>
      </div>
    </div>

    <ng-container *ngIf="formGroup.get('isPersonCompletingReferral').value && !isParent">
      <div class="row">
        <div class="col-md-12">
          <fieldset>
            <app-radio-group
              [options]="yesNoOptions"
              [formControl]="legalGuardian"
              label="Is the parent or legal guardian aware that the referral has been made to Early ACCESS?"
              [showAsterisk]="parentForm.hasError('guardianRequired')"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>

      <div class="row mt-2 mb-2" *ngIf="parentForm.get('legalGuardian').value === false">
        <div class="col-md-12">
          <p>
            <strong class="text-danger text-xs">
              Referral sources are responsible for informing the family that a referral has been made. Please contact the family after
              completing this referral.
            </strong>
          </p>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <fieldset>
            <app-radio-group
              [options]="yesNoOptions"
              [formControl]="exchangeInformation"
              label="Do you have a signed release from the family to exchange information about the child with Early ACCESS?"
              [showAsterisk]="parentForm.hasError('exchangeRequired')"
            ></app-radio-group>
          </fieldset>
        </div>
      </div>
    </ng-container>
  </div>
</mat-card>
