<div class="my-1">
  <app-page-alert [alert]="emailPhoneRequirement" role="status" aria-live="polite"></app-page-alert>
</div>
<form [formGroup]="formGroup">
  <mat-card>
    <mat-card-title>Lives With 1</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg">
          <app-text-field label="Name (first and last)" formControlName="livesWith1Name"></app-text-field>
        </div>
        <div class="col-lg">
          <app-text-field label="Email" formControlName="livesWith1Email"></app-text-field>
        </div>
        <div class="col-lg">
          <app-autocomplete
            label="Relationship"
            formControlName="livesWith1RelationshipId"
            [options]="relationshipOptions"
          ></app-autocomplete>
        </div>
      </div>
      <div class="row">
        <div class="col-lg">
          <app-masked-input label="Home Phone Number" formControlName="livesWith1HomePhone" mask="(000) 000-0000"></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input label="Work Phone Number" formControlName="livesWith1WorkPhone" mask="(000) 000-0000"></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input label="Cell Phone Number" formControlName="livesWith1CellPhone" mask="(000) 000-0000"></app-masked-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg">
          <mat-error
            *ngIf="
              formGroup.get('livesWith1Name').value !== '' &&
              formGroup.controls.livesWith1BestWayToReachHomePhone.value &&
              !formGroup.controls.livesWith1HomePhone.value
            "
          >
            Home phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error
            *ngIf="
              formGroup.get('livesWith1Name').value !== '' &&
              formGroup.controls.livesWith1BestWayToReachWorkPhone.value &&
              !formGroup.controls.livesWith1WorkPhone.value
            "
          >
            Work phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error
            *ngIf="
              formGroup.get('livesWith1Name').value !== '' &&
              formGroup.controls.livesWith1BestWayToReachCellPhone.value &&
              !formGroup.controls.livesWith1CellPhone.value
            "
          >
            Cell phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error
            *ngIf="
              formGroup.get('livesWith1Name').value !== '' &&
              formGroup.controls.livesWith1BestWayToReachText.value &&
              !formGroup.controls.livesWith1CellPhone.value
            "
          >
            Cell phone number is <strong>required</strong> if texting is the best way to contact
          </mat-error>
          <mat-error
            *ngIf="
              formGroup.get('livesWith1Name').value !== '' &&
              formGroup.controls.livesWith1BestWayToReachEmail.value &&
              !formGroup.controls.livesWith1Email.value
            "
          >
            Email is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="parentForm.hasError('atLeastOnePhoneRequired')">
            At least one phone number or email address is
            <strong>required</strong>
          </mat-error>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="vertical-controll">
            <label class="text-md vertical-label" for="livesWith1BestTimeToContact">Best Way to Contact</label>
            <div>
              <app-checkbox-single
                formControlName="livesWith1BestWayToReachHomePhone"
                label="Home"
                aria-labelledby="Home"
              ></app-checkbox-single>
              <app-checkbox-single
                formControlName="livesWith1BestWayToReachWorkPhone"
                label="Work"
                aria-labelledby="Work"
              ></app-checkbox-single>
              <app-checkbox-single
                formControlName="livesWith1BestWayToReachCellPhone"
                label="Cell"
                aria-labelledby="Cell"
              ></app-checkbox-single>
              <app-checkbox-single
                formControlName="livesWith1BestWayToReachEmail"
                label="Email"
                aria-labelledby="Email"
              ></app-checkbox-single>
              <app-checkbox-single
                formControlName="livesWith1BestWayToReachText"
                label="Texting"
                aria-labelledby="Texting"
              ></app-checkbox-single>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <app-text-field
            label="Best Time to Contact"
            id="livesWith1BestTimeToContact"
            formControlName="livesWith1BestTimeToContact"
          ></app-text-field>
        </div>
        <div class="col-lg-4 align-self-center">
          <mat-checkbox
            [checked]="referralSource === 'LivesWith1'"
            (change)="updateReferralSource($event, 'LivesWith1')"
            aria-labelledby="This is the person completing the referral"
          >
            This is the person completing the referral
          </mat-checkbox>
        </div>
      </div>
      <div class="row mt-0 mb-0" *ngIf="referralSource === 'LivesWith1'">
        <ng-container [formGroup]="parentForm">
          <div class="col-xl-4 col-lg-6">
            <app-autocomplete
              [options]="referralHowHearAboutUsOptions"
              formControlName="referralSourceHowHearAboutUsId"
              label="How did you hear about Early ACCESS?"
            ></app-autocomplete>
          </div>
          <div class="col-md-8" *ngIf="parentForm.get('referralSourceHowHearAboutUsId').value === referralHowHearAboutUsOtherId">
            <div class="container">
              <app-text-field label="Describe" formControlName="referralSourceEarlyAccessOtherText"></app-text-field>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="row mt-0 mb-0 ml-2" *ngIf="referralSource === 'LivesWith1'">
        <div class="row">
          <div class="col-lg-12 mr-5">
            <fieldset>
              <app-radio-group
                label="Is the parent or legal guardian aware that the referral has been
                made to Early ACCESS?"
                [options]="yesNoOptions"
                [formControl]="legalGuardian"
              ></app-radio-group>
            </fieldset>
          </div>
          <div class="row mt-2 mb-2" *ngIf="parentForm.get('legalGuardian').value === false">
            <div class="col-lg">
              <p>
                <strong style="color: red"
                  >Referral sources are responsible for informing the family that a referral has been made. Please contact the
                  family.</strong
                >
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12">
            <fieldset>
              <app-radio-group
                label="Do you have a signed release from the family to exchange information about the child with Early ACCESS?"
                [options]="yesNoOptions"
                [formControl]="exchangeInformation"
              ></app-radio-group>
            </fieldset>
          </div>
        </div>
      </div>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top">
    <mat-card-title>Lives With 2</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg">
          <app-text-field label="Name (first and last)" formControlName="livesWith2Name"></app-text-field>
        </div>
        <div class="col-lg">
          <app-text-field label="Email" formControlName="livesWith2Email"></app-text-field>
        </div>
        <div class="col-lg">
          <app-autocomplete
            label="Relationship"
            formControlName="livesWith2RelationshipId"
            [options]="relationshipOptions"
            [showAsterisk]="formGroup.hasError('livesWith2RelationshipRequired')"
            attr.aria-required="{{ formGroup.hasError('livesWith2RelationshipRequired') ? 'true' : 'false' }}"
          ></app-autocomplete>
          <mat-error *ngIf="formGroup.get('livesWith2RelationshipId').touched && formGroup.hasError('livesWith2RelationshipRequired')">
            Field is <strong>required</strong>
          </mat-error>
        </div>
      </div>
      <div class="row">
        <div class="col-lg">
          <app-masked-input label="Home Phone Number" formControlName="livesWith2HomePhone" mask="(000) 000-0000"></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input label="Work Phone Number" formControlName="livesWith2WorkPhone" mask="(000) 000-0000"></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input label="Cell Phone Number" formControlName="livesWith2CellPhone" mask="(000) 000-0000"></app-masked-input>
        </div>
      </div>
      <div class="row">
        <div class="col-lg">
          <mat-error
            *ngIf="
              formGroup.get('livesWith2Name').value !== '' &&
              formGroup.controls.livesWith2BestWayToReachHomePhone.value &&
              !formGroup.controls.livesWith2HomePhone.value
            "
          >
            Home phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error
            *ngIf="
              formGroup.get('livesWith2Name').value !== '' &&
              formGroup.controls.livesWith2BestWayToReachWorkPhone.value &&
              !formGroup.controls.livesWith2WorkPhone.value
            "
          >
            Work phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error
            *ngIf="
              formGroup.get('livesWith2Name').value !== '' &&
              formGroup.controls.livesWith2BestWayToReachCellPhone.value &&
              !formGroup.controls.livesWith2CellPhone.value
            "
          >
            Cell phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error
            *ngIf="
              formGroup.get('livesWith2Name').value !== '' &&
              formGroup.controls.livesWith2BestWayToReachText.value &&
              !formGroup.controls.livesWith2CellPhone.value
            "
          >
            Cell phone number is <strong>required</strong> if texting is the best way to contact
          </mat-error>
          <mat-error
            *ngIf="
              formGroup.get('livesWith2Name').value !== '' &&
              formGroup.controls.livesWith2BestWayToReachEmail.value &&
              !formGroup.controls.livesWith2Email.value
            "
          >
            Email is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="parentForm.hasError('atLeastOnePhoneRequired')">
            At least one phone number or email address is
            <strong>required</strong>
          </mat-error>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-4">
          <div class="vertical-controll">
            <label class="text-md vertical-label" for="livesWith2BestTimeToContact">Best Way to Contact</label>
            <div>
              <app-checkbox-single
                formControlName="livesWith2BestWayToReachHomePhone"
                label="Home"
                aria-labelledby="Home"
              ></app-checkbox-single>
              <app-checkbox-single
                formControlName="livesWith2BestWayToReachWorkPhone"
                label="Work"
                aria-labelledby="Work"
              ></app-checkbox-single>
              <app-checkbox-single
                formControlName="livesWith2BestWayToReachCellPhone"
                label="Cell"
                aria-labelledby="Cell"
              ></app-checkbox-single>
              <app-checkbox-single
                formControlName="livesWith2BestWayToReachEmail"
                label="Email"
                aria-labelledby="Email"
              ></app-checkbox-single>
              <app-checkbox-single
                formControlName="livesWith2BestWayToReachText"
                label="Texting"
                aria-labelledby="Texting"
              ></app-checkbox-single>
            </div>
          </div>
        </div>
        <div class="col-lg-4">
          <app-text-field
            label="Best Time to Contact"
            id="livesWith2BestTimeToContact"
            formControlName="livesWith2BestTimeToContact"
          ></app-text-field>
        </div>
        <div class="col-lg-4 align-self-center">
          <mat-checkbox
            [checked]="referralSource === 'LivesWith2'"
            (change)="updateReferralSource($event, 'LivesWith2')"
            aria-labelledby="This is the person completing the referral"
          >
            This is the person completing the referral
          </mat-checkbox>
        </div>
      </div>
      <div class="row mt-0 mb-0" *ngIf="referralSource === 'LivesWith2'">
        <ng-container [formGroup]="parentForm">
          <div class="col-xl-4 col-lg-6">
            <app-autocomplete
              [options]="referralHowHearAboutUsOptions"
              formControlName="referralSourceHowHearAboutUsId"
              label="How did you hear about Early ACCESS?"
            ></app-autocomplete>
          </div>
          <div class="col-md-8" *ngIf="parentForm.get('referralSourceHowHearAboutUsId').value === referralHowHearAboutUsOtherId">
            <div class="container">
              <app-text-field label="Describe" formControlName="referralSourceEarlyAccessOtherText"></app-text-field>
            </div>
          </div>
        </ng-container>
      </div>
      <div class="row mt-0 mb-0 ml-2" *ngIf="referralSource === 'LivesWith2'">
        <ng-container [formGroup]="parentForm">
          <div class="row">
            <div class="col-lg">
              <fieldset>
                <app-radio-group
                  [options]="yesNoOptions"
                  label="
                  Is the parent or legal guardian aware that the referral has been
                  made to Early ACCESS?"
                  [formControl]="legalGuardian"
                ></app-radio-group>
              </fieldset>
            </div>
          </div>
          <div class="row mt-2 mb-2" *ngIf="parentForm.get('legalGuardian').value === false">
            <div class="col-lg">
              <p>
                <strong style="color: red"
                  >Referral sources are responsible for informing the family that a referral has been made. Please contact the
                  family.</strong
                >
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-lg">
              <label
                class="vertical-label display-block text-md"
                [ngClass]="{
                  asterisk_input: parentForm.get('exchangeInformation').hasError('required')
                }"
              >
                Do you have a
                <a
                  (click)="onOpenHelp($event, helpSection.Referral, referralSourceInfoHelp.SignedRelease)"
                  class="text-lower text-underline"
                  role="link"
                  tabindex="0"
                  attr.aria-label="{{ referralSourceInfoHelp.SignedRelease }}"
                >
                  {{ referralSourceInfoHelp.SignedRelease }}
                </a>
                from the family to exchange information about the child with Early ACCESS?
              </label>
              <fieldset>
                <app-radio-group [options]="yesNoOptions" [hideAsterisk]="true" [formControl]="exchangeInformation"></app-radio-group>
              </fieldset>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-card>
</form>
