import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MeetingParticipantRead } from 'src/app/learner-management/family-meeting/participants-list/meeting-participants';
import { AgencyNotificationDto, AgencyNotificationUpdateDto } from '../../../users/components/models/user-agency-notification';
import { CaseUserRole } from '../../models/case';
import { OperationResult, OperationResultWithValue } from '../../models/operation-result';
import { PaginatedList } from '../../models/paginated-list';
import { ServiceCoordinator } from '../../models/service-coordinator';
import { AddUserDto, ProviderUser, User } from '../../models/user';

export interface UserSearchParams {
  aeaId?: string;
  firstName?: string;
  lastName?: string;
  schoolDistrictId?: string;
  buildingId?: string;
  professionId?: string;
  filterBy?: CaseUserRole;
  searchFilter?: string;
  includeInactiveRecords?: boolean;
  includeGeneralEducation?: boolean;
  includeFamily?: boolean;
  aeaIds?: Array<string>;
  districtIds?: Array<string>;
  buildingIds?: Array<string>;
  roleIds?: Array<string>;
  aoIds?: Array<string>;
  userIds?: Array<string>;
  hasPhase1ReportClaim?: boolean;
  hasPhase2ReportClaim?: boolean;
  hasDhhReportClaim?: boolean;
  includeStateWideForRoleIds?: boolean;
}

export interface UserSearchLiteParams {
  aeaId?: string;
  schoolDistrictId?: string;
  buildingId?: string;
  filterBy?: CaseUserRole;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  baseUrl = 'api/users/';

  constructor(private http: HttpClient) {}

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.baseUrl);
  }

  getLoginUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${this.baseUrl}login-users`);
  }

  getIvrsLoginUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${this.baseUrl}ivrs-login-users`);
  }

  getInteractiveAccessUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${this.baseUrl}interactive-access-users`);
  }

  getUser(userId: string): Observable<User> {
    return this.http.get<User>(this.baseUrl + userId);
  }

  searchUsers(userSearchParameters: UserSearchParams) {
    let params = new HttpParams();
    params = params.append('aeaId', userSearchParameters.aeaId ?? '');
    params = params.append('firstName', userSearchParameters.firstName ?? '');
    params = params.append('lastName', userSearchParameters.lastName ?? '');
    params = params.append('schoolDistrictId', userSearchParameters.schoolDistrictId ?? '');
    params = params.append('buildingId', userSearchParameters.buildingId ?? '');
    params = params.append('professionId', userSearchParameters.professionId ?? '');
    params = params.append('filterBy', userSearchParameters.filterBy ?? '');
    params = params.append('includeFamily', userSearchParameters.includeFamily ?? true);
    params = params.append('includeInactiveRecords', userSearchParameters.includeInactiveRecords ?? false);
    params = params.append('includeGeneralEducation', userSearchParameters.includeGeneralEducation ?? false);
    return this.http.get<User[]>(this.baseUrl, { params });
  }

  searchUsersPaginated(
    pageNumber: number,
    pageSize: number,
    orderBy: string,
    orderByDescending: boolean,
    userSearchParameters: UserSearchParams
  ) {
    let params = new HttpParams();
    userSearchParameters.aeaIds?.forEach((id) => (params = params.append('aeaIds', id)));
    userSearchParameters.districtIds?.forEach((id) => (params = params.append('districtIds', id)));
    userSearchParameters.buildingIds?.forEach((id) => (params = params.append('buildingIds', id)));
    userSearchParameters.roleIds?.forEach((id) => (params = params.append('roleIds', id)));
    userSearchParameters.aoIds?.forEach((id) => (params = params.append('aoIds', id)));
    userSearchParameters.userIds?.forEach((id) => (params = params.append('userIds', id)));
    params = params.append('includeFamily', userSearchParameters.includeFamily ?? true);
    params = params.append('includeInactiveRecords', userSearchParameters.includeInactiveRecords ?? false);
    params = params.append('searchFilter', userSearchParameters.searchFilter ?? '');
    params = params.append('pageNumber', pageNumber);
    params = params.append('pageSize', pageSize);
    params = params.append('orderBy', orderBy);
    params = params.append('orderByDescending', orderByDescending);
    params = params.append('includeStateWideForRoleIds', userSearchParameters.includeStateWideForRoleIds ?? false);
    params = params.append('includeGeneralEducation', userSearchParameters.includeGeneralEducation ?? false);
    return this.http.get<PaginatedList<User>>(`${this.baseUrl}paginated`, { params });
  }

  searchAdhocReportUsers(userSearchParameters: UserSearchParams) {
    let params = new HttpParams();
    params = params.append('aeaId', userSearchParameters.aeaId ?? '');
    params = params.append('firstName', userSearchParameters.firstName ?? '');
    params = params.append('lastName', userSearchParameters.lastName ?? '');
    params = params.append('schoolDistrictId', userSearchParameters.schoolDistrictId ?? '');
    params = params.append('buildingId', userSearchParameters.buildingId ?? '');
    params = params.append('professionId', userSearchParameters.professionId ?? '');
    params = params.append('filterBy', userSearchParameters.filterBy ?? '');
    params = params.append('includeFamily', userSearchParameters.includeFamily ?? true);
    params = params.append('includeInactiveRecords', userSearchParameters.includeInactiveRecords ?? false);
    params = params.append('hasPhase1ReportClaim', userSearchParameters.hasPhase1ReportClaim ?? false);
    params = params.append('hasPhase2ReportClaim', userSearchParameters.hasPhase2ReportClaim ?? false);
    params = params.append('hasDhhReportClaim', userSearchParameters.hasDhhReportClaim ?? false);
    return this.http.get<User[]>(`${this.baseUrl}adhoc-report-users`, { params });
  }

  searchAdhocReportUsersPaginated(
    pageNumber: number,
    pageSize: number,
    orderBy: string,
    orderByDescending: boolean,
    userSearchParameters: UserSearchParams
  ) {
    let params = new HttpParams();
    userSearchParameters.aeaIds?.forEach((id) => (params = params.append('aeaIds', id)));
    userSearchParameters.districtIds?.forEach((id) => (params = params.append('districtIds', id)));
    userSearchParameters.buildingIds?.forEach((id) => (params = params.append('buildingIds', id)));
    userSearchParameters.roleIds?.forEach((id) => (params = params.append('roleIds', id)));
    userSearchParameters.aoIds?.forEach((id) => (params = params.append('aoIds', id)));
    params = params.append('includeFamily', userSearchParameters.includeFamily ?? true);
    params = params.append('includeInactiveRecords', userSearchParameters.includeInactiveRecords ?? false);
    params = params.append('searchFilter', userSearchParameters.searchFilter ?? '');
    params = params.append('pageNumber', pageNumber);
    params = params.append('pageSize', pageSize);
    params = params.append('orderBy', orderBy);
    params = params.append('orderByDescending', orderByDescending);

    return this.http.get<PaginatedList<User>>(`${this.baseUrl}adhoc-report-users-paginated`, { params });
  }

  searchParticipants(searchTerm: string, learnerId: string) {
    let params = new HttpParams();
    params = params.append('searchTerm', searchTerm ?? '');
    params = params.append('learnerId', learnerId ?? '');

    return this.http.get<MeetingParticipantRead[]>(`${this.baseUrl}participants`, { params });
  }

  getAeaUsers(learnerId: string): Observable<User[]> {
    return this.http.get<User[]>(`${this.baseUrl}aea/${learnerId}`);
  }
  getLeaUsers(learnerId: string): Observable<User[]> {
    return this.http.get<User[]>(`${this.baseUrl}lea/${learnerId}`);
  }

  getProviders(caseId?: string): Observable<ProviderUser[]> {
    return this.http.get<ProviderUser[]>(`${this.baseUrl}providers/${caseId ? caseId : ''}`);
  }

  getProvidersByAea(learnerId: string): Observable<ProviderUser[]> {
    return this.http.get<ProviderUser[]>(`${this.baseUrl}providers/aea/${learnerId}`);
  }

  getServiceActivityProviders(learnerId: string): Observable<ProviderUser[]> {
    return this.http.get<ProviderUser[]>(`${this.baseUrl}service-activity-providers/${learnerId}`);
  }

  getServiceCoordinators(
    pageNumber: number,
    pageSize: number,
    orderBy: string,
    orderByDescending: boolean,
    userIds?: string,
    learnerId?: string,
    searchCriteria?: string,
    districtId?: string
  ) {
    let params = new HttpParams();
    params = params.append('learnerId', learnerId ?? '');
    params = params.append('searchCriteria', searchCriteria ?? '');
    params = params.append('districtId', districtId ?? '');
    params = params.append('userIds', userIds ?? '');
    params = params.append('pageNumber', pageNumber);
    params = params.append('pageSize', pageSize);
    params = params.append('orderBy', orderBy);
    params = params.append('orderByDescending', orderByDescending);

    return this.http.get<PaginatedList<ServiceCoordinator>>('api/users/service-coordinators', { params });
  }

  getAgencyNotification(): Observable<OperationResultWithValue<AgencyNotificationDto>> {
    return this.http.get<OperationResultWithValue<AgencyNotificationDto>>(this.baseUrl + 'agency-notification');
  }

  setAgencyNotification(agencyNotificationUpdate: AgencyNotificationUpdateDto): Observable<OperationResult> {
    return this.http.post<OperationResult>(`${this.baseUrl}agency-notification`, agencyNotificationUpdate);
  }

  getDefaultDmpsCaseUser(): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'default-dmps-case-user');
  }

  getDefaultChscCaseUser(): Observable<User> {
    return this.http.get<User>(this.baseUrl + 'default-chsc-case-user');
  }

  addNewUser(user: AddUserDto): Observable<any> {
    return this.http.post(`${this.baseUrl}addNew`, user);
  }

  saveUser(user: User): Observable<User> {
    return this.http.put<User>(this.baseUrl, user);
  }

  deactivateUser(userId: string) {
    return this.http.put<OperationResult>(`${this.baseUrl}${userId}/deactivate`, null);
  }

  activateUser(userId: string) {
    return this.http.post<OperationResult>(`${this.baseUrl}${userId}/activate`, null);
  }

  sendTestEmail(user: User) {
    return this.http.post<any>(this.baseUrl + 'send-test-notification', user);
  }
}
