<div #accommodationForEdit></div>
<form [formGroup]="formGroup" #formDirective="ngForm">
  <div class="row">
    <div class="col-md-12">
      <app-textarea
        label="Description"
        formControlName="description"
        [isBankableField]="true"
        [bankableFieldId]="bankableFieldIds.iepSsaaAccommodations.description"
        [rows]="4"
      ></app-textarea>
      <app-compare-amendment-output
        *ngIf="amendingAccommodation"
        class="mr-1"
        [amendmentId]="amendmentId"
        [amendmentFinalized]="amendmentIsFinalized"
        [amendmentDate]="lastFinalizedDate(amendingAccommodation?.amendmentId)"
        [priorVersionId]="amendingAccommodation?.priorVersionId"
        [isActive]="amendingAccommodation.isActive"
        [changedVersionText]="formGroup.get('description').value"
        [currentVersionText]="amendingAccommodation?.priorVersion?.description"
        [currentPrefixText]="amendingAccommodation?.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      >
      </app-compare-amendment-output>
    </div>

    <div class="col-md-12" [ngClass]="{ 'col-lg-6': !amendingAccommodation }">
      <app-autocomplete label="Frequency" formControlName="frequencyPeriod" [options]="frequencyPeriodOptions"> </app-autocomplete>
      <app-compare-amendment-output
        *ngIf="amendingAccommodation"
        class="mr-1"
        [amendmentId]="amendmentId"
        [amendmentFinalized]="amendmentIsFinalized"
        [amendmentDate]="lastFinalizedDate(amendingAccommodation?.amendmentId)"
        [priorVersionId]="amendingAccommodation?.priorVersionId"
        [isActive]="amendingAccommodation.isActive"
        [changedVersionText]="getFrequencyPeriodLabel(formGroup.get('frequencyPeriod').value)"
        [currentVersionText]="getFrequencyPeriodLabel(amendingAccommodation?.priorVersion?.frequencyPeriod)"
        [currentPrefixText]="amendingAccommodation?.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      >
      </app-compare-amendment-output>
    </div>

    <div class="col-md-12" [ngClass]="{ 'col-lg-6': !amendingAccommodation }">
      <app-autocomplete-chips formControlName="providers" [options]="providerOptions" label="Providers"> </app-autocomplete-chips>
      <app-compare-amendment-output
        *ngIf="amendingAccommodation"
        class="mr-1"
        [amendmentId]="amendmentId"
        [amendmentFinalized]="amendmentIsFinalized"
        [amendmentDate]="lastFinalizedDate(amendingAccommodation?.amendmentId)"
        [priorVersionId]="amendingAccommodation?.priorVersionId"
        [isActive]="amendingAccommodation.isActive"
        [changedVersionText]="getProviderLabels(formGroup.get('providers').value)"
        [currentVersionText]="getPriorProviderLabels(amendingAccommodation?.priorVersion?.providers)"
        [currentPrefixText]="amendingAccommodation?.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      ></app-compare-amendment-output>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12 col-lg-6 offset-lg-6">
      <app-checkbox-single
        class="mt-2 mb-2"
        formControlName="otherProvider"
        aria-labelledby="Provider is outside of ACHIEVE"
        label="Provider is outside of ACHIEVE"
      >
      </app-checkbox-single>
    </div>
    <div class="col-md-12" *ngIf="formGroup.get('otherProvider').value">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <app-text-field label="Provider Name" formControlName="otherProviderName"></app-text-field>
          <app-compare-amendment-output
            *ngIf="amendingAccommodation"
            [amendmentId]="amendingAccommodation.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate(amendingAccommodation?.amendmentId)"
            [priorVersionId]="amendingAccommodation?.priorVersionId"
            [isActive]="amendingAccommodation?.isActive"
            [changedVersionText]="formGroup.get('otherProviderName').value"
            [currentVersionText]="amendingAccommodation?.priorVersion ? amendingAccommodation?.priorVersion?.otherProviderName : null"
            [currentPrefixText]="amendingAccommodation?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
        <div class="col-md-12 col-lg-6">
          <app-text-field label="Provider Agency" formControlName="otherProviderAgency"></app-text-field>
          <app-compare-amendment-output
            *ngIf="amendingAccommodation"
            [amendmentId]="amendingAccommodation.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate(amendingAccommodation?.amendmentId)"
            [priorVersionId]="amendingAccommodation?.priorVersionId"
            [isActive]="amendingAccommodation?.isActive"
            [changedVersionText]="formGroup.get('otherProviderAgency').value"
            [currentVersionText]="amendingAccommodation?.priorVersion ? amendingAccommodation?.priorVersion?.otherProviderAgency : null"
            [currentPrefixText]="amendingAccommodation?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
        <div class="col-md-12 col-lg-6">
          <app-text-field label="Provider Role" formControlName="otherProviderRole"></app-text-field>
          <app-compare-amendment-output
            *ngIf="amendingAccommodation"
            [amendmentId]="amendingAccommodation.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate(amendingAccommodation?.amendmentId)"
            [priorVersionId]="amendingAccommodation?.priorVersionId"
            [isActive]="amendingAccommodation?.isActive"
            [changedVersionText]="formGroup.get('otherProviderRole').value"
            [currentVersionText]="amendingAccommodation?.priorVersion ? amendingAccommodation?.priorVersion?.otherProviderRole : null"
            [currentPrefixText]="amendingAccommodation?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="iepIncludesTrialPlacement" class="row">
    <div class="col-md-12">
      <app-checkbox-single
        class="display-inline-block"
        formControlName="includesTrialPlacement"
        aria-labelledby=" Include in Trial Placement"
        label=" Include in Trial Placement"
      >
      </app-checkbox-single>
    </div>
  </div>

  <div class="action__row action__row--between mt-0">
    <div>
      <button
        *ngIf="!formGroup.controls.taggedForPwn?.value"
        class="ml-1"
        mat-raised-button
        aria-label="Tag for Prior Written Notice Button"
        (click)="tag(true)"
        tabindex="0"
      >
        Tag for Prior Written Notice
      </button>
      <button
        *ngIf="formGroup.controls.taggedForPwn?.value"
        mat-raised-button
        aria-label="Untag for Prior Written Notice"
        color="primary"
        (click)="tag(false)"
        tabindex="0"
      >
        Untag for Prior Written Notice
      </button>
    </div>

    <div>
      <button
        type="button"
        mat-raised-button
        aria-label="View Summary"
        color="accent"
        *ngIf="!hasAmendment"
        (click)="viewSummary()"
        tabindex="0"
      >
        View Summary
      </button>
      <button
        mat-raised-button
        aria-label="Cancel Button"
        color="warn"
        (click)="onCancel()"
        [disabled]="!accommodationId"
        *ngIf="hasAmendment || hasPriorVersion"
        type="button"
        tabindex="0"
      >
        Cancel
        <mat-icon class="ml-2" aria-label="Cancel Button">close</mat-icon>
      </button>
      <button
        mat-raised-button
        aria-label="Delete"
        color="warn"
        (click)="onDelete()"
        [disabled]="!accommodationId"
        *ngIf="!hasAmendment"
        type="button"
        tabindex="0"
      >
        Delete
        <mat-icon class="ml-2" aria-label="Delete">delete</mat-icon>
      </button>
      <button
        mat-raised-button
        aria-label="Save Button"
        color="primary"
        (click)="saveAndClose()"
        [disabled]="!accommodationId"
        [disableAfterBusy]="!accommodationId"
        [isBusy]="activeCall"
        tabindex="0"
      >
        Save
      </button>
      <button
        mat-raised-button
        aria-label="Complete"
        color="primary"
        [disabled]="formGroup.invalid"
        (click)="submit()"
        [disableAfterBusy]="formGroup.invalid"
        [isBusy]="activeCall"
        tabindex="0"
      >
        Complete
      </button>
    </div>
  </div>
</form>
<ng-container *ngIf="!hasAmendment">
  <hr class="input__divider" />

  <h3 class="mt-0 mb-2">Accommodations List</h3>
  <mat-card class="my-0">
    <app-iep-accommodations-table
      #accommodationlist
      (editAccommodation)="onEdit($event)"
      [iepId]="iepId"
      [amendmentId]="amendmentId"
      [amendments]="amendments"
      [isEditing]="!!this.accommodation"
      [iepIncludesTrialPlacement]="iepIncludesTrialPlacement"
      [trialPlacementTarget]="trialPlacementTarget"
    >
    </app-iep-accommodations-table>
  </mat-card>
</ng-container>
