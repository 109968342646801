<ng-container *ngIf="buttonType === 'Button'">
  <button type="button" mat-raised-button [attr.aria-label]="buttonText" [color]="color" (click)="openModal()" tabindex="0">
    {{ buttonText }}
  </button>
</ng-container>
<ng-container *ngIf="buttonType === 'MenuItem'">
  <button mat-menu-item [attr.aria-label]="buttonText" tabindex="0" (click)="openModal()">
    {{ buttonText }}
  </button>
</ng-container>
