import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user/user.service';
import { LoginModel } from '../../../auth-models';

@Component({
  selector: 'app-family-login-form',
  templateUrl: './family-login-form.component.html',
  styleUrls: ['./family-login-form.component.scss'],
})
export class FamilyLoginFormComponent implements OnInit {
  @Input() loginFailed: boolean;
  @Input() isBusy = false;

  @Output() submitted = new EventEmitter<LoginModel>();
  options: string[];
  filteredLogins: Observable<string[]>;

  loginForm = this.fb.group({
    email: [
      '',
      {
        validators: [Validators.required, Validators.email],
      },
    ],
    password: ['', { validators: [Validators.required] }],
  });

  get email() {
    return this.loginForm.get('email');
  }

  get password() {
    return this.loginForm.get('password');
  }

  constructor(private fb: FormBuilder, private userService: UserService) {}

  ngOnInit() {
    this.userService.getLoginUsers().subscribe((users) => {
      if (users && users.length) {
        this.options = users.map((u) => u.userName).sort();
        this.filteredLogins = this.email.valueChanges.pipe(
          startWith(''),
          map((value) => this._filter(value))
        );
        this.loginForm.get('password').setValue('fake-password');
      }
    });
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter((option) => option.toLowerCase().includes(filterValue));
  }

  onSubmit() {
    this.loginForm.markAllAsTouched();
    this.loginForm.updateValueAndValidity();

    if (this.loginForm.valid) {
      this.submitted.emit(this.loginForm.value);
    }
  }

  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' : this.email.hasError('email') ? 'Not a valid email' : '';
  }
}
