import { AfterViewInit, Component, OnInit } from '@angular/core';
import { PopupProxy } from '../../services/popup-proxy';

@Component({
  selector: 'app-pdf-draft-viewer',
  templateUrl: './pdf-draft-viewer.component.html',
  styleUrls: ['./pdf-draft-viewer.component.scss'],
})
export class PdfDraftViewerComponent implements OnInit, AfterViewInit {
  pdfSrc: ArrayBuffer;

  constructor() {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    window.opener.postMessage('loaded', window.opener.location.href);

    PopupProxy.draftPdf.onmessage = (src: ArrayBuffer) => {
      if (!this.pdfSrc) {
        this.pdfSrc = src;
      }
    };
  }
}
