<main class="learner-management" *ngIf="learnerSummary$ | async as learnerSummary">
  <app-learner-info *ngIf="learnerSummary && !authService.isVrUser" [learner]="learnerSummary"></app-learner-info>
    <section class="card-spacing-top">
      <app-family-contact-info [learner]="learnerSummary"></app-family-contact-info>
    </section>
    <section class="card-spacing-top">
      <app-family-contact-log [showTitle]="true" [learner]="learnerSummary"></app-family-contact-log>
    </section>
</main>

<div class="page-bottom-leeway"></div>
