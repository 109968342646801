<mat-card class="mt-2" *ngIf="learners?.items">
  <div class="table-overflow app-table" #learnersTable>
    <table
      mat-table
      [dataSource]="dataSource"
      matSort
      matSortActive="learnerName"
      matSortDirection="asc"
      (matSortChange)="onSortChange($event)"
      class="mat-elevation-z0"
    >
      <ng-container matColumnDef="selectMatch">
        <th mat-header-cell *matHeaderCellDef class="text-left"></th>
        <td mat-cell *matCellDef="let element" class="text-left">
          <button mat-raised-button aria-label="Select" color="primary" (click)="selectLearner(element)" tabindex="0">Select</button>
        </td>
      </ng-container>

      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Learner Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.fullName }}
          <ng-container *ngIf="element.isDhh">
            <i class="material-icons ml-2" style="color: #007a7c"> radio_button_on </i>
          </ng-container>
          <ng-container *ngIf="element.isDoNotTest">
            <i class="material-icons ml-2" style="color: #9b2242"> error </i>
          </ng-container>
          <ng-container *ngIf="element.isDoNotScreen">
            <i class="material-icons ml-2" style="color: #24327b"> indeterminate_check_box </i>
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="dateOfBirth">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DOB</th>
        <td mat-cell *matCellDef="let element">
          {{ element.dateOfBirth | dobNoTz | dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="gradeDescription">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Grade</th>
        <td mat-cell *matCellDef="let element">
          <app-dhh-learner-grade-view
            [dateOfBirth]="element.dateOfBirth"
            [gradeDescription]="element.gradeDescription"
          ></app-dhh-learner-grade-view>
        </td>
      </ng-container>

      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">
          {{ element.status }}
        </td>
      </ng-container>

      <ng-container matColumnDef="audiologistName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Audiologist Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.audiologistName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="tdhhName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>TDHH Name</th>
        <td mat-cell *matCellDef="let element">
          {{ element.tdhhName }}
        </td>
      </ng-container>

      <ng-container matColumnDef="attendingAea">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Attending AEA</th>
        <td mat-cell *matCellDef="let element">
          {{ element.attendingAea }}
        </td>
      </ng-container>

      <ng-container matColumnDef="attendingDistrict">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Attending District</th>
        <td mat-cell *matCellDef="let element">
          {{ element.attendingDistrict }}
        </td>
      </ng-container>

      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef colspan="9">
          <i>No results...</i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr
        [hidden]="dataSource?.data?.length > 0"
        mat-footer-row
        *matFooterRowDef="dataSource?.data?.length === 0 ? ['noResults'] : []"
        class="example-second-footer-row"
      ></tr>
    </table>
    <p *ngIf="!dataSource.data">No data yet...</p>

    <app-table-paginator
      *ngIf="currentPageInfo.length > 0"
      [length]="currentPageInfo.length"
      [pageSize]="currentPageInfo.pageSize"
      [pageSizeOptions]="pageSizeOptions"
      [pageIndex]="currentPageInfo.pageIndex"
      (page)="onPageChanged($event)"
    ></app-table-paginator>
  </div>
</mat-card>
