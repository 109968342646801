<h1 mat-dialog-title>Choose Download Format</h1>
<mat-dialog-content class="pt-3 pb-3">
  <button mat-raised-button *ngFor="let format of formats" [mat-dialog-close]="format" color="primary" class="mr-2">
    {{ format }}
  </button>
</mat-dialog-content>
<mat-dialog-actions>
  <div class="d-flex justify-content-end">
    <button mat-raised-button color="primary" mat-dialog-close>Cancel</button>
  </div>
</mat-dialog-actions>
