import { Help, HelpSection } from '../help';
import { IepGoalHelp, IepPlaafpHelp, IepServiceHelp } from '../models/iep.help';

export function getIepDictionary(): Help[] {
  return [].concat(getIepGoalDictionary(), getIepServiceDictionary(), getIepPlaafpDictionary());
}

export function getIepGoalDictionary(): Help[] {
  return [
    {
      title: HelpSection.IepGoal,
      dictionary: [
        {
          label: IepGoalHelp.Domain,
          descriptor:
            'Include relevant evaluation information from the initial or most recent evaluation, district-wide assessments, and current performance in comparison to general education peers and standards and the functional expectations of the environments where the individual’s skills will be performed. Relevant information is directly related to the goal area and recent enough to merit consideration in developing this particular goal.  3 Links standards.',
        },
        {
          label: IepGoalHelp.Nickname,
          descriptor: 'This goal will be identified using this nickname on the multi-learner entry page.',
        },
        {
          label: IepGoalHelp.Baseline,
          descriptor:
            'Describe the baseline including the unit of measurement. The baseline must be observable, measurable, and specific. It must be a numeric value which will be used as the starting point on the graph and for progress monitoring purposes. Determine the targeted value based on the goal statement. The team may enter a baseline number or click the calculate baseline button to enter multiple data points to have the system autocalculate the baseline.',
        },
        {
          label: IepGoalHelp.TargetValue,
          descriptor:
            'The target value must align with the value stated in the goal statement and the unit of measurement will autofill to match the baseline.',
        },
        {
          label: IepGoalHelp.CurrentLevel,
          descriptor:
            'Include relevant evaluation information from the initial or most recent evaluation, district-wide assessments, and current performance in comparison to general education peers and standards and the functional expectations of the environments where the individual’s skills will be performed. Relevant information is directly related to the goal area and recent enough to merit consideration in developing this particular goal.',
        },
        {
          label: IepGoalHelp.UnitMeasurement,
          descriptor:
            'Describes what will be measured in order to determine progress towards the goal. The unit of measurement should align to the goal statement’s criterion. Examples include: words per minute, times per day, prompting level, % accurate, points, etc.',
        },
        {
          label: IepGoalHelp.Objectives,
          descriptor:
            'Are the skills the learner needs to acquire or be able to perform in order to achieve the goal (may also be referred to as short-term objectives). Objectives could include a task analysis of smaller tasks or skills that lead to mastery of the goal, developmental skills needed to achieve the goal, or a breakdown of the goal into discrete skill components. Example: Tell time to the hour, tell time to the half hour, tell time to the quarter hour, tell time to the minute.',
        },
        {
          label: IepGoalHelp.Milestones,
          descriptor:
            'Are measurable indicators of learner progress towards the goal (may also be referred to as major milestones or benchmarks). Milestones are the measurable, intermediate steps between the baseline value and the target value across various points of time. The skill/behavior of a milestone is the same skill/behavior identified in the goal. Example: 60% of opportunities by October 1, 70% of opportunities by January 1, 80% of opportunities by June 1.',
        },
        {
          label: IepGoalHelp.Scales,
          descriptor:
            'Is a measurement tool that assigns a numerical value to a statement that clearly describes the skill or behavior that is being rated at that level. A scale can help describe qualitative differences in how a skill is achieved and provide a numeric value for progress-monitoring.',
        },
        {
          label: IepGoalHelp.SecondMeasurement,
          descriptor:
            'Can be added to the goal, allowing for two dimensions of behavior to be measured for one goal.  Example: Frequency (words per minute) and Accuracy (% accurate)',
        },
        {
          label: IepGoalHelp.CombinedObjectives,
          descriptor:
            'A goal with combined objectives is used when the overall goal will be measured as a combined score of all the objectives together (similar to a rubric). In order to use this type of goal, one scale is used as the measure and the values entered for each objective will be added together to create one “score” for the overall goal. All objectives must be progress-monitored at all times and the unit of measure (scale) must be the same for all objectives.',
        },
      ],
    },
  ];
}

export function getIepServiceDictionary(): Help[] {
  return [
    {
      title: HelpSection.IepService,
      dictionary: [
        {
          label: IepServiceHelp.Services,
          descriptor:
            'Services imply a regular, purposeful, ongoing set of actions delivered to or on behalf of a student over time. The systematic nature of a described service is reflected in the number of minutes and frequency, the setting where services are provided and the persons responsible.',
        },
        {
          label: IepServiceHelp.ActivitySupport,
          descriptor:
            'Activities and supports are events, tasks or things provided to or on behalf of an eligible individual in order for the individual to take advantage of, or respond to, educational programs and opportunities. Activities and supports are less regular or systematic than services and do not require an ongoing designation of minutes in settings or monitoring of progress towards goal attainment.',
        },
        {
          label: IepServiceHelp.RegularChildhood,
          descriptor:
            'Regular Early Childhood refers to a session or class that enrolls less than or equal to 50% of children on an IEP for instructional, support, and/or related special education services. Regular Early Childhood programs include: Statewide Voluntary Preschool (District operated), Statewide Voluntary Preschool (Community operated), Head Start, Shared Visions Preschool, Preschool - Other (District operated), Preschool - Other (Community operated), and Child Care Center.',
        },
        {
          label: IepServiceHelp.Accommodation,
          descriptor:
            'Supports provided to help a learner access settings, opportunities, and the general curriculum and validly demonstrate learning.',
        },
        {
          label: IepServiceHelp.Esy,
          descriptor:
            'Extended School Year Services (ESY Services) are special education and related services which may be provided to eligible individuals beyond the normal school year in accordance with the IEP and at no cost to the parents of the child. ESY Services must be made available if it is necessary in order for a learner to receive a FAPE. ESY Services must be provided in the LRE that is appropriate and meet the individually identified needs and match the purpose identified by the IEP team.',
        },
        {
          label: IepServiceHelp.StatewideDistrictAssessment,
          descriptor:
            "Include any and all assessments given as a part of the state accountability system and administered to all learners. Students with disabilities may participate without accommodations, with accommodations, or through the state alternate assessment. Indicate the IEP team's decision regarding how the learner will participate.",
        },
        {
          label: IepServiceHelp.Accommodations,
          descriptor:
            'When considering the reasonable accommodations a student requires, several factors need to be addressed.  These factors are:  The use of accommodations in a similar fashion in the classroom and their demonstrated benefit, the relationship of accommodations to an identified special education need, the consideration of whether the accommodation will provide an accurate picture of the knowledge of the individual.  Accommodations can not negate the intent of the assessment.',
        },
      ],
    },
  ];
}

export function getIepPlaafpDictionary(): Help[] {
  return [
    {
      title: HelpSection.IepPlaafp,
      dictionary: [
        {
          label: IepPlaafpHelp.Tag,
          descriptor:
            'Icons will allow the user to tag the strength or skill to connect to other parts of the IEP such as, goals and services.',
        },
        {
          label: IepPlaafpHelp.StrengthSkill,
          descriptor:
            'Strengths and skills are general things the student is good at (singing, telling jokes, math computation, sports, band, following directions, etc.). For preschool children this may include strengths and skills associated with the developmental skills addressed in the Iowa Early Learning Standards such as sharing, turn-taking, dressing, doing puzzles, etc.',
        },
        {
          label: IepPlaafpHelp.PreferenceInterest,
          descriptor:
            "Preferences are things, events, or people that the learner chooses over others. They could pertain to, but not limited to the needs of the learner in the school setting (e.g. prefers working with a partner, working independently, utilizing hands-on activities, etc.). For preschool learners this may include their choice of participation in centers or activities in the home (e.g. cooking, dramatic play, blocks, art, etc.). Interests are things, events, or people that evoke the learner's curiosity (sports, rocks, peer interaction, computers, music, etc.). For preschool learners this may include interests associated with their daily activities (e.g. vehicles, animals, dancing, community helpers, etc.)",
        },
        {
          label: IepPlaafpHelp.NonAcademicAct,
          descriptor: 'Includes activities such as recess, lunch, and school assemblies.',
        },
        {
          label: IepPlaafpHelp.ExtracurricularAct,
          descriptor: 'Includes activities such as sports, clubs, band, etc.',
        },
        {
          label: IepPlaafpHelp.OtherInfo,
          descriptor:
            'Document information that the IEP team believes is necessary for an understanding of the learner’s current status and needs or that may require future action through the IEP.',
        },
        {
          label: IepPlaafpHelp.InvolvementProgress,
          descriptor:
            "Provide specific and individualized statements regarding the impact of the learner's disability both in the classroom and community. For preschool learners, also consider home environments. These statements will provide support for the services, supports, accommodations, and modifications throughout the IEP.",
        },
        {
          label: IepPlaafpHelp.Diploma,
          descriptor:
            "When a learner has met their local school district’s graduation requirements, which at a minimum, must include four years of English, three years of math, three years of social studies, and three years of science (“4-3-3-3”), the completion of district PE expectations, AND any other requirements established by the IEP team (if permitted by district policy), the learner must be exited for special education services.\n\nNote: Eligible individuals graduating prior to in the class of 2022 may graduate per other means such as IEP goal completions as determined by the learner's IEP team.",
        },
        {
          label: IepPlaafpHelp.Certificate,
          descriptor:
            'When a learner is unable to meet the academic requirements for a diploma, the district may issue a Certificate of Achievement or Completion of Attendance. The IEP team should inform the learner/family such an award does not end the student’s right to receive a free appropriate public education (FAPE). Therefore, the student could continue to receive special education services rather than be exited./',
        },
        {
          label: IepPlaafpHelp.AgeMajority,
          descriptor:
            'In Iowa, a student reaches the age of majority when: The student turns 18 years old; The student gets married; and/or A student under 18 years old is tried, convicted, and sentenced as an adult and is confined in an adult correctional facility. Note: The student’s rights to make educational decisions transfer to him/her during the period of incarceration.',
        },
        {
          label: IepPlaafpHelp.TransferRights,
          descriptor:
            'When a learner reaches the age of majority, their educational rights are transferred to them. In Iowa, the age of majority is when a student obtains the rights of any Iowa citizen and is legally responsible for his or her own decisions, including educational decisions. ',
        },
        {
          label: IepPlaafpHelp.NotTransfer,
          descriptor:
            'In special cases (e.g., the establishment of a guardianship or another individual has been appointed to represent the educational interests of an eligible individual), educational rights do not transfer to the student. If rights have not transferred to the student, the LEA/AEA should obtain a copy of the legal documentation regarding who represents the educational interests of the eligible student, and the EP should reflect the reason why the rights did not transfer to the student.',
        },
      ],
    },
  ];
}
