<form [formGroup]="formGroup">
  <mat-card class="mb-3">
    <mat-card-title class="mb-3">Supporting Documentation</mat-card-title>
    <div class="container container--not-centered container--form">
      <div>
        <strong> Documentation uploads are optional. Examples of documentation that may be helpful include the following: </strong>
      </div>
      <ul>
        <li
          *ngIf="
            referralReasonInfo.get('referralReasonCognitive')?.value === true ||
            referralReasonInfo.get('referralReasonMotor')?.value === true ||
            referralReasonInfo.get('referralReasonCommunication')?.value === true ||
            referralReasonInfo.get('referralReasonAdaptive')?.value === true ||
            referralReasonInfo.get('referralReasonSocialEmotional')?.value === true ||
            referralReasonInfo.get('referralReasonOther')?.value === true ||
            referralReasonInfo.get('referralReasonDiagnosedCondition')?.value === true
          "
        >
          Medical Records or Documentation of Diagnosis
        </li>
        <li *ngIf="referralReasonInfo.get('referralReasonScreeningToolUsed')?.value === unknownYesNo.Yes">Screening Tool Documentation</li>
        <li
          *ngIf="
            referralReasonInfo.get('referralReasonBornLessThan32Week')?.value === true ||
            referralReasonInfo.get('referralReasonBirthWeightLessThan3lb')?.value === true
          "
        >
          Birth Record
        </li>
        <li>Consent to Release and Exchange Information</li>
      </ul>
    </div>
  </mat-card>
  <mat-card class="mb-3">
    <mat-card-title class="mb-3">Documentation Uploads</mat-card-title>
    <div class="container container--not-centered container--form">
      <app-documentation-upload
        [documents]="documents"
        title="Upload"
        [baseUrl]="isAuthenticated ? 'api/early-access-referrals' : null"
        [activeCall]="activeCall"
        (upload)="onUpload($event)"
        (deleteDocument)="onDeleteDocument($event)"
        [learnersNameOverride]="learnersName"
      ></app-documentation-upload>
    </div>
    <div class="mt-4" *ngIf="parentForm.get('referralSource').value !== 'Parent1' && parentForm.get('referralSource').value !== 'Parent2'">
      <strong> Please Note: Early ACCESS cannot share information until a signed permission from the family is received. </strong>
    </div>
  </mat-card>
</form>
