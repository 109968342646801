<app-page-header
  [pageTitle]="pageTitle"
  [pageTitleColor]="pageTitleColor"
  backgroundColor="yellow"
  [helpLinks]="true"
  [condensed]="true"
  [shouldShowDhhBanner]="learner?.isDhh"
>
  <ng-container pageActions>
    <app-create-output-button
      *ngIf="canPushToPortal"
      [output]="pdfOutputs.IepOutput"
      [apiId]="iepId"
      [learnerId]="learnerId"
      [isDraft]="true"
      [pushDraftToPortal]="true"
      [hasOpenModification]="hasOpenAmendment"
      buttonText="Push to Portal"
      buttonColor="primary"
      class="mr-2"
      buttonType="mat-flat-button"
    >
    </app-create-output-button>
    <app-create-output-button
      [removeIfFeatureOff]="'outputIepEnabled'"
      [output]="pdfOutputs.IepOutput"
      [apiId]="iepId"
      [learnerId]="learnerId"
      [documentId]="!hasOpenAmendment ? getLastIepDocumentId(iep) : null"
      [hasOpenModification]="hasOpenAmendment"
      buttonText="IEP Report"
      buttonColor="primary"
      class="mr-2"
      buttonType="mat-flat-button"
    ></app-create-output-button>
    <button mat-flat-button aria-label="Data History" (click)="onDataHistoryNavigate()" class="mr-3" color="primary" tabindex="0">
      Data History
    </button>
    <button
      mat-flat-button
      aria-label="View CLP"
      routerLink="../clp"
      [queryParams]="getClpPageQueryParams()"
      color="primary"
      class="mat-elevation-z0 mr-3"
      tabindex="0"
    >
      View CLP
    </button>
  </ng-container>
</app-page-header>

<!-- <app-iep-learner-info-header [learnerSummary]="learner"></app-iep-learner-info-header> -->
<app-dynamic-learner-header [learnerId]="learnerId"></app-dynamic-learner-header>

<div
  class="display-flex align-items-center flex-wrap mb-2 card-spacing-top"
  [ngClass]="{
    'justify-content-between': hasOpenAmendment,
    'justify-content-end': !hasOpenAmendment
  }"
>
  <div *ngIf="hasOpenAmendment" class="flex-1 mb-0">
    <!-- TODO: Add in logic to disable changing trial placement if placement records are not cancelled-->
    <form [formGroup]="formGroup">
      <app-checkbox-single
        class="display-inline-block"
        formControlName="includesTrialPlacement"
        aria-labelledby="Include in Trial Placement"
        label="Include in Trial Placement"
      >
      </app-checkbox-single>
      <ng-container *ngIf="formGroup.get('includesTrialPlacement').value === true">
        <app-date-picker
          class="ml-3 display-inline-block"
          controlName="trialPlacementDate"
          label="Trial Placement End Date"
          [max]="maxTrialPlacementDate"
          [min]="tenDaysPrior"
          conditionallyRequired="includesTrialPlacement"
        ></app-date-picker>
        <fieldset>
          <app-radio-group
            class="ml-3 display-inline-block"
            [options]="trialPlacementOptions"
            formControlName="trialPlacementTarget"
            label="Trial placement for:"
            conditionallyRequired="includesTrialPlacement"
          ></app-radio-group>
        </fieldset>
        <button mat-raised-button aria-label="End Trial Placement" (click)="onEndTrialPlacement()" class="mr-2" color="accent" tabindex="0">
          End Trial Placement
        </button>
      </ng-container>
    </form>
  </div>
  <button
    *appAuthForRoles="{ permission: [permissions.CreateEditProgressMonitoring], caseId: caseId }"
    mat-raised-button
    aria-label="Enter/View Progress"
    color="primary"
    (click)="enterProgress()"
    class="mr-2"
    tabindex="0"
  >
    Enter/View Progress
  </button>
  <button
    mat-raised-button
    attr.aria-label="{{ isAccordionsExpanded ? 'Collapse All' : 'Expand All' }}"
    color="accent"
    (click)="toggleAccordion()"
    class="mr-2"
    tabindex="0"
  >
    {{ isAccordionsExpanded ? 'Collapse All' : 'Expand All' }}
    <mat-icon class="ml-2" attr.aria-label="{{ isAccordionsExpanded ? 'expand less' : 'expand more' }}">
      {{ isAccordionsExpanded ? 'expand_less' : 'expand_more' }}
    </mat-icon>
  </button>
</div>

<mat-accordion *ngIf="dataLoaded" class="accordion accordion--short" multi>
  <mat-expansion-panel class="mat-elevation-z0" #plaafp>
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">PLAAFP</h3></mat-panel-title>
    </mat-expansion-panel-header>

    <app-iep-details-plaafp [amendments]="amendments" [amending]="amendment?.amendingPlaafp"> </app-iep-details-plaafp>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0" #goals>
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Goals</h3></mat-panel-title>
    </mat-expansion-panel-header>

    <app-iep-details-goals [learnerId]="learnerId" [amendments]="amendments"> </app-iep-details-goals>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0" #services>
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Services</h3></mat-panel-title>
    </mat-expansion-panel-header>

    <app-iep-details-services
      [amendments]="amendments"
      [isPK]="learner.isPK"
      [isPKToK]="caseSummary.activeIepIsPKToK"
      [iepIncludesTrialPlacement]="doesIncludeTrialPlacement"
      [trialPlacementTarget]="trialPlacementTarget"
      (trialPlacementChange)="trialPlacementChange()"
    ></app-iep-details-services>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0" #accommodations>
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Accommodations</h3></mat-panel-title>
    </mat-expansion-panel-header>

    <app-iep-details-accommodations
      [amendments]="amendments"
      [learnerId]="learner.id"
      [iepIncludesTrialPlacement]="doesIncludeTrialPlacement"
      [trialPlacementTarget]="trialPlacementTarget"
      (trialPlacementChange)="trialPlacementChange()"
    ></app-iep-details-accommodations>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0" #activitiesSupports>
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Activities and Supports</h3></mat-panel-title>
    </mat-expansion-panel-header>

    <app-iep-details-activities-supports
      [amendments]="amendments"
      [iepIncludesTrialPlacement]="doesIncludeTrialPlacement"
      [trialPlacementTarget]="trialPlacementTarget"
      (trialPlacementChange)="trialPlacementChange()"
    >
    </app-iep-details-activities-supports>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0" #extendedSchoolYear>
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Extended School Year</h3></mat-panel-title>
    </mat-expansion-panel-header>

    <app-iep-ssaa-extended-school
      [learner]="learner"
      [isAmendment]="true"
      [amendmentId]="amendment?.id"
      [amendments]="amendments"
      [amendingESY]="amendment?.amendingESY"
      [iepMeetings]="iepMeetings"
      [isDetailsView]="true"
      [isDeleting]="isDeleting"
    ></app-iep-ssaa-extended-school>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0" #physicalEducation>
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Physical Education</h3></mat-panel-title>
    </mat-expansion-panel-header>

    <app-iep-ssaa-physical-education
      [isAmendment]="true"
      [amendmentId]="amendment?.id"
      [amendments]="amendments"
      [amendingPhysicalEducation]="amendment?.amendingPhysicalEducation"
      [isDetailsView]="true"
      [isDeleting]="isDeleting"
    ></app-iep-ssaa-physical-education>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0" #assessment>
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Assessment</h3></mat-panel-title>
    </mat-expansion-panel-header>

    <app-iep-ssaa-assessment
      [learner]="learner"
      [isAmendment]="true"
      [amendmentId]="amendment?.id"
      [amendments]="amendments"
      [amendingAssessment]="amendment?.amendingAssessment"
      [isDetailsView]="true"
      [isDeleting]="isDeleting"
    ></app-iep-ssaa-assessment>
  </mat-expansion-panel>

  <mat-expansion-panel class="mat-elevation-z0" #leastRestrictiveEnv>
    <mat-expansion-panel-header>
      <mat-panel-title><h3 class="my-0 text-normal">Least Restrictive Environment</h3></mat-panel-title>
    </mat-expansion-panel-header>

    <app-iep-ssaa-least-restrictive-env
      [caseSummary]="caseSummary"
      [learner]="learner"
      [previousIepId]="caseSummary?.previousIepId"
      [isAmendment]="true"
      [amendmentId]="amendment?.id"
      [amendments]="amendments"
      [amendingLRE]="amendment?.amendingLRE"
      [isDetailsView]="true"
      [iepMeetings]="iepMeetings"
      [isDeleting]="isDeleting"
    >
    </app-iep-ssaa-least-restrictive-env>
  </mat-expansion-panel>

  <mat-expansion-panel *ngIf="hasOpenAmendment" class="mat-elevation-z0">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <div class="display-flex justify-content-between align-items-center w-100">
          <h3 class="my-0 text-normal">Incomplete Data Report</h3>

          <div class="display-flex align-items-center">
            <span class="text-md position-fix-notice" [ngClass]="{ 'text-danger': incompleteItems?.length > 0 }">{{
              incompleteItemsAmount
            }}</span>
            <button
              mat-icon-button
              aria-label="Refresh Icon"
              color="primary"
              (click)="refreshIncompleteItems($event)"
              class="ml-2"
              matTooltip="Refresh"
              tabindex="0"
            >
              <mat-icon aria-label="Refresh Icon"> refresh </mat-icon>
            </button>
          </div>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <app-iep-completion
      [incompleteItems]="incompleteItems"
      [caseSummary]="caseSummary"
      (refreshData)="refreshData()"
      (gotoPanel)="openPanel($event)"
      [iepId]="iepId"
      [amendmentId]="amendment?.id"
      (gotoProceduralSafeguards)="goToLMDocs()"
    ></app-iep-completion>
  </mat-expansion-panel>
</mat-accordion>
<div class="action__row">
  <ng-container *appAuthForRoles="{ permission: [permissions.CancelIEPAmendment], caseId: caseSummary?.id }">
    <button
      mat-raised-button
      color="primary"
      aria-label="Cancel Amendment"
      title="Cancel Amendment"
      type="button"
      (click)="onDelete()"
      *ngIf="hasOpenAmendment"
      [isBusy]="isSaving"
      tabindex="0"
    >
      Cancel Amendment
    </button>
  </ng-container>
  <button
    *ngIf="hasOpenAmendment && canFinalizeIep"
    mat-flat-button
    aria-label="Finalize IEP Amendment"
    color="primary"
    [disabled]="incompleteItems?.length > 0"
    (click)="finalizeIepAmendment()"
    [isBusy]="isSaving"
    tabindex="0"
  >
    Finalize IEP Amendment
  </button>
</div>
<app-iep-additional-info></app-iep-additional-info>
<app-iep-weight-summary
  *ngIf="caseSummary"
  [caseSummary]="caseSummary"
  [amendmentId]="mostRecentAmendmentId"
  [canReviewSpecialCircumstances]="canReviewSpecialCircumstances"
></app-iep-weight-summary>
<div class="page-bottom-leeway"></div>
