<header class="card-spacing-bottom">
  <section>
    <app-page-header
      [pageTitle]="pageTitle"
      [backgroundColor]="'yellow'"
      [helpLinks]="true"
      [condensed]="true"
      [shouldShowDhhBanner]="learnerSummary?.isDhh"
      (helpToggled)="helpToggled($event)"
      attr.aria-label="{{ pageTitle }}"
      aria-live="polite"
      aria-level="1"
    >
      <!-- <ng-container *ngIf="showCloseButton" pageActions>
        <span class="mr-2">
          <app-close-case-button [caseId]="caseId"> </app-close-case-button>
        </span>
      </ng-container> -->
      <ng-container pageActions>
        <button
          *ngIf="hasWorkableCase && IsAbleForScheduleMeeting"
          mat-flat-button
          aria-label="Open Scheduler"
          (click)="onScheduleMeeting()"
          color="primary"
          class="mr-2"
          tabindex="0"
        >
          Open Scheduler
        </button>
        <button
          mat-raised-button
          aria-label="Invite Learner to Portal"
          class="mr-2"
          color="primary"
          (click)="onInviteLearnerToPortal()"
          *ngIf="isPortalUser && canInviteLearnerToPortal"
          tabindex="0"
        >
          Invite Learner to Portal
          <i class="fas fa-share-alt ml-2" aria-labelledby="Invite Learner to Portal"></i>
        </button>
        <button mat-flat-button aria-label="Data History" (click)="onViewDataHistory()" color="primary" class="mr-2" tabindex="0">
          Data History
        </button>
      </ng-container>
    </app-page-header>
  </section>

  <app-dynamic-learner-header *ngIf="learnerSummary$ | async"></app-dynamic-learner-header>

  <app-learner-management-nav *ngIf="learnerSummary$ | async" [learner]="learnerSummary$ | async"></app-learner-management-nav>
</header>
