import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { Aea } from 'src/app/shared/models/aea';
import { FileDocument } from 'src/app/shared/models/file-document';
import { WeightUnits } from 'src/app/shared/models/weight-units';
import { NAYesNo, UnknownYesNo } from 'src/app/shared/models/yes-no';
import { ReferralService } from '../early-access-referral.service';
import { Referral } from '../referral';

@Component({
  selector: 'app-read-only',
  templateUrl: './read-only.component.html',
  styleUrls: ['./read-only.component.scss'],
})
export class ReadOnlyComponent implements OnInit {
  aea: Aea;
  displayAgency: boolean;
  referral: Referral;
  referralId: string;
  weightUnits = WeightUnits;
  yesNo = NAYesNo;
  unknownYesNo = UnknownYesNo;
  documents: FileDocument[];
  shortDateFormat = shortDateFormat;
  screeningTools: string;
  constructor(private readonly referralService: ReferralService, private route: ActivatedRoute) {}

  ngOnInit() {
    this.referralId = this.route.snapshot.paramMap.get('referralId');
    this.getReferralInformation(this.referralId);
  }

  getReferralInformation(id: string) {
    this.referralService.getReferral(id).subscribe((res) => {
      this.referral = res;
      this.documents = res.documentationInfo.referralDocuments;
      this.screeningTools = this.referral.referralReasonInfo.screeningTools.map((t) => t.name).join(', ');
    });
  }

  getBestWayToReach(person: string) {
    let bestWayToReach = '';
    if (person) {
      const personProperty = person.charAt(0) === 'l' ? 'familyInfo' : 'parentInfo';

      if (this.referral[personProperty][person + 'BestWayToReachCellPhone']) {
        bestWayToReach += 'Cell';
      }
      if (this.referral[personProperty][person + 'BestWayToReachHomePhone']) {
        bestWayToReach === '' ? (bestWayToReach += 'Home') : (bestWayToReach += ', Home');
      }
      if (this.referral[personProperty][person + 'BestWayToReachWorkPhone']) {
        bestWayToReach === '' ? (bestWayToReach += 'Work') : (bestWayToReach += ', Work');
      }
      if (this.referral[personProperty][person + 'BestWayToReachEmail']) {
        bestWayToReach === '' ? (bestWayToReach += 'Email') : (bestWayToReach += ', Email');
      }
      if (this.referral[personProperty][person + 'BestWayToReachText']) {
        bestWayToReach === '' ? (bestWayToReach += 'Text') : (bestWayToReach += ', Text');
      }
    }

    return bestWayToReach;
  }

  isADevelopmentalDelay() {
    return (
      this.referral.referralReasonInfo.referralReasonCognitive ||
      this.referral.referralReasonInfo.referralReasonMotor ||
      this.referral.referralReasonInfo.referralReasonCommunication ||
      this.referral.referralReasonInfo.referralReasonAdaptive ||
      this.referral.referralReasonInfo.referralReasonSocialEmotional ||
      this.referral.referralReasonInfo.referralReasonOther
    );
  }

  developmentDelayList() {
    let delList = '';
    if (this.referral.referralReasonInfo.referralReasonCognitive) {
      delList += 'Cognitive';
    }
    if (this.referral.referralReasonInfo.referralReasonMotor) {
      delList.length === 0 ? (delList += 'Motor') : (delList += ', Motor');
    }
    if (this.referral.referralReasonInfo.referralReasonCommunication) {
      delList.length === 0 ? (delList += 'Communication') : (delList += ', Communication');
    }
    if (this.referral.referralReasonInfo.referralReasonAdaptive) {
      delList.length === 0 ? (delList += 'Adaptive / Self-Help') : (delList += ', Adaptive / Self-Help');
    }
    if (this.referral.referralReasonInfo.referralReasonSocialEmotional) {
      delList.length === 0 ? (delList += 'Social-Emotional') : (delList += ', Social-Emotional');
    }
    if (this.referral.referralReasonInfo.referralReasonOther) {
      delList.length === 0 ? (delList += 'Other') : (delList += ', Other');
    }
    return delList;
  }

  getBirthWeight() {
    let birthWeight = '';
    switch (this.referral.referralReasonInfo.referralReasonBirthWeightUnits) {
      case this.weightUnits.lbOz: {
        birthWeight += this.checkForZeroWeight(this.referral.referralReasonInfo.referralReasonBirthInLb) + 'lb ';
        birthWeight += this.checkForZeroWeight(this.referral.referralReasonInfo.referralReasonBirthInOz) + 'oz ';
        break;
      }
      case this.weightUnits.g: {
        birthWeight += this.checkForZeroWeight(this.referral.referralReasonInfo.referralReasonBirthInGrams) + 'g';
        break;
      }
      case this.weightUnits.kg: {
        birthWeight += this.checkForZeroWeight(this.referral.referralReasonInfo.referralReasonBirthInKilograms) + 'kg';
        break;
      }
    }
    return birthWeight;
  }

  checkForZeroWeight(weight: string) {
    if (weight === null) {
      return '0';
    }
    return weight;
  }

  returnYesOrNo(value: boolean) {
    if (value) {
      return this.yesNo.Yes;
    }
    return this.yesNo.No;
  }

  onPrint() {
    window.print();
  }
}
