<mat-card class="filters-card" *ngIf="isReady">
  <form [formGroup]="formGroup" (keyup.enter)="onSearch()">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-3 no-form-field-padding">
          <app-autocomplete
            label="District"
            formControlName="districtId"
            [options]="districtOptions"
            [showAsterisk]="formGroup.get('districtId').hasError('required')"
            attr.aria-required="{{ formGroup.get('districtId').hasError('required') ? 'true' : 'false' }}"
          ></app-autocomplete>
        </div>
        <div class="col-lg-3 no-form-field-padding">
          <app-autocomplete
            label="Building"
            formControlName="buildingId"
            [options]="buildingOptions"
            [showAsterisk]="formGroup.get('buildingId').hasError('required')"
            attr.aria-required="{{ formGroup.get('buildingId').hasError('required') ? 'true' : 'false' }}"
          ></app-autocomplete>
        </div>
        <div class="col-lg-3 no-form-field-padding">
          <app-autocomplete
            label="Teacher"
            formControlName="teacherId"
            [disabledState]="!formGroup.get('districtId')?.value"
            [options]="teacherOptions"
          ></app-autocomplete>
        </div>
        <div class="col-lg-3 no-form-field-padding">
          <app-autocomplete
            label="Grade"
            formControlName="grade"
            [disabledState]="disabledField()"
            [options]="gradeOptions"
          ></app-autocomplete>
        </div>
        <div class="col-lg-4 no-form-field-padding" *ngIf="filterType === 'recheck'">
          <app-date-picker-v2 label="Start Date" formControlName="startDate" [min]="schoolYearDate" [max]="today"></app-date-picker-v2>
        </div>
        <div class="col-lg-4 no-form-field-padding" *ngIf="filterType === 'recheck'">
          <app-date-picker-v2 label="End Date" formControlName="endDate" [min]="minEndDate" [max]="today"></app-date-picker-v2>
        </div>
        <div class="col-lg-12 mt-1 no-form-field-padding">
          <div class="display-flex align-items-center gap-1">
            <app-radio-group
              formControlName="searchType"
              [defaultValue]="'All'"
              [options]="viewOptions"
              [useUndo]="false"
            ></app-radio-group>
          </div>
        </div>
      </div>
      <div class="row mt-1" *ngIf="formGroup.get('searchType')?.value === 'OnlyOne'">
        <div class="col-lg-4 no-form-field-padding">
          <app-text-field
            label="Learner Last Name"
            formControlName="learnerLastName"
            [showAsterisk]="formGroup.hasError('searchInputRequired')"
            attr.aria-required="{{ formGroup.hasError('searchInputRequired') ? 'true' : 'false' }}"
          ></app-text-field>
        </div>
        <div class="col-lg-4 no-form-field-padding">
          <app-text-field
            label="Learner First Name"
            formControlName="learnerFirstName"
            [showAsterisk]="formGroup.hasError('searchInputRequired')"
            attr.aria-required="{{ formGroup.hasError('searchInputRequired') ? 'true' : 'false' }}"
          ></app-text-field>
        </div>
        <div class="col-lg-4 no-form-field-padding">
          <app-date-picker-v2
            label="DOB"
            formControlName="learnerDOB"
            [max]="today"
            [min]="twentyFiveYearsAgo"
            [showAsterisk]="formGroup.hasError('searchInputRequired')"
            attr.aria-required="{{ formGroup.hasError('searchInputRequired') ? 'true' : 'false' }}"
          ></app-date-picker-v2>
        </div>
        <div class="col-lg-4 no-form-field-padding">
          <app-number-field
            [showAsterisk]="formGroup.hasError('searchInputRequired')"
            label="State ID"
            formControlName="learnerStateAssignedId"
          ></app-number-field>
          <mat-error
            style="display: inline"
            *ngIf="
              formGroup.controls.learnerStateAssignedId.touched && formGroup.get('learnerStateAssignedId').hasError('stateIdInvalidLength')
            "
          >
            <span class="text-sm">State ID must be 10 digit numbers</span>
          </mat-error>
        </div>
      </div>
    </div>
  </form>
  <div class="row">
    <div class="col-lg-12">
      <div class="mt-2 display-flex justify-content-end flex-wrap align-items-start">
        <div class="search-buttons display-flex align-items-end justify-content-end flex-1">
          <div>
            <button
              type="button"
              class="mr-2 btn--icon"
              mat-raised-button
              aria-label="Clear"
              color="primary"
              (click)="clear()"
              tabindex="0"
            >
              Clear Filters
            </button>
            <button mat-raised-button aria-label="Search" color="primary" (click)="onSearch()" [disabled]="formGroup.invalid" tabindex="0">
              {{ mainButtonLabel }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</mat-card>
