import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { NotificationService } from '../../../shared/services/notification.service';
import { AuthService } from '../../auth.service';
import { ResetPasswordViewModel } from '../../auth-models';

const passwordsMatchValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password').value;
  const confirmedPassword = control.get('confirmedPassword').value;

  return password !== confirmedPassword ? { passwordMismatch: true } : null;
};

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {
  isBusy = false;
  token: string;
  id: string;

  @Input() loginUrl = '/auth/login';

  formGroup = new FormGroup(
    {
      password: new FormControl('', { validators: [Validators.required] }),
      confirmedPassword: new FormControl('', { validators: [Validators.required] }),
    },
    {
      updateOn: 'blur',
      validators: passwordsMatchValidator,
    }
  );

  constructor(
    private notificationService: NotificationService,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.token = this.route.snapshot.queryParamMap.get('token');
    this.id = this.route.snapshot.queryParamMap.get('id');

    if (!this.token || !this.id) {
      // important parameters weren't provided, hence redirect user to login page
      this.routeToLoginPage();
    }
  }

  onSubmit() {
    const model = this.formGroup.value as ResetPasswordViewModel;
    model.token = this.token;
    model.id = this.id;

    if (model.password !== model.confirmedPassword) {
      this.notificationService.error('Password and Confirm Password do not match.');
      return;
    }

    this.isBusy = true;
    this.authService.resetPassword(model).subscribe(
      (response) => {
        this.isBusy = false;
        this.routeToLoginPage();
      },
      (error) => {
        this.notificationService.error(error?.error);
        this.isBusy = false;
      }
    );
  }

  routeToLoginPage() {
    this.router.navigateByUrl(this.loginUrl);
  }
}
