import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NotificationService } from '../../../shared/services/notification.service';
import { AuthService } from '../../auth.service';
import { ForgotPasswordViewModel } from '../../auth-models';

@Component({
  selector: 'app-interactive-access-forgot-password',
  templateUrl: './interactive-access-forgot-password.component.html',
  styleUrls: ['./interactive-access-forgot-password.component.scss'],
})
export class InteractiveAccessForgotPasswordComponent implements OnInit {
  pageTitle = 'Interactive Access Forgot Password';
  isBusy: boolean;
  requestCompleted: boolean;

  formGroup: FormGroup;

  get email() {
    return this.formGroup.get('email');
  }

  constructor(private notificationService: NotificationService, private authService: AuthService) {}

  ngOnInit(): void {
    this.formGroup = new FormGroup({
      email: new FormControl('', { validators: [Validators.required, Validators.email] }),
    });
  }

  getErrorMessage() {
    return this.email.hasError('required') ? 'You must enter a value' : this.email.hasError('email') ? 'Not a valid email' : '';
  }

  onSubmit() {
    if (this.formGroup.valid) {
      const model = this.formGroup.value as ForgotPasswordViewModel;

      this.authService.interactiveAccessForgotPassword(model).subscribe(
        (response) => {
          this.requestCompleted = true;
        },
        (error) => {
          this.notificationService.error(error?.error);
        }
      );
    }
  }
}
