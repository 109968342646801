import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AppMatModule } from 'src/app/shared/app-mat.module';
import { SearchComponent } from './search.component';
import { SearchRoutingModule } from './search-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { ResultsComponent } from './results/results.component';
import { DetailsComponent } from './details/details.component';
import { MatPaginatorModule } from '@angular/material/paginator';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { NewCaseModalComponent } from './details/new-case-modal/new-case-modal.component';

@NgModule({
  declarations: [SearchComponent, ResultsComponent, DetailsComponent, NewCaseModalComponent],
  imports: [AppMatModule, CommonModule, MatPaginatorModule, ReactiveFormsModule, SearchRoutingModule, SharedModule, AngularSvgIconModule],
  exports: [ResultsComponent],
})
export class SearchModule {}
