import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { FeatureFlagService } from 'src/app/shared/services/feature-flags/feature-flag.service';
import { StringSizes } from 'src/app/shared/components/form/constants/constants';
import { IncompleteDataReportItem } from 'src/app/shared/components/incomplete-data-report/incomplete-data-report-item';
import { CaseSummaryByIepId } from 'src/app/shared/models/case';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { PdfOutputs, ReportingService } from 'src/app/shared/services/reporting/reporting.service';
import { openDraftPdfWindow } from 'src/app/shared/windowHelpers';
import { BaseComponent } from '../../../../shared/components/base-component/base-component';
import { DeactivationService } from '../../../../shared/services/deactivation.service';
import { NotificationService } from '../../../../shared/services/notification.service';
import { IepAmendment } from '../../../models/iep-amendment';
import { IepAmendmentService } from '../../../services/iep-amendment.service';
import { IepESYConsiderationsDto } from '../../esy';
import { ESYService } from '../../esy-service';
import { IepStatus } from '../../../models/iep';

@Component({
  selector: 'app-esy-overview',
  templateUrl: './esy-overview.component.html',
  styleUrls: ['./esy-overview.component.scss'],
})
export class EsyOverviewComponent extends BaseComponent implements OnInit, OnDestroy {
  pdfOutputs = PdfOutputs;
  formGroup = new FormGroup({});
  iepId: string;
  showField = {
    FactorsSection: false,
    LimitedEnglish: false,
    Behavior: false,
    AccessibleMaterials: false,
    Braille: false,
    CommunicationLanguage: false,
    AssistiveTechnology: false,
    Health: false,
    Hearing: false,
  };
  incompleteItems: IncompleteDataReportItem[];
  amendments: IepAmendment[] = [];
  esyConsideration: IepESYConsiderationsDto = null;
  caseSummary: CaseSummaryByIepId;
  stringSizes = StringSizes;
  generatingDraftESY = false;
  loading = {
    finalization: false,
  };
  documentId: string;

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentId() {
    return this.amendment?.id;
  }

  get amendmentIsFinalized() {
    return this.amendment?.finalizeDate !== null;
  }

  get hasOpenAmendment() {
    return !!this.amendment && !this.amendmentIsFinalized;
  }

  get lastFinalizedDate() {
    if (this.amendments && this.amendments.length > 0) {
      const latest = this.amendments.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  get hasAmendment() {
    return this.amendmentId && this.amendmentId !== null;
  }

  get hasPriorVersion() {
    return !!this.esyConsideration?.priorVersionId;
  }

  get readonlyEsy() {
    return (
      (this.esyConsideration?.iepIsFinalized && !this.hasOpenAmendment) ||
      (this.hasOpenAmendment && !this.esyConsideration?.amendingESYPlan) ||
      this.authService.IsReadOnly
    );
  }

  get childFirstName() {
    return this.caseSummary?.learner?.firstName;
  }

  get showDraftESYBtn() {
    return this.caseSummary?.activeIepStatus === IepStatus.Draft || this.hasOpenAmendment;
  }

  get esyDocId() {
    return this.amendment?.esyDocumentId ? this.amendment.esyDocumentId : this.caseSummary?.esyDocumentId;
  }

  constructor(
    private route: ActivatedRoute,
    private esyService: ESYService,
    private notificationService: NotificationService,
    private iepAmendmentService: IepAmendmentService,
    private caseService: CaseService,
    deactivationService: DeactivationService,
    private authService: AuthService,
    private readonly reportingService: ReportingService,
    public readonly featureFlagService: FeatureFlagService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.iepId = this.route.parent.snapshot.paramMap.get('iepId');
    this.iepAmendmentService.getAmendmentsByIepId(this.iepId).subscribe((amendments) => {
      this.amendments = amendments;

      forkJoin([
        this.caseService.getCaseSummaryByIep(this.iepId),
        this.esyService.getSpecialFactors(this.iepId, this.amendmentId),
      ]).subscribe(async ([caseSummary, specialFactors]) => {
        this.caseSummary = caseSummary;
        this.esyConsideration = specialFactors;
        this.populateForms(specialFactors);
        this.formGroup.valueChanges.pipe(debounceTime(1000)).subscribe(() => {
          if (this.formGroup.dirty) {
            this.onSave();
          }
        });
        this.getIncompleteDataReport();
      });
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  refreshIncompleteItems(e: MouseEvent) {
    this.getIncompleteDataReport();
  }

  getIncompleteDataReport() {
    this.esyService.getESYIdr(this.iepId, this.amendmentId).subscribe((items) => {
      this.incompleteItems = items;
    });
  }

  populateForms(specialFactors: IepESYConsiderationsDto) {
    if (specialFactors.specialFactorsAddressed.length > 0) {
      for (const factor of specialFactors.specialFactorsAddressed) {
        this.formGroup.addControl(factor.toString(), new FormControl('', Validators.required));
        this.showField[factor] = true;
        this.showField.FactorsSection = true;
      }

      this.formGroup.patchValue({
        LimitedEnglish: specialFactors.limitedEnglishConsiderations ? specialFactors.limitedEnglishConsiderations : '',
        Behavior: specialFactors.behaviorConsiderations ? specialFactors.behaviorConsiderations : '',
        AccessibleMaterials: specialFactors.accessibleMaterialsConsiderations ? specialFactors.accessibleMaterialsConsiderations : '',
        Braille: specialFactors.brailleConsiderations ? specialFactors.brailleConsiderations : '',
        CommunicationLanguage: specialFactors.communicationLanguageConsiderations ? specialFactors.communicationLanguageConsiderations : '',
        AssistiveTechnology: specialFactors.assistiveTechnologyConsiderations ? specialFactors.assistiveTechnologyConsiderations : '',
        Health: specialFactors.healthConsiderations ? specialFactors.healthConsiderations : '',
        Hearing: specialFactors.hearingConsiderations ? specialFactors.hearingConsiderations : '',
      });
    }
  }

  formatFormGroup() {
    const values = this.formGroup.value;
    const considerations = {
      id: this.esyConsideration?.id,
      iepId: this.iepId,
      amendmentId: this.amendmentId,
      limitedEnglishConsiderations: values.LimitedEnglish ? values.LimitedEnglish : '',
      behaviorConsiderations: values.Behavior ? values.Behavior : '',
      accessibleMaterialsConsiderations: values.AccessibleMaterials ? values.AccessibleMaterials : '',
      brailleConsiderations: values.Braille ? values.Braille : '',
      communicationLanguageConsiderations: values.CommunicationLanguage ? values.CommunicationLanguage : '',
      assistiveTechnologyConsiderations: values.AssistiveTechnology ? values.AssistiveTechnology : '',
      healthConsiderations: values.Health ? values.Health : '',
      hearingConsiderations: values.Hearing ? values.Hearing : '',
      specialFactorsAddressed: null,
      amendingESYPlan: this.esyConsideration.amendingESYPlan,
    } as IepESYConsiderationsDto;

    return considerations;
  }

  startAmendment() {
    if (this.esyConsideration.id === null) {
      this.esyConsideration.amendingESYPlan = true;
    } else {
      this.esyService
        .startESYConsiderationsAmendment(this.esyConsideration.id, this.iepId, this.amendmentId)
        .subscribe((amendingESYConsideration) => {
          this.esyConsideration = amendingESYConsideration;
        });
    }
  }

  onSave() {
    this.esyService.updateESYConsiderations(this.formatFormGroup()).subscribe(
      (x) => {
        this.esyConsideration.id = x.id;
        this.notificationService.success('Saved!');
      },
      () => {
        this.notificationService.error('Failed to save.');
      }
    );
  }

  onViewDraftESY(): void {
    this.generatingDraftESY = true;
    this.reportingService.getDraftEsyPlan(this.iepId).subscribe({
      next: (res: ArrayBuffer) => {
        this.generatingDraftESY = false;
        openDraftPdfWindow(res);
      },
      error: (err) => {
        this.generatingDraftESY = false;
        this.handleError(err);
      },
    });
  }

  private handleError(err) {
    this.loading.finalization = false;
    this.notificationService.errorWithAction("Couldn't open output", 'Why?', () =>
      this.notificationService.alert(err.error, "Couldn't open output")
    );
  }
}
