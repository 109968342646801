<div class="display-flex justify-content-between align-items-center flex-wrap" *ngIf="ownerOrSuperAdmin">
  <div class="display-flex flex-1 mr-3">
    <app-help-directional-text *ngIf="!isPartB">
      <p>
        Addressing the following developmental areas is required for all developmental delay referrals: adaptive, cognitive, communication,
        physical, and social-emotional. After evaluation assignments have been made by the facilitator, team members may edit their own
        assignments. To edit and/or update a row, click on the Quick Access Menu. Save responses by clicking on the save icon.
      </p>
    </app-help-directional-text>
    <app-help-directional-text *ngIf="isPartB">
      <p>
        After evaluation assignments have been made by the facilitator, team members may edit their own assignments. To edit and/or update a
        row, click on the Quick Access Menu. Save responses by clicking on the save icon.
      </p>
    </app-help-directional-text>
  </div>

  <button
    mat-raised-button
    aria-label="Assign Areas to be Evaluated"
    color="primary"
    class="justify-content-end"
    [disabled]="isEditing"
    (click)="onOpenAssignEvaluator()"
    tabindex="0"
  >
    <!-- Assign by Evaluator (Pivot View) -->
    Assign Areas to be Evaluated
  </button>
</div>

<ng-container *ngIf="evaluation.owner.id !== me && !ownerOrSuperAdmin">
  <app-help-directional-text *ngIf="!isPartB">
    <p>
      Addressing the following developmental areas is required for all developmental delay referrals: adaptive, cognitive, communication,
      physical, and social-emotional. After evaluation assignments have been made by the facilitator, team members may edit their own
      assignments. To edit and/or update a row, click on the Quick Access Menu. Save responses by clicking on the save icon.
    </p>
  </app-help-directional-text>
  <app-help-directional-text *ngIf="isPartB">
    <p>
      After evaluation assignments have been made by the facilitator, team members may edit their own assignments. To edit and/or update a
      row, click on the Quick Access Menu. Save responses by clicking on the save icon.
    </p>
  </app-help-directional-text>
</ng-container>

<hr class="input__divider" />

<div class="table-overflow app-table">
  <table mat-table [dataSource]="dataSource" matSort class="w-100">
    <!-- Actions Column -->
    <ng-container matColumnDef="quickActions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Actions</th>
      <td mat-cell *matCellDef="let assignment" [ngClass]="{ 'mat-column-actions--more': assignment.isEditing }">
        <ng-container *ngIf="assignment.id && !isEditing && atLeastOneMenuOption(assignment)">
          <mat-menu #actionMenu="matMenu">
            <button
              *ngIf="canEditAssignment && (ownerOrSuperAdmin || assignment.userId === me)"
              color="primary"
              mat-menu-item
              aria-label="Edit Icon"
              (click)="setEdit(assignment)"
              tabindex="0"
            >
              Edit
            </button>
            <a
              mat-menu-item
              target="_blank"
              href="mailto:{{ assignment.teamMemberEmail }}"
              *ngIf="assignment.teamMemberName && assignment.teamMemberName !== myUserName"
              >Contact</a
            >
            <button
              mat-menu-item
              aria-label="Clear Team Member"
              (click)="removeAssignment(assignment)"
              *ngIf="ownerOrSuperAdmin && (assignment.teamMemberName || assignment.startTime || assignment.date)"
              tabindex="0"
            >
              Clear Team Member
            </button>
            <button
              mat-menu-item
              aria-label="Delete Row"
              (click)="removeDomainAssignment(assignment)"
              *ngIf="ownerOrSuperAdmin && assignment.canRemove"
              tabindex="0"
            >
              Delete Row
            </button>
          </mat-menu>

          <button *ngIf="!addingNew" mat-icon-button aria-label="More option" color="primary" [matMenuTriggerFor]="actionMenu" tabindex="0">
            <mat-icon>more_vert</mat-icon>
          </button>
        </ng-container>
        <ng-container *ngIf="assignment.isEditing">
          <button aria-label="Save Icon" mat-icon-button color="primary" (click)="onSave(assignment)" title="Save" tabindex="0">
            <mat-icon>save</mat-icon>
          </button>
          <button aria-label="Clear Icon" mat-icon-button color="warn" (click)="setEdit(assignment, false)" title="Cancel" tabindex="0">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-menu #actionMenu="matMenu">
            <button mat-menu-item aria-label="Save" (click)="onSave(assignment)" tabindex="0">Save</button>
            <button mat-menu-item aria-label="Cancel" (click)="setEdit(assignment, false)" tabindex="0">Cancel</button>
          </mat-menu>
        </ng-container>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <ng-container *ngIf="addingNew">
          <button aria-label="Save Icon" mat-icon-button color="primary" title="Save" (click)="onSave(null)" tabindex="0">
            <mat-icon>save</mat-icon>
          </button>
          <button aria-label="Clear Icon" mat-icon-button color="warn" (click)="setEdit(null, false)" title="Cancel" tabindex="0">
            <mat-icon>clear</mat-icon>
          </button>
          <mat-menu #actionMenu="matMenu">
            <button mat-menu-item aria-label="Save Button" (click)="onSave(null)" tabindex="0">Save</button>
            <button mat-menu-item aria-label="Cancel Button" (click)="setEdit(null, false)" tabindex="0">Cancel</button>
          </mat-menu>
        </ng-container>
        <ng-container *ngIf="!addingNew && !isEditing && ownerOrSuperAdmin">
          <button mat-icon-button aria-label="Add Icon" color="primary" (click)="addingNew = true" tabindex="0">
            <mat-icon>add_circle</mat-icon>
          </button>
        </ng-container>
      </td>
    </ng-container>

    <!-- Domain Column -->
    <ng-container matColumnDef="domain">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ isPartB ? 'Domain' : 'Developmental Area' }}
      </th>
      <td mat-cell *matCellDef="let assignment">
        <app-inline-edit
          [editing]="assignment.isEditing"
          [allowEdit]="ownerOrSuperAdmin && assignment.status?.label !== 'Complete' && !assignment.startTime && !assignment.date"
        >
          <ng-container viewMode>
            {{ assignment.domainArea?.label?.includes(':') ? assignment.domainArea.label.split(':')[0] : assignment.domainArea.label }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-autocomplete
              [options]="domainOptions"
              [label]="isPartB ? 'Select Domain' : 'Select Developmental Area'"
              formControlName="domain"
              class="assignment--spacing-fix cell-spacing-fix"
            >
            </app-autocomplete>
          </ng-container>
        </app-inline-edit>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <ng-container *ngIf="addingNew" [formGroup]="formGroup">
          <app-autocomplete
            [options]="domainOptions"
            [label]="isPartB ? 'Select Domain' : 'Select Developmental Area'"
            formControlName="domain"
            class="assignment--spacing-fix cell-spacing-fix"
          >
          </app-autocomplete>
        </ng-container>
      </td>
    </ng-container>

    <!-- Area Column -->
    <ng-container matColumnDef="area" *ngIf="isPartB">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Area</th>
      <td mat-cell *matCellDef="let assignment">
        <app-inline-edit
          [editing]="assignment.isEditing"
          [allowEdit]="ownerOrSuperAdmin && assignment.status?.label !== 'Complete' && !assignment.startTime && !assignment.date"
        >
          <ng-container viewMode>
            {{ assignment.domainArea.area }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <mat-form-field class="pt-2 cell-spacing-fix w-100" *ngIf="assignment.domainArea?.evaluationDomain?.id === academicDomainId">
              <mat-label>Area</mat-label>
              <mat-select formControlName="area">
                <mat-option value="Reading" role="option">Reading</mat-option>
                <mat-option value="Written Expression" role="option"> Written Expression</mat-option>
                <mat-option value="Math" role="option">Math</mat-option>
              </mat-select>
            </mat-form-field>

            <app-text-field
              *ngIf="assignment.domainArea?.evaluationDomain?.id !== academicDomainId"
              label="Area"
              class="assignment--spacing-fix"
              formControlName="area"
            ></app-text-field>
          </ng-container>
        </app-inline-edit>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <ng-container *ngIf="addingNew" [formGroup]="formGroup">
          <mat-form-field *ngIf="formGroup.value.domain === academicDomainId" class="pt-2 cell-spacing-fix w-100">
            <mat-label>Area</mat-label>
            <mat-select formControlName="area">
              <mat-option value="Reading" role="option">Reading</mat-option>
              <mat-option value="Written Expression" role="option"> Written Expression</mat-option>
              <mat-option value="Math" role="option">Math</mat-option>
            </mat-select>
          </mat-form-field>

          <app-text-field
            *ngIf="formGroup.value.domain !== academicDomainId"
            label="Area"
            class="assignment--spacing-fix cell-spacing-fix"
            formControlName="area"
          ></app-text-field>
        </ng-container>
      </td>
    </ng-container>

    <!-- Team Member Column -->
    <ng-container matColumnDef="teamMember">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Team Member</th>
      <td mat-cell *matCellDef="let assignment">
        <app-inline-edit
          [editing]="assignment.isEditing"
          [allowEdit]="ownerOrSuperAdmin && assignment.status?.label !== 'Complete' && !assignment.startTime && !assignment.date"
        >
          <ng-container viewMode>
            {{ assignment.teamMemberName }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-autocomplete
              [options]="teamMemberOptions"
              label="Team Member"
              formControlName="teamMember"
              class="pr-2 assignment--spacing-fix"
            >
            </app-autocomplete>
          </ng-container>
        </app-inline-edit>
      </td>
      <td mat-footer-cell *matFooterCellDef>
        <ng-container *ngIf="addingNew" [formGroup]="formGroup">
          <app-autocomplete
            [options]="teamMemberOptions"
            label="Team Member"
            formControlName="teamMember"
            class="pr-2 assignment--spacing-fix"
          >
          </app-autocomplete>
        </ng-container>
      </td>
    </ng-container>

    <!-- Start Time Column -->
    <ng-container matColumnDef="startTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let assignment">
        <app-inline-edit [editing]="assignment.isEditing" [allowEdit]="me === assignment.userId">
          <ng-container viewMode>
            {{ assignment.date | dateFormat }}
            {{ assignment.startTime ? ', ' + assignment.startTime : '' }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <div class="display-flex align-items-center">
              <app-date-picker controlName="date" class="mr-2 assignment--spacing-fix width--sm"></app-date-picker>
              <app-autocomplete
                class="width--sm assignment--spacing-fix"
                formControlName="startTime"
                [options]="startTimeOptions"
              ></app-autocomplete>
            </div>
          </ng-container>
        </app-inline-edit>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!-- Status Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Evaluation Status</th>
      <td mat-cell *matCellDef="let assignment">
        <app-inline-edit [editing]="assignment.isEditing" [allowEdit]="me === assignment.userId">
          <ng-container viewMode>
            {{ assignment.status.label }}
          </ng-container>
          <ng-container editMode [formGroup]="formGroup">
            <app-autocomplete
              class="pt-2 width--sm assignment--spacing-fix"
              [options]="statuesOptions"
              (selected)="statusSelected($event)"
              label="Status"
              formControlName="status"
            ></app-autocomplete>
          </ng-container>
        </app-inline-edit>
      </td>
      <td mat-footer-cell *matFooterCellDef></td>
    </ng-container>

    <!--suppress AngularUndefinedBinding -->
    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <!--suppress AngularUndefinedBinding -->
    <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="{ highlight: row.userId === this.me }"></tr>
    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
  </table>
</div>
<p *ngIf="!dataSource.data">No data yet...</p>
