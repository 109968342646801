import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMatModule } from 'src/app/shared/app-mat.module';
import { HelpModalComponent } from 'src/app/shared/modals/help-modal/help-modal.component';
import { TeamMemberSearchModalComponent } from 'src/app/shared/modals/team-member-search-modal/team-member-search-modal.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ChildInformationComponent } from './child-information/child-information.component';
import { DisabilitySuspectRoutingModule } from './disability-suspect-routing.module';
import { DisabilitySuspectComponent } from './disability-suspect.component';
import { DsFormSummaryComponent } from './ds-form-summary/ds-form-summary.component';
import { DsFormComponent } from './ds-form/ds-form.component';
import { DsNonachieveTeamMemberComponent } from './ds-form/ds-nonachieve-team-member/ds-nonachieve-team-member.component';
import { QuestionComponent } from './ds-form/question/question.component';
import { StatewideAssessmentResultsComponent } from './statewide-assessment-results/statewide-assessment-results.component';

@NgModule({
  declarations: [
    ChildInformationComponent,
    DisabilitySuspectComponent,
    DsFormComponent,
    QuestionComponent,
    StatewideAssessmentResultsComponent,
    DsFormSummaryComponent,
    DsNonachieveTeamMemberComponent,
  ],
  imports: [AppMatModule, CommonModule, DisabilitySuspectRoutingModule, ReactiveFormsModule, FormsModule, SharedModule],
  entryComponents: [TeamMemberSearchModalComponent, HelpModalComponent],
  exports: [
    ChildInformationComponent,
    DisabilitySuspectComponent,
    DsFormComponent,
    StatewideAssessmentResultsComponent,
    DsFormSummaryComponent,
  ],
})
export class DisabilitySuspectModule {}
