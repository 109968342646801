import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-statewide-assessment-results',
  templateUrl: './statewide-assessment-results.component.html',
  styleUrls: ['./statewide-assessment-results.component.scss'],
})
export class StatewideAssessmentResultsComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
