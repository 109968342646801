<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mb-0">Add Consent Agency</h3>

<div mat-dialog-content class="py-3 mt-3">
  <mat-card class="my-0">
    <ng-container [formGroup]="formGroup">
      <app-autocomplete label="Consent Agency" formControlName="consentAgencyId" [options]="consentAgencyOptions"></app-autocomplete>
    </ng-container>
  </mat-card>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button mat-dialog-close aria-label="Cancel" class="background-color--muted mr-1" tabindex="0">Cancel</button>

  <button mat-raised-button aria-label="Submit" color="primary" (click)="onSubmit()" [disabled]="formGroup.invalid" tabindex="0">
    Submit
  </button>
</div>
