import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { OperationResult, OperationResultWithValue } from '../../../../shared/models/operation-result';
import { NotificationService } from '../../../../shared/services/notification.service';
import { DhhScreeningSearchDto, DhhScreeningRecheckFilters, DhhLearnerScreeningDto, DhhDeleteDto } from '../../../models/DhhDtos';
import { Teacher } from '../../../../shared/models/teacher';

@Injectable({
  providedIn: 'root',
})
export class DhhScreeningService {
  private dhhBaseUrl = 'api/dhh/screening';

  constructor(private readonly http: HttpClient, private readonly notificationService: NotificationService) {}

  getLearners(searchParams: DhhScreeningRecheckFilters) {
    let params = new HttpParams();

    Object.keys(searchParams).forEach((k) => {
      params = params.set(k, searchParams[k]);
    });

    return this.http.get<OperationResultWithValue<DhhScreeningSearchDto[]>>(`${this.dhhBaseUrl}/learners`, { params });
  }

  saveScreening(dto: DhhLearnerScreeningDto) {
    return this.http.post<OperationResultWithValue<string>>(this.dhhBaseUrl, dto);
  }

  getScreening(id: string) {
    return this.http.get<OperationResultWithValue<DhhLearnerScreeningDto>>(`${this.dhhBaseUrl}?id=${id}`);
  }

  getRunningListOutput(selectedIds: string[], filters: DhhScreeningRecheckFilters) {
    const body = {
      districtId: filters.districtId,
      buildingId: filters.buildingId,
      teacherId: filters.teacherId || '',
      grade: filters.grade || '',
      learnerIds: selectedIds,
    };
    return this.http.post<Blob>(`${this.dhhBaseUrl}/running-list-output`, body, {
      responseType: 'blob' as 'json',
    });
  }

  getTeachersByDistrict(districtId: string) {
    return this.http.get<OperationResultWithValue<Teacher[]>>(`${this.dhhBaseUrl}/teachers?districtId=${districtId}`);
  }

  deleteScreening(dto: DhhDeleteDto) {
    return this.http.put<OperationResult>(`${this.dhhBaseUrl}/delete`, dto);
  }

  handleError(errorTitle: string, error: any): void {
    let errorMessage = '';
    if (error && error.error && error.error.errors) {
      errorMessage = Array.isArray(error.error.errors)
        ? error.error.errors.map((err) => err.description)?.join(',')
        : Object.keys(error.error.errors)
            .map((key) => error.error.errors[key])
            ?.join(',');
    } else if (error && error.errors) {
      errorMessage = Array.isArray(error.errors)
        ? error.errors.map((err) => err.description)?.join(',')
        : Object.keys(error.errors)
            .map((key) => error.errors[key])
            ?.join(',');
    } else if (error && error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = JSON.stringify(error);
    }

    this.notificationService.errorWithAction(errorTitle, 'Why?', () => {
      this.notificationService.alert(errorMessage, errorTitle);
    });
  }
}
