import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { forkJoin } from 'rxjs';
import {
  ProceduralSafeguardDto,
  ProceduralSafeguardsRead,
  ProceduralSafeguardsRow,
} from 'src/app/shared/components/procedural-safeguards/procedural-safeguards';
import { ProceduralSafeguardsService } from 'src/app/shared/components/procedural-safeguards/procedural-safeguards.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { AuthService } from '../../../auth/auth.service';
import { AppPermissions } from '../../../permissions';
import { CaseSummary, IntakeType } from '../../models/case';
import { LearnerSummary } from '../../models/learner';
import { AchieveConfigService } from '../../services/achieve-config-service/achieve-config.service';
import { CaseService } from '../../services/case/case.service';
import { LearnerService } from '../../services/learner/learner.service';
import { DatabaseLinksService } from '../../services/database-links/database-links.service';

@Component({
  selector: 'app-procedural-safeguards-list',
  templateUrl: './procedural-safeguards-list.component.html',
  styleUrls: ['./procedural-safeguards-list.component.scss'],
})
export class ProceduralSafeguardsListComponent implements OnInit {
  @Input() showTable = true;
  @Input() showProvidedOn = true;
  @Input() caseId: string;
  @Input() learnerId: string;
  @Input() accessToParentPortal = false;
  @Input() mostRecentProceduralSafeguard: ProceduralSafeguardsRead;
  @Output() mostRecentChanged = new EventEmitter<ProceduralSafeguardsRead>();

  formGroup: FormGroup = new FormGroup({
    id: new FormControl(null),
    caseId: new FormControl(null, Validators.required),
    providedById: new FormControl(null, Validators.required),
    providedOn: new FormControl(null, Validators.required),
    howProvidedMethod: new FormControl(null, Validators.required),
    otherHowProvided: new FormControl(null),
    acceptedPrintedCopy: new FormControl(null, Validators.required),
  });

  dsDate = dayjs().startOf('day').add(-90, 'day').toDate();
  today = dayjs().startOf('day').toDate();
  acceptDeclineOptions: KeyValuePair[] = [
    new KeyValuePair(true, 'Parent accepted printed copy of "Procedural Safeguards Manual".'),
    new KeyValuePair(false, 'Parent declined printed copy of "Procedural Safeguards Manual".'),
  ];
  shortDateFormat = shortDateFormat;
  teamMemberOptions: KeyValuePair[] = [];
  providedMethodOptions: KeyValuePair[] = [];
  otherProvidedMethodId: string;
  isOtherProvidedMethod: boolean;
  dataSource = new MatTableDataSource<ProceduralSafeguardsRow>();
  displayedColumns = ['actions', 'providedOn', 'providedBy', 'howProvidedString', 'acceptedPrintedCopy'];
  learnerSummary: LearnerSummary;
  caseSummary: CaseSummary;
  caseExited = false;
  isBusy = false;
  manualLink: string;

  get hasEditPermission() {
    return this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.EditProceduralSafeguard);
  }

  get hasViewPermission() {
    return (
      this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.EditProceduralSafeguard) ||
      this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.ViewProceduralSafeguard) ||
      this.isPortalUser
    );
  }

  get achieveSettings() {
    return this.achieveConfigService?.settings;
  }

  get isPortalUser() {
    return this.authService.isPortalUser;
  }

  constructor(
    private proceduralSafeguardsService: ProceduralSafeguardsService,
    private caseService: CaseService,
    private learnerService: LearnerService,
    public authService: AuthService,
    private readonly achieveConfigService: AchieveConfigService,
    private readonly databaseLinksService: DatabaseLinksService
  ) {}

  async ngOnInit(): Promise<void> {
    if (this.isPortalUser) {
      this.removeActionColumn();
    }

    this.learnerService.getLearnerSummary(this.learnerId).subscribe((summary: LearnerSummary) => {
      this.learnerSummary = summary;
      if (summary && summary.activeCases && summary.activeCases.length > 0) {
        const caseSummary = summary.activeCases.find((x) => x.id == this.caseId);
        this.manualLink =
          caseSummary?.intakeType === IntakeType.PartB
            ? this.databaseLinksService.getSpecialEducationLink('proceduralSafeguards')
            : this.databaseLinksService.getEarlyAccessLink('proceduralSafeguards');
      }
    });

    forkJoin([
      this.proceduralSafeguardsService.get(this.caseId),
      this.proceduralSafeguardsService.getProvidedMethods(this.caseId),
      this.caseService.getCaseSummary(this.caseId),
    ]).subscribe(([proceduralSafeguards, providedMethods, caseSummary]) => {
      this.dataSource.data = this.createRows(proceduralSafeguards);
      this.providedMethodOptions = providedMethods
        .filter((x) => this.accessToParentPortal || !x.label.includes('Family Portal'))
        .map((pm) => new KeyValuePair(pm.id, pm.label));
      this.otherProvidedMethodId = providedMethods.find((pm) => pm.isOther)?.id;
      const { caseUsers } = caseSummary;
      this.caseSummary = caseSummary;
      caseUsers.sort((a, b) => (a.fullName < b.fullName ? -1 : 1));
      const ownerIdx = caseUsers.findIndex((cu) => cu.isOwner);
      this.teamMemberOptions = [caseUsers[ownerIdx], ...caseUsers.slice(0, ownerIdx), ...caseUsers.slice(ownerIdx + 1)]
        .filter((x) => !!x)
        .map((cu) => new KeyValuePair(cu.id, cu.fullName));
      this.mostRecentProceduralSafeguard = proceduralSafeguards.length > 0 ? proceduralSafeguards[0] : null;
      this.mostRecentChanged.emit(this.mostRecentProceduralSafeguard);

      if (caseSummary.exitFinalized) {
        this.caseExited = true;
        setTimeout(() => {
          this.formGroup.disable();
        }, 100);
        this.removeActionColumn();
      }
    });

    this.formGroup.controls.caseId.setValue(this.caseId);

    this.formGroup.controls.howProvidedMethod.valueChanges.subscribe((val: string[]) => {
      if (!val) {
        this.isOtherProvidedMethod = false;
        return;
      }
      this.isOtherProvidedMethod = val.includes(this.otherProvidedMethodId);
    });
  }

  removeActionColumn() {
    this.displayedColumns = ['providedOn', 'providedBy', 'howProvidedString', 'acceptedPrintedCopy'];
  }

  resetForm(): void {
    this.formGroup.reset({
      caseId: this.caseId,
      howProvidedMethod: null,
    });
  }

  canEdit(id: string): boolean {
    return this.mostRecentProceduralSafeguard.id === id;
  }

  onEdit(readOnlyPs: ProceduralSafeguardsRead): void {
    const ps = {
      id: readOnlyPs.id,
      caseId: this.caseId,
      providedById: readOnlyPs.providedBy.id,
      providedOn: readOnlyPs.providedOn,
      howProvidedMethod: readOnlyPs.howProvided.id,
      otherHowProvided: readOnlyPs.otherHowProvided,
      acceptedPrintedCopy: readOnlyPs.acceptedPrintedCopy,
    } as ProceduralSafeguardDto;

    this.formGroup.patchValue(ps);
  }

  async submit(): Promise<void> {
    this.isBusy = true;
    this.formGroup.markAllAsTouched();
    if (!this.formGroup.valid) {
      return;
    }

    const proceduralSafeguard = this.formGroup.value as ProceduralSafeguardDto;
    if (!proceduralSafeguard.id) {
      await this.proceduralSafeguardsService.add(proceduralSafeguard).toPromise();
    } else {
      await this.proceduralSafeguardsService.update(proceduralSafeguard).toPromise();
    }

    this.resetForm();
    const ps = await this.proceduralSafeguardsService.get(this.caseId).toPromise();
    this.dataSource.data = this.createRows(ps);
    this.mostRecentProceduralSafeguard = ps.length > 0 ? ps[0] : null;
    this.mostRecentChanged.emit(this.mostRecentProceduralSafeguard);
    this.isBusy = false;
  }

  private createRows(ps: ProceduralSafeguardsRead[]): ProceduralSafeguardsRow[] {
    return ps.map((m) => {
      let howProvidedString = m.howProvided.label;
      if (m.otherHowProvided) {
        howProvidedString += `, ${m.otherHowProvided}`;
      }
      return { ...m, howProvidedString };
    });
  }
}
