import { Component, ContentChild, Directive, EventEmitter, Inject, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';

@Directive({
  selector: '[appDhhUploadModalAboveDirective]',
})
export class DhhUploadModalAboveDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[appDhhUploadModalFieldsDirective]',
})
export class DhhUploadModalFieldsDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Directive({
  selector: '[appDhhUploadModalFileFieldDirective]',
})
export class DhhUploadModalFileFieldDirective {
  constructor(public templateRef: TemplateRef<unknown>) {}
}

@Component({
  selector: 'app-dhh-upload-modal-container',
  templateUrl: './dhh-upload-modal-container.component.html',
  styleUrls: ['./dhh-upload-modal-container.component.scss'],
})
export class DhhUploadModalContainerComponent implements OnInit {
  @Input() fileTitle;
  @Input() cardHeader = 'Upload Documentation';
  @Input() allowedFileTypes = 'TXT, CSV, PDF, DOC, DOCX, ODT, PPTX, PPT, RTF, XLS, XLSX, PNG, JPEG, JPG, GIF, BMP';
  @Input() maxFileSize = 209715200;
  @Input() maxFileSizeLabel = '200 MB';
  @Input() listOfFiles: File[] = [];
  @Input() readOnly = false;
  @Input() extraFileFormFields = [];
  @Output() listOfFilesChange = new EventEmitter<File[]>();
  @ContentChild(DhhUploadModalAboveDirective) aboveContent!: DhhUploadModalAboveDirective;
  @ContentChild(DhhUploadModalFieldsDirective) fieldsContent!: DhhUploadModalFieldsDirective;
  @ContentChild(DhhUploadModalFileFieldDirective) fileFieldContent!: DhhUploadModalFileFieldDirective;
  @ViewChild('fileInput') fileInput;

  documentationTypeOptions: KeyValuePair[];

  errors: string[];

  get formArrayControls() {
    return this.formArray.controls as FormGroup[];
  }

  get formArray() {
    return this.parentForm.get('documents') as FormArray;
  }

  get parentForm() {
    return this.parentFormDirective.form;
  }

  constructor(private readonly parentFormDirective: FormGroupDirective, private fb: FormBuilder) {}

  ngOnInit(): void {
    this.errors = [];
    const formArray = this.fb.array([]);
    this.parentForm.addControl('documents', formArray);
  }

  openNativeFileSelector() {
    this.errors = [];
    this.listOfFiles = [];

    if (this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }

  onFileSelected() {
    const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    for (const key in files) {
      if (!isNaN(parseInt(key))) {
        const file = files[key];
        if (this.isValidFile(file)) {
          this.listOfFiles.push(file);
        }
      }
    }
    if (this.errors.length === 0) {
      this.addFilesToList();
    }
  }

  private isValidFile(file: File): boolean {
    if (!(file && file.name)) {
      this.errors.push('Invalid file or files');
      return false;
    }

    const ext = file.name.split('.').slice(-1)[0];
    if (!ext) {
      this.errors.push('Cannot determine file type');
      return false;
    }

    if (!this.allowedFileTypes.toLowerCase().includes(ext.toLowerCase())) {
      this.errors.push(`File type '${ext}' is not supported`);
      return false;
    }

    if (file.size === 0) {
      this.errors.push(`File cannot be empty: ${file.name}`);
      return false;
    }

    if (file.size > this.maxFileSize) {
      this.errors.push(`File cannot be larger than ${this.maxFileSizeLabel}: ${file.name}`);
      return false;
    }

    return true;
  }

  async addFilesToList() {
    this.listOfFilesChange.emit(this.listOfFiles);

    const formArray = this.formArray;
    formArray.clear();

    for (const file of this.listOfFiles) {
      let group = {
        title: [this.fileTitle ?? file.name, Validators.required],
        type: [''],
        file: [file],
      };
      this.extraFileFormFields.forEach((field) => {
        group = { ...group, [field.name]: [field.value, field.validators] };
      });
      const fg = this.fb.group(group);
      formArray.push(fg);
    }
  }

  onRemoveAt(index: number) {
    this.formArray.removeAt(index);
  }
}
