import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { LookupBase } from 'src/app/shared/models/lookup-base';
import { Surrogate } from 'src/app/shared/models/surrogate';
import { OperationResult } from '../../../shared/models/operation-result';

@Injectable({
  providedIn: 'root',
})
export class SurrogateService {
  surrogateAdd = new Subject<boolean>();

  constructor(private http: HttpClient) {}

  get(learnerId: string): Observable<Surrogate[]> {
    return this.http.get<Surrogate[]>(`api/learners/${learnerId}/surrogates`);
  }

  create(learnerId: string, surrogate: Surrogate) {
    return this.http.post<string>(`api/learners/${learnerId}/surrogates`, surrogate);
  }

  update(learnerId: string, surrogate: Surrogate) {
    return this.http.put(`api/learners/${learnerId}/surrogates/${surrogate.id}`, surrogate);
  }

  delete(learnerId: string, surrogateId: string) {
    return this.http.put(`api/learners/${learnerId}/surrogates/delete/${surrogateId}`, null);
  }

  getReasonsForSurrogate() {
    return this.http.get<LookupBase[]>('api/reasons-for-surrogate');
  }

  inviteSurrogate(learnerId: string, surrogateId: string) {
    return this.http.post<OperationResult>(`api/learners/${learnerId}/surrogates/${surrogateId}/invite`, null, {
      params: { surrogateId: surrogateId },
    });
  }
}
