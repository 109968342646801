<ng-container *ngIf="summaryView">
  <h3 class="my-0">Scheduled Appointments</h3>
  <div *ngFor="let appointment of learnerAppointments">
    <div class="mt-2">
      <span>{{ appointment.startTime }} - {{ appointment.endTime }}</span> - {{ appointment.learner.lastName }},
      {{ appointment.learner.firstName }} - {{ appointment.appointmentType?.label }}
    </div>
  </div>

  <ng-container *ngIf="learnerAppointments?.length === 0">
    <p class="mt-2">No appointments set up yet.</p>
  </ng-container>

  <hr class="input__divider" />

  <div *ngIf="editMode && formGroup">
    <ng-container [formGroup]="formGroup">
      <div class="row mt-2">
        <div class="col-lg-12">
          <h3 class="my-0">Appointment Time</h3>
        </div>
        <div class="col-lg-3 no-form-field-padding">
          <app-timepicker label="Start Time" formControlName="startTime"></app-timepicker>
        </div>
        <div class="col-lg-3 no-form-field-padding">
          <app-timepicker label="End Time" formControlName="endTime"></app-timepicker>
        </div>
        <div class="col-lg-3 no-form-field-padding">
          <app-autocomplete
            label="Appointment Type"
            formControlName="appointmentTypeId"
            [options]="appointmentTypeOptions"
          ></app-autocomplete>
        </div>
        <div *ngIf="learnerAppointment" class="col-lg-3 no-form-field-padding">
          <app-autocomplete label="Reason" formControlName="rescheduleReasonId" [options]="rescheduleReasonOptions"></app-autocomplete>
        </div>
      </div>

      <div *ngIf="formGroup.get('startTime').hasError('invalidStartTime')" class="row">
        <div class="col-lg-12">
          <mat-error>
            <span class="text-sm">Start Time cannot be after the End Time.</span>
          </mat-error>
        </div>
      </div>

      <div *ngIf="formGroup.get('startTime').hasError('invalidMinDuration')" class="row">
        <div class="col-lg-12">
          <mat-error>
            <span class="text-sm">The minimum appointment duration is 15 minutes.</span>
          </mat-error>
        </div>
      </div>

      <div *ngIf="formGroup.get('startTime').hasError('outOfRange')" class="row">
        <div class="col-lg-12">
          <mat-error>
            <span class="text-sm">Schedule appointment within hours sound booth is available.</span>
          </mat-error>
        </div>
      </div>

      <div *ngIf="formGroup.get('startTime').hasError('overlap')" class="row">
        <div class="col-lg-12">
          <mat-error>
            <span class="text-sm">Reschedule for an available time.</span>
          </mat-error>
        </div>
      </div>

      <div class="row">
        <div class="col-lg-12">
          <h3 class="my-0">Appointment Letter Recipient</h3>
        </div>
        <div class="col-lg-6 no-form-field-padding">
          <app-autocomplete-chips
            label="Select Family Member(s)"
            formControlName="familyMembers"
            [options]="familyMembersOptions"
          ></app-autocomplete-chips>
        </div>
      </div>

      <div class="row" *ngIf="formGroup.controls.familyMembers?.value?.includes('Other')">
        <div class="col-lg-12 mt-2">
          <h3 class="my-0">Other Recipient</h3>
        </div>
        <ng-container formGroupName="participant">
          <div class="col-lg-6 no-form-field-padding">
            <app-text-field label="Name" formControlName="fullName"></app-text-field>
          </div>
          <div class="col-lg-6 no-form-field-padding">
            <app-text-field label="Email" formControlName="email"></app-text-field>
          </div>
          <div class="col-lg-6 no-form-field-padding">
            <app-autocomplete
              label="Relationship"
              formControlName="familyRelationshipId"
              [options]="relationshipOptions"
            ></app-autocomplete>
          </div>
          <div class="col-lg-6 no-form-field-padding">
            <app-text-field label="Street Address" formControlName="streetAddress"></app-text-field>
          </div>
          <div class="col-lg-6 no-form-field-padding">
            <app-text-field
              label="Zip Code"
              [maxLength]="10"
              formControlName="zipCode"
              (blurMe)="populateLocationFromZipCode()"
            ></app-text-field>
          </div>
          <div class="col-lg-6 no-form-field-padding">
            <app-text-field label="City/Town" formControlName="city"></app-text-field>
          </div>
          <div class="col-lg-6 no-form-field-padding">
            <app-text-field label="State" formControlName="state"></app-text-field>
          </div>
        </ng-container>
      </div>
    </ng-container>

    <div class="display-flex align-items-center justify-content-end mt-2">
      <button mat-raised-button aria-label="Clear Button" (click)="onCancel()" class="mr-2" [disabled]="isSaving" tabindex="0">
        Clear
      </button>
      <button
        color="primary"
        class="mr-2"
        mat-raised-button
        aria-label="Confirm Appointment & Generate Letter"
        (click)="onSave()"
        [disableAfterBusy]="formGroup.invalid"
        [disabled]="formGroup.invalid || isEventPast || (learnerAppointment && !appointmentUpdated)"
        [isBusy]="isSaving"
        tabindex="0"
      >
        Confirm Appointment & Generate Letter
      </button>
      <button
        color="primary"
        mat-raised-button
        aria-label="Choose Another Day"
        (click)="onChooseAnotherDay()"
        [isBusy]="isSaving"
        tabindex="0"
      >
        Choose Another Day
      </button>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="!summaryView">
  <h3 class="my-0">Appointments</h3>
  <mat-card class="mb-2">
    <mat-accordion class="accordion accordion--close accordion--no-border accordion--short accordion--in-card" multi>
      <mat-expansion-panel *ngFor="let appointment of learnerAppointments" class="mat-elevation-z0 no-body-padding">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="display-flex justify-content-between align-items-center w-100 flex-wrap">
              <h4 class="my-0 text-normal">{{ appointment.startTime }} - {{ appointment.endTime }}</h4>
              <div class="display-flex align-items-center justify-content-between flex-wrap">
                <div class="mx-2 flex-1">
                  {{ appointment.learner.firstName }} {{ appointment.learner.lastName }} | {{ appointment.appointmentType?.label }}
                </div>
              </div>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <app-dhh-learner-appointment-participant-table
          [participants]="appointment.participants"
        ></app-dhh-learner-appointment-participant-table>

        <div class="display-flex align-items-center justify-content-end mt-2 mb-2">
          <button
            *ngIf="canCancelRescheduleAppointment"
            color="primary"
            class="mr-2"
            mat-raised-button
            aria-label="Cancel Appointment"
            (click)="onCancelAppointment(appointment.id)"
            tabindex="0"
          >
            Cancel Appointment
          </button>

          <button
            *ngIf="canCancelRescheduleAppointment"
            color="primary"
            mat-raised-button
            aria-label="Reschedule Appointment"
            (click)="onRescheduleAppointment(appointment.learnerId, appointment.id)"
            tabindex="0"
          >
            Reschedule Appointment
          </button>
        </div>
      </mat-expansion-panel>
    </mat-accordion>

    <ng-container *ngIf="learnerAppointments?.length === 0">
      <p class="mt-2">No appointments set up yet.</p>
    </ng-container>
  </mat-card>

  <h3 class="my-0">Past Appointments</h3>
  <mat-card> Comming soon...</mat-card>
</ng-container>
