import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SpinnerService } from '../../../../../shared/services/spinner/spinner.service';
import { DhhPendingAppointmentDto } from '../../../../models/DhhDtos';
import { DhhRoutingService } from '../../../../services/dhh-routing.service';
import { DhhService } from '../../../../services/dhh.service';
import { DhhCancelLearnerAppointmentComponent } from '../dhh-cancel-learner-appointment/dhh-cancel-learner-appointment.component';

@Component({
  selector: 'app-dhh-learner-appointments-detail',
  templateUrl: './dhh-learner-appointments-detail.component.html',
  styleUrls: ['./dhh-learner-appointments-detail.component.scss'],
})
export class DhhLearnerAppointmentsDetailComponent implements OnInit {
  learnerId: string;
  pendingAppointments: DhhPendingAppointmentDto[] = [];

  constructor(
    private readonly dhhService: DhhService,
    private readonly dhhRoutingService: DhhRoutingService,
    private readonly spinnerService: SpinnerService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<DhhLearnerAppointmentsDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    this.learnerId = this.data.learnerId;
    setTimeout(() => this.spinnerService.incrementLoading(), 100);

    this.dhhService.getPendingAppointmentsByLearnerId(this.learnerId).subscribe(
      (result) => {
        if (result.succeeded) {
          this.pendingAppointments = result.value;
          this.pendingAppointments.sort((a, b) => {
            return (
              Date.parse('1970/01/01 ' + a.startTime.slice(0, -3) + a.startTime.slice(-3)) -
              Date.parse('1970/01/01 ' + b.startTime.slice(0, -3) + b.startTime.slice(-3))
            );
          });
        } else {
          this.dhhService.handleError('Failed to retrieving learner appointments.', result);
        }
        setTimeout(() => this.spinnerService.decrementLoading(), 400);
      },
      (error) => {
        setTimeout(() => this.spinnerService.decrementLoading(), 400);
        this.dhhService.handleError('There was an error while retrieving learner appointments.', error);
      }
    );
  }

  onCancelAppointment(learnerAppointmentId: string) {
    this.dialog.open(DhhCancelLearnerAppointmentComponent, {
      data: {
        learnerAppointmentId: learnerAppointmentId,
      },
    });
    setTimeout(() => this.closeModal(), 100);
  }

  onRescheduleAppointment(appointment: any) {
    this.dhhRoutingService.openLearnerAppointmentScheduler(appointment.learnerId, appointment.learnerAppointmentId);
    setTimeout(() => this.closeModal(), 100);
  }

  onAddNewAppointment() {
    this.dhhRoutingService.openLearnerAppointmentScheduler(this.learnerId);
    setTimeout(() => this.closeModal(), 100);
  }

  onClose() {
    this.closeModal();
  }

  closeModal() {
    this.dialogRef.close();
  }
}
