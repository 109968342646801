<app-print-head [title]="'Post-Referral Screening Results'" [showLogo]="true"></app-print-head>

<div class="print__container">
  <section class="display-flex align-items-center justify-content-between avoid-break">
    <p class="my-0"><strong>Child's Name:</strong> {{ displayName }}</p>
    <p class="my-0"><strong>DOB:</strong> {{ childInfo.dateOfBirth | dateFormat }}</p>
  </section>

  <section class="avoid-break">
    <h3 class="mt-0 mb-2">Tools used for post-referral screening:</h3>
    <p class="mt-0 mb-1" *ngFor="let postReferralTool of intake.nextSteps.postReferralScreeningTools">
      {{ postReferralTool.name }}
    </p>
  </section>

  <section class="avoid-break">
    <h3 class="mt-0 mb-2">Date screening completed:</h3>
    <p class="mt-0 mb-0">
      {{ intake.nextSteps.postReferralScreeningCompleted | dateFormat }}
    </p>
  </section>

  <section class="avoid-break">
    <h3 class="mt-0 mb-2">Screening conducted by:</h3>
    <p class="my-0">{{ intake.nextSteps.postReferralScreeningConductedBy }}</p>
  </section>

  <section class="avoid-break" *ngIf="hasNoConcerns()">
    <h3 class="mt-0 mb-2">Interpretation results:</h3>

    <p class="mt-0 mb-1">Post-Referral Screening indicates no concerns</p>
    <p class="mt-0 mb-1" *ngFor="let concern of intake.nextSteps.postReferralScreeningConcerns">
      {{ getDevelopmentalAreaLabel(concern) }}
    </p>
  </section>

  <section class="avoid-break" *ngIf="hasConcerns()">
    <h3 class="mt-0 mb-2">Interpretation results:</h3>
    <p class="my-0">The screening indicates child may have concerns in the following developmental areas:</p>
  </section>

  <section class="avoid-break">
    <h3 class="mt-0 mb-2">Summary:</h3>
    <p class="my-0">{{ intake.nextSteps.postReferralScreeningSummary }}</p>
  </section>

  <section class="avoid-break">
    <h3 class="mt-0 mb-2">Recommendations and follow-Up template:</h3>
    <p class="my-0">
      {{ intake.nextSteps.postReferralScreeningRecommendationFollowUp }}
    </p>
  </section>
</div>
