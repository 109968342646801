import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';

@Pipe({
  name: 'camelCaseWords',
})
export class CamelCaseWordsPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    return value
      .split(/(?=[A-Z])/)
      .join(' ')
      .replace(/^./, (s) => s.toUpperCase());
  }
}
