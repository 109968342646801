import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LookupBase } from '../../models/lookup-base';
import { FamilyRelationship } from '../../models/family-relationship';

@Injectable({
  providedIn: 'root',
})
export class FamilyRelationshipService {
  baseUrl = 'api/FamilyRelationship';

  constructor(private readonly http: HttpClient) {}

  get(includeDeleted = false): Observable<FamilyRelationship[]> {
    return this.http.get<FamilyRelationship[]>(`${this.baseUrl}?includeDeleted=${includeDeleted}`);
  }

  add(model): Observable<LookupBase> {
    return this.http.post<LookupBase>(this.baseUrl, model);
  }

  edit(model): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${model.id}`, model);
  }

  remove(id: string): Observable<void> {
    return this.http.put<void>(`${this.baseUrl}/${id}/delete`, null);
  }
}
