<div #serviceForEdit></div>
<form [formGroup]="formGroup" #formDirective="ngForm">
  <div class="row">
    <div class="col-md-12 col-xl-6">
      <app-autocomplete label="Service" formControlName="serviceTypeId" [options]="sasOptions"> </app-autocomplete>
      <app-compare-amendment-output
        *ngIf="amendingService"
        class="mr-1"
        [amendmentId]="amendment?.id"
        [amendmentFinalized]="amendmentIsFinalized"
        [amendmentDate]="lastFinalizedDate(amendingService?.amendmentId)"
        [priorVersionId]="amendingService?.priorVersionId"
        [isActive]="amendingService.isActive"
        [changedVersionText]="getServiceTypeLabel(formGroup.get('serviceTypeId').value)"
        [currentVersionText]="getServiceTypeLabel(amendingService?.priorVersion?.serviceTypeId)"
        [currentPrefixText]="amendingService?.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      >
      </app-compare-amendment-output>

      <div class="mt-2" *ngIf="showParaprofessionalSupportQuestions">
        <label>Select the type(s) of paraprofessional support</label>
        <app-checkbox controlName="paraprofessionalSupports" [options]="paraprofessionalSupportOptions"></app-checkbox>

        <ng-container *ngIf="hasParaprofessionalSupportBehaviorSafety">
          <fieldset>
            <app-radio-group
              label="Does the learner have a Behavior Intervention Plan?"
              formControlName="hasBehaviorInterventionPlan"
              [options]="yesNoOptions"
            ></app-radio-group>
          </fieldset>
          <app-compare-amendment-output
            *ngIf="amendingService"
            class="mr-1"
            [amendmentId]="amendment?.id"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate(amendingService?.amendmentId)"
            [priorVersionId]="amendingService?.priorVersionId"
            [isActive]="amendingService.isActive"
            [changedVersionText]="formGroup.get('hasBehaviorInterventionPlan').value | boolToYesNo"
            [currentVersionText]="amendingService?.priorVersion?.hasBehaviorInterventionPlan | boolToYesNo"
            [currentPrefixText]="amendingService?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </ng-container>

        <ng-container *ngIf="hasParaprofessionalSupportHealth">
          <fieldset>
            <app-radio-group
              label="Does the learner have a Health Care Plan?"
              formControlName="hasHealthCarePlan"
              [options]="yesNoOptions"
            ></app-radio-group>
          </fieldset>
          <app-compare-amendment-output
            *ngIf="amendingService"
            class="mr-1"
            [amendmentId]="amendment?.id"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate(amendingService?.amendmentId)"
            [priorVersionId]="amendingService?.priorVersionId"
            [isActive]="amendingService.isActive"
            [changedVersionText]="formGroup.get('hasHealthCarePlan').value | boolToYesNo"
            [currentVersionText]="amendingService?.priorVersion?.hasHealthCarePlan | boolToYesNo"
            [currentPrefixText]="amendingService?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          ></app-compare-amendment-output>
        </ng-container>
      </div>

      <ng-container *ngIf="showDirectConsultQuestions">
        <fieldset>
          <app-radio-group label="Service Method" formControlName="directConsult" [options]="directConsultOptions"></app-radio-group>
        </fieldset>
        <app-compare-amendment-output
          *ngIf="amendingService"
          class="mr-1"
          [amendmentId]="amendment?.id"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate(amendingService?.amendmentId)"
          [priorVersionId]="amendingService?.priorVersionId"
          [isActive]="amendingService.isActive"
          [changedVersionText]="formGroup.get('directConsult').value"
          [currentVersionText]="amendingService?.priorVersion?.directConsult"
          [currentPrefixText]="amendingService?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </ng-container>

      <ng-container *ngIf="showInstructionalSupportTypeQuestions">
        <fieldset>
          <app-radio-group
            label="Select the type(s) of instructional support"
            formControlName="instructionalSupportType"
            [options]="instructionalSupportTypeOptions"
          ></app-radio-group>
        </fieldset>
        <app-compare-amendment-output
          *ngIf="amendingService"
          class="mr-1"
          [amendmentId]="amendment?.id"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate(amendingService?.amendmentId)"
          [priorVersionId]="amendingService?.priorVersionId"
          [isActive]="amendingService.isActive"
          [changedVersionText]="getInstructionalSupportTypeLabel(formGroup.get('instructionalSupportType').value)"
          [currentVersionText]="getInstructionalSupportTypeLabel(amendingService?.priorVersion?.instructionalSupportType)"
          [currentPrefixText]="amendingService?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </ng-container>

      <ng-container *ngIf="showTransportationServiceQuestions">
        <app-autocomplete-chips
          formControlName="transportationServices"
          [options]="transportationServiceOptions"
          label="Select the type(s) of transportation services"
        >
        </app-autocomplete-chips>
        <app-compare-amendment-output
          *ngIf="amendingService"
          class="mr-1"
          [amendmentId]="amendment?.id"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate(amendingService?.amendmentId)"
          [priorVersionId]="amendingService?.priorVersionId"
          [isActive]="amendingService.isActive"
          [changedVersionText]="getTransportationServicesLabel(formGroup.get('transportationServices').value)"
          [currentVersionText]="amendingService?.priorVersion?.transportationServices"
          [currentPrefixText]="amendingService?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>

        <app-textarea
          *ngIf="hasTransportationServicesOther"
          label="Please describe transportation services"
          formControlName="transportationServicesDescription"
          [isBankableField]="true"
          [bankableFieldId]="bankableFieldIds.iepSsaaServices.transportationServicesDescription"
        >
        </app-textarea>
        <app-compare-amendment-output
          *ngIf="amendingService && hasTransportationServicesOther"
          class="mr-1"
          [amendmentId]="amendment?.id"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate(amendingService?.amendmentId)"
          [priorVersionId]="amendingService?.priorVersionId"
          [isActive]="amendingService.isActive"
          [changedVersionText]="formGroup.get('transportationServicesDescription').value"
          [currentVersionText]="amendingService?.priorVersion?.transportationServicesDescription"
          [currentPrefixText]="amendingService?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
        <fieldset>
          <app-radio-group
            *ngIf="hasTransportationServicesAttendant"
            label="Attendant service Type"
            formControlName="instructionalSupportType"
            [options]="instructionalSupportTypeOptions"
          ></app-radio-group>
        </fieldset>
        <app-compare-amendment-output
          *ngIf="amendingService && hasTransportationServicesAttendant"
          class="mr-1"
          [amendmentId]="amendment?.id"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate(amendingService?.amendmentId)"
          [priorVersionId]="amendingService?.priorVersionId"
          [isActive]="amendingService.isActive"
          [changedVersionText]="formGroup.get('instructionalSupportType').value"
          [currentVersionText]="amendingService?.priorVersion?.instructionalSupportType"
          [currentPrefixText]="amendingService?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </ng-container>
    </div>
    <div class="col-md-12 col-xl-6">
      <app-textarea
        label="Description"
        formControlName="description"
        [isBankableField]="true"
        [bankableFieldId]="bankableFieldIds.iepSsaaServices.description"
      ></app-textarea>
      <app-compare-amendment-output
        *ngIf="amendingService"
        class="mr-1"
        [amendmentId]="amendment?.id"
        [amendmentFinalized]="amendmentIsFinalized"
        [amendmentDate]="lastFinalizedDate(amendingService?.amendmentId)"
        [priorVersionId]="amendingService?.priorVersionId"
        [isActive]="amendingService.isActive"
        [changedVersionText]="formGroup.get('description').value"
        [currentVersionText]="amendingService?.priorVersion?.description"
        [currentPrefixText]="amendingService.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      >
      </app-compare-amendment-output>
    </div>
  </div>

  <div class="row align-items-baseline">
    <div class="col-lg-12">
      <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-2">
          <span class="mr-3">Frequency</span>
          <app-number-field formControlName="frequencyNumber" class="input--small" [min]="0" id="frequencyNumber"></app-number-field>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-3">
          <fieldset>
            <app-radio-group
              label="Increments"
              formControlName="frequencyIncrement"
              [options]="frequencyIncrementOptions"
              [useUndo]="false"
            ></app-radio-group>
          </fieldset>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-3">
          <app-autocomplete label="How Often" formControlName="frequencyPeriod" [options]="frequencyPeriodOptions"> </app-autocomplete>
        </div>
        <div class="col-md-12">
          <app-compare-amendment-output
            *ngIf="amendingService"
            class="mr-1"
            [amendmentId]="amendment?.id"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate(amendingService?.amendmentId)"
            [priorVersionId]="amendingService?.priorVersionId"
            [isActive]="amendingService.isActive"
            [changedVersionText]="
              getFrequencyOptionLabel(
                formGroup.get('frequencyNumber').value,
                formGroup.get('frequencyIncrement').value,
                formGroup.get('frequencyPeriod').value
              )
            "
            [currentVersionText]="
              getFrequencyOptionLabel(
                amendingService?.priorVersion?.frequencyNumber,
                amendingService?.priorVersion?.frequencyIncrement,
                amendingService?.priorVersion?.frequencyPeriod
              )
            "
            [currentPrefixText]="amendingService?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          >
          </app-compare-amendment-output>
        </div>
        <div *ngIf="!disableAmountRemoved" class="col-md-12">
          <div class="display-flex align-items-baseline">
            <span class="mr-3" [ngClass]="{ ' text-muted': disableAmountRemoved }">
              {{
                isPK
                  ? 'Amount removed from General Education and/or from Regular Early Childhood Program'
                  : 'Amount Removed from General Education'
              }}
            </span>
            <app-number-field
              formControlName="amountRemovedFromGeneralEducation"
              class="input--small"
              [min]="0"
              [disabledState]="disableAmountRemoved"
              id="amountRemovedFromGeneralEducation"
            ></app-number-field>
            <span class="ml-2" [ngClass]="{ ' text-muted': disableAmountRemoved }">
              {{ formGroup.get('frequencyIncrement').value }}
              {{ formGroup.get('frequencyPeriod').value }}
            </span>
          </div>
        </div>
        <div class="col-md-12" *ngIf="amendingService">
          <app-compare-amendment-output
            class="mr-1"
            [amendmentId]="amendment?.id"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate(amendingService?.amendmentId)"
            [priorVersionId]="amendingService?.priorVersionId"
            [isActive]="amendingService.isActive"
            [changedVersionText]="
              getAmountRemovedText(
                formGroup.get('amountRemovedFromGeneralEducation').value,
                formGroup.get('frequencyIncrement').value,
                formGroup.get('frequencyPeriod').value
              )
            "
            [currentVersionText]="
              getAmountRemovedText(
                amendingService?.priorVersion?.amountRemovedFromGeneralEducation,
                amendingService?.priorVersion?.frequencyIncrement,
                amendingService?.priorVersion?.frequencyPeriod
              )
            "
            [currentPrefixText]="amendingService?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          >
          </app-compare-amendment-output>
        </div>
      </div>
    </div>

    <div class="col-lg-12 col-xl-4"></div>
  </div>

  <div class="row" *ngIf="showServiceDates && !startingKDuringIep">
    <div class="col-md-12 col-xl-6">
      <app-date-picker
        label="Start Date"
        controlName="projectedStartDate"
        [min]="minProjectedStartDate"
        [max]="maxProjectedEndDate"
      ></app-date-picker>
      <app-compare-amendment-output
        *ngIf="amendingService"
        class="mr-1"
        [amendmentId]="amendment?.id"
        [amendmentFinalized]="amendmentIsFinalized"
        [amendmentDate]="lastFinalizedDate(amendingService?.amendmentId)"
        [priorVersionId]="amendingService.priorVersionId"
        [isActive]="amendingService.isActive"
        [changedVersionText]="formGroup.get('projectedStartDate').value | dateFormat"
        [currentVersionText]="amendingService?.priorVersion?.projectedStartDate | dateFormat"
        [currentPrefixText]="amendingService?.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      >
      </app-compare-amendment-output>
    </div>
    <div class="col-md-12 col-xl-6">
      <app-date-picker
        label="End Date"
        controlName="projectedEndDate"
        [min]="minProjectedEndDate"
        [max]="maxProjectedEndDate"
      ></app-date-picker>
      <app-compare-amendment-output
        *ngIf="amendingService"
        class="mr-1"
        [amendmentId]="amendment?.id"
        [amendmentFinalized]="amendmentIsFinalized"
        [amendmentDate]="lastFinalizedDate(amendingService?.amendmentId)"
        [priorVersionId]="amendingService.priorVersionId"
        [isActive]="amendingService.isActive"
        [changedVersionText]="formGroup.get('projectedEndDate').value | dateFormat"
        [currentVersionText]="amendingService?.priorVersion?.projectedEndDate | dateFormat"
        [currentPrefixText]="amendingService?.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      >
      </app-compare-amendment-output>
    </div>
  </div>

  <div class="row" *ngIf="startingKDuringIep">
    <div class="col-md-12 col-xl-6">
      <fieldset>
        <app-radio-group
          [options]="customDateOptions"
          formControlName="customServiceDate"
          label="Select the grade(s) the learner will be in when this service is provided"
        ></app-radio-group>
      </fieldset>
      <app-compare-amendment-output
        *ngIf="amendingService"
        class="mr-1"
        [amendmentId]="amendment?.id"
        [amendmentFinalized]="amendmentIsFinalized"
        [amendmentDate]="lastFinalizedDate(amendingService?.amendmentId)"
        [priorVersionId]="amendingService.priorVersionId"
        [isActive]="amendingService.isActive"
        [changedVersionText]="formGroup.get('customServiceDate').value"
        [currentVersionText]="amendingService.priorVersion?.customServiceDate"
        [currentPrefixText]="amendingService?.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      >
      </app-compare-amendment-output>
    </div>
  </div>

  <div class="row">
    <div class="col-md-6">
      <app-autocomplete-chips formControlName="providers" [options]="providerOptions" label="Providers"> </app-autocomplete-chips>
      <app-compare-amendment-output
        *ngIf="amendingService"
        class="mr-1"
        [amendmentId]="amendment?.id"
        [amendmentFinalized]="amendmentIsFinalized"
        [amendmentDate]="lastFinalizedDate(amendingService?.amendmentId)"
        [priorVersionId]="amendingService.priorVersionId"
        [isActive]="amendingService.isActive"
        [changedVersionText]="getProviderLabels(formGroup.get('providers').value)"
        [currentVersionText]="getPriorProviderLabels(amendingService?.priorVersion?.providers)"
        [currentPrefixText]="amendingService?.priorVersion ? 'Current: ' : ''"
        changedPrefixText="Proposed: "
      >
      </app-compare-amendment-output>
      <app-checkbox-single
        class="mt-2 mb-2"
        formControlName="otherProvider"
        aria-labelledby="Provider is outside of ACHIEVE"
        label=" Provider is outside of ACHIEVE"
      >
      </app-checkbox-single>
      <ng-container *ngIf="formGroup.get('otherProvider').value">
        <app-text-field label="Provider Name" formControlName="otherProviderName"></app-text-field>
        <app-compare-amendment-output
          *ngIf="amendingService"
          [amendmentId]="amendingService.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate(amendingService?.amendmentId)"
          [priorVersionId]="amendingService.priorVersionId"
          [isActive]="service?.isActive"
          [changedVersionText]="formGroup.get('otherProviderName').value"
          [currentVersionText]="service?.priorVersion ? service?.priorVersion?.otherProviderName : null"
          [currentPrefixText]="amendingService?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
        <app-text-field label="Provider Agency" formControlName="otherProviderAgency"></app-text-field>
        <app-compare-amendment-output
          *ngIf="amendingService"
          [amendmentId]="amendingService.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate(amendingService?.amendmentId)"
          [priorVersionId]="amendingService.priorVersionId"
          [isActive]="service?.isActive"
          [changedVersionText]="formGroup.get('otherProviderAgency').value"
          [currentVersionText]="service?.priorVersion ? service?.priorVersion?.otherProviderAgency : null"
          [currentPrefixText]="amendingService?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
        <app-text-field label="Provider Role" formControlName="otherProviderRole"></app-text-field>
        <app-compare-amendment-output
          *ngIf="amendingService"
          [amendmentId]="amendingService.amendmentId"
          [amendmentFinalized]="amendmentIsFinalized"
          [amendmentDate]="lastFinalizedDate(amendingService?.amendmentId)"
          [priorVersionId]="amendingService.priorVersionId"
          [isActive]="service?.isActive"
          [changedVersionText]="formGroup.get('otherProviderRole').value"
          [currentVersionText]="service?.priorVersion ? service?.priorVersion?.otherProviderRole : null"
          [currentPrefixText]="amendingService?.priorVersion ? 'Current: ' : ''"
          changedPrefixText="Proposed: "
        ></app-compare-amendment-output>
      </ng-container>
    </div>

    <div *ngIf="formGroup.controls.providers?.value?.length > 0 && requireAgency" class="col-md-6">
      <app-autocomplete label="Agency" formControlName="agencyId" [options]="agencyOptions"> </app-autocomplete>
    </div>
  </div>

  <div *ngIf="iepIncludesTrialPlacement" class="row">
    <div class="col-md-12">
      <app-checkbox-single
        class="display-inline-block"
        formControlName="includesTrialPlacement"
        aria-labelledby="Include in Trial Placement"
        label="Include in Trial Placement"
      >
      </app-checkbox-single>
    </div>
  </div>

  <div class="action__row action__row--between">
    <div>
      <button
        *ngIf="!formGroup.controls.taggedForPwn?.value"
        class="ml-1"
        mat-raised-button
        aria-label="Tag for Prior Written Notice Button"
        (click)="tag(true)"
        tabindex="0"
      >
        Tag for Prior Written Notice
      </button>
      <button
        *ngIf="formGroup.controls.taggedForPwn?.value"
        mat-raised-button
        aria-label="Untag for Prior Written Notice"
        color="primary"
        (click)="tag(false)"
        tabindex="0"
      >
        Untag for Prior Written Notice
      </button>
    </div>

    <div>
      <button
        *ngIf="!hasAmendment"
        type="button"
        mat-raised-button
        aria-label="View Summary"
        color="accent"
        (click)="viewSummary()"
        tabindex="0"
      >
        View Summary
      </button>
      <button
        mat-raised-button
        aria-label="Cancel Button"
        color="warn"
        (click)="onCancel()"
        [disabled]="!serviceId"
        *ngIf="hasAmendment || hasPriorVersion"
        type="button"
        class="ml-2"
        tabindex="0"
      >
        Cancel
        <mat-icon class="ml-2" aria-label="Cancel Button">close</mat-icon>
      </button>
      <button
        mat-raised-button
        aria-label="Delete"
        color="warn"
        (click)="onDelete()"
        [disabled]="!serviceId"
        *ngIf="!hasAmendment"
        type="button"
        class="ml-2"
        [disableAfterBusy]="true"
        [isBusy]="activeCall"
        tabindex="0"
      >
        Delete
        <mat-icon class="ml-2" aria-label="Delete">delete</mat-icon>
      </button>
      <button
        mat-raised-button
        aria-label="Save Icon"
        class="ml-2"
        color="primary"
        (click)="saveAndClose()"
        [disabled]="!serviceId"
        [disableAfterBusy]="!serviceId"
        [isBusy]="activeCall"
        tabindex="0"
      >
        Save
      </button>
      <button
        *ngIf="!isGoalsPage && !hasAmendment"
        mat-raised-button
        aria-label="Complete and Add Goal"
        color="primary"
        class="ml-2"
        [disabled]="formGroup.invalid"
        [disableAfterBusy]="formGroup.invalid"
        [isBusy]="activeCall"
        (click)="submitAndAddGoal()"
        tabindex="0"
      >
        Complete and Add Goal
      </button>
      <button
        class="ml-2"
        mat-raised-button
        aria-label="Complete"
        color="primary"
        [disabled]="formGroup.invalid"
        [disableAfterBusy]="formGroup.invalid"
        [isBusy]="activeCall"
        (click)="submit()"
        tabindex="0"
      >
        Complete
      </button>
    </div>
  </div>
</form>
<ng-container *ngIf="!hasAmendment">
  <hr class="input__divider" />

  <h3 class="mt-0 mb-2">Services List</h3>
  <mat-card class="my-0">
    <app-iep-services-table
      #servicelist
      [isGoalsPage]="isGoalsPage"
      (editService)="onEditService($event)"
      [iepId]="iepId"
      [isTransitionPK]="caseSummary?.activeIepIsPKToK"
      [amendmentId]="amendmentId"
      [amendments]="amendments"
      [iepIncludesTrialPlacement]="iepIncludesTrialPlacement"
      [trialPlacementTarget]="trialPlacementTarget"
      [isEditing]="!!this.service"
    ></app-iep-services-table>
  </mat-card>
</ng-container>
