<div class="row">
  <div class="col">
    <h3 class="mb-2">Learner Information</h3>
  </div>
</div>
<div class="print__container">
  <div class="display-flex flex-wrap">
    <p *ngIf="learner?.stateAssignedId"><strong>State ID: </strong> {{ learner?.stateAssignedId }}</p>

    <p *ngIf="learner?.firstName && learner?.lastName">
      <strong>Learner's Name:</strong> {{ learner?.firstName }}
      {{ learner?.lastName }}
    </p>

    <p *ngIf="learner?.dateOfBirth"><strong>DOB:</strong> {{ learner?.dateOfBirth | date : shortDateFormat }}</p>

    <p *ngIf="learner?.gender">
      <strong>Gender:</strong>
      {{ learner?.gender || 'N/A' }}
    </p>

    <p *ngIf="learner?.residentDistrict">
      <strong>Resident School District:</strong>
      {{ learner?.residentDistrict || 'N/A' }}
    </p>

    <p *ngIf="learner?.attendingDistrict">
      <strong>Attending School District:</strong>
      {{ learner?.attendingDistrict || 'N/A' }}
    </p>

    <p>
      <strong>Building:</strong>
    </p>

    <p><strong>Grade:</strong> {{ learner?.gradeDescription }}</p>
  </div>
</div>
