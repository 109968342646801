import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { forkJoin, Subscription } from 'rxjs';
import { Referral } from 'src/app/child-find/early-access-referral/referral';
import { IntakeSections } from 'src/app/evaluation/early-access-intake/intake-sections/intake-sections';
import { EarlyAccessQuestionnaire } from 'src/app/evaluation/models/early-access-questionnaire';
import { Intake } from 'src/app/evaluation/models/intake';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { usStates } from 'src/app/shared/models/us-states';
import { DistrictService } from 'src/app/shared/services/district/district.service';
import { GenderService } from 'src/app/shared/services/gender/gender.service';
import { LanguageService } from 'src/app/shared/services/language/language.service';
import { LocationService } from 'src/app/shared/services/location/location.service';
import { RaceService } from 'src/app/shared/services/race/race.service';
import { noNumbersLimitedSpecialCharsValidator, noNumbersValidator, tooOld, zipCodeValidator } from 'src/app/shared/validators';
import { AuthService } from '../../../../auth/auth.service';
import { ChildInformation } from '../../models/child-information';
import { DatabaseLinksService } from '../../../../shared/services/database-links/database-links.service';

@Component({
  selector: 'app-child-info-form',
  templateUrl: './child-info-form.component.html',
  styleUrls: ['./child-info-form.component.scss'],
})
export class ChildInfoFormComponent implements OnInit, AfterContentChecked, OnChanges, OnDestroy {
  private subscription: Subscription = new Subscription();
  @ViewChild('tooOld') tooOldWarning: TemplateRef<any>;

  @Input() formGroup: FormGroup;
  @Input() childInfo: ChildInformation;
  @Input() referral: Referral;
  @Input() questionnaire: EarlyAccessQuestionnaire;
  @Input() isQuestionnaire: boolean;
  @Input() questionnaireLocked: boolean;
  @Input() documentationInfo: FormGroup;
  @Input() intake: Intake;
  @Input() intakeLocked: boolean;
  @Input() importQuestionnaireSelected: boolean;
  @Output() upload = new EventEmitter();
  @Output() deleteDocument = new EventEmitter();
  @Output() formInitialized = new EventEmitter();
  formInitializationFlags = {
    controlsInitialized: false,
    dataLoaded: false,
    eventEmitted: false,
  };

  intakeSections = IntakeSections;
  locked = false;
  shownFields = {
    genderId: true,
    languageSpokenInHomeId: true,
    interpreterNeeded: true,
    streetAddress: true,
    city: true,
    state: true,
    zipCode: true,
    county: true,
    attendsChildCare: true,
    childCareProviderName: true,
    childCareProviderStreetAddress: true,
    childCareProviderCity: true,
    childCareProviderState: true,
    childCareProviderZipCode: true,
  };

  usStates = usStates.map((x) => new KeyValuePair(x, x));
  genderOptions: KeyValuePair[] = [];
  languageOptions: KeyValuePair[] = [];
  districtOptions: KeyValuePair[] = [];
  raceOptions: KeyValuePair[] = [];
  today = new Date();
  threeYearsAgo = dayjs(this.today).subtract(3, 'year').toDate();
  twentyFiveYearsAgo = dayjs(this.today).subtract(25, 'year').toDate();
  showChildCare = false;

  yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];

  determinationOptions: KeyValuePair[] = [new KeyValuePair('Parent', 'Parent'), new KeyValuePair('Observer', 'Observer')];

  caseId: string;

  constructor(
    private locationService: LocationService,
    private genderService: GenderService,
    private languageService: LanguageService,
    private districtService: DistrictService,
    private dialog: MatDialog,
    private raceService: RaceService,
    private route: ActivatedRoute,
    private readonly cdref: ChangeDetectorRef,
    private readonly databaseLinksService: DatabaseLinksService,
    private authService: AuthService
  ) {}

  get canEditLearnerDobAndName() {
    return this.authService.isSuperAdmin || this.authService.isAchieveDataLead;
  }

  ngOnInit() {
    this.caseId = this.route.snapshot.paramMap.get('caseId');
    if (this.isQuestionnaire) {
      this.showChildCare = !this.questionnaire.referral?.childInfo.attendsChildCare && !this.questionnaire.inviteIsReferrer;
    }

    this.initializeControls();
    this.setLock();
    this.loadData();
    this.monitorForValidDateOfBirth();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (
      changes.importQuestionnaireSelected?.currentValue !== changes.importQuestionnaireSelected?.previousValue &&
      this.importQuestionnaireSelected
    ) {
      this.importChildInfoFromQuestionnaire();
      this.formGroup.markAsDirty();
    }
  }

  importChildInfoFromQuestionnaire() {
    const questionnaireChildInfo = this.questionnaire?.childInfo;
    for (const prop in questionnaireChildInfo) {
      if (questionnaireChildInfo[prop] !== null && questionnaireChildInfo[prop] !== '') {
        this.childInfo[prop] = questionnaireChildInfo[prop];
      }
    }
    this.formGroup.patchValue(this.childInfo);
  }

  private loadData() {
    forkJoin([
      this.languageService.getLanguages(),
      this.genderService.get(),
      this.districtService.getDistricts(),
      this.raceService.getRaces(),
    ]).subscribe(([languages, genders, districts, races]) => {
      this.languageOptions = languages.map((x) => new KeyValuePair(x.id, x.label));
      this.genderOptions = genders.map((x) => new KeyValuePair(x.id, x.label));
      this.districtOptions = districts.map((x) => new KeyValuePair(x.id, x.name));
      this.raceOptions = races.map((x) => new KeyValuePair(x.id, x.label));

      setTimeout(() => this.updateForm(), 0);
    });
  }

  private initializeControls() {
    this.formGroup.addControl('firstName', new FormControl('', [Validators.required, noNumbersValidator]));
    this.formGroup.addControl('middleName', new FormControl('', noNumbersValidator));
    this.formGroup.addControl('lastName', new FormControl('', [Validators.required, noNumbersValidator]));

    this.formGroup.addControl('dateOfBirth', new FormControl('', [Validators.required, tooOld(3)]));

    this.formGroup.addControl('genderId', new FormControl(null, { updateOn: 'change' }));

    this.formGroup.addControl('languageSpokenInHomeId', new FormControl('', this.isQuestionnaire ? [] : [Validators.required]));

    this.formGroup.addControl(
      'interpreterNeeded',
      new FormControl('', {
        updateOn: 'change',
        validators: this.isQuestionnaire ? [] : [Validators.required],
      })
    );

    this.formGroup.addControl('streetAddress', new FormControl('', this.isQuestionnaire ? [] : [Validators.required]));

    this.formGroup.addControl(
      'city',
      new FormControl(
        '',
        this.isQuestionnaire ? [noNumbersLimitedSpecialCharsValidator] : [Validators.required, noNumbersLimitedSpecialCharsValidator]
      )
    );

    this.formGroup.addControl('state', new FormControl('', this.isQuestionnaire ? [] : [Validators.required]));

    this.formGroup.addControl(
      'zipCode',
      new FormControl(
        '',
        this.isQuestionnaire
          ? [Validators.pattern(/(^\d{5}$)|(^\d{5}-\d{4}$)/)]
          : [Validators.required, Validators.pattern(/(^\d{5}$)|(^\d{5}-\d{4}$)/)]
      )
    );

    this.formGroup.addControl('county', new FormControl('', [noNumbersLimitedSpecialCharsValidator]));
    this.formGroup.addControl('aeaId', new FormControl(null));
    if (this.isQuestionnaire) {
      this.formGroup.addControl('attendsChildCare', new FormControl('', { updateOn: 'change' }));
      this.formGroup.addControl('childCareProviderName', new FormControl(''));
      this.formGroup.addControl('childCareProviderStreetAddress', new FormControl(''));
      this.formGroup.addControl('childCareProviderCity', new FormControl('', [noNumbersLimitedSpecialCharsValidator]));
      this.formGroup.addControl('childCareProviderState', new FormControl(''));
      this.formGroup.addControl('childCareProviderZipCode', new FormControl('', [zipCodeValidator]));
    }
    if (!this.isQuestionnaire) {
      this.formGroup.addControl('childResidentDistrictId', new FormControl('', [Validators.required]));
      this.formGroup.addControl('childAttendingDistrictId', new FormControl('', [Validators.required]));
      this.formGroup.addControl('races', new FormControl({ value: null, disabled: true }, Validators.required));
      this.formGroup.addControl(
        'raceDeterminedBy',
        new FormControl(null, {
          updateOn: 'change',
          validators: [Validators.required],
        })
      );
      // PBI 22545 - Remove Hispanic/Latino questions on intake form
      //this.formGroup.addControl(
      //  'hispanicLatino',
      //  new FormControl(null, {
      //    updateOn: 'change',
      //    validators: [Validators.required],
      //  })
      //);
      //this.formGroup.addControl(
      //  'hispanicLatinoDeterminedBy',
      //  new FormControl(null, {
      //    updateOn: 'change',
      //    validators: [Validators.required],
      //  })
      //);
    }
    this.formInitializationFlags.controlsInitialized = true;
    this.evaluateFormInitialization();
  }

  private updateForm() {
    this.formGroup.patchValue(this.childInfo);
    if (this.isQuestionnaire) {
      this.updateFieldsValueAndVisibility();
    } else {
      this.formGroup.get('races').markAsPristine();
      this.formGroup.get('races').markAsUntouched();
    }
    this.formInitializationFlags.dataLoaded = true;
    this.evaluateFormInitialization();
  }

  private updateFieldsValueAndVisibility() {
    const referral = this.questionnaire.referral;
    /* If the questionnaire respondent is the same as the referral source,
       the API provides us with the referral record so we can hide certain fields. */
    if (referral) {
      if (referral.childInfo?.gender) {
        this.setControlToNull('genderId');
      }
      if (referral.childInfo?.languageSpokenInHome) {
        this.setControlToNull('languageSpokenInHomeId');
      }
      if (typeof referral.childInfo?.interpreterNeeded === 'boolean') {
        this.setControlToNull('interpreterNeeded');
      }
      if (referral.childInfo?.streetAddress) {
        this.setControlToNull('streetAddress');
      }
      if (referral.childInfo?.city) {
        this.setControlToNull('city');
      }
      if (referral.childInfo?.state) {
        this.setControlToNull('state');
      }
      if (referral.childInfo?.zipCode) {
        this.setControlToNull('zipCode');
      }
      if (referral.childInfo?.county) {
        this.setControlToNull('county');
      }
      if (typeof this.childInfo?.attendsChildCare === 'boolean') {
        this.setControlToNull('attendsChildCare');
      }
      if (referral.childInfo?.childCareProviderName) {
        this.setControlToNull('childCareProviderName');
      }
      if (referral.childInfo?.childCareProviderStreetAddress) {
        this.setControlToNull('childCareProviderStreetAddress');
      }
      if (referral.childInfo?.childCareProviderCity) {
        this.setControlToNull('childCareProviderCity');
      }
      if (referral.childInfo?.childCareProviderState) {
        this.setControlToNull('childCareProviderState');
      }
      if (referral.childInfo?.childCareProviderZipCode) {
        this.setControlToNull('childCareProviderZipCode');
      }
    }
  }

  private setControlToNull(controlName: string) {
    this.shownFields[controlName] = false;
    this.formGroup.controls[controlName].setValue(null);
    this.formGroup.controls[controlName].setValidators(null);
    this.formGroup.controls[controlName].updateValueAndValidity();
  }

  setLock() {
    this.locked = this.isQuestionnaire ? this.questionnaireLocked : this.intakeLocked;

    if (this.locked && !this.isQuestionnaire) {
      this.formGroup.controls.races.disable();
      this.formGroup.controls.races.updateValueAndValidity();
    }
  }

  ngAfterContentChecked(): void {
    this.cdref.detectChanges();
  }

  openIowaFamilySupportNetworkUrl() {
    window.open(this.databaseLinksService.getDatabaseLink('iowaFamilySupportNetwork'), '_blank');
  }

  private monitorForValidDateOfBirth() {
    const dobControl = this.formGroup.controls.dateOfBirth;
    this.subscription.add(
      dobControl.valueChanges.subscribe((dob: string) => {
        if (this.formGroup.controls.dateOfBirth.getError('tooOld')) {
          this.dialog
            .open(this.tooOldWarning, { width: '728px' })
            .afterClosed()
            .subscribe(() => {
              dobControl.setValue('');
            });
        }
      })
    );
  }

  private evaluateFormInitialization() {
    if (this.formInitializationFlags.eventEmitted) {
      return;
    }
    if (this.formInitializationFlags.controlsInitialized && this.formInitializationFlags.dataLoaded) {
      this.formInitializationFlags.eventEmitted = true;
      this.formInitialized.emit();
    }
  }

  populateLocationFromZipCode(event: any) {
    const zipCodeControl = this.formGroup.get('zipCode');
    const zipCode = zipCodeControl.value;
    if (!zipCode || !zipCodeControl.valid) {
      return;
    }

    this.locationService.getLocationData(zipCode.substring(0, 5)).subscribe(
      (res) => {
        if (res) {
          this.formGroup.patchValue({
            aeaId: res.aeaId,
            city: res.city,
            county: res.county,
            state: 'IA',
          });
        }
      },
      () => {
        this.formGroup.patchValue({
          aeaId: null,
          city: null,
          county: null,
          state: null,
        });
      }
    );
  }

  populateChildcareLocationFromZipCode() {
    const zipCodeControl = this.formGroup.get('childCareProviderZipCode');
    const zipCode = zipCodeControl.value;

    if (!zipCode || !zipCodeControl.valid) {
      return;
    }

    this.locationService.getLocationData(zipCode.substring(0, 5)).subscribe((res) => {
      if (res) {
        this.formGroup.patchValue({
          childCareProviderCity: res.city,
          childCareProviderState: 'IA',
        });
      }
    });
  }

  onUpload(formData: FormData) {
    this.upload.emit(formData);
  }

  onDeleteDocument(documentId: string) {
    this.deleteDocument.emit(documentId);
  }
}
