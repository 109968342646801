import { HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { of } from 'rxjs';
import { tap } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { AppPermissions } from 'src/app/permissions';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { NotifySave } from 'src/app/shared/decorators/notify-save.decorator';
import { CaseSummary, ExitCaseCodes, ExitCaseInfo } from 'src/app/shared/models/case';
import { PriorWrittenNoticeType } from 'src/app/shared/models/prior-written-notices/early-access-prior-written-notice';
import { PriorWrittenNotice } from 'src/app/shared/models/prior-written-notices/prior-written-notice';
import { CaseService } from 'src/app/shared/services/case/case.service';
import { LoggerService } from 'src/app/shared/services/logger/logger.service';
import { ConfirmationDialogComponent, NotificationService } from 'src/app/shared/services/notification.service';
import { PriorWrittenNoticeService } from 'src/app/shared/services/prior-written-notices/prior-written-notice.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { AppEvents, AutosavingStates } from '../../shared/app-events';
import { TeamUser } from '../../shared/models/team';
import { DisabilitySuspectForm } from './models/disability-suspect-form';
import { DisabilitySuspectService } from './services/disability-suspect.service';

@Component({
  selector: 'app-disability-suspect',
  templateUrl: './disability-suspect.component.html',
  styleUrls: ['./disability-suspect.component.scss'],
})
export class DisabilitySuspectComponent implements OnInit, OnDestroy {
  id: string;
  dsForm: DisabilitySuspectForm;
  isSubmitEnabled: boolean;
  justSubmitted = false;
  shortDateFormat = shortDateFormat;
  caseSummary: CaseSummary;
  pageTitle: string;
  isPopup: boolean;
  activeCall = false;
  isSaving = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private routingService: RoutingService,
    private dsService: DisabilitySuspectService,
    private pwnService: PriorWrittenNoticeService,
    private caseService: CaseService,
    private loggerService: LoggerService,
    private dialog: MatDialog,
    private notificationService: NotificationService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    this.isPopup = this.route.snapshot.root.queryParams.popup;
    this.getDsForm();
  }

  ngOnDestroy() {
    AppEvents.autosaving.emit(AutosavingStates.Off);
  }

  onSubmit(formData: FormGroup) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '728px',
      data: {
        title: 'Finalize Disability Suspected',
        message: 'Are you sure you want to finalize?  This action cannot be undone and you will be unable to edit this form further.',
        buttons: { cancel: 'No', ok: 'Yes' },
      },
    });
    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.activeCall = true;
        if (
          this.dsForm.diagnosisOfCondition === false &&
          this.dsForm.uniqueComparedToPeers === false &&
          this.dsForm.progressionStandards === false &&
          this.dsForm.requiresContinuedEffort === false
        ) {
          this.caseService.getExitReasons().subscribe((response) => {
            const dsExitCode = response.value.find((x) => x.value.includes(ExitCaseCodes.NDS))?.key;

            const exitInfo = {
              exiting: true,
              exitingDate: new Date(),
              exitReasonId: dsExitCode,
              childFindExitReasonId: dsExitCode,
              isSystemExit: true,
            } as ExitCaseInfo;

            this.caseService.updateExitingInfo(this.caseSummary.id, exitInfo).subscribe(() => {
              this.activeCall = false;
            });
          });
        }

        this.saveForm(formData, true).subscribe(
          () => {
            this.justSubmitted = true;
          },
          (error) => {
            console.log(error);
            this.activeCall = false;
            AppEvents.autosaving.emit(AutosavingStates.Failed);
          }
        );
      }
    });
  }

  getCaseSummary() {
    this.caseService.getCaseSummary(this.dsForm.caseId).subscribe((res) => {
      this.caseSummary = res;
      this.pageTitle = 'Disability Suspected - ' + res.learner.fullName;
    });
  }

  get isReadOnly() {
    const readOnly =
      this.dsForm?.isSubmitted ||
      (!this.authService.isAllowedByCaseId(this.dsForm?.caseId, undefined, AppPermissions.CompleteDisabilitySuspect) &&
        !this.authService.isAllowedByCaseId(this.dsForm?.caseId, undefined, AppPermissions.EditDsForm));
    return readOnly;
  }

  get oneYesResponse() {
    return (
      this.dsForm.requiresContinuedEffort ||
      this.dsForm.progressionStandards ||
      this.dsForm.uniqueComparedToPeers ||
      this.dsForm.diagnosisOfCondition
    );
  }

  onSaveAndClose(formData: FormGroup) {
    this.isSaving = false;
    this.activeCall = true;
    this.saveForm(formData, false, true).subscribe(() => {
      this.activeCall = false;
      this.routingService.learnerDashboard(this.dsForm.learner.id);
    });
  }

  @NotifySave
  saveForm(formData?: FormGroup, isSubmit = false, isSaveAndClose = false) {
    if (this.isSaving && !isSubmit && !isSaveAndClose) return of(undefined);

    this.isSaving = true;
    let form;
    if (formData !== undefined) {
      form = {
        ...this.dsForm,
        ...formData.value,
        diagnosisOfCondition: formData.value?.conditions?.diagnosisOfCondition?.answer,
        diagnosisOfConditionComments: formData.value?.conditions?.diagnosisOfCondition?.comments,
        uniqueComparedToPeers: formData.value?.conditions?.uniqueComparedToPeers?.answer,
        uniqueComparedToPeersComments: formData.value?.conditions?.uniqueComparedToPeers?.comments,
        progressionStandards: formData.value?.conditions?.progressionStandards?.answer,
        progressionStandardsComments: formData.value?.conditions?.progressionStandards?.comments,
        requiresContinuedEffort: formData.value?.conditions?.requiresContinuedEffort?.answer,
        requiresContinuedEffortComments: formData.value?.conditions?.requiresContinuedEffort?.comments,
        rationaleComments: formData.value?.conditions?.rationaleComments,
      };

      if (formData.controls.participants) {
        form.participants = formData.controls.participants.value;
      }

      if (formData.controls.familyParticipants) {
        form.familyParticipants = formData.controls.familyParticipants.value;
      }

      if (formData.controls.surrogateParticipants) {
        form.surrogateParticipants = formData.controls.surrogateParticipants.value;
      }
    } else {
      form = { ...this.dsForm };
    }

    return this.dsService.saveForm(form, isSubmit).pipe(
      tap((res) => {
        this.dsForm = res;
        AppEvents.autosaving.emit(AutosavingStates.Saved);
        this.isSaving = false;
      })
    );
  }

  removeTeamUser(teamUser: TeamUser) {
    this.dsService.deleteTeamUser(this.id, teamUser.userId).subscribe((deleteResponse) => {
      if (deleteResponse.succeeded) {
        this.notificationService.success('Removed!');
      } else {
        this.notificationService.error('Remove user failed!');
      }
    });
  }

  editForm() {
    this.dsForm.isSubmitted = false;
  }

  goToPwn() {
    const newWindowConfig: NewWindowConfig = {
      path: '',
      popup: true,
      width: '1450px',
    };
    if (this.dsForm.activeDsPwnId) {
      newWindowConfig.path = `cases/${this.dsForm.caseId}/pwns/${this.dsForm.activeDsPwnId}`;
      openNewWindow(newWindowConfig);
    } else {
      const pwn = {
        pwnType: PriorWrittenNoticeType.DisabilitySuspectIneligible,
      } as PriorWrittenNotice;
      this.pwnService.createPwn(this.dsForm.caseId, pwn).subscribe(
        (recPwn) => {
          newWindowConfig.path = `cases/${this.dsForm.caseId}/pwns/${recPwn.id}`;
          openNewWindow(newWindowConfig);
        },
        (error) => {
          // Throws error if there already exists a pwn for this case's pwn type
          this.loggerService.error(error);
        }
      );
    }
  }

  navigateOnSubmit() {
    if (
      this.authService.isAllowedByCaseId(
        this.dsForm.caseId,
        new Map<string, string[]>([['TeamMember', ['AeaEdit']]]),
        ...[AppPermissions.RequestForFiieConsent]
      ) &&
      (this.dsForm.diagnosisOfCondition ||
        this.dsForm.uniqueComparedToPeers ||
        this.dsForm.progressionStandards ||
        this.dsForm.requiresContinuedEffort)
    ) {
      this.router.navigate(this.routingService.fiieConsentPath(this.dsForm.learner.id, this.dsForm.caseId));
    } else if (this.dsForm.pwnNeeded) {
      this.goToPwn();
      this.routingService.userHome();
    } else {
      this.routingService.userHome();
    }
  }

  onDataHistoryNavigate() {
    this.routingService.dataHistory(this.dsForm.learner.id);
  }

  private getDsForm() {
    this.dsService.getForm(this.id).subscribe(
      (res) => {
        this.dsForm = res;
        this.getCaseSummary();
        this.dsService.openedForm(this.dsForm.id).subscribe((val) => {});
      },
      (error: HttpErrorResponse) => {
        if (error.status === HttpStatusCode.NotFound) this.router.navigate(['/not-found']);
        console.log(error);
      }
    );
  }
}
