<form [formGroup]="formGroup" *ngIf="isReady">
  <mat-card>
    <mat-card-title>Referral Source Information</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg-5">
          <app-autocomplete
            [options]="referralCategoryOptions"
            formControlName="referralSourceCategoryId"
            label="Referral Source Category"
          ></app-autocomplete>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-lg">
          <app-text-field label="Name (first and last)" formControlName="referralSourceName"></app-text-field>
        </div>
        <div class="col-lg">
          <app-text-field label="Email" formControlName="referralSourceEmail"></app-text-field>
        </div>
        <div class="col-lg-4">
          <app-text-field
            label="Agency / Organization"
            [showAsterisk]="formGroup.hasError('agencyRequired')"
            formControlName="referralSourceAgency"
            attr.aria-required="{{ formGroup.hasError('agencyRequired') ? 'true' : 'false' }}"
          ></app-text-field>
          <mat-error
            class="position-fix--error"
            *ngIf="formGroup.get('referralSourceAgency').touched && formGroup.hasError('agencyRequired')"
            >Agency is <strong>required</strong></mat-error
          >
        </div>
      </div>

      <div class="row">
        <div class="col-lg">
          <app-masked-input
            label="Home Phone Number"
            [showAsterisk]="formGroup.hasError('referralSourceOnePhoneNumberRequired')"
            formControlName="referralSourceHomePhone"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input
            label="Work Phone Number"
            [showAsterisk]="formGroup.hasError('referralSourceOnePhoneNumberRequired')"
            formControlName="referralSourceWorkPhone"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input
            label="Cell Phone Number"
            [showAsterisk]="formGroup.hasError('referralSourceOnePhoneNumberRequired')"
            formControlName="referralSourceCellPhone"
            mask="(000) 000-0000"
          ></app-masked-input>
        </div>
      </div>
      <div
        class="row"
        *ngIf="
          (formGroup.controls.referralSourceHomePhone.touched ||
            formGroup.controls.referralSourceWorkPhone.touched ||
            formGroup.controls.referralSourceCellPhone.touched) &&
          formGroup.hasError('referralSourceOnePhoneNumberRequired')
        "
      >
        <div class="col-lg">
          <mat-error class="position-fix--error"> At least one phone number is <strong>required</strong> </mat-error>
        </div>
      </div>
      <div class="row mb-1">
        <div class="col-lg">
          <app-text-field
            label="Address"
            formControlName="referralSourceAddress"
            [showAsterisk]="formGroup.hasError('addressRequired')"
            attr.aria-required="{{ formGroup.hasError('addressRequired') ? 'true' : 'false' }}"
          ></app-text-field>
        </div>
        <div class="col-lg">
          <app-text-field
            label="Zip Code"
            formControlName="referralSourceZipCode"
            (blurMe)="populateCityStateFromZipCode()"
            [showAsterisk]="formGroup.hasError('addressRequired')"
            attr.aria-required="{{ formGroup.hasError('addressRequired') ? 'true' : 'false' }}"
          ></app-text-field>
        </div>
        <div class="col-lg">
          <app-text-field
            label="City"
            formControlName="referralSourceCity"
            [showAsterisk]="formGroup.hasError('addressRequired')"
            attr.aria-required="{{ formGroup.hasError('addressRequired') ? 'true' : 'false' }}"
          ></app-text-field>
        </div>
        <div class="col-lg">
          <app-autocomplete
            label="State"
            formControlName="referralSourceState"
            [options]="usStateOptions"
            [showAsterisk]="formGroup.hasError('addressRequired')"
            attr.aria-required="{{ formGroup.hasError('addressRequired') ? 'true' : 'false' }}"
          ></app-autocomplete>
        </div>
      </div>
      <div
        class="row"
        *ngIf="
          (formGroup.controls.referralSourceAddress.touched ||
            formGroup.controls.referralSourceCity.touched ||
            formGroup.controls.referralSourceState.touched ||
            formGroup.controls.referralSourceZipCode.touched) &&
          formGroup.hasError('addressRequired')
        "
      >
        <div class="col-lg">
          <mat-error> Address, zip code, city, and state are <strong>required</strong>. </mat-error>
        </div>
      </div>
      <ng-container>
        <div class="row mt-0 mb-0">
          <div class="col-xl-6 col-lg-12">
            <app-autocomplete
              [options]="referralHowHearAboutUsOptions"
              [formControl]="referralSourceHowHearAboutUsId"
              label="How did you hear about Early ACCESS?"
            ></app-autocomplete>
          </div>
          <div class="col-xl-6 col-lg-12" *ngIf="parentForm.get('referralSourceHowHearAboutUsId').value === referralHowHearAboutUsOtherId">
            <app-text-field
              label="Describe"
              [formControl]="referralSourceEarlyAccessOtherText"
              id="referralSourceEarlyAccessOtherText"
            ></app-text-field>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <fieldset>
              <app-radio-group
                label="Is the parent or legal guardian aware that the referral has been
                made to Early ACCESS?"
                [formControl]="legalGuardian"
                [options]="yesNoOptions"
              ></app-radio-group>
            </fieldset>
          </div>
        </div>
        <div class="row" *ngIf="parentForm.get('legalGuardian').value === false">
          <div class="col">
            <p>
              <strong style="color: red"
                >Referral sources are responsible for informing the family that a referral has been made. Please contact the family after
                completing this referral.</strong
              >
            </p>
          </div>
        </div>
        <div class="row mb-2">
          <div class="col">
            <label
              class="vertical-label display-block text-md"
              [ngClass]="{
                asterisk_input: parentForm.get('exchangeInformation').hasError('required')
              }"
            >
              Do you have a
              <a
                (click)="onOpenHelp($event, helpSection.Referral, referralSourceInfoHelp.SignedRelease)"
                class="text-lower text-underline"
                tabindex="0"
                role="link"
                attr.aria-label="{{ referralSourceInfoHelp.SignedRelease }}"
              >
                {{ referralSourceInfoHelp.SignedRelease }}
              </a>
              from the family to exchange information about the child with Early ACCESS?
            </label>
            <fieldset>
              <app-radio-group [hideAsterisk]="true" [formControl]="exchangeInformation" [options]="yesNoOptions"></app-radio-group>
            </fieldset>
          </div>
        </div>
      </ng-container>
    </div>
  </mat-card>
</form>
