import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Base64Document } from 'src/app/shared/models/base64Document';

@Component({
  selector: 'app-upload-document-flat',
  templateUrl: './upload-document-flat.component.html',
  styleUrls: ['./upload-document-flat.component.scss'],
})
export class UploadDocumentFlatComponent implements OnInit {
  @ViewChild('fileInput') fileInput;

  @Input() formArray: FormArray;
  @Input() documents: Base64Document[];
  @Input() singleFile: boolean;

  maxFileSize = 209715200;
  maxFileSizeLabel = '200 MB';

  allowedFileTypes = 'TXT, CSV, PDF, DOC, DOCX, ODT, PPTX, PPT, RTF, XLS, XLSX, PNG, JPEG, JPG, GIF, BMP';

  errors: string[];

  get formArrayControls() {
    return this.formArray.controls as FormGroup[];
  }

  constructor(private readonly fb: FormBuilder) {}

  ngOnInit(): void {
    if (this.documents) {
      this.documents
        .map((d) =>
          this.fb.group({
            id: d.id,
            title: [d.title, Validators.required],
            file: [null],
            fileName: d.fileName,
          })
        )
        .forEach((g) => this.formArray.push(g));
    }
  }

  openNativeFileSelector() {
    this.errors = [];
    if (this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }

  onFileSelected() {
    const files: { [key: string]: File } = this.fileInput.nativeElement.files;
    const listOfFiles = [];
    for (const key in files) {
      if (!isNaN(parseInt(key))) {
        const file = files[key];
        if (this.isValidFile(file)) {
          listOfFiles.push(file);
        }
      }
    }
    if (this.errors.length === 0) {
      this.addFilesToList(listOfFiles);
    }
  }

  private isValidFile(file: File): boolean {
    if (!(file && file.name)) {
      this.errors.push('Invalid file or files');
      return false;
    }

    const ext = file.name.split('.').slice(-1)[0];
    if (!ext) {
      this.errors.push('Cannot determine file type');
      return false;
    }

    if (!this.allowedFileTypes.toLowerCase().includes(ext.toLowerCase())) {
      this.errors.push(`File type '${ext}' is not supported`);
      return false;
    }

    if (file.size === 0) {
      this.errors.push(`File cannot be empty: ${file.name}`);
      return false;
    }

    if (file.size > this.maxFileSize) {
      this.errors.push(`File cannot be larger than ${this.maxFileSizeLabel}: ${file.name}`);
      return false;
    }

    return true;
  }

  private addFilesToList(listOfFiles: File[]) {
    const formArray = this.formArray;
    if (this.singleFile) {
      formArray.clear();
    }
    for (const file of listOfFiles) {
      formArray.push(
        this.fb.group({
          id: '',
          title: [file.name.replace(/\.[^/.]+$/, ''), Validators.required],
          file: [file],
        })
      );
    }
  }

  onRemoveAt(index: number) {
    this.formArray.removeAt(index);
  }
}
