import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { Subscription, timer } from 'rxjs';
import { ReferralService } from 'src/app/child-find/early-access-referral/early-access-referral.service';
import { usStateOptions, yesNoOptions } from 'src/app/shared/formHelpers';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { ReferralSourceInfoHelp } from 'src/app/shared/services/help/models/referral.help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { LocationService } from 'src/app/shared/services/location/location.service';
import { noNumbersValidator, phoneValidator } from 'src/app/shared/validators';
import { ReferralSourceCategory } from '../referral-source-category';

const referralSourcePhoneValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const referralSourceHomePhone = control.get('referralSourceHomePhone');
  const referralSourceWorkPhone = control.get('referralSourceWorkPhone');
  const referralSourceCellPhone = control.get('referralSourceCellPhone');

  return !referralSourceHomePhone.value && !referralSourceWorkPhone.value && !referralSourceCellPhone.value
    ? { referralSourceOnePhoneNumberRequired: true }
    : null;
};

@Component({
  selector: 'app-referral-source-info-form',
  templateUrl: './referral-source-info-form.component.html',
  styleUrls: [
    '../../../child-find/early-access-referral/early-access-referral.component.scss',
    './referral-source-info-form.component.scss',
  ],
})
export class ReferralSourceInfoFormComponent implements OnInit, OnDestroy {
  private subscription: Subscription = new Subscription();

  @Input()
  parentForm: FormGroup;

  formGroup: FormGroup;

  usStateOptions = usStateOptions;
  yesNoOptions = yesNoOptions;
  referralCategoryOptions: KeyValuePair[];
  referralHowHearAboutUsOptions: KeyValuePair[];
  referralHowHearAboutUsOtherId: string;
  referralSourceCategories: ReferralSourceCategory[];
  isReady = false;

  helpSection = HelpSection;
  referralSourceInfoHelp = ReferralSourceInfoHelp;

  constructor(private locationService: LocationService, private referralService: ReferralService, private helpService: HelpService) {}

  get referralSourceHowHearAboutUsId() {
    return this.parentForm.get('referralSourceHowHearAboutUsId') as FormControl;
  }

  get referralSourceEarlyAccessOtherText() {
    return this.parentForm.get('referralSourceEarlyAccessOtherText') as FormControl;
  }

  get exchangeInformation() {
    return this.parentForm.get('exchangeInformation') as FormControl;
  }

  get legalGuardian() {
    return this.parentForm.get('legalGuardian') as FormControl;
  }

  ngOnInit() {
    this.formGroup = this.parentForm.get('referralSourceInfo') as FormGroup;
    this.referralService.getReferralSourceCategories().subscribe((res) => {
      this.referralSourceCategories = res;
      this.referralCategoryOptions = res.map((val) => new KeyValuePair(val.id, val.label));
    });

    this.referralService.getReferralSourceHowHearAboutUs().subscribe((res) => {
      this.referralHowHearAboutUsOptions = res.map((val) => new KeyValuePair(val.id, val.label));
      this.referralHowHearAboutUsOtherId = res.find((x) => x.isOther)?.id;
    });

    setTimeout(() => this.initializeControls(), 0);
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
    const timerSub = timer(0).subscribe(() => {
      this.formGroup.setValidators([]);
      this.formGroup.reset();
      Object.keys(this.formGroup.controls).forEach((k) => this.formGroup.removeControl(k));
      this.parentForm.get('legalGuardian').setValue(null);
      this.parentForm.get('exchangeInformation').setValue(null);
      timerSub.unsubscribe();
    });
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm) {
    e.preventDefault();

    const dictionary = this.helpService.getReferralDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }

  initializeControls() {
    this.formGroup.addControl('referralSourceName', new FormControl(null, [Validators.required, noNumbersValidator]));
    this.formGroup.addControl('referralSourceEmail', new FormControl(null, [Validators.email]));
    this.formGroup.addControl('referralSourceAgency', new FormControl(null));
    this.formGroup.addControl('referralSourceHomePhone', new FormControl(null, phoneValidator));
    this.formGroup.addControl('referralSourceWorkPhone', new FormControl(null, phoneValidator));
    this.formGroup.addControl('referralSourceCellPhone', new FormControl(null, phoneValidator));
    this.formGroup.addControl('referralSourceAddress', new FormControl(null));
    this.formGroup.addControl('referralSourceCity', new FormControl(null, noNumbersValidator));
    this.formGroup.addControl('referralSourceState', new FormControl(null, noNumbersValidator));
    this.formGroup.addControl(
      'referralSourceZipCode',
      new FormControl(null, [Validators.minLength(5), Validators.maxLength(5), Validators.pattern('[0-9]{5}')])
    );
    this.formGroup.addControl('referralSourceCategoryId', new FormControl(null, [Validators.required]));
    this.formGroup.setValidators([this.agencyValidator.bind(this), referralSourcePhoneValidator, this.addressValidator.bind(this)]);
    this.formGroup.updateValueAndValidity();
    this.isReady = true;
  }

  populateCityStateFromZipCode() {
    const zipCodeCtrl = this.formGroup.get('referralSourceZipCode');
    if (!zipCodeCtrl.value || !zipCodeCtrl.valid) {
      return;
    }

    this.locationService.getLocationData(zipCodeCtrl.value).subscribe((res) => {
      if (res) {
        this.formGroup.patchValue({
          referralSourceCity: res.city,
          referralSourceState: 'IA',
        });
      }
    });
  }

  private agencyValidator(formGroup: FormGroup): ValidationErrors | null {
    const referralSourceCategory = this.referralSourceCategories?.find((c) => c.id === formGroup.get('referralSourceCategoryId')?.value);
    const agency = formGroup.get('referralSourceAgency').value;

    return referralSourceCategory && !referralSourceCategory.isFamily && !agency ? { agencyRequired: true } : null;
  }

  private addressValidator(formGroup: FormGroup): ValidationErrors | null {
    const address = formGroup.get('referralSourceAddress');
    const city = formGroup.get('referralSourceCity');
    const state = formGroup.get('referralSourceState');
    const zipcode = formGroup.get('referralSourceZipCode');

    return (address && !address.value) || (city && !city.value) || (state && !state.value) || (zipcode && !zipcode.value)
      ? { addressRequired: true }
      : null;
  }
}
