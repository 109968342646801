import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, Observable, Subject, Subscription } from 'rxjs';
import { debounceTime, filter, pairwise, startWith, takeUntil } from 'rxjs/operators';
import { ProgressMonitorBService } from 'src/app/monitoring-progress/services/progress-monitor-b.service';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { MultiGraphViewModalComponent } from 'src/app/shared/components/multi-graph-view-modal/multi-graph-view-modal.component';
import { QuantifiableDataService } from 'src/app/shared/components/quantifiable-data/quantifiable-data.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { DocumentService } from 'src/app/shared/services/document/document.service';
import { FormService } from 'src/app/shared/services/form.service';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { IepGoalHelp } from 'src/app/shared/services/help/models/iep.help';
import { SpinnerService } from 'src/app/shared/services/spinner/spinner.service';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { TaggedItem } from 'src/app/tags/tagged-item';
import { TaggingService } from 'src/app/tags/taggingService.service';
import { BaseComponent } from '../../../shared/components/base-component/base-component';
import {
  IepGoalQuantifiableDatum,
  MilestoneStatus,
  QuantifiableDataPoint,
  QuantifiableDataRating,
  QuantifiableTieredMilestone,
} from '../../../shared/components/quantifiable-data/quantifiable-data';
import { NotifySave } from '../../../shared/decorators/notify-save.decorator';
import {
  DialogData,
  UploadDocumentationModalComponent,
} from '../../../shared/modals/upload-documentation-modal/upload-documentation-modal.component';
import { CaseSummary, CaseUserRole } from '../../../shared/models/case';
import { CaseService } from '../../../shared/services/case/case.service';
import { CanComponentDeactivate, DeactivationService, DeactivationStatus } from '../../../shared/services/deactivation.service';
import { NotificationService } from '../../../shared/services/notification.service';
import { UserService } from '../../../shared/services/user/user.service';
import { conditionalValidator } from '../../../shared/validators';
import { GoalArea, GoalAreaDomain } from '../../models/goal-area';
import { IepGoal, IepView } from '../../models/iep';
import { IepAmendment } from '../../models/iep-amendment';
import { GoalAreaService } from '../../services/goalarea.service';
import { IepGoalService } from '../../services/iep-goal.service';
import { IepService } from '../../services/iep.service';
import { IepGoalsViewMoreComponent } from './iep-goals-view-more/iep-goals-view-more.component';
import { IepQuantifiableDataComponent } from './iep-quantifiable-data/iep-quantifiable-data.component';

@Component({
  selector: 'app-iep-goals',
  templateUrl: './iep-goals.component.html',
  styleUrls: ['./iep-goals.component.scss'],
  providers: [DatePipe],
})
export class IepGoalsComponent extends BaseComponent implements OnInit, CanComponentDeactivate {
  iepGoalHelp = IepGoalHelp;
  helpSection = HelpSection;
  @ViewChild(IepQuantifiableDataComponent)
  quantData: IepQuantifiableDataComponent;
  @ViewChild('additionalQuantData') addlQuantData: IepQuantifiableDataComponent;
  @ViewChild('formDirective') form: NgForm;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChild('editingGoal', { static: false })
  editingGoalRef: ElementRef<any>;

  @Input() amendmentId: string;
  @Input() amendments: IepAmendment[] = [];
  @Input() amendingGoal: IepGoal;
  @Input() isPK = false;
  @Input() behaviorAddressed?: boolean = null;

  formGroup: FormGroup;
  showQuantData = false;
  activeCall = false;
  controlsWord = 'controls';

  methodsToReportOptions: KeyValuePair[] = [
    new KeyValuePair('An IEP report with report cards and progress reports', 'An IEP report with report cards and progress reports'),
    new KeyValuePair('Updated copies of IEP goal pages', 'Updated copies of IEP goal pages'),
    new KeyValuePair('Family Portal', 'Family Portal'),
    new KeyValuePair('Other', 'Other'),
  ];
  frequencyPeriodOptions: KeyValuePair[] = [
    new KeyValuePair('Day', 'Day'),
    new KeyValuePair('Week', 'Week'),
    new KeyValuePair('Month', 'Month'),
    new KeyValuePair('Quarter', 'Quarter'),
    new KeyValuePair('Year', 'Year'),
  ];
  userOptions: KeyValuePair[] = [];
  standardsOfComparisonOptions: KeyValuePair[];
  goalAreaOptions: KeyValuePair[] = [];
  goalAreas: GoalArea[] = [];

  sasOptions: KeyValuePair[] = [];
  showSSA = false;
  serviceId: string;

  caseSummary: CaseSummary;
  caseId: string;
  iepId: string;
  iepGoal: IepGoal;
  iepGoalId: string;
  iepGoals: IepGoal[] = [];
  iepView: IepView;
  editingMilestoneId: string;
  editingGoal = false;

  goalDataSource = new MatTableDataSource<IepGoal>([]);
  displayedGoalColumns = ['actions', 'area', 'goalSentence', 'graph'];
  measurementMilestoneDataSource = new MatTableDataSource<IepGoalQuantifiableDatum>();
  measurementMilestoneDisplayedColumns = ['actions', 'description', 'baseline', 'target'];

  autosaveSubscription = new Subscription();
  private stopAutosaving$ = new Subject();
  submitting = false;
  resettingForm = false;
  showBody = false;
  quantDataAdvancedVisible = false;
  quantDataSecondMeasurement = false;
  quantDataSecondaryAdvancedVisible = false;
  taskSkillMilestoneAdvancedVisible = false;
  taskSkillMilestoneBaselineVisible = false;

  shortDateFormat = shortDateFormat;

  externalResources = [
    {
      link: 'https://educateiowa.gov/documents/early-childhood-standards/2019/01/iowa-early-learning-standards-3rd-edition',
      label: 'Iowa Early Learning Standards',
    },
    {
      link: 'https://iowacore.gov/',
      label: 'Iowa Core Standards',
    },
    {
      link: 'https://iowacore.gov/standards/essential-elements/k-1-2-3-4-5-6-7-8-9-10-11-12/essential-elements',
      label: 'Iowa Core Essential Elements',
    },
  ];

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentIsFinalized() {
    return this.amendment?.finalizeDate !== null;
  }

  get lastFinalizedDate() {
    if (this.amendments && this.amendments.length > 0) {
      const latest = this.amendments.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  get hasAmendment() {
    return this.amendmentId && this.amendmentId !== null;
  }

  get hasPriorVersion() {
    return !!this.amendingGoal?.priorVersionId;
  }

  get primaryQuantifiableData() {
    return this.formGroup.get('primaryQuantifiableData') as FormGroup;
  }

  get priorPrimaryQuantifiableData() {
    return this.amendingGoal?.priorVersion?.primaryQuantifiableData;
  }

  get primaryUnitOfMeasurement() {
    return this.primaryQuantifiableData.get('primaryMeasurement').get('unitOfMeasurement')?.value;
  }

  get priorPrimaryUnitOfMeasurement() {
    return this.amendingGoal?.priorVersion?.primaryQuantifiableData?.primaryMeasurement?.unitOfMeasurement;
  }

  get primaryTargetValue() {
    return this.primaryQuantifiableData.get('primaryMeasurement').get('learnersGoalTarget')?.value;
  }

  get priorPrimaryTargetValue() {
    return this.amendingGoal?.priorVersion?.primaryQuantifiableData?.primaryMeasurement?.learnersGoalTarget;
  }

  // Get's the current instance of the quantifiable form group
  get editingQuantifiableFormGroup() {
    if (this.addlQuantifiableDataFormArray.controls.length === 0 || !this.editingMilestoneId) {
      return null;
    }

    const index = this.addlQuantifiableDataFormArray.value.findIndex((x) => x.id === this.editingMilestoneId);
    return this.addlQuantifiableDataFormArray.controls[index] as FormGroup;
  }

  get addlQuantifiableDataFormArray() {
    return this.formGroup.get('additionalQuantifiableData') as FormArray;
  }

  get addlQuantifiableDataCount() {
    return this.addlQuantifiableDataFormArray.length;
  }

  get rubricMax(): number {
    return this.formGroup?.get('rubricMax').valid ? this.formGroup.get('rubricMax').value : 1;
  }

  get combinedGoal(): boolean {
    return this.formGroup.get('combinedGoal').value;
  }

  constructor(
    private goalAreaService: GoalAreaService,
    private caseService: CaseService,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private notificationService: NotificationService,
    public iepGoalService: IepGoalService,
    private iepService: IepService,
    private userService: UserService,
    private quantifiableService: QuantifiableDataService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private helpService: HelpService,
    private readonly formService: FormService,
    private readonly taggingService: TaggingService,
    spinnerService: SpinnerService,
    deactivationService: DeactivationService,
    private documentService: DocumentService,
    private progressMonitorBService: ProgressMonitorBService
  ) {
    super(deactivationService);
    spinnerService.registerIgnoredRequestUrlMatcher(new RegExp(/^api\/.*\/iepservice.*/));
  }

  ngOnInit(): void {
    this.caseId = this.route.parent?.snapshot.paramMap.get('caseId');
    this.iepId = this.route.parent?.snapshot.paramMap.get('iepId');
    this.serviceId = this.route.snapshot.paramMap.get('serviceId');

    if (!this.caseId) {
      this.caseId = this.route.snapshot.paramMap.get('caseId');
    }
    if (!this.iepId) {
      this.iepId = this.route.snapshot.paramMap.get('iepId');
    }

    this.formGroup = this.initFormGroup();

    this.subscriptions.add(this.formService.showAllErrors$.subscribe(() => this.formGroup.markAllAsTouched()));

    if (this.amendingGoal) {
      this.onEditGoal(this.amendingGoal, true);
    }

    this.subscriptions.add(
      this.iepGoalService.setAmendingGoal$.subscribe((result) => {
        this.amendingGoal = result;
        this.onEditGoal(this.amendingGoal, true);
      })
    );

    forkJoin([
      this.caseService.getCaseSummary(this.caseId),
      this.goalAreaService.getAllGoalAreas(),
      this.iepGoalService.getAllIepGoals(this.iepId),
      this.iepGoalService.getSASTypes(this.iepId),
      this.quantifiableService.getStandardsOfComparison(),
      this.iepService.get(this.iepId),
    ]).subscribe(([caseSummary, goalAreas, iepGoals, sasTypes, standards, iepView]) => {
      this.iepGoals = iepGoals;
      this.goalDataSource.data = this.iepGoals;
      this.goalAreas = goalAreas;
      this.sasOptions = sasTypes.map((x) => new KeyValuePair(x.id, x.label));

      this.measurementMilestoneDataSource.data = this.addlQuantifiableDataFormArray.value;
      this.caseSummary = caseSummary;

      this.goalAreaOptions = goalAreas.map((g) => new KeyValuePair(g.id, g.label));
      this.userService
        .searchUsers({
          aeaId: caseSummary?.learner?.attendingAeaId,
          schoolDistrictId: caseSummary?.learner?.attendingDistrictId,
          buildingId: caseSummary?.learner?.building?.id,
          filterBy: CaseUserRole.Aea,
        })
        .subscribe((users) => {
          this.userOptions = users.map((u) => new KeyValuePair(u.id, u.fullName));
        });
      this.standardsOfComparisonOptions = standards.map((x) => new KeyValuePair(x.id, x.label));
      this.iepView = iepView;

      if (this.behaviorAddressed === null) {
        this.behaviorAddressed = this.caseSummary.socialBehaviorIdentified;
      }

      this.formGroup
        .get('goalAreaIds')
        .valueChanges.pipe(startWith(null as string), pairwise())
        .subscribe(([prev, next]: [string, string]) => {
          const behaviorId = this.goalAreas.find((x) => x.domain === GoalAreaDomain.SocialEmotionalBehavior).id;
          if (next?.includes(behaviorId) && !this.behaviorAddressed) {
            this.notificationService.alert(
              'Social Emotional Behavior is not currently indicated as a special factor, the team should consider adding it as a special factor.'
            );
          }
          if (prev === null && next !== null) {
            // If it's the first time selecting, start the autosaving process
            this.showBody = true;
            this.startAutosaving();
          }
          if (this.goalAreas.find((x) => x.id === next)) {
            this.formGroup.get('domain').setValue(this.goalAreas.find((x) => x.id === next).domain);
          }

          this.cd.detectChanges();
        });

      this.goalDataSource.sort = this.sort;

      this.setFormGroupValueChanges();
    });

    this.subscriptions.add(
      this.iepGoalService.goalClose$.subscribe(() => {
        this.quantDataAdvancedVisible = false;
        this.quantDataSecondMeasurement = false;
        this.quantDataSecondaryAdvancedVisible = false;
      })
    );

    this.subscriptions.add(
      this.formGroup.controls.rubricMax.valueChanges.pipe(filter(() => this.combinedGoal)).subscribe(() => {
        for (const control of this.addlQuantifiableDataFormArray.controls.map(
          (x) => x.get('primaryMeasurement.learnersGoalTarget') as FormControl
        )) {
          const validBefore = control.valid;
          control.setValidators([Validators.required, conditionalValidator(() => this.combinedGoal, Validators.max(this.rubricMax))]);
          control.updateValueAndValidity();
          if (validBefore !== control.valid) {
            control.markAsTouched();
          }
        }
      })
    );
  }

  initFormGroup() {
    return new FormGroup({
      goalAreaIds: new FormControl([], [Validators.required]),
      currentLevelDescription: new FormControl(null, [Validators.required]),
      standardOfComparisonId: new FormControl(null, [Validators.required]),
      standardOfComparisonOther: new FormControl(null),
      conditionIndividualWillPerform: new FormControl(null, [Validators.required]),
      goalNickName: new FormControl(null),

      frequencyAmount: new FormControl(null, [Validators.required, Validators.min(1)]),
      frequencyPeriod: new FormControl(null, [Validators.required]),
      peopleMonitoringGoal: new FormControl(null, [Validators.required]),
      collaborators: new FormControl(null),
      monitoringProceduresDescription: new FormControl(null),

      sameRateAsPeersReported: new FormControl(null, [Validators.required]),
      sameRateFrequency: new FormControl(null, Validators.min(1)),
      sameRatePeriod: new FormControl(null),
      differentRateFrequency: new FormControl(null, Validators.min(1)),
      differentRatePeriod: new FormControl(null),
      methodsToReport: new FormControl(null, [Validators.required]),
      otherMethodToReport: new FormControl(null, [Validators.required]),
      combinedGoal: new FormControl(false),
      rubricMax: new FormControl(null, [Validators.min(1), Validators.max(10)]),

      domain: new FormControl(null),
      primaryQuantifiableData: new FormGroup({
        id: new FormControl(null),
        amendmentId: new FormControl(null),
        priorVersionId: new FormControl(null),
        amendmentStartDate: new FormControl(null),
        amendmentEndDate: new FormControl(null),
        amendmentComments: new FormControl(null),
        description: new FormControl(null),
        status: new FormControl(null),
        tieredMilestones: this.initializeTieredMilestones(null),
        tieredMilestoneSelected: new FormControl(null),
        secondaryTieredMilestoneSelected: new FormControl(null),
        scaleRatings: this.initializeScales(null),
        scaleSelected: new FormControl(null),
        secondaryScalesSelected: new FormControl(null),
        primaryMeasurement: new FormGroup({
          baseline: new FormControl(null, [Validators.required, Validators.min(0)]),
          learnersGoalTarget: new FormControl(null, [Validators.required]),
          unitOfMeasurement: new FormControl(null, [conditionalValidator(() => !this.combinedGoal, Validators.required)]),
          dataPoints: this.initializeDataPoints(null),
          amendmentId: new FormControl(null),
          priorVersionId: new FormControl(null),
          amendmentStartDate: new FormControl(null),
          amendmentEndDate: new FormControl(null),
          amendmentComments: new FormControl(null),
        }),
        secondaryMeasurement: new FormGroup({
          baseline: new FormControl(null, [Validators.min(0)]),
          learnersGoalTarget: new FormControl(null),
          unitOfMeasurement: new FormControl(null),
          dataPoints: this.initializeDataPoints(null),
          amendmentId: new FormControl(null),
          priorVersionId: new FormControl(null),
          amendmentStartDate: new FormControl(null),
          amendmentEndDate: new FormControl(null),
          amendmentComments: new FormControl(null),
        }),
      }),
      useSameUnitAndTarget: new FormControl(),
      additionalQuantifiableData: new FormArray([]),
      amendmentId: new FormControl(null),
      priorVersionId: new FormControl(null),
      amendmentStartDate: new FormControl(null),
      amendmentEndDate: new FormControl(null),
      amendmentComments: new FormControl(null),
    });
  }

  onOpenHelp(section: HelpSection, item: HelpTerm) {
    const help = this.helpService.getIepGoalDictionary();
    this.helpService.openHelpModal({
      help,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }

  addMilestone() {
    if (!this.editingQuantifiableFormGroup) {
      this.addQuantData();
    }
  }

  async onCancelMilestone(): Promise<void> {
    const item = this.addlQuantifiableDataFormArray.value.find((x) => x.id === this.editingMilestoneId);
    if (!(await this.deleteMilestone(item))) {
      return;
    }
    this.editingMilestoneId = null;
    if (!!this.quantData) {
      this.quantData.isEditingTaskSkillMilestone = false;
    }
  }

  onSubmitMilestone() {
    this.editingMilestoneId = null;
    if (!!this.quantData) {
      this.quantData.isEditingTaskSkillMilestone = false;
    }
  }

  editMilestone(milestone: IepGoalQuantifiableDatum) {
    this.editingMilestoneId = milestone.id;
    this.cd.detectChanges();
  }

  async deleteMilestone(milestone: IepGoalQuantifiableDatum): Promise<boolean> {
    if (!(await this.showConfirmationDialog('Clicking Yes will remove this objective.'))) {
      return false;
    }

    const index = this.addlQuantifiableDataFormArray.value.findIndex((x) => x.id === milestone.id);
    this.addlQuantifiableDataFormArray.removeAt(index);

    // Reorder form array's milestone numbers
    let milestoneIncrement = 1;
    this.addlQuantifiableDataFormArray.controls
      .sort((a, b) => a[this.controlsWord].milestoneNumber.value - b[this.controlsWord].milestoneNumber.value)
      .forEach((quantFormGroup) => {
        quantFormGroup[this.controlsWord].milestoneNumber.setValue(milestoneIncrement);
        milestoneIncrement++;
      });

    if (this.addlQuantifiableDataFormArray.length === 0) {
      this.quantData.isEditingTaskSkillMilestone = false;
    }

    return true;
  }

  getObjectiveDescription(element, milestoneLength) {
    return `${element?.milestoneNumber} of ${milestoneLength}: ${element?.description ? element.description : '-'}`;
  }

  getObjectiveBaseline(element) {
    if (element?.primaryMeasurement?.baseline === null && !element?.primaryMeasurement?.unitOfMeasurement) {
      return '-';
    }
    return `${element?.primaryMeasurement?.baseline !== null ? element?.primaryMeasurement?.baseline : ''} ${
      element?.primaryMeasurement?.unitOfMeasurement ?? ''
    }`;
  }

  getObjectiveTarget(element) {
    if (element?.primaryMeasurement?.learnersGoalTarget === null && !element?.primaryMeasurement?.unitOfMeasurement) {
      return '-';
    }
    return `${element?.primaryMeasurement?.learnersGoalTarget !== null ? element?.primaryMeasurement?.learnersGoalTarget : ''} ${
      element?.primaryMeasurement?.unitOfMeasurement ?? ''
    }`;
  }

  getPeopleMonitoringGoalText(userIds) {
    const userNames = [];
    if (userIds) {
      userIds.forEach((userId) => {
        userNames.push(this.userOptions.find((x) => x.key === userId)?.value);
      });
    }
    return userNames.join(', ');
  }

  getHowOftenProgressReportedText(iepGoal) {
    let progressReportText = '';
    if (iepGoal) {
      progressReportText += iepGoal.sameRateAsPeersReported
        ? 'At the same rate as non-disabled peers'
        : 'At a different rate than non-disabled peers';

      if (iepGoal.sameRateAsPeersReported) {
        progressReportText += ' ' + iepGoal.sameRateFrequency + ' times per ' + iepGoal.sameRatePeriod;
      }

      if (!iepGoal.sameRateAsPeersReported) {
        progressReportText += ' ' + iepGoal.differentRateFrequency + ' times per ' + iepGoal.differentRatePeriod;
      }
    }

    return progressReportText;
  }

  getMethodsToReportText(methods) {
    const methodsArray = [];
    if (methods) {
      methods.forEach((method) => {
        methodsArray.push(this.methodsToReportOptions.find((x) => x.key === method)?.value);
      });
    }
    return methodsArray.join(', ');
  }

  routeTo(title: string) {
    const config: NewWindowConfig = {
      external: true,
      path: this.externalResources.find((x) => x.label === title).link,
      popup: false,
      width: '800px',
    };
    openNewWindow(config);
  }

  onClearRadio(formControlName: string) {
    this.formGroup.get(formControlName).setValue(null);
  }

  calculateRubricMax() {
    if (this.formGroup.get('combinedGoal').value) {
      return this.formGroup.get('rubricMax').value * (this.addlQuantifiableDataFormArray.length + 1); // +1 for primary;
    }
  }

  isStandardOfComparisonOther(id: string) {
    return id === this.standardsOfComparisonOptions?.find((x) => x.value === 'Other').key;
  }

  showWarning(milestoneId: string) {
    const formGroup = this.addlQuantifiableDataFormArray.controls[
      this.addlQuantifiableDataFormArray.value.findIndex((x) => x.id === milestoneId)
    ] as FormGroup;
    return formGroup?.invalid;
  }

  onSaveAndClose() {
    this.activeCall = true;
    const callback = () => {
      this.resetForm();
      this.activeCall = false;
      this.iepGoalService.goalClose.next();
    };
    this.stopAutosaving$.next(undefined);
    this.makeSaveRequest({ callback });
  }

  onSubmit() {
    this.activeCall = true;
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.stopAutosaving$.next(undefined);
      const callback = () => {
        this.activeCall = false;
        this.iepGoalService.goalClose.next();
      };
      this.makeSaveRequest({
        submitting: true,
        callback,
      });
    }
  }

  private repopulateFormArray(formArray: FormArray, lengthOfArray: number, type: string) {
    formArray.clear();
    switch (type) {
      case 'dataPoints': {
        for (let i = 0; i < lengthOfArray; i++) {
          formArray.push(
            this.fb.group({
              score: new FormControl(null),
              date: new FormControl(null),
            })
          );
        }
        break;
      }
      case 'milestones': {
        for (let i = 0; i < lengthOfArray; i++) {
          formArray.push(
            this.fb.group({
              // eslint-disable-next-line id-blacklist
              number: new FormControl(null),
              endDate: new FormControl(null),
              target: new FormControl(null),
              secondary: new FormControl(null),
            })
          );
        }
        break;
      }
      case 'scales': {
        for (let i = 0; i < lengthOfArray; i++) {
          formArray.push(
            this.fb.group({
              rating: new FormControl(null),
              description: new FormControl(null),
              secondary: new FormControl(null),
            })
          );
        }
        break;
      }
    }
  }

  onEditGoal(goal: IepGoal, isAmending = false) {
    this.showBody = true;
    this.editingGoal = true;
    this.quantDataAdvancedVisible = false;
    this.quantDataSecondMeasurement = false;
    this.quantDataSecondaryAdvancedVisible = false;
    this.taskSkillMilestoneAdvancedVisible = false;
    this.taskSkillMilestoneBaselineVisible = false;

    if (!isAmending) {
      this.resetForm();
    }

    // We need to repopulate form controls for the form arrays before we patch them
    // Primary Quantifiable Data Points
    const primaryMeasurementDataPointFormArray = this.formGroup.controls.primaryQuantifiableData[this.controlsWord].primaryMeasurement
      .controls.dataPoints as FormArray;
    const secondaryMeasurementDataPointFormArray = this.formGroup.controls.primaryQuantifiableData[this.controlsWord].secondaryMeasurement
      .controls.dataPoints as FormArray;

    this.repopulateFormArray(
      primaryMeasurementDataPointFormArray,
      goal.primaryQuantifiableData?.primaryMeasurement?.dataPoints?.length,
      'dataPoints'
    );
    this.repopulateFormArray(
      secondaryMeasurementDataPointFormArray,
      goal.primaryQuantifiableData?.secondaryMeasurement?.dataPoints?.length,
      'dataPoints'
    );

    // Primary Quantifiable Milestones
    const primaryQuantifiableMilestoneFormArray = this.formGroup.controls.primaryQuantifiableData[this.controlsWord]
      .tieredMilestones as FormArray;

    this.repopulateFormArray(primaryQuantifiableMilestoneFormArray, goal.primaryQuantifiableData?.tieredMilestones?.length, 'milestones');

    // Primary Quantifiable Scales
    const primaryQuantifiableScaleFormArray = this.formGroup.controls.primaryQuantifiableData[this.controlsWord].scaleRatings as FormArray;

    this.repopulateFormArray(primaryQuantifiableScaleFormArray, goal.primaryQuantifiableData?.scaleRatings?.length, 'scales');

    this.iepGoalId = goal.id;
    this.iepGoal = goal;
    this.formGroup.patchValue(goal, { emitEvent: false });
    if (this.formGroup.get('rubricMax').value !== this.rubricMax) {
      this.formGroup.get('rubricMax').setValue(this.rubricMax);
    }
    // If anything has value in the first section, open section up
    if (
      !this.quantData &&
      (goal.primaryQuantifiableData?.scaleRatings.filter((x) => !x.secondary)?.length > 0 ||
        goal.primaryQuantifiableData?.tieredMilestones.filter((x) => !x.secondary)?.length > 0 ||
        goal.additionalQuantifiableData?.length > 0 ||
        goal.combinedGoal)
    ) {
      this.quantDataAdvancedVisible = true;
    }

    const primaryMeasurement = this.primaryQuantifiableData.controls.primaryMeasurement as FormArray;
    primaryMeasurement.get('learnersGoalTarget').setValidators([Validators.required]);

    // If anything has value in the second section, open section up
    if (
      goal.primaryQuantifiableData?.secondaryMeasurement &&
      (goal.primaryQuantifiableData?.secondaryMeasurement.baseline ||
        goal.primaryQuantifiableData?.secondaryMeasurement.learnersGoalTarget ||
        goal.primaryQuantifiableData?.secondaryMeasurement.unitOfMeasurement)
    ) {
      this.quantDataAdvancedVisible = true;
      this.quantDataSecondMeasurement = true;

      const secondaryMeasurement = this.primaryQuantifiableData.controls.secondaryMeasurement as FormArray;
      secondaryMeasurement.get('learnersGoalTarget').setValidators([Validators.required]);
      secondaryMeasurement.get('unitOfMeasurement').setValidators([Validators.required]);

      if (
        goal.primaryQuantifiableData.scaleRatings?.filter((x) => x.secondary)?.length > 0 ||
        goal.primaryQuantifiableData.tieredMilestones?.filter((x) => x.secondary)?.length > 0
      ) {
        this.quantDataSecondaryAdvancedVisible = true;
      }
    }

    if (goal.primaryQuantifiableData) {
      // Sets boolean openers depending on what's populated
      this.formGroup.controls.primaryQuantifiableData[this.controlsWord].tieredMilestoneSelected.setValue(
        goal.primaryQuantifiableData.tieredMilestones.filter((x) => !x.secondary)?.length > 0
      );

      this.formGroup.controls.primaryQuantifiableData[this.controlsWord].secondaryTieredMilestoneSelected.setValue(
        goal.primaryQuantifiableData.tieredMilestones.filter((x) => x.secondary)?.length > 0
      );

      this.formGroup.controls.primaryQuantifiableData[this.controlsWord].scaleSelected.setValue(
        goal.primaryQuantifiableData.scaleRatings.filter((x) => !x.secondary)?.length > 0
      );

      this.formGroup.controls.primaryQuantifiableData[this.controlsWord].secondaryScalesSelected.setValue(
        goal.primaryQuantifiableData.scaleRatings.filter((x) => x.secondary)?.length > 0
      );
    }

    if (this.addlQuantifiableDataFormArray?.length === goal.additionalQuantifiableData?.length) {
      this.addlQuantifiableDataFormArray.clear();
    }

    // Push in secondary nested formgroups sorted by milestone number
    goal.additionalQuantifiableData
      .sort((a, b) => a.milestoneNumber - b.milestoneNumber)
      .forEach((adlQuant) => {
        this.addlQuantifiableDataFormArray.push(
          new FormGroup({
            tieredMilestoneSelected: new FormControl(null),
            secondaryTieredMilestoneSelected: new FormControl(null),
            scaleSelected: new FormControl(null),
            secondaryScalesSelected: new FormControl(null),

            id: new FormControl(adlQuant.id),
            description: new FormControl(adlQuant.description),
            status: new FormControl(MilestoneStatus.active),
            milestoneNumber: new FormControl(
              this.addlQuantifiableDataFormArray.length + 1 // Hasn't been added in yet
            ),
            tieredMilestones: this.initializeTieredMilestones(adlQuant.tieredMilestones),
            scaleRatings: this.initializeScales(adlQuant.scaleRatings),
            amendmentId: new FormControl(adlQuant.amendmentId),
            priorVersionId: new FormControl(adlQuant.priorVersionId),
            amendmentStartDate: new FormControl(adlQuant.amendmentStartDate),
            amendmentEndDate: new FormControl(adlQuant.amendmentEndDate),
            amendmentComments: new FormControl(adlQuant.amendmentComments),
            priorVersion: new FormControl(adlQuant.priorVersion),
            primaryMeasurement: new FormGroup({
              baseline: new FormControl(adlQuant.primaryMeasurement?.baseline, [Validators.required, Validators.min(0)]),
              learnersGoalTarget: new FormControl(adlQuant.primaryMeasurement?.learnersGoalTarget, [
                Validators.required,
                conditionalValidator(() => this.combinedGoal, Validators.max(this.rubricMax)),
              ]),
              unitOfMeasurement: new FormControl(adlQuant.primaryMeasurement?.unitOfMeasurement, [
                conditionalValidator(() => !this.formGroup.get('combinedGoal').value, Validators.required),
              ]),
              dataPoints: this.initializeDataPoints(adlQuant.primaryMeasurement?.dataPoints),
              amendmentId: new FormControl(adlQuant.primaryMeasurement?.amendmentId),
              priorVersionId: new FormControl(adlQuant.primaryMeasurement?.priorVersionId),
              amendmentStartDate: new FormControl(adlQuant.primaryMeasurement?.amendmentStartDate),
              amendmentEndDate: new FormControl(adlQuant.primaryMeasurement?.amendmentEndDate),
              amendmentComments: new FormControl(adlQuant.primaryMeasurement?.amendmentComments),
              priorVersion: new FormControl(adlQuant.primaryMeasurement?.priorVersion),
            }),
            secondaryMeasurement: new FormGroup({
              baseline: new FormControl(adlQuant.secondaryMeasurement?.baseline, [Validators.min(0)]),
              learnersGoalTarget: new FormControl(adlQuant.secondaryMeasurement?.learnersGoalTarget),
              unitOfMeasurement: new FormControl(adlQuant.secondaryMeasurement?.unitOfMeasurement),
              dataPoints: this.initializeDataPoints(adlQuant.secondaryMeasurement?.dataPoints),
              amendmentId: new FormControl(adlQuant.secondaryMeasurement?.amendmentId),
              priorVersionId: new FormControl(adlQuant.secondaryMeasurement?.priorVersionId),
              amendmentStartDate: new FormControl(adlQuant.secondaryMeasurement?.amendmentStartDate),
              amendmentEndDate: new FormControl(adlQuant.secondaryMeasurement?.amendmentEndDate),
              amendmentComments: new FormControl(adlQuant.secondaryMeasurement?.amendmentComments),
              priorVersion: new FormControl(adlQuant.secondaryMeasurement?.priorVersion),
            }),
          })
        );
      });
    this.addlQuantifiableDataFormArray.controls.forEach((element, index) => {
      const primaryMeasurement = element.get('primaryMeasurement') as FormGroup;
      Object.keys(primaryMeasurement.controls).forEach((key) => {
        const control = primaryMeasurement.controls[key];
        control.updateValueAndValidity({ emitEvent: false });
      });
      element.updateValueAndValidity({ emitEvent: false });
    });
    this.formGroup.get('goalAreaIds').updateValueAndValidity();
    this.editingGoal = false;
    if (goal.goalAreaIds?.length > 0) {
      this.startAutosaving();
    }
  }

  async onDeleteGoal(goal: IepGoal): Promise<void> {
    const res = await this.showConfirmationDialog('Clicking Yes will remove this goal and the ESY goal if associated with it.');
    if (res) {
      this.goalDataSource.data = this.iepGoals.filter((x) => x.id !== goal.id);

      this.iepGoalService.delete(goal.iepId, goal.id).subscribe(() => {
        if (this.goalDataSource.data?.length === 0) {
          this.iepGoals = [];
          this.showBody = false;
          this.cd.detectChanges();
          this.stopAutosaving$.next(undefined);
        }
        if (goal.id === this.iepGoalId) {
          this.resetForm(true);
        }
        this.iepGoalService.goalClose.next();
        this.getIepGoals();
      });
    }
  }

  getGoalAreas(goal: IepGoal) {
    if (goal) {
      const goalAreas: string[] = [];
      goal?.goalAreaIds?.forEach((gaId) => {
        goalAreas.push(this.goalAreaOptions.find((x) => x.key === gaId)?.value);
      });
      return goalAreas?.join(', ');
    }
  }

  onViewGoal(goal) {
    const modalData = this.getModalData(goal);
    if (goal.priorVersionId !== null) {
      modalData.priorVersion = this.getModalData(goal.priorVersion);
    } else {
      modalData.priorVersion = null;
    }
    this.dialog.open(IepGoalsViewMoreComponent, {
      width: '728px',
      data: {
        goal: modalData,
        amendment: {
          amendmentId: this.amendmentId,
          amendmentIsFinalized: this.amendmentIsFinalized,
          lastFinalizedDate: this.lastFinalizedDate,
        },
      },
    });
  }

  startAutosaving() {
    this.autosaveSubscription.unsubscribe();
    this.autosaveSubscription = this.formGroup.valueChanges.pipe(debounceTime(3000), takeUntil(this.stopAutosaving$)).subscribe(() => {
      this.cd.detectChanges();
      if (!this.editingGoal && this.checkSafeSave()) {
        this.makeSaveRequest({});
      }
    });
  }

  tag(tagFlag: boolean) {
    if (!this.iepGoal.taggedItem) {
      this.iepGoal.taggedItem = {
        id: null,
        caseId: this.caseId,
        iepGoalId: this.iepGoal.id,
        taggedForPwn: tagFlag,
      } as TaggedItem;
      this.taggingService.addTag(this.iepGoal.taggedItem).subscribe((taggedItem) => {
        this.iepGoal.taggedItem = taggedItem;
      });
    } else {
      this.iepGoal.taggedItem.taggedForPwn = tagFlag;
      this.taggingService.updateTag(this.iepGoal.taggedItem).subscribe((taggedItem) => {
        this.iepGoal.taggedItem = taggedItem;
      });
    }
  }

  openTags() {
    const config: NewWindowConfig = {
      path: `tags/cases/${this.caseId}/goals`,
      popup: true,
    };
    openNewWindow(config);
  }

  @NotifySave
  private async makeSaveRequest({ submitting = false, callback = null }): Promise<void> {
    if (!this.isSaving) {
      this.isSaving = true;
      const iepGoal = {
        id: this.iepGoalId,
        ...this.formGroup.value,
        isComplete: submitting,
        amendmentId: this.amendmentId,
        isActive: true,
      };

      const iepGoalOperationResult = await this.iepGoalService.update(this.iepId, iepGoal).toPromise();
      if (iepGoalOperationResult.succeeded) {
        const iepGoalRec = iepGoalOperationResult.value;
        this.iepGoalId = iepGoalRec.id;
        this.iepGoal = iepGoalRec;
        if (iepGoalRec.additionalQuantifiableData.length > 0 && this.quantData?.isEditingTaskSkillMilestone) {
          this.editingMilestoneId = iepGoalRec.additionalQuantifiableData[iepGoalRec.additionalQuantifiableData.length - 1].id;
        }

        (this.formGroup.get('additionalQuantifiableData') as FormArray).controls.forEach((element, index) => {
          if (!!iepGoalRec.additionalQuantifiableData[index] && !!iepGoalRec.additionalQuantifiableData[index].id) {
            element.get('id').patchValue(iepGoalRec.additionalQuantifiableData[index].id, { emitEvent: false });
          }
        });

        const goalIndex = this.iepGoals.findIndex((x) => x.id === this.iepGoalId);
        if (goalIndex !== -1) {
          this.iepGoals[goalIndex] = iepGoalRec;
        } else {
          this.iepGoals.push(iepGoalRec);
        }
        this.goalDataSource.data = this.iepGoals;
        if (submitting) {
          this.resetForm();
          this.notificationService.success('Completed Goal successfully');
        }

        this.cd.detectChanges();
        this.isSaving = false;
      } else {
        this.notificationService.error(iepGoalOperationResult.errors[0].description);
        this.isSaving = false;
      }

      if (callback) {
        callback();
      }
    }
  }

  private checkSafeSave(): boolean {
    return !('typeahead' in (this.formGroup.controls.standardOfComparisonId.errors ?? {}));
  }

  private addQuantData() {
    this.formGroup.updateValueAndValidity();
    const newQuantData = new FormGroup({
      tieredMilestoneSelected: new FormControl(null),
      secondaryTieredMilestoneSelected: new FormControl(null),
      scaleSelected: new FormControl(null),
      secondaryScalesSelected: new FormControl(null),

      id: new FormControl(null),
      description: new FormControl(null),
      status: new FormControl(MilestoneStatus.active),
      milestoneNumber: new FormControl(
        this.addlQuantifiableDataFormArray.length + 1 // Hasn't been added in yet
      ),
      tieredMilestones: this.initializeTieredMilestones(null),
      scaleRatings: this.initializeScales(null),
      primaryMeasurement: new FormGroup({
        baseline: new FormControl(null, [Validators.required, Validators.min(0)]),
        learnersGoalTarget: new FormControl(null, [
          Validators.required,
          conditionalValidator(() => this.combinedGoal, Validators.max(this.rubricMax)),
        ]),
        unitOfMeasurement: new FormControl(null, [
          conditionalValidator(() => !this.formGroup.get('combinedGoal').value, Validators.required),
        ]),
        dataPoints: this.initializeDataPoints(null),
      }),
      secondaryMeasurement: new FormGroup({
        baseline: new FormControl(null, [Validators.min(0)]),
        learnersGoalTarget: new FormControl(null),
        unitOfMeasurement: new FormControl(null),
        dataPoints: this.initializeDataPoints(null),
      }),
    });

    if (this.formGroup.get('useSameUnitAndTarget').value) {
      const primaryQuantGoalTarget =
        this.formGroup.controls.primaryQuantifiableData[this.controlsWord].primaryMeasurement.controls.learnersGoalTarget.value;
      const primaryQuantUnitOfMeasurement =
        this.formGroup.controls.primaryQuantifiableData[this.controlsWord].primaryMeasurement.controls.unitOfMeasurement.value;
      (newQuantData.controls.primaryMeasurement as FormGroup).controls.learnersGoalTarget.setValue(primaryQuantGoalTarget);
      (newQuantData.controls.primaryMeasurement as FormGroup).controls.unitOfMeasurement.setValue(primaryQuantUnitOfMeasurement);
    }

    this.addlQuantifiableDataFormArray.push(newQuantData);
    this.makeSaveRequest({});
    if (!this.quantData.isEditingTaskSkillMilestone) {
      this.quantData.isEditingTaskSkillMilestone = !this.quantData.isEditingTaskSkillMilestone;
    }
    if (this.combinedGoal) {
      this.modifyRubricScales(this.rubricMax);
    }
  }

  public modifyRubricScales(rubricMax: number) {
    if (!this.addlQuantifiableDataFormArray || this.addlQuantifiableDataFormArray.length === 0 || !rubricMax) {
      return;
    }
    this.addlQuantifiableDataFormArray.controls
      .map((x) => x.get('scaleRatings') as FormArray)
      .forEach((scaleRatings) => {
        const scaleRatingsCount = scaleRatings.length - 1;
        if (rubricMax === scaleRatingsCount) {
          return;
        }

        const diff = rubricMax - scaleRatingsCount;
        if (diff > 0) {
          const baseRating = scaleRatings.length;
          for (let i = baseRating; i < baseRating + diff; i++) {
            scaleRatings.push(
              this.fb.group({
                rating: [i, [Validators.required, Validators.min(0)]],
                description: [null, [Validators.required]],
                secondary: [false],
              })
            );
          }
        } else {
          const finalLength = scaleRatingsCount + diff;
          for (let i = scaleRatingsCount; i > finalLength; i--) {
            const itemIndex = scaleRatings.controls.findIndex((x) => x.value.rating === i);
            scaleRatings.removeAt(itemIndex);
          }
        }
      });
  }

  getStandardOfComparisonText(id) {
    return this.standardsOfComparisonOptions?.find((x) => x.key === id)?.value;
  }

  private setFormGroupValueChanges() {
    this.useSameUnitAndTargetValueChange();
    this.formGroup.controls.combinedGoal.valueChanges.pipe(startWith({}), pairwise()).subscribe(([prev, next]: [boolean, boolean]) => {
      if (next !== prev) {
        if (next) {
          this.addMilestone();
        }
      }
    });

    this.formGroup.controls.rubricMax.valueChanges.subscribe((change) => {
      if (!this.submitting) {
        this.modifyRubricScales(this.rubricMax);
      }
    });

    this.formGroup.controls.methodsToReport.valueChanges.subscribe((change) => {
      if (change?.includes('Other')) {
        this.formGroup.controls.otherMethodToReport.setValidators(Validators.required);
      } else {
        this.formGroup.controls.otherMethodToReport.setValidators(null);
      }
      this.formGroup.controls.otherMethodToReport.updateValueAndValidity();
    });

    this.formGroup.controls.standardOfComparisonId.valueChanges.subscribe((change) => {
      if (this.isStandardOfComparisonOther(change)) {
        this.formGroup.controls.standardOfComparisonOther.setValidators(Validators.required);
      } else {
        this.formGroup.controls.standardOfComparisonOther.setValidators(null);
      }
      this.formGroup.controls.standardOfComparisonOther.updateValueAndValidity();
    });

    this.addlQuantifiableDataFormArray.valueChanges.subscribe(() => {
      this.measurementMilestoneDataSource.data = this.addlQuantifiableDataFormArray.value;

      if (this.formGroup.controls.combinedGoal?.value) {
        let totalBaseline = 0;
        let totalTargetValue = 0;
        this.addlQuantifiableDataFormArray.controls.forEach((quantData) => {
          totalBaseline += quantData.get('primaryMeasurement').get('baseline')?.value;
          totalTargetValue += quantData.get('primaryMeasurement').get('learnersGoalTarget')?.value;
        });
        this.primaryQuantifiableData.controls.primaryMeasurement.get('baseline').setValue(totalBaseline);
        this.primaryQuantifiableData.controls.primaryMeasurement.get('learnersGoalTarget').setValue(totalTargetValue);
      }
    });

    this.setReportingProgressValueChanges();
  }

  private setReportingProgressValueChanges() {
    this.formGroup.controls.sameRateAsPeersReported.valueChanges.subscribe((change) => {
      if (change) {
        this.setControlsToRequired(['sameRatePeriod', 'sameRateFrequency']);
        this.resetControlsValueValidatorAndMarkAsUntounched(['differentRateFrequency', 'differentRatePeriod']);
      } else if (change === false) {
        this.setControlsToRequired(['differentRateFrequency', 'differentRatePeriod']);
        this.resetControlsValueValidatorAndMarkAsUntounched(['sameRatePeriod', 'sameRateFrequency']);
      }
      this.formGroup.controls.sameRateFrequency.setValidators(Validators.min(1));
      this.formGroup.controls.differentRateFrequency.setValidators(Validators.min(1));
      this.formGroup.controls.sameRateFrequency.updateValueAndValidity();
      this.formGroup.controls.differentRateFrequency.updateValueAndValidity();
    });
  }

  private setControlsToRequired(controls: string[]) {
    controls.forEach((control) => {
      this.formGroup.controls[control].setValidators(Validators.required);
      this.formGroup.controls[control].updateValueAndValidity();
    });
  }

  private resetControlsValueValidatorAndMarkAsUntounched(controls: string[]) {
    controls.forEach((control) => {
      this.formGroup.controls[control].setValue(null);
      this.formGroup.controls[control].setValidators(null);
      this.formGroup.controls[control].markAsUntouched();
      this.formGroup.controls[control].updateValueAndValidity();
    });
  }

  private getIepGoals() {
    this.iepGoalService.getAllIepGoals(this.iepId).subscribe((iepGoals) => {
      this.iepGoals = iepGoals;
      this.goalDataSource.data = this.iepGoals;
    });
  }

  private resetForm(stopScroll?: boolean) {
    this.resettingForm = true;
    this.form.reset({ emitEvent: false });
    this.formGroup.controls.combinedGoal.setValue(false, { emitEvent: false });
    (this.primaryQuantifiableData.controls.primaryMeasurement[this.controlsWord].dataPoints as FormArray).clear();
    (this.primaryQuantifiableData.controls.secondaryMeasurement as FormGroup).reset();
    (this.primaryQuantifiableData.controls.secondaryMeasurement[this.controlsWord].dataPoints as FormArray).clear();
    (this.primaryQuantifiableData.controls.tieredMilestones as FormArray).clear();
    (this.primaryQuantifiableData.controls.scaleRatings as FormArray).clear();
    this.addlQuantifiableDataFormArray.clear();
    this.formGroup.controls.methodsToReport.setValue([], { emitEvent: false });
    this.formGroup.controls.peopleMonitoringGoal.setValue([], {
      emitEvent: false,
    });
    this.formGroup.get('primaryQuantifiableData.primaryMeasurement.learnersGoalTarget').clearValidators();
    this.formGroup.get('primaryQuantifiableData.secondaryMeasurement.baseline').clearValidators();
    this.formGroup.get('primaryQuantifiableData.secondaryMeasurement.learnersGoalTarget').clearValidators();
    this.formGroup.get('primaryQuantifiableData.secondaryMeasurement.unitOfMeasurement').clearValidators();

    this.iepGoalId = null;
    this.iepGoal = null;
    if (!!this.quantData) {
      this.quantData.isEditingTaskSkillMilestone = false;
      this.quantData.advancedVisible = false;
      this.quantData.secondaryAdvancedVisible = false;
      this.quantData.secondMeasurement = false;
      this.quantData.secondaryMeasurement.reset();
    }
    this.editingMilestoneId = null;
    if (!stopScroll) {
      this.editingGoalRef.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
        alignToTop: true,
      });
    }
    this.resettingForm = false;
    this.showBody = false;
    this.cd.detectChanges();
  }

  private useSameUnitAndTargetValueChange() {
    this.formGroup.controls.useSameUnitAndTarget.valueChanges.subscribe(async (checkboxChange) => {
      if (!this.addlQuantifiableDataFormArray || this.addlQuantifiableDataFormArray.length === 0 || this.resettingForm) {
        return;
      }

      const confirmationMessage = checkboxChange
        ? 'Clicking yes will overwrite all of the objective unit of measurements and target values.'
        : 'Clicking Yes will remove all of the milestone unit of measurements and target values.';

      const res = await this.showConfirmationDialog(confirmationMessage);

      if (checkboxChange) {
        if (res) {
          // Replace all milestones/quandata with the parent's units/targets
          const primaryQuantGoalTarget =
            this.formGroup.controls.primaryQuantifiableData[this.controlsWord].primaryMeasurement.controls.learnersGoalTarget.value;
          const primaryQuantUnitOfMeasurement =
            this.formGroup.controls.primaryQuantifiableData[this.controlsWord].primaryMeasurement.controls.unitOfMeasurement.value;
          const secondaryQuantUnitOfMeasurement =
            this.formGroup.controls.primaryQuantifiableData[this.controlsWord].secondaryMeasurement.controls.unitOfMeasurement.value;
          this.addlQuantifiableDataFormArray.controls.forEach((addlQuantData) => {
            const addlPrimMeasurement = addlQuantData[this.controlsWord].primaryMeasurement as FormGroup;
            const addlSecMeasurement = addlQuantData[this.controlsWord].secondaryMeasurement as FormGroup;
            addlPrimMeasurement.controls.learnersGoalTarget.setValue(primaryQuantGoalTarget);
            addlPrimMeasurement.controls.unitOfMeasurement.setValue(primaryQuantUnitOfMeasurement);
            addlSecMeasurement.controls.learnersGoalTarget.setValue(primaryQuantGoalTarget);
            addlSecMeasurement.controls.unitOfMeasurement.setValue(secondaryQuantUnitOfMeasurement);
          });
        } else {
          this.formGroup.controls.useSameUnitAndTarget.setValue(false, {
            emitEvent: false,
          });
        }
      }
      // Unchecks checkbox
      else {
        if (res) {
          // Remove all milestones targets and units
          this.addlQuantifiableDataFormArray.controls.forEach((addlQuantData) => {
            const addlPrimMeasurement = addlQuantData[this.controlsWord].primaryMeasurement as FormGroup;
            const addlSecMeasurement = addlQuantData[this.controlsWord].secondaryMeasurement as FormGroup;
            addlPrimMeasurement.controls.learnersGoalTarget.setValue(null);
            addlPrimMeasurement.controls.learnersGoalTarget.enable();
            addlPrimMeasurement.controls.unitOfMeasurement.setValue(null);
            addlPrimMeasurement.controls.unitOfMeasurement.enable();
            addlSecMeasurement.controls.learnersGoalTarget.setValue(null);
            addlSecMeasurement.controls.learnersGoalTarget.enable();
            addlSecMeasurement.controls.unitOfMeasurement.setValue(null);
            addlSecMeasurement.controls.unitOfMeasurement.enable();
          });
        } else {
          this.formGroup.controls.useSameUnitAndTarget.setValue(true, {
            emitEvent: false,
          });
        }
      }
    });
  }

  private getStandardOfComparisonLabel(key: string) {
    return this.standardsOfComparisonOptions.find((x) => x.key === key);
  }

  private getPeopleResponsibleLabels(userIds: string[]) {
    const users: string[] = [];
    userIds?.forEach((userId) => {
      users.push(this.userOptions.find((x) => x.key === userId)?.value);
    });
    return users.join(', ');
  }

  private getMethodsToReportLabel(reportingMethods: string[], otherMethod: string) {
    let modifiedMethods = reportingMethods?.join(', ');
    if (modifiedMethods && modifiedMethods.includes('Other')) {
      if (modifiedMethods.includes(', Other')) {
        modifiedMethods = modifiedMethods.replace(', Other', `, ${otherMethod}`);
      } else {
        modifiedMethods = modifiedMethods.replace('Other', `${otherMethod}`);
      }
    }
    return modifiedMethods;
  }

  /// ToDo: The following method is duplicated in these 3 places:
  /// * IepGoalsComponent.getModalData()
  /// * IepDetailsGoalsComponent.getModalData()
  /// * GoalPlanService.getModalData()
  private getModalData(goal): any {
    const isSecondary = (arr): any[] => {
      return arr.filter((x) => x.secondary);
    };
    const notSecondary = (arr): any[] => {
      return arr.filter((x) => !x.secondary);
    };
    const sortScale = (a: { rating: number }, b: { rating: number }) => {
      if (a && b) {
        if (a.rating > b.rating) return 1;
        if (a.rating < b.rating) return -1;
      }
      return 0;
    };

    const modalData: any = {
      goalArea: this.getGoalAreas(goal),
      currentLevelDescription: goal.currentLevelDescription,
      standardOfComparison: goal.standardOfComparisonOther
        ? goal.standardOfComparisonOther
        : this.getStandardOfComparisonLabel(goal.standardOfComparison),
      descriptionOfCondition: goal.conditionIndividualWillPerform,
      nickname: goal.goalNickName,
      primaryMilestone: [
        {
          firstMeasurement: {
            baseline: goal.primaryQuantifiableData.primaryMeasurement.baseline,
            dataPoints: goal.primaryQuantifiableData?.primaryMeasurement?.dataPoints?.map((dp, index) => ({
              name: `Data Point ${index + 1}:`,
              value: [
                {
                  name: 'Score',
                  value: dp.score,
                  nested: true,
                },
                {
                  name: 'Date',
                  value: this.datePipe.transform(dp.date, shortDateFormat),
                },
              ],
            })),
            unitOfMeasurement: goal.primaryQuantifiableData.primaryMeasurement.unitOfMeasurement,
            learnersGoalTarget: goal.primaryQuantifiableData.primaryMeasurement.learnersGoalTarget,
            tieredMilestones: notSecondary(goal.primaryQuantifiableData.tieredMilestones)?.map((tm) => ({
              name: `Tiered Milestone Number: ${tm.number}:`,
              value: [
                {
                  name: 'Target',
                  value: tm.target,
                },
                {
                  name: 'End Date',
                  value: this.datePipe.transform(tm.endDate, shortDateFormat),
                },
              ],
            })),
            scale: notSecondary(goal.primaryQuantifiableData.scaleRatings)
              ?.sort(sortScale)
              ?.map((s) => ({
                name: 'Scale:',
                value: [
                  {
                    name: 'Rating',
                    value: s.rating,
                  },
                  {
                    name: 'Description',
                    value: s.description,
                  },
                ],
              })),
          },
          secondMeasurement: {
            baseline: goal.primaryQuantifiableData.secondaryMeasurement.baseline,
            dataPoints: goal.primaryQuantifiableData?.secondaryMeasurement?.dataPoints?.map((dp, index) => ({
              name: `Data Point ${index + 1}:`,
              value: [
                {
                  name: 'Score',
                  value: dp.score,
                },
                {
                  name: 'Date',
                  value: this.datePipe.transform(dp.date, shortDateFormat),
                },
              ],
            })),
            unitOfMeasurement: goal.primaryQuantifiableData.secondaryMeasurement.unitOfMeasurement,
            learnersGoalsTarget: goal.primaryQuantifiableData.secondaryMeasurement.learnersGoalTarget,
            tieredMilestones: isSecondary(goal.primaryQuantifiableData.tieredMilestones)?.map((tm) => ({
              name: `Tiered Milestone Number: ${tm.number}:`,
              value: [
                {
                  name: 'Target',
                  value: tm.target,
                },
                {
                  name: 'End Date',
                  value: this.datePipe.transform(tm.endDate, shortDateFormat),
                },
              ],
            })),
            scale: isSecondary(goal.primaryQuantifiableData.scaleRatings)
              ?.sort(sortScale)
              ?.map((s) => ({
                name: 'Scale:',
                value: [
                  {
                    name: 'Rating',
                    value: s.rating,
                  },
                  {
                    name: 'Description',
                    value: s.description,
                  },
                ],
              })),
          },
        },
      ],
      additionalMilestones: goal.additionalQuantifiableData?.map((am) => ({
        name: `Milestone Number ${am.milestoneNumber}`,
        status: am.status,
        description: am.description,
        firstMeasurement: {
          baseline: am.primaryMeasurement.baseline,
          dataPoints: am?.primaryMeasurement?.dataPoints?.map((dp, index) => ({
            name: `Data Point ${index + 1}:`,
            value: [
              {
                name: 'Score',
                value: dp.score,
              },
              {
                name: 'Date',
                value: this.datePipe.transform(dp.date, shortDateFormat),
              },
            ],
          })),
          unitOfMeasurement: am.primaryMeasurement.unitOfMeasurement,
          learnersGoalTarget: am.primaryMeasurement.learnersGoalTarget,
          tieredMilestones: notSecondary(am.tieredMilestones)?.map((tm) => ({
            name: `Tiered Milestone Number: ${tm.number}:`,
            value: [
              {
                name: 'Target',
                value: tm.target,
              },
              {
                name: 'End Date',
                value: this.datePipe.transform(tm.endDate, shortDateFormat),
              },
            ],
          })),
          scale: notSecondary(am.scaleRatings)
            ?.sort(sortScale)
            ?.map((s) => ({
              name: 'Scale:',
              value: [
                {
                  name: 'Rating',
                  value: s.rating,
                },
                {
                  name: 'Description',
                  value: s.description,
                },
              ],
            })),
        },
        secondMeasurement: {
          baseline: am.secondaryMeasurement.baseline,
          dataPoints: am?.secondaryMeasurement?.dataPoints?.map((dp, index) => ({
            name: `Data Point ${index + 1}:`,
            value: [
              {
                name: 'Score',
                value: dp.score,
              },
              {
                name: 'Date',
                value: this.datePipe.transform(dp.date, shortDateFormat),
              },
            ],
          })),
          unitOfMeasurement: am.secondaryMeasurement.unitOfMeasurement,
          learnersGoalTarget: am.secondaryMeasurement.learnersGoalTarget,
          tieredMilestones: isSecondary(am.tieredMilestones)?.map((tm) => ({
            name: `Tiered Milestone Number: ${tm.number}:`,
            value: [
              {
                name: 'Target',
                value: tm.target,
              },
              {
                name: 'End Date',
                value: this.datePipe.transform(tm.endDate, shortDateFormat),
              },
            ],
          })),
          scale: isSecondary(am.scaleRatings)
            ?.sort(sortScale)
            ?.map((s) => ({
              name: 'Scale:',
              value: [
                {
                  name: 'Rating',
                  value: s.rating,
                },
                {
                  name: 'Description',
                  value: s.description,
                },
              ],
            })),
        },
      })),
      howOftenProgressMeasured:
        goal.frequencyAmount && goal.frequencyPeriod ? `${goal.frequencyAmount} time per ${goal.frequencyPeriod}` : null,
      responsibleForMonitoring: this.getPeopleResponsibleLabels(goal.peopleMonitoringGoal),
      collaborators: goal.collaborators,
      descriptionOfMonitoring: goal.monitoringProceduresDescription,
      howOftenReported: goal.sameRateAsPeersReported
        ? goal.sameRateFrequency && goal.sameRatePeriod
          ? `At the same rate as typical peers ${goal.sameRateFrequency} times per ${goal.sameRatePeriod}`
          : null
        : goal.differentRateFrequency && goal.differentRatePeriod
        ? `At the different rate as typical peers ${goal.differentRateFrequency} times per ${goal.differentRatePeriod}`
        : null,
      methodsToReport: this.getMethodsToReportLabel(goal.methodsToReport, goal.otherMethodToReport),
    };

    return modalData;
  }

  showAllErrors() {
    this.formService.showAllErrors();
  }

  canDeactivate(): Observable<DeactivationStatus> | Promise<DeactivationStatus> | DeactivationStatus {
    if (this.editingGoal || !this.showBody) {
      return DeactivationStatus.Accepted;
    }

    if (!this.checkSafeSave()) {
      return DeactivationStatus.NeedsConfirmation;
    }

    return new Promise<DeactivationStatus>((resolve) =>
      this.makeSaveRequest({
        callback: () => resolve(DeactivationStatus.Accepted),
      })
    );
  }

  private showConfirmationDialog<T>(message: string): Promise<T> {
    const dialogRef = this.dialog.open(AreYouSureComponent, {
      width: '450px',
      data: {
        subQuestion: message,
      },
    });

    return dialogRef.afterClosed().toPromise();
  }

  private initializeDataPoint(dataPoint: QuantifiableDataPoint): FormGroup {
    return this.fb.group({
      score: [dataPoint?.score, [Validators.min(0)]],
      date: [dataPoint?.date],
    });
  }

  private initializeDataPoints(dataPoints: QuantifiableDataPoint[]) {
    return this.fb.array((dataPoints || []).map((t) => this.initializeDataPoint(t)));
  }

  private initializeTieredMilestone(tieredMilestone: QuantifiableTieredMilestone) {
    return this.fb.group({
      // eslint-disable-next-line id-blacklist
      number: [tieredMilestone?.number],
      endDate: [tieredMilestone?.endDate],
      target: [tieredMilestone?.target],
      secondary: [tieredMilestone?.secondary],
    });
  }

  private initializeTieredMilestones(tieredMilestones: QuantifiableTieredMilestone[]) {
    return this.fb.array((tieredMilestones || []).map((t) => this.initializeTieredMilestone(t)));
  }

  private initializeScale(scale: QuantifiableDataRating) {
    return this.fb.group({
      rating: [scale?.rating, [Validators.required, Validators.min(0)]],
      description: [scale?.description, [Validators.required]],
      secondary: [scale?.secondary],
    });
  }

  private initializeScales(scales: QuantifiableDataRating[]) {
    return this.fb.array((scales || []).map((t) => this.initializeScale(t)));
  }

  onUploadDocuments(e) {
    this.activeCall = true;
    e.stopPropagation();
    const dialogRef = this.dialog.open(UploadDocumentationModalComponent, {
      data: {
        title: 'Upload',
        learnersName: this.caseSummary.learner.fullName,
      } as DialogData,
      width: '728px',
    });
    dialogRef.afterClosed().subscribe((result: Array<any>) => {
      if (result) {
        const formData = new FormData();
        result.forEach((element) => {
          formData.append('titles', element.title);
          formData.append('documents', element.file, element.file.name);
          formData.append('types', 'User');
        });
        this.documentService.uploadDocuments(formData, this.caseSummary.learnerId).subscribe(() => {
          this.activeCall = false;
        });
      } else {
        this.activeCall = false;
      }
    });
  }

  onOpenGraph(goal) {
    this.progressMonitorBService.getSingle(this.caseSummary.learnerId, goal.id).subscribe({
      next: (res) =>
        this.dialog.open(MultiGraphViewModalComponent, {
          data: { isIep: true, iepGoal: res },
          width: '728px',
        }),
      error: (err) =>
        this.notificationService.errorWithAction("Couldn't open graph", 'Why?', () =>
          this.notificationService.alert(err.error, "Couldn't open graph")
        ),
    });
  }
}
