import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AuthService } from 'src/app/auth/auth.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { FamilyMemberType } from 'src/app/shared/models/learner';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ReferralService } from '../early-access-referral.service';

@Component({
  selector: 'app-referral-list',
  templateUrl: './referral-list.component.html',
  styleUrls: ['./referral-list.component.scss'],
})
export class ReferralListComponent implements OnInit {
  constructor(
    private readonly referralService: ReferralService,
    private readonly authService: AuthService,
    private notificationService: NotificationService
  ) {}

  dataSource = new MatTableDataSource<TableRow>();
  noResults = false;
  displayedColumns = ['learnerName', 'updatedOn', 'referralSourceName', 'aeaName'];
  shortDateFormat = shortDateFormat;

  ngOnInit(): void {
    if (this.authService.isSuperAdmin) {
      this.displayedColumns = ['actions', ...this.displayedColumns];
    }
    this.loadData();
  }

  loadData() {
    const aeaIds = this.authService.isSuperAdmin ? [] : this.authService.user?.aeas?.map((x) => x.id);
    if (!!aeaIds && aeaIds.length === 0 && !this.authService.isSuperAdmin) {
      this.noResults = true;
      this.notificationService.error('No AEAs assigned.');
      return;
    }

    this.referralService.getAeaReferrals(aeaIds).subscribe((res) => {
      if (res.length > 0) {
        this.dataSource.data = res.map((referral) => ({
          referralId: referral.id,
          learnerName: `${referral.childInfo.lastName}, ${referral.childInfo.firstName}`,
          updatedOn: referral.updatedOn,
          referralSourceName:
            referral.referralSource === FamilyMemberType.Parent1
              ? referral.parentInfo.parent1Name
              : referral.referralSource === FamilyMemberType.Parent2
              ? referral.parentInfo.parent2Name
              : referral.referralSource === FamilyMemberType.LivesWith1
              ? referral.familyInfo.livesWith1Name
              : referral.referralSource === FamilyMemberType.LivesWith2
              ? referral.familyInfo.livesWith2Name
              : referral.referralSourceInfo.referralSourceName,
          aeaName: referral.aea?.name || 'Unknown',
        }));
      } else {
        this.noResults = true;
      }
    });
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  async onDelete(referralId: string) {
    const confirm = await this.notificationService.confirmationPromise('Are you sure?', 'Confirm');
    if (confirm) {
      await this.referralService.deleteReferral(referralId).toPromise();
      this.loadData();
    }
  }
}

interface TableRow {
  referralId: string;
  learnerName: string;
  updatedOn: Date;
  aeaName: string;
  referralSourceName: string;
}
