import { Injectable } from '@angular/core';
import { AchieveConfigService } from '../../services/achieve-config-service/achieve-config.service';

@Injectable({
  providedIn: 'root',
})
export class DatabaseLinksService {
  constructor(private achieveConfigService: AchieveConfigService) {}

  getDatabaseLink(linkId: string) {
    return this.linkUrl(linkId);
  }

  getEarlyAccessLink(linkId: string) {
    return this.earlyAccessLinkUrl(linkId);
  }

  getSpecialEducationLink(linkId: string) {
    return this.specialEducationLinkUrl(linkId);
  }

  linkUrl(linkId: string) {
    let url = '';
    if (this.achieveConfigService.settings?.databaseLinks[linkId] !== null) {
      url = this.achieveConfigService.settings?.databaseLinks[linkId];
    }
    return url;
  }

  earlyAccessLinkUrl(linkId: string) {
    let url = '';
    if (this.achieveConfigService.settings.earlyAccessLinks[linkId] !== null) {
      url = this.achieveConfigService.settings.earlyAccessLinks[linkId];
    }
    return url;
  }

  specialEducationLinkUrl(linkId: string) {
    let url = '';
    if (this.achieveConfigService.settings.specialEducationLinks[linkId] !== null) {
      url = this.achieveConfigService.settings.specialEducationLinks[linkId];
    }
    return url;
  }
}
