import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import dayjs from 'dayjs';
import { forkJoin, Subscription } from 'rxjs';
import { AreYouSureComponent } from 'src/app/shared/components/are-you-sure-modal/are-you-sure.component';
import { addWeekdays, shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { AlertConfig } from '../../../shared/components/page-alert/page-alert.component';
import { ConsentFormType } from '../../../shared/models/fiie-consent/consent-form';
import { FamilyConsentStatus } from '../../../shared/models/fiie-consent/family-consent-status';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { ConsentFormService } from '../../../shared/services/consent-form/consent-form.service';
import { IepAmendmentService } from '../../services/iep-amendment.service';
import { IepService } from '../../services/iep.service';
import { TrialPlacementTarget } from '../../models/iep';

@Component({
  selector: 'app-iep-amendment-modal',
  templateUrl: './iep-amendment-modal.component.html',
  styleUrls: ['./iep-amendment-modal.component.scss'],
})
export class IepAmendmentModalComponent implements OnInit, OnDestroy {
  alert: AlertConfig = {
    status: 'info',
    message:
      'Once you click Begin Amendment, an IEP amendment will be created and you will ' +
      'be required to complete the full amendment process. If you do not ' +
      'wish to start the amendment process, please click Cancel to exit.',
  };
  caseId: string;
  iepId: string;
  iepEndDate: Date;
  consentTypes: ConsentFormType[] = [ConsentFormType.ReceiveElectronicCommunication];
  hasElectronicCommunicationConsent = false;
  formGroup = new FormGroup({
    meetingRequired: new FormControl(null, [Validators.required]),
    personContactedParentId: new FormControl(null),
    methodOfContact: new FormControl(null),
    dateOfAmendWithoutMeeting: new FormControl(null),
    includesTrialPlacement: new FormControl(null),
    trialPlacementDate: new FormControl(null),
  });
  shortDateFormat: any;
  yesNoOptions: KeyValuePair[] = [new KeyValuePair(true, 'Yes'), new KeyValuePair(false, 'No')];
  tenDaysPrior = dayjs().startOf('day').add(-10, 'day').toDate();

  personContactedParentOptions: KeyValuePair[] = [];

  methodOfContactOptions: KeyValuePair[] = [
    new KeyValuePair('InPerson', 'In Person'),
    new KeyValuePair('Phone', 'Phone'),
    new KeyValuePair('WrittenNote', 'Written Note'),
    new KeyValuePair('MeetingInProgress', 'Meeting In-Progress'),
  ];
  private subscriptions = new Subscription();
  today = dayjs().startOf('day').toDate();
  submittingForm: boolean;

  get meetingRequiredHasValue() {
    return this.formGroup.get('meetingRequired').value !== null;
  }

  get meetingRequired() {
    return this.formGroup.get('meetingRequired').value;
  }

  get doesIncludeTrialPlacement() {
    return this.formGroup.get('includesTrialPlacement').value !== null && this.formGroup.get('includesTrialPlacement').value === true;
  }

  fourtyfiveWeekdaysFromNow = addWeekdays(dayjs(), 45);

  get disableSubmitBtn() {
    return !this.formGroup.valid;
  }

  constructor(
    private cd: ChangeDetectorRef,
    private iepService: IepService,
    private iepAmendmentService: IepAmendmentService,
    private consentFormService: ConsentFormService,
    private dialog: MatDialog,
    public dialogRef: MatDialogRef<IepAmendmentModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}

  ngOnInit(): void {
    this.shortDateFormat = shortDateFormat;
    this.caseId = this.data.caseId;
    this.iepId = this.data.iepId;
    this.iepEndDate = this.data.iepEndDate;

    if (this.data.includesTrialPlacement) {
      this.formGroup.get('includesTrialPlacement').setValue(true, { emitEvent: false });
      this.formGroup.get('trialPlacementDate').setValue(this.data.trialPlacementDate, { emitEvent: false });
    }
    forkJoin([
      this.iepService.getPersonContactedUsers(this.caseId),
      this.consentFormService.getConsentForms(this.caseId, this.consentTypes),
    ]).subscribe(([teamMembers, consentForms]) => {
      teamMembers.forEach((teamMember) => {
        this.personContactedParentOptions.push(new KeyValuePair(teamMember.id, teamMember.fullName));
      });

      this.hasElectronicCommunicationConsent =
        consentForms.filter((x) => x.statuses.find((xx) => xx.status === FamilyConsentStatus.Approved)).length > 0;

      if (this.hasElectronicCommunicationConsent) {
        this.methodOfContactOptions.push(new KeyValuePair('Email', 'Email'));
        this.methodOfContactOptions.push(new KeyValuePair('Text', 'Text'));
      }
      this.methodOfContactOptions.sort();

      this.setupSubscriptions();
    });
  }

  onSubmit() {
    if (!this.formGroup.valid) {
      return;
    }
    if (!this.data.includesTrialPlacement && this.formGroup.get('includesTrialPlacement').value === true) {
      if (dayjs(this.formGroup.get('trialPlacementDate').value).isAfter(this.fourtyfiveWeekdaysFromNow)) {
        const dialogRef = this.dialog.open(AreYouSureComponent, {
          data: {
            subQuestion: 'This date is greater than 45 week days. Are you sure this is the correct date?',
          },
        });
        dialogRef.afterClosed().subscribe(async (res) => {
          if (!res) {
            this.formGroup.get('trialPlacementDate').setValue(null, { emitEvent: false });
          } else {
            this.doSave();
          }
        });
      } else {
        this.doSave();
      }
    } else {
      this.doSave();
    }
  }

  doSave() {
    this.submittingForm = true;
    const model = {
      id: null,
      iepId: this.iepId,
      includesTrialPlacement: this.formGroup.value.includesTrialPlacement ?? false,
      dateOfAmendWithoutMeeting: this.formGroup.value.dateOfAmendWithoutMeeting,
      meetingRequired: this.formGroup.value.meetingRequired,
      methodOfContact: this.formGroup.value.methodOfContact,
      personContactedParentId: this.formGroup.value.personContactedParentId,
      trialPlacementDate: this.formGroup.value.trialPlacementDate,
      trialPlacementTarget: this.data.trialPlacementTarget,
    };
    this.iepAmendmentService.updateAmendment(this.iepId, model).subscribe((x) => {
      this.submittingForm = false;
      this.dialogRef.close(x);
    });
  }

  onCancel() {
    this.dialogRef.close();
  }

  setupSubscriptions() {
    this.subscriptions.add(
      this.formGroup.get('includesTrialPlacement').valueChanges.subscribe((x) => {
        if (
          this.data.includesTrialPlacement &&
          (this.data.trialPlacementTarget == TrialPlacementTarget.AllItems ||
            (this.data.trialPlacementTarget == TrialPlacementTarget.SomeItems && this.data.includesTrialPlacementServices))
        ) {
          this.formGroup.get('includesTrialPlacement').setValue(true, { emitEvent: false });
        }
        if (this.doesIncludeTrialPlacement) {
          this.formGroup.get('trialPlacementDate').setValidators(Validators.required);
        } else {
          this.formGroup.get('trialPlacementDate').clearValidators();
          this.formGroup.get('trialPlacementDate').setValue(null);
        }
        this.formGroup.updateValueAndValidity();
        this.cd.detectChanges();
      })
    );

    this.subscriptions.add(
      this.formGroup.get('trialPlacementDate').valueChanges.subscribe((x) => {
        if (this.data.includesTrialPlacement) {
          this.formGroup.get('trialPlacementDate').setValue(this.data.trialPlacementDate, { emitEvent: false });
        }
        this.formGroup.updateValueAndValidity();
        this.cd.detectChanges();
      })
    );

    this.subscriptions.add(
      this.formGroup.get('meetingRequired').valueChanges.subscribe((meetingRequiredResult) => {
        if (this.meetingRequiredHasValue && !this.meetingRequired) {
          this.formGroup.get('personContactedParentId').setValidators([Validators.required]);
          this.formGroup.get('methodOfContact').setValidators([Validators.required]);
          this.formGroup.get('dateOfAmendWithoutMeeting').setValidators([Validators.required]);
        } else {
          this.formGroup.get('personContactedParentId').clearValidators();
          this.formGroup.get('methodOfContact').clearValidators();
          this.formGroup.get('dateOfAmendWithoutMeeting').clearValidators();
        }
        this.formGroup.get('personContactedParentId').setValue(null);
        this.formGroup.get('methodOfContact').setValue(null);
        this.formGroup.get('dateOfAmendWithoutMeeting').setValue(null);
        this.formGroup.updateValueAndValidity();
        this.cd.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
