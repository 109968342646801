<div *ngIf="showMeetingInfo">
  <div class="meeting">
    <div class="meeting__title">
      <h4 class="text-primary text-nrm my-0 mr-1">
        {{ familyMeeting.purposesOfMeeting.join(', ') }}
      </h4>
    </div>

    <div class="meeting__meta">
      <p class="my-0 text-md mr-1" *ngIf="familyMeeting.dateAndTime">
        Date:
        {{ familyMeeting?.dateAndTime | dateFormat }} |
      </p>
      <p class="my-0 text-md" *ngIf="familyMeeting.location">Location: {{ familyMeeting?.location }}</p>
    </div>
  </div>
</div>
<div class="my-0">
  <h4 class="mt-2 mb-0">Requested Participants:</h4>
  <table class="w-100" mat-table [dataSource]="participantsDataSource">
    <!-- Name Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef>Names</th>
      <td mat-cell *matCellDef="let group">
        <ng-template [ngIf]="group.get('participant').value.fullName === null || group.get('participant').value.fullName?.length === 0">
          <em>NA</em>
        </ng-template>
        <ng-template [ngIf]="group.get('participant').value.fullName !== null"> {{ group.get('participant').value.fullName }}</ng-template>
      </td>
    </ng-container>

    <!-- Profession / Role / Discipline Column -->
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef>Role</th>
      <td mat-cell *matCellDef="let group">
        <ng-template [ngIf]="group.get('participant').value.role === null || group.get('participant').value.role?.length === 0">
          <em>NA</em>
        </ng-template>
        <ng-template [ngIf]="group.get('participant').value.role !== null">
          {{ getFormattedRole(group.get('participant').value) }}
        </ng-template>
      </td>
    </ng-container>

    <!-- Agency Column -->
    <ng-container matColumnDef="agency">
      <th mat-header-cell *matHeaderCellDef>Agency</th>
      <td mat-cell *matCellDef="let group">
        <ng-template
          [ngIf]="
            group.get('participant').value.role === 'Family' ||
            group.get('participant').value.role === 'Family Friend' ||
            group.get('participant').value.role === 'Family Advocate' ||
            group.get('participant').value.agency === null
          "
        >
          <em>NA</em>
        </ng-template>
        <ng-template
          [ngIf]="
            group.get('participant').value.role !== 'Family' &&
            group.get('participant').value.role !== 'Family Friend' &&
            group.get('participant').value.role !== 'Family Advocate' &&
            group.get('participant').value.agency !== null
          "
        >
          {{ getFormattedAgency(group.get('participant').value.agency, group.get('participant').value.agencyOther) }}
        </ng-template>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
  <p *ngIf="!participantsDataSource">No data yet...</p>
</div>

<div class="display-flex align-items-center justify-content-end mt-3">
  <a *ngIf="isExecusalSigned" href="javascript:void(0)" (click)="viewSignedExcusalForm()" class="mr-3">Agreement to Excuse</a>

  <a *ngIf="isExecusalPending" href="javascript:void(0)" (click)="printExcusalForm()" class="mr-3">Agreement to Excuse</a>

  <button
    *ngIf="isExecusalPending"
    mat-raised-button
    aria-label="Sign Electronically"
    class="mr-1"
    color="primary"
    (click)="signExcusalElectronically()"
  >
    Sign Electronically
  </button>

  <button
    *ngIf="
      intakeType === intakeTypeEnum.PartB &&
      familyMeeting?.status !== meetingStatus.Complete &&
      familyMeeting?.status !== meetingStatus.Cancelled &&
      canRequestExcusal() &&
      learnerHasWorkableCase
    "
    mat-raised-button
    aria-label="Request Excusal"
    class="mr-1"
    color="primary"
    (click)="requestExcusal()"
  >
    Request Excusal
  </button>
  <button mat-raised-button aria-label="Print Notice of Meeting" (click)="openPdf()" class="mr-2" color="primary">
    Print Notice of Meeting
  </button>
  <button
    mat-raised-button
    aria-label="Meeting Roll Call"
    color="primary"
    (click)="openMeetingRollCall()"
    *ngIf="canRollCall(familyMeeting) && learnerHasWorkableCase && familyMeeting?.status !== meetingStatus.Cancelled"
    class="mr-2"
  >
    Meeting Roll Call
  </button>
  <button
    mat-raised-button
    aria-label="Cancel Event"
    color="primary"
    (click)="deleteMeeting()"
    *ngIf="canDelete() && learnerHasWorkableCase && familyMeeting?.status !== meetingStatus.Cancelled"
    class="mr-2"
  >
    Cancel Event
  </button>
  <button
    mat-raised-button
    aria-label="Reschedule Event"
    class="mr-1"
    color="primary"
    (click)="reschedule()"
    *ngIf="
      learnerHasWorkableCase &&
      familyMeeting?.status !== meetingStatus.Cancelled &&
      ((hasPermissionToReschedule && !rollCallComplete(familyMeeting)) ||
        (!rollCallComplete(familyMeeting) && hasPermissionToReschedulePartC))
    "
  >
    Reschedule Event
  </button>
  <button
    mat-raised-button
    aria-label="Add Participants"
    *ngIf="
      learnerHasWorkableCase &&
      familyMeeting?.status !== meetingStatus.Cancelled &&
      ((hasAddingPermission && !rollCallComplete(familyMeeting)) ||
        (intakeType === intakeTypeEnum.PartC && hasAddingPermissionPartC && !rollCallComplete(familyMeeting)))
    "
    color="primary"
    (click)="onAddParticipants()"
  >
    Add Participants
  </button>
</div>
