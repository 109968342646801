import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { LearnerInvite } from '../models/learner-invite';
import { LearnerRegistration } from '../models/learner-registration';
import { KeyValuePair } from '../../../shared/models/key-value-pair';
import { Roles } from '../../auth.service';

const passwordsMatchValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
  const password = control.get('password').value;
  const confirmedPassword = control.get('confirmedPassword').value;

  return password !== confirmedPassword ? { passwordMismatch: true } : null;
};

@Component({
  selector: 'app-learner-portal-registration-form',
  templateUrl: './learner-portal-registration-form.component.html',
  styleUrls: ['./learner-portal-registration-form.component.scss'],
})
export class LearnerPortalRegistrationFormComponent implements OnInit {
  @Input() invite: LearnerInvite;
  @Input() registerError: string;
  @Output() submitted = new EventEmitter<LearnerRegistration>();
  formGroup: FormGroup;
  accessPreferences: KeyValuePair[] = [
    new KeyValuePair(
      Roles.LearnerEdit,
      'I opt to register for interactive access, which includes the ability to sign documents electronically.'
    ),
    new KeyValuePair(
      Roles.LearnerReadOnly,
      'I opt to register for view only access, without the ability to sign documents electronically.'
    ),
  ];

  constructor(private fb: FormBuilder) {}

  ngOnInit(): void {
    this.initializeFormGroup();
    this.formGroup.patchValue({
      invitationId: this.invite.id,
      email: this.invite.email,
    });
  }

  initializeFormGroup() {
    this.formGroup = this.fb.group(
      {
        invitationId: ['', { validators: [Validators.required] }],
        firstName: ['', { validators: [Validators.required] }],
        lastName: ['', { validators: [Validators.required] }],
        dateOfBirth: ['', { validators: [Validators.required] }],
        email: ['', { validators: [Validators.required] }],
        password: ['', { validators: [Validators.required], updateOn: 'change' }],
        confirmedPassword: ['', { validators: [Validators.required], updateOn: 'change' }],
        consentAccepted: [false, { validators: [Validators.requiredTrue] }],
      },
      {
        validators: passwordsMatchValidator,
      }
    );
    if (this.invite.rightsHaveTransferred) {
      this.formGroup.addControl('accessPreference', new FormControl('', Validators.required));
    }
  }

  onSubmit() {
    this.formGroup.markAllAsTouched();
    this.formGroup.updateValueAndValidity();

    if (this.formGroup.valid) {
      this.submitted.emit(this.formGroup.value);
    }
  }
}
