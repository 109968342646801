import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { KeyValuePair } from '@ascend/survey-tool-common';
import { Subscription } from 'rxjs';
import { DhhScreeningRecheckFilters, DhhScreeningRecheckSearchType } from 'src/app/dhh/models/DhhDtos';
import dayjs from 'dayjs';
import { noNumbersValidator } from 'src/app/shared/validators';
import { formatDate } from '@angular/common';
import { DhhService } from 'src/app/dhh/services/dhh.service';
import { DhhLookupsService } from '../../../services/dhh-lookups.service';
import { DhhScreeningService } from '../../dhh-screening/shared/dhh-screening.service';

const constrainedSearch = (control: FormGroup): ValidationErrors | null => {
  const searchType = control.get('searchType').value;
  const firstName = control.get('learnerFirstName').value;
  const lastName = control.get('learnerLastName').value;
  const dobControl = control.get('learnerDOB');
  const stateId = control.get('learnerStateAssignedId').value;

  return (!!firstName && !!lastName && dobControl.valid && !!dobControl.value) || !!stateId || searchType !== 'OnlyOne'
    ? null
    : { searchInputRequired: true };
};

const stateAssignedIdLengthValidator = (control: FormControl): ValidationErrors | null => {
  return control.value && control.value.toString().length !== 10 ? { stateIdInvalidLength: true } : null;
};

@Component({
  selector: 'app-dhh-screening-recheck-filters',
  templateUrl: './dhh-screening-recheck-filters.component.html',
  styleUrls: ['./dhh-screening-recheck-filters.component.scss'],
})
export class DhhScreeningRecheckFiltersComponent implements OnInit {
  @Input() filterType: 'screening' | 'recheck' = 'screening';
  @Input() set customViewOption(value: KeyValuePair) {
    this.viewOptions.splice(1, 0, value);
  }
  @Input() mainButtonLabel = 'Create List';

  @Output() listenFilterParams = new EventEmitter<DhhScreeningRecheckFilters>();
  @Output() listenClearFilters = new EventEmitter();

  private subscription = new Subscription();
  private districtsLookup: any[] = [];
  private buildings: any[] = [];

  today = dayjs().startOf('day').toDate();
  schoolYearDate = this.dhhService.getCurrentYearStartDate();
  twentyFiveYearsAgo = dayjs(this.today).subtract(25, 'year').toDate();

  districtOptions: KeyValuePair[];
  buildingOptions: KeyValuePair[] = [];
  gradeOptions: KeyValuePair[] = [
    new KeyValuePair('PreK', 'PK'),
    new KeyValuePair('Kindergarten', 'K'),
    new KeyValuePair('First', '1'),
    new KeyValuePair('Second', '2'),
    new KeyValuePair('Third', '3'),
    new KeyValuePair('Fourth', '4'),
    new KeyValuePair('Fifth', '5'),
    new KeyValuePair('Sixth', '6'),
    new KeyValuePair('Seventh', '7'),
    new KeyValuePair('Eighth', '8'),
    new KeyValuePair('Ninth', '9'),
    new KeyValuePair('Tenth', '10'),
    new KeyValuePair('Eleventh', '11'),
    new KeyValuePair('Twelfth', '12'),
  ];
  viewOptions: KeyValuePair[] = [
    new KeyValuePair(DhhScreeningRecheckSearchType.All, 'All Learners'),
    new KeyValuePair(DhhScreeningRecheckSearchType.OnlyOne, 'Only One Learner'),
  ];
  teacherOptions: KeyValuePair[] = [];
  formGroup: FormGroup;
  searchParams: DhhScreeningRecheckFilters;

  isReady = false;
  submitAttempted = false;
  filterInitiatedViaBuilding = false;

  get minEndDate() {
    return this.formGroup.get('startDate').value ? this.formGroup.get('startDate').value : this.schoolYearDate;
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly dhhLookupService: DhhLookupsService,
    private readonly dhhService: DhhService,
    private readonly dhhScreeningService: DhhScreeningService
  ) {}

  async ngOnInit(): Promise<void> {
    this.initializeFormGroup();

    await this.getLookups().then(() => {
      this.filterBuildings();
      this.isReady = true;
    });

    this.subscription.add(
      this.formGroup.get('districtId').valueChanges.subscribe((districtId) => {
        if (!this.filterInitiatedViaBuilding) {
          this.formGroup.get('buildingId').reset();
        }
        this.filterInitiatedViaBuilding = false;
        this.filterBuildings(districtId);
      })
    );

    this.subscription.add(
      this.formGroup.get('buildingId').valueChanges.subscribe((buildingId) => {
        const districtId = this.formGroup.controls.districtId.value;
        if (!districtId) {
          const getMatchingDistrictId = this.buildings.find((b) => b.id === buildingId)?.parentId;
          if (getMatchingDistrictId) {
            this.filterInitiatedViaBuilding = true;
            this.formGroup.controls.districtId.setValue(getMatchingDistrictId);
          }
        }
      })
    );

    this.formGroup.valueChanges.subscribe((data) => {
      if (data.buildingId && data.buildingId !== '' && data.districtId && data.districtId !== '') {
        this.viewOptions[2].enabled = true;
      } else {
        this.viewOptions[2].enabled = false;
      }
    });
  }

  async getLookups() {
    const removeChildren = (lookup) => {
      return {
        childRequired: lookup.childRequired,
        id: lookup.id,
        isDeleted: lookup.isDeleted,
        label: lookup.label,
        lookupName: lookup.lookupName,
        parentId: lookup.parentId,
      };
    };

    const locations = await this.dhhLookupService.getLocations().toPromise();
    const aeasLookup = locations ?? [];
    this.districtsLookup = aeasLookup.flatMap((x) => x.children);
    this.districtOptions = this.getHierarchicalLookup(this.districtsLookup);

    const buildingsLookup = this.districtsLookup.flatMap((x) => x.children);
    this.buildings = buildingsLookup.map((x) => removeChildren(x)).sort((a, b) => a.label.localeCompare(b.label)) || [];
  }

  private getHierarchicalLookup(lookup, parentId?) {
    return lookup.filter((x) => (parentId && x.parentId === parentId) || !parentId).map((x) => new KeyValuePair(x.id, x.label));
  }

  initializeFormGroup() {
    this.formGroup = this.fb.group({
      districtId: ['', [Validators.required]],
      buildingId: ['', [Validators.required]],
      grade: [''],
      searchType: ['All', [Validators.required]],
      teacherId: [''],
      startDate: [''],
      endDate: [''],
      learnerLastName: ['', [noNumbersValidator]],
      learnerFirstName: ['', [noNumbersValidator]],
      learnerDOB: [''],
      learnerStateAssignedId: ['', [stateAssignedIdLengthValidator]],
    });

    setTimeout(() => {
      this.subscription.add(
        this.formGroup.get('searchType').valueChanges.subscribe((value) => {
          if (value && value === 'OnlyOne') {
            this.formGroup.setValidators([constrainedSearch]);
          } else {
            this.formGroup.removeValidators([constrainedSearch]);
            this.formGroup.controls.learnerLastName.setValue(null);
            this.formGroup.controls.learnerFirstName.setValue(null);
            this.formGroup.controls.learnerDOB.setValue(null);
            this.formGroup.controls.learnerStateAssignedId.setValue(null);
          }
          this.formGroup.updateValueAndValidity();
        })
      );

      this.subscription.add(
        this.formGroup.get('districtId').valueChanges.subscribe((value) => {
          if (value && this.districtOptions.some((d) => d.key === value)) {
            this.dhhScreeningService.getTeachersByDistrict(value).subscribe((res) => {
              if (res.succeeded) {
                this.teacherOptions = res.value.map((x) => new KeyValuePair(x.id, x.name));
              } else {
                this.dhhScreeningService.handleError('Failed to get teachers', res);
              }
            });
          } else this.teacherOptions = [];
        })
      );
    }, 100);
  }

  filterBuildings(districtId?: string) {
    if (districtId) {
      this.buildingOptions = this.getHierarchicalLookup(this.buildings, districtId);
    } else {
      this.buildingOptions = this.getHierarchicalLookup(this.buildings);
    }
  }

  clear() {
    this.filterBuildings();
    this.formGroup.reset();
    this.formGroup.get('searchType').setValue('All');
    this.submitAttempted = false;
    this.listenClearFilters.emit();
  }

  onSearch() {
    if (!this.formGroup.valid) {
      this.dhhService.handleError('Invalid Search input', { message: 'Please provide all required fields.' });
      return;
    }

    this.setSearchParameters();
    if (this.searchParams.districtId) {
      this.searchParams.aeaId = this.districtsLookup.find((d) => d.id === this.searchParams.districtId)?.parentId;
    }

    this.listenFilterParams.emit(this.searchParams);
  }

  private setSearchParameters() {
    const formValues = this.formGroup.value;
    const keys = Object.keys(formValues);
    this.searchParams = keys.reduce((accum, k) => {
      if (!!formValues[k]) {
        if (formValues[k] instanceof Date) {
          accum[k] = formatDate(formValues[k], 'yyyy-MM-ddT00:00:00.000-0000', 'en-US');
        } else {
          accum[k] = formValues[k].toString().trim();
        }
      }
      return accum;
    }, {} as DhhScreeningRecheckFilters);
  }

  disabledField(): boolean {
    return (
      !this.formGroup.get('buildingId').value ||
      this.formGroup.get('buildingId').value === '' ||
      !this.formGroup.get('districtId').value ||
      this.formGroup.get('districtId').value === ''
    );
  }
}
