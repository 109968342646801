<header class="card-spacing-bottom" *ngIf="evaluation">
  <section>
    <app-page-header
      [pageTitle]="getHeaderText()"
      [backgroundColor]="'yellow'"
      [helpLinks]="true"
      [condensed]="true"
      (helpToggled)="helpToggled($event)"
      [shouldShowDhhBanner]="evaluation?.learner?.isDhh"
    >
      <ng-container pageActions>
        <button
          mat-flat-button
          aria-label="Review Disability Suspected"
          (click)="onReviewDS()"
          *ngIf="isPartB"
          color="primary"
          class="mat-elevation-z0 mr-2"
          tabindex="0"
        >
          <mat-icon class="text-lg" aria-label="Open in a new">open_in_new</mat-icon>
          Review Disability Suspected
        </button>
        <button
          *ngIf="evaluation.intakeType === 'PartB'"
          mat-flat-button
          attr.aria-label="View {{ evaluation.isFinalized ? 'Final' : 'Draft' }} EER"
          color="primary"
          class="mr-2"
          [isBusy]="loading.eer"
          (click)="onViewEer()"
          tabindex="0"
        >
          View {{ evaluation.isFinalized ? 'Final' : 'Draft' }} EER
        </button>
      </ng-container>
    </app-page-header>
  </section>

  <app-learner-info-header [learnerId]="evaluation?.learner.id" [condensed]="true"></app-learner-info-header>

  <section>
    <app-evaluation-nav
      [intakeType]="evaluation.intakeType"
      [evaluation]="evaluation"
      [currentCaseId]="evaluation.caseId"
    ></app-evaluation-nav>
  </section>

  <section *ngIf="evaluation.intakeType === 'PartB'">
    <app-evaluation-domains [evaluation]="evaluation"></app-evaluation-domains>
  </section>

  <section *ngIf="onOverviewPage" class="card-spacing-top">
    <app-evaluation-assessment-info [evaluation]="evaluation"></app-evaluation-assessment-info>
  </section>

  <section class="card-spacing-top" *ngIf="evaluation.intakeType === 'PartC' && evaluation.isInitial">
    <app-eligibility-info [evaluation]="evaluation"></app-eligibility-info>
  </section>
</header>
