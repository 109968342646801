<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mb-0">{{ pageTitle }}</h3>

<div mat-dialog-content class="py-3 mt-3">
  <mat-card class="my-0">
    <table mat-table [dataSource]="dataSource" matSort matSortActive="fullName" matSortDirection="asc" class="mat-elevation-z0">
      <ng-container matColumnDef="select">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="onToggleAll($event)" [checked]="toggleAll" aria-labelledby="Selected"></mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-checkbox (change)="onToggleElement(element)" [checked]="element.selected" aria-labelledby="Selected"></mat-checkbox>
        </td>
      </ng-container>

      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name / First Name</th>
        <td mat-cell *matCellDef="let element">{{ element.fullName }}</td>
      </ng-container>

      <ng-container matColumnDef="dateOfBirth">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>DOB</th>
        <td mat-cell *matCellDef="let element">
          {{ element.dateOfBirth | dobNoTz | dateFormat }}
        </td>
      </ng-container>

      <ng-container matColumnDef="noResults">
        <td mat-footer-cell *matFooterCellDef colspan="3">
          <i>No results...</i>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
      <tr [hidden]="dataSource?.data?.length > 0" mat-footer-row *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"></tr>
    </table>
  </mat-card>
</div>

<div mat-dialog-actions align="end">
  <button mat-raised-button aria-label="Cancel" (click)="onCancel()" class="background-color--muted mr-1" tabindex="0">Cancel</button>

  <button mat-raised-button aria-label="Print" color="primary" (click)="onPrint()" [disabled]="!canPrint" [isBusy]="isPrinting">
    Print
  </button>
</div>
