<mat-card class="my-2">
  <button mat-raised-button aria-label="Add Attachments" color="accent" (click)="openNativeFileSelector()" tabindex="0">
    Add Attachments
  </button>

  <input
    #fileInput
    hidden
    attr.aria-label="Max file size allowed: {{ maxFileSizeLabel }}"
    type="file"
    [multiple]="!singleFile"
    (change)="onFileSelected()"
  />
  <p class="mb-0 mt-3">
    <i class="text-sm">Max file size allowed: {{ maxFileSizeLabel }}</i>
  </p>
  <p class="mt-1" attr.aria-label="Supported file types - {{ allowedFileTypes }}">
    <i class="text-sm">Supported file types - {{ allowedFileTypes }}</i>
  </p>
  <mat-error *ngIf="errors && errors.length > 0">
    <p class="mb-0 mt-3" *ngFor="let error of errors">
      <i class="text-sm"> * {{ error }}</i>
    </p>
  </mat-error>
</mat-card>

<mat-card formArray="formArray">
  <ng-container *ngFor="let fileGroup of formArrayControls; let i = index">
    <div [formGroup]="fileGroup">
      <div class="uploaded-document">
        <div class="uploaded-document__item">
          <p *ngIf="fileGroup.controls.id.value" class="uploaded-document__name mt-0 mb-2">
            {{ fileGroup.controls.fileName.value }}
          </p>
          <p *ngIf="!fileGroup.controls.id.value" class="uploaded-document__name mt-0 mb-2">
            {{ fileGroup.controls.file.value?.name }}
          </p>
        </div>
        <div class="uploaded-document__remove">
          <p class="my-0 text-error color-maroon cursor-pointer" (click)="onRemoveAt(i)">&times;</p>
        </div>
      </div>
      <mat-form-field class="w-100 mt-1">
        <mat-label class="asterisk_input">Document Title</mat-label>
        <input matInput type="text" aria-labelledby="Document Title" placeholder="Document Title" formControlName="title" />
        <mat-error *ngIf="fileGroup.controls.title.hasError('required')"> Document title is <strong>required</strong> </mat-error>
      </mat-form-field>
    </div>
  </ng-container>
</mat-card>
