<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mb-0">Add New Learner</h3>

<div mat-dialog-content class="py-3 mt-3">
  <ng-container *ngIf="data?.learner">
    <app-dhh-learner-entry-form
      [learner]="data.learner"
      [minimalInputRequirement]="true"
      (completeEvent)="onComplete($event)"
      (closeEvent)="onClose()"
    ></app-dhh-learner-entry-form>
  </ng-container>
</div>
