import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, NgZone, OnChanges, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { IfspService } from 'src/app/ifsp/services/ifsp.service';
import {
  IfspIncompleteItemAction,
  IncompleteDataReportItem,
} from 'src/app/shared/components/incomplete-data-report/incomplete-data-report-item';
import { MeetingRollCallComponent } from 'src/app/shared/components/meeting-roll-call/meeting-roll-call.component';
import { CaseSummaryByIfspId } from 'src/app/shared/models/case';
import { PunctuationPipe } from 'src/app/shared/pipes/punctuation.pipe';
import { AlertDialogComponent } from 'src/app/shared/services/notification.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { openNewWindow } from 'src/app/shared/windowHelpers';
import { DateFormatPipe } from '../../../../shared/pipes/date-transform.pipe';
import { IfspServicesService } from '../../../services/ifsp-service.service';
import { OutcomeService } from '../../../services/outcome.service';

@Component({
  selector: 'app-ifsp-completion',
  templateUrl: './ifsp-completion.component.html',
  styleUrls: ['./ifsp-completion.component.scss'],
  providers: [PunctuationPipe],
})
export class IfspCompletionComponent implements OnChanges {
  @Input() incompleteItems: IncompleteDataReportItem[] = [];
  @Input() modificationId: string;
  @Input() evaluationId: string;
  @Input() caseSummary: CaseSummaryByIfspId;

  @Output() gotoProceduralSafeguards = new EventEmitter<void>();
  @Output() gotoConsent = new EventEmitter<void>();
  @Output() gotoLateReason = new EventEmitter<void>();
  @Output() gotoPlod = new EventEmitter<void>();
  @Output() gotoAnnualReview = new EventEmitter<void>();
  @Output() refreshData = new EventEmitter();

  ifspId: string;
  caseId: string;
  dataSource = new MatTableDataSource<IncompleteDataReportItem>();
  displayedColumns = ['action', 'item'];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private ifspService: IfspService,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    private ngZone: NgZone,
    private ifspServicesService: IfspServicesService,
    private ifspOutcomesService: OutcomeService,
    private routingService: RoutingService
  ) {
    this.ifspId = this.route.parent?.snapshot.paramMap.get('ifspId');
    this.caseId = this.route.parent?.snapshot.paramMap.get('caseId');
    if (!this.ifspId || !this.caseId) {
      this.ifspId = this.route.snapshot.paramMap.get('ifspId');
      this.caseId = this.route.snapshot.paramMap.get('caseId');
    }
  }

  ngOnChanges(): void {
    this.dataSource.data = this.incompleteItems;
  }

  navigateToAction(element: IncompleteDataReportItem) {
    switch (element.action) {
      case IfspIncompleteItemAction.AnnualReview:
        this.gotoAnnualReview.emit();
        break;
      case IfspIncompleteItemAction.ProceduralSafeguards:
        this.gotoProceduralSafeguards.emit();
        break;
      case IfspIncompleteItemAction.Consent:
        this.gotoConsent.emit();
        break;
      case IfspIncompleteItemAction.LateReason:
        this.gotoLateReason.emit();
        break;
      case IfspIncompleteItemAction.Eco:
        if (!!this.modificationId) {
          this.gotoPlod.emit();
        } else {
          this.router.navigate(['/cases', this.caseId, 'ifsp', this.ifspId, 'plod']);
        }
        break;
      case IfspIncompleteItemAction.Pwn:
        if (element.id) {
          openNewWindow({
            path: `cases/${this.caseId}/pwns/${element.id || ''}/ifsp/${this.ifspId || ''}`,
            popup: true,
          });
        } else {
          openNewWindow({
            path: `cases/${this.caseId}/pwns/ifsp/${this.ifspId || ''}`,
            popup: true,
          });
        }
        break;
      case IfspIncompleteItemAction.RollCall:
        this.openRollCallModal();
        break;
      case IfspIncompleteItemAction.RollCallModification:
        this.openRollCallModal(true);
        break;
      case IfspIncompleteItemAction.FutureRollCall:
        this.dialog.open(AlertDialogComponent, {
          data: {
            title: 'Meeting Roll Call',
            message: `Meeting Roll Call cannot be edited until the date of the meeting - ${new DateFormatPipe().transform(
              element.meetingDate
            )}`,
          },
        });
        break;
      case IfspIncompleteItemAction.Meeting:
        this.routingService.openScheduleMeeting(this.caseSummary.learnerId, () => {
          this.ngZone.run(() => this.refreshData.emit());
        });
        break;
      case IfspIncompleteItemAction.TransitionPlan:
        this.routingService.transitionPlanning(this.caseSummary.learnerId);
        break;
      case IfspIncompleteItemAction.Evaluation:
        this.routingService.evaluationList(this.caseSummary.learnerId);
        break;
      case IfspIncompleteItemAction.EvaluationOverview:
        this.router.navigate(this.routingService.evalOverview(this.caseId, this.evaluationId));
        break;
      case IfspIncompleteItemAction.ExternalConsent:
        this.router.navigate(['/learner-management', this.caseSummary?.learnerId, 'documentation']);
        break;
      case IfspIncompleteItemAction.FamilyContact:
        this.router.navigate(['/learner-management', this.caseSummary?.learnerId, 'family-contact']);
        break;
      case IfspIncompleteItemAction.Intake:
        this.router.navigate(['/cases', this.caseSummary?.caseId, 'evaluation', 'early-access-intake', this.caseSummary?.caseId]);
        break;
      case IfspIncompleteItemAction.EvaluationFamilyInterview:
      case IfspIncompleteItemAction.EvaluationAreas:
      case IfspIncompleteItemAction.EvaluationToolUsed:
      case IfspIncompleteItemAction.EvaluationNotesIncomplete:
      case IfspIncompleteItemAction.EvaluationNoteStrengthIncomplete:
      case IfspIncompleteItemAction.EvaluationNotesResourceIncomplete:
      case IfspIncompleteItemAction.EvaluationNotesPriorityIncomplete:
      case IfspIncompleteItemAction.EvaluationNotesRoutineIncomplete:
      case IfspIncompleteItemAction.EvaluationNotesAdditionalResultsIncomplete:
      case IfspIncompleteItemAction.EvaluationNotesConcernsIncomplete:
      case IfspIncompleteItemAction.EvaluationNotesOtherIncomplete:
        this.routingService.evalPartCDetails(this.caseId, this.evaluationId);
        break;
      default:
        if (this.modificationId) {
          switch (element.action) {
            case IfspIncompleteItemAction.Services:
              this.ifspServicesService.openServicesAccordion.next();
              break;
            case IfspIncompleteItemAction.Outcomes:
              this.ifspOutcomesService.openOutcomesAccordion.next();
              break;
            case IfspIncompleteItemAction.Plod:
              this.gotoPlod.emit();
              this.ifspService.openPlodAccordion.next();
              break;
          }
        } else {
          element.id
            ? this.router.navigate(['/cases', this.caseId, 'ifsp', this.ifspId, element.action, element.id])
            : this.router.navigate(['/cases', this.caseId, 'ifsp', this.ifspId, element.action]);
        }
        break;
    }
  }

  openRollCallModal(isModification = false) {
    this.ifspService.getOldestIncompleteIfspMeeting(this.caseId, this.modificationId).subscribe((meeting) => {
      const dialogRef = this.dialog.open(MeetingRollCallComponent, {
        width: '1100px',
        data: {
          meeting,
          caseSummary: this.caseSummary,
        },
      });
      dialogRef.afterClosed().subscribe(() => {
        this.refreshData.emit();
      });
    });
  }
}
