import { Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NotificationService } from '../../services/notification.service';
import { PdfOutputs, ReportingService } from '../../services/reporting/reporting.service';
import { openPdfWindow } from '../../windowHelpers';
import { AreYouSureComponent } from '../are-you-sure-modal/are-you-sure.component';

@Component({
  selector: 'app-create-output-button',
  templateUrl: './create-output-button.component.html',
  styleUrls: ['./create-output-button.component.scss'],
})
export class CreateOutputButtonComponent {
  @Input() output: PdfOutputs;
  @Input() apiId: string;
  @Input() apiAdditionalId: string;
  @Input() learnerId: string;
  @Input() documentId: string;
  @Input() openNewIcon = false;
  @Input() buttonText: string;
  @Input() buttonColor: 'primary' | 'accent' = 'primary';
  @Input() isDraft = false;
  @Input() buttonType: 'mat-raised-button' | 'mat-flat-button' = 'mat-raised-button';
  @Input() hasOpenModification = false;
  @Input() pushDraftToPortal = false;

  loading = false;
  pushOutputToFamily = false;

  constructor(
    private readonly reportingService: ReportingService,
    private readonly notificationService: NotificationService,
    private dialog: MatDialog
  ) {}

  onOpenOutput() {
    if (
      this.output === PdfOutputs.IfspOutput ||
      this.output === PdfOutputs.IepOutput ||
      this.output === PdfOutputs.BipOutput ||
      this.output === PdfOutputs.FbaOutput ||
      this.output === PdfOutputs.Reevaluation ||
      this.output === PdfOutputs.ClpOutput ||
      this.output === PdfOutputs.EsyPlan
    ) {
      if (this.pushDraftToPortal && (this.isDraft || this.hasOpenModification)) {
        const dialogRef = this.dialog.open(AreYouSureComponent, {
          width: '450px',
          data: {
            question: 'Are you sure?',
            subQuestion: 'Clicking Yes will generate a new draft PDF that will be sent to the portal for the family to view.',
          },
        });
        dialogRef.afterClosed().subscribe((confirmed) => {
          if (confirmed) {
            this.pushOutputToFamily = true;
            this.createOutput();
          }
        });
      } else {
        if (this.isDraft || this.hasOpenModification) {
          this.pushOutputToFamily = false;
          this.createOutput();
        } else {
          this.documentId ? this.viewOutput() : this.createOutput();
        }
      }
    } else if (this.isDraft) {
      this.createOutput();
    } else {
      this.documentId ? this.viewOutput() : this.createOutput();
    }
  }

  private viewOutput() {
    openPdfWindow(this.learnerId, this.documentId);
  }

  private createOutput() {
    this.loading = true;
    if (this.output === PdfOutputs.IfspOutput) {
      this.reportingService.createIfspOutput(this.apiId, this.pushOutputToFamily, this.apiAdditionalId).subscribe({
        next: (documentId: string) => this.handleCreateOutput(documentId),
        error: (err) => this.handleError(err),
      });
    } else if (this.output === PdfOutputs.IepOutput) {
      this.reportingService.createIepOutput(this.apiId, null, this.pushOutputToFamily, this.apiAdditionalId).subscribe({
        next: (documentId: string) => this.handleCreateOutput(documentId),
        error: (err) => this.handleError(err),
      });
    } else if (this.output === PdfOutputs.BipOutput) {
      this.reportingService.createBip(this.apiAdditionalId, this.apiId, this.pushOutputToFamily).subscribe({
        next: (documentId: string) => this.handleCreateOutput(documentId),
        error: (err) => this.handleError(err),
      });
    } else if (this.output === PdfOutputs.FbaOutput) {
      this.reportingService.createFbaOutput(this.apiId, this.pushOutputToFamily).subscribe({
        next: (documentId: string) => this.handleCreateOutput(documentId),
        error: (err) => this.handleError(err),
      });
    } else if (this.output === PdfOutputs.Reevaluation) {
      this.reportingService.createReevaluationOutput(this.apiId, false, this.pushOutputToFamily).subscribe({
        next: (documentId: string) => this.handleCreateOutput(documentId),
        error: (err) => this.handleError(err),
      });
    } else if (this.output === PdfOutputs.ClpOutput) {
      this.reportingService.createClpOutput(this.apiId, this.pushOutputToFamily).subscribe({
        next: (documentId: string) => this.handleCreateOutput(documentId),
        error: (err) => this.handleError(err),
      });
    } else if (this.output === PdfOutputs.EsyPlan) {
      this.reportingService.createEsyPlan(this.apiId, this.pushOutputToFamily).subscribe({
        next: (documentId: string) => this.handleCreateOutput(documentId),
        error: (err) => this.handleError(err),
      });
    } else {
      this.reportingService.createUniversalOutput(this.apiId, this.output, this.apiAdditionalId).subscribe({
        next: (documentId: string) => this.handleCreateOutput(documentId),
        error: (err) => this.handleError(err),
      });
    }
  }

  private handleCreateOutput(documentId: string) {
    this.documentId = documentId;
    this.notLoading();
    this.viewOutput();
  }

  public handleError(err) {
    this.notLoading();
    this.reportingService.handleOutputError(err);
  }

  private notLoading(): void {
    this.loading = false;
  }
}
