import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { Aea } from 'src/app/shared/models/aea';
import { FamilyMemberType } from 'src/app/shared/models/learner';
import { AeaService } from 'src/app/shared/services/aea/aea.service';
import { DatabaseLinksService } from '../../../shared/services/database-links/database-links.service';

@Component({
  selector: 'app-referral-submit',
  templateUrl: './referral-submit.component.html',
  styleUrls: ['./referral-submit.component.scss'],
})
export class ReferralSubmitComponent implements OnInit {
  referralId: string;
  aea: Aea;
  displayAgency: boolean;
  referralSourceIsParent: boolean;
  referralSource: string;
  familyMemberType = FamilyMemberType;

  constructor(
    public authService: AuthService,
    private aeaService: AeaService,
    private route: ActivatedRoute,
    private readonly databaseLinksService: DatabaseLinksService,
    private router: Router
  ) {}

  ngOnInit() {
    this.referralId = this.route.snapshot.queryParamMap.get('id');
    const aeaId = this.route.snapshot.queryParamMap.get('aea');
    this.referralSource = this.route.snapshot.queryParamMap.get('referralSource');
    if (aeaId !== null) {
      this.getAeaInformation(aeaId);
    }
  }

  getAeaInformation(id: string) {
    this.aeaService.getAeaInformation(id).subscribe((res: Aea) => {
      this.aea = res;
      this.displayAgency = true;
    });
  }

  getNewReferralLink() {
    if (this.authService.isAuthenticated) {
      return ['/', 'child-find', 'early-access-referral'];
    } else {
      return ['/', 'early-access-referral'];
    }
  }

  openIowaIdeaInformationUrl() {
    window.open(this.databaseLinksService.getDatabaseLink('iowaFamilySupportNetwork'), '_blank');
  }
}
