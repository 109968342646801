import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppMatModule } from 'src/app/shared/app-mat.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ServiceCoordinatorAssignmentRoutingModule } from './service-coordinator-assignment-routing.module';
import { ServiceCoordinatorAssignmentComponent } from './service-coordinator-assignment.component';
import { ServiceCoordinatorsComponent } from './service-coordinators/service-coordinators.component';
import { UnassignedCasesComponent } from './unassigned-cases/unassigned-cases.component';

@NgModule({
  declarations: [ServiceCoordinatorAssignmentComponent, UnassignedCasesComponent, ServiceCoordinatorsComponent],
  imports: [CommonModule, AppMatModule, ServiceCoordinatorAssignmentRoutingModule, SharedModule, ReactiveFormsModule, FormsModule],
  exports: [ServiceCoordinatorAssignmentComponent, UnassignedCasesComponent, ServiceCoordinatorsComponent],
})
export class ServiceCoordinatorAssignmentModule {}
