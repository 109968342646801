import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { BaseComponent } from 'src/app/shared/components/base-component/base-component';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ViewMoreModalData } from 'src/app/shared/modals/view-more-modal/view-more-modal.component';
import { DeactivationService } from 'src/app/shared/services/deactivation.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { needsLabelOffset, openViewMore } from 'src/app/shared/tableHelpers';
import { TrialPlacementTarget } from '../../../../../models/iep';
import { IepAccommodationDto } from '../../../../../models/iep-accommodation';
import { IepAmendment } from '../../../../../models/iep-amendment';
import { IepAccommodationService } from '../../../../../services/iep-accommodation.service';

@Component({
  selector: 'app-iep-accommodations-table',
  templateUrl: './iep-accommodations-table.component.html',
  styleUrls: ['./iep-accommodations-table.component.scss'],
  providers: [DatePipe],
})
export class IepAccommodationsTableComponent extends BaseComponent implements OnInit, OnDestroy {
  @Input() iepId: string;
  @Input() isProfilePage = false;
  @Input() isEditing = false;
  @Input() amendmentId: string;
  @Input() amendments: IepAmendment[];
  @Input() iepIncludesTrialPlacement: boolean;
  @Input() trialPlacementTarget: TrialPlacementTarget;
  trialPlacementTargetEnum = TrialPlacementTarget;

  @Output() accommodationsCount = new EventEmitter<number>();
  @Output() editAccommodation = new EventEmitter<IepAccommodationDto>();

  accommodationUpdatedSubscription: Subscription;

  accommodations: Array<IepAccommodationDto> = [];
  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentIsFinalized() {
    return this.amendment?.finalizeDate !== null;
  }

  //#region summary
  displayedColumns: string[] = ['actions', 'description', 'frequencyPeriod', 'providers'];

  dataSource: MatTableDataSource<any>;
  //#endregion

  shortDateFormat = shortDateFormat;

  constructor(
    private iepAccommodationService: IepAccommodationService,
    private fb: FormBuilder,
    private notificationService: NotificationService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private datePipe: DatePipe,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource([]);
    this.getAccommodations();
    this.subscriptions.add(this.iepAccommodationService.accommodationsUpdated$.subscribe(() => this.getAccommodations()));
    this.dataSource.sortingDataAccessor = (item: IepAccommodationDto, columnId) => {
      switch (columnId) {
        case 'providers':
          return this.getProviderNames(item?.providers);
        default:
          return item[columnId];
      }
    };
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  onView(accommodation) {
    const modalData: ViewMoreModalData[] = [
      {
        name: 'Description',
        value: accommodation.description,
      },
      {
        name: 'Frequency',
        value: accommodation.frequencyPeriod,
      },
    ];

    if (
      this.iepIncludesTrialPlacement &&
      (this.trialPlacementTarget === this.trialPlacementTargetEnum.AllItems || accommodation.includesTrialPlacement)
    ) {
      modalData.unshift({
        name: 'Included In Trial Placement',
        value: 'Yes',
      });
    }

    if (accommodation.providers) {
      const data = {
        name: 'Providers',
        value: this.getProviderNames(accommodation.providers, accommodation.otherProviderName),
      };

      modalData.push(data);
    }

    openViewMore(this.dialog, modalData);
  }

  goToAccommodation() {
    const caseId = this.route.parent?.snapshot.paramMap.get('caseId');
    this.router.navigate(['/', 'cases', caseId, 'iep', this.iepId, 'services']);
  }

  // #region Accommodations
  getAccommodations() {
    this.iepAccommodationService.getAllAccommodations(this.iepId).subscribe((accommodations) => {
      this.accommodations = accommodations;
      this.dataSource.data = this.accommodations;
      this.accommodationsCount.emit(this.accommodations.length);
    });
  }

  onEdit(accommodation) {
    this.editAccommodation.emit(accommodation);
  }

  onRemove(accommodation) {
    this.notificationService.confirmation('Are you sure you want to delete this accommodation?', () => {
      this.iepAccommodationService.deleteAccommodation(this.iepId, accommodation.id).subscribe(() => {
        this.notificationService.success('Accommodation deleted');
      });
    });
  }
  // #endregion

  getProviderNames(providers, otherProvider?: string) {
    const providersMapped = providers?.map((x) => `${x.firstName} ${x.lastName || ''}`).join(', ');

    if (otherProvider && providersMapped) {
      return providersMapped + `, ${otherProvider}`;
    } else if (otherProvider) {
      return otherProvider;
    }

    return providersMapped;
  }

  convertToShortDate(date: Date) {
    return this.datePipe.transform(date, shortDateFormat);
  }

  checkLabelOffset(row): boolean {
    return needsLabelOffset(row);
  }

  lastFinalizedDate(amendmentId: string) {
    if (this.amendments && this.amendments.length > 0) {
      const amendment = this.amendments.find((a) => a.id === amendmentId);
      return amendment?.finalizeDate;
    }
    return null;
  }
}
