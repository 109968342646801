import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { forkJoin } from 'rxjs';
import { ProviderInfoFormService } from 'src/app/evaluation/shared/components/health-info-form/provider-info-form/provider-info-form.service';
import { FamilyProviderMemberSearchModalComponent } from 'src/app/shared/modals/family-provider-member-search-modal/family-provider-member-search-modal.component';
import { ParticipantType } from 'src/app/shared/modals/participant-search/participant-type';
import {
  TeamMemberSearchModalComponent,
  TeamSearchModalData,
} from 'src/app/shared/modals/team-member-search-modal/team-member-search-modal.component';
import { Aea } from 'src/app/shared/models/aea';
import { Agency } from 'src/app/shared/models/agency';
import { CaseSummary, CaseUserRole, CaseUserSummary, IntakeType } from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { Profession } from 'src/app/shared/models/profession';
import { AeaService } from 'src/app/shared/services/aea/aea.service';
import { ProfessionService } from 'src/app/shared/services/profession/profession.service';
import { AuthService } from '../../../auth/auth.service';
import { ECPSearchModalComponent, ECPSearchModalData } from '../../../shared/modals/ecp-search-modal/ecp-search-modal.component';
import { LearnerSummary } from '../../../shared/models/learner';
import { getAgency } from '../../../shared/models/team';
import { ConfirmationDialogComponent } from '../../../shared/services/notification.service';
import { TeamService } from '../../../shared/services/team/team.service';
import { EcProgramsService } from '../../early-childhood/ec-programs/ec-programs.service';
import { FamilyMeetingService } from '../family-meeting.service';
import { MeetingParticipantRead } from './meeting-participants';
import { ParticipantRole } from './participant-role';

@Component({
  selector: 'app-participants-list',
  templateUrl: './participants-list.component.html',
  styleUrls: ['./participants-list.component.scss'],
})
export class ParticipantsListComponent implements OnInit, OnChanges {
  @Input() caseSummary: CaseSummary;
  @Input() learner: LearnerSummary;
  @Input() formGroup: FormGroup;
  @Input() requestAgreementToExcuse: boolean;
  dataSource = new MatTableDataSource<MeetingParticipantRead>();
  displayedColumns: Array<string> = ['select', 'name', 'role', 'agency'];
  aeaList: Aea[] = [];
  professionList: Profession[] = [];
  agencyOptions: KeyValuePair[] = [];
  agencyList: Agency[] = [];
  professionOptions: KeyValuePair[] = [];
  familyProviders: MeetingParticipantRead[] = [];
  participantRoles = ParticipantRole;
  editingModel: MeetingParticipantRead;
  modelFormGroup: FormGroup;
  familyMembers: MeetingParticipantRead[] = [];
  availableProviders: MeetingParticipantRead[] = [];
  availableMembers: MeetingParticipantRead[] = [];
  users: CaseUserSummary[];
  ecpContacts: MeetingParticipantRead[];
  addParticipantFormValid = false;
  displayLeaHelperText = false;
  get participants() {
    return this.formGroup.get('participants').value as MeetingParticipantRead[];
  }

  addParticipantFormGroup = this.fb.group({
    name: [null, Validators.required],
    agency: [null, Validators.required],
    agencyOther: [null],
    role: [null, Validators.required],
    roleOther: [null],
    excusalDate: [null],
  });

  constructor(
    private fb: FormBuilder,
    private aeaService: AeaService,
    private professionService: ProfessionService,
    private providerService: ProviderInfoFormService,
    private dialog: MatDialog,
    private teamService: TeamService,
    private authService: AuthService,
    private ecProgramsService: EcProgramsService,
    private familyMeetingService: FamilyMeetingService
  ) {}

  ngOnInit(): void {
    this.dataSource.data = this.participants;
    this.getAeas();
    this.getProfessions();

    if (this.formGroup.controls.requestAgreementToExcuse?.value) {
      this.displayedColumns.push('excusal');
    }

    // TODO: Add back in once 18790 is figured out
    // this.familyMeetingService.hasPartBPurposeSelected$.subscribe((value) => {
    //   if (value) {
    //     this.displayedColumns.push('leaRepresentative');
    //     this.displayLeaHelperText = true;
    //   } else {
    //     const index = this.displayedColumns.indexOf('leaRepresentative');
    //     if (index > -1) {
    //       this.displayedColumns.splice(index);
    //       this.displayLeaHelperText = false;
    //     }
    //   }
    // });

    this.modelFormGroup = this.fb.group({
      fullName: [null, Validators.required],
      agency: [null, Validators.required],
      agencyOther: [null],
      role: [null, Validators.required],
      roleOther: [null],
      excusalDate: [null],
    });
    this.users = this.caseSummary?.caseUsers;
    this.formGroup.get('participants').valueChanges.subscribe((participantsChanged) => {
      this.dataSource.data = participantsChanged;
    });
    this.setupParticipantsForm();
    this.getFamilyMembers();
    this.getFamilyProviders();

    // TODO: Add back in once 18790 is figured out
    // if (this.learner?.activeCases?.length === 1 && !!this.learner?.activeCases?.find((x) => x.intakeType === IntakeType.PartB)) {
    //   this.familyMeetingService.hasPartBPurposeSelected.next(true);
    // }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.requestAgreementToExcuse.previousValue !== changes?.requestAgreementToExcuse.currentValue) {
      if (changes?.requestAgreementToExcuse.currentValue) {
        this.displayedColumns.push('excusal');
      } else {
        this.displayedColumns = this.displayedColumns.filter((x) => x !== 'excusal');
      }
    }
  }

  setupParticipantsForm() {
    this.addParticipantFormGroup.valueChanges.subscribe(() => {
      this.addParticipantFormValid = this.addParticipantFormGroup.valid;
    });
    this.addParticipantFormGroup.get('role').valueChanges.subscribe((role) => {
      this.buildAeaOptions(role === 'Family' ? [] : this.aeaList);
    });
  }
  getAeas() {
    if (this.caseSummary?.intakeType === IntakeType.PartB) {
      this.aeaService.getPublicAeas().subscribe((res) => {
        this.aeaList = res.slice();
        this.buildAeaOptions(res);
      });
    } else {
      this.aeaService.getAllAeas().subscribe((res) => {
        this.aeaList = res.slice();
        this.buildAeaOptions(res);
      });
    }
  }
  buildAeaOptions(aeas: Aea[]) {
    // TODO: The Keyvalue Pair is mapped to name/name, label/label (not using id)
    // because we just store the plain string for Agencies and Roles/Professions
    this.agencyOptions = aeas.map((x) => new KeyValuePair(x.name, x.name));
    this.agencyOptions.push(new KeyValuePair('None', 'None'));
    this.agencyOptions.push(new KeyValuePair('Other', 'Other'));
    this.agencyList = this.agencyOptions.map((x) => ({
      id: x.key,
      label: x.value,
    }));
  }
  getProfessions() {
    this.professionService.getProfessions().subscribe((res) => {
      this.professionOptions = res.map((x) => new KeyValuePair(x.label, x.label));
      this.professionOptions.push(new KeyValuePair('Other', 'Other'));
      this.professionList = this.professionOptions.map((x) => ({ id: x.key, label: x.value }));
    });
  }

  isOtherRole(roleLabelAsId: string) {
    const role = this.professionList.find((x) => x.label === roleLabelAsId);
    if (role && role.label === 'Other') {
      return true;
    } else {
      this.addParticipantFormGroup.get('roleOther').reset();
      this.addParticipantFormGroup.get('roleOther').clearValidators();
      this.addParticipantFormGroup.get('roleOther').updateValueAndValidity();
      return false;
    }
  }

  isOtherAgency(agencyLabelAsId: string) {
    const agency = this.agencyList.find((x) => x.label === agencyLabelAsId);
    if (agency && agency.label === 'Other') {
      return true;
    } else {
      this.addParticipantFormGroup.get('agencyOther').reset();
      this.addParticipantFormGroup.get('agencyOther').clearValidators();
      this.addParticipantFormGroup.get('agencyOther').updateValueAndValidity();
      return false;
    }
  }

  isOtherRoleEdit(roleLabelAsId: string) {
    const role = this.professionList.find((x) => x.label === roleLabelAsId);
    if (role && role.label !== 'Other') {
      this.modelFormGroup.get('roleOther').reset();
    }
    return role && role.label === 'Other';
  }

  isOtherAgencyEdit(agencyLabelAsId: string) {
    const agency = this.agencyList.find((x) => x.label === agencyLabelAsId);
    if (agency && agency.label !== 'Other') {
      this.modelFormGroup.get('agencyOther').reset();
    }
    return agency && agency.label === 'Other';
  }

  getProperRole(participant) {
    if (participant?.role === CaseUserRole.ServiceCoordinator) {
      return 'Service Coordinator';
    } else {
      return participant?.role;
    }
  }

  save() {
    const participant = this.participants.find((x) => x === this.editingModel);
    this.editingModel = null;
    if (participant) {
      participant.fullName = this.modelFormGroup.get('fullName').value;
      participant.agency = this.modelFormGroup.get('agency').value;
      participant.role = this.modelFormGroup.get('role').value;
      participant.roleOther = this.modelFormGroup.get('roleOther').value;
      participant.agencyOther = this.modelFormGroup.get('agencyOther').value;
      participant.excusalDate = this.modelFormGroup.get('excusalDate').value ? dayjs().toDate() : null;
      this.formGroup.patchValue({
        participants: this.participants,
      });
    } else {
      this.formGroup.get('participants').setValue([...this.participants, this.modelFormGroup.value]);
    }
    this.formGroup.markAsDirty();
    this.formGroup.updateValueAndValidity();
    this.dataSource.data = this.participants;
  }

  cancel() {
    this.editingModel = null;
  }

  edit(model: MeetingParticipantRead) {
    this.editingModel = model;
    this.modelFormGroup.patchValue(model);
  }

  isFamily(model: MeetingParticipantRead) {
    return (
      model.role === ParticipantRole.family || model.role === ParticipantRole.familyFriend || model.role === ParticipantRole.familyAdvocate
    );
  }

  removeParticipant(participant: MeetingParticipantRead) {
    if (participant.role === 'Family') {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        data: {
          title: 'Remove Family Member',
          message: 'Are you sure you want to remove this family member?',
        },
      });
      dialogRef.afterClosed().subscribe((res) => {
        if (res) {
          const participants = this.formGroup.get('participants').value;
          participants.splice(participants.indexOf(participant, 0), 1);
          this.formGroup.get('participants').setValue([...participants]);

          // Updates users
          this.users = this.users.filter((x) => x.id !== participant.userId);
          this.updateAvailableProvidersMembers();
        }
      });
    } else {
      const participants = this.formGroup.get('participants').value;
      participants.splice(participants.indexOf(participant, 0), 1);
      this.formGroup.get('participants').setValue([...participants]);

      // Updates users
      this.users = this.users.filter((x) => x.id !== participant.userId);
      this.updateAvailableProvidersMembers();
    }
  }

  // TODO: Add back in once 18790 is figured out
  // leaRepresentativeChecked(userId: string) {
  //   const leaRepresentativeId = this.formGroup.get('leaRepresentative').value;
  //   return !!leaRepresentativeId && leaRepresentativeId === userId;
  // }

  // updateLeaRepresentative(event: MatCheckboxChange, userId) {
  //   const leaRepresentativeId = this.formGroup.get('leaRepresentative').value;
  //   const openDialog = () => {
  //     return this.dialog.open(ConfirmationDialogComponent, {
  //       data: {
  //         title: 'Are you sure you want to remove this LEA representative?',
  //         message: 'Doing so will require the selection of a new LEA representative.',
  //       },
  //     });
  //   };
  //   if (event.checked) {
  //     if (!!leaRepresentativeId) {
  //       const dialogRef = openDialog();
  //       dialogRef.afterClosed().subscribe((value) => {
  //         if (value) {
  //           this.formGroup.get('leaRepresentative').setValue(userId);
  //         } else {
  //           event.source.checked = false;
  //         }
  //       });
  //     } else {
  //       this.formGroup.get('leaRepresentative').setValue(userId);
  //     }
  //   } else {
  //     if (!!leaRepresentativeId) {
  //       const dialogRef = openDialog();
  //       dialogRef.afterClosed().subscribe((value) => {
  //         if (value) {
  //           this.formGroup.get('leaRepresentative').setValue(null);
  //         } else {
  //           event.source.checked = true;
  //         }
  //       });
  //     } else {
  //       this.formGroup.get('leaRepresentative').setValue(null);
  //     }
  //   }
  // }

  openTeamDialog(): void {
    const userIds = this.users.map((u) => u.id);

    const config = {
      userId: this.authService.user.id,
      intakeType: this.caseSummary.intakeType,
      learnerId: this.learner.id,
      isMeeting: true,
      userIds,
    };

    const dialogRef = this.dialog.open(TeamMemberSearchModalComponent, {
      width: '728px',
      data: {
        config,
      } as TeamSearchModalData,
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result || !result.newUserIds || !result.teamUsers) return;

      if (result.newUserIds) {
        const newParticipants = result.teamUsers
          .filter((x) => result.newUserIds.includes(x.userId))
          .map((res) => {
            return {
              fullName: res.fullName,
              userId: res.userId,
              agency: getAgency(res, this.learner),
              agencyOther: null,
              role: res.jobTitle,
              roleOther: null,
              email: res.email,
            };
          });

        this.formGroup.get('participants').setValue([...this.participants, ...newParticipants]);

        // Updates users to prevent duplicates
        this.users = this.users.concat(
          newParticipants
            .map((x) => {
              return {
                id: x.userId,
              } as CaseUserSummary;
            })
            .filter((x) => {
              return !userIds.includes(x.id);
            })
        );
      }
    });

    // this.teamService.getTeamUsers(this.authService.user.id, this.caseSummary.intakeType, this.learner.id, true).subscribe((teamUsers) => {
    //   const userIds = this.users.map((u) => u.id);
    //   const users = teamUsers
    //     .map((x) => {
    //       // TODO when we get buildings info fill this in
    //       return {
    //         id: x.userId,
    //         fullName: x.fullName,
    //         jobTitle: x.jobTitle,
    //         buildings: null,
    //         isByResident: x.isByResident,
    //       } as User;
    //     })
    //     .filter((x) => {
    //       return !userIds.includes(x.id);
    //     });

    //   const dialogRef = this.dialog.open(TeamMemberSearchModalComponent, {
    //     width: '728px',
    //     data: {
    //       users,
    //       showResident: this.learner.residentAeaId !== this.learner.attendingAeaId,
    //     } as TeamSearchModalData,
    //   });

    //   dialogRef.afterClosed().subscribe((newUserIds: string[]) => {
    //     if (newUserIds) {
    //       const newParticipants = teamUsers
    //         .filter((x) => newUserIds.includes(x.userId))
    //         .map((res) => {
    //           return {
    //             fullName: res.fullName,
    //             userId: res.userId,
    //             agency: getAgency(res),
    //             agencyOther: null,
    //             role: 'AEA',
    //             roleOther: null,
    //             email: res.email,
    //           };
    //         });
    //       this.formGroup.get('participants').setValue([...this.participants, ...newParticipants]);

    //       // Updates users to prevent duplicates
    //       this.users = this.users.concat(
    //         newParticipants
    //           .map((x) => {
    //             return {
    //               id: x.userId,
    //             } as CaseUserSummary;
    //           })
    //           .filter((x) => {
    //             return !userIds.includes(x.id);
    //           })
    //       );
    //     }
    //   });
    // });
  }

  updateAvailableProvidersMembers() {
    const participants = this.formGroup.controls.participants.value;
    this.availableProviders = this.familyProviders.filter(
      (x) =>
        !participants.some(
          (p) => p.agencyId === x.agencyId && p.medicalSpecialistId === x.medicalSpecialistId && p.learnerId === x.learnerId
        )
    );
    this.availableMembers = this.familyMembers.filter((x) => !participants.some((p) => p.familyMemberId === x.familyMemberId));
  }

  openProviderDialog() {
    const dialogRef = this.dialog.open(FamilyProviderMemberSearchModalComponent, {
      width: '728px',
      data: {
        providers: this.availableProviders,
        familyMembers: this.availableMembers,
      },
    });

    dialogRef.afterClosed().subscribe((res: MeetingParticipantRead[]) => {
      if (res) {
        this.formGroup.get('participants').setValue([...this.participants, ...res]);
        this.updateAvailableProvidersMembers();
      }
    });
  }

  openEcpDialog() {
    const open = () => {
      const dialogRef = this.dialog.open(ECPSearchModalComponent, {
        width: '728px',
        data: {
          ecpContacts: this.ecpContacts,
          existingContacts: this.participants,
        } as ECPSearchModalData,
      });

      dialogRef.afterClosed().subscribe((res: MeetingParticipantRead[]) => {
        if (res) {
          this.formGroup.get('participants').setValue([...this.participants, ...res]);
          this.updateAvailableProvidersMembers();
        }
      });
    };

    if (!this.ecpContacts) {
      this.ecProgramsService.getContacts(this.learner.id).subscribe((contacts) => {
        this.ecpContacts = contacts;
        open();
      });
    } else {
      open();
    }
  }

  updateParticipantExcusal(event, participant: MeetingParticipantRead) {
    const participantFormControl = this.formGroup.controls.participants as FormControl;
    const selectedParticipant = participantFormControl.value.find((x) => x === participant);
    if (event.checked) {
      selectedParticipant.excusalDate = dayjs().toDate();
    } else {
      selectedParticipant.excusalDate = null;
    }
  }

  addParticipant(formDirective: FormGroupDirective) {
    if (this.addParticipantFormGroup.invalid) {
      return;
    }

    const newParticipant: MeetingParticipantRead = {
      id: null,
      agency: this.addParticipantFormGroup.controls.agency.value,
      agencyOther: this.addParticipantFormGroup.controls.agencyOther.value,
      role: this.addParticipantFormGroup.controls.role.value,
      roleOther: this.addParticipantFormGroup.controls.roleOther.value,
      fullName: this.addParticipantFormGroup.controls.name.value,
      email: null,
      agencyId: null,
      userId: null,
      familyMemberId: null,
      learnerId: null,
      medicalSpecialistId: null,
      type: null,
      meetingAttendanceId: null,
      meetingAttendanceOther: null,
      isByResident: false,
      excusalSignedFormId: null,
      excusalSignatoryToken: null,
      excusalSigned: false,
      excusalApproved: false,
    };

    this.formGroup.get('participants').setValue([...this.participants, newParticipant]);

    formDirective.resetForm();
    this.addParticipantFormGroup.reset();
  }

  getFamilyMembers() {
    this.familyMembers = this.learner.familyMembers.map((member) => ({
      id: null,
      familyMemberId: member.id,
      agency: '',
      agencyOther: null,
      role: 'Family',
      roleOther: null,
      fullName: member.fullName,
      email: member.email,
      agencyId: null,
      medicalSpecialistId: null,
      learnerId: null,
      userId: null,
      type: ParticipantType.FamilyMember,
      meetingAttendanceId: null,
      meetingAttendanceOther: null,
      isByResident: false,
      excusalSignedFormId: null,
      excusalSignatoryToken: null,
      excusalSigned: false,
      excusalApproved: false,
    }));
  }

  getFamilyProviders() {
    forkJoin([
      this.providerService.getIntakeAgencyOrOtherPrograms(this.learner.id),
      this.providerService.getIntakeMedicalSpecialist(this.learner.id),
      this.providerService.getLearnerPhcp(this.learner.id),
    ]).subscribe(([programs, specialists, phcp]) => {
      this.familyProviders = this.familyProviders.concat(
        programs.map((program) => ({
          id: null,
          agencyId: program.id,
          fullName: program.contact,
          agency: program.name,
          agencyOther: null,
          role: null,
          roleOther: null,
          email: null,
          userId: null,
          familyMemberId: null,
          learnerId: null,
          medicalSpecialistId: null,
          type: ParticipantType.FamilyProvider,
          meetingAttendanceId: null,
          meetingAttendanceOther: null,
          isByResident: false,
          excusalSignedFormId: null,
          excusalSignatoryToken: null,
          excusalSigned: false,
          excusalApproved: false,
        }))
      );
      this.familyProviders = this.familyProviders.concat(
        specialists.map((specialist) => ({
          id: null,
          medicalSpecialistId: specialist.id,
          fullName: specialist.name,
          agency: null,
          agencyOther: null,
          role: specialist.areaOfSpecialty,
          roleOther: null,
          email: null,
          userId: null,
          familyMemberId: null,
          agencyId: null,
          learnerId: null,
          type: ParticipantType.FamilyProvider,
          meetingAttendanceId: null,
          meetingAttendanceOther: null,
          isByResident: false,
          excusalSignedFormId: null,
          excusalSignatoryToken: null,
          excusalSigned: false,
          excusalApproved: false,
        }))
      );
      if (phcp.phcpClinicAgencyName || phcp.phcpName) {
        this.familyProviders.push({
          id: null,
          learnerId: this.learner.id,
          fullName: phcp.phcpTitle && phcp.phcpName ? `${phcp.phcpTitle} ${phcp.phcpName}` : `${phcp.phcpName}`,
          agency: phcp.phcpClinicAgencyName,
          agencyOther: null,
          email: phcp.phcpEmail,
          role: null,
          roleOther: null,
          userId: null,
          familyMemberId: null,
          agencyId: null,
          medicalSpecialistId: null,
          type: ParticipantType.FamilyProvider,
          meetingAttendanceId: null,
          meetingAttendanceOther: null,
          isByResident: false,
          excusalSignedFormId: null,
          excusalSignatoryToken: null,
          excusalSigned: false,
          excusalApproved: false,
        });
      }
      this.updateAvailableProvidersMembers();
    });
  }
}
