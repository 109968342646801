import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { PageEvent } from '@angular/material/paginator';
import { MatSort, Sort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { TeamMemberSearchEntity } from 'src/app/shared/modals/team-member-search-modal/team-member-search-modal.component';
import { TeamService } from 'src/app/shared/services/team/team.service';
import { UserSearchParams, UserService } from 'src/app/shared/services/user/user.service';

export interface UserSearchConfig {
  users?: Array<string>;
  includeFamily?: boolean;
  aeaIds?: Array<string>;
  roleIds?: Array<string>;
  includeStateWideForRoleIds?: boolean;
  returnIdsOnly?: boolean;
}

@Component({
  selector: 'app-user-search',
  templateUrl: './user-search.component.html',
  styleUrls: ['./user-search.component.scss'],
})
export class UserSearchComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  formGroup = this.fb.group({
    searchFilter: '',
  });
  isSearching = false;
  orderBy = 'firstname';
  orderByDescending = false;
  currentPage = 0;
  pageSize = 20;
  totalRows = 0;
  dataSource = new MatTableDataSource<any>();
  activeCall = false;
  subscriptions = new Subscription();

  displayedColumns = ['fullName', 'actions'];

  selectedUsers = [];
  includeFamily = false;
  aeaIds: string[];
  roleIds: string[];
  includeStateWideForRoleIds = false;
  returnIdsOnly = false;
  tempAdding = [];
  singleSelect = false;
  loading = false;

  get currentPageInfo(): PageEvent {
    return {
      pageIndex: this.currentPage,
      pageSize: this.pageSize,
      length: this.totalRows,
    } as PageEvent;
  }

  constructor(
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) readonly data: UserSearchConfig,
    private readonly userService: UserService
  ) {
    this.selectedUsers = data.users;
    this.includeFamily = data.includeFamily;
    this.aeaIds = data.aeaIds;
    this.roleIds = data.roleIds;
    this.includeStateWideForRoleIds = data.includeStateWideForRoleIds;
    this.returnIdsOnly = data.returnIdsOnly;
  }

  ngOnInit(): void {
    this.getTableData(true);
  }

  onToggleTeamMember(member: TeamMemberSearchEntity) {
    member.isAdded = !member.isAdded;
    if (member.isAdded) {
      this.tempAdding.push(member.id);
    } else {
      this.tempAdding = this.selectedUsers.filter((x) => x !== member.id);
    }
  }

  pageChanged(event: PageEvent): void {
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.getTableData();
  }

  onSaveClick(): void {
    if (this.returnIdsOnly) {
      this.dialogRef.close(this.tempAdding);
      return;
    }
    const users = this.dataSource.data.filter((x) => this.tempAdding.includes(x.id));
    this.dialogRef.close(users);
  }

  private getTableData(firstLoad = false): void {
    if (firstLoad) this.initDataSource();

    const userSearchParameters: UserSearchParams = {
      searchFilter: this.formGroup.get('searchFilter').value,
      userIds: this.selectedUsers,
      includeFamily: this.includeFamily,
      aeaIds: this.aeaIds,
      roleIds: this.roleIds,
      includeStateWideForRoleIds: this.includeStateWideForRoleIds,
    } as UserSearchParams;

    this.userService
      .searchUsersPaginated(this.currentPage + 1, this.pageSize, this.orderBy, this.orderByDescending, userSearchParameters)
      .subscribe((res) => {
        this.totalRows = res.totalCount;
        this.dataSource.data = res.items.map((x) => {
          return {
            id: x.id,
            fullName: x.fullName,
            jobTitle: x.jobTitle,
            email: x.email,
            phone: x.phoneNumber,
            aea: x.aea,
            district: x.district,
            isAdded: this.tempAdding.includes(x.id),
          };
        });
      });
  }

  private initDataSource(): void {
    this.dataSource.sortingDataAccessor = () => null;
    this.subscriptions.add(
      this.sort?.sortChange.subscribe((sort: Sort) => {
        this.orderBy = !!sort.direction ? sort.active : 'name';
        this.orderByDescending = sort.direction === 'desc' ? true : false;
        this.getTableData();
      })
    );

    this.subscriptions.add(
      this.formGroup.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
        this.currentPage = 0;
        this.getTableData();
      })
    );
  }
}
