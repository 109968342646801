<span>
  <div class="hide-normal">
    <div class="row">
      <div class="col">
        <h3 class="mb-2 card-spacing-top">Important Information</h3>
      </div>
    </div>
    <div>
      <mat-card class="mb-0">
        <div class="row">
          <div class="col">
            <ul class="list">
              <li class="list__item" *ngIf="oneYesResponse()">
                Written parental consent for a full and individual initial evaluation must be sought when disibility is suspected.
              </li>
              <li class="list__item" *ngIf="allNoResponses()">
                Prior Written Notice of a refusal to conduct an evaluation must be provided when parents have requested an evaluation and
                disability is not suspected.
              </li>
              <li class="list__item">The responses entered will be saved as part of the student's records.</li>
            </ul>
          </div>
        </div>
      </mat-card>
    </div>
  </div>

  <hr class="input__divider card-spacing-top mb-0 hide-normal" />

  <div>
    <div class="row">
      <div class="col">
        <h3 class="mb-2 card-spacing-top">Participants Involved in Decision</h3>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <mat-card class="mb-0">
          <div class="table-overflow w-100">
            <table mat-table [dataSource]="dsForm.participants">
              <!-- Name Column -->
              <ng-container matColumnDef="fullName">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let participant">
                  {{ participant.fullName }}
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="jobTitle">
                <th mat-header-cell *matHeaderCellDef>Job Title</th>
                <td mat-cell *matCellDef="let participant">
                  {{ participant.jobTitle }}
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="email">
                <th mat-header-cell *matHeaderCellDef>Email</th>
                <td mat-cell *matCellDef="let participant">
                  {{ participant.email }}
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="phone">
                <th mat-header-cell *matHeaderCellDef>Phone</th>
                <td mat-cell *matCellDef="let participant">
                  {{ participant.phone | phone }}
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="aea">
                <th mat-header-cell *matHeaderCellDef>AEA</th>
                <td mat-cell *matCellDef="let participant">
                  {{ participant.aea }}
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="district">
                <th mat-header-cell *matHeaderCellDef>District</th>
                <td mat-cell *matCellDef="let participant">
                  {{ participant.district }}
                </td>
              </ng-container>

              <!-- Name Column -->
              <ng-container matColumnDef="primaryOffice">
                <!-- TODO This needs to be updated when we get building information -->
                <th mat-header-cell *matHeaderCellDef>Primary Office</th>
                <td mat-cell *matCellDef="let participant">
                  {{ participant?.buildings[0]?.name }}
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
            <p *ngIf="!dsForm.participants">No data yet...</p>
          </div>
          <div class="mt-2" *ngIf="dsForm.nonAchieveParticipants.length > 0">
            <h4 class="mb-1 mt-0">Non-ACHIEVE Team Member(s) Involved in Decision</h4>
            <ul class="nonFormat-list">
              <li *ngFor="let participant of dsForm.nonAchieveParticipants">
                {{ participant.name }}
              </li>
            </ul>
          </div>
          <div class="mt-2" *ngIf="dsForm.familyParticipants.length > 0">
            <h4 class="mb-1 mt-0">Family Member(s) Involved in Decision</h4>
            <ul class="list">
              <li class="list__item" *ngFor="let fm of dsForm.familyParticipants">
                {{ fm.fullName }}
              </li>
            </ul>
          </div>
        </mat-card>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col">
      <h3 class="card-spacing-top mb-2">Summary of Responses</h3>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <mat-card class="mb-0">
        <ul class="list p-0">
          <li class="list__item">
            <p class="mr-1 mb-0">
              <strong>Diagnosis of Condition: </strong>
              <span class="yes" *ngIf="dsForm.diagnosisOfCondition">Yes</span>
              <span class="no" *ngIf="dsForm.diagnosisOfCondition !== null && !dsForm.diagnosisOfCondition">No</span>
              <span class="no-response" *ngIf="dsForm.diagnosisOfCondition === null">Unanswered</span>
            </p>

            <ng-container *ngIf="dsForm.diagnosisOfConditionComments">
              <p class="mb-0 mt-1">Comments: {{ dsForm.diagnosisOfConditionComments }}</p>
            </ng-container>
          </li>

          <li class="list__item">
            <p class="mr-1 mb-0">
              <strong>Unique Compared to Peers: </strong>
              <span class="yes" *ngIf="dsForm.uniqueComparedToPeers">Yes</span>
              <span class="no" *ngIf="dsForm.uniqueComparedToPeers !== null && !dsForm.uniqueComparedToPeers">No</span>
              <span class="no-response" *ngIf="dsForm.uniqueComparedToPeers === null">Unanswered</span>
            </p>

            <ng-container *ngIf="dsForm.uniqueComparedToPeersComments">
              <p class="mb-0 mt-1">Comments: {{ dsForm.uniqueComparedToPeersComments }}</p>
            </ng-container>
          </li>

          <li class="list__item">
            <p class="mr-1 mb-0">
              <strong>Progression and Meeting Standards: </strong>
              <span class="yes" *ngIf="dsForm.progressionStandards">Yes</span>
              <span class="no" *ngIf="dsForm.progressionStandards !== null && !dsForm.progressionStandards">No</span>
              <span class="no-response" *ngIf="dsForm.progressionStandards === null">Unanswered</span>
            </p>

            <ng-container *ngIf="dsForm.progressionStandardsComments">
              <p class="mb-0 mt-1">Comments: {{ dsForm.progressionStandardsComments }}</p>
            </ng-container>
          </li>

          <li class="list__item">
            <p class="mr-1 mb-0">
              <strong>Requires Continued Substantial Effort: </strong>
              <span class="yes" *ngIf="dsForm.requiresContinuedEffort">Yes</span>
              <span class="no" *ngIf="dsForm.requiresContinuedEffort !== null && !dsForm.requiresContinuedEffort">No</span>
              <span class="no-response" *ngIf="dsForm.requiresContinuedEffort === null">Unanswered</span>
            </p>

            <ng-container *ngIf="dsForm.requiresContinuedEffortComments">
              <p class="mb-0 mt-1">Comments: {{ dsForm.requiresContinuedEffortComments }}</p>
            </ng-container>
          </li>

          <li class="list__item" *ngIf="dsForm.rationaleComments">
            <p class="mr-1 mb-0">
              <strong>Parent Originated: </strong>
              <span class="yes" *ngIf="dsForm.parentOriginated">Yes</span>
              <span class="no" *ngIf="dsForm.parentOriginated !== null && !dsForm.parentOriginated">No</span>
              <span class="no-response" *ngIf="dsForm.parentOriginated === null">Unanswered</span>
            </p>
            <p
              class="mb-0 mt-1"
              *ngIf="
                dsForm.rationaleComments &&
                !dsForm.diagnosisOfCondition &&
                !dsForm.uniqueComparedToPeers &&
                !dsForm.progressionStandards &&
                !dsForm.requiresContinuedEffort
              "
            >
              Comment:
              {{ dsForm.rationaleComments }}
            </p>
          </li>
        </ul>
      </mat-card>
    </div>
  </div>
</span>

<mat-card class="card-spacing-top hide-print">
  <p class="my-0" *ngIf="oneYesResponse()">
    <strong class="text-primary text-md">Next Step: </strong>
    <!-- Complete the Consent/Notice for Full Individual Initial Evaluation -->
    Contact family to gain consent for and notice of Full and Individual Initial Evaluation
  </p>
  <p class="my-0" *ngIf="allNoResponses()">
    <strong class="text-primary text-md">Next Step: </strong>
    Complete Prior Written Notice
  </p>
</mat-card>
