import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyValuePair } from '../../../../../shared/models/key-value-pair';
import { DhhLookupsService } from '../../../../services/dhh-lookups.service';
import { DhhService } from '../../../../services/dhh.service';

@Component({
  selector: 'app-dhh-cancel-learner-appointment',
  templateUrl: './dhh-cancel-learner-appointment.component.html',
  styleUrls: ['./dhh-cancel-learner-appointment.component.scss'],
})
export class DhhCancelLearnerAppointmentComponent implements OnInit {
  learnerAppointmentId: string;
  cancellationReasonOptions: KeyValuePair[] = [];
  isSaving = false;
  formGroup = this.fb.group({
    cancellationReasonId: [null, [Validators.required]],
  });

  constructor(
    private readonly dhhLookupService: DhhLookupsService,
    private readonly dhhService: DhhService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DhhCancelLearnerAppointmentComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit(): Promise<void> {
    this.learnerAppointmentId = this.data.learnerAppointmentId;

    const cancellationReason = await this.dhhLookupService.getLearnerAppointmentCancellationReason().toPromise();
    this.cancellationReasonOptions = cancellationReason.value.map((r) => new KeyValuePair(r.id, r.label));
  }

  onNo() {
    this.dialogRef.close(false);
  }

  onYes() {
    this.isSaving = true;
    this.dhhService.cancelLearnerAppointment(this.learnerAppointmentId, this.formGroup.controls.cancellationReasonId.value).subscribe(
      (result) => {
        if (result.succeeded) {
          this.dialogRef.close(true);
        } else {
          this.dhhService.handleError('Failed to cancel appointment.', result);
        }
      },
      (error) => {
        this.dhhService.handleError('There was an error while cancelling the appointment.', error);
      },
      () => {
        this.isSaving = false;
      }
    );
  }
}
