<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>Edit</h3>
<div mat-dialog-content class="py-3">
  <mat-card [formGroup]="formGroup">
    <p class="mt-0 mb-2">
      {{ document?.title }}
    </p>
    <hr class="input__divider" />
    <app-text-field label="Document Title" formControlName="title"></app-text-field>
  </mat-card>
</div>
<div mat-dialog-actions class="w-100 display-flex justify-content-end align-items-center">
  <button mat-button aria-label="Cancel Button" class="background-color--muted" (click)="onCancel()" tabindex="0">Cancel</button>
  <button mat-raised-button aria-label="Save and Close" color="primary" [disabled]="formGroup.invalid" (click)="onSubmit()" tabindex="0">
    Save and Close
  </button>
</div>
