import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConsentFormType } from 'src/app/shared/models/fiie-consent/consent-form';
import { AuthService } from '../../../auth/auth.service';
import { AreYouSureComponent } from '../../../shared/components/are-you-sure-modal/are-you-sure.component';
import { ConsentStatus } from '../../../shared/models/fiie-consent/consent-status';
import { FamilyConsentStatus } from '../../../shared/models/fiie-consent/family-consent-status';
import { FiieConsentUpdateForm } from '../../../shared/models/fiie-consent/fiie-consent-update';
import { DateToUtcPipe } from '../../../shared/pipes/date-transform.pipe';
import { ConsentFormService } from '../../../shared/services/consent-form/consent-form.service';

@Component({
  selector: 'app-update-consent-form',
  templateUrl: './update-consent-form.component.html',
  styleUrls: ['./update-consent-form.component.scss'],
  providers: [DateToUtcPipe],
})
export class UpdateConsentFormComponent implements OnInit {
  formGroup = this.fb.group({
    status: ['', [Validators.required]],
    comments: [''],
    dateReceived: [null, [Validators.required]],
    dateSigned: [null],
    signedBy: [''],
  });
  today = new Date();
  activeCall = false;

  updatedForm: FiieConsentUpdateForm;
  statuses = FamilyConsentStatus;
  consentFormType = ConsentFormType;

  get title() {
    return this.data.consentFormId ? `Update ${this.data.title}` : `Create ${this.data.title}`;
  }

  get isSuperAdmin() {
    return this.authService.isSuperAdmin;
  }

  get isInvalid() {
    return this.formGroup.invalid;
  }

  get isNotRevokedOrDenied() {
    return (
      this.formGroup.get('status').value !== this.statuses.Revoked &&
      this.formGroup.get('status').value !== this.statuses.Denied &&
      this.formGroup.get('status').value !== this.statuses.Declined
    );
  }

  get isNotDeniedOrRequested() {
    return (
      this.formGroup.get('status').value !== this.statuses.Revoked &&
      this.formGroup.get('status').value !== this.statuses.Declined &&
      this.formGroup.get('status').value !== this.statuses.Denied &&
      this.formGroup.get('status').value !== this.statuses.Requested
    );
  }

  constructor(
    private fb: FormBuilder,
    private consentFormService: ConsentFormService,
    private authService: AuthService,
    private dialogRef: MatDialogRef<UpdateConsentFormComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      caseId: string;
      title: string;
      consentFormId?: string;
      ifspId?: string;
      iepId?: string;
      consentFormType: ConsentFormType;
      currentStatus?: FamilyConsentStatus;
      createdOn?: Date;
      parentsProvidedFiieConsentDate?: Date;
      dateReceived?: Date;
      learnerId?: string;
    },
    public dialog: MatDialog,
    private readonly dateToUtcPipe: DateToUtcPipe
  ) {}

  ngOnInit(): void {
    this.formGroup.updateValueAndValidity();

    this.formGroup.get('status').valueChanges.subscribe(() => {
      const dateSigned = this.formGroup.get('dateSigned');
      const signedBy = this.formGroup.get('signedBy');
      if (this.isNotDeniedOrRequested) {
        dateSigned.addValidators(Validators.required);
        signedBy.addValidators(Validators.required);
      } else {
        dateSigned.reset();
        dateSigned.removeValidators(Validators.required);
        signedBy.reset();
        signedBy.removeValidators(Validators.required);
      }
      dateSigned.updateValueAndValidity();
      signedBy.updateValueAndValidity();
      this.formGroup.updateValueAndValidity();
    });
  }

  onSubmit() {
    if (this.formGroup.invalid) {
      return;
    }
    const dateSigned: Date = this.isNotRevokedOrDenied
      ? this.formGroup.controls.dateSigned.value
      : this.formGroup.controls.dateReceived.value;
    const statusModel = {
      status: this.formGroup.controls.status.value,
      consentFormId: this.data.consentFormId,
      comments: this.formGroup.controls.comments.value,
      documents: [],
      dateReceived: this.dateToUtcPipe.transform(this.formGroup.controls.dateReceived.value),
      dateSigned: this.dateToUtcPipe.transform(dateSigned),
      signedBy: this.isNotRevokedOrDenied ? this.formGroup.controls.signedBy.value : '',
    };

    if (
      (this.data.consentFormType === ConsentFormType.PostReferralScreening ||
        this.data.consentFormType === ConsentFormType.Reevaluation ||
        this.data.consentFormType === ConsentFormType.InitialFamilyEvaluationAndAssessment ||
        this.data.consentFormType === ConsentFormType.InitialIepServices ||
        this.data.consentFormType === ConsentFormType.FiieConsent) &&
      this.formGroup.controls.status.value === FamilyConsentStatus.Revoked
    ) {
      const dialogRef = this.dialog.open(AreYouSureComponent, {
        width: '450px',
        data: {
          question: 'Are you sure?',
          subQuestion: 'Clicking Yes will permanently revoke this consent.',
        },
      });
      dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
          this.submit(statusModel);
        }
      });
    } else {
      this.submit(statusModel);
    }
  }

  submit(statusModel: ConsentStatus) {
    this.activeCall = true;

    // If we're just adding a consent to an existing ConsentForm
    if (this.data.consentFormId && this.data.caseId) {
      this.consentFormService.addConsentFormStatus(this.data.caseId, statusModel).subscribe(() => {
        this.activeCall = false;
        this.dialogRef.close(statusModel);
      });
    }
    // if this dialog was created from Dhh Consents with no Case Id
    else if (this.data.consentFormId && !this.data.caseId) {
      this.consentFormService.addDhhConsentFormStatus(this.data.learnerId, statusModel).subscribe(() => {
        this.activeCall = false;
        this.dialogRef.close(statusModel);
      });
    } else {
      const consentForm = {
        caseId: this.data.caseId,
        type: this.data.consentFormType,
        statuses: [statusModel],
        createdOn: new Date(),
        isComplete: false,
      };

      switch (consentForm.type) {
        case ConsentFormType.EarlyAccessServices:
          this.consentFormService.createIfspConsentForServices(this.data.ifspId, consentForm).subscribe(() => {
            this.activeCall = false;
            this.dialogRef.close(statusModel);
          });
          break;
        case ConsentFormType.InitialIepServices:
          this.consentFormService.createIepConsentForServices(this.data.iepId, consentForm).subscribe(() => {
            this.activeCall = false;
            this.dialogRef.close(statusModel);
          });
          break;
        default: {
          this.consentFormService.createConsentForm(this.data.caseId, consentForm).subscribe(() => {
            this.activeCall = false;
            this.dialogRef.close(statusModel);
          });
        }
      }
    }
  }
}
