<div class="table-overflow app-table" *ngIf="pendingAppointments?.length > 0">
  <table mat-table [dataSource]="dataSource" matSort matSortActive="appointmentDate" matSortDirection="asc" class="mat-elevation-z0">
    <ng-container matColumnDef="action">
      <th mat-header-cell *matHeaderCellDef class="text-right"></th>
      <td mat-cell *matCellDef="let element" class="action--position-fix">
        <button mat-icon-button [matMenuTriggerFor]="menu" color="primary" tabindex="0" aria-label="Quick Access Menu">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <ng-container *ngIf="canUpdateAppointment(element.status)">
            <button mat-menu-item aria-label="Cancel Appointment" tabindex="0" (click)="onCancelAppointment(element.learnerAppointmentId)">
              Cancel Appointment
            </button>
            <button mat-menu-item aria-label="Reschedule Appointment" tabindex="0" (click)="onRescheduleAppointment(element)">
              Reschedule Appointment
            </button>
            <button
              mat-menu-item
              aria-label="Appointment Kept"
              tabindex="0"
              (click)="onAppointmentKept(element)"
              *ngIf="isAppointmentPastOrPresent(element)"
            >
              Appointment Kept
            </button>
          </ng-container>

          <button
            *ngIf="canAddNewTest(element.status)"
            mat-menu-item
            aria-label="Add New Test"
            (click)="onAddNewTest(element)"
            tabindex="0"
          >
            Add New Test
          </button>

          <button
            *ngIf="canPerfomAction"
            mat-menu-item
            aria-label="Print Confirmation Letter"
            tabindex="0"
            (click)="onPrintConfirmationLetter(element)"
          >
            Print Confirmation Letter
          </button>

          <button
            mat-menu-item
            aria-label="Create Release / Exchange of Information"
            tabindex="0"
            *ngIf="canPerfomAction"
            (click)="onAddNewConsent(element)"
          >
            Create Release / Exchange of Information
          </button>
        </mat-menu>
      </td>
    </ng-container>

    <ng-container matColumnDef="select">
      <th mat-header-cell *matHeaderCellDef>
        <mat-checkbox (change)="onToggleAll($event)" [checked]="toggleAll" aria-labelledby="Selected"></mat-checkbox>
      </th>
      <td mat-cell *matCellDef="let element">
        <mat-checkbox (change)="onToggleElement(element)" [checked]="element.selected" aria-labelledby="Selected"></mat-checkbox>
      </td>
    </ng-container>

    <ng-container matColumnDef="appointmentDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Date</th>
      <td mat-cell *matCellDef="let element">{{ element.appointmentDate | dateFormat }}</td>
    </ng-container>

    <ng-container matColumnDef="startTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Time</th>
      <td mat-cell *matCellDef="let element">
        {{ element.startTime }}
      </td>
    </ng-container>

    <ng-container matColumnDef="endTime">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>End Time</th>
      <td mat-cell *matCellDef="let element">
        {{ element.endTime }}
      </td>
    </ng-container>

    <ng-container matColumnDef="learnerName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Learner Name</th>
      <td mat-cell *matCellDef="let element">
        <a
          (click)="goToLearnerHearingDashboard(element.learnerId)"
          tabindex="0"
          role="link"
          aria-labelledby="Learner Name"
          *ngIf="!element.isDhhPending; else noLink"
        >
          {{ element.learnerName }}
        </a>
        <ng-template #noLink>
          {{ element.learnerName }}
        </ng-template>
        <mat-icon style="color: #007a7c; vertical-align: bottom" *ngIf="element.isDhh"> radio_button_on </mat-icon>
        <mat-icon style="color: #9b2242; vertical-align: bottom" *ngIf="element.isDoNotTest"> error </mat-icon>
        <mat-icon style="color: #24327b; vertical-align: bottom" *ngIf="element.isDoNotScreen"> indeterminate_check_box </mat-icon>
      </td>
    </ng-container>

    <ng-container matColumnDef="dateOfBirth">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>DOB</th>
      <td mat-cell *matCellDef="let element">
        {{ element.dateOfBirth | dobNoTz | dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="appointmentType">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Appointment Type</th>
      <td mat-cell *matCellDef="let element">{{ element.appointmentType }}</td>
    </ng-container>

    <ng-container matColumnDef="audiologists">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Audiologist Name</th>
      <td mat-cell *matCellDef="let element">
        {{ element.audiologists }}
      </td>
    </ng-container>

    <ng-container matColumnDef="birthHospital">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Birth Hospital</th>
      <td mat-cell *matCellDef="let element">
        {{ element.birthHospital }}
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="9">
        <i>No results...</i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr [hidden]="dataSource?.data?.length > 0" mat-footer-row *matFooterRowDef="dataSource.data?.length === 0 ? ['noResults'] : []"></tr>
  </table>

  <mat-paginator [dataSource]="dataSource"></mat-paginator>
</div>
