import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { AfterContentChecked, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { AuthService } from 'src/app/auth/auth.service';
import { AlertConfig } from 'src/app/shared/components/page-alert/page-alert.component';
import { FileDocument } from 'src/app/shared/models/file-document';
import { FamilyMemberType } from 'src/app/shared/models/learner';
import { checkForAlert, createAlertConfig } from 'src/app/shared/pageAlertHelper';
import { DatabaseLinksService } from '../../shared/services/database-links/database-links.service';
import { LearnerService } from '../../shared/services/learner/learner.service';
import { ReferralService } from './early-access-referral.service';
import { InProgressReferralModalComponent } from './modals/in-progress-referral-modal/in-progress-referral-modal.component';
import { Referral } from './referral';

@Component({
  selector: 'app-early-access-referral',
  templateUrl: './early-access-referral.component.html',
  styleUrls: ['./early-access-referral.component.scss'],
})
export class EarlyAccessReferralComponent implements OnInit, OnDestroy, AfterContentChecked {
  private autoSaveSubscription: Subscription = new Subscription();
  private submitted$ = new Subject();

  inProgressReferrals: Referral[];
  submitAttempted = false;
  isAuthenticated = false;
  documents: FileDocument[] = [];
  referralId: string;
  tabs: {
    message: string;
    check: boolean;
  }[];

  pagesIncomplete: AlertConfig = {
    status: null,
    message: null,
  };

  formGroup = this.fb.group(
    {
      id: null,
      referralSource: null,
      referralSourceHowHearAboutUsId: [null, { updateOn: 'change' }],
      referralSourceEarlyAccessOtherText: null,
      exchangeInformation: [null, { validators: [this.guardianValidator.bind(this)], updateOn: 'change' }],
      legalGuardian: [null, { validators: [this.exchangeValidator.bind(this)], updateOn: 'change' }],

      childInfo: this.fb.group({}),
      parentInfo: this.fb.group({}),
      familyInfo: this.fb.group({}),
      referralSourceInfo: this.fb.group({}),
      referralReasonInfo: this.fb.group({}),
      documentationInfo: this.fb.group({}),
    },
    {
      validators: [phoneEmailValidator],
      updateOn: 'blur',
    }
  );

  constructor(
    private readonly fb: FormBuilder,
    private readonly authService: AuthService,
    private readonly referralService: ReferralService,
    private readonly learnerService: LearnerService,
    private readonly dialog: MatDialog,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly databaseLinksService: DatabaseLinksService,
    private cd: ChangeDetectorRef
  ) {}
  // #region Getters
  get referralSourceIsLivesWith() {
    return (
      this.formGroup.get('referralSource').value === FamilyMemberType.LivesWith1 ||
      this.formGroup.get('referralSource').value === FamilyMemberType.LivesWith2
    );
  }

  get legalGuardianOrExchangeInvalid() {
    return this.formGroup.get('legalGuardian').invalid || this.formGroup.get('exchangeInformation').invalid;
  }

  get childInfo() {
    return this.formGroup.controls.childInfo as FormGroup;
  }
  get childsName() {
    return this.childInfo?.value?.firstName + ' ' + this.childInfo?.value?.lastName;
  }
  get childInfoInvalid() {
    return this.childInfo.touched && this.childInfo.invalid;
  }
  get childInfoComplete() {
    return this.childInfo.touched && this.childInfo.valid;
  }
  get parentInfo() {
    return this.formGroup.controls.parentInfo as FormGroup;
  }
  get parentInfoInvalid() {
    if (this.parentInfo.touched && this.formGroup.hasError('atLeastOnePhoneRequired')) {
      return true;
    }
    return this.parentInfo.touched && this.parentInfo.invalid;
  }
  get parentInfoComplete() {
    if (this.parentInfo.touched && this.formGroup.hasError('atLeastOnePhoneRequired')) {
      return false;
    }
    return this.parentInfo.touched && this.parentInfo.valid;
  }
  get familyInfo() {
    return this.formGroup.controls.familyInfo as FormGroup;
  }
  get familyInfoInvalid() {
    if (
      this.familyInfo.touched &&
      (this.formGroup.hasError('atLeastOnePhoneRequired') || (this.referralSourceIsLivesWith && this.legalGuardianOrExchangeInvalid))
    ) {
      return true;
    }
    return this.familyInfo.touched && this.familyInfo.invalid;
  }
  get familyInfoComplete() {
    if (
      this.familyInfo.touched &&
      (this.formGroup.hasError('atLeastOnePhoneRequired') || (this.referralSourceIsLivesWith && this.legalGuardianOrExchangeInvalid))
    ) {
      return false;
    }
    return this.familyInfo.touched && this.familyInfo.valid;
  }
  get referralSourceInfo() {
    return this.formGroup.controls.referralSourceInfo as FormGroup;
  }
  get referralSourceInfoInvalid() {
    return this.referralSourceInfo.touched && (this.referralSourceInfo.invalid || this.legalGuardianOrExchangeInvalid);
  }
  get referralSourceInfoComplete() {
    return this.referralSourceInfo.touched && this.referralSourceInfo.valid && !this.legalGuardianOrExchangeInvalid;
  }
  get referralReasonInfo() {
    return this.formGroup.controls.referralReasonInfo as FormGroup;
  }
  get referralReasonInfoInvalid() {
    return this.referralReasonInfo.touched && this.referralReasonInfo.invalid;
  }
  get referralReasonInfoComplete() {
    return this.referralReasonInfo.touched && this.referralReasonInfo.valid;
  }
  get documentationInfo() {
    return this.formGroup.controls.documentationInfo as FormGroup;
  }
  get documentationInfoInvalid() {
    return this.documentationInfo.touched && this.documentationInfo.invalid;
  }
  get documentationInfoComplete() {
    return this.documentationInfo.touched && this.documentationInfo.valid;
  }
  // #endregion
  ngOnInit() {
    this.isAuthenticated = this.authService.isAuthenticated;
    if (this.isAuthenticated) {
      const learnerId = this.route.snapshot.queryParamMap.get('learnerId');
      if (learnerId) {
        this.learnerService.getLearnerSummaryForReferral(learnerId).subscribe((res) => {
          if (res) {
            this.formGroup.get('childInfo').patchValue(res.childInfo);
            this.formGroup.get('parentInfo').patchValue(res.parentInfo);
          }
        });
      }
      this.referralService.getInProgressReferrals().subscribe((res) => {
        if (res.length > 0) {
          this.inProgressReferrals = res;
          this.openInProgressReferralsModal();
        }

        this.startAutosaving();
        this.startAutoAlerts();
      });
    }
  }

  ngAfterContentChecked() {
    this.cd.detectChanges();
  }

  ngOnDestroy() {
    this.autoSaveSubscription.unsubscribe();
  }

  onStepperSelectionChange(change: StepperSelectionEvent) {
    change.previouslySelectedStep.stepControl.markAllAsTouched();
    this.createAlert();
  }

  onSubmit() {
    // Stop autosaving...markAllAsTouched will fire off a bunch needlessly
    this.autoSaveSubscription.unsubscribe();

    this.submitAttempted = true;
    this.formGroup.markAllAsTouched();

    if (this.formGroup.valid) {
      // If a debounced auto-save is pending...this will cancel it via the
      // takeUntil call in that subscription
      this.submitted$.next(undefined);

      this.saveReferral({ ...this.formGroup.value, isSubmitted: true });
    } else {
      this.startAutosaving();
    }
  }

  openIowaIdeaInformationUrl() {
    window.open(this.databaseLinksService.getDatabaseLink('iowaFamilySupportNetwork'), '_blank');
  }

  helpToggled(e: any) {
    this.dialog.open(DialogTemporaryHelpComponent, { disableClose: false });
  }

  createAlert(): void {
    this.tabs = this.getMessageTabsForAlert();
    const invalid = checkForAlert(this.tabs);
    this.pagesIncomplete = createAlertConfig('Incomplete Page(s): ', 'warning', invalid);
  }

  getMessageTabsForAlert() {
    return [
      { message: 'Child Info', check: this.childInfoInvalid },
      { message: 'Parent Info', check: this.parentInfoInvalid },
      { message: 'Family Info', check: this.familyInfoInvalid },
      {
        message: 'Referral Source Info',
        check: this.referralSourceInfoInvalid,
      },
      { message: 'Reason for Referral', check: this.referralReasonInfoInvalid },
      { message: 'Documentation', check: this.documentationInfoInvalid },
    ];
  }

  private saveReferral(referral: Referral) {
    if (referral.referralReasonInfo && referral.referralReasonInfo.referralReasonDiagnosedConditions) {
      referral.referralReasonInfo.referralReasonDiagnosedConditions = referral.referralReasonInfo.referralReasonDiagnosedConditions.filter(
        (x) => x.id !== null
      );
    }

    return this.referralService.saveReferral(referral).subscribe(
      (res: Referral) => {
        if (res.isSubmitted) {
          this.router.navigate(['referral-submitted'], {
            relativeTo: this.route,
            queryParams: {
              id: res.id,
              aea: res.childInfo.aeaId,
              referralSource: res.referralSource,
            },
          });
        }
        this.formGroup.get('id').setValue(res.id, { emitEvent: false });
        this.referralId = this.formGroup.get('id').value;
      },
      (error) => console.log('Submittal error:', error)
    );
  }

  private openInProgressReferralsModal(): void {
    const dialogRef = this.dialog.open(InProgressReferralModalComponent, {
      width: '650px',
      data: this.inProgressReferrals,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.formGroup.patchValue(res);
        this.formGroup.get('referralReasonInfo.screeningToolIds').setValue(res.referralReasonInfo.screeningTools.map((t) => t.id));

        // "Expression changed" avoidance:
        setTimeout(() => {
          this.formGroup
            .get('documentationInfo')
            .get('documentIds')
            .setValue(res.documentationInfo.referralDocuments.map((d) => d.id));
        }, 0);
        this.referralId = this.formGroup.get('id').value;
        this.documents = res.documentationInfo.referralDocuments;

        // FamilyInfo controls won't exist until parent livesWith info is populated
        // and the FamilyInfo form renders and creates its controls.
        // Need to wait so the familyInfo controls exist before patching.
        // Hack on the timeout (was 0 at first, now 100!) because it's was not loading
        // data for the familyInfo if coming from the dialog (a referral in progress).
        setTimeout(() => {
          this.formGroup.patchValue({ familyInfo: res.familyInfo });
          this.formGroup.markAllAsTouched();
        }, 0);
      }
    });
  }

  private startAutosaving() {
    this.autoSaveSubscription = this.formGroup.valueChanges.pipe(debounceTime(3000), takeUntil(this.submitted$)).subscribe(() => {
      if (this.formGroup.dirty) {
        this.saveReferral(this.formGroup.value);
      }
    });
  }

  private startAutoAlerts() {
    this.tabs = this.getMessageTabsForAlert();
    this.formGroup.valueChanges.subscribe(() => {
      // Only update alerts if there is a change in value (object equality of tabs)
      if (JSON.stringify(this.getMessageTabsForAlert()) !== JSON.stringify(this.tabs)) {
        this.createAlert();
      }
    });
  }

  private exchangeValidator(control: FormControl): ValidationErrors | null {
    const referralSource = this.formGroup?.get('referralSource').value;
    if (
      // TODO: wrong
      (!referralSource || referralSource === FamilyMemberType.LivesWith1 || referralSource === FamilyMemberType.LivesWith2) &&
      typeof control.value !== 'boolean'
    ) {
      return { required: true };
    }
    return null;
  }

  private guardianValidator(control: FormControl): ValidationErrors | null {
    const referralSource = this.formGroup?.get('referralSource').value;
    if (
      (!referralSource || referralSource === FamilyMemberType.LivesWith1 || referralSource === FamilyMemberType.LivesWith2) &&
      typeof control.value !== 'boolean'
    ) {
      return { required: true };
    }
    return null;
  }
}

@Component({
  selector: 'app-dialog-temporary-help',
  template: `<h3>Help: Early ACCESS Referral</h3>
    <hr />
    <p>Help content is pending for this feature.</p>
    <p>Click anywhere outside of this box to close.</p>`,
})
export class DialogTemporaryHelpComponent {
  constructor(public dialogRef: MatDialogRef<DialogTemporaryHelpComponent>) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}

const phoneEmailValidator: ValidatorFn = (formGroup: FormGroup): ValidationErrors | null => {
  const livesWith1Email = formGroup.get('familyInfo.livesWith1Email');
  const livesWith1HomePhone = formGroup.get('familyInfo.livesWith1HomePhone');
  const livesWith1CellPhone = formGroup.get('familyInfo.livesWith1CellPhone');
  const livesWith1WorkPhone = formGroup.get('familyInfo.livesWith1WorkPhone');
  const livesWith2Email = formGroup.get('familyInfo.livesWith2Email');
  const livesWith2HomePhone = formGroup.get('familyInfo.livesWith2HomePhone');
  const livesWith2CellPhone = formGroup.get('familyInfo.livesWith2CellPhone');
  const livesWith2WorkPhone = formGroup.get('familyInfo.livesWith2WorkPhone');

  const parent1Email = formGroup.get('parentInfo.parent1Email');
  const parent1HomePhone = formGroup.get('parentInfo.parent1HomePhone');
  const parent1CellPhone = formGroup.get('parentInfo.parent1CellPhone');
  const parent1WorkPhone = formGroup.get('parentInfo.parent1WorkPhone');
  const parent2Email = formGroup.get('parentInfo.parent2Email');
  const parent2HomePhone = formGroup.get('parentInfo.parent2HomePhone');
  const parent2CellPhone = formGroup.get('parentInfo.parent2CellPhone');
  const parent2WorkPhone = formGroup.get('parentInfo.parent2WorkPhone');

  let livesWith = true;

  const hasFamilyInfo =
    formGroup.get('parentInfo.parent1LivesWithChild')?.value === false ||
    formGroup.get('parentInfo.parent2LivesWithChild')?.value === false;

  if (hasFamilyInfo) {
    livesWith =
      !livesWith1Email?.value &&
      !livesWith1HomePhone?.value &&
      !livesWith1CellPhone?.value &&
      !livesWith1WorkPhone?.value &&
      !livesWith2Email?.value &&
      !livesWith2HomePhone?.value &&
      !livesWith2CellPhone?.value &&
      !livesWith2WorkPhone?.value;
  }

  const parents =
    !parent1Email?.value &&
    !parent1HomePhone?.value &&
    !parent1CellPhone?.value &&
    !parent1WorkPhone?.value &&
    !parent2Email?.value &&
    !parent2HomePhone?.value &&
    !parent2CellPhone?.value &&
    !parent2WorkPhone?.value;

  if (livesWith && parents) {
    return { atLeastOnePhoneRequired: true };
  }
  return null;
};
