export enum FeatureFlags {
  OutOfStatePlacement = 'outOfStatePlacement',
  MyTools = 'myTools',
  ImplementationPlan = 'implementationPlan',
  DataManagement = 'dataManagement',
  DisputeResolution = 'disputeResolution',
  CorrectiveAction = 'correctiveAction',
  ContinuousImprovement = 'continuousImprovement',
  FocusedMonitoring = 'focusedMonitoring',
  DataReviewProtocol = 'dataReviewProtocol',
  ELAA = 'elaa',
  ShowIvrsBanner = 'showIvrsBanner',
  Ivrs = 'ivrs',
  FitCsa = 'fitCsaEnabled',
  IlotReportingExport = 'ilotReportingExport',
  IVRSPhase2 = 'ivrsPhase2',
  Equity = 'equity',
  SigDisPro = 'sigDisPro',
  StateComplaint = 'stateComplaint',
  Mediation = 'mediation',
  DueProcess = 'dueProcess',
  Dhh = 'dhh',
  DueDiligence = 'dueDiligence',
}
