<app-print-head title="" [adjustLogo]="false" [showClose]="true" [showLogo]="true" (closeEvent)="onClose()"></app-print-head>

<div *ngIf="!!referral">
  <div class="print__container">
    <p>{{ date | dateFormat : 'long' }}</p>
    <p>
      {{ aea?.name }}<br />
      {{ aea?.streetAddress }}<br />
      {{ aea?.city }}, {{ aea?.state }}, {{ aea?.zipCode }}<br />
    </p>
    <br />
    <div class="display-flex align-items-center justify-content-between mb-2">
      <p class="my-0">
        Re: {{ referral?.childInfo?.firstName }}
        {{ referral?.childInfo?.lastName }}
      </p>
      <p class="my-0">DOB: {{ referral?.childInfo?.dateOfBirth | dateFormat : 'long' }}</p>
    </div>

    <p>Dear {{ recipient }},</p>

    <div *ngIf="referral.referralSource === familyMemberType.Parent1 || referral.referralSource === familyMemberType.Parent2">
      <p>
        Thank you for referring your child to Early ACCESS, Iowa’s early intervention system for infants and toddlers with developmental
        delays or disabilities. An Early ACCESS service coordinator will be reaching out to you within the next 2-4 business days to
        schedule a developmental screening or evaluation. If you have not heard from a service coordinator within 5 business days, contact
        the area education agency (AEA) listed below or call the Iowa Family Support Network at 1-888-425-4371.
      </p>
      <p>
        Your child’s eligibility for Early ACCESS will be determined within 45 days from the referral. Early ACCESS cannot share information
        about your referral with anyone unless you provide written permission to do so. If you would like referral results sent to a
        physician or other agency/person, ask your service coordinator to provide the consent form that will allow Early ACCESS to
        communicate with others.
      </p>
    </div>

    <div *ngIf="referral.referralSource !== familyMemberType.Parent1 && referral.referralSource !== familyMemberType.Parent2">
      <p>
        Thank you for referring this child to Early ACCESS, Iowa’s early intervention system for infants and toddlers with developmental
        delays or disabilities and their families. The focus of Early ACCESS is to support caregivers to help their children learn and grow
        through everyday routines and activities. This means early intervention service providers work with parents and other caregivers to
        help their children learn and grow.
      </p>
      <p>
        The child’s eligibility for Early ACCESS will be determined within 45 days from the referral. Please be aware that without signed
        parent consent, Early ACCESS is unable to share any information with you about the child or family
      </p>
    </div>

    <p>
      To learn more about Early ACCESS, visit
      <a href="www.iafamilysupportnetwork.org" tabindex="0" role="link" aria-labelledby="To learn more about Early ACCESS, click">
        www.iafamilysupportnetwork.org</a
      >.
    </p>
    <p>Please feel free to contact me with questions using the information below.</p>
    <br />

    <p>
      {{ user?.fullName }}<br />
      {{ aea?.name }}<br />
      {{ user?.email }}<br />
      {{ user?.phoneNumber | phone }}<br />
    </p>
    <br />
    <p class="footer">Iowa Department of Education, Early ACCESS, 2019</p>
  </div>
</div>
