import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { pairwise, startWith } from 'rxjs/operators';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { HelpSection, HelpTerm } from 'src/app/shared/services/help/help';
import { HelpModalConfig, HelpService } from 'src/app/shared/services/help/help.service';
import { IepServiceHelp } from 'src/app/shared/services/help/models/iep.help';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import dayjs from '../../../../../../../node_modules/dayjs';
import { AreYouSureComponent } from '../../../../../shared/components/are-you-sure-modal/are-you-sure.component';
import { BaseComponent } from '../../../../../shared/components/base-component/base-component';
import { FamilyMeetingRead } from '../../../../../shared/models/case';
import { MeetingStatus } from '../../../../../shared/models/family-meeting-status';
import { LearnerSummary } from '../../../../../shared/models/learner';
import { DeactivationService } from '../../../../../shared/services/deactivation.service';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { IepAmendment } from '../../../../models/iep-amendment';
import { ExtendedSchoolYearOptions, IepExtendedSchoolYear } from '../../../../models/iep-extended-school-year';
import { IepExtendedSchoolYearDto } from '../../../../models/iep-extended-school-year-dto';
import { IepAmendmentService } from '../../../../services/iep-amendment.service';
import { IepService } from '../../../../services/iep.service';

export const validateDate: ValidatorFn = (control: FormControl): ValidationErrors | null => {
  return dayjs(control.value).isValid() ? null : { invalidDate: true };
};

@Component({
  selector: 'app-iep-ssaa-extended-school',
  templateUrl: './iep-ssaa-extended-school.component.html',
  styleUrls: ['./iep-ssaa-extended-school.component.scss'],
})
export class IepSsaaExtendedSchoolComponent extends BaseComponent implements OnInit {
  @Input() learner: LearnerSummary;
  @Input() isDetailsView = false;
  @Input() isAmendment: boolean;
  @Input() amendmentId: string;
  @Input() amendments: IepAmendment[];
  @Input() amendingESY: boolean;
  @Input() iepMeetings: FamilyMeetingRead[];
  @Input() isDeleting = false;

  showDate = false;
  planDevelopedByWording = false;
  options: IepExtendedSchoolYear[];
  caseId: string;
  iepId: string;
  shortDateFormat = shortDateFormat;
  extendedSchoolYearModel: IepExtendedSchoolYearDto;
  determinationOptions: KeyValuePair[];
  minDate: Date;
  canSaveDecision = true;
  iepEndDate: Date;

  helpSection = HelpSection;
  iepServiceHelp = IepServiceHelp;

  get hasAmendment() {
    return this.amendmentId;
  }

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentIsFinalized() {
    return this.amendment?.finalizeDate !== null;
  }

  get hasOpenAmendment() {
    return !!this.amendment && !this.amendmentIsFinalized;
  }

  get lastFinalizedDate() {
    if (this.amendments && this.amendments.length > 0) {
      const latest = this.amendments.reduce((r, a) => {
        return r.finalizeDate > a.finalizeDate ? r : a;
      });
      return latest?.finalizeDate;
    }
    return null;
  }

  get hasPriorVersion() {
    return !!this.extendedSchoolYearModel?.priorVersion;
  }

  get formData() {
    return this.formGroup.value;
  }

  get showESY() {
    return (
      this.options?.find((x) => x.id === this.extendedSchoolYearModel?.extendedSchoolYear)?.type === ExtendedSchoolYearOptions.DevelopNow &&
      this.options?.find((x) => x.id === this.formGroup.get('extendedSchoolYear')?.value).type === ExtendedSchoolYearOptions.DevelopNow
    );
  }

  formGroup = new FormGroup({
    extendedSchoolYear: new FormControl('', Validators.required),
    extendedSchoolYearDate: new FormControl(''),
  });

  save = new Observable<boolean>((observer) => {
    this.cd.detectChanges();

    const done = () => {
      this.isSaving = false;
      observer.next(true);
    };

    if (!this.formGroup.dirty || this.isSaving) {
      done();
      return;
    }

    this.isSaving = true;
    this.onSave().then(() => {
      done();
    });
  });

  constructor(
    private iepService: IepService,
    private route: ActivatedRoute,
    private notificationService: NotificationService,
    private router: Router,
    private dialog: MatDialog,
    private helpService: HelpService,
    private cd: ChangeDetectorRef,
    private iepAmendmentService: IepAmendmentService,
    deactivationService: DeactivationService
  ) {
    super(deactivationService);
  }

  async ngOnInit(): Promise<void> {
    this.minDate = dayjs().toDate();
    this.caseId = this.route.parent?.snapshot.paramMap.get('caseId');
    this.iepId = this.route.parent?.snapshot.paramMap.get('iepId');
    if (!this.caseId) {
      this.caseId = this.route.snapshot.paramMap.get('caseId');
    }
    if (!this.iepId) {
      this.iepId = this.route.snapshot.paramMap.get('iepId');
    }

    const meeting = this.iepMeetings?.find((x) => x.status === MeetingStatus.Complete && x.meetingRollCallDate !== null);
    if (meeting) {
      this.iepEndDate = dayjs(meeting.meetingRollCallDate).add(1, 'year').subtract(1, 'day').toDate();
    }

    this.iepService.getExtendedSchoolYearOptions().subscribe(async (res) => {
      this.determinationOptions = res.map((x) => new KeyValuePair(x.id, x.label));
      this.options = res;
      await this.preloadForm();

      this.saveSubscription = this.formGroup.valueChanges;
      this.startAutosaving();
      this.subscriptions.add(
        this.formGroup
          .get('extendedSchoolYear')
          .valueChanges.pipe(startWith(null as string), pairwise())
          .subscribe(([prev, next]: [string, string]) => {
            const previousValue = this.options.find((x) => x.id === prev);
            const currentValue = this.options.find((x) => x.id === next);
            if (
              previousValue?.type === ExtendedSchoolYearOptions.DevelopNow &&
              currentValue?.type !== ExtendedSchoolYearOptions.DevelopNow &&
              this.formGroup.dirty
            ) {
              this.canSaveDecision = false;
              const dialog = this.dialog.open(AreYouSureComponent, {
                data: {
                  subQuestion: 'Are you sure? Continuing will result in the loss of all current ESY data.',
                },
              });
              dialog.afterClosed().subscribe((val) => {
                if (!val) {
                  this.formGroup.get('extendedSchoolYear').setValue(prev);
                }
                this.canSaveDecision = true;
              });
            }
            if (currentValue?.type === ExtendedSchoolYearOptions.DevelopLater && this.formGroup.dirty) {
              this.planDevelopedByWording = true;
            } else {
              this.planDevelopedByWording = false;
            }
            this.showDate = this.options.find((x) => x.id === this.formGroup.get('extendedSchoolYear').value)?.showDateField;
            if (this.showDate) {
              this.formGroup.get('extendedSchoolYearDate').setValidators([Validators.required, validateDate]);
            } else {
              this.formGroup.get('extendedSchoolYearDate').setValidators(null);
              this.formGroup.get('extendedSchoolYearDate').setValue('');
            }
            this.cd.detectChanges();
          })
      );
    });
  }

  getExtendedSchoolYearLabel(id) {
    return this.options?.find((x) => x.id === id)?.label;
  }

  preloadForm() {
    this.iepService.getExtendedSchoolYearFromIep(this.iepId, this.amendmentId ? this.amendmentId : '').subscribe((x) => {
      if (x) {
        this.extendedSchoolYearModel = x;
        this.formGroup.patchValue({
          extendedSchoolYear: x.extendedSchoolYear || '',
          extendedSchoolYearDate: x.extendedSchoolYearDate || '',
        });
      }
    });
  }

  onAmend() {
    if (!this.amendment.amendingESY) {
      this.amendment.amendingESY = true;
      this.iepAmendmentService.updateAmendment(this.iepId, this.amendment).subscribe(() => {});
    }
  }

  openSummary() {
    const config: NewWindowConfig = {
      path: `cases/${this.caseId}/iep/${this.iepId}/services/summary`,
      popup: true,
      width: '1480px',
    };
    openNewWindow(config);
  }

  async onSave(showNotification = true) {
    const formValues = this.formGroup.value;
    formValues.amendmentId = this.amendmentId;
    if (this.formGroup.valid && this.canSaveDecision && !this.isDeleting) {
      try {
        await this.iepService.updateExtendedSchoolYear(this.iepId, formValues).toPromise();
        if (showNotification) {
          this.notificationService.success('Saved!');
        }
        this.extendedSchoolYearModel = await this.iepService
          .getExtendedSchoolYearFromIep(this.iepId, this.amendmentId ? this.amendmentId : '')
          .toPromise();
      } catch {
        if (showNotification) {
          this.notificationService.error('Failed to save.');
        }
      }
    }
  }

  onOpenHelp(e: Event, section: HelpSection, item: HelpTerm): void {
    e.preventDefault();

    const dictionary = this.helpService.getIepServiceDictionary();
    this.helpService.openHelpModal({
      help: dictionary,
      section,
      item,
      canBrowse: true,
    } as HelpModalConfig);
  }
}
