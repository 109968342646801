import { AfterViewChecked, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import dayjs from 'dayjs';
import { forkJoin, Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { ProviderInfoFormService } from 'src/app/evaluation/shared/components/health-info-form/provider-info-form/provider-info-form.service';
import { FamilyMeetingService } from 'src/app/learner-management/family-meeting/family-meeting.service';
import {
  MeetingAttendance,
  MeetingAttendanceType,
  MeetingParticipantRead,
  MeetingParticipantUpdate,
} from 'src/app/learner-management/family-meeting/participants-list/meeting-participants';
import { AppPermissions } from 'src/app/permissions';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { ParticipantSearchModalComponent } from 'src/app/shared/modals/participant-search/participant-search-modal.component';
import { ParticipantType } from 'src/app/shared/modals/participant-search/participant-type';
import {
  CaseSummary,
  CaseSummaryByIepId,
  CaseSummaryByIfspId,
  CaseUserRole,
  FamilyMeetingRead,
  IntakeType,
} from 'src/app/shared/models/case';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { ProfessionService } from 'src/app/shared/services/profession/profession.service';
import { TeamService } from 'src/app/shared/services/team/team.service';
import { EcProgramsService } from '../../../learner-management/early-childhood/ec-programs/ec-programs.service';
import { ECPSearchModalComponent, ECPSearchModalData } from '../../modals/ecp-search-modal/ecp-search-modal.component';
import { RescheduleMeetingModalComponent } from '../../modals/reschedule-meeting-modal/reschedule-meeting-modal.component';
import { Agency } from '../../models/agency';
import { MeetingStatus } from '../../models/family-meeting-status';
import { ConsentForm, ConsentFormType } from '../../models/fiie-consent/consent-form';
import { FamilyConsentStatus } from '../../models/fiie-consent/family-consent-status';
import { LearnerSummary } from '../../models/learner';
import { getAgency } from '../../models/team';
import { AeaService } from '../../services/aea/aea.service';
import { ConsentFormService } from '../../services/consent-form/consent-form.service';
import { LearnerService } from '../../services/learner/learner.service';
import { NotificationService } from '../../services/notification.service';
import { ReportingService } from '../../services/reporting/reporting.service';
import { RoutingService } from '../../services/routing.service';
import { openPdfWindow } from '../../windowHelpers';
import { DialogComingSoonComponent } from '../coming-soon/coming-soon.component';

@Component({
  selector: 'app-meeting-roll-call',
  templateUrl: './meeting-roll-call.component.html',
  styleUrls: ['./meeting-roll-call.component.scss'],
})
export class MeetingRollCallComponent implements OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('addParticipantFormDirective')
  private addParticipantFormDirective: NgForm;

  permissions = AppPermissions;
  dataLoaded = false;
  agencyOptions: KeyValuePair[] = [];
  agencyList: Agency[] = [];
  subscriptions: Subscription = new Subscription();

  excusalNeeded = false;
  uploadOrPrintSelected = false;
  printExcusalSelected = false;
  isReadOnly = false;
  displayedColumns: string[] = ['name', 'role', 'agency', 'attendance'];
  participantControlsDataSource: MatTableDataSource<AbstractControl>;

  meeting: FamilyMeetingRead;
  caseSummary: any;
  intakeType: IntakeType;
  learner: LearnerSummary;
  excusedAttendanceOption: KeyValuePair;

  professionOptions: KeyValuePair[];
  attendanceOptions: KeyValuePair[];
  attendanceOtherKey: string;
  shortDateFormat = shortDateFormat;

  familyProviders: MeetingParticipantRead[] = [];
  familyMembers: MeetingParticipantRead[] = [];
  users: MeetingParticipantRead[] = [];

  availableProviders: MeetingParticipantRead[] = [];
  availableFamilyMembers: MeetingParticipantRead[] = [];
  availableUsers: MeetingParticipantRead[] = [];

  participantFormGroups: FormArray = new FormArray([]);
  addParticipantFormGroup = this.fb.group({
    name: [null, Validators.required],
    agency: [null, Validators.required],
    agencyOther: [null],
    role: [null, Validators.required],
    roleOther: [null],
  });
  meetingAttendances: MeetingAttendance[];
  ecpContacts: MeetingParticipantRead[];
  loadingUsers = false;

  get canAddParticipants() {
    return this.authService.isAllowedByCaseId(this.caseSummary.id, null, AppPermissions.AddParticipants);
  }

  get availableCount() {
    this.filterOutCurrentParticipants();
    return this.availableProviders?.length + this.availableFamilyMembers?.length + this.availableUsers?.length;
  }

  constructor(
    readonly authService: AuthService,
    private aeaService: AeaService,
    private readonly dialog: MatDialog,
    private readonly familyMeetingService: FamilyMeetingService,
    private readonly professionService: ProfessionService,
    private readonly teamService: TeamService,
    private readonly providerService: ProviderInfoFormService,
    private readonly learnerService: LearnerService,
    private readonly cdRef: ChangeDetectorRef,
    private readonly fb: FormBuilder,
    private ecProgramsService: EcProgramsService,
    private reportingService: ReportingService,
    private readonly notificationService: NotificationService,
    private readonly consentFormService: ConsentFormService,
    private routingService: RoutingService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      meeting: FamilyMeetingRead;
      caseSummary: CaseSummary | CaseSummaryByIfspId | CaseSummaryByIepId;
      isReadOnly: boolean;
    }
  ) {
    this.meeting = data?.meeting;
    this.caseSummary = data?.caseSummary;
    this.isReadOnly = data?.isReadOnly;
  }

  ngOnInit(): void {
    this.getAeas();
    this.learnerService.getLearnerSummary(this.caseSummary.learnerId).subscribe((learnerRes) => {
      this.learner = learnerRes;
      this.intakeType = this.caseSummary?.intakeType;
      this.getMeetingAttendances();
      this.getFamilyProvidersAsParticipants();
      this.getFamilyMembersAsParticipants();
      this.getTeamMembersAsParticipants();
      this.disabledrop();
      this.dataLoaded = true;
      this.setExcusalNeeded();
    });

    this.subscribeToFormControls();
  }

  ngAfterViewChecked() {
    this.cdRef.detectChanges();
  }

  subscribeToFormControls() {
    this.subscriptions.add(
      this.addParticipantFormGroup.controls.agency.valueChanges.subscribe((change) => {
        if (change === 'Other') {
          this.addParticipantFormGroup.controls.agencyOther.setValidators(Validators.required);
        } else {
          this.addParticipantFormGroup.controls.agencyOther.setValidators(null);
        }
        this.addParticipantFormGroup.controls.agencyOther.updateValueAndValidity();
      })
    );
  }

  addNewParticipant() {
    if (this.addParticipantFormGroup.invalid) {
      return;
    }

    this.addRow({
      id: null,
      agency: this.addParticipantFormGroup.controls.agency.value,
      agencyOther: this.addParticipantFormGroup.controls.agencyOther?.value,
      role: this.addParticipantFormGroup.controls.role.value,
      roleOther: this.addParticipantFormGroup.controls.roleOther.value,
      fullName: this.addParticipantFormGroup.controls.name.value,
      email: null,
      agencyId: null,
      userId: null,
      familyMemberId: null,
      learnerId: this.caseSummary.learnerId,
      medicalSpecialistId: null,
      type: null,
      meetingAttendanceId: null,
      meetingAttendanceOther: null,
      isByResident: false,
      excusalSignedFormId: null,
      excusalSignatoryToken: null,
      excusalSigned: false,
      excusalApproved: false,
    });

    this.setUpTableData();
    this.addParticipantFormDirective.resetForm();
    this.addParticipantFormGroup.reset();
  }

  showAttendanceOther(attendanceId: string) {
    return attendanceId === this.attendanceOtherKey;
  }

  getAeas() {
    if (this.intakeType === IntakeType.PartB) {
      this.aeaService.getPublicAeas().subscribe((res) => {
        // TODO: The Keyvalue Pair is mapped to name/name, label/label (not using id)
        // because we just store the plain string for Agencies and Roles/Professions
        this.agencyOptions = res.map((x) => new KeyValuePair(x.name, x.name));
        this.agencyOptions.push(new KeyValuePair('None', 'None'));
        this.agencyOptions.push(new KeyValuePair('Other', 'Other'));
        this.agencyList = this.agencyOptions.map((x) => ({
          id: x.key,
          label: x.value,
        }));
      });
    } else {
      this.aeaService.getAllAeas().subscribe((res) => {
        this.agencyOptions = res.map((x) => new KeyValuePair(x.name, x.name));
        this.agencyOptions.push(new KeyValuePair('None', 'None'));
        this.agencyOptions.push(new KeyValuePair('Other', 'Other'));
        this.agencyList = this.agencyOptions.map((x) => ({
          id: x.key,
          label: x.value,
        }));
      });
    }
  }

  onAttendanceChange(change: string, participantId: string, isOther: boolean) {
    const newValues = this.participantFormGroups.controls
      .find((x) => x.get('participant').value.id === participantId)
      .get('participant').value;

    if (isOther) {
      newValues.meetingAttendanceOther = change;
    } else {
      newValues.meetingAttendanceId = change;
      if (change !== this.attendanceOtherKey) {
        newValues.meetingAttendanceOther = null;
      }
    }

    this.participantFormGroups.controls
      .find((x) => x.get('participant').value.id === participantId)
      .get('participant')
      .setValue(newValues);

    this.setExcusalNeeded();
  }

  private setExcusalNeeded() {
    const excusalNeededOptionKey = this.attendanceOptions?.find((x) => x.value === 'Excusal Agreement Needed')?.key;
    const participantFormGroups = this.participantFormGroups.controls as FormGroup[];

    if (participantFormGroups.find((x) => x.get('attendance').value === excusalNeededOptionKey)) {
      this.excusalNeeded = true;
    } else {
      this.excusalNeeded = false;
    }
  }

  onCancel() {
    this.dialog.closeAll();
  }

  disabledrop() {
    this.participantFormGroups.controls.forEach((control) => {
      const participant = control.get('participant').value as MeetingParticipantUpdate;
      const controlas = control.get('attendance').disable();
    });
  }

  getFormattedRole(participant) {
    if (participant?.role === CaseUserRole.ServiceCoordinator) {
      return 'Service Coordinator';
    } else if (participant?.role === 'Other') {
      return participant?.role + ' (' + participant?.roleOther + ')';
    } else {
      return participant?.role;
    }
  }

  getFormattedAgency(agency, agencyOther) {
    if (agency === 'Other') {
      return agency + ' (' + agencyOther + ')';
    } else {
      return agency;
    }
  }

  onSubmit(isFinalized = false) {
    const participants: MeetingParticipantUpdate[] = [];
    this.participantFormGroups.controls.forEach((control) => {
      const participant = control.get('participant').value as MeetingParticipantUpdate;
      participant.meetingAttendanceId = control.get('attendance').value;
      participant.meetingAttendanceOther = control.get('attendanceOther').value;
      participants.push(participant);
    });
    if (isFinalized) {
      this.familyMeetingService
        .updateStatus(this.meeting.id, this.caseSummary.caseId, MeetingStatus.Complete, participants)
        .subscribe(() => {
          this.dialog.closeAll();
        });
    } else {
      this.familyMeetingService.updateAttendances(this.caseSummary.caseId, this.meeting.id, participants).subscribe(() => {
        this.dialog.closeAll();
      });
    }
  }

  openPdf() {
    const utcOffsetMinutes = dayjs().utcOffset();

    this.reportingService.createMeetingNoticeOutput(this.meeting.id, utcOffsetMinutes).subscribe({
      next: (documentId: string) => openPdfWindow(this.learner.id, documentId),
      error: (err) =>
        this.notificationService.errorWithAction("Couldn't open output", 'Why?', () =>
          this.notificationService.alert(err.error, "Couldn't open output")
        ),
    });
  }

  onAddExistingParticipants() {
    this.filterOutCurrentParticipants();
    const dialogRef = this.dialog.open(ParticipantSearchModalComponent, {
      width: '728px',
      data: {
        currentParticipants: this.meeting?.participants,
        users: this.availableUsers,
        providers: this.availableProviders,
        familyMembers: this.availableFamilyMembers,
      },
    });

    dialogRef.afterClosed().subscribe((newParticipants: MeetingParticipantRead[]) => {
      if (newParticipants) {
        newParticipants.forEach((participant) => {
          this.addRow(participant);
        });
        this.setUpTableData();
      }
    });
  }

  openEcpDialog() {
    const open = () => {
      const dialogRef = this.dialog.open(ECPSearchModalComponent, {
        width: '728px',
        data: {
          ecpContacts: this.ecpContacts,
          existingContacts: this.meeting.participants,
        } as ECPSearchModalData,
      });

      dialogRef.afterClosed().subscribe((newParticipants: MeetingParticipantRead[]) => {
        if (newParticipants) {
          newParticipants.forEach((participant) => {
            this.addRow(participant);
          });
          this.setUpTableData();
        }
      });
    };

    if (!this.ecpContacts) {
      this.ecProgramsService.getContacts(this.caseSummary.learnerId).subscribe((contacts) => {
        this.ecpContacts = contacts;
        open();
      });
    } else {
      open();
    }
  }

  uploadAgreement() {
    if (!this.uploadOrPrintSelected && this.excusedAttendanceOption) {
      this.uploadOrPrintSelected = true;
      const index = this.attendanceOptions.indexOf(this.attendanceOptions.find((x) => x.value === 'Excusal Agreement Needed'));
      this.attendanceOptions.splice(index + 1, 0, this.excusedAttendanceOption);
    }
    this.dialog.open(DialogComingSoonComponent);
  }

  printAgreement() {
    const exclusalOptionId = this.attendanceOptions.find((x) => x.value === 'Excusal Agreement Needed')?.key;

    if (!exclusalOptionId) return;

    const participantsIds: string[] = [];
    this.participantFormGroups.controls
      .filter((x) => x.get('attendance').value === exclusalOptionId)
      .forEach((control) => {
        const participant = control.get('participant').value as MeetingParticipantUpdate;
        participantsIds.push(participant.id);
      });

    const now = new Date();
    const consentForm: ConsentForm = {
      caseId: this.caseSummary.id,
      type: ConsentFormType.AgreementToExcuseIep,
      statuses: [
        {
          status: FamilyConsentStatus.Requested,
          dateReceived: now,
          dateSigned: now,
        },
      ],
      createdOn: new Date(),
      isComplete: false,
      additionalData: {
        meetingId: this.meeting.id,
        participantIds: participantsIds,
      },
    };

    this.consentFormService.createAgreementToExcuse(this.caseSummary.id, consentForm, true).subscribe(async (result) => {
      if (result.succeeded) {
        this.routingService.openBase64Blob(result.value, 'application/pdf');
      } else {
        this.notificationService.alert(result.errors.toString(), 'Error creating Agreement to Excuse');
      }
    });
  }

  signAgreement() {
    const arrayFg = this.participantFormGroups.controls as FormGroup[];
    const oldKey = this.attendanceOptions.find((x) => x.value === 'Excusal Agreement Needed').key;
    const newKey = this.attendanceOptions.find((x) => x.value === 'Excused (Agreement Received)').key;
    arrayFg.forEach((participantFormGroup) => {
      if (participantFormGroup.controls?.attendance.value === oldKey) {
        participantFormGroup.controls?.attendance.setValue(newKey);
      }
    });
    this.dialog.open(DialogComingSoonComponent);
  }

  reschedule() {
    const dialogRef = this.dialog.open(RescheduleMeetingModalComponent, {
      width: '728px',
      data: { caseId: this.caseSummary.id, familyMeeting: this.meeting },
    });
    dialogRef.afterClosed().subscribe((rescheduledMeeting) => {
      // if (this.inLearnerDashboard) {
      //   this.updateRescheduledDate.emit(rescheduledMeeting);
      // } else {
      //   this.refreshData.emit(false);
      // }
      // this.getMeetingAndRefreshTable();
    });
  }

  private getProfessionsAndSetRoleValidator() {
    this.professionService.getProfessions().subscribe((res) => {
      this.professionOptions = res.map((x) => new KeyValuePair(x.label, x.label));

      this.addParticipantFormGroup.controls.role.valueChanges.subscribe((change) => {
        if (change === 'Other') {
          this.addParticipantFormGroup.controls.roleOther.setValidators(Validators.required);
        } else {
          this.addParticipantFormGroup.controls.roleOther.setValidators(null);
        }
        this.addParticipantFormGroup.controls.roleOther.updateValueAndValidity();
      });
    });
  }

  private addRow(participant: MeetingParticipantRead) {
    const participantRowGroup = this.fb.group({});
    participantRowGroup.addControl('participant', new FormControl({ value: participant, disabled: true }));

    participantRowGroup.addControl('attendance', new FormControl(this.getDefaultAttendanceKey(participant), Validators.required));
    participantRowGroup.addControl('attendanceOther', new FormControl(participant.meetingAttendanceOther));

    participantRowGroup.controls.attendance.valueChanges.subscribe((res) => {
      if (res === this.attendanceOtherKey) {
        participantRowGroup.controls.attendanceOther.setValidators(Validators.required);
      } else {
        participantRowGroup.controls.attendanceOther.setValidators(null);
        participantRowGroup.controls.attendanceOther.updateValueAndValidity();
      }
    });

    if (this.isReadOnly) {
      participantRowGroup.disable();
    }

    this.participantFormGroups.push(participantRowGroup);
  }

  private getDefaultAttendanceKey(participant: MeetingParticipantRead) {
    if (participant.meetingAttendanceId) {
      return participant.meetingAttendanceId;
    } else if (participant.excusalDate) {
      return this.meetingAttendances.find((x) => x.type === MeetingAttendanceType.ExcusalNeeded)?.id;
    } else if (participant.teamMemberInputReceived) {
      return this.meetingAttendances.find((x) => x.type === MeetingAttendanceType.Written).id;
    } else if (!participant.teamMemberInputReceived && participant.excusalDate) {
      return this.meetingAttendances.find((x) => x.type === MeetingAttendanceType.DidNotAttend).id;
    } else {
      return this.meetingAttendances.find((x) => x.type === MeetingAttendanceType.InPerson).id;
    }
  }

  private setUpFormGroups() {
    this.meeting.participants.forEach((participant) => {
      this.addRow(participant);
    });
  }

  private setUpTableData() {
    this.participantControlsDataSource = new MatTableDataSource<AbstractControl>(this.participantFormGroups.controls);
  }

  private getMeetingAttendances() {
    this.familyMeetingService.getMeetingAttendances(this.caseSummary.caseId).subscribe((meetingAttendances) => {
      this.meetingAttendances = meetingAttendances;
      if (this.intakeType !== IntakeType.PartB) {
        this.meetingAttendances = meetingAttendances.filter(
          (x) => x.type !== MeetingAttendanceType.ExcusalNeeded && x.type !== MeetingAttendanceType.Excused
        );
      }

      this.attendanceOptions = this.meetingAttendances.map((g) => new KeyValuePair(g.id, g.label));

      this.attendanceOtherKey = this.attendanceOptions.find((x) => x.value === 'Other').key;

      this.getProfessionsAndSetRoleValidator();
      this.setUpFormGroups();
      this.setUpTableData();
    });
  }

  private getFamilyMembersAsParticipants() {
    this.familyMembers = this.caseSummary.learner.familyMembers.map((member) => ({
      id: null,
      familyMemberId: member.id,
      agency: 'Family',
      role: 'Family',
      roleOther: null,
      fullName: member.fullName,
      email: member.email,
      agencyId: null,
      medicalSpecialistId: null,
      learnerId: null,
      userId: null,
      type: ParticipantType.FamilyMember,
      meetingAttendanceId: null,
      meetingAttendanceOther: null,
      isByResident: false,
    }));
  }

  private getTeamMembersAsParticipants() {
    this.loadingUsers = true;
    this.teamService.getTeamUsers(this.authService.user.id, this.intakeType, this.learner?.id, true).subscribe((teamUsers) => {
      this.users = teamUsers.map((x) => {
        return {
          userId: x.userId,
          agencyId: null,
          fullName: x.fullName,
          agency: getAgency(x, this.learner),
          role: x.jobTitle,
          roleOther: null,
          email: x.email,
          familyMemberId: null,
          learnerId: null,
          medicalSpecialistId: null,
          type: ParticipantType.User,
          meetingAttendanceId: null,
          meetingAttendanceOther: null,
          isByResident: x.isByResident,
        } as MeetingParticipantRead;
      });
      this.loadingUsers = false;
    });
  }

  private getFamilyProvidersAsParticipants() {
    forkJoin([
      this.providerService.getIntakeAgencyOrOtherPrograms(this.caseSummary.learnerId),
      this.providerService.getIntakeMedicalSpecialist(this.caseSummary.learnerId),
      this.providerService.getLearnerPhcp(this.caseSummary.learnerId),
    ]).subscribe(([programs, specialists, phcp]) => {
      this.familyProviders = [];
      this.familyProviders = this.familyProviders.concat(
        programs.map((program) => ({
          id: null,
          agencyId: program.id,
          fullName: program.contact,
          agency: program.name,
          agencyOther: null,
          role: null,
          roleOther: null,
          email: null,
          userId: null,
          familyMemberId: null,
          learnerId: null,
          medicalSpecialistId: null,
          type: ParticipantType.FamilyProvider,
          meetingAttendanceId: null,
          meetingAttendanceOther: null,
          isByResident: false,
          excusalSignedFormId: null,
          excusalSignatoryToken: null,
          excusalSigned: false,
          excusalApproved: false,
        }))
      );
      this.familyProviders = this.familyProviders.concat(
        specialists.map((specialist) => ({
          id: null,
          medicalSpecialistId: specialist.id,
          fullName: specialist.name,
          agency: null,
          agencyOther: null,
          role: specialist.areaOfSpecialty,
          roleOther: null,
          email: null,
          userId: null,
          familyMemberId: null,
          agencyId: null,
          learnerId: null,
          type: ParticipantType.FamilyProvider,
          meetingAttendanceId: null,
          meetingAttendanceOther: null,
          isByResident: false,
          excusalSignedFormId: null,
          excusalSignatoryToken: null,
          excusalSigned: false,
          excusalApproved: false,
        }))
      );
      if (phcp.phcpClinicAgencyName || phcp.phcpName) {
        this.familyProviders.push({
          id: null,
          learnerId: this.caseSummary.learnerId,
          fullName: phcp.phcpTitle && phcp.phcpName ? `${phcp.phcpTitle} ${phcp.phcpName}` : phcp.phcpName ? `${phcp.phcpName}` : null,
          agency: phcp.phcpClinicAgencyName,
          agencyOther: null,
          email: phcp.phcpEmail,
          role: null,
          roleOther: null,
          userId: null,
          familyMemberId: null,
          agencyId: null,
          medicalSpecialistId: null,
          type: ParticipantType.FamilyProvider,
          meetingAttendanceId: null,
          meetingAttendanceOther: null,
          isByResident: false,
          excusalSignedFormId: null,
          excusalSignatoryToken: null,
          excusalSigned: false,
          excusalApproved: false,
        });
      }
      this.setExcusalNeeded();
    });
  }

  private filterOutCurrentParticipants() {
    const currentParticipants: MeetingParticipantRead[] = [];
    this.participantFormGroups.controls.forEach((group) => {
      currentParticipants.push(group.get('participant').value);
    });

    if (currentParticipants.length > 0) {
      this.availableProviders = this.familyProviders.filter(
        (x) =>
          !currentParticipants.some(
            (p) => p.agencyId === x.agencyId && p.medicalSpecialistId === x.medicalSpecialistId && p.learnerId === x.learnerId
          )
      );

      this.availableFamilyMembers = this.familyMembers.filter(
        (x) => !currentParticipants.some((p) => p.familyMemberId === x.familyMemberId)
      );

      const userIds = currentParticipants.map((u) => u.userId);
      this.availableUsers = this.users.filter((x) => {
        return !userIds.includes(x.userId);
      });
    }
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}
