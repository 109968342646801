<form class="login-form" [formGroup]="loginForm" (ngSubmit)="onSubmit()" (keyup.enter)="onSubmit()">
  <h1 class="mat-display-1">Sign In</h1>
  <mat-form-field class="login-field">
    <ng-container *ngIf="options && options.length > 0; else productionLogin">
      <input type="text" placeholder="Email" matInput aria-labelledby="Email" formControlName="email" [matAutocomplete]="auto" />
      <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="onSubmit()">
        <mat-option *ngFor="let option of filteredLogins | async" [value]="option" role="combobox">
          {{ option }}
        </mat-option>
      </mat-autocomplete>
      <div class="form__carrot"></div>
    </ng-container>
    <ng-template #productionLogin>
      <input type="text" placeholder="Email" aria-labelledby="Email" matInput formControlName="email" />
    </ng-template>
  </mat-form-field>
  <mat-error *ngIf="email.touched && email.invalid">{{ getErrorMessage() }}</mat-error>
  <mat-form-field class="login-field shift-right">
    <input
      type="password"
      id="password"
      matInput
      placeholder="Password"
      aria-labelledby="Password"
      formControlName="password"
      autocomplete="current-password"
    />
  </mat-form-field>
  <mat-error *ngIf="password.touched && password.invalid">{{ getErrorMessage() }}</mat-error>
  <mat-error *ngIf="loginFailed"> Invalid email or password... </mat-error>
  <button mat-flat-button aria-label="Sign in" #submitButton class="buttons" type="submit" color="primary" [isBusy]="isBusy" tabindex="0">
    Sign in
  </button>
  <a class="forget-password" routerLink="/auth/family/forgot-password">Forgot password?</a>
</form>
