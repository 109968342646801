<h3 mat-dialog-title cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle class="mb-0">Appointment Details</h3>

<div mat-dialog-content class="py-3 mt-3">
  <ng-container *ngIf="pendingAppointments?.length === 0">
    <p>There are no scheduled appointments.</p>
  </ng-container>

  <ng-container *ngFor="let appointment of pendingAppointments">
    <mat-card class="my-0 mb-3">
      <div class="row">
        <div class="col-lg-4"><strong>Date: </strong>{{ appointment.appointmentDate | dateFormat }}</div>
        <div class="col-lg-4"><strong>Sound Booth: </strong>{{ appointment.nameOfBooth }}</div>
        <div class="col-lg-4"><strong>Audiologist: </strong>{{ appointment.audiologists }}</div>
      </div>

      <div class="row mt-2">
        <div class="col-lg-4"><strong>Start Time: </strong>{{ appointment.startTime }}</div>
        <div class="col-lg-4"><strong>End Time: </strong>{{ appointment.endTime }}</div>
        <div class="col-lg-4"><strong>Appointment Type: </strong>{{ appointment.appointmentType }}</div>
      </div>

      <div class="row mt-3">
        <div class="col-lg-12">
          <div align="end">
            <button
              class="mr-2"
              mat-raised-button
              aria-label="Cancel Appointment"
              color="primary"
              (click)="onCancelAppointment(appointment.learnerAppointmentId)"
              tabindex="0"
            >
              Cancel Appointment
            </button>

            <button
              mat-raised-button
              aria-label="Reschedule Appointment"
              color="primary"
              (click)="onRescheduleAppointment(appointment)"
              tabindex="0"
            >
              Reschedule Appointment
            </button>
          </div>
        </div>
      </div>
    </mat-card>
  </ng-container>
</div>

<div mat-dialog-actions align="end">
  <button class="mr-2" mat-raised-button aria-label="Add New Appointment" color="primary" (click)="onAddNewAppointment()" tabindex="0">
    Add New Appointment
  </button>

  <button mat-raised-button aria-label="Close" color="primary" (click)="onClose()" tabindex="0">Close</button>
</div>
