import { Component } from '@angular/core';

@Component({
  selector: 'app-dhh-legends-table',
  templateUrl: './dhh-legends-table.component.html',
  styleUrls: ['./dhh-legends-table.component.scss'],
})
export class DhhLegendsTableComponent {
  constructor() {}
}
