<div class="my-1">
  <app-page-alert [alert]="emailPhoneRequirement" role="status" aria-live="polite"></app-page-alert>
</div>

<form [formGroup]="formGroup">
  <mat-card aria-live="polite">
    <mat-card-title>Parent 1 Information</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg">
          <app-text-field label="Name (first and last)" formControlName="parent1Name"></app-text-field>
        </div>
        <div class="col-lg">
          <app-text-field label="Email" formControlName="parent1Email"></app-text-field>
        </div>
        <div class="col-lg">
          <fieldset>
            <app-radio-group label="Lives With Child" formControlName="parent1LivesWithChild" [options]="yesNoOptions"></app-radio-group>
          </fieldset>
        </div>
      </div>
      <div class="row">
        <div class="col-lg">
          <app-masked-input label="Home Phone Number" formControlName="parent1HomePhone" mask="(000) 000-0000"></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input label="Work Phone Number" formControlName="parent1WorkPhone" mask="(000) 000-0000"></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input label="Cell Phone Number" formControlName="parent1CellPhone" mask="(000) 000-0000"></app-masked-input>
        </div>
      </div>
      <div
        class="row"
        *ngIf="
          (formGroup.controls.parent1BestWayToReachHomePhone.value && !formGroup.controls.parent1HomePhone.value) ||
          (formGroup.controls.parent1BestWayToReachWorkPhone.value && !formGroup.controls.parent1WorkPhone.value) ||
          (formGroup.controls.parent1BestWayToReachCellPhone.value && !formGroup.controls.parent1CellPhone.value) ||
          (formGroup.controls.parent1BestWayToReachText.value && !formGroup.controls.parent1CellPhone.value) ||
          (formGroup.controls.parent1BestWayToReachEmail.value && !formGroup.controls.parent1Email.value) ||
          ((formGroup.controls.parent1HomePhone.touched ||
            formGroup.controls.parent1WorkPhone.touched ||
            formGroup.controls.parent1CellPhone.touched) &&
            parentForm.hasError('atLeastOnePhoneRequired'))
        "
      >
        <div class="col-lg">
          <mat-error *ngIf="formGroup.controls.parent1BestWayToReachHomePhone.value && !formGroup.controls.parent1HomePhone.value">
            Home phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.parent1BestWayToReachWorkPhone.value && !formGroup.controls.parent1WorkPhone.value">
            Work phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.parent1BestWayToReachCellPhone.value && !formGroup.controls.parent1CellPhone.value">
            Cell phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.parent1BestWayToReachText.value && !formGroup.controls.parent1CellPhone.value">
            Cell phone number is <strong>required</strong> if texting is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.parent1BestWayToReachEmail.value && !formGroup.controls.parent1Email.value">
            Email is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="parentForm.hasError('atLeastOnePhoneRequired')">
            At least one phone number or email address is
            <strong>required</strong>
          </mat-error>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-4">
          <div class="vertical-control">
            <label class="text-md vertical-label" for="parent1BestTimeToContact">Best Way to Contact</label>
            <div>
              <app-checkbox-single controlName="parent1BestWayToReachHomePhone" label="Home" aria-labelledby="Home"></app-checkbox-single>
              <app-checkbox-single controlName="parent1BestWayToReachWorkPhone" label="Work" aria-labelledby="Work"></app-checkbox-single>
              <app-checkbox-single controlName="parent1BestWayToReachCellPhone" label="Cell" aria-labelledby="Cell"></app-checkbox-single>
              <app-checkbox-single controlName="parent1BestWayToReachEmail" label="Email" aria-labelledby="Email"></app-checkbox-single>
              <app-checkbox-single controlName="parent1BestWayToReachText" label="Texting" aria-labelledby="Texting"></app-checkbox-single>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-text-field
            label="Best Time to Contact"
            id="parent1BestTimeToContact"
            formControlName="parent1BestTimeToContact"
          ></app-text-field>
        </div>
        <div class="col-lg-4 align-self-center">
          <mat-checkbox
            [checked]="referralSource === 'Parent1'"
            (change)="updateReferralSource($event, 'Parent1')"
            aria-labelledby=" This is the person completing the referral"
          >
            This is the person completing the referral
          </mat-checkbox>
        </div>
      </div>
      <ng-container>
        <div class="row mt-0 mb-0" *ngIf="referralSource === 'Parent1'">
          <div class="col-xl-4 col-lg-6">
            <app-autocomplete
              [options]="referralHowHearAboutUsOptions"
              [formControl]="referralSourceHowHearAboutUsId"
              label="How did you hear about Early ACCESS?"
            ></app-autocomplete>
          </div>
          <div class="col-md-8" *ngIf="parentForm.get('referralSourceHowHearAboutUsId').value === referralHowHearAboutUsOtherId">
            <div class="container">
              <label class="mr-1" for="referralSourceEarlyAccessOtherText">
                <app-text-field
                  label="Describe"
                  id="referralSourceEarlyAccessOtherText"
                  [formControl]="referralSourceEarlyAccessOtherText"
                ></app-text-field>
              </label>
            </div>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="formGroup.get('parent1LivesWithChild').value === false">
        <ng-container *ngTemplateOutlet="parentAddress; context: { selectedParent: 'parent1' }"></ng-container>
      </ng-container>
    </div>
  </mat-card>

  <mat-card class="card-spacing-top" aria-live="polite">
    <mat-card-title>Parent 2 Information</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg">
          <app-text-field label="Name (first and last)" formControlName="parent2Name"></app-text-field>
        </div>
        <div class="col-lg">
          <app-text-field label="Email" formControlName="parent2Email"></app-text-field>
        </div>
        <div class="col-lg">
          <!-- TODO: use 'showAsterisk' -->
          <fieldset>
            <app-radio-group
              label="Lives With Child"
              formControlName="parent2LivesWithChild"
              [options]="yesNoOptions"
              [showAsterisk]="formGroup.hasError('parent2LivesWithChildRequired')"
            ></app-radio-group>
          </fieldset>
          <mat-error *ngIf="formGroup.get('parent2LivesWithChild').touched && formGroup.hasError('parent2LivesWithChildRequired')">
            Field is <strong>required</strong>
          </mat-error>
        </div>
      </div>
      <div class="row">
        <div class="col-lg">
          <app-masked-input label="Home Phone Number" formControlName="parent2HomePhone" mask="(000) 000-0000"></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input label="Work Phone Number" formControlName="parent2WorkPhone" mask="(000) 000-0000"></app-masked-input>
        </div>
        <div class="col-lg">
          <app-masked-input label="Cell Phone Number" formControlName="parent2CellPhone" mask="(000) 000-0000"></app-masked-input>
        </div>
      </div>
      <div
        class="row mb-1"
        *ngIf="
          (formGroup.controls.parent2BestWayToReachHomePhone.value && !formGroup.controls.parent2HomePhone.value) ||
          (formGroup.controls.parent2BestWayToReachWorkPhone.value && !formGroup.controls.parent2WorkPhone.value) ||
          (formGroup.controls.parent2BestWayToReachCellPhone.value && !formGroup.controls.parent2CellPhone.value) ||
          (formGroup.controls.parent2BestWayToReachText.value && !formGroup.controls.parent2CellPhone.value) ||
          (formGroup.controls.parent2BestWayToReachEmail.value && !formGroup.controls.parent2Email.value) ||
          ((formGroup.controls.parent2HomePhone.touched ||
            formGroup.controls.parent2WorkPhone.touched ||
            formGroup.controls.parent2CellPhone.touched) &&
            parentForm.hasError('atLeastOnePhoneRequired'))
        "
      >
        <div class="col-lg">
          <mat-error *ngIf="formGroup.controls.parent2BestWayToReachHomePhone.value && !formGroup.controls.parent2HomePhone.value">
            Home phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.parent2BestWayToReachWorkPhone.value && !formGroup.controls.parent2WorkPhone.value">
            Work phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.parent2BestWayToReachCellPhone.value && !formGroup.controls.parent2CellPhone.value">
            Cell phone number is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.parent2BestWayToReachText.value && !formGroup.controls.parent2CellPhone.value">
            Cell phone number is <strong>required</strong> if texting is the best way to contact
          </mat-error>
          <mat-error *ngIf="formGroup.controls.parent2BestWayToReachEmail.value && !formGroup.controls.parent2Email.value">
            Email is <strong>required</strong> if it is the best way to contact
          </mat-error>
          <mat-error *ngIf="parentForm.hasError('atLeastOnePhoneRequired')">
            At least one phone number or email address is
            <strong>required</strong>
          </mat-error>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 col-lg-6 col-xl-4">
          <div class="vertical-control">
            <label class="text-md vertical-label" for="parent2BestTimeToContact">Best Way to Contact</label>
            <div>
              <app-checkbox-single
                formControlName="parent2BestWayToReachHomePhone"
                aria-labelledby="Home"
                label="Home"
              ></app-checkbox-single>
              <app-checkbox-single
                formControlName="parent2BestWayToReachWorkPhone"
                aria-labelledby="Work"
                label="Work"
              ></app-checkbox-single>
              <app-checkbox-single
                formControlName="parent2BestWayToReachCellPhone"
                aria-labelledby="Cell"
                label="Cell"
              ></app-checkbox-single>
              <app-checkbox-single formControlName="parent2BestWayToReachEmail" aria-labelledby="Email" label="Email"></app-checkbox-single>
              <app-checkbox-single
                formControlName="parent2BestWayToReachText"
                aria-labelledby="Texting"
                label="Texting"
              ></app-checkbox-single>
            </div>
          </div>
        </div>
        <div class="col-md-12 col-lg-6 col-xl-4">
          <app-text-field
            label="Best Time to Contact"
            id="parent2BestTimeToContact"
            formControlName="parent2BestTimeToContact"
          ></app-text-field>
        </div>
        <div class="col-lg-4 align-self-center">
          <mat-checkbox
            [checked]="referralSource === 'Parent2'"
            (change)="updateReferralSource($event, 'Parent2')"
            aria-labelledby="This is the person completing the referral"
          >
            This is the person completing the referral
          </mat-checkbox>
        </div>
      </div>
      <ng-container>
        <div class="row mt-0 mb-0" *ngIf="referralSource === 'Parent2'">
          <div class="col-xl-4 col-lg-6">
            <app-autocomplete
              [options]="referralHowHearAboutUsOptions"
              [formControl]="referralSourceHowHearAboutUsId"
              label="How did you hear about Early ACCESS?"
            ></app-autocomplete>
          </div>
          <div class="col-md-8" *ngIf="referralSourceHowHearAboutUsId.value === referralHowHearAboutUsOtherId">
            <div class="container">
              <label class="mr-1" for="referralSourceEarlyAccessOtherText">
                <app-text-field
                  label="Describe"
                  id="referralSourceEarlyAccessOtherText"
                  [formControl]="referralSourceEarlyAccessOtherText"
                ></app-text-field>
              </label>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <ng-container *ngIf="formGroup.get('parent2LivesWithChild').value === false">
      <ng-container *ngTemplateOutlet="parentAddress; context: { selectedParent: 'parent2' }"></ng-container>
    </ng-container>
  </mat-card>

  <mat-card class="card-spacing-top" aria-live="polite">
    <mat-card-title>Others in Home</mat-card-title>
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg">
          <label class="vertical-label" for="othersInHome"
            >Please describe the other people in the home It is helpful to include each person's name, age, and relationship to the
            child.</label
          >
          <app-textarea id="othersInHome" formControlName="othersInHome"></app-textarea>
        </div>
      </div>
    </div>
  </mat-card>

  <ng-template #parentAddress let-selectedParent="selectedParent">
    <div class="container container--not-centered container--form">
      <div class="row">
        <div class="col-lg-4">
          <app-text-field label="Street Address" [formControlName]="selectedParent + 'StreetAddress'"></app-text-field>
        </div>
        <div class="col-lg-3">
          <app-text-field
            label="Zip Code"
            [formControlName]="selectedParent + 'ZipCode'"
            (blurMe)="populateLocationFromZipCode(selectedParent)"
          ></app-text-field>
        </div>
        <div class="col-lg-3">
          <app-text-field label="City/Town" [formControlName]="selectedParent + 'City'"></app-text-field>
        </div>
        <div class="col-lg-2">
          <app-autocomplete label="State" [formControlName]="selectedParent + 'State'" [options]="usStateOptions"></app-autocomplete>
        </div>
      </div>
    </div>
  </ng-template>
</form>
