import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, timer } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { ActivityWatcher } from '../activity-watcher.service';
import { AppEvents } from '../shared/app-events';
import { AuthService } from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService, private readonly activityWatcher: ActivityWatcher) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // hack from https://medium.com/better-programming/angular-loader-interceptor-f0b37e244ccb#8191
    if (req.method === 'GET') {
      Promise.resolve(null).then(() => AppEvents.beforeRequest.emit(req));
    }
    const exclude = ['/api/auth'];
    const startTime = new Date();
    if (!exclude.includes(req.url)) {
      this.activityWatcher.addRequest(req, startTime);
    }
    return next.handle(req).pipe(
      tap(
        (response) => {
          if (response instanceof HttpResponse) {
            if (!exclude.includes(req.url)) {
              this.activityWatcher.addResponse(response, startTime);
            }
          }
          if (req.method === 'GET') {
            timer(100)
              .pipe(take(1))
              .subscribe(() => AppEvents.afterRequest.emit(req));
          }
        },
        (err: any) => {
          if (req.method === 'GET') {
            AppEvents.afterRequest.emit(req);
          }
          if (err instanceof HttpErrorResponse) {
            if (err.status !== 401) {
              if (!exclude.includes(req.url)) {
                this.activityWatcher.addError(err, startTime);
              }
              return;
            }
            console.warn('Unauthenticated response detected...navigating to login...');

            if (this.authService.redirectUrl.includes('portal')) {
              this.authService.navigateToFamilyLogin();
            } else {
              this.authService.navigateToLogin();
            }
            //
          }
        },
        () => {
          /*if (req.method === 'GET') {
            AppEvents.afterRequest.emit(req);
          }*/
        }
      )
    );
  }
}
