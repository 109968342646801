<section class="submitted">
  <div class="main-content">
    <header class="px-3">
      <app-page-header [pageTitle]="'Referral Submitted!'" [helpLinks]="false"> </app-page-header>
    </header>

    <div class="mat-horizontal-content-container px-3">
      <mat-card class="mt-0 mb-0 submitted__card">
        <div *ngIf="referralSource === familyMemberType.Parent1 || referralSource === familyMemberType.Parent2">
          <p>
            Thank you for referring your child to Early ACCESS, Iowa’s early intervention system for infants and toddlers with developmental
            delays or disabilities. An Early ACCESS service coordinator will be reaching out to you within the next 2-4 business days to
            schedule a developmental screening or evaluation. If you have not heard from a service coordinator within 5 business days,
            contact the area education agency (AEA) listed below or call the Iowa Family Support Network at 1-888-425-4371.
          </p>
          <p>
            Your child’s eligibility for Early ACCESS will be determined within 45 days from the referral. Early ACCESS cannot share
            information about your referral with anyone unless you provide written permission to do so. If you would like referral results
            sent to a physician or other agency/person, ask your service coordinator to provide the consent form that will allow Early
            ACCESS to communicate with others.
          </p>
        </div>
        <div *ngIf="referralSource !== familyMemberType.Parent1 && referralSource !== familyMemberType.Parent2">
          <p>
            Thank you for referring this child to Early ACCESS, Iowa’s early intervention system for infants and toddlers with developmental
            delays or disabilities. An Early ACCESS service coordinator will be reaching out to the child’s family or legal guardian within
            the next 2-4 business days to schedule a developmental screening or evaluation. If the family has not heard from a service
            coordinator within 5 business days, ask them to contact the area education agency (AEA) listed below or call the Iowa Family
            Support Network at 1-888-425-4371.
          </p>
          <p>
            The child’s eligibility for Early ACCESS will be determined within 45 days from the referral. Early ACCESS cannot share
            information with you until signed permission from the family or legal guardian is received. If you want to know the results of
            this referral, contact the family and ask them to provide written consent for Early ACCESS to release information to you.
          </p>
        </div>

        <p>
          The focus of Early ACCESS is to support caregivers to help their children learn and grow throughout their everyday activities.
          This means Early ACCESS providers work with parents and other caregivers to help their children learn and grow.
        </p>

        <p>
          To learn more about Early ACCESS, visit
          <a (click)="openIowaIdeaInformationUrl()" tabindex="0" role="link" aria-labelledby="To learn more about Early ACCESS, click">
            www.iafamilysupportnetwork.org</a
          >
        </p>
      </mat-card>

      <mat-card class="mb-3 card-spacing-top submitted__card" *ngIf="!displayAgency">
        <mat-card-title>This referral has been submitted to:</mat-card-title>

        <div class="submitted__info">
          <p class="text-semibold">Iowa Family Support Network</p>
          <p>
            Email:
            <a
              href="mailto:iafamilysupportnetwork@everystep.org"
              tabindex="0"
              role="link"
              aria-labelledby="Email: iafamilysupportnetwork@everystep.org"
            >
              iafamilysupportnetwork@everystep.org
            </a>
          </p>
          <p>Phone Number: 1-888-425-4371</p>
        </div>

        <p class="mt-4">
          Please feel free to contact the
          <strong>Iowa Family Support Network</strong> with any questions or concerns.
        </p>
      </mat-card>

      <mat-card class="mb-3 card-spacing-top submitted__card" *ngIf="displayAgency">
        <mat-card-title> Based on the child's zip code, this referral has been submitted to: </mat-card-title>

        <div class="submitted__info">
          <p class="text-semibold">{{ aea?.name }}</p>
          <p>{{ aea?.streetAddress }}</p>
          <p>{{ aea?.city }}, {{ aea?.state }} {{ aea?.zipCode }}</p>
          <p>{{ aea?.primaryPhone | phone }}</p>
          <p>{{ aea?.secondaryPhone | phone }}</p>
        </div>

        <p>
          Please feel free to contact the
          <strong>{{ aea?.name }}</strong> with any questions or concerns.
        </p>
      </mat-card>

      <div class="display-flex justify-content-end align-items-center">
        <button
          mat-raised-button
          aria-label="New Referral"
          class="hide-print"
          color="primary"
          [routerLink]="getNewReferralLink()"
          tabindex="0"
        >
          New Referral
        </button>
      </div>
    </div>

    <div class="early-access__container">
      <img src="../../../assets/img/ea-logo.png" alt="Early ACCESS Logo" class="early-access__image" />
    </div>
  </div>
</section>
