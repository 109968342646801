import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { KeyValuePair } from '../../../../../shared/models/key-value-pair';
import { SpinnerService } from '../../../../../shared/services/spinner/spinner.service';
import { DhhService } from '../../../../services/dhh.service';

@Component({
  selector: 'app-dhh-add-consent-agency',
  templateUrl: './dhh-add-consent-agency.component.html',
  styleUrls: ['./dhh-add-consent-agency.component.scss'],
})
export class DhhAddConsentAgencyModalComponent implements OnInit {
  learnerId: string;
  consentAgencies: any[] = [];
  consentAgencyOptions: KeyValuePair[] = [];
  formGroup = this.fb.group({
    consentAgencyId: [null, [Validators.required]],
  });

  constructor(
    private readonly dhhService: DhhService,
    private readonly spinnerService: SpinnerService,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<DhhAddConsentAgencyModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  async ngOnInit(): Promise<void> {
    this.learnerId = this.data.learnerId;

    this.dhhService.getLearnerConsentAgencies(this.learnerId).subscribe(
      async (result) => {
        if (result.succeeded) {
          this.consentAgencies = result.value;
          this.consentAgencyOptions = this.consentAgencies.map((x) => new KeyValuePair(x.id, x.agencyProgramName));
        } else {
          this.dhhService.handleError('Failed to retrieving learner consent agencies.', result);
        }
        setTimeout(() => this.spinnerService.decrementLoading(), 400);
      },
      (error) => {
        setTimeout(() => this.spinnerService.decrementLoading(), 400);
        this.dhhService.handleError('There was an error while retrieving learner consent agencies.', error);
      }
    );
  }

  onSubmit() {
    this.dialogRef.close(this.consentAgencies.find((x) => x.id === this.formGroup.value.consentAgencyId));
  }
}
