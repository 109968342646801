<section class="card-spacing-top">
  <h3>Family Contact Information</h3>
  <div class="row">
    <div class="col-md-12">
      <div *ngIf="isFamilyContactEditing" class="mb-3">
        <app-family-member
          [formGroup]="getFamilyFormGroup"
          [parentForm]="formGroup"
          [cardTitle]="editTitle"
          [relationshipOptions]="relationshipOptions"
          [referralHowHearAboutUsOptions]="[]"
          [howHearAboutUsOtherId]="''"
          [isFromFamilyContact]="true"
          [isPartCLearner]="false"
        >
        </app-family-member>
        <div class="action__row">
          <button mat-raised-button aria-label="Cancel Button" (click)="cancelEditFamilyMember()" tabindex="0">Cancel</button>
          <button
            color="primary"
            mat-raised-button
            aria-label="Save Button"
            [disabled]="!isFamilyFormValid"
            (click)="saveFamilyMember()"
            tabindex="0"
          >
            Save
          </button>
        </div>
      </div>

      <mat-card class="py-1 position-fix--card">
        <div class="table-overflow app-table">
          <table matSort mat-table [dataSource]="dataSource" class="w-100">
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef>Actions</th>
              <td mat-cell *matCellDef="let family">
                <div class="display-flex align-items-center justify-content-start">
                  <button mat-icon-button aria-label="View Icon" color="primary" (click)="viewMoreFamilyMemberModal(family.id)">
                    <mat-icon>remove_red_eye</mat-icon>
                  </button>
                  <button
                    aria-label="Edit Icon"
                    mat-icon-button
                    color="primary"
                    (click)="editFamilyMember(family)"
                    tabindex="0"
                    *ngIf="!isLearnerExited"
                  >
                    <mat-icon>edit</mat-icon>
                  </button>
                  <button
                    aria-label="Delete Icon"
                    mat-icon-button
                    color="warn"
                    (click)="deleteFamilyMember(family)"
                    tabindex="0"
                    *ngIf="!isLearnerExited"
                  >
                    <mat-icon>close</mat-icon>
                  </button>
                </div>
              </td>
            </ng-container>
            <ng-container matColumnDef="fullName">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>Name</th>
              <td mat-cell *matCellDef="let family">{{ family.fullName }}</td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>Email</th>
              <td mat-cell *matCellDef="let family">{{ family.email }}</td>
            </ng-container>

            <ng-container matColumnDef="homePhone">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>Phones</th>
              <td mat-cell *matCellDef="let family" class="column-nowrap">
                <span [style.display]="'block'" *ngIf="family.homePhone">
                  {{ family.homePhone | phone }}
                </span>
                <span [style.display]="'block'" *ngIf="family.workPhone">
                  {{ family.workPhone | phone }}
                </span>
                <span [style.display]="'block'" *ngIf="family.cellPhone">
                  {{ family.cellPhone | phone }}
                </span>
              </td>
            </ng-container>

            <ng-container matColumnDef="familyRelationship">
              <th mat-sort-header mat-header-cell *matHeaderCellDef>Relationship</th>
              <td mat-cell *matCellDef="let family">
                {{ family.familyRelationship }}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator [dataSource]="dataSource"></mat-paginator>
          <button
            mat-icon-button
            aria-label="Add Icon"
            color="primary"
            (click)="addingNewFamilyMember()"
            *ngIf="!isFamilyContactEditing && !isLearnerExited"
            class="position-fix--add"
            tabindex="0"
          >
            <mat-icon>add_circle</mat-icon>
          </button>
        </div>
        <p *ngIf="!dataSource.data">No data yet...</p>
      </mat-card>
    </div>
  </div>
</section>
