<div class="hide-print">
  <app-page-header
    [pageTitle]="pageTitle"
    backgroundColor="yellow"
    [helpLinks]="!isPopup"
    [condensed]="true"
    [shouldShowDhhBanner]="caseSummary?.learner.isDhh"
  >
    <ng-container pageActions *ngIf="!isPopup">
      <button
        mat-flat-button
        aria-label="Data History"
        (click)="onDataHistoryNavigate()"
        color="primary"
        class="mat-elevation-z0 mr-3"
        tabindex="0"
      >
        Data History
      </button>
    </ng-container>
  </app-page-header>
</div>

<div class="">
  <div class="child-information mt-0 hide-print" *ngIf="dsForm?.learner">
    <app-learner-info-header [learnerId]="dsForm.learner.id" [condensed]="true"></app-learner-info-header>
  </div>

  <section class="hide-normal" *ngIf="dsForm?.learner">
    <div class="display-flex align-items-baseline justify-content-between mb-3">
      <h2 class="mb-0">Disability Suspected</h2>
      <img src="/assets/img/achieve-logo.png" class="print__logo--main" alt="ACHIEVE Iowa Logo" />
    </div>

    <div class="display-flex align-items-center justify-content-between avoid-break">
      <p class="my-0">
        <strong class="text-primary">Full Name:</strong>
        {{ dsForm.learner.fullName }}
      </p>
      <p class="my-0">
        <strong class="text-primary">DOB:</strong>
        {{ dsForm.learner.dateOfBirth | date : shortDateFormat }}
      </p>
    </div>
  </section>

  <div class="ds-form card-spacing-top" *ngIf="!dsForm?.isSubmitted && !isReadOnly">
    <app-ds-form
      *ngIf="dsForm"
      [dsForm]="dsForm"
      [learnerId]="caseSummary?.learnerId"
      [familyMembers]="caseSummary?.learner?.familyMembers"
      [surrogates]="caseSummary?.learner?.surrogates"
      [activeCall]="activeCall"
      (submitEvent)="onSubmit($event)"
      (saveAndClose)="onSaveAndClose($event)"
      (formChange)="saveForm($event).subscribe()"
      (teamUserRemovedEvent)="removeTeamUser($event)"
      role="status"
    ></app-ds-form>
  </div>

  <div class="ds-form-summary mt-0" *ngIf="dsForm?.isSubmitted || (isReadOnly && !!dsForm)">
    <app-ds-form-summary [dsForm]="dsForm" role="status"></app-ds-form-summary>
    <div class="display-flex justify-content-end align-items-center mt-4 mb-4 hide-print">
      <button
        mat-raised-button
        attr.aria-label="{{ dsForm.activeDsPwnId ? 'Edit' : 'Create' }} Prior Written Notice"
        color="accent"
        *ngIf="!dsForm.otherActivePwn && !dsForm.pwnSubmitted && !oneYesResponse"
        (click)="goToPwn()"
        class="mr-2 hide-print"
        tabindex="0"
      >
        {{ dsForm.activeDsPwnId ? 'Edit' : 'Create' }} Prior Written Notice
      </button>
      <button
        mat-raised-button
        attr.aria-label="{{ oneYesResponse ? 'Continue to Consent' : 'Continue' }}"
        color="primary"
        class="hide-print"
        *ngIf="!isPopup && justSubmitted"
        (click)="navigateOnSubmit()"
        tabindex="0"
      >
        {{ oneYesResponse ? 'Continue to Consent' : 'Continue' }}
      </button>
    </div>
  </div>
</div>

<div class="page-bottom-leeway hide-print"></div>
