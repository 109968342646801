<ng-container [formGroup]="formGroup">
  <mat-card class="mb-3">
    <mat-card-title>Dental</mat-card-title>
    <div class="container-fluid container--not-centered container--form">
      <div class="row">
        <div class="col-md-12 col-lg-6">
          <fieldset>
            <app-radio-group
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoNAOptions"
              formControlName="dentistSeen"
              label="Has your child seen a dentist?"
              [disabledState]="locked"
              [sourceData]="questionnaire.healthInfo.dentalVisionHearingInformation"
            ></app-radio-group>
          </fieldset>
        </div>

        <div class="col-md-12 col-lg-6">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoOptions"
              formControlName="dentalConcern"
              label="Are there any concerns about your child’s dental health?"
              [sourceData]="questionnaire.healthInfo.dentalVisionHearingInformation"
            ></app-radio-group>
          </fieldset>
        </div>

        <ng-container *ngIf="!isQuestionnaire">
          <div *ngIf="formGroup.get('dentalConcern').value" class="col-md-12 col-xl-6">
            <app-textarea [disabledState]="locked" formControlName="dentalConcernDescription" label="Describe concern(s)"></app-textarea>
          </div>
          <div class="col-md-12 col-xl-6">
            <app-textarea
              [disabledState]="locked"
              formControlName="dentalAdditionalInformation"
              label="Additional information"
            ></app-textarea>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-card>
  <mat-card class="mb-3">
    <mat-card-title>Vision</mat-card-title>
    <div class="container-fluid container--not-centered container--form">
      <div class="row">
        <div class="col-lg-6">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoNAOptions"
              formControlName="visionTested"
              label="Has your child’s vision been screened or tested?"
              [sourceData]="questionnaire.healthInfo.dentalVisionHearingInformation"
            ></app-radio-group>
          </fieldset>
        </div>
        <div *ngIf="formGroup.get('visionTested').value === 'Yes'" class="col-sm-12 col-md-6 col-xl-3">
          <app-date-picker
            [disabled]="locked"
            *ngIf="isQuestionnaire"
            controlName="visionTestedWhen"
            [max]="today"
            [min]="dateOfBirth"
            label="When?"
          ></app-date-picker>
          <app-overwrite-date-picker
            [disabled]="locked"
            *ngIf="!isQuestionnaire"
            controlName="visionTestedWhen"
            [max]="today"
            [min]="dateOfBirth"
            label="When?"
            [sourceData]="questionnaire.healthInfo.dentalVisionHearingInformation"
          ></app-overwrite-date-picker>
        </div>
        <div *ngIf="formGroup.get('visionTested').value === 'Yes'" class="col-sm-12 col-md-6 col-xl-4">
          <app-text-field
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Where?"
            formControlName="visionTestedWhere"
          ></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Where?"
            formControlName="visionTestedWhere"
            [sourceData]="questionnaire.healthInfo.dentalVisionHearingInformation"
          ></app-overwrite-text-field>
        </div>
      </div>
      <div class="row" *ngIf="formGroup.get('visionTested').value === 'Yes'">
        <div class="col-lg-12">
          <app-textarea
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Results"
            formControlName="visionTestedResults"
          ></app-textarea>
          <app-overwrite-textarea
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Results"
            formControlName="visionTestedResults"
            [sourceData]="questionnaire.healthInfo.dentalVisionHearingInformation"
          ></app-overwrite-textarea>
        </div>
      </div>
      <div class="row" *ngIf="!isQuestionnaire">
        <div class="col-lg-4">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [options]="yesNoOptions"
              formControlName="visionChecklist"
              label="Was the vision checklist completed?"
            ></app-radio-group>
          </fieldset>
        </div>
        <div *ngIf="formGroup.get('visionChecklist').value === false" class="col-lg-8 col-xl-6">
          <app-textarea
            [disabledState]="locked"
            label="Reason for not completing"
            formControlName="visionChecklistDescription"
          ></app-textarea>
        </div>
        <div *ngIf="formGroup.get('visionChecklist').value === true" class="col-lg-4 col-xl-6">
          <div class="display-flex flex-column">
            <fieldset>
              <app-radio-group
                [disabledState]="locked"
                [options]="yesNoOptions"
                formControlName="visionReferral"
                label="Is child being referred to a teacher of the visually impaired (TVI)?"
              ></app-radio-group>
            </fieldset>
            <a
              (click)="openIowaEducationServicesForTheBlindAndVisuallyImpaired()"
              tabindex="0"
              role="link"
              aria-labelledby="TVI Contacts by AEA Region"
            >
              TVI Contacts by AEA Region
            </a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoOptions"
              formControlName="visionConcern"
              label="Are there any concerns regarding your child’s vision?"
              [sourceData]="questionnaire.healthInfo.dentalVisionHearingInformation"
            ></app-radio-group>
          </fieldset>
        </div>

        <ng-container *ngIf="!isQuestionnaire">
          <div *ngIf="formGroup.get('visionConcern').value" class="col-md-12 col-xl-4">
            <app-textarea [disabledState]="locked" formControlName="visionConcernDescription" label="Describe concern(s)"></app-textarea>
          </div>
          <div class="col-md-12">
            <app-textarea
              [disabledState]="locked"
              formControlName="visionAdditionalInformation"
              label="Additional information"
            ></app-textarea>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-card>
  <mat-card class="mb-3">
    <mat-card-title>Hearing</mat-card-title>
    <div class="container-fluid container--not-centered container--form">
      <div class="row">
        <div class="col-lg-6">
          <label
            class="vertical-label text-md display-block"
            [ngClass]="{
              asterisk_input: formGroup.get('hearingTested').hasError('required')
            }"
          >
            Has your child’s hearing been
            <a
              (click)="onOpenHelp($event, helpSection.Intake, intakeDentalVisionHearingHelp.ScreenedTested)"
              class="text-lower text-underline"
              tabindex="0"
              role="link"
              attr.aria-label="{{ intakeDentalVisionHearingHelp.ScreenedTested }}?"
            >
              {{ intakeDentalVisionHearingHelp.ScreenedTested }}?
            </a>
          </label>
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoNAOptions"
              formControlName="hearingTested"
              [hideAsterisk]="true"
              [sourceData]="questionnaire.healthInfo.dentalVisionHearingInformation"
            ></app-radio-group>
          </fieldset>
        </div>
        <div *ngIf="formGroup.get('hearingTested').value === 'Yes'" class="col-sm-12 col-md-6 col-xl-3">
          <app-date-picker
            [disabled]="locked"
            *ngIf="isQuestionnaire"
            controlName="hearingTestedWhen"
            [max]="today"
            [min]="dateOfBirth"
            label="When?"
          ></app-date-picker>
          <app-overwrite-date-picker
            [disabled]="locked"
            *ngIf="!isQuestionnaire"
            controlName="hearingTestedWhen"
            [max]="today"
            [min]="dateOfBirth"
            label="When?"
            [sourceData]="questionnaire.healthInfo.dentalVisionHearingInformation"
          ></app-overwrite-date-picker>
        </div>
        <div *ngIf="formGroup.get('hearingTested').value === 'Yes'" class="col-sm-12 col-md-6 col-xl-4">
          <app-text-field *ngIf="isQuestionnaire" label="Where?" formControlName="hearingTestedWhere"></app-text-field>
          <app-overwrite-text-field
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Where?"
            formControlName="hearingTestedWhere"
            [sourceData]="questionnaire.healthInfo.dentalVisionHearingInformation"
          ></app-overwrite-text-field>
        </div>
      </div>
      <div class="row" *ngIf="formGroup.get('hearingTested').value === 'Yes'">
        <div class="col-lg-12">
          <app-textarea
            [disabledState]="locked"
            *ngIf="isQuestionnaire"
            label="Results"
            formControlName="hearingTestedResults"
          ></app-textarea>
          <app-overwrite-textarea
            [disabledState]="locked"
            *ngIf="!isQuestionnaire"
            label="Results"
            formControlName="hearingTestedResults"
            [sourceData]="questionnaire.healthInfo.dentalVisionHearingInformation"
          ></app-overwrite-textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-lg-6">
          <fieldset>
            <app-radio-group
              [disabledState]="locked"
              [useOverwrite]="!isQuestionnaire"
              [options]="yesNoOptions"
              formControlName="hearingConcern"
              label="Are there any concerns regarding your child’s hearing?"
              [sourceData]="questionnaire.healthInfo.dentalVisionHearingInformation"
            ></app-radio-group>
          </fieldset>
        </div>

        <ng-container *ngIf="!isQuestionnaire">
          <div *ngIf="formGroup.get('hearingConcern').value" class="col-md-12 col-xl-4">
            <app-textarea [disabledState]="locked" formControlName="hearingConcernDescription" label="Describe concern(s)"></app-textarea>
          </div>
          <div class="col-md-12">
            <app-textarea
              [disabledState]="locked"
              formControlName="hearingAdditionalInformation"
              label="Additional information"
            ></app-textarea>
          </div>
        </ng-container>
      </div>
    </div>
  </mat-card>
</ng-container>

<!-- TODO: Update this link -->
<app-documentation-info-form
  *ngIf="!isQuestionnaire"
  title="Relevant Form(s)"
  uploadCardTitle="Upload Completed Form"
  uploadButtonTitle="Upload Completed Form"
  documentTitle="Vision Checklist"
  [documentLink]="visionChecklistUrl"
  [formGroup]="documentationInfo"
  [documents]="intake?.documentationInfo"
  [caseId]="intake?.caseId"
  [locked]="locked"
  [section]="intakeSections.DentalVisionHearing"
  [learnersName]="childsName"
  (upload)="onUpload($event)"
  (deleteDocument)="onDeleteDocument($event)"
  documentType="Vision"
>
</app-documentation-info-form>
