import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, AbstractControlOptions, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Aea } from 'src/app/shared/models/aea';
import { District } from 'src/app/shared/models/district';
import { ConsentFormAgency, ConsentFormType } from 'src/app/shared/models/fiie-consent/consent-form';
import { FamilyConsentStatus } from 'src/app/shared/models/fiie-consent/family-consent-status';
import { KeyValuePair } from 'src/app/shared/models/key-value-pair';
import { usStates } from 'src/app/shared/models/us-states';
import { AeaService } from 'src/app/shared/services/aea/aea.service';
import { DistrictService } from 'src/app/shared/services/district/district.service';
import * as AppValidators from 'src/app/shared/validators';
import { DateToUtcPipe } from '../../../../shared/pipes/date-transform.pipe';
import { LocationService } from '../../../../shared/services/location/location.service';
import { RoutingService } from 'src/app/shared/services/routing.service';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { ReevaluationService } from 'src/app/reevaluation/services/reevaluation.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { ParentType } from '../../../../shared/models/parent-type';
import { DhhConsentAgencyDto } from '../../../../dhh/models/DhhDtos';

export interface AddConsentDialogResult {
  type: { value: ConsentFormType; caseId: string };
  parentType: string;
  notes: string;
  status: FamilyConsentStatus;
  dateReceived: Date;
  dateSigned: Date;
  signedBy: string;
  aea?: {
    aeaId?: string;
  };
  district?: {
    districtId?: string;
  };
  agency?: ConsentFormAgency;
  reevaluationId?: string;
  externalForm?: any;
}

@Component({
  selector: 'app-family-consent-add',
  templateUrl: './family-consent-add.component.html',
  styleUrls: ['./family-consent-add.component.scss'],
  providers: [DateToUtcPipe],
})
export class FamilyConsentAddComponent implements OnInit {
  formGroup: FormGroup;
  today: Date = new Date();
  statuses = FamilyConsentStatus;
  aeas: Array<Aea>;
  districts: Array<District>;
  aeasOptions: KeyValuePair[] = [];
  districtsOptions: KeyValuePair[] = [];
  usStates = usStates.map((x) => new KeyValuePair(x, x));
  externalForm?: any;
  hideAll: boolean;
  showSavedAgencies = false;
  savedAgencyOptions: KeyValuePair[] = [];

  availableConsentTypeOptionsWithCases: KeyValuePair[] = [];
  availConsentFormTypeOptions: KeyValuePair[] = [];
  familyConsentStatusOptions: KeyValuePair[] = [
    new KeyValuePair(FamilyConsentStatus.Approved, 'Approved'),
    new KeyValuePair(FamilyConsentStatus.Requested, 'Requested'),
    new KeyValuePair(FamilyConsentStatus.Declined, 'Declined'),
  ];
  parentTypeOptions: KeyValuePair[] = [
    new KeyValuePair(ParentType.Mother, 'Mother'),
    new KeyValuePair(ParentType.Father, 'Father'),
    new KeyValuePair(ParentType.Guardian, 'Guardian'),
    new KeyValuePair(ParentType.Surrogate, 'Surrogate'),
  ];

  get consentTypeCtrl() {
    return this.formGroup.get('type');
  }

  get aeaFields() {
    return <FormGroup>this.formGroup.get('aea');
  }

  get districtFields() {
    return <FormGroup>this.formGroup.get('district');
  }

  get agencyFields() {
    return <FormGroup>this.formGroup.get('agency');
  }

  get reevaluationFields() {
    return <FormControl>this.formGroup.get('reevaluationId');
  }

  get parentTypeField() {
    return <FormGroup>this.formGroup.get('parentType');
  }

  get title() {
    if (this.data.referralConsent) return 'Add to Consent List';
    if (this.data.defaultValues?.type?.value) return `Add New - ${this.getConsentFormTitle(this.data.defaultValues?.type?.value)}`;
    return 'Add New Consent';
  }

  get isNotDeniedOrRequested() {
    return (
      this.formGroup.get('status').value !== this.statuses.Declined &&
      this.formGroup.get('status').value !== this.statuses.Denied &&
      this.formGroup.get('status').value !== this.statuses.Requested
    );
  }

  get shouldShowSavedAgencies() {
    return (
      this.data.isDhh &&
      (this.formGroup.get('type').value === ConsentFormType.AuthorizationReleaseAndExchangeInformation ||
        this.formGroup.get('type').value === ConsentFormType.ReleaseAndExchangeInformation)
    );
  }

  constructor(
    public dialogRef: MatDialogRef<AddConsentDialogResult>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      referralConsent: false;
      availConsentFormTypeOptions: KeyValuePair[];
      defaultValues: AddConsentDialogResult;
      isDhh: boolean;
      savedAgencies: DhhConsentAgencyDto[];
    },
    private readonly fb: FormBuilder,
    private readonly aeaService: AeaService,
    private readonly districtService: DistrictService,
    private readonly locationService: LocationService,
    private readonly dateToUtcPipe: DateToUtcPipe,
    private readonly routingService: RoutingService,
    private readonly reevaluationService: ReevaluationService,
    private readonly notificationService: NotificationService
  ) {
    this.availableConsentTypeOptionsWithCases = data.availConsentFormTypeOptions;
    //strip out the case id so autocomplete control can compare keys correctly
    this.availConsentFormTypeOptions = data.availConsentFormTypeOptions.map((e) => new KeyValuePair(e.key.value, e.value));
    if (data.savedAgencies) this.savedAgencyOptions = data.savedAgencies.map((a) => new KeyValuePair(a.id, a.agencyProgramName));
  }

  public getConsentFormTitle(key: ConsentFormType): string {
    const found = this.data.availConsentFormTypeOptions.find((cft) => cft.key.value == key);
    return found.value;
  }

  /** Create a disabled FormGroup */
  private optionalFormGroup(
    controlsConfig: {
      [key: string]: any;
    },
    options?: AbstractControlOptions | null
  ): FormGroup {
    const ret = this.fb.group(controlsConfig, options);
    ret.disable();
    return ret;
  }

  ngOnInit(): void {
    const defaults = this.data.defaultValues;
    this.formGroup = this.fb.group(
      {
        type: new FormControl(defaults?.type.value, [Validators.required]),
        notes: new FormControl(defaults?.notes),
        status: new FormControl(this.data.referralConsent ? FamilyConsentStatus.Approved : null, [Validators.required]),
        dateReceived: [defaults?.dateReceived, [Validators.required]],
        dateSigned: [defaults?.dateSigned, [Validators.required]],
        signedBy: [defaults?.signedBy ?? '', [Validators.required]],
        aea: this.optionalFormGroup({
          aeaId: [{ value: defaults?.aea?.aeaId }, Validators.required],
        }),
        district: this.optionalFormGroup({
          districtId: [{ value: defaults?.district?.districtId }, Validators.required],
        }),
        agency: this.optionalFormGroup({
          //at least one of name or contact is required
          name: [
            defaults?.agency?.name,
            AppValidators.conditionalValidator(() => !this.formGroup.get('agency').get('contact')?.value, Validators.required),
          ],
          contact: [
            defaults?.agency?.contact,
            AppValidators.conditionalValidator(() => !this.formGroup.get('agency').get('name')?.value, Validators.required),
          ],
          email: [defaults?.agency?.email],
          phone: [defaults?.agency?.phone],
          fax: [defaults?.agency?.fax],
          address: [defaults?.agency?.address, Validators.required],
          city: [defaults?.agency?.city, Validators.required],
          state: [defaults?.agency?.state, Validators.required],
          zipCode: [defaults?.agency?.zipCode, [Validators.required, AppValidators.zipCodeValidator]],
        }),
        parentType: this.optionalFormGroup({
          parentType: [
            '',
            AppValidators.conditionalValidator(
              () => this.formGroup.get('status')?.value === FamilyConsentStatus.Approved,
              Validators.required
            ),
          ],
        }),
        reevaluationId: ['', Validators.required],
      },
      { updateOn: 'blur' }
    );

    if (this.data.isDhh && this.data.defaultValues?.type?.value !== ConsentFormType.IvrsConsent) {
      this.formGroup.addControl('savedAgency', new FormControl());
      this.formGroup.get('savedAgency').valueChanges.subscribe((res) => {
        if (res) {
          const savedAgency = this.data.savedAgencies.find((x) => x.id === res);
          if (!!savedAgency) {
            this.formGroup.get('agency').patchValue({
              name: savedAgency.agencyProgramName,
              contact: savedAgency.contactName,
              email: savedAgency.contactEmail,
              phone: savedAgency.contactPhone,
              fax: savedAgency.contactFax,
              address: savedAgency.address,
              city: savedAgency.city,
              state: savedAgency.state,
              zipCode: savedAgency.zipCode,
            });
          }
        }
      });
    }

    //prevent blowing up the stack by update triggering update triggering update...
    let isPropagating = false;
    this.formGroup.get('agency').valueChanges.subscribe((v) => {
      if (!isPropagating) {
        isPropagating = true;
        try {
          this.formGroup.get('agency').get('name').updateValueAndValidity();
          this.formGroup.get('agency').get('contact').updateValueAndValidity();
        } finally {
          isPropagating = false;
        }
      }
    });

    this.formGroup.get('status').valueChanges.subscribe((val) => {
      const fields = ['signedBy', 'dateSigned'];
      if (val === this.statuses.Approved) {
        fields.forEach((field) => {
          this.formGroup.get(field).enable();
        });
      } else {
        fields.forEach((field) => {
          this.formGroup.get(field).disable();
        });
      }
    });

    this.consentTypeCtrl.valueChanges.subscribe({
      next: (val) => this.configureFormGroups(val),
    });

    this.configureFormGroups(this.data.defaultValues?.type.value);
  }

  private configureFormGroups(type: ConsentFormType) {
    this.setHideAll(type);
    switch (type) {
      case ConsentFormType.MedicaidAEA:
        this.getAEAS();
        this.enableOptionalFormGroup(this.aeaFields);
        break;
      case ConsentFormType.MedicaidLEA:
        this.getDistricts();
        this.enableOptionalFormGroup(this.districtFields);
        break;
      case ConsentFormType.AuthorizationReleaseAndExchangeInformation:
        this.enableOptionalFormGroups([this.agencyFields, this.parentTypeField]);
        break;
      case ConsentFormType.ReleaseAndExchangeInformation:
        this.enableOptionalFormGroup(this.agencyFields);
        break;
      case ConsentFormType.Reevaluation:
        this.enableOptionalFormGroup(this.reevaluationFields);
        break;
      default:
        this.enableOptionalFormGroup();
        break;
    }
  }

  private enableOptionalFormGroup(enabled?: AbstractControl) {
    if (this.aeaFields !== enabled) this.aeaFields.disable();
    if (this.districtFields !== enabled) this.districtFields.disable();
    if (this.agencyFields !== enabled) this.agencyFields.disable();
    if (this.reevaluationFields !== enabled) this.reevaluationFields.disable();
    if (this.parentTypeField !== enabled) this.parentTypeField.disable();
    enabled?.enable();
  }

  //allow multiple enable optional form groups to be enabled at once
  private enableOptionalFormGroups(enabled?: AbstractControl | AbstractControl[]) {
    if (enabled instanceof Array) {
      this.aeaFields.disable();
      this.districtFields.disable();
      this.agencyFields.disable();
      this.reevaluationFields.disable();
      this.parentTypeField.disable();
      enabled.forEach((e) => e.enable());
    } else {
      if (this.aeaFields !== enabled) this.aeaFields.disable();
      if (this.districtFields !== enabled) this.districtFields.disable();
      if (this.agencyFields !== enabled) this.agencyFields.disable();
      if (this.reevaluationFields !== enabled) this.reevaluationFields.disable();
      if (this.parentTypeField !== enabled) this.parentTypeField.disable();
      enabled?.enable();
    }
  }

  getAEAS() {
    if (!this.aeas) {
      this.aeaService.getPublicAeas().subscribe({
        next: (aeas) => {
          this.aeas = aeas;
          this.aeasOptions = this.aeas.map((o) => new KeyValuePair(o.id, o.name));
        },
      });
    }
  }

  populateLocationFromZipCode() {
    const zipCodeControl = this.formGroup.get('agency').get('zipCode');
    const zipCode = zipCodeControl.value;
    if (!zipCodeControl.valid) {
      return;
    }

    this.locationService.getLocationData(zipCode.substring(0, 5)).subscribe(
      (res) => {
        if (res) {
          this.formGroup.get('agency').patchValue({
            city: res.city,
            state: 'IA',
          });
        }
      },
      () => {
        this.formGroup.get('agency').patchValue({
          city: null,
          state: null,
        });
      }
    );
  }

  getDistricts() {
    if (!this.districts) {
      this.districtService.getDistricts().subscribe({
        next: (districts) => {
          this.districts = districts;
          this.districtsOptions = this.districts.map((o) => new KeyValuePair(o.id, o.name));
        },
      });
    }
  }

  openReevaluationForm() {
    this.reevaluationFields.setValue(null);
    this.externalForm = null;
    const caseId = this.availableConsentTypeOptionsWithCases.find((kvp) => kvp.key.value == ConsentFormType.Reevaluation)?.key?.caseId;
    this.reevaluationService.getAll(caseId).subscribe((res) => {
      if (res.succeeded && res.value?.length > 0) {
        const openReevaluation = res.value.find((x) => !x.isFinalized);
        if (openReevaluation) {
          const path = this.routingService.reevaluationConsentPath(caseId, openReevaluation.id, 'pop-up').join('/');
          const config: NewWindowConfig = {
            path,
            popup: true,
            width: '1280px',
          };
          const w = openNewWindow(config);
          w.addEventListener('message', async (event) => {
            if (event.data?.action === 'Ready') {
              this.reevaluationFields.setValue(event.data.reevaluationId);
              this.externalForm = event.data.model;
            }
            this.setHideAll(ConsentFormType.Reevaluation);
          });
          return;
        }
      }
      this.notificationService.error('Something went wrong! The reevaluation is missing.');
    });
  }

  private setHideAll(type: ConsentFormType) {
    this.hideAll = type === ConsentFormType.Reevaluation && this.externalForm && this.externalForm.recommendAssessment === false;
    if (this.hideAll) {
      this.formGroup.controls.status.disable();
      this.formGroup.controls.dateReceived.disable();
      this.formGroup.controls.signedBy.disable();
      this.formGroup.controls.dateSigned.disable();
      this.formGroup.controls.parentType.disable();
    } else {
      this.formGroup.controls.status.enable();
      this.formGroup.controls.dateReceived.enable();
      this.formGroup.controls.signedBy.enable();
      this.formGroup.controls.dateSigned.enable();
      if (this.parentTypeField.enabled) {
        this.formGroup.controls.parentType.enable();
      }
    }
  }

  onCancel() {
    this.dialogRef.close();
  }

  onSave() {
    const ret = this.formGroup.value as AddConsentDialogResult;
    //Add back the case id that was stripped out on load
    if (this.parentTypeField.enabled) {
      ret.parentType = this.formGroup.get('parentType').value.parentType;
    }
    console.info('Add Consent Form:', ret);
    ret.type = this.availableConsentTypeOptionsWithCases.find((kvp) => kvp.key.value == ret.type).key;
    ret.dateReceived = ret.dateReceived !== null ? this.dateToUtcPipe.transform(ret.dateReceived) : null;
    ret.dateSigned = ret.dateSigned !== null ? this.dateToUtcPipe.transform(ret.dateSigned) : null;
    if (this.externalForm) ret.externalForm = this.externalForm;
    this.dialogRef.close(ret);
  }
}
