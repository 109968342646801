import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  DisputeResolutionStateComplaintDashboardFilters,
  InvestigatorUpdate,
  QuestionResponse,
  ResponseType,
  StateComplaintSubmissionDto,
} from '../models/state-complaint';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { OperationResult, OperationResultWithValue } from '../../../../../shared/models/operation-result';
import { Observable, of, Subject, throwError } from 'rxjs';
import { SpinnerService } from '../../../../../shared/services/spinner/spinner.service';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { StateComplaintForwardDto } from '../models/state-complaint-forward-recipient-dto';
import { Base64Document } from '../../../../../shared/models/base64Document';
import { DisputeResolutionDocument } from '../../../shared/models/dispute-resolution-document';

@Injectable({
  providedIn: 'root',
})
export class StateComplaintService {
  constructor(
    private readonly http: HttpClient,
    private readonly spinnerService: SpinnerService,
    private notificationService: NotificationService
  ) {}

  private baseUrl = 'api/dispute-resolution/state-complaint';

  private caseMarkedReceived = new Subject<string>();
  caseMarkedReceived$ = this.caseMarkedReceived.asObservable();

  private questionsPrepared = new Subject<string>();
  questionsPrepared$ = this.questionsPrepared.asObservable();

  addSignature(dto: StateComplaintSubmissionDto) {
    const turnOffSpinner = () => this.spinnerService.decrementLoading();
    this.spinnerService.incrementLoading();
    return this.http.post<OperationResultWithValue<any>>(`${this.baseUrl}/signature`, dto).pipe(
      tap(turnOffSpinner, turnOffSpinner),
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  uploadStateComplaintSubmission(signatoryId: string, dto: StateComplaintSubmissionDto) {
    const turnOffSpinner = () => this.spinnerService.decrementLoading();
    this.spinnerService.incrementLoading();
    return this.http.post<OperationResultWithValue<any>>(`${this.baseUrl}/upload/${signatoryId}`, dto).pipe(
      tap(turnOffSpinner, turnOffSpinner),
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  getStateComplaintSubmission(id: string) {
    return this.http.get<OperationResultWithValue<any>>(`${this.baseUrl}/${id}`).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  getStateComplaintSubmissionByEmail(email: string) {
    let params = new HttpParams();
    params = params.set('email', email);
    return this.http.get<OperationResultWithValue<any>>(`${this.baseUrl}/interactive-access`, { params }).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  forwardStateComplaint(dto: StateComplaintForwardDto) {
    return this.http.post<OperationResultWithValue<any>>(`${this.baseUrl}/forward`, dto).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  getStateComplaintCase(id: string) {
    return this.http.get<OperationResultWithValue<any>>(`${this.baseUrl}/case/${id}`).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  getQuestions(id: string, assignedToMe: boolean, response?: string) {
    let param = '?selfAssigned=' + (assignedToMe ? 'true' : 'false');
    if (response) {
      param = param + '&' + (response.toLowerCase() === 'answered' ? 'answered=true' : 'answered=false');
    }
    return this.http.get<OperationResultWithValue<any>>(`${this.baseUrl}/${id}/questions${param}`).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }
  private displayErrorNotification(error: any): Observable<string> {
    this.notificationService.error(error);
    return of('error');
  }

  private throwError(errorTitle: string, error: any): Observable<string> {
    let errorMessage = '';
    if (error && error.error && error.error.errors) {
      errorMessage = Array.isArray(error.error.errors)
        ? error.error.errors.map((err) => err.description)?.join(',')
        : Object.keys(error.error.errors)
            .map((key) => error.error.errors[key])
            ?.join(',');
    } else if (error && error.errors) {
      errorMessage = Array.isArray(error.errors)
        ? error.errors.map((err) => err.description)?.join(',')
        : Object.keys(error.errors)
            .map((key) => error.errors[key])
            ?.join(',');
    } else if (error && error.message) {
      errorMessage = error.message;
    } else {
      errorMessage = JSON.stringify(error);
    }

    return throwError({ title: errorTitle, message: new Error(errorMessage) });
  }

  getStateComplaintCaseList(filters: DisputeResolutionStateComplaintDashboardFilters) {
    return this.http.post<OperationResultWithValue<any>>(`${this.baseUrl}`, filters).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  getDashboardLookups() {
    return this.http.get<OperationResultWithValue<any>>(`${this.baseUrl}/dashboard-lookups`).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  getInvestigators() {
    return this.http.get<OperationResultWithValue<any>>(`${this.baseUrl}/investigators`).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }
  updateInvestigators(dto: InvestigatorUpdate) {
    return this.http.post<OperationResultWithValue<any>>(`${this.baseUrl}/investigators`, dto).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  updateLearner(caseId, dto) {
    return this.http.post<OperationResultWithValue<any>>(`${this.baseUrl}/${caseId}/update-learner`, dto).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  changeMultipleLearner(caseId: string, multipleLearner: boolean) {
    return this.http.post<OperationResultWithValue<any>>(`${this.baseUrl}/change-multiple-learner`, { caseId, multipleLearner }).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  changeCaseNumber(caseId: string, caseNumber: string, reason: string) {
    return this.http.post<OperationResultWithValue<any>>(`${this.baseUrl}/change-case-number`, { caseId, caseNumber, reason }).pipe(
      catchError((error) => this.displayErrorNotification(error)),
      switchMap((result: OperationResultWithValue<any>) =>
        result.succeeded ? of(result.value) : this.displayErrorNotification(result.errors[0].description)
      )
    );
  }

  markCaseReceived(caseId: string, dateReceived: string) {
    return this.http.post<OperationResult>(`${this.baseUrl}/update-received-date`, { caseId: caseId, receivedDate: dateReceived }).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResult) => (result.succeeded ? of('') : this.throwError('Error', result.errors)))
    );
  }

  emitCaseReceived(caseId: string) {
    this.caseMarkedReceived.next(caseId);
  }

  emitQuestionsPrepared(caseId: string) {
    this.questionsPrepared.next(caseId);
  }

  getAssignedTo() {
    return this.http.get<OperationResultWithValue<any>>(`${this.baseUrl}/assigned-to`).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  updateQuestions(caseId: string, questions: Array<any>) {
    return this.http.post<OperationResult>(`${this.baseUrl}/${caseId}/update-questions`, questions).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResult) => (result.succeeded ? of('') : this.throwError('Error', result.errors)))
    );
  }

  updateResponses(responses: QuestionResponse[], caseId: string) {
    return this.http.post<OperationResult>(`${this.baseUrl}/${caseId}/update-responses`, responses).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResult) => (result.succeeded ? of('') : this.throwError('Error', result.errors)))
    );
  }

  updateResponse(
    caseId: string,
    questionId: string,
    responseType: ResponseType,
    response: string,
    documents: Array<DisputeResolutionDocument>
  ) {
    return this.http
      .post<OperationResult>(`${this.baseUrl}/${caseId}/update-response`, {
        questionId: questionId,
        responseType: responseType,
        response: response,
        documents: documents,
      })
      .pipe(
        catchError((error) => this.throwError('Error', error)),
        switchMap((result: OperationResult) => (result.succeeded ? of('') : this.throwError('Error', result.errors)))
      );
  }

  getCaseSummaryLookups() {
    return this.http.get<OperationResultWithValue<any>>(`${this.baseUrl}/case-summary-lookups`).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResultWithValue<any>) => (result.succeeded ? of(result.value) : this.throwError('Error', result.errors)))
    );
  }

  saveCaseSummary(caseId: string, dto: any) {
    return this.http.post<OperationResult>(`${this.baseUrl}/${caseId}/update-case-summary`, dto).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResult) => (result.succeeded ? of('') : this.throwError('Error', result.errors)))
    );
  }

  finalizeCase(caseId: string, dto: any) {
    return this.http.post<OperationResult>(`${this.baseUrl}/${caseId}/finalize-case`, dto).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResult) => (result.succeeded ? of('') : this.throwError('Error', result.errors)))
    );
  }

  closeCase(caseId: string) {
    return this.http.post<OperationResult>(`${this.baseUrl}/${caseId}/close-case`, {}).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResult) => (result.succeeded ? of('') : this.throwError('Error', result.errors)))
    );
  }

  reopenCase(caseId: string) {
    return this.http.post<OperationResult>(`${this.baseUrl}/${caseId}/reopen-case`, {}).pipe(
      catchError((error) => this.throwError('Error', error)),
      switchMap((result: OperationResult) => (result.succeeded ? of('') : this.throwError('Error', result.errors)))
    );
  }

  printQuestions(caseId: string) {
    window.open(`api/bip/${caseId}/pdf/implementationChecklist`, '_blank');
  }
}
