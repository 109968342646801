<div class="table-overflow app-table">
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z0" matSort>
    <ng-container matColumnDef="selectMatch">
      <th mat-header-cell *matHeaderCellDef class="text-left"></th>
      <td mat-cell *matCellDef="let element" class="text-left">
        <button mat-raised-button aria-label="Select" color="primary" (click)="selectLearner(element)" tabindex="0">Select</button>
      </td>
    </ng-container>

    <ng-container matColumnDef="lastName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Last Name</th>
      <td mat-cell *matCellDef="let element">{{ element.lastName }}</td>
    </ng-container>

    <ng-container matColumnDef="firstName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>First Name</th>
      <td mat-cell *matCellDef="let element">{{ element.firstName }}</td>
    </ng-container>

    <ng-container matColumnDef="dateOfBirth">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>DOB</th>
      <td mat-cell *matCellDef="let element">
        {{ element.dateOfBirth | dobNoTz | dateFormat }}
      </td>
    </ng-container>

    <ng-container matColumnDef="grade">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Grade</th>
      <td mat-cell *matCellDef="let element">{{ element.grade }}</td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
      <td mat-cell *matCellDef="let element">{{ element.status }}</td>
    </ng-container>

    <ng-container matColumnDef="owner">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Facilitator/Service Coordinator</th>
      <td mat-cell *matCellDef="let element">{{ element.owner }}</td>
    </ng-container>

    <ng-container matColumnDef="annualReviewDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Annual Review Date</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.status !== 'Exited'">
          {{ element.annualReviewDate | dobNoTz | dateFormat }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="reevalDate">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>ReEval Date</th>
      <td mat-cell *matCellDef="let element">
        <ng-container *ngIf="element.status !== 'Exited'">
          {{ element.reevalDate | dobNoTz | dateFormat }}
        </ng-container>
      </td>
    </ng-container>

    <ng-container matColumnDef="attendingAea">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>AEA</th>
      <td mat-cell *matCellDef="let element">
        {{ element.attendingAea }}
      </td>
    </ng-container>

    <ng-container matColumnDef="attendingDistrict">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>School District</th>
      <td mat-cell *matCellDef="let element">
        {{ element.attendingDistrict }}
      </td>
    </ng-container>

    <ng-container matColumnDef="noResults">
      <td mat-footer-cell *matFooterCellDef colspan="7">
        <i>No results...</i>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    <tr mat-footer-row *matFooterRowDef="searchResults.length === 0 ? ['noResults'] : []" class="example-second-footer-row"></tr>
  </table>
  <mat-paginator [dataSource]="dataSource" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"></mat-paginator>
  <p *ngIf="!dataSource.data">No data yet...</p>
</div>
