import { Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { EmailStatus, SignatoryType, SignedForm, SignedFormSignatory, SignedFormStatus } from '@idoe/electronic-signature';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable } from 'rxjs';
import { AuthService } from '../../../auth/auth.service';
import { AppPermissions } from '../../../permissions';
import { SignedFormService } from '../../../signed-forms/signed-form.service';
import { ConsentFormType } from '../../models/fiie-consent/consent-form';
import { FamilyConsentStatus } from '../../models/fiie-consent/family-consent-status';
import { NotificationService } from '../../services/notification.service';
import { IDocument } from '../document-upload/document-upload-list/document-upload-list.models';
import { AlertConfig } from '../page-alert/page-alert.component';
import { SelectSignatoriesComponent } from '../select-signatories/select-signatories.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'app-signed-form-overview',
  templateUrl: './signed-form-overview.component.html',
  styleUrls: ['./signed-form-overview.component.scss'],
})
export class SignedFormOverviewComponent implements OnInit {
  SignedFormStatus = SignedFormStatus;
  EmailStatus = EmailStatus;

  @Input() signedFormId: string;
  @Input() finalizedDocument: IDocument;
  @Input() supplementaryDocuments: IDocument[];
  @Input() allowDocumentUpload: boolean;
  @Input() learnerId: string;
  @Input() parentId: string;
  @Input() consentFormType: string;
  @Input() isRevokable: boolean;
  @Input() allowWetSignature: boolean;
  @Input() caseId: string;
  @Input() isComplete: boolean;
  @Input() consentStatus: string;
  @Input() isDhh: boolean;
  @Input() hideViewButton: boolean;
  @Input() isMultipleSignatory: boolean;
  @Input() isRemovable: boolean;

  // if you don't want to handle the document events manually,
  // you can pass bound storage service functions below and the
  // document list will be managed automatically for you from here.
  @Input() docUploadFunc: (id: string, uploadData: FormData) => Observable<IDocument[]>;
  @Input() docDeleteFunc: (id: string, docId: string) => any;
  @Input() docGetFunc: (id: string, docId: string) => Observable<string>;
  @Input() viewFinalDocFunc: (id: string) => void;
  @Input() getRoleNameFunc: (signatory: SignedFormSignatory) => string;

  @Output() signedFormIdChange = new EventEmitter<string>();
  @Output() docUploaded = new EventEmitter<FormData>();
  @Output() docDeleted = new EventEmitter<string>();
  @Output() supplementaryDocumentsChange = new EventEmitter<IDocument[]>();
  @Output() createSignedForm = new EventEmitter<SignedFormSignatory[]>();
  @Output() wetSignatureUploaded = new EventEmitter<FormData>();
  @Output() signatureCompleted = new EventEmitter<string>();
  @Output() removeSignatory = new EventEmitter<SignedFormSignatory>();

  @ViewChild('signFormDialog') signFormDialog: SelectSignatoriesComponent;

  signedForm: SignedForm;
  signatoriesDataSource = new MatTableDataSource<SignedFormSignatory>();
  displayedColumns = [
    'actions',
    'firstName',
    'lastName',
    // 'email',
    // 'status',
    'sigStatus',
  ];
  tabIndex: number;

  canSign: boolean;

  consentStatuses = FamilyConsentStatus;

  ConsentFormType = ConsentFormType;

  SignatoryType = SignatoryType;

  get alertConfig(): AlertConfig {
    if (!this.isSignedByAllParties()) {
      return {
        status: 'info',
        message: 'Not everyone has signed this form yet.',
      };
    }

    if (this.signedForm?.signedFormStatus === SignedFormStatus.Revoked) {
      return {
        status: 'warning',
        message: 'This form has been revoked.',
      };
    }

    if (this.isSignedByAllParties()) {
      return {
        status: 'warning',
        message: 'This form has been signed by all parties.',
      };
    }
  }

  getSignatoryStatus(signatory: SignedFormSignatory) {
    if (this.isMultipleSignatory) {
      return signatory.signedFormValues?.length > 0 ? 'Signed' : 'Pending';
    }

    if (signatory.signedFormValues?.length > 0) return 'Signed';

    if (this.isComplete) return 'Unavailable';
    return this.signedForm?.signatories?.some((x) => x.signedFormValues?.length > 0) ? 'Inactive' : 'Pending';
  }

  get hasEditFamilyConsentPermission() {
    return this.authService.isAllowedByCaseId(this.caseId, undefined, AppPermissions.EditFamilyConsent) || this.isDhh;
  }

  get canAddSignatories() {
    if (this.consentStatus !== this.consentStatuses.Requested) return false;
    if (this.consentFormType !== ConsentFormType.ReceiveElectronicCommunication) return true;

    //Receive Electronic Communication Consent can only have a single signatory per consent form
    return !this.signedForm?.signatories || this.signedForm.signatories.length === 0;
  }

  get isSignedForm() {
    return !!this.signedFormId;
  }

  get canViewSignedForm() {
    return (
      (!this.authService?.isPortalUser || (this.authService?.isFamily && !this.authService?.isPortalUserEdit) || this.isDhh) &&
      !this.hideViewButton
    );
  }

  constructor(
    private signedFormService: SignedFormService,
    private route: ActivatedRoute,
    private notifications: NotificationService,
    private ngZone: NgZone,
    readonly authService: AuthService
  ) {}

  ngOnInit(): void {
    if (this.getRoleNameFunc) {
      this.displayedColumns.splice(3, 0, 'signatoryType');
    }
    this.loadForm();
    this.route.fragment.pipe(untilDestroyed(this)).subscribe((fragment) => {
      switch (fragment) {
        case 'signatories':
          this.tabIndex = 2;
          break;
      }
    });

    this.route.snapshot.queryParamMap.get('canSign') === 'false' ? (this.canSign = false) : (this.canSign = true);
  }

  private async loadForm() {
    if (this.signedFormId) {
      this.signedForm = await this.signedFormService.getForm(this.signedFormId).toPromise();
      this.setDataSource();
    }
  }

  newSignature() {
    //TODO: consentFormType here, passing as signedFormType in dialog
    this.signFormDialog.open(this.learnerId, this.parentId, this.consentFormType);
  }

  docUpload(documents) {
    if (this.docUploadFunc) {
      this.docUploadFunc(this.parentId, documents).subscribe(
        (r) => {
          if (!this.supplementaryDocuments) {
            this.supplementaryDocumentsChange.emit([]);
          }

          for (const doc of r) {
            this.supplementaryDocuments.push(doc);
          }

          this.supplementaryDocumentsChange.emit(this.supplementaryDocuments.slice());
        },
        (err) => {
          this.notifications.alert('Error: Document upload failed. ' + err.error);
        }
      );
    }

    this.docUploaded.emit(documents);
  }

  docDelete(document) {
    if (this.docDeleteFunc) {
      this.docDeleteFunc(this.parentId, document).subscribe(
        (_) => this.supplementaryDocumentsChange.emit(this.supplementaryDocuments.filter((x) => x.id !== document)),
        (err) => this.notifications.alert('Error: Document delete failed. ' + (err.error || ''))
      );
    }

    this.docDeleted.emit(document);
  }

  async onAddSignatories(signatories: SignedFormSignatory[]) {
    if (this.signatoriesDataSource.data) {
      const found = signatories.find((x) =>
        this.signatoriesDataSource.data.find(
          (y) => y.firstName.toLowerCase() === x.firstName.toLowerCase() && x.lastName.toLowerCase() === y.lastName.toLowerCase()
        )
      );
      if (found) {
        this.notifications.alert(`${found.firstName} has already been added.`);
        return;
      }
    }

    if (!this.signedFormId) {
      try {
        const signedFormId = await this.signedFormService.create(this.parentId, this.consentFormType, signatories).toPromise();
        this.signedForm = await this.signedFormService.getForm(signedFormId).toPromise();
        this.signedFormIdChange.emit(this.signedForm.id);
      } catch (ex) {
        this.notifications.alert('Only the case manager can create signable forms.');
      }
    } else {
      for (const sig of signatories) {
        sig.signedFormId = this.signedFormId;
        this.signedFormService.addSignatory(sig).subscribe(
          (r) => {
            this.signedForm.signatories.push(r);
            this.setDataSource();
          },
          (err) => {
            this.notifications.alert('Error: ' + err.error);
          }
        );
      }
    }

    this.setDataSource();
  }

  setDataSource() {
    if (!this.signedForm?.signatories) {
      return;
    }

    this.signatoriesDataSource.data = this.signedForm.signatories;
    if (this.consentFormType === 'HomeschoolingSpecialEd' && !this.displayedColumns.includes('publicAgencyRep'))
      this.displayedColumns.push('publicAgencyRep');
  }

  async resendEmail(signatory: SignedFormSignatory) {
    if (await this.notifications.confirmationPromise(`Resend the signature email to ${signatory.firstName}?`)) {
      this.signedFormService.resendSignatureEmail(signatory.id).subscribe(
        (_) => {
          this.notifications.alert('The email has been sent.');
        },
        (e) => this.notifications.alert(e.error)
      );
    }
  }

  async revokeSignatory(signatory: SignedFormSignatory) {
    if (await this.notifications.confirmationPromise(`Revoke the signature email for ${signatory.firstName}?`)) {
      this.signedFormService.revokeSignatureInvitation(signatory.id).subscribe(
        (_) => {
          this.signedForm.signatories = this.signedForm.signatories.filter((x) => x.id !== signatory.id);
          this.setDataSource();
        },
        (e) => this.notifications.alert(e.error)
      );
    }
  }

  canResend(signatory: SignedFormSignatory) {
    return (
      (!signatory.signedFormValues || signatory.signedFormValues.length === 0) &&
      this.signedForm.signedFormStatus === SignedFormStatus.Active
    );
  }

  canRevoke(signatory: SignedFormSignatory) {
    return (
      (!signatory.signedFormValues || signatory.signedFormValues.length === 0) &&
      this.signedForm.signedFormStatus === SignedFormStatus.Active &&
      !signatory.userId
    );
  }

  viewDoc(docId: string) {
    if (this.viewFinalDocFunc && docId === this.finalizedDocument?.id) {
      this.viewFinalDocFunc(this.parentId);
    } else {
      if (this.docGetFunc) {
        this.docGetFunc(this.parentId, docId).subscribe(
          (r) => {
            window.open(r);
          },
          (err) => this.notifications.alert('Error: Unable to view this document.')
        );
      } else {
        this.notifications.alert('Error: Unable to view document. No GET handler registered.');
      }
    }
  }

  printForLiveSignature() {
    if (this.viewFinalDocFunc) {
      this.viewFinalDocFunc(this.parentId);
    }
  }

  inPersonSign(sig: SignedFormSignatory) {
    const signatureWindow = window.open(`https://${window.location.host}/signedforms/${encodeURIComponent(sig.token)}`);
    signatureWindow.addEventListener('message', async (event) => {
      if (event.data === 'submitted') {
        this.ngZone.run(async () => {
          await this.loadForm();
          if (this.isMultipleSignatory) {
            if (!this.signedForm.signatories.some((s) => !s.signedFormValues?.length)) {
              this.signatureCompleted.emit(this.signedForm.id);
            }
          } else {
            if (this.signedForm.signatories.filter((s) => !!s.signedFormValues?.length)) {
              this.signatureCompleted.emit(this.signedForm.id);
            }
          }
        });
      }
    });
  }

  async viewSignedForm() {
    const pdf = await this.signedFormService.getSignedFormPdf(this.signedFormId);
    if (!pdf?.url) {
      this.notifications.alert('Error opening consent form');
      return;
    }

    window.open(pdf.url);
  }

  async viewSignatory(sig: SignedFormSignatory) {
    const pdf = await this.signedFormService.getSignedFormPdf(this.signedFormId, sig.token);
    if (!pdf?.url) {
      this.notifications.alert('Error opening consent form');
      return;
    }

    window.open(pdf.url);
  }

  isFormActive() {
    return !this.finalizedDocument;
  }

  isSignedByAllParties() {
    return !!this.finalizedDocument;
  }

  hasElectronicForm() {
    return !!this.signedFormId;
  }

  canSignatorySign(sig) {
    return (
      (!this.authService.isPortalUser || (this.authService.isPortalUserEdit && this.authService.user.id === sig.userId)) &&
      this.consentStatus !== this.consentStatuses.Inactive &&
      this.consentStatus !== this.consentStatuses.Approved &&
      this.consentStatus !== this.consentStatuses.Declined &&
      (!sig.signedFormValues || sig.signedFormValues.length === 0)
    );
  }
}
