<div class="case-list" *ngIf="isSectionVisible">
  <div class="case-list__header">
    <h3 class="mb-0">My Case List</h3>
  </div>
  <mat-card class="p-0">
    <mat-accordion class="accordion accordion--close accordion--no-border accordion--short accordion--in-card" multi>
      <mat-expansion-panel class="mat-elevation-z0 no-body-padding" expanded *ngIf="canViewDhhCaseList">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="display-flex justify-content-between align-items-center w-100 flex-wrap">
              <header>
                <h3 class="my-0 text-normal">DHH Birth to 3</h3>
              </header>
              <form [formGroup]="dhhU3FormGroup" class="display-flex align-items-center justify-content-between flex-wrap">
                <div class="mx-2 flex-1">
                  <app-text-field
                    label="Search Name"
                    aria-label="Search Name"
                    (click)="$event.stopPropagation()"
                    formControlName="nameFilter"
                  ></app-text-field>
                </div>
                <div class="mx-2 flex-1">
                  <app-autocomplete
                    (click)="$event.stopPropagation()"
                    [options]="dhhU3DistrictOptions"
                    formControlName="districtFilter"
                    label="Filter by District"
                    aria-label="Filter by District"
                  ></app-autocomplete>
                </div>
                <div class="mx-2">
                  <app-clear-form-button [formGroup]="dhhU3FormGroup"></app-clear-form-button>
                </div>
              </form>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="table-overflow app-table" #dhhU3Table>
          <table
            mat-table
            [dataSource]="dhhU3DataSource"
            #dhhU3Sort="matSort"
            matSort
            matSortActive="fullName"
            matSortDirection="asc"
            class="w-100 case-list__table"
          >
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="text-right"></th>
              <td mat-cell *matCellDef="let caseListItem" class="action--position-fix">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  color="primary"
                  tabindex="0"
                  aria-label="Quick Access Menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                  <button mat-menu-item
                          aria-label="Reassign Audiologist"
                          (click)="dhhReassignAudiologist(caseListItem.learnerId)"
                          tabindex="0">
                    Reassign Audiologist
                  </button>
                  <button *ngIf="canScheduleDhhAppointments"
                          mat-menu-item
                          aria-label="Schedule Appointment"
                          tabindex="0"
                          (click)="scheduleDhhAppointment(caseListItem.learnerId)">
                    Schedule Appointment
                  </button>
                  <button mat-menu-item
                          aria-label="Create Release / Exchange of Information"
                          tabindex="0"
                          *ngIf="canAddConsent"
                          (click)="addConsent(caseListItem.learnerId, ConsentFormType.ReleaseAndExchangeInformation)">
                    Create Release / Exchange of Information
                  </button>
                </mat-menu>
              </td>
            </ng-container>

            <ng-container matColumnDef="fullName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="" class="cell--learner-name">Learner Name</th>
              <td mat-cell *matCellDef="let caseListItem" class="cell--learner-name">
                <a [routerLink]="learnerHearingDashboardNavLink(caseListItem)" tabindex="0" role="link" aria-labelledby="Case List Item">
                  {{ caseListItem.fullName }}
                </a>
                <mat-icon style="color: #007a7c" *ngIf="caseListItem.isDhh">
                  radio_button_on
                </mat-icon>
                <mat-icon style="color: #9b2242" *ngIf="caseListItem.isDoNotTest">
                  error
                </mat-icon>
                <mat-icon style="color: #24327b" *ngIf="caseListItem.isDoNotScreen">
                  indeterminate_check_box
                </mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="dateOfBirth">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">DOB</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.dateOfBirth | date : shortDateFormat }}
              </td>
            </ng-container>

            <ng-container matColumnDef="attendingDistrict">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">District</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.attendingDistrict }}
              </td>
            </ng-container>

            <ng-container matColumnDef="activeCases">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">Active Case</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.activeCases }}
              </td>
            </ng-container>

            <ng-container matColumnDef="hasAssistiveTech">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">Assistive Tech</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.hasAssistiveTech | boolToYesNo }}
              </td>
            </ng-container>

            <ng-container matColumnDef="noResults">
              <td mat-footer-cell *matFooterCellDef colspan="6">
                <i>No results...</i>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="dhhU3DisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: dhhU3DisplayedColumns"></tr>
            <tr
              [hidden]="dhhU3DataSource?.data?.length > 0"
              mat-footer-row
              *matFooterRowDef="dhhU3DataSource?.data?.length === 0 ? ['noResults'] : []"
              class="example-second-footer-row"
            ></tr>
          </table>
          <app-table-paginator
            *ngIf="dhhU3CurrentPageInfo.length > 0"
            #partCPaginator
            [length]="dhhU3CurrentPageInfo.length"
            [pageSize]="dhhU3CurrentPageInfo.pageSize"
            [pageIndex]="dhhU3CurrentPageInfo.pageIndex"
            (page)="dhhU3PageChanged($event)"
          ></app-table-paginator>
          <p *ngIf="!dhhU3DataSource.data">No data yet...</p>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0 no-body-padding" expanded *ngIf="canViewDhhCaseList">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="display-flex justify-content-between align-items-center w-100 flex-wrap">
              <header>
                <h3 class="my-0 text-normal">DHH PK to 21</h3>
              </header>
              <form [formGroup]="dhhFormGroup">
                <div class="display-flex align-items-center justify-content-between flex-wrap">
                  <div class="mx-2 flex-1">
                    <app-text-field
                      label="Search Name"
                      aria-label="Search Name"
                      (click)="$event.stopPropagation()"
                      formControlName="nameFilter"
                    ></app-text-field>
                  </div>
                  <div class="mx-2 flex-1">
                    <app-autocomplete
                      (click)="$event.stopPropagation()"
                      [options]="dhhDistrictOptions"
                      formControlName="districtFilter"
                      label="Filter by District"
                      aria-label="Filter by District"
                    ></app-autocomplete>
                  </div>
                  <div class="mx-2 flex-1">
                    <app-autocomplete
                      (click)="$event.stopPropagation()"
                      [options]="dhhBuildingOptions"
                      formControlName="buildingFilter"
                      label="Filter by Building"
                      aria-label="Filter by Building"
                    ></app-autocomplete>
                  </div>
                  <div class="mx-2">
                    <app-clear-form-button [formGroup]="dhhFormGroup"></app-clear-form-button>
                  </div>
                </div>
              </form>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="table-overflow app-table" #dhhTable>
          <table
            mat-table
            [dataSource]="dhhDataSource"
            #dhhSort="matSort"
            matSort
            matSortActive="fullName"
            matSortDirection="asc"
            class="w-100 case-list__table"
          >
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="text-right"></th>
              <td mat-cell *matCellDef="let caseListItem" class="action--position-fix">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  color="primary"
                  tabindex="0"
                  aria-label="Quick Access Menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                  <button
                    mat-menu-item
                    aria-label="Reassign Audiologist"
                    (click)="dhhReassignAudiologist(caseListItem.learnerId)"
                    tabindex="0"
                  >
                    Reassign Audiologist
                  </button>
                  <button *ngIf="canScheduleDhhAppointments"
                    mat-menu-item
                    aria-label="Schedule Appointment"
                    tabindex="0"
                    (click)="scheduleDhhAppointment(caseListItem.learnerId)"
                  >
                    Schedule Appointment
                  </button>
                  <button
                    mat-menu-item
                    aria-label="Create Release / Exchange of Information"
                    tabindex="0"
                    *ngIf="canAddConsent"
                    (click)="addConsent(caseListItem.learnerId, ConsentFormType.AuthorizationReleaseAndExchangeInformation)"
                  >
                    Create Release / Exchange of Information
                  </button>
                  <button
                    mat-menu-item
                    aria-label="Create Consent for IVRS"
                    tabindex="0"
                    *ngIf="isThirteenOrAbove(caseListItem.dateOfBirth) && canAddConsent"
                    (click)="addConsent(caseListItem.learnerId, ConsentFormType.IvrsConsent)"
                  >
                    Create Consent for IVRS
                  </button>
                </mat-menu>
              </td>
            </ng-container>

            <ng-container matColumnDef="fullName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="" class="cell--learner-name">Learner Name</th>
              <td mat-cell *matCellDef="let caseListItem" class="cell--learner-name">
                <a [routerLink]="learnerHearingDashboardNavLink(caseListItem)" tabindex="0" role="link" aria-labelledby="Case List Item">
                  {{ caseListItem.fullName }}
                </a>
                <mat-icon style="color: #007a7c" *ngIf="caseListItem.isDhh">
                  radio_button_on
                </mat-icon>
                <mat-icon style="color: #9b2242" *ngIf="caseListItem.isDoNotTest">
                  error
                </mat-icon>
                <mat-icon style="color: #24327b" *ngIf="caseListItem.isDoNotScreen">
                  indeterminate_check_box
                </mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="dateOfBirth">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">DOB</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.dateOfBirth | date : shortDateFormat }}
              </td>
            </ng-container>

            <ng-container matColumnDef="attendingDistrict">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">District</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.attendingDistrict }}
              </td>
            </ng-container>

            <ng-container matColumnDef="building">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">Building</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.building }}
              </td>
            </ng-container>

            <ng-container matColumnDef="activeCases">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">Active Case</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.activeCases }}
              </td>
            </ng-container>

            <ng-container matColumnDef="hasAssistiveTech">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">Assistive Tech</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.hasAssistiveTech | boolToYesNo }}
              </td>
            </ng-container>

            <ng-container matColumnDef="noResults">
              <td mat-footer-cell *matFooterCellDef colspan="6">
                <i>No results...</i>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="dhhDisplayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: dhhDisplayedColumns"></tr>
            <tr
              [hidden]="dhhDataSource?.data?.length > 0"
              mat-footer-row
              *matFooterRowDef="dhhDataSource?.data?.length === 0 ? ['noResults'] : []"
              class="example-second-footer-row"
            ></tr>
          </table>
          <app-table-paginator
            *ngIf="dhhCurrentPageInfo.length > 0"
            #partCPaginator
            [length]="dhhCurrentPageInfo.length"
            [pageSize]="dhhCurrentPageInfo.pageSize"
            [pageIndex]="dhhCurrentPageInfo.pageIndex"
            (page)="dhhPageChanged($event)"
          ></app-table-paginator>
          <p *ngIf="!dhhDataSource.data">No data yet...</p>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel class="mat-elevation-z0 no-body-padding" expanded *ngIf="showPartCCaseTable">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="display-flex justify-content-between align-items-center w-100 flex-wrap">
              <header>
                <h3 data-testid="early-access-header" class="my-0 text-normal">Early ACCESS</h3>
              </header>
              <form [formGroup]="partCFormGroup" class="display-flex align-items-center justify-content-between flex-wrap">
                <div class="mx-2 flex-1">
                  <app-text-field
                    label="Search"
                    aria-label="Search"
                    (click)="$event.stopPropagation()"
                    formControlName="nameFilter"
                  ></app-text-field>
                </div>
                <div class="mx-2 flex-1">
                  <app-autocomplete
                    (click)="$event.stopPropagation()"
                    [options]="partCDistrictOptions"
                    formControlName="districtFilter"
                    label="Filter by District"
                    aria-label="Filter by District"
                  ></app-autocomplete>
                </div>
                <div class="mx-2">
                  <app-clear-form-button [formGroup]="partCFormGroup"></app-clear-form-button>
                </div>
              </form>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="table-overflow app-table" #partCTable>
          <table
            mat-table
            [dataSource]="partCDataSource"
            #partCSort="matSort"
            matSort
            matSortActive="learnerName"
            matSortDirection="asc"
            class="w-100 case-list__table"
            data-testid="early-access-table"
          >
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="text-right"></th>
              <td mat-cell *matCellDef="let caseListItem" data-testid="early-access-table-row-menu" class="action--position-fix">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  color="primary"
                  *ngIf="!caseListItem.exitFinalized && canViewMenu(caseListItem)"
                  tabindex="0"
                  aria-label="Quick Access Menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                  <a
                    *ngIf="
                      !!caseListItem.disabilitySuspectFormId &&
                      !caseListItem.disabilitySuspectFormSubmitted &&
                      authService.isAllowed(permissions.EditDsForm)
                    "
                    mat-menu-item
                    [routerLink]="routingService.disabilitySuspectFormPath(caseListItem.disabilitySuspectFormId)"
                    tabindex="0"
                    >Disability Suspected</a
                  >
                  <a
                    *ngIf="
                      caseListItem.fiieConsentFormId &&
                      !caseListItem.fiieConsentIsApproved &&
                      authService.isAllowedByCaseId(
                        caseListItem.id,
                        requestForFiieConsentAdditionalRequirements,
                        permissions.RequestForFiieConsent
                      )
                    "
                    mat-menu-item
                    [routerLink]="routingService.fiieConsentPath(caseListItem.learnerId, caseListItem.id)"
                    tabindex="0"
                    >Consent for and Notice of Full and Individual Initial Evaluation</a
                  >
                  <!-- <a
                    *ngIf="canEmailTheFamily(caseListItem)"
                    [href]="'mailto:'.concat(caseListItem.familyMemberEmails.join(','))"
                    target="_blank"
                    mat-menu-item
                    tabindex="0"
                  >
                    Email Family
                  </a> -->
                  <button
                    mat-menu-item
                    aria-label="Transfer Case"
                    *ngIf="canTransfer(caseListItem)"
                    (click)="onTransferCase(caseListItem)"
                    tabindex="0"
                  >
                    Transfer Case
                  </button>
                  <button
                    mat-menu-item
                    aria-label="Share Case"
                    *ngIf="canShare(caseListItem)"
                    (click)="onShareCase(caseListItem)"
                    tabindex="0"
                  >
                    Share Case
                  </button>
                  <button
                    *ngIf="canScheduleMeeting(caseListItem)"
                    aria-label="Open Scheduler"
                    mat-menu-item
                    (click)="onScheduleMeeting(caseListItem)"
                    tabindex="0"
                  >
                    Open Scheduler
                  </button>
                  <button
                    mat-menu-item
                    aria-label="Create PWN"
                    (click)="openCreatePWN(caseListItem.id)"
                    *ngIf="!caseListItem.activePwn && canCreateEditPwn(caseListItem)"
                    tabindex="0"
                  >
                    Create PWN
                  </button>
                  <button
                    mat-menu-item
                    aria-label="Edit PWN"
                    (click)="openEditPWN(caseListItem)"
                    *ngIf="caseListItem.activePwn && canCreateEditPwn(caseListItem)"
                    tabindex="0"
                  >
                    Edit PWN
                  </button>
                  <button
                    mat-menu-item
                    aria-label="Enter/View Progress"
                    *ngIf="canEditProgressMonitoring(caseListItem) && (caseListItem.activeIfsp || caseListItem.activeIep)"
                    (click)="enterProgress(caseListItem.id)"
                    tabindex="0"
                  >
                    Enter/View Progress
                  </button>
                  <a
                    mat-menu-item
                    *ngIf="caseListItem.isEarlyAccess"
                    [routerLink]="routingService.intakePath(caseListItem.id)"
                    tabindex="0"
                  >
                    {{ caseListItem.activeIfsp ? 'Update' : 'Complete' }} Intake
                  </a>
                  <a
                    mat-menu-item
                    *ngIf="showEvaluationPartC(caseListItem)"
                    [routerLink]="routingService.evalOverview(caseListItem.id, caseListItem.activeEvaluationId)"
                    tabindex="0"
                  >
                    Evaluation
                  </a>
                  <a
                    mat-menu-item
                    *ngIf="caseListItem.isEligible && caseListItem.draftIEPId && !caseListItem.isEarlyAccess && canEditIep(caseListItem)"
                    [routerLink]="['/cases', caseListItem.id, 'iep', caseListItem.draftIEPId, 'profile']"
                    tabindex="0"
                  >
                    Edit IEP
                  </a>
                  <a
                    mat-menu-item
                    *ngIf="caseListItem.isEligible && caseListItem.draftIFSPId && caseListItem.isEarlyAccess && canEditIfsp(caseListItem)"
                    [routerLink]="['/cases', caseListItem.id, 'ifsp', caseListItem.draftIFSPId, 'profile']"
                    tabindex="0"
                  >
                    Edit IFSP
                  </a>
                  <ng-container *appAuthForRoles="{ permission: [permissions.CreateIFSP], caseId: caseListItem.id }">
                    <a
                      mat-menu-item
                      *ngIf="caseListItem.canBeginPeriodicReview"
                      href="javascript:void(0)"
                      (click)="beginPeriodicReview(caseListItem)"
                    >
                      Start Periodic Review
                    </a>
                  </ng-container>
                  <ng-container *appAuthForRoles="{ permission: [permissions.CreateIFSP], caseId: caseListItem.id }">
                    <a
                      mat-menu-item
                      *ngIf="caseListItem.canBeginAnnualReview"
                      href="javascript:void(0)"
                      (click)="beginAnnualReview(caseListItem)"
                    >
                      Start Annual Review
                    </a>
                  </ng-container>
                  <ng-container *appAuthForRoles="{ permission: [permissions.CreateIFSP], caseId: caseListItem.id }">
                    <a
                      mat-menu-item
                      *ngIf="caseListItem.canSendPeriodicReviewPoll"
                      href="javascript:void(0)"
                      (click)="sendPeriodicReviewPoll(caseListItem)"
                    >
                      Send Periodic Review Poll
                    </a>
                  </ng-container>
                  <ng-container *appAuthForRoles="{ permission: [permissions.CreateIFSP], caseId: caseListItem.id }">
                    <a
                      mat-menu-item
                      *ngIf="caseListItem.canSendAnnualReviewPoll"
                      href="javascript:void(0)"
                      (click)="sendAnnualReviewPoll(caseListItem)"
                    >
                      Send Annual Review Poll
                    </a>
                  </ng-container>
                </mat-menu>
              </td>
            </ng-container>

            <ng-container matColumnDef="agency">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">Agency</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.attendingAeaName }}
              </td>
            </ng-container>

            <ng-container matColumnDef="learnerName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="" class="cell--learner-name">Learner Name</th>
              <td mat-cell *matCellDef="let caseListItem" class="cell--learner-name">
                <a [routerLink]="caseListNavLink(caseListItem)" tabindex="0" role="link" aria-labelledby="Case List Item">
                  {{ caseListItem.learnerLastName }},
                  {{ caseListItem.learnerFirstName }}

                  <mat-icon
                    *ngIf="caseListItem.caseOwnerId.toLowerCase() === userId"
                    class="text-primary"
                    aria-label="Case Manager Icon"
                    aria-hidden="false"
                  >
                    account_circle
                  </mat-icon>
                </a>
              </td>
            </ng-container>

            <ng-container matColumnDef="referralDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">Referral</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.referralDate | date : shortDateFormat }}
              </td>
            </ng-container>

            <ng-container matColumnDef="initialDueDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">Initial Due</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.initialDueDate | date : shortDateFormat }}
              </td>
            </ng-container>

            <ng-container matColumnDef="periodicDueDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">Periodic Due</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.periodicDueDate | date : shortDateFormat }}
              </td>
            </ng-container>

            <ng-container matColumnDef="annualDueDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">Annual Due</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.annualDueDate | date : shortDateFormat }}
              </td>
            </ng-container>

            <ng-container matColumnDef="learnerThirdBirthday">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">3rd Birthday</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.learnerThirdBirthday | date : shortDateFormat }}
              </td>
            </ng-container>

            <ng-container matColumnDef="beginTransitionDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">Begin Transition</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.beginTransitionDate | date : shortDateFormat }}
              </td>
            </ng-container>

            <ng-container matColumnDef="transitionMtgDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">Transition Mtg</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.transitionMtgDate | date : shortDateFormat }}
              </td>
            </ng-container>

            <ng-container matColumnDef="noResults">
              <td mat-footer-cell *matFooterCellDef colspan="7">
                <i>No results...</i>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsPartC"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsPartC" [ngClass]="{ newLearner: row.newLearner }"></tr>
            <tr
              [hidden]="partCDataSource?.data?.length > 0"
              mat-footer-row
              *matFooterRowDef="partCDataSource?.data?.length === 0 ? ['noResults'] : []"
              class="example-second-footer-row"
            ></tr>
          </table>

          <app-table-paginator
            *ngIf="partCCurrentPageInfo.length > 0"
            #partCPaginator
            [length]="partCCurrentPageInfo.length"
            [pageSize]="partCCurrentPageInfo.pageSize"
            [pageIndex]="partCCurrentPageInfo.pageIndex"
            (page)="partCPageChanged($event)"
          ></app-table-paginator>
          <p *ngIf="!partCDataSource.data">No data yet...</p>
        </div>
      </mat-expansion-panel>

      <mat-expansion-panel expanded class="mat-elevation-z0 mb-0 no-body-padding" *ngIf="showPartBCaseTable">
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="display-flex justify-content-between align-items-center w-100 flex-wrap">
              <header>
                <h3 data-testid="special-education-header" class="my-0 text-normal">Special Education</h3>
              </header>
              <form [formGroup]="partBFormGroup">
                <div class="display-flex align-items-center justify-content-between flex-wrap">
                  <div class="mx-2 flex-1">
                    <app-text-field
                      label="Search"
                      aria-label="Search"
                      (click)="$event.stopPropagation()"
                      formControlName="nameFilter"
                    ></app-text-field>
                  </div>
                  <div class="mx-2 flex-1">
                    <app-autocomplete
                      (click)="$event.stopPropagation()"
                      [options]="partBDistrictOptions"
                      formControlName="districtFilter"
                      label="Filter by District"
                      aria-label="Filter by District"
                    ></app-autocomplete>
                  </div>
                  <div class="mx-2 flex-1">
                    <app-autocomplete
                      (click)="$event.stopPropagation()"
                      [options]="partBBuildingOptions"
                      formControlName="buildingFilter"
                      label="Filter by Building"
                      aria-label="Filter by Building"
                    ></app-autocomplete>
                  </div>
                  <div class="mx-2">
                    <app-clear-form-button [formGroup]="partBFormGroup"></app-clear-form-button>
                  </div>
                </div>
              </form>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>

        <div class="table-overflow app-table" #partBTable>
          <table
            data-testid="special-education-table"
            mat-table
            [dataSource]="partBDataSource"
            #partBSort="matSort"
            matSort
            matSortActive="learnerName"
            matSortDirection="asc"
            class="w-100 case-list__table"
          >
            <ng-container matColumnDef="action">
              <th mat-header-cell *matHeaderCellDef class="text-right"></th>
              <td mat-cell *matCellDef="let caseListItem" data-testid="special-education-table-row-menu" class="action--position-fix">
                <button
                  mat-icon-button
                  [matMenuTriggerFor]="menu"
                  color="primary"
                  *ngIf="!caseListItem.exitFinalized && canViewMenu(caseListItem)"
                  tabindex="0"
                  aria-label="Quick Access Menu"
                >
                  <mat-icon>more_vert</mat-icon>
                </button>

                <mat-menu #menu="matMenu">
                  <a
                    *ngIf="
                      !!caseListItem.disabilitySuspectFormId &&
                      !caseListItem.disabilitySuspectFormSubmitted &&
                      authService.isAllowed(permissions.EditDsForm)
                    "
                    mat-menu-item
                    [routerLink]="['child-find', 'disability-suspect', caseListItem.disabilitySuspectFormId]"
                    tabindex="0"
                    >Disability Suspected</a
                  >
                  <a
                    *ngIf="
                      caseListItem.fiieConsentFormId &&
                      !caseListItem.fiieConsentIsApproved &&
                      authService.isAllowedByCaseId(
                        caseListItem.id,
                        requestForFiieConsentAdditionalRequirements,
                        permissions.RequestForFiieConsent
                      )
                    "
                    mat-menu-item
                    [routerLink]="routingService.fiieConsentPath(this.caseListItem.learnerId, caseListItem.id)"
                    tabindex="0"
                  >
                    Consent for and Notice of Full and Individual Initial Evaluation
                  </a>
                  <!-- <a
                    *ngIf="canEmailTheFamily(caseListItem)"
                    [href]="'mailto:'.concat(caseListItem.familyMemberEmails.join(','))"
                    target="_blank"
                    mat-menu-item
                  >
                    Email Family
                  </a> -->
                  <button
                    mat-menu-item
                    aria-label="Transfer Case"
                    *ngIf="canTransfer(caseListItem)"
                    (click)="onTransferCase(caseListItem)"
                    tabindex="0"
                  >
                    Transfer Case
                  </button>
                  <button
                    mat-menu-item
                    aria-label="Share Case"
                    *ngIf="canShare(caseListItem)"
                    (click)="onShareCase(caseListItem)"
                    tabindex="0"
                  >
                    Share Case
                  </button>
                  <button
                    *ngIf="canScheduleMeeting(caseListItem)"
                    aria-label="Open Scheduler"
                    mat-menu-item
                    (click)="onScheduleMeeting(caseListItem)"
                    tabindex="0"
                  >
                    Open Scheduler
                  </button>
                  <button
                    mat-menu-item
                    (click)="openCreatePWN(caseListItem.id)"
                    *ngIf="!caseListItem.activePwn && canCreateEditPwn(caseListItem)"
                    tabindex="0"
                    aria-label="Create PWN"
                  >
                    Create PWN
                  </button>
                  <button
                    mat-menu-item
                    aria-label="Edit PWN"
                    (click)="openEditPWN(caseListItem)"
                    *ngIf="caseListItem.activePwn && canCreateEditPwn(caseListItem)"
                    tabindex="0"
                  >
                    Edit PWN
                  </button>
                  <a
                    mat-menu-item
                    *ngIf="showBeginEvaluationPartB(caseListItem)"
                    [routerLink]="['/learner-management', caseListItem.learnerId, 'evaluation']"
                    tabindex="0"
                  >
                    Begin Evaluation
                  </a>
                  <a
                    mat-menu-item
                    *ngIf="showEditEvaluationPartB(caseListItem)"
                    [routerLink]="['/cases', caseListItem.id, 'evaluation', caseListItem.activeEvaluationId, 'overview']"
                    tabindex="0"
                  >
                    Edit Evaluation
                  </a>
                  <a
                    mat-menu-item
                    *ngIf="showBeginReevaluation(caseListItem)"
                    [routerLink]="['/learner-management', caseListItem.learnerId, 'evaluation']"
                    tabindex="0"
                  >
                    Begin Reevaluation
                  </a>
                  <a
                    mat-menu-item
                    *ngIf="showEditReevaluation(caseListItem)"
                    [routerLink]="['/cases', caseListItem.id, 'reevaluation', caseListItem.activeReevaluationId, 'overview']"
                    tabindex="0"
                  >
                    Edit Reevaluation
                  </a>
                  <a
                    mat-menu-item
                    *ngIf="canEditIep(caseListItem)"
                    [routerLink]="['/cases', caseListItem.id, 'iep', caseListItem.draftIEPId, 'profile']"
                    tabindex="0"
                  >
                    Edit IEP
                  </a>
                  <a
                    mat-menu-item
                    *ngIf="canAmendIep(caseListItem)"
                    [routerLink]="['/learner-management', caseListItem.learnerId, 'iep']"
                    tabindex="0"
                  >
                    Amend IEP
                  </a>
                  <button
                    mat-menu-item
                    *ngIf="canEditProgressMonitoring(caseListItem) && (caseListItem.activeIfsp || caseListItem.activeIep)"
                    (click)="enterProgress(caseListItem.id)"
                    aria-label="Enter/View Progress"
                    tabindex="0"
                  >
                    Enter/View Progress
                  </button>
                  <a
                    mat-menu-item
                    *ngIf="caseListItem.isEligible && caseListItem.draftIFSPId && caseListItem.isEarlyAccess && canEditIfsp(caseListItem)"
                    [routerLink]="['/cases', caseListItem.id, 'ifsp', caseListItem.draftIFSPId, 'profile']"
                    tabindex="0"
                  >
                    Edit IFSP
                  </a>
                  <a
                    mat-menu-item
                    *ngIf="caseListItem.showESY"
                    [routerLink]="['/cases', caseListItem.id, 'iep', caseListItem.draftIEPId, 'esy', 'overview']"
                    tabindex="0"
                  >
                    ESY Plan
                  </a>
                </mat-menu>
              </td>
            </ng-container>

            <ng-container matColumnDef="learnerName">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="" class="cell--learner-name">Learner Name</th>
              <td mat-cell *matCellDef="let caseListItem" class="cell--learner-name">
                <a [routerLink]="caseListNavLink(caseListItem)" tabindex="0" role="link" aria-labelledby="Case List Item">
                  {{ caseListItem.learnerLastName }},
                  {{ caseListItem.learnerFirstName }}
                </a>

                <mat-icon *ngIf="caseListItem.caseOwnerId.toLowerCase() === userId" class="text-primary" aria-label="Case List Item">
                  account_circle
                </mat-icon>
              </td>
            </ng-container>

            <ng-container matColumnDef="learnerDateOfBirth">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">DOB</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.learnerDateOfBirth | date : shortDateFormat }}
              </td>
            </ng-container>

            <ng-container matColumnDef="annualDueDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">Annual Due</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.annualDueDate | date : shortDateFormat }}
              </td>
            </ng-container>

            <ng-container matColumnDef="reevaluationDueDate">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">Reeval Due</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.reevaluationDueDate | date : shortDateFormat }}
              </td>
            </ng-container>

            <!-- TODO After PR3 this will need to be changed to either 'Initial', 'Interim', or 'Annual' -->
            <ng-container matColumnDef="iepType">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">IEP Type</th>
              <td mat-cell *matCellDef="let caseListItem">Initial</td>
            </ng-container>

            <ng-container matColumnDef="iepStatus">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="">IEP Status</th>
              <td mat-cell *matCellDef="let caseListItem">
                {{ caseListItem.iepStatus }}
              </td>
            </ng-container>

            <ng-container matColumnDef="noResults">
              <td mat-footer-cell *matFooterCellDef colspan="7">
                <i>No results...</i>
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumnsPartB"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumnsPartB" [ngClass]="{ newLearner: row.newLearner }"></tr>
            <tr
              [hidden]="partBDataSource?.data?.length > 0"
              mat-footer-row
              *matFooterRowDef="partBDataSource?.data?.length === 0 ? ['noResults'] : []"
              class="example-second-footer-row"
            ></tr>
          </table>
          <app-table-paginator
            *ngIf="partBCurrentPageInfo.length > 0"
            [length]="partBCurrentPageInfo.length"
            [pageSize]="partBCurrentPageInfo.pageSize"
            [pageIndex]="partBCurrentPageInfo.pageIndex"
            (page)="partBPageChanged($event)"
          ></app-table-paginator>
          <p *ngIf="!partBDataSource.data">No data yet...</p>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-card>
</div>
