<h3 class="text-semibold mt-0 mb-2" id="radio_head_01">Consideration of Special Factors</h3>

<form [formGroup]="formGroup">
  <mat-card class="mb-0 card--shadow">
    <app-help-directional-text>
      <p>
        Click on each special factor box and review the associated questions. If a special factor will be addressed in the IEP, click the
        checkbox to indicate its inclusion. If no factors were selected for the IEP, check the box next to the statement, "All special
        factors were considered but none have been selected for this IEP."
      </p>
    </app-help-directional-text>

    <div class="special-factors" role="radiogroup" aria-labelledby="radio_head_01">
      <div
        class="special-factors__icon"
        (click)="onSelectSpecialFactor(0)"
        [ngClass]="{
          filled: formGroup.controls.accessibleMaterialsAddressed.value === true,
          active: selectedSpecialFactor === 0
        }"
        role="button"
        tabindex="0"
        aria-labelledby="Accessible Educational Materials (AEM)"
      >
        <p role="presentation">Accessible Educational Materials (AEM)</p>
      </div>

      <div
        class="special-factors__icon"
        (click)="onSelectSpecialFactor(1)"
        [ngClass]="{
          filled: formGroup.controls.assistiveTechnologyAddressed.value === true,
          active: selectedSpecialFactor === 1
        }"
        role="button"
        tabindex="0"
        aria-labelledby="Assistive Technology"
      >
        <p role="presentation">Assistive Technology</p>
      </div>

      <div
        class="special-factors__icon"
        (click)="onSelectSpecialFactor(3)"
        [ngClass]="{
          filled: formGroup.controls.communicationLanguageAddressed.value === true,
          active: selectedSpecialFactor === 3
        }"
        role="button"
        tabindex="0"
        aria-labelledby="Communication"
      >
        <p role="presentation">Communication</p>
      </div>

      <div
        class="special-factors__icon"
        (click)="onSelectSpecialFactor(4)"
        [ngClass]="{
          filled: formGroup.controls.limitedEnglishAddressed.value === true,
          active: selectedSpecialFactor === 4
        }"
        role="button"
        tabindex="0"
        aria-labelledby="English Learner"
      >
        <p role="presentation">English Learner</p>
      </div>

      <div
        class="special-factors__icon"
        (click)="onSelectSpecialFactor(5)"
        [ngClass]="{
          filled:
            formGroup.controls.healthAddressed.value === true ||
            formGroup.controls.healthPlan.value === true ||
            formGroup.controls.healthPlanCreate.value === true,
          active: selectedSpecialFactor === 5
        }"
        role="button"
        tabindex="0"
        aria-labelledby="Health"
      >
        <p role="presentation">Health</p>
      </div>

      <div
        class="special-factors__icon"
        (click)="onSelectSpecialFactor(6)"
        [ngClass]="{
          filled: formGroup.controls.hearingAddressed.value === true,
          active: selectedSpecialFactor === 6
        }"
        role="button"
        tabindex="0"
        aria-labelledby="Hearing"
      >
        <p role="presentation">Hearing</p>
      </div>

      <div
        class="special-factors__icon"
        (click)="onSelectSpecialFactor(7)"
        [ngClass]="{
          filled: formGroup.controls.behaviorAddressed.value === true,
          active: selectedSpecialFactor === 7
        }"
        role="button"
        tabindex="0"
        aria-labelledby="Social Emotional Behavior"
      >
        <p role="presentation">Social Emotional Behavior</p>
      </div>

      <div
        class="special-factors__icon"
        (click)="onSelectSpecialFactor(2)"
        [ngClass]="{
          filled: formGroup.controls.visionAddressed.value === true,
          active: selectedSpecialFactor === 2
        }"
        role="button"
        tabindex="0"
        aria-labelledby="Vision"
      >
        <p role="presentation">Vision</p>
      </div>
    </div>

    <div>
      <ng-container *ngIf="!isAmendment || (hasOpenAmendment && amendingPlaafp)">
        <app-checkbox-single
          class="wrap"
          formControlName="allConsideredNoneSelected"
          aria-labelledby="Check All special factors were considered but none have been selected for this IEP."
          label="All special factors were considered but none have been selected for this IEP."
        >
        </app-checkbox-single>

        <p *ngIf="isAmendment">
          <app-compare-amendment-output
            [amendmentId]="plaafpModel?.amendmentId"
            [amendmentFinalized]="amendmentIsFinalized"
            [amendmentDate]="lastFinalizedDate"
            [priorVersionId]="plaafpModel?.priorVersionId"
            [changedVersionText]="formGroup.get('allConsideredNoneSelected').value | boolToYesNo"
            [currentVersionText]="plaafpModel?.priorVersion?.allConsideredNoneSelected | boolToYesNo"
            [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
            changedPrefixText="Proposed: "
          >
          </app-compare-amendment-output>
        </p>

        <app-help-directional-text *ngIf="fbaId">
          <p class="mb-2">
            Based on FBA results,
            {{ bipNeeded ? 'a BIP is needed' : 'the team should consider addressing behavior within this IEP' }}.
          </p>
        </app-help-directional-text>

        <ng-container *ngFor="let specialFactorHelper of specialFactorHelpers">
          <app-help-directional-text>
            <p class="mb-2">
              {{ specialFactorHelper.description }}
            </p>
          </app-help-directional-text>
        </ng-container>
      </ng-container>
      <ng-container *ngIf="isAmendment && (!hasOpenAmendment || !amendingPlaafp) && plaafpModel?.priorVersion?.allConsideredNoneSelected">
        All special factors were considered but none have been selected for this IEP.
      </ng-container>

      <hr class="input__divider my-2" />

      <ng-container *ngIf="selectedSpecialFactor === 0">
        <p class="text-semibold" aria-level="1">AEM Considerations:</p>
        <ol>
          <li>Does the learner understand grade-level reading material at a higher level when it is read to them?</li>
          <li>Does the learner’s rate of reading impact comprehension or the time it takes to complete the task?</li>
          <li>
            Does the learner have
            <a
              class="text-underline"
              (click)="openHelpWindow(specialFactorsSections.AEM, 'accommodations')"
              tabindex="0"
              role="link"
              aria-labelledby="Does the learner have accommodations"
            >
              accommodations
            </a>
            listed in the IEP that require an adult reader at specific times?
          </li>
          <li>
            Does the learner have hearing, vision or physical difficulties that prevent them from independently utilizing
            <a
              class="text-underline"
              (click)="openHelpWindow(specialFactorsSections.AEM, 'print')"
              tabindex="0"
              role="link"
              aria-labelledby="Special Factor Section"
            >
              print or digitally-based materials?</a
            >
          </li>
          <li>
            Does the learner require
            <a
              class="text-underline"
              (click)="openHelpWindow(specialFactorsSections.AEM, 'accessible')"
              tabindex="0"
              role="link"
              aria-labelledby=" Does the learner require  accessible materials for working or living?"
            >
              accessible materials</a
            >
            for working or living?
          </li>
        </ol>
        <ng-container *ngIf="isAmendment && (!hasOpenAmendment || !amendingPlaafp)">
          <label>This is a special factor to be addressed in this IEP:</label>
          <p *ngIf="isAmendment">
            <app-compare-amendment-output
              [amendmentId]="plaafpModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="plaafpModel?.priorVersionId"
              [changedVersionText]="formGroup.get('accessibleMaterialsAddressed').value | boolToYesNo"
              [currentVersionText]="plaafpModel?.priorVersion?.accessibleMaterialsAddressed | boolToYesNo"
              [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
        </ng-container>
        <ng-container *ngIf="!isAmendment || (hasOpenAmendment && amendingPlaafp)">
          <div class="display-flex justify-content-between align-items-center flex-wrap">
            <app-checkbox-single
              formControlName="accessibleMaterialsAddressed"
              attr.aria-label="{{
                previousPlaafp && previousPlaafp.accessibleMaterialsAddressed
                  ? 'Accessible Educational Materials was identified as a special factor in the previous IEP and the IEP team has confirmed it’s still a need for the learner.'
                  : 'Accessible Educational Materials is a special factor to be addressed in this IEP.'
              }}"
              [label]="
                previousPlaafp && previousPlaafp.accessibleMaterialsAddressed
                  ? 'Accessible Educational Materials was identified as a special factor in the previous IEP and the IEP team has confirmed it’s still a need for the learner.'
                  : 'Accessible Educational Materials is a special factor to be addressed in this IEP.'
              "
            >
            </app-checkbox-single>
          </div>
          <p *ngIf="isAmendment">
            <app-compare-amendment-output
              [amendmentId]="plaafpModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="plaafpModel?.priorVersionId"
              [changedVersionText]="formGroup.get('accessibleMaterialsAddressed').value | boolToYesNo"
              [currentVersionText]="plaafpModel?.priorVersion?.accessibleMaterialsAddressed | boolToYesNo"
              [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
        </ng-container>

        <!-- <div class="display-flex justify-content-end align-items-center">
          <button
            mat-raised-button
            color="accent"
            aria-label="Create Behavior Intervention Plan"
            tabindex="0"
          >
            Create Behavior Intervention Plan
          </button>
        </div> -->
      </ng-container>

      <ng-container *ngIf="selectedSpecialFactor === 1">
        <p class="text-semibold" aria-level="1">Assistive Technology Considerations:</p>
        <ol>
          <li>
            Is there a specific task that is difficult for this learner? Would assistive technology support the learner with this task?
          </li>
          <li>
            Is there a
            <a class="text-underline" (click)="openHelpWindow(specialFactorsSections.AssistiveTech, 'device')" tabindex="0" role="link"
              >device, software, product or strategy</a
            >
            that may allow the learner to perform this task with greater accuracy, ease or independence?
          </li>
          <li>
            Does the learner require
            <a
              class="text-underline"
              (click)="openHelpWindow(specialFactorsSections.AssistiveTech, 'assistive')"
              tabindex="0"
              role="link"
              aria-labelledby=" Does the learner require assistive technology for working or living?"
            >
              assistive technology</a
            >
            for working or living?
          </li>
        </ol>
        <ng-container *ngIf="isAmendment && !hasOpenAmendment">
          <label>This is a special factor to be addressed in this IEP:</label>
          <p>
            <app-compare-amendment-output
              [amendmentId]="plaafpModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="plaafpModel?.priorVersionId"
              [changedVersionText]="formGroup.get('assistiveTechnologyAddressed').value | boolToYesNo"
              [currentVersionText]="plaafpModel?.priorVersion?.assistiveTechnologyAddressed | boolToYesNo"
              [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
        </ng-container>
        <ng-container *ngIf="!isAmendment || (hasOpenAmendment && amendingPlaafp)">
          <div class="display-flex align-items-center justify-content-between flex-wrap">
            <app-checkbox-single
              formControlName="assistiveTechnologyAddressed"
              attr.aria-label="{{
                previousPlaafp && previousPlaafp.assistiveTechnologyAddressed
                  ? 'Assistive Technology was identified as a special factor in the previous IEP and the IEP team has confirmed it\'s still a need for the learner.'
                  : 'Assistive Technology is a special factor to be addressed in this IEP.'
              }}"
              [label]="
                previousPlaafp && previousPlaafp.assistiveTechnologyAddressed
                  ? 'Assistive Technology was identified as a special factor in the previous IEP and the IEP team has confirmed it’s still a need for the learner.'
                  : 'Assistive Technology is a special factor to be addressed in this IEP.'
              "
            >
            </app-checkbox-single>
          </div>
          <p *ngIf="isAmendment">
            <app-compare-amendment-output
              [amendmentId]="plaafpModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="plaafpModel?.priorVersionId"
              [changedVersionText]="formGroup.get('assistiveTechnologyAddressed').value | boolToYesNo"
              [currentVersionText]="plaafpModel?.priorVersion?.assistiveTechnologyAddressed | boolToYesNo"
              [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="selectedSpecialFactor === 2">
        <p class="text-semibold" aria-level="1">Vision Considerations:</p>
        <ol>
          <li>
            Is there
            <a
              class="text-underline"
              (click)="openHelpWindow(specialFactorsSections.Vision, 'evidence')"
              tabindex="0"
              role="link"
              aria-labelledby="Is there evidence of a vision loss?"
            >
              evidence</a
            >
            of a vision loss?
          </li>
          <li>
            Does the learner have
            <a
              (click)="openHelpWindow(specialFactorsSections.Vision, 'accessibility')"
              class="text-underline"
              tabindex="0"
              role="link"
              aria-labelledby=" Does the learner accessibility needs due to their vision loss that will not be met by the general education environment? Has a Learning Media Assessment been
            completed?"
            >
              accessibility needs</a
            >
            due to their vision loss that will not be met by the general education environment? Has a Learning Media Assessment been
            completed?
          </li>
          <li>
            Does the learner have
            <a
              (click)="openHelpWindow(specialFactorsSections.Vision, 'expanded')"
              class="text-underline"
              tabindex="0"
              role="link"
              aria-labelledby="Does the learner have Expanded Core Curriculum needs that will not be met by the general education curriculum?"
            >
              Expanded Core Curriculum</a
            >
            needs that will not be met by the general education curriculum?
          </li>
        </ol>

        <ng-container *ngIf="isAmendment && !hasOpenAmendment">
          <label>This is a special factor to be addressed in this IEP:</label>
          <p>
            <app-compare-amendment-output
              [amendmentId]="plaafpModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="plaafpModel?.priorVersionId"
              [changedVersionText]="formGroup.get('visionAddressed').value | boolToYesNo"
              [currentVersionText]="plaafpModel?.priorVersion?.visionAddressed | boolToYesNo"
              [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
        </ng-container>
        <ng-container *ngIf="!isAmendment || (hasOpenAmendment && amendingPlaafp)">
          <div class="display-flex align-items-center justify-content-between flex-wrap">
            <app-checkbox-single
              formControlName="visionAddressed"
              attr.aria-label="{{
                previousPlaafp && previousPlaafp.visionAddressed
                  ? 'Vision was identified as a special factor in the previous IEP and the IEP team has confirmed it’s still a need for the learner.'
                  : 'Vision is a special factor to be addressed in this IEP.'
              }}"
              [label]="
                previousPlaafp && previousPlaafp.visionAddressed
                  ? 'Vision was identified as a special factor in the previous IEP and the IEP team has confirmed it’s still a need for the learner.'
                  : 'Vision is a special factor to be addressed in this IEP.'
              "
            >
            </app-checkbox-single>
          </div>
          <p *ngIf="isAmendment">
            <app-compare-amendment-output
              [amendmentId]="plaafpModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="plaafpModel?.priorVersionId"
              [changedVersionText]="formGroup.get('visionAddressed').value | boolToYesNo"
              [currentVersionText]="plaafpModel?.priorVersion?.visionAddressed | boolToYesNo"
              [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="selectedSpecialFactor === 3">
        <p class="text-semibold" aria-level="1">Communication Considerations:</p>
        <ol>
          <li>
            Does the learner’s
            <a
              (click)="openHelpWindow(specialFactorsSections.Communication, 'communication')"
              class="text-underline"
              tabindex="0"
              role="link"
              >communication or language skills</a
            >
            interfere with their education?
          </li>
          <li>
            Does the learner’s
            <a
              (click)="openHelpWindow(specialFactorsSections.Communication, 'mode')"
              class="text-underline"
              tabindex="0"
              role="link"
              aria-labelledby="Does the learner's mode of communication impact their academic progress?"
            >
              mode of communication</a
            >
            impact their academic progress?
          </li>
          <li>
            Does the learner’s communication and language interfere with
            <a
              (click)="openHelpWindow(specialFactorsSections.Communication, 'participation')"
              class="text-underline"
              tabindex="0"
              role="link"
            >
              participation</a
            >
            in social and extracurricular opportunities?
          </li>
        </ol>

        <ng-container *ngIf="isAmendment && !hasOpenAmendment">
          <label>This is a special factor to be addressed in this IEP:</label>
          <p>
            <app-compare-amendment-output
              [amendmentId]="plaafpModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="plaafpModel?.priorVersionId"
              [changedVersionText]="formGroup.get('communicationLanguageAddressed').value | boolToYesNo"
              [currentVersionText]="plaafpModel?.priorVersion?.communicationLanguageAddressed | boolToYesNo"
              [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
        </ng-container>
        <ng-container *ngIf="!isAmendment || (hasOpenAmendment && amendingPlaafp)">
          <div class="display-flex justify-content-between align-items-center flex-wrap">
            <app-checkbox-single
              formControlName="communicationLanguageAddressed"
              attr.aria-label="{{
                previousPlaafp && previousPlaafp.communicationLanguageAddressed
                  ? 'Communication and language was identified as a special factor in the previous IEP and the IEP team has confirmed it\'s still a need for the learner.'
                  : 'Communication is a special factor to be addressed in this IEP.'
              }}"
              [label]="
                previousPlaafp && previousPlaafp.communicationLanguageAddressed
                  ? 'Communication and language was identified as a special factor in the previous IEP and the IEP team has confirmed it’s still a need for the learner.'
                  : 'Communication is a special factor to be addressed in this IEP.'
              "
            >
            </app-checkbox-single>
          </div>
          <p *ngIf="isAmendment">
            <app-compare-amendment-output
              [amendmentId]="plaafpModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="plaafpModel?.priorVersionId"
              [changedVersionText]="formGroup.get('communicationLanguageAddressed').value | boolToYesNo"
              [currentVersionText]="plaafpModel?.priorVersion?.communicationLanguageAddressed | boolToYesNo"
              [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="selectedSpecialFactor === 4">
        <p class="text-semibold" aria-level="1">English Learner Considerations:</p>
        <ol>
          <li>
            What is the background of the learner’s prior schooling? Have there been interruptions or gaps in education that may impact
            progress and performance?
          </li>
          <li>
            Will the instructional plan incorporate instructional strategies that support English development (SIOP, other academic language
            approaches)?
          </li>
          <li>
            Do the learner’s needs require collaboration between general and special education as well as English as a Second Language and
            bilingual education teachers?
          </li>
          <li>Are there specific accommodations or linguistic supports needed to support language learning and assessment?</li>
        </ol>

        <ng-container *ngIf="isAmendment && (!hasOpenAmendment || !amendingPlaafp)">
          <label>This is a special factor to be addressed in this IEP:</label>
          <p *ngIf="isAmendment">
            <app-compare-amendment-output
              [amendmentId]="plaafpModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="plaafpModel?.priorVersionId"
              [changedVersionText]="formGroup.get('limitedEnglishAddressed').value | boolToYesNo"
              [currentVersionText]="plaafpModel?.priorVersion?.limitedEnglishAddressed | boolToYesNo"
              [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
        </ng-container>
        <ng-container *ngIf="!isAmendment || (hasOpenAmendment && amendingPlaafp)">
          <div class="display-flex align-items-center justify-content-between flex-wrap">
            <app-checkbox-single
              formControlName="limitedEnglishAddressed"
              attr.aria-label="{{
                previousPlaafp && previousPlaafp.limitedEnglishAddressed
                  ? 'English learner was identified as a special factor in the previous IEP and the IEP team has confirmed it\'s still a need for the learner.'
                  : 'English learner is a special factor to be addressed in this IEP.'
              }}"
              [label]="
                previousPlaafp && previousPlaafp.limitedEnglishAddressed
                  ? 'English learner was identified as a special factor in the previous IEP and the IEP team has confirmed it’s still a need for the learner.'
                  : 'English learner is a special factor to be addressed in this IEP.'
              "
            >
            </app-checkbox-single>
          </div>
          <p *ngIf="isAmendment">
            <app-compare-amendment-output
              [amendmentId]="plaafpModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="plaafpModel?.priorVersionId"
              [changedVersionText]="formGroup.get('limitedEnglishAddressed').value | boolToYesNo"
              [currentVersionText]="plaafpModel?.priorVersion?.limitedEnglishAddressed | boolToYesNo"
              [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="selectedSpecialFactor === 5">
        <p class="text-semibold" aria-level="1">Health Considerations:</p>
        <ol>
          <li>Does the learner’s medical condition impact strength, vitality, alertness, endurance, or restrict activity at school?</li>
          <li>
            Does the learner have health needs which require school health services, school nurse services, and/or outcomes/goals to promote
            learner health, prevent disease, and/or support academic achievement?
          </li>
          <li>
            Due to the health condition, does the learner
            <a
              (click)="openHelpWindow(specialFactorsSections.Health, 'require')"
              class="text-underline"
              tabindex="0"
              role="link"
              aria-labelledby=" Due to the health condition, does the learner require"
              >require</a
            >:
          </li>
        </ol>
        <ng-container *ngIf="isAmendment && !hasOpenAmendment">
          <label>This is a special factor to be addressed in this IEP:</label>
          <p>
            <app-compare-amendment-output
              [amendmentId]="plaafpModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="plaafpModel?.priorVersionId"
              [changedVersionText]="formGroup.get('healthAddressed').value | boolToYesNo"
              [currentVersionText]="plaafpModel?.priorVersion?.healthAddressed | boolToYesNo"
              [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
        </ng-container>
        <ng-container *ngIf="!isAmendment || (hasOpenAmendment && amendingPlaafp)">
          <div class="display-flex align-items-center justify-content-between flex-wrap">
            <app-checkbox-single
              formControlName="healthAddressed"
              attr.aria-label="{{
                previousPlaafp && previousPlaafp.healthAddressed
                  ? 'Health was identified as a special factor in the previous IEP and the IEP team has confirmed it’s still a need for the learner.'
                  : 'Health is a special factor to be addressed in this IEP.'
              }}"
              [label]="
                previousPlaafp && previousPlaafp.healthAddressed
                  ? 'Health was identified as a special factor in the previous IEP and the IEP team has confirmed it’s still a need for the learner.'
                  : 'Health is a special factor to be addressed in this IEP.'
              "
            >
            </app-checkbox-single>
          </div>
          <p *ngIf="isAmendment">
            <app-compare-amendment-output
              [amendmentId]="plaafpModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="plaafpModel?.priorVersionId"
              [changedVersionText]="formGroup.get('healthAddressed').value | boolToYesNo"
              [currentVersionText]="plaafpModel?.priorVersion?.healthAddressed | boolToYesNo"
              [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
          <ng-container *ngIf="formGroup.get('healthAddressed').value">
            <div class="ml-4">
              <div class="row my-2">
                <div class="col">
                  <app-help-directional-text>
                    <p>Check any of the below that apply to this learner</p>
                  </app-help-directional-text>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <app-checkbox-single
                    formControlName="healthPlan"
                    aria-labelledby="Health is a concern and the learner has an Individual Health Plan."
                    label="Health is a concern and the learner has an Individual Health Plan."
                  >
                  </app-checkbox-single>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <mat-checkbox formControlName="healthPlanCreate" aria-labelledby="Check me!">
                    Health is a concern and an
                    <a (click)="openI3IowaIndividualHealthPlan()" target="_blank" tabindex="0" role="link">Individual Health Plan</a>
                    will be developed for the learner.
                  </mat-checkbox>
                  <p *ngIf="isAmendment">
                    <app-compare-amendment-output
                      [amendmentId]="plaafpModel?.amendmentId"
                      [amendmentFinalized]="amendmentIsFinalized"
                      [amendmentDate]="lastFinalizedDate"
                      [priorVersionId]="plaafpModel?.priorVersionId"
                      [changedVersionText]="formGroup.get('healthPlanCreate').value | boolToYesNo"
                      [currentVersionText]="!formGroup.get('healthPlanCreate').value | boolToYesNo"
                      [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
                      changedPrefixText="Proposed: "
                    >
                    </app-compare-amendment-output>
                  </p>
                  <button
                    *ngIf="formGroup.get('healthPlanCreate').value || formGroup.get('healthPlan').value"
                    class="ml-2"
                    mat-raised-button
                    aria-label="Upload Individual Health Plan"
                    (click)="onOpenUpload()"
                    color="primary"
                    tabindex="0"
                  >
                    Upload Individual Health Plan
                  </button>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="selectedSpecialFactor === 6">
        <p class="text-semibold" aria-level="1">Hearing Considerations:</p>
        <ol>
          <li>
            Is there
            <a
              class="text-underline"
              (click)="openHelpWindow(specialFactorsSections.Hearing, 'evidence')"
              tabindex="0"
              role="link"
              aria-labelledby="Is there evidence of a hearing loss?"
            >
              evidence</a
            >
            of a hearing loss?
          </li>
          <li>
            What impact does the learner's primary
            <a
              class="text-underline"
              (click)="openHelpWindow(specialFactorsSections.Hearing, 'communication')"
              tabindex="0"
              role="link"
              aria-labelledby="What impact does the learner's primary communication mode, expressively and receptively, have on their educational experience?"
            >
              communication mode</a
            >, expressively and receptively, have on their educational experience?
          </li>
          <li>
            Are
            <a
              (click)="openHelpWindow(specialFactorsSections.Hearing, 'additionalSupports')"
              class="text-underline"
              tabindex="0"
              role="link"
            >
              additional supports</a
            >
            needed to follow for opportunities for direct communication with peers and staff in the learner's primary mode of communication,
            expressively and receptively?
          </li>
          <li>
            What
            <a
              (click)="openHelpWindow(specialFactorsSections.Hearing, 'supports')"
              class="text-underline text-lower"
              tabindex="0"
              role="link"
            >
              supports</a
            >
            are needed to increase the learner's proficiency in their language and communications to acquire grade-level concepts?
          </li>
          <li>
            Does the learner have
            <a (click)="openHelpWindow(specialFactorsSections.Hearing, 'expanded')" class="text-underline">Expanded Core Curriculum</a>
            needs that will not be met by the general education curriculum?
          </li>
        </ol>

        <ng-container *ngIf="isAmendment && !hasOpenAmendment">
          <label>This is a special factor to be addressed in this IEP:</label>
          <p>
            <app-compare-amendment-output
              [amendmentId]="plaafpModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="plaafpModel?.priorVersionId"
              [changedVersionText]="formGroup.get('hearingAddressed').value | boolToYesNo"
              [currentVersionText]="plaafpModel?.priorVersion?.hearingAddressed | boolToYesNo"
              [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
        </ng-container>
        <ng-container *ngIf="!isAmendment || (hasOpenAmendment && amendingPlaafp)">
          <div class="display-flex align-items-center justify-content-between flex-wrap">
            <app-checkbox-single
              formControlName="hearingAddressed"
              attr.aria-label="{{
                previousPlaafp && previousPlaafp.hearingAddressed
                  ? 'Hearing was identified as a special factor in the previous IEP and the IEP team has confirmed it’s still a need for the learner.'
                  : 'Hearing is a special factor to be addressed in this IEP.'
              }}"
              [label]="
                previousPlaafp && previousPlaafp.hearingAddressed
                  ? 'Hearing was identified as a special factor in the previous IEP and the IEP team has confirmed it’s still a need for the learner.'
                  : 'Hearing is a special factor to be addressed in this IEP.'
              "
            >
            </app-checkbox-single>
          </div>
          <p *ngIf="isAmendment">
            <app-compare-amendment-output
              [amendmentId]="plaafpModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="plaafpModel?.priorVersionId"
              [changedVersionText]="formGroup.get('hearingAddressed').value | boolToYesNo"
              [currentVersionText]="plaafpModel?.priorVersion?.hearingAddressed | boolToYesNo"
              [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
        </ng-container>
      </ng-container>

      <ng-container *ngIf="selectedSpecialFactor === 7">
        <p class="text-semibold" aria-level="1">Social Emotional Behavior Considerations:</p>
        <ol>
          <li>Has the learner been removed from the educational setting for more than 10 cumulative days?</li>
          <li>
            What
            <a
              (click)="openHelpWindow(specialFactorsSections.SocialEmotional, 'impact')"
              class="text-underline"
              tabindex="0"
              role="link"
              aria-labelledby="What Social Emotional impact"
            >
              impact</a
            >
            does the learner's social-emotional needs and/or behavior have on their daily functioning across all settings and/or routines?
          </li>
          <li>
            Are there situations when the learner’s social-emotional needs, social skill development, and/or behavior make them or other
            learners
            <a (click)="openHelpWindow(specialFactorsSections.SocialEmotional, 'risk')" class="text-underline" tabindex="0" role="link"
              >at risk for loss of instructional time</a
            >?
          </li>
          <li>
            Are there situations when the learner’s social-emotional needs and/or behavior create a
            <a
              (click)="openHelpWindow(specialFactorsSections.SocialEmotional, 'safety')"
              class="text-underline"
              tabindex="0"
              role="link"
              aria-labelledby="Are there situations when the learner’s social-emotional needs and/or behavior create a safety risk for themselves or others?"
            >
              safety risk</a
            >
            for themselves or others?
          </li>
          <li>
            Does the learner’s social-emotional needs, behavior, or social skills development make them at risk for or result in
            <a (click)="openHelpWindow(specialFactorsSections.SocialEmotional, 'loss')" class="text-underline" tabindex="0" role="link"
              >loss of other opportunities?</a
            >
          </li>
          <li>
            Does the learner have any extenuating life circumstances that could be impacting social-emotional behavioral health and
            learning?
          </li>
        </ol>

        <ng-container *ngIf="isAmendment && !hasOpenAmendment">
          <label>This is a special factor to be addressed in this IEP:</label>
          <p>
            <app-compare-amendment-output
              [amendmentId]="plaafpModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="plaafpModel?.priorVersionId"
              [changedVersionText]="formGroup.get('behaviorAddressed').value | boolToYesNo"
              [currentVersionText]="plaafpModel?.priorVersion?.behaviorAddressed | boolToYesNo"
              [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
          <div *ngIf="formGroup.get('behaviorAddressed').value">
            <p>
              A Functional Behavioral Assessment will be conducted.
              <app-compare-amendment-output
                [amendmentId]="plaafpModel?.amendmentId"
                [amendmentFinalized]="amendmentIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [priorVersionId]="plaafpModel?.priorVersionId"
                [changedVersionText]="formGroup.get('fbaWillBeConducted').value | boolToYesNo"
                [currentVersionText]="plaafpModel?.priorVersion?.fbaWillBeConducted | boolToYesNo"
                [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
                changedPrefixText="Proposed: "
              >
              </app-compare-amendment-output>
            </p>
            <p>
              The learner has a Behavior Intervention Plan.
              <app-compare-amendment-output
                [amendmentId]="plaafpModel?.amendmentId"
                [amendmentFinalized]="amendmentIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [priorVersionId]="plaafpModel?.priorVersionId"
                [changedVersionText]="formGroup.get('hasBip').value | boolToYesNo"
                [currentVersionText]="plaafpModel?.priorVersion?.hasBip | boolToYesNo"
                [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
                changedPrefixText="Proposed: "
              >
              </app-compare-amendment-output>
            </p>
            <p>
              A Behavior Intervention Plan will be developed.
              <app-compare-amendment-output
                [amendmentId]="plaafpModel?.amendmentId"
                [amendmentFinalized]="amendmentIsFinalized"
                [amendmentDate]="lastFinalizedDate"
                [priorVersionId]="plaafpModel?.priorVersionId"
                [changedVersionText]="formGroup.get('bipWillBeDeveloped').value | boolToYesNo"
                [currentVersionText]="plaafpModel?.priorVersion?.bipWillBeDeveloped | boolToYesNo"
                [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
                changedPrefixText="Proposed: "
              >
              </app-compare-amendment-output>
            </p>
          </div>
        </ng-container>
        <ng-container *ngIf="!isAmendment || (hasOpenAmendment && amendingPlaafp)">
          <div class="display-flex align-items-center justify-content-between flex-wrap">
            <app-checkbox-single
              formControlName="behaviorAddressed"
              (click)="onBehaviorAddressedChange()"
              attr.aria-label="{{
                previousPlaafp && previousPlaafp.behaviorAddressed
                  ? 'Social Emotional Behavior was identified as a special factor in the previous IEP and the IEP team has confirmed it’s still a need for the learner.'
                  : 'Social Emotional Behavior is a special factor to be addressed in this IEP.'
              }}"
              [label]="
                previousPlaafp && previousPlaafp.behaviorAddressed
                  ? 'Social Emotional Behavior was identified as a special factor in the previous IEP and the IEP team has confirmed it’s still a need for the learner.'
                  : 'Social Emotional Behavior is a special factor to be addressed in this IEP.'
              "
            >
            </app-checkbox-single>
          </div>
          <p *ngIf="isAmendment">
            <app-compare-amendment-output
              [amendmentId]="plaafpModel?.amendmentId"
              [amendmentFinalized]="amendmentIsFinalized"
              [amendmentDate]="lastFinalizedDate"
              [priorVersionId]="plaafpModel?.priorVersionId"
              [changedVersionText]="formGroup.get('behaviorAddressed').value | boolToYesNo"
              [currentVersionText]="plaafpModel?.priorVersion?.behaviorAddressed | boolToYesNo"
              [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
              changedPrefixText="Proposed: "
            >
            </app-compare-amendment-output>
          </p>
          <ng-container *ngIf="formGroup.get('behaviorAddressed').value">
            <div class="ml-4">
              <div class="row my-2">
                <div class="col">
                  <app-help-directional-text>
                    <p>Check any of the below that apply to this learner</p>
                  </app-help-directional-text>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <app-checkbox-single
                    formControlName="hasFba"
                    aria-labelledby="The learner has a Functional Behavior Assessment."
                    label="The learner has a Functional Behavior Assessment."
                  >
                  </app-checkbox-single>
                  <p *ngIf="isAmendment">
                    <app-compare-amendment-output
                      [amendmentId]="plaafpModel?.amendmentId"
                      [amendmentFinalized]="amendmentIsFinalized"
                      [amendmentDate]="lastFinalizedDate"
                      [priorVersionId]="plaafpModel?.priorVersionId"
                      [changedVersionText]="formGroup.get('hasFba').value | boolToYesNo"
                      [currentVersionText]="plaafpModel?.priorVersion?.hasFba | boolToYesNo"
                      [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
                      changedPrefixText="Proposed: "
                    >
                    </app-compare-amendment-output>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <app-checkbox-single
                    #fbaWillBeDevelopedRef
                    (change)="onFbaWillBeDevelopedChange($event)"
                    formControlName="fbaWillBeConducted"
                    aria-labelledby="A Functional Behavior Assessment will be conducted."
                    label="A Functional Behavior Assessment will be conducted."
                  >
                  </app-checkbox-single>
                  <p *ngIf="isAmendment">
                    <app-compare-amendment-output
                      [amendmentId]="plaafpModel?.amendmentId"
                      [amendmentFinalized]="amendmentIsFinalized"
                      [amendmentDate]="lastFinalizedDate"
                      [priorVersionId]="plaafpModel?.priorVersionId"
                      [changedVersionText]="formGroup.get('fbaWillBeConducted').value | boolToYesNo"
                      [currentVersionText]="plaafpModel?.priorVersion?.fbaWillBeConducted | boolToYesNo"
                      [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
                      changedPrefixText="Proposed: "
                    >
                    </app-compare-amendment-output>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <!-- TODO 5969: Add check to see if learner has FBA active from Iowa IDEA IEP -->
                  <app-checkbox-single
                    formControlName="hasBip"
                    aria-labelledby="The learner has a Behavior Intervention Plan."
                    label="The learner has a Behavior Intervention Plan."
                  >
                  </app-checkbox-single>
                  <p *ngIf="isAmendment">
                    <app-compare-amendment-output
                      [amendmentId]="plaafpModel?.amendmentId"
                      [amendmentFinalized]="amendmentIsFinalized"
                      [amendmentDate]="lastFinalizedDate"
                      [priorVersionId]="plaafpModel?.priorVersionId"
                      [changedVersionText]="formGroup.get('hasBip').value | boolToYesNo"
                      [currentVersionText]="plaafpModel?.priorVersion?.hasBip | boolToYesNo"
                      [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
                      changedPrefixText="Proposed: "
                    >
                    </app-compare-amendment-output>
                  </p>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <app-checkbox-single
                    formControlName="bipWillBeDeveloped"
                    #bipWillBeDevelopedRef
                    (change)="onBipWillBeDevelopedChange($event)"
                    aria-labelledby="A Behavior Intervention Plan will be developed."
                    label="A Behavior Intervention Plan will be developed."
                  >
                  </app-checkbox-single>
                  <p *ngIf="isAmendment">
                    <app-compare-amendment-output
                      [amendmentId]="plaafpModel?.amendmentId"
                      [amendmentFinalized]="amendmentIsFinalized"
                      [amendmentDate]="lastFinalizedDate"
                      [priorVersionId]="plaafpModel?.priorVersionId"
                      [changedVersionText]="formGroup.get('bipWillBeDeveloped').value | boolToYesNo"
                      [currentVersionText]="plaafpModel?.priorVersion?.bipWillBeDeveloped | boolToYesNo"
                      [currentPrefixText]="plaafpModel?.priorVersion ? 'Current: ' : ''"
                      changedPrefixText="Proposed: "
                    >
                    </app-compare-amendment-output>
                  </p>
                </div>
              </div>
              <ng-container>
                <hr class="input__divider my-2" />
                <div class="action__row my-0">
                  <ng-container *appAuthForRoles="{ permission: [permissions.FbaFullAccess], caseId: caseId }">
                    <a
                      mat-raised-button
                      color="primary"
                      *ngIf="fbaId && formGroup.get('fbaWillBeConducted').value"
                      [routerLink]="['/', 'cases', caseId, 'behavior-discipline', 'fba', fbaId, 'overview']"
                      tabindex="0"
                      role="link"
                      aria-label="Open FBA"
                      aria-live="polite"
                    >
                      Open FBA
                    </a>
                    <a
                      *ngIf="!fbaId && formGroup.get('fbaWillBeConducted').value"
                      (click)="onCreateFba()"
                      mat-raised-button
                      aria-label="Create FBA"
                      color="primary"
                      aria-live="polite"
                    >
                      Create FBA
                    </a>
                  </ng-container>
                  <ng-container *appAuthForRoles="{ permission: [permissions.BipFullAccess], caseId: caseId }">
                    <a
                      mat-raised-button
                      color="primary"
                      aria-label="Open BIP"
                      *ngIf="bipId && formGroup.get('bipWillBeDeveloped').value"
                      [routerLink]="['/', 'cases', caseId, 'behavior-discipline', 'bip', bipId, 'overview']"
                      tabindex="0"
                      role="link"
                      aria-live="polite"
                    >
                      Open BIP
                    </a>
                    <a
                      *ngIf="!bipId && formGroup.get('bipWillBeDeveloped').value"
                      (click)="createBip()"
                      mat-raised-button
                      aria-label="Create BIP"
                      color="primary"
                      aria-live="polite"
                    >
                      Create BIP
                    </a>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </ng-container>
          <ng-container *ngIf="previousPlaafp && previousPlaafp.behaviorAddressed && !formGroup.get('behaviorAddressed').value">
            <div class="row">
              <div class="col">
                <app-checkbox-single
                  formControlName="behaviorNoLongerFactor"
                  aria-labelledby="Behavior is no longer a special factor"
                  label="Behavior is no longer a special factor"
                >
                </app-checkbox-single>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </mat-card>
</form>
