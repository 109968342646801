import { DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import dayjs from 'dayjs';
import { IepService } from 'src/app/iep/services/iep.service';
import { shortDateFormat } from 'src/app/shared/dateTimeHelpers';
import { CaseSummary } from 'src/app/shared/models/case';
import { NewWindowConfig, openNewWindow } from 'src/app/shared/windowHelpers';
import { AuthService } from '../../../../../auth/auth.service';
import { AppPermissions } from '../../../../../permissions';
import { DialogComingSoonComponent } from '../../../../../shared/components/coming-soon/coming-soon.component';
import { BooleanYesNoPipe } from '../../../../../shared/pipes/boolean-yesno.pipe';
import { CaseService } from '../../../../../shared/services/case/case.service';
import { NotificationService } from '../../../../../shared/services/notification.service';
import { IepAmendmentEndItemModalComponent } from '../../../../modals/iep-amendment-end-item-modal/iep-amendment-end-item-modal.component';
import { IepServicesViewMoreComponent } from '../../../../modals/iep-services-view-more/iep-services-view-more.component';
import { IepView, TrialPlacementTarget } from '../../../../models/iep';
import { IepAmendment } from '../../../../models/iep-amendment';
import { IepServiceDto } from '../../../../models/iep-service';
import { IepServiceService } from '../../../../services/iep-service.service';
import { IepChangeProviderComponent } from '../../../../shared/iep-change-provider/iep-change-provider.component';
import {
  CustomServiceDate,
  IepSsaaServicesComponent,
} from '../../../iep-services-supports-activities/components/iep-ssaa-services/iep-ssaa-services.component';
import { EndServiceComponent } from './end-service/end-service.component';
import { IepServiceInfoComponent } from './iep-service-info/iep-service-info.component';

@Component({
  selector: 'app-iep-details-services',
  templateUrl: './iep-details-services.component.html',
  styleUrls: ['./iep-details-services.component.scss'],
  providers: [DatePipe, BooleanYesNoPipe],
})
export class IepDetailsServicesComponent implements OnInit {
  iepId: string;
  caseId: string;
  @Input() amendments: IepAmendment[] = [];
  @Output() trialPlacementChange = new EventEmitter();
  @Input() iepIncludesTrialPlacement: boolean;
  @Input() trialPlacementTarget: TrialPlacementTarget;
  @Input() isPK = false;
  @Input() isPKToK: boolean;
  @ViewChild('servicesComponent') servicesComponent: IepSsaaServicesComponent;
  servicesDisplayedColumns: string[] = ['actions', 'service', 'serviceType', 'description', 'otherInformation', 'frequency', 'providers'];
  servicesDataSource: MatTableDataSource<any>;
  shortDateFormat = shortDateFormat;
  showServicesForm = false;
  amendingService: IepServiceDto;
  isEditing = false;
  customServiceDate = CustomServiceDate;
  iepView: IepView;
  today: Date;
  trialPlacementTargetEnum = TrialPlacementTarget;
  caseSummary: CaseSummary;
  isLoading = false;

  get amendment() {
    return this.amendments?.length > 0 ? this.amendments[0] : null;
  }

  get amendmentIsFinalized() {
    return !!this.amendment?.finalizeDate;
  }

  get hasOpenAmendment() {
    return !!this.amendment && !this.amendmentIsFinalized;
  }

  get amendmentId() {
    return this.amendment?.id;
  }

  get canEnterEndReason() {
    return this.authService.isAllowedByCaseId(this.caseId, null, AppPermissions.EndServiceNonAmending);
  }

  get showTrialPlacementCheckBox(): boolean {
    return this.iepIncludesTrialPlacement && this.amendment?.trialPlacementTarget === TrialPlacementTarget.SomeItems;
  }

  constructor(
    private iepServiceService: IepServiceService,
    private route: ActivatedRoute,
    private yesNoPipe: BooleanYesNoPipe,
    private datePipe: DatePipe,
    private notificationService: NotificationService,
    private dialog: MatDialog,
    private authService: AuthService,
    private iepService: IepService,
    private caseService: CaseService,
    private cd: ChangeDetectorRef
  ) {}

  async ngOnInit(): Promise<void> {
    this.today = new Date();
    this.iepId = this.route.snapshot.paramMap.get('iepId');
    this.caseId = this.route.snapshot.paramMap.get('caseId');
    this.iepView = await this.iepService.get(this.iepId).toPromise();
    this.servicesDataSource = new MatTableDataSource([]);
    this.caseSummary = await this.caseService.getCaseSummary(this.caseId).toPromise();

    if (!this.caseSummary?.activeIepIsPKToK) {
      this.servicesDisplayedColumns = this.servicesDisplayedColumns.filter((x) => x !== 'serviceType');
    }

    this.getServices();
    this.servicesDataSource.sortingDataAccessor = (item: IepServiceDto, columnId) => {
      switch (columnId) {
        case 'service':
          return item?.serviceType?.label;
        case 'serviceType':
          return this.getServiceType(item?.serviceType);
        case 'frequency':
          return this.getFrequencyText(item);
        case 'otherInformation':
          return this.getOtherInformationText(item);
        case 'providers':
          return this.getProviderString(item?.providers);
        default:
          return item[columnId];
      }
    };
    this.iepServiceService.servicesUpdated$.subscribe(() => this.getServices());
    this.iepServiceService.serviceAddNew$.subscribe(() => {
      this.showServicesForm = true;
      this.isEditing = true;
      this.amendingService = null;
    });
    this.iepServiceService.serviceClose$.subscribe(() => {
      this.showServicesForm = false;
      this.isEditing = false;
    });
  }

  addNew() {
    this.iepServiceService.serviceAddNew.next();
  }

  onEdit(service: IepServiceDto) {
    const callback = (_service: IepServiceDto) => {
      this.showServicesForm = true;
      this.amendingService = _service;
      this.iepServiceService.setAmendingService.next(_service);
      this.isEditing = true;
      this.isLoading = false;
    };

    if (service.amendmentId === null || service.amendmentId !== this.amendmentId) {
      this.isLoading = true;
      this.iepServiceService.amend(this.iepId, service.id, this.amendment.id).subscribe((amendResult) => {
        callback(amendResult);
      });
    } else {
      callback(service);
    }
  }

  updateTrialPlacement(element: IepServiceDto, includedInTrialPlacement: boolean) {
    this.iepServiceService.updateTrialPlacement(element.iepId, element.id, includedInTrialPlacement).subscribe(() => {});
    this.servicesDataSource.data.find((x) => x.id === element.id).includesTrialPlacement = includedInTrialPlacement;
    this.trialPlacementChange.emit();
  }

  viewAuditLog(service) {
    const dialogRef = this.dialog.open(IepServiceInfoComponent, {
      width: '758px',
      data: {
        service,
        id: service.id,
        iepId: this.iepId,
      },
    });
  }

  onEnd(element) {
    this.dialog.open(IepAmendmentEndItemModalComponent, {
      width: '740px',
      data: {
        iepId: this.iepId,
        amendmentId: this.amendment?.id,
        type: 'Service',
        model: element,
        caseId: this.caseId,
      },
    });
  }

  onRemove(service, isUndo = false) {
    this.notificationService.confirmation(
      `Are you sure${isUndo ? '? This will delete this change.' : ' you want to delete this service?'} `,
      () => {
        this.isLoading = true;
        this.iepServiceService.deleteService(this.iepId, service.id, true).subscribe(() => {
          this.notificationService.success(`Service ${isUndo ? 'reverted' : 'deleted'}`);
          this.isLoading = false;
        });
      }
    );
  }

  openComingSoon() {
    this.dialog.open(DialogComingSoonComponent, {
      width: '990px',
    });
  }

  getServices() {
    this.iepServiceService.getAllForAmendment(this.iepId, this.amendment?.id).subscribe((services) => {
      services.forEach((service) => {
        if (service.paraprofessionalSupports) {
          service.paraprofessionalSupports = service.paraprofessionalSupports.split(',');
          service.paraprofessionalSupports = service.paraprofessionalSupports.filter((x) => x !== null && x !== '');
        } else {
          service.paraprofessionalSupports = [];
        }

        if (service.transportationServices) {
          service.transportationServices = service.transportationServices.split(',');
          service.transportationServices = service.transportationServices.filter((x) => x !== null && x !== '');
        } else {
          service.transportationServices = [];
        }
      });

      this.servicesDataSource.data = services;
    });
  }

  getOtherInformationText(service: IepServiceDto) {
    const otherInfo = [];

    if (service) {
      otherInfo.push('Start Date - ' + this.convertToShortDate(service.projectedStartDate) ?? '');
      otherInfo.push('End Date - ' + this.convertToShortDate(service.projectedEndDate) ?? '');

      if (service.directConsult) {
        otherInfo.push('Service Method - ' + service.directConsult);
      }
      if (service.instructionalSupportType) {
        otherInfo.push('Instructional Support Type - ' + service.instructionalSupportType);
      }
      if (service.hasBehaviorInterventionPlan !== null) {
        otherInfo.push('Has Behavior Intervention Plan - ' + this.yesNoPipe.transform(service.hasBehaviorInterventionPlan));
      }
      if (service.hasHealthCarePlan !== null) {
        otherInfo.push('Has Health Care Plan - ' + this.yesNoPipe.transform(service.hasHealthCarePlan));
      }
    }
    return otherInfo?.join(', ');
  }

  joinServiceCodes(serviceCodeArr) {
    return serviceCodeArr.map((x) => x.code)?.join(', ');
  }

  getProviderString(providers, otherProvider?: string) {
    let returnString = '';
    if (providers) {
      let providerCount = 0;
      providers.forEach((provider) => {
        providerCount++;
        let name = provider.firstName;
        if (provider.lastName) {
          name = name + ' ' + provider.lastName;
        }
        returnString += name;
        if (providers.length !== providerCount) {
          returnString += ', ';
        }
      });
    }

    if (otherProvider) {
      return returnString ? returnString + `, ${otherProvider}` : `${otherProvider}`;
    }
    return returnString;
  }

  getFrequencyText(element) {
    let returnText = '';
    if (element) {
      returnText += element.frequencyNumber
        ? element.frequencyNumber + ' ' + element.frequencyIncrement + ' ' + element.frequencyPeriod
        : '';
    }

    return returnText;
  }

  getServiceType(customServiceDate, instructionType?: string) {
    if (instructionType) {
      return instructionType;
    }
    if (customServiceDate === 'BothPKAndK') {
      return 'Both';
    } else {
      if (customServiceDate === 'KOnly') {
        return 'K';
      }
      if (customServiceDate === 'PKOnly') {
        return 'PK';
      }
    }
  }

  convertToShortDate(date: Date) {
    if (!date) {
      return '';
    }
    return this.datePipe.transform(date, shortDateFormat);
  }

  onViewMore(el) {
    el.includesTrialPlacement = this.trialPlacementTarget === this.trialPlacementTargetEnum.AllItems || el.includesTrialPlacement;
    this.dialog.open(IepServicesViewMoreComponent, {
      width: '728px',
      data: {
        service: el,
        amendmentId: this.amendmentId,
        amendmentIsFinalized: this.amendmentIsFinalized,
        lastFinalizedDate: this.lastFinalizedDate(el.amendmentId),
      },
    });
  }

  atLeastOneOptionAvailable(iepService) {
    return (
      this.canAmend(iepService) ||
      this.canIncludeTrialPlacement(iepService) ||
      this.canRemoveTrialPlacement(iepService) ||
      this.canUndo(iepService) ||
      this.canRemove(iepService) ||
      this.canViewAuditLog(iepService) ||
      this.canEnd(iepService) ||
      !this.anyEndDatesEqualOrPast(iepService)
    );
  }

  checkServiceIsEnding(iepService: IepServiceDto, override = false) {
    const serviceTenDayPrior = dayjs(iepService.projectedEndDate).subtract(10, 'day').toDate();
    if (override) {
      return dayjs().toDate() >= serviceTenDayPrior && dayjs().toDate() <= dayjs(iepService.projectedEndDate).toDate();
    }

    return (
      !iepService.amendmentReasonId &&
      dayjs().toDate() >= serviceTenDayPrior &&
      dayjs().toDate() <= dayjs(iepService.projectedEndDate).toDate()
    );
  }

  canAmend(element) {
    return this.hasOpenAmendment && (!this.anyEndDatesEqualOrPast(element) || !element.isComplete) && !this.isEditing;
  }

  canEnd(element) {
    return (
      this.hasOpenAmendment &&
      !this.anyEndDatesInPast(element) &&
      !element.amendmentEndDate &&
      (element?.isActive || (!element?.isActive && !!element?.priorVersionId))
    );
  }

  canRemove(element) {
    return (
      this.hasOpenAmendment &&
      (!this.anyEndDatesInPast(element) || !element.isComplete) &&
      !element?.priorVersionId &&
      element.amendmentId === this.amendmentId
    );
  }

  canUndo(element) {
    return this.hasOpenAmendment && !this.anyEndDatesInPast(element) && element?.priorVersionId && element.amendmentId === this.amendmentId;
  }

  canIncludeTrialPlacement(element) {
    return (
      this.hasOpenAmendment &&
      !this.anyEndDatesEqualOrPast(element) &&
      !this.isEditing &&
      !element.includesTrialPlacement &&
      this.iepIncludesTrialPlacement
    );
  }

  canRemoveTrialPlacement(element) {
    return (
      this.hasOpenAmendment &&
      !this.anyEndDatesInPast(element) &&
      !this.isEditing &&
      element.includesTrialPlacement &&
      this.iepIncludesTrialPlacement
    );
  }

  canViewAuditLog(element) {
    return (
      this.hasOpenAmendment &&
      !this.anyEndDatesInPast(element) &&
      !this.isEditing &&
      (element?.isActive || element?.priorVersionId) &&
      !element?.amendmentEndDate
    );
  }

  anyEndDatesInPast(service) {
    const amendmentEndDate = dayjs(service.amendmentEndDate).toDate().setHours(23, 59, 59, 9999);
    const projectedEndDate = dayjs(service.projectedEndDate).toDate().setHours(23, 59, 59, 9999);
    const today = dayjs().toDate().setHours(0, 0, 0, 9999);
    return projectedEndDate < today || (amendmentEndDate < today && service.isActive);
  }

  anyEndDatesEqualOrPast(service) {
    const amendmentEndDate = dayjs(service.amendmentEndDate).toDate().setHours(23, 59, 59, 9999);
    const projectedEndDate = dayjs(service.projectedEndDate).toDate().setHours(23, 59, 59, 9999);
    const today = dayjs().toDate().setHours(0, 0, 0, 9999);
    return amendmentEndDate <= today || projectedEndDate <= today;
  }

  onChangeProviders(element) {
    const dialogRef = this.dialog.open(IepChangeProviderComponent, {
      data: {
        id: element.id,
        iepId: this.iepId,
        caseId: this.route.snapshot.paramMap.get('caseId'),
        type: 'IEPService',
        dataElement: element,
      },
      width: '364px',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        this.getServices();
      }
    });
  }

  openTags() {
    const config: NewWindowConfig = {
      path: `tags/cases/${this.route.snapshot.paramMap.get('caseId')}/services`,
      popup: true,
    };
    openNewWindow(config);
  }

  enterEndReason(iepService) {
    const dialogRef = this.dialog.open(EndServiceComponent, {
      data: {
        isPk: this.isPK,
        serviceCodes: iepService.serviceCode,
      },
      width: '364px',
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res) {
        const model = {
          bypassAmendment: true,
          endDate: null,
          endReason: res,
        };
        this.iepServiceService.endService(this.iepId, iepService.id, model).subscribe((x) => {
          iepService.endReason = res;
        });
      }
    });
  }

  lastFinalizedDate(amendmentId: string) {
    if (this.amendments && this.amendments.length > 0) {
      const amendment = this.amendments.find((a) => a.id === amendmentId);
      return amendment?.finalizeDate;
    }
    return null;
  }
}
